<template>
  <div>
    <PageHeader title="Log List" class="logListPageHeader-contents">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">

          <DateSelect
            v-if="true"
            ref="dateSelect"
            :presetName="periodPresetName"
            :period="period"
            class="ml-2"
            @change="periodChange"
            :titleText="'登録日'"
          />

          <div class="flex-1 search-contents">
            <label for="search" class="sr-only">Search</label>
            <div class="relative text-light-blue-100 focus-within:text-gray-400">
              <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <Icon iconName="Search" class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              </div>
              <input 
                type="search"
                :value="searchKeyword" 
                class="block w-full bg-light-blue-700 bg-opacity-50 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 placeholder-light-blue-100 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="キーワード検索"
                @change="searchKeywordChanged($event.target.value)"
              />
            </div>
          </div>

          <Icon 
            iconName="Refresh" 
            :clickable="false" 
            :class="{'animate-spin': loading}" 
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer"
            @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="pl-4 flex items-center">
          <h1 class="text-lg font-bold text-white flex items-center">            
            <Icon iconName="UserGroup" class="w-7 h-7 mr-2" /> <span class="mt-0">ログ一覧</span>
          </h1>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-32 w-screen bottom-4 -mt-2 logListMain-contents">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full">

          <LogTable 
            v-show="!loading && searchResults.length > 0" 
            ref="logList" 
            :searchKeyword="searchKeyword"
            :period="period" 
            :freeze="freeze"
            @search-start="searchStart" 
            @search-end="searchEnd" 
          />
          
          <div v-if="loading" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
          </div>
          
          <div v-if="!loading && !initial && searchResults.length === 0" class="w-full h-full flex justify-center items-center text-gray-400">
            <EmptyMessage
              iconName="Document"
              message="該当する案件はありません。"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import LogTable from '../components/LogTable.vue'
import DateSelect from '@components/DateSelect.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import * as utils from '@libs/utils'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    Icon,
    LogTable,
    DateSelect,
    PageHeader,
    EmptyMessage
  },

  data() {
    return {
      searchKeyword: '',
      period: {
        start: utils.formatDateString(new Date().setMonth(new Date().getMonth()-1), '{yyyy}-{MM}-{dd}'),
        end: utils.formatDateString(new Date(), '{yyyy}-{MM}-{dd}')
      },
      periodPresetName: 'custom',
      searchResults: [],
      loading: false,
      initial: true,
      freeze: true,
    }
  },

  created() {
    this.freeze = true
    this.loadState()
  },

  mounted() {
    this.$nextTick(() => {
      this.freeze = false
      this.refresh()
    })
  },

  methods: {
    loadState() {
      const state = this.state
      if (state) {
        this.searchKeyword = state.searchKeyword
        this.periodPresetName = state.periodPresetName
        this.period = state.period ? { start: state.period.start, end: state.period.end } : null
      }
    },

    async refresh() {
      this.loading = true
      await utils.wait(500)
      await this.$refs.logList.fetchLogs()
      await utils.wait(500)
      this.loading = false
    },

    /**
     * 検索
     */
    searchStart() {
      this.loading = true
    },

    searchEnd(data) {
      const self = this
      self.initial = false
      self.loading = false
      self.searchResults = data
    },

    // 全体の検索機能と個別検索機能
    searchKeywordChanged(keyword) {
      this.searchKeyword = keyword
    },

    /**
     * 期間指定 変更
     */
    periodChange(range, presetName) {
      this.period = range
      this.periodPresetName = presetName
    }
  }
}
</script>