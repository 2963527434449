<template>
  <div>
    <DownloadComponent
      typeName='設備点検'
      :type=4
    >
    </DownloadComponent>
  </div>
</template>

<script>
import DownloadComponent from '../../Patrol/components/DownloadComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    DownloadComponent,
  },
}
</script>