<template>
	<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
		<div class="rounded overflow-auto w-fit">
			<div class="p-1">
				<div class="mb-2">
					<PrimaryButton v-if="!leaveFlg" text="休職設定" class="clickButtonColor2 ml-auto block" @click="setLeave()" ></PrimaryButton>
					<PrimaryButton v-else text="上司設定" class="clickButtonColor2 ml-auto block" @click="leaveFlg=false" ></PrimaryButton>
				</div>
				<!-- 休職中 -->
				<div v-if="leaveFlg">
					<div class="text-sm text-blue-600 font-bold mb-1">
						{{ target }}  さんの休職期間を設定してください。
					</div>
					<div class="text-xs text-blue-700 mb-5">
						※保存した期間は再編集できません。<br>&emsp;登録ミスの場合は、その期間を削除して登録しなおしてください。
					</div>
					<!-- 追加 -->
					<PlusCircleIcon class="cursor-pointer text-blue-500 w-8 h-8 ml-auto mt-1 hover:text-blue-600" @click="add()"/>
					<!-- 休職期間リスト -->
					<div v-for="(row, i) in leaveList" :key="i">
						<div class="flex mb-1">
							<input
								type="date"
								v-model="row.startDay"
								class="w-full rounded border-gray-300"
								:class="!row.startDay?'bg-red-300':''"
								:disabled="row.id"
								@change="changeDate($event.target.value, row)"
							>
							<div class="font-bold my-auto mx-2">～</div>
							<input
								type="date"
								v-model="row.endDay"
								class="w-full rounded border-gray-300"
								:class="!row.endDay?'bg-red-300':''"
								:disabled="row.id"
							>
							<div class="w-full ml-2 m-auto">
								<TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 m-auto w-6 h-6" @click="deleteRow(row, i)" />
							</div>
						</div>
					</div>
				</div>
				<!-- 休職中ではない -->
				<div v-else>
					<div class="text-blue-700 font-bold mb-5">
						{{ target }}
					</div>
					<div class="mt-7 w-60">
						<div>
							<div class="text-xs text-blue-600 font-bold">
								グループ
							</div>
							<select
								v-model="group"
								class="focus:bg-yellow-100 border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block w-full"
								@change="setBossList()"
							>
								<option
									v-for="row in [1, 2, 3, 4, 5, 6, 7]" :key='row.name'
									:value="row"
									class="bg-white">
									{{ row }}
								</option>
							</select>
						</div>
					</div>
					<div class="mt-5 w-60">
						<div>
							<div class="text-xs text-blue-600 font-bold">
								上司
							</div>
							<select
								v-model="boss"
								class="focus:bg-yellow-100 border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block w-full"
							>
								<option
									v-for="row in bossList2" :key='row.name'
									:value="row.name"
									class="bg-white">
									{{ row.name }}
								</option>
							</select>
						</div>
					</div>
				</div>

				<!-- 保存ボタン -->
				<div class="mt-7 mb-2">
					<PrimaryButton text="保存" class="w-full " @click="save()" ></PrimaryButton>
				</div>
				<div class="text-transparent">{{reload}}</div>
			</div>
		</div>
	</Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import { TrashIcon, PlusCircleIcon } from '@vue-hero-icons/solid'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as moment from 'moment'
import * as utils from '@libs/utils'

const ROW = { startDay: moment().format('YYYY-MM-DD'), endDay: '' }

export default {
	components: {
		Modal,
		PrimaryButton,
		TrashIcon,
		PlusCircleIcon
	},

	props: ['open', 'target', 'bossList', 'leave'],

	data() {
		return {
			reload: 0,
			// 休職中フラグ
			leaveFlg: false,
			// 休職期間
			leaveList: [utils.clone(ROW)],
			// 上司
			boss: null,
			// グループ番号
			group: null,
			// 自分よりも上のグループの上司
			bossList2: []
		}
	},

	computed: {
	},

	watch: {
		open() {
			if (this.open) {
				// 休職中
				if (this.leave) {
					this.leaveFlg = true
				}
				this.bossList2 = this.bossList
				this.getData()
			}
		}
	},

	methods: {
		/**
		 * モーダルclose
		 */
		closed() {
			this.leaveFlg = false
			this.leaveList = [utils.clone(ROW)]
			this.boss = null
			this.group = null
		},

		/**
		 * 休職データ取得
		 */
		async getData() {
			const d = await backend.searchData('evaluation/getLeaveWork', { name: this.target })
			if (d.data.data) {
				this.leaveList = d.data.data.leave
				this.boss = d.data.data.boss
				this.group = d.data.data.group
			}
			this.setBossList()
		},

		/**
		 * 対象者よりも上のグループの上司に絞り込む
		 */
		setBossList() {
			if (this.group) {
				this.bossList2 = this.bossList.filter((b) => {
					return !b.groupNum || Number(this.group) < b.groupNum
				})
			} else {
				this.bossList2 = this.bossList
			}
		},

		/**
		 * 保存
		 */
		async save() {
			// 休職設定
			if (this.leaveFlg) {
				// 削除の場合
				if (!this.leaveList || !this.leaveList.length) {
					this.leaveList = []
				}
				// 記載分のみ取得
				const list = this.leaveList.filter((l) => {
					return l.startDay || l.endDay
				})
				if (list && list.length) {
					this.leaveList = list
					for (let i = 0; i < this.leaveList.length; i++) {
						const leave = this.leaveList[i]
						if (!leave.startDay) {
							await dialogs.showErrorDialog('開始日未入力あり', '開始日がないため保存できません。')
							return
						}
						if (!leave.endDay) {
							await dialogs.showErrorDialog('終了日未入力あり', '終了日がないため保存できません。')
							return
						}
						if (Number(leave.startDay.replace(/-/g, '')) > Number(leave.endDay.replace(/-/g, ''))) {
							await dialogs.showErrorDialog((i + 1) + '番目が不正な値', '開始日が終了日より後になっているため保存できません。')
							return
						}
					}
				}
				await backend.postData('evaluation/saveLeaveWork', { leaveList: this.leaveList, name: this.target, userId: this.$store.getters.user.id })

			// 上司設定
			} else {
				if (!this.group) {
					await dialogs.showErrorDialog('グループ未選択', 'グループを選択してください。')
					return
				}
				if (!this.boss) {
					await dialogs.showErrorDialog('上司未選択', '上司を選択してください。')
					return
				} else {
					const res = this.bossList2.find((b) => {
						return b.name == this.boss
					})
					if (!res || !res.name) {
						await dialogs.showErrorDialog('上司未選択', '上司を選択してください。\r\n選択できる上司は、自分よりもグループが上、かつ休職中ではない人です。')
						return
					}
				}
				await backend.postData('evaluation/saveOrganization', { boss: this.boss, group: this.group, name: this.target, userId: this.$store.getters.user.id })
			}
			this.$emit('getData')
			this.$emit('closed')
			this.closed()
		},

		/**
		 * 休職設定
		 */
		setLeave() {
			this.leaveFlg = true
		},

		/**
		 * 休職開始日変更
		 * @param val 入力値
		 * @param row 対象行
		 */
		changeDate(val, row) {
			if (val) {
				row.endDay = moment(val + ' 00:00:00').add(3, 'months').format('YYYY-MM-DD')
				this.reload++
			}
		},

		/**
		 * 追加ボタン
		 */
		add() {
			this.leaveList.unshift(utils.clone(ROW))
		},

		/**
		 * 行削除
		 * @param row 対象行
		 * @param i インデックス
		 */
		async deleteRow(row, i) {
			if (row.id) {
				const log = await dialogs.showWarningConfirmDialog('登録間違いですか？', '休職履歴は残してください。\r\n評価シートで「休職」期間が分かるようにしています。\r\n登録間違いの場合のみ、データを削除するようにしてください。')
				if (log != 'YES') {
					return
				}
			}
			this.leaveList.splice(i, 1)
		}
	}
}
</script>
<style scoped>
</style>