<template>
  <div>
    <CheckListTableComponent
      typeName='品質巡回'
      :type=1
      routerName='ScheduleListByStaff'
      :siteLocation="siteLocation"
      :spot="spot"
    >
    </CheckListTableComponent>
  </div>
</template>

<script>
import CheckListTableComponent from '../components/CheckListTableComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    CheckListTableComponent,
  },

  props: {
    // 位置情報
    siteLocation: {
      type: Object
    },
    // スポット
    spot: {
      type: Boolean,
      default: false
    },
  },
}
</script>