<template>
  <div>
    <PageHeader :title="isMobileDevice?'':'評価シート'" class="list">
      <template #title-header-content>
        <div class="flex-1 flex justify-around items-center">
          <div></div>
          
          <!-- 評価対象者 -->
          <div class="mt-2 mx-auto">
            <div class="m-auto font-bold w-fit text-gray-50 text-xl flex">
              <div class="mr-3">
                {{ year }} 年度
                <span class="">{{ period==1?'（上期）':'（下期）' }}</span>
              </div>
              {{ userName }}
            </div>
          </div>

          <!-- 一覧 -->
          <div class="min-w-fit">
            <PrimaryButton class="" size="sm" text="評価シート一覧" @click="$router.push({ name: 'EvaluationList' })">
              <LoadingIcon v-if="loading" slot="before" class="h-4 w-4" />
              <Icon v-else iconName="ClipboardList" slot="before" class="h-4 w-4" />
            </PrimaryButton>
          </div>
        </div>
      </template>
    </PageHeader>
    <main class="absolute top-20 overflow-auto bg-white2 rounded mx-auto w-full">
      <div v-if="!loading" class="mx-auto px-2 input-form">

        <div class="mt-5 p-2 border-2 border-yellow-400 rounded">
          <div class="font-bold mb-4">
            {{ year }} 年度
            <span class="pl-3">{{ period==1?'上期（2～7月）':'下期（8～1月）' }}</span>
          </div>
          <div class="flex">
            <!-- 入社日 -->
            <div class="flex mb-1 w-1/2 mr-1">
              <div class="py-2 bg-gray-300 items-stretch w-28 text-center rounded-l">
                入社日
              </div>
              <div class="w-full">
                <input
                  type="date"
                  v-model="list.startDate"
                  class="w-full rounded-r border-gray-300"
                  :class="!list.startDate?'bg-red-300':''"
                  :disabled="notEdit"
                  @change="saveInfo('startDate')"
                >
              </div>
            </div>
            <!-- 部署名 -->
            <div class="flex mb-1 w-1/2 ml-1">
              <div class="py-2 bg-gray-300 items-stretch w-28 text-center rounded-l">
                部署名
              </div>
              <select
                name="departmentName"
                v-model="list.departmentName"
                :class="!list.departmentName?'bg-red-300':''"
                class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 font-bold w-full"
                :disabled="notEdit"
                @change="changeDepartment()"
              >
                <option
                  v-for="(d, i) in departmentList" :key="'department' + i"
                  :value="d.name"
                  class="bg-white">
                  {{ d.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="flex">
            <!-- 等級 -->
            <div class="flex mb-1 w-1/2 mr-1">
              <div class="py-2 bg-gray-300 items-stretch w-28 text-center rounded-l">
                等&emsp;級
              </div>
              <select
                name="grade"
                v-model="list.grade"
                class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 font-bold w-full"
                :class="!list.grade?'bg-red-300':''"
                :disabled="notEdit"
                @change="saveInfo('grade')"
              >
                <option
                  v-for="(d, i) in [1, 2, 3, 4, 5, 6]" :key="'gra' + i"
                  :value="d"
                  class="bg-white">
                  {{ d }}
                </option>
              </select>
            </div>
            <!-- グループ -->
            <div class="flex mb-1 w-1/2 ml-1">
              <div class="py-2 bg-gray-300 items-stretch w-28 text-center rounded-l">
                グループ
              </div>
              <select
                name="group"
                v-model="list.groupNum"
                class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 font-bold w-full"
                :class="!list.groupNum?'bg-red-300':''"
                :disabled="notEdit"
                @change="saveInfo('groupNum')"
              >
                <option
                  v-for="(d, i) in [1, 2, 3, 4, 5, 6, 7]" :key="'gro' + i"
                  :value="d"
                  class="bg-white">
                  {{ d }}
                </option>
              </select>
            </div>
          </div>

          <div class="py-3 mx-auto">
            <table>
              <thead>
                <tr>
                  <th scope="col" v-for="(col, i) in ['', '前年同期実績', '当期実績', '前年比']" :key="'col0' + i" class="px-2 text-sm">
                    {{ col }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-sm font-bold px-2 whitespace-nowrap">
                    1. 会社売上
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="w-full">
                      <NumericTextInput
                        name="lastYearSales"
                        v-model="list.lastYearSales"
                        class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-green-400 rounded"
                        :disabled="notEdit"
                        @change="saveInfo('lastYearSales')"
                      />
                    </div>
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="w-full">
                      <NumericTextInput
                        name="sales"
                        v-model="list.sales"
                        class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-yellow-500 rounded"
                        :disabled="notEdit"
                        @change="saveInfo('sales')"
                      />
                    </div>
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="">
                      <input
                        type="text"
                        :disabled="true"
                        :value="getRateWork(list.lastYearSales, list.sales)"
                        class="w-full border border-gray-500 rounded text-sm text-right"
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-sm font-bold px-2 whitespace-nowrap">
                    2. 部門粗利益
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="w-full">
                      <NumericTextInput
                        name="lastYearGrossProfit"
                        v-model="list.lastYearGrossProfit"
                        class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-green-400 rounded"
                        :disabled="notEdit"
                        @change="saveInfo('lastYearGrossProfit')"
                      />
                    </div>
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="w-full">
                      <NumericTextInput
                        name="grossProfit"
                        v-model="list.grossProfit"
                        class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-yellow-500 rounded"
                        :disabled="notEdit"
                        @change="saveInfo('grossProfit')"
                      />
                    </div>
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="">
                      <input
                        type="text"
                        :disabled="true"
                        :value="getRateWork(list.lastYearGrossProfit, list.grossProfit)"
                        class="w-full border border-gray-500 rounded text-sm text-right"
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-sm font-bold px-2 whitespace-nowrap">
                    3. 部門営業利益
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="w-full">
                      <NumericTextInput
                        name="lastYearProfit"
                        v-model="list.lastYearProfit"
                        class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-green-400 rounded"
                        :disabled="notEdit"
                        @change="saveInfo('lastYearProfit')"
                      />
                    </div>
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="w-full">
                      <NumericTextInput
                        name="profit"
                        v-model="list.profit"
                        class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-yellow-500 rounded"
                        :disabled="notEdit"
                        @change="saveInfo('profit')"
                      />
                    </div>
                  </td>
                  <td class="px-2 w-1/4">
                    <div class="">
                      <input
                        type="text"
                        :disabled="true"
                        :value="getRateWork(list.lastYearProfit, list.profit)"
                        class="w-full border border-gray-500 rounded text-sm text-right"
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div class="flex mt-5">
            <!-- 始末書 -->
            <div class="flex mb-1 mr-1 w-full">
              <div class="w-20 text-xs h-full whitespace-nowrap font-bold py-1 px-2 my-auto bg-gray-200 items-stretch text-center rounded-l">
                始末書<div class="font-normal">（枚数）</div>
              </div>
              <div class="w-full">
                <input
                  type="number"
                  v-model="list.apology"
                  class="text-right w-full h-full focus:bg-yellow-100 sm:text-sm border-gray-300 rounded-r"
                  :class="!list.apology&&list.apology!=0?'bg-red-300':''"
                  :disabled="notEdit"
                  @change="saveInfo('apology')"
                />
              </div>
            </div>
            <!-- 禁煙 -->
            <div class="flex mb-1 ml-1 w-full">
              <div class="w-20 text-xs h-full whitespace-nowrap font-bold py-3 px-2 my-auto bg-gray-200 items-stretch text-center rounded-l">
                禁煙
              </div>
              <select
                name="noSmoking"
                v-model="list.noSmoking"
                class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-full"
                :class="!list.noSmoking&&list.noSmoking==null?'bg-red-300':''"
                :disabled="notEdit"
                @change="saveInfo('noSmoking')"
              >
                <option
                  v-for="(d, i) in [{val:true, text:'している'}, {val:false, text:'できていない'}]" :key="'smo' + i"
                  :value="d.val"
                  class="bg-white">
                  {{ d.text }}
                </option>
              </select>
            </div>
            <!-- 事故違反 -->
            <div class="flex mb-1 ml-1 w-full">
              <div class="w-20 text-xs h-full whitespace-nowrap font-bold py-3 px-2 my-auto bg-gray-200 items-stretch text-center rounded-l">
                事故違反
              </div>
              <select
                name="violation"
                v-model="list.violation"
                class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-full"
                :class="!list.violation&&list.violation==null?'bg-red-300':''"
                :disabled="notEdit"
                @change="saveInfo('violation')"
              >
                <option
                  v-for="(d, i) in [{val:true, text:'有り'}, {val:false, text:'無し'}]" :key="'smo' + i"
                  :value="d.val"
                  class="bg-white">
                  {{ d.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- プロセス評価 -->
        <div class="mt-5 border-2 border-blue-600 rounded py-2">
          <div class="font-bold text-blue-800 mb-3 px-2">プロセス評価 <span class="text-sm text-gray-600">（５：極めて優れている&emsp;４：優れている&emsp;３：標準&emsp;２：劣る&emsp;１：非常に劣る）</span></div>
          
          <div class="ml-2 mb-3 text-red-600 font-bold text-xs flex">
            <Icon iconName="ExclamationCircle" slot="before" class="h-4 w-4 mr-1" />
            15日までに先月分を（上司評価まで全て）完了してください。過ぎた場合、上司部下ともに反省文提出となります。
          </div>

          <div class="flex mt-2 ml-2">
            <!-- 上司 -->
            <div class="flex mb-1">
              <div class="py-2 bg-blue-300 items-stretch w-28 text-center rounded-l font-bold text-blue-900">
                上司
              </div>
              <select
                name="boss"
                v-model="bossName"
                class="border-blue-400 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 font-bold w-450px"
                :class="!bossName||bossName=='削除'?'bg-red-300':''"
                @change="saveBoss()"
              >
                <option
                  v-for="(boss, i) in bossList2" :key="'gra' + i"
                  :value="boss.name"
                  class="bg-white">
                  {{ boss.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="w-fit mx-auto overflow-auto process">
            <div v-for="(row, i) in list.item" :key="'row'+i">
              <div class="p-2 text-sm whitespace-pre-line text-blue-700 bg-white2">
                <!-- タイトル -->
                <div class="font-bold">
                  {{ (i + 1) + ' ' + row.title }}
                </div>
                <!-- 項目 -->
                <div class="text-sm text-blue-800 my-2">
                  {{ row.item }}
                </div>
              </div>

              <div class="flex mx-2 mb-5">
                <!-- 1つ目 -->
                <div class="bg-bl w-fit px-2 pb-1" @click="showAlert(1)">
                  <div class="w-fit mx-auto text-gray-800">{{ columns[1].val }}</div>
                  <!-- 入力欄 -->
                  <div class="flex">
                    <div>
                      <div class="text-xs text-gray-800 m-auto w-fit">自己</div>
                      <select
                        v-model="row.valueSelf1"
                        class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white text-gray-900 font-bold px-2 py-1.5 last-center"
                        :disabled="boss||setDisableReason(1, 'Self')=='-'"
                        @change="saveProcess($event.target.value, row, 1)"
                      >
                        <option
                          v-for="(p, i) in processValue" :key="'pro' + i"
                          :value="p"
                          class="bg-white">
                          {{ p }}
                        </option>
                      </select>
                    </div>

                    <div>
                      <div class="text-xs text-green-800 m-auto w-fit">上司</div>
                      <select
                        v-model="row.valueBoss1"
                        class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white text-green-800 font-bold px-2 py-1.5 last-center"
                        :disabled="!boss||notEdit||setDisableReason(1, 'Boss')=='-'"
                        @change="saveProcess($event.target.value, row, 1)"
                      >
                        <option
                          v-for="(p, i) in processValue" :key="'pro' + i"
                          :value="p"
                          class="bg-white">
                          {{ p }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- 2つ目 -->
                <div class="bg-bl2 w-fit px-2 pb-1">
                  <div class="w-fit mx-auto text-gray-800">{{ columns[2].val }}</div>
                  <!-- 入力欄 -->
                  <div class="flex" @click="showAlert(2)">
                    <div>
                      <div class="text-xs text-gray-800 m-auto w-fit">自己</div>
                      <select
                        v-model="row.valueSelf2"
                        class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white text-gray-900 font-bold px-2 py-1.5 last-center"
                        :disabled="boss||setDisableReason(2, 'Self')=='-'"
                        @change="saveProcess($event.target.value, row, 2)"
                      >
                        <option
                          v-for="(p, i) in processValue" :key="'pro' + i"
                          :value="p"
                          class="bg-white">
                          {{ p }}
                        </option>
                      </select>
                    </div>
                    <div>
                      <div class="text-xs text-green-800 m-auto w-fit">上司</div>
                      <select
                        v-model="row.valueBoss2"
                        class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white text-green-800 font-bold px-2 py-1.5 last-center"
                        :disabled="!boss||notEdit||setDisableReason(2, 'Boss')=='-'"
                        @change="saveProcess($event.target.value, row, 2)"
                      >
                        <option
                          v-for="(p, i) in processValue" :key="'pro' + i"
                          :value="p"
                          class="bg-white">
                          {{ p }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- 3つ目 -->
                <div class="bg-bl w-fit px-2 pb-1">
                  <div class="w-fit mx-auto text-gray-800">{{ columns[3].val }}</div>
                  <!-- 入力欄 -->
                  <div class="flex" @click="showAlert(3)">
                    <div>
                      <div class="text-xs text-gray-800 m-auto w-fit">自己</div>
                      <select
                        v-model="row.valueSelf3"
                        class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white text-gray-900 font-bold px-2 py-1.5 last-center"
                        :disabled="boss||setDisableReason(3, 'Self')=='-'"
                        @change="saveProcess($event.target.value, row, 3)"
                      >
                        <option
                          v-for="(p, i) in processValue" :key="'pro' + i"
                          :value="p"
                          class="bg-white">
                          {{ p }}
                        </option>
                      </select>
                    </div>
                    <div>
                      <div class="text-xs text-green-800 m-auto w-fit">上司</div>
                      <select
                        v-model="row.valueBoss3"
                        class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white text-green-800 font-bold px-2 py-1.5 last-center"
                        :disabled="!boss||notEdit||setDisableReason(3, 'Boss')=='-'"
                        @change="saveProcess($event.target.value, row, 3)"
                      >
                        <option
                          v-for="(p, i) in processValue" :key="'pro' + i"
                          :value="p"
                          class="bg-white">
                          {{ p }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- 4つ目 -->
                <div class="bg-bl2 w-fit px-2 pb-1">
                  <div class="w-fit mx-auto text-gray-800">{{ columns[4].val }}</div>
                  <!-- 入力欄 -->
                  <div class="flex" @click="showAlert(4)">
                    <div>
                      <div class="text-xs text-gray-800 m-auto w-fit">自己</div>
                      <select
                        v-model="row.valueSelf4"
                        class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white text-gray-900 font-bold px-2 py-1.5 last-center"
                        :disabled="boss||setDisableReason(4, 'Self')=='-'"
                        @change="saveProcess($event.target.value, row, 4)"
                      >
                        <option
                          v-for="(p, i) in processValue" :key="'pro' + i"
                          :value="p"
                          class="bg-white">
                          {{ p }}
                        </option>
                      </select>
                    </div>
                    <div>
                      <div class="text-xs text-green-800 m-auto w-fit">上司</div>
                      <select
                        v-model="row.valueBoss4"
                        class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white text-green-800 font-bold px-2 py-1.5 last-center"
                        :disabled="!boss||notEdit||setDisableReason(4, 'Boss')=='-'"
                        @change="saveProcess($event.target.value, row, 4)"
                      >
                        <option
                          v-for="(p, i) in processValue" :key="'pro' + i"
                          :value="p"
                          class="bg-white">
                          {{ p }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- 5つ目 -->
                <div class="bg-bl w-fit px-2 pb-1">
                  <div class="w-fit mx-auto text-gray-800">{{ columns[5].val }}</div>
                  <!-- 入力欄 -->
                  <div class="flex" @click="showAlert(5)">
                    <div>
                      <div class="text-xs text-gray-800 m-auto w-fit">自己</div>
                      <select
                        v-model="row.valueSelf5"
                        class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white text-gray-900 font-bold px-2 py-1.5 last-center"
                        :disabled="boss||setDisableReason(5, 'Self')=='-'"
                        @change="saveProcess($event.target.value, row, 5)"
                      >
                        <option
                          v-for="(p, i) in processValue" :key="'pro' + i"
                          :value="p"
                          class="bg-white">
                          {{ p }}
                        </option>
                      </select>
                    </div>
                    <div>
                      <div class="text-xs text-green-800 m-auto w-fit">上司</div>
                      <select
                        v-model="row.valueBoss5"
                        class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white text-green-800 font-bold px-2 py-1.5 last-center"
                        :disabled="!boss||notEdit||setDisableReason(5, 'Boss')=='-'"
                        @change="saveProcess($event.target.value, row, 5)"
                      >
                        <option
                          v-for="(p, i) in processValue" :key="'pro' + i"
                          :value="p"
                          class="bg-white">
                          {{ p }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- 6つ目 -->
                <div class="bg-bl2 w-fit px-2 pb-1">
                  <div class="w-fit mx-auto text-gray-800">{{ columns[6].val }}</div>
                  <!-- 入力欄 -->
                  <div class="flex" @click="showAlert(6)">
                    <div>
                      <div class="text-xs text-gray-800 m-auto w-fit">自己</div>
                      <select
                        v-model="row.valueSelf6"
                        class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white text-gray-900 font-bold px-2 py-1.5 last-center"
                        :disabled="boss||setDisableReason(6, 'Self')=='-'"
                        @change="saveProcess($event.target.value, row, 6)"
                      >
                        <option
                          v-for="(p, i) in processValue" :key="'pro' + i"
                          :value="p"
                          class="bg-white">
                          {{ p }}
                        </option>
                      </select>
                    </div>
                    <div>
                      <div class="text-xs text-green-800 m-auto w-fit">上司</div>
                      <select
                        v-model="row.valueBoss6"
                        class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white text-green-800 font-bold px-2 py-1.5 last-center"
                        :disabled="!boss||notEdit||setDisableReason(6, 'Boss')=='-'"
                        @change="saveProcess($event.target.value, row, 6)"
                      >
                        <option
                          v-for="(p, i) in processValue" :key="'pro' + i"
                          :value="p"
                          class="bg-white">
                          {{ p }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- 平均 -->
                <div class="w-fit px-2 pb-1 font-bold bg-blue-200">
                  <div class="w-fit mx-auto text-gray-800">平均</div>
                  <!-- 入力欄 -->
                  <div class="flex">
                    <div>
                      <div class="text-xs text-gray-800 m-auto w-fit">自己</div>
                      <div class="w-14 font-bold text-gray-900 text-center py-1.5 border border-gray-300 rounded bg-white">
                        {{ getAve(row, 'valueSelf') }}
                      </div>
                    </div>
                    <div>
                      <div class="text-xs text-green-800 m-auto w-fit">上司</div>
                      <div class="w-14 font-bold text-green-800 text-center py-1.5 border border-gray-300 rounded bg-white">
                        {{ getAve(row, 'valueBoss') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 各月の合計点 -->
            <div class="flex m-2">
              <div v-for="t in [1, 2, 3, 4, 5, 6]" :key="'t'+t" class="w-fit py-1 px-2" :class="t%2==1?'bg-bl':'bg-bl2'">
                <div class="text-xs text-center mb-1 text-gray-900">
                  {{ columns[t].val }}合計
                </div>
                <div class="text-sm flex font-bold">
                  <!-- 自己合計 -->
                  <div class="w-14 text-center">
                    {{ totalProcessMonth('valueSelf', t) }}
                  </div>
                  <!-- 上司合計 -->
                  <div class="w-14 text-center text-green-700">
                    {{ totalProcessMonth('valueBoss', t) }}
                  </div>
                </div>
              </div>
            </div>

            <!-- 完了ボタン -->
            <div class="mx-2 mt-5 mb-2 font-bold text-sm text-blue-800">
              ※入力完了後、その月の「完了」ボタンを押してください。
            </div>
            <div class="flex m-2">
              <div v-for="btn in [1, 2, 3, 4, 5, 6]" :key="'btn'+btn" class="text-xs w-32">
                <!-- 自己完了 -->
                <div v-if="!boss" @click="saveComp(btn, 'processSelf')" class="w-fit mx-auto text-center cursor-pointer font-normal p-1.5 whitespace-nowrap rounded border border-blue-700" :class="compBtn(btn, 'processSelf').indexOf('済')>-1?'bg-blue-700 cursor-not-allowed text-white font-bold text-xss':'text-blue-700 bg-blue-50'">
                  {{ columns[btn].val }} 自己入力 {{ compBtn(btn, 'processSelf') }}
                </div>

                <!-- 確認 -->
                <div v-if="!boss" @click="processConfirm(btn)" class="mt-2 w-fit mx-auto text-center cursor-pointer font-normal p-1.5 whitespace-nowrap rounded border border-green-700" :class="list['processSelfCheck'+btn]?'bg-green-700 cursor-not-allowed text-white font-bold text-xss':'text-green-700 bg-blue-50'">
                  {{ columns[btn].val }} 上司評価 {{ list['processSelfCheck'+btn]?'確認済':'確認' }}
                </div>
                <!-- アラート -->
                <div v-if="!boss&&list['processBossTimeSt'+btn]&&!list['processSelfCheck'+btn]" class="text-xs w-fit mx-auto text-red-700 animate-pulse whitespace-pre-line">{{ list['processBossTimeSt' + btn] }}<br>押してください！</div>

                <!-- 上司完了 -->
                <div v-if="boss" @click="saveComp(btn, 'processBoss')" class="w-fit mx-auto cursor-pointer font-normal p-1.5  whitespace-nowrap rounded border border-blue-700" :class="compBtn(btn, 'processBoss').indexOf('済')>-1?'bg-blue-700 cursor-not-allowed text-white font-bold text-xss':'text-blue-700 bg-blue-50'">
                  {{ columns[btn].val }} 上司入力 {{ compBtn(btn, 'processBoss') }}
                </div>
              </div>

              <!-- 点数 -->
              <div class="font-bold text-sm text-center text-blue-700 mx-auto">
                <div>点数</div>
                <div class="my-1.5">{{ getTotalProcess }} 点</div>
                <div class="text-xs font-normal text-gray-800">（上司平均合計×10）</div>
              </div>
            </div>
            <div v-if="!boss" class="mx-2 my-2 font-bold text-sm text-green-800">
              <!-- eslint-disable-next-line no-irregular-whitespace -->
              ※上司が入力完了後、{{userName.replace(' ', '').replace('　', '')}} さんが 5分以内にその月の「上司評価 確認」ボタンを押してください。
              <div class="font-normal w-fit border-b border-blue-700 text-blue-700 cursor-pointer mt-3" @click="uploadProcess()">最新の上司評価を表示する</div>
            </div>
          </div>
        </div>

        <!-- 備考 -->
        <div class="mt-5 border-2 border-green-500 rounded p-2 bg-gr">
          <div class="font-bold text-green-800 mb-3 px-2">備考</div>
          <textarea
            :rows="countRowNum(note)"
            class="border border-gray-300 w-full rounded text-sm leading-6"
            v-model="note">
          </textarea>
          <div class="py-1 px-5 w-fit bg-green-700 text-white font-bold rounded text-sm cursor-pointer" @click="saveNote()">保存</div>
          <div class="mt-2 text-gray-800 text-sm">
            <div v-for="(n, i) in list.note" :key="'note'+i" class="my-1 whitespace-pre flex">
              <div class="w-5 text-center text-xs text-gray-500">●</div>
              <div>{{ n }}</div>
            </div>
          </div>
        </div>

        <!-- 方針共有点 -->
        <div class="mt-5 border-2 border-pink-500 rounded pt-2">
          <div class="font-bold text-pink-800 mb-3 px-2">方針共有点<span class="text-sm text-gray-600">（自動集計のため入力不要）</span></div>

          <div class="ml-2 mb-3 text-gray-600 text-xs">
            ※サンクスカードの目標：グループ2以下は10枚/月、その他20枚/月
          </div>
          <div id="policy-form" class="overflow-auto">
            <table class="w-full">
              <thead>
                <tr>
                  <th scope="col" v-for="(col, i) in columns2" :key="'col2'+i" class="whitespace-nowrap bg-white2 p-2 text-right border-pink-300 border-b">
                    {{ col.val }}
                  </th>
                </tr>
              </thead>
              <tbody v-for="(row, i) in list.item2" :key="'row'+i" class="border-pink-300 border-t">
                <tr class="hover-color2">
                  <!-- 項目 -->
                  <td class="p-2 text-sm whitespace-pre font-bold text-pink-700">
                    {{ row.item }}
                  </td>
                  <!-- 目標 -->
                  <td class="p-2 text-sm text-right whitespace-nowrap font-bold text-gray-500">
                    {{ row.sortNo==2?goalThanks:row.goal }} {{ row.unitName }}
                  </td>

                  <td class="p-2 text-sm font-bold text-right">
                    {{ row.value1 }}
                  </td>

                  <td class="p-2 text-sm font-bold text-right">
                    {{ row.value2 }}
                  </td>

                  <td class="p-2 text-sm font-bold text-right">
                    {{ row.value3 }}
                  </td>

                  <td class="p-2 text-sm font-bold text-right">
                    {{ row.value4 }}
                  </td>

                  <td class="p-2 text-sm font-bold text-right">
                    {{ row.value5 }}
                  </td>

                  <td class="p-2 text-sm font-bold text-right">
                    {{ row.value6 }}
                  </td>

                  <!-- 合計 -->
                  <td class="p-2 text-sm whitespace-nowrap text-right">
                    <div>{{ getTotal(row) }}</div>
                  </td>
                  <!-- 点数（達成率と同じ少数以下切り捨て） -->
                  <td class="p-2 whitespace-nowrap text-right text-pink-700 font-bold">
                    <div v-if="row.goal">{{ getPolicyPoint(row) }} 点</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="h-10"></div>
        <div class="text-transparent">{{reload}}</div>
      </div>

      <div v-else class="w-full h-screen flex justify-center items-center">
        <p class="text-gray-400 font-bold animate-pulse">Loading...</p>
      </div>
    </main>
  </div>
</template>
<script>

import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import NumericTextInput from '@components/NumericTextInput.vue'
import LoadingIcon from '@assets/loading-circle.svg'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
import * as moment from 'moment'
import BigNumber from 'bignumber.js'

const ROW = {
  // プロセス評価
  item: [{}],
  // 方針共有
  item2: [{}]
}

export default {
  components: {
    Icon,
    PageHeader,
    PrimaryButton,
    NumericTextInput,
    LoadingIcon
    // TrashIcon,
    // PlusCircleIcon
  },

  props: ['target'],

  data() {
    return {
      reload: 0,

      loading: false,

      list: utils.clone(ROW),

      // プロセスの選択値
      processValue: [1, 2, 3, 4, 5],

      // プロセスのカラム
      columns: [
        { val: '' }
      ],
      columns1: [],

      // 方針共有点のカラム
      columns2: [
        { val: '' },
        { val: '目標' }
      ],

      // 表示している期間
      year: null, // 年度
      period: null, // 上期：1、下期：2

      // 評価対象者
      userName: '',
      userId: null,

      // 部署リスト
      departmentList: [],

      // ログインユーザーが上司
      boss: false,

      // 評価対象者の上司データ
      bossName: '',

      // 上司リスト（元データ）
      bossList: [],
      // 上司リスト（対象者よりも上のグループに絞り込み）
      bossList2: [],

      // プロセス評価完了リスト
      itemRes: {},
      itemBossRes: {},

      // 方針共有完了リスト
      item2Res: {},

      // 備考
      note: '',

      // 部下の部下の未完了者データ
      notComp: [],

      // 編集不可
      notEdit: false,

      // 管理者権限あり
      admin: false,

      // 評価プロセス反映
      uploadFlg: false,

      interval: null
    }
  },

  mounted() {
    // ５秒おきに反映
    this.interval = setInterval(() => {
      this.uploadProcess()
    }, 5000)
  },

  destroyed() {
    // 5秒おきの反映終了
    clearInterval(this.interval)
  },

  async created() {
    // 管理者権限
    let auth = this.$store.getters.auth
    if (auth && auth.adminAuth) {
      this.admin = true
    }
    // 部署リスト取得
    this.departmentList = utils.clone(this.$store.getters.departmentList)
    // 上司フラグ
    const user = this.$store.getters.loginUserData
    this.boss = user.boss

    this.uploadFlg = true
    // データ取得
    await this.getData()
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * タブレット
     */
    isTabletDevice() {
      return utils.deviceInfo.isTablet
    },

    /**
     * ログインユーザー名
     */
    loginName() {
      return utils.deleteKana(this.$store.getters.user.user_name)
    },

    /**
     * 業績
     */
    getRateWork() {
      return (last, now) => {
        if (last && now && !isNaN(last) && !isNaN(now)) {
          let res = Math.floor(Number(now) * 100 / Number(last))
          return res + '％'
        }
        return ''
      }
    },

    /**
     * 入力完了したか
     */
    compBtn() {
      return (i, tar) => {
        let num = i
        // console.log(tar + num)
        const res = this.itemRes[tar + num]
        if (res) {
          return '完了済'
        } else {
          return '完了'
        }
      }
    },

    /**
     * プロセス評価の入力の可否
     */
    setDisableReason() {
      return (num, tar) => {
        if (this.columns && this.columns.length) {

          // 完了済み
          let tar2 = ''
          if (tar) {
            tar2 = 'process' + tar + num
          } else {
            tar2 = 'policy' + num
          }
          if (this.itemRes[tar2]) {
            return '完了済み'
          }

          // 上司の場合、部下が未完了であれば入力不可
          if (this.boss && this.target) {
            // 未来
            if (this.target['pb' + num] == 2) {
              return '-'
            }
            // 入社前
            if (this.target['pb' + num] == -1) {
              return '入社前、もしくは入社月'
            }
            if (this.target['pb' + num] != -10) {
              return '本人入力が未完了'
            }
          }

          // 反省文提出済みでロック解除されている
          if (this.list['lock' + num] == false) {
            return ''
          }

          // 本人の場合
          if (!this.boss && this.target) {
            // 未来
            if (this.target['ps' + num] == 2) {
              return '-'
            }
            // 入社前
            if (this.target['ps' + num] == -1) {
              return '入社前、もしくは入社月'
            }
          }

          // 今期がいつか取得
          const now = this.getThisPeriod
          // 今月
          const mm = Number(moment().format('M'))

          // 2月、8月以外で表示が今期ではない
          if (mm != 2 && mm != 8) {
            if (this.year != now.year || this.period != now.period) {
              return '-'
            }
          // 2月の場合、年度が1年前かつ後期もしくは今期以外はだめ
          } else if (mm == 2) {
            if (this.year != now.year || this.period != now.period) {
              if (this.year != (now.year - 1) || this.period != 2) {
                return '-'
              }
            }
          // 8月の場合、今年度の前期、もしくは今期以外はダメ
          } else if (mm == 8) {
            if (this.year != now.year || this.period != now.period) {
              if (this.year != now.year || this.period != 1) {
                return '-'
              }
            }
          }

          // 対象の期を表示している場合、当月はOK、先月分は15日までしか編集できない
          // 本日の日にち
          const dd = Number(moment().format('D'))
          // 何月の値か
          const month = Number(this.columns[num].val.replace('月', ''))
          // 先月
          let lastM = mm - 1
          if (lastM == 0) {
            lastM = 12
          }

          // 15日まで
          if (dd <= 15) {
            // 先月でも今月でもない
            if (month != lastM && month != mm) {
              return '-'
            }
          // 16日以降
          } else {
            // 先月
            if (month == lastM) {
              return '15日を過ぎている'
            }
            // 今月以外
            if (month != mm) {
              return '-'
            }
          }
        }

        // 未完了の部下がいる場合、入力不可
        if (this.notComp && this.notComp.length) {
          for (let ni = 0; ni < this.notComp.length; ni++) {
            const n = this.notComp[ni]
            if (!n['comp' + num]) {
              return this.userName + 'さんの部下の中に未完了者がいる'
            }
          }
        }
        return ''
      }
    },

    /**
     * プロセス評価の平均
     */
    getAve() {
      return (row, tar) => {
        let num = 0
        let total = 0
        for (let i = 1; i <= 6; i++) {
          const t = tar + i
          if (row[t] && !isNaN(row[t])) {
            num++
            total += Number(row[t])
          }
        }
        if (num && total) {
          // 少数第2位まで出す
          const val = Math.floor(BigNumber(total).div(num).times(100))
          return BigNumber(val).div(100)
        }
        return 0
      }
    },

    /**
     * 月ごとのプロセス評価合計
     */
    totalProcessMonth() {
      return (tar, num) => {
        let val = 0
        if (this.list.item) {
          for (let i = 0; i < this.list.item.length; i++) {
            const e = this.list.item[i]
            if (e[tar + num]) {
              val += Number(e[tar + num])
            }
          }
        }
        return val
      }
    },

    /**
     * プロセス評価上司平均合計×10
     */
    getTotalProcess() {
      let val = 0
      if (this.list.item) {
        for (let i = 0; i < this.list.item.length; i++) {
          const e = this.list.item[i]
          val = BigNumber(val).plus(this.getAve(e, 'valueBoss'))
        }
        val = BigNumber(val).times(10)
      }
      return val
    },

    /**
     * 方針共有の合計
     */
    getTotal() {
      return (row) => {
        if (row) {
          let res = 0
          for (let j = 1; j <= 6; j++) {
            const tar = 'value' + j
            if (row[tar] && !isNaN(row[tar])) {
              res += Number(row[tar])
            }
          }
          return res
        } else {
          return 0
        }
      }
    },

    /**
     * 方針共有の達成率＝点数
     */
    getPolicyPoint() {
      return (row) => {
        if (row) {
          if (row.sortNo == 2) {
            if (isNaN(this.goalThanks)) {
              return 0
            }
          // 目標が「出勤日数」以外で、なし、もしくは文字列の場合は0
          } else if (row.goal != '出勤日数' && (!row.goal || isNaN(row.goal))) {
            return 0
          }
          let val = this.getTotal(row)
          if (!val) {
            return 0
          }

          let res = 0
          if (row.sortNo == 2) {
            const goal = Number(this.goalThanks)
            // サンクスカードは月ごとに達成率を出し、その合計÷6ヶ月が総合達成率となる
            for (let i = 1; i <= 6; i++) {
              const point = row['value' + i]
              if (point && point != '0') {
                let res2 = Number(point) * 100 / goal
                // 100％を超えたら、100にする
                if (res2 >= 100) {
                  res2 = 100
                }
                res = BigNumber(res2).plus(res)
              }
            }
            res = BigNumber(res).div(6)

          // 目標が出勤日数の場合
          } else if (row.goal == '出勤日数') {
            const workDays = this.list.item2.find((e) => {
              return e.item == '出勤日数'
            })

            // 月ごとに達成率を出し、その合計÷6ヶ月が総合達成率となる
            for (let i = 1; i <= 6; i++) {
              // 対象の数字
              const point = row['value' + i]
              // 同月の出勤日数（目標）
              let goal = 0
              if (workDays && workDays['value' + i] && Number(workDays['value' + i]) && !isNaN(workDays['value' + i])) {
                goal = Number(workDays['value' + i])
              }
              if (point && point != '0' && goal) {
                let res2 = Number(point) * 100 / goal
                // 100％を超えたら、100にする
                if (res2 >= 100) {
                  res2 = 100
                }
                res = BigNumber(res2).plus(res)
              }
            }
            res = BigNumber(res).div(6)
          } else {
            res = val * 100 / Number(row.goal)
          }
          // 少数以下、切り捨て
          res = Math.floor(res)
          // 100以上は100にする
          if (res >= 100) {
            res = 100
          }
          
          return res
        }
        return 0
      }
    },

    /**
     * サンクスカードの目標
     */
    goalThanks() {
      if (this.list.groupNum) {
        if (Number(this.list.groupNum) >= 3) {
          return '20'
        } else {
          return '10'
        }
      }
      return '10or20'
    },

    /**
     * 有休の合計
     */
    totalHoliday() {
      let res = 0
      if (this.list.item2) {
        for (let i = 0; i < this.list.item2.length; i++) {
          const it = this.list.item2[i]
          if (it.item.indexOf('有給日数') > -1) {
            for (let j = 1; j <= 6; j++) {
              if (it['value' + j] && !isNaN(it['value' + j])) {
                res += Number(it['value' + j])
              }
            }
            break
          }
        }
      }
      return res
    },

    /**
     * 今期がいつか取得
     */
    getThisPeriod() {
      let yy = Number(moment().format('YYYY'))
      const mm = Number(moment().format('M'))
      let period
      if (mm >= 2 && mm <= 7) {
        period = 1
      } else {
        period = 2
      }
      if (mm == 1) {
        yy--
      }

      // 期首月のみ、15日以前なら前の期を表示
      if (mm == 2 || mm == 8) {
        const dd = Number(moment().format('D'))
        if (dd <= 15) {
          if (mm == 2) {
            yy--
            period = 2
          } else {
            period = 1
          }
        }
      }
      return { year: yy, period }
    },

    /**
     * 行の数
     */
    countRowNum() {
      return (val) => {  
        if (val) {
          const c = (val.match(/\n/g) || []).length
          if (c) {
            return c + 1
          }
        }
        return 2
      }
    },
  },

  methods: {
    /**
     * データ取得
     */
    async getData() {
      this.loading = true
      window.scroll({ top: 0 })
      this.list = utils.clone(ROW)
      this.bossName = ''
      this.bossList = []
      this.bossList2 = []
      let periodId = null
      let startDay = ''
      let endDay = ''
      let res

      // 編集の場合
      if (this.$route.query.id) {
        if (!this.target || !this.target.year) {
          // 一覧に遷移する
          this.$router.push({ name: 'EvaluationList' })
          return
        }
        periodId = this.$route.query.id
        // 評価対象者
        this.userName = this.target.userName
        this.userId = this.target.userId
        // 年度
        this.year = this.target.year
        // 期
        this.period = this.target.period
        this.setColumn()
        // 部下の部下に未完了者がいる場合
        if (this.target.notComp && this.target.notComp.length) {
          this.notComp = this.target.notComp
        }

      // 新規
      } else {
        // 基本情報の期
        this.period = this.getThisPeriod.period
        // 基本情報の年度
        this.year = this.getThisPeriod.year
        this.setColumn()
        // 評価対象者
        this.userName = utils.deleteKana(this.$store.getters.user.user_name)
        this.userId = this.$store.getters.user.id
        // 上司フラグ解除
        this.boss = false
      }

      startDay = this.year + '-' + ('00' + this.columns[1].val.replace('月', '')).slice(-2) + '-01'
      const endM = ('00' + this.columns[6].val.replace('月', '')).slice(-2)
      if (endM == '01') {
        endDay = (this.year + 1) + '-' + endM + '-01'
      } else {
        endDay = this.year + '-' + endM + '-01'
      }

      res = await backend.searchData('evaluation/getData', { periodId, year: this.year, period: this.period, userName: this.userName, userId: this.userId, startDay, endDay, admin: this.admin })
      if (res.data.data) {
        this.list = res.data.data

        // 期ごとのデータが未登録の場合
        if (!this.list.id) {
          // 所属部署を初期値で入れる
          const userInfo = this.$store.getters.loginUserData
          this.list.departmentId = userInfo.departmentId
          this.list.departmentName = userInfo.departmentName
          if (!this.list.departmentName) {
            this.list.departmentName = ''
            this.list.departmentId = '0'
          }
          // グループ番号を入れる
          if (res.data.groupNum) {
            this.list.groupNum = res.data.groupNum
          }
          this.saveInfo('departmentId')
        } else {
          for (let i = 1; i <= 6; i++) {
            this.itemRes['processSelf' + i] = this.list['processSelf' + i]
            this.itemRes['processBoss' + i] = this.list['processBoss' + i]
          }
        }
      }
      if (res.data.boss) {
        // 評価対象者の上司データ
        this.bossName = res.data.boss.bossName
        // 上司リスト
        this.bossList = res.data.boss.bossList

        // 休職中などで登録していた上司がいない場合
        if (this.bossList.length && this.bossName) {
          let ok = false
          for (let i = 0; i < this.bossList.length; i++) {
            const e = this.bossList[i]
            if (this.bossName == e.name) {
              ok = true
              break
            }
          }
          if (!ok) {
            this.bossName = '削除'
          }
        }
        this.setBossList()
      }
      await this.setNowDepartment()

      // 今期を表示している場合
      let now = this.getThisPeriod
      if (now.period == this.period && now.year == this.year) {
        // グループが変更している場合
        if (res.data.groupNum && res.data.groupNum != this.list.groupNum) {
          this.list.groupNum = res.data.groupNum
          this.saveInfo('groupNum')
        }
      }
      if (this.boss) {
        
        // 自分の分なら上司フラグ解除
        if (this.userName == this.loginName || this.$store.getters.user.id == this.userId) {
          this.boss = false
        }

        // 上司の場合
        if (this.boss) {
          if (this.bossName) {
            // 直属の上司しか編集できない
            if (this.loginName != this.bossName) {
              this.notEdit = true
            }
          }
        }
      }

      if (!this.boss) {
        // 本人しか見ることはできない
        if (this.loginName != this.userName) {
          if (this.admin) {
            this.notEdit = true
          } else {
            alert(this.userName + ' さん本人しか見ることができません。\r\nメニュー画面に遷移します。')
            // 一覧に遷移する
            this.$router.push({ name: 'EvaluationList' })
          }
        } else {
          this.uploadFlg = true
        }
      }
      
      this.loading = false
    },

    /**
     * カラムを生成
     */
    setColumn() {
      // 上半期
      if (this.period == 1) {
        for (let i = 0; i < 6; i++) {
          this.columns.push({ val: (2 + i) + '月' })
          this.columns1.push({ val: '自己' })
          this.columns1.push({ val: '上司' })
          this.columns2.push({ val: (2 + i) + '月' })
        }
      // 下半期
      } else {
        for (let i = 0; i < 5; i++) {
          this.columns.push({ val: (8 + i) + '月' })
          this.columns1.push({ val: '自己' })
          this.columns1.push({ val: '上司' })
          this.columns2.push({ val: (8 + i) + '月' })
        }
        this.columns.push({ val: '1月' })
        this.columns1.push({ val: '自己' })
        this.columns1.push({ val: '上司' })

        this.columns2.push({ val: '1月' })
      }
      this.columns1.push({ val: '自己' })
      this.columns1.push({ val: '上司' })
      this.columns2.push({ val: '合計' })
      this.columns2.push({ val: '点数' })
    },
    
    /**
     * 基本情報変更イベント
     * @param tar 対象プロパティ名
     */
    async saveInfo(tar) {
      if (tar == 'grade' && !this.list.groupNum) {
        this.list.groupNum = this.list.grade
        this.saveInfo('groupNum')
      }
      const saveData = this.commonSaveData()
      const list = utils.clone(this.list)
      delete list.item
      delete list.item2

      if (tar == 'groupNum') {
        this.setBossList()
      }

      try {
        const res = await backend.postData('evaluation/saveEvaluationPeriod', { ...saveData, ...list, tar })
        if (!this.list.id) {
          if (res.data.data) {
            this.list.id = res.data.data
          }
        }
      } catch (error) {
        alert('エラーが発生しました\r\n' + error)
      }
      this.reload++
    },

    /**
     * 早めに記入してほしい箇所を警告
     */
    isAlert() {
      const l = this.list
      // 本人入力の場合
      if (!this.boss) {
        const check = [
          { name: '入社日', tar: l.startDate, msg: '入力' },
          { name: '部署', tar: l.departmentId, msg: '選択' },
          { name: '等級', tar: l.grade, msg: '選択' },
          { name: 'グループ', tar: l.groupNum, msg: '選択' }
        ]
        for (let i = 0; i < check.length; i++) {
          const c = check[i]
          if (!c.tar) {
            alert(`${c.name}が未${c.msg}です。${c.msg}してください。`)
            return
          }
        }
      }
    },

    /**
     * プロセス評価保存
     * @param row 保存対象の行
     * @param i 保存対象の列番号
     */
    async saveProcess(e, row, i) {
      const isDisable = this.isDisable(i)
      if (isDisable) {
        if (this.boss) {
          row['valueBoss' + i] = null
        } else {
          row['valueSelf' + i] = null
        }
        return
      }

      this.isAlert()
      if (!e || e == 0 || e > 5) {
        alert('不正な値です。プロセス評価は１～５で判定してください。')
        if (this.boss) {
          row['valueBoss' + i] = null
        } else {
          row['valueSelf' + i] = null
        }
        return
      }
      const saveData = this.commonSaveData('item', row, i)
      if (this.boss) {
        saveData.boss = this.boss
        saveData.bossName = saveData.insertUserName
      }
      try {
        const res = await backend.postData('evaluation/saveProcess', saveData)
        if (res.data.data) {
          if (res.data.data.periodId && !this.list.id) {
            this.list.id = res.data.data.periodId
          }
        }
        this.uploadFlg = false
      } catch (error) {
        alert('エラーが発生しました\r\n' + error)
      }
    },

    /**
     * 方針共有点保存
     * @param row 保存対象の行
     * @param i 保存対象の列番号
     */
    async savePolicy(row, i) {
      this.isAlert()
      const saveData = this.commonSaveData('item2', row, i)

      try {
        const res = await backend.postData('evaluation/savePolicy', saveData)
        if (res.data.data) {
          if (res.data.data.periodId && !this.list.id) {
            this.list.is = res.data.data.periodId
          }
        }
      } catch (error) {
        alert('エラーが発生しました\r\n' + error)
      }
    },

    /**
     * 保存時の共通項目
     * @param type 保存対象の種類（プロセス評価：item、方針共有：item2、他はnull）
     * @param row 保存対象の行
     * @param i 保存対象の列番号
     */
    commonSaveData(type, row, i) {
      // 期ごとのデータID
      let periodId = this.list.id
      // 年度
      let year = this.year
      // 月
      let month
      // 対象日（年度ではなく、当年。日付は1日で統一）
      let day
      // プロセス評価、方針共有の保存対象の値
      let value
      // 該当月が全て完了した場合、そのプロパティー名
      // let target = { tar: '', res: true  }
      // ソート番号
      let sortNo
      // サブソート番号
      let subSortNo

      const saveData = utils.clone(this.list)
      delete saveData.item1
      delete saveData.item2

      // プロセス評価、方針共有の場合
      if (type) {
        // 上期の場合
        let num = 1
        // 下期の場合
        if (this.period == 2) {
          num = 7
        }
        num += i
        // 13の場合
        if (num == 13) {
          // 1月にする
          num = 1
        }

        // 月をゼロパディング表記
        month = ('00' + num).slice(-2)

        // 1月の場合、年を当年にするためプラス1
        if (num == 1) {
          day = (year + 1) + '-' + month + '-01'
        } else {
          day = year + '-' + month + '-01'
        }

        sortNo = row.sortNo
        subSortNo = row.subSortNo

        // プロセス評価の場合
        if (type == 'item') {
          // 自己評価の場合
          if (!this.boss) {
            // 保存する値
            value = row['valueSelf' + i]
            // 該当月が全て環境したか判定
            // target = this.isComplete(type, 'valueSelf', i, 'process_self')

          // 上司入力の場合
          } else {
            // 保存する値
            value = row['valueBoss' + i]
            // 該当月が全て環境したか判定
            // target = this.isComplete(type, 'valueBoss', i, 'process_boss')
          }
        } else {
          // 保存する値
          value = row['value' + i]
          // 該当月が全て環境したか判定
          // target = this.isComplete(type, 'value', i, 'policy')
        }
      }

      return {
        year,
        period: this.period,
        periodId,
        insertUserName: utils.deleteKana(this.$store.getters.user.user_name),
        insertUser: this.$store.getters.user.id,
        userName: this.userName,
        userId: this.userId,
        day,
        sortNo,
        subSortNo,
        value
      }
    },

    /**
     * 備考を保存
     */
    saveNote() {
      if (!this.note) {
        return
      }
      if (!this.list.note || !this.list.note.length) {
        this.list.note = []
      }
      // this.list.note.push(this.note + ' ' + moment().format('YYYY/MM/DD HH:mm:ss') + ' （' + utils.deleteKana(this.$store.getters.user.user_name) + '）')
      // this.list.note.push(this.note + ' （' + moment().format('YYYY/MM/DD HH:mm:ss') + ' ' + utils.deleteKana(this.$store.getters.user.user_name) + '）')
      this.list.note.push(this.note + ' （' + utils.deleteKana(this.$store.getters.user.user_name) + '） ' + moment().format('YYYY/MM/DD HH:mm:ss'))
      this.saveInfo('note')
      this.note = ''
    },

    /**
     * 完了保存
     * @param i カラムインデックス
     * @param tar 対象プロパティ名
     */
    async saveComp(i, tar) {
      let num = i

      let name = 'プロセス評価'

      // 同月全て入力済みか確認
      let res = true
      let sortNo = null

      const isComplete = this.itemRes[tar + num]
      // すでに完了している
      if (isComplete) {
        return
      }

      let valueName = 'valueSelf'
      if (this.boss) {
        valueName = 'valueBoss'
      }

      for (let j = 0; j < this.list.item.length; j++) {
        const it = this.list.item[j]
        if (!it[valueName + num]) {
          res = false
          sortNo = j + 1
          break
        }
      }

      if (!res) {
        await this.showErr('未入力あり', '完了できません。\r\n' + sortNo + '番目の項目が未入力です。')
        return
      } else {
        // 上司の確認
        if (!this.bossName || this.bossName == '削除') {
          await this.showErr('完了できません', '完了できません。\r\n' + '上司が未選択です。上司を選択して再度「完了」を押してください。')
          return
        }

        // 本人の場合
        if (!this.boss) {
          if (!await this.showConfirm('確認', '上司は' + this.bossName + ' さんでよろしいでしょうか？\r\n' + name + 'の再編集はできなくなります。完了してよろしいでしょうか？\r\nまた、完了後に上司に面談を依頼してください。\r\n15日までに面談を行わないと共有方針点の「上司との面談」が「1」になりません。')) {
            return
          }
        // 上司の場合
        } else {
          if (!await this.showConfirm('確認', '完了後5分以内に部下が「確認ボタン」を押さなければなりません。\r\n5分以内に「確認ボタン」が押せないと、方針共有点の「上司との面談」が「1」になりません。\r\nまた、' + name + 'の再編集はできなくなります。\r\n完了してよろしいでしょうか？')) {
            return
          }
        }
      }

      const tar2 = tar + num
      this.itemRes[tar2] = true
      this.list[tar2] = true
      this.reload++

      const saveData = this.commonSaveData()
      const list = utils.clone(this.list)
      delete list.item
      delete list.item2

      const monthNum = num
      
      await backend.postData('evaluation/saveEvaluationPeriod', { ...saveData, ...list, tar: tar2, monthNum })
    },

    /**
     * 本人確認ボタン（上司の評価を確認）
     */
    async processConfirm(num) {
      // すでに完了している
      if (this.list['processSelfCheck' + num]) {
        return
      }
      const saveData = this.commonSaveData()
      const list = utils.clone(this.list)
      delete list.item
      delete list.item2

      list['processSelfCheck' + num] = true
      const tar = 'processSelfCheck' + num
      const monthNum = num
      
      const res = await backend.postData('evaluation/saveEvaluationPeriod', { ...saveData, ...list, tar, monthNum })
      // 更新不可
      if (res.data.data.upDateErr) {
        alert(res.data.data.msg)
        this.list['processSelfCheck' + num] = false
        return
      } else {
        this.list['processSelfCheck' + num] = true
      }

      this.uploadFlg = false

      // 方針共有点の「上司との面談」を更新
      const poli = this.list.item2.find((it) => {
        return it.item == '上司との面談'
      })
      poli['value' + num] = 1
      await this.savePolicy(poli, num)
    },

    /**
     * プロセス評価最新値取得
     */
    async uploadProcess() {
      if (this.boss || !this.uploadFlg) {
        return
      }
      // 期ごとのデータID
      let periodId = this.list.id
      let year = this.year
      let period = this.period

      let startDay = ''
      let endDay = ''

      startDay = this.year + '-' + ('00' + this.columns[1].val.replace('月', '')).slice(-2) + '-01'
      const endM = ('00' + this.columns[6].val.replace('月', '')).slice(-2)
      if (endM == '01') {
        endDay = (this.year + 1) + '-' + endM + '-01'
      } else {
        endDay = this.year + '-' + endM + '-01'
      }
      const res = await backend.searchData('evaluation/getProcess', { periodId, year, period, startDay, endDay, userId: this.userId, userName: this.userName })
      if (res.data.data) {
        this.list.item = res.data.data.item

        const month = [1, 2, 3, 4, 5, 6]
        const info = res.data.data.info
        for (let i = 0; i < month.length; i++) {
          const m = month[i]
          this.list['processBoss' + m] = info['processBoss' + m]

          // 上司完了で、本人確認が未完了の場合のみ
          if (info['processBoss' + m] && !info['processSelfCheck' + m]) {
            this.list['processBossTime' + m] = info['processBossTime' + m]
            // 上司完了から5分後の時間
            const limit = moment(info['processBossTime' + m]).add(5, 'minutes')
            // 現在
            const ima = moment()
            // 5分以内の場合のみ
            if (ima.isSameOrBefore(limit, 'minute')) {
              const msg = moment(info['processBossTime' + m]).add(5, 'minutes').format('HH時mm分') + 'までに'
              
              if (!this.list['processBossTimeSt' + m]) {
                alert(msg + '「上司評価 確認ボタン」を押してください。')
              }
              // 表示用
              this.list['processBossTimeSt' + m] = msg
            }
          }
        }
      }
    },

    /**
     * エラーメッセージ表示
     * @param title ダイアログのタイトル
     * @param msg メッセージ
     */
    async showErr(title, msg) {
      // スマホ、タブレットフラグ
      let mb = false
      if (this.isMobileDevice || this.isTabletDevice) {
        mb = true
      }

      // スマホ、タブレットの場合は、アラート表示
      if (mb) {
        alert(msg)
      } else {
        await dialogs.showErrorDialog(title, msg)
      }
    },

    /**
     * 確認メッセージ表示
     * @param title ダイアログのタイトル
     * @param msg メッセージ
     */
    async showConfirm(title, msg) {
      // スマホ、タブレットフラグ
      let mb = false
      if (this.isMobileDevice || this.isTabletDevice) {
        mb = true
      }

      let res = true

      // スマホ、タブレットの場合は、アラート表示
      if (mb) {
        res = window.confirm(msg)
      } else {
        const log = await dialogs.showWarningConfirmDialog(title, msg)
        if (log == 'YES') {
          res = true
        } else {
          res = false
        }
      }
      return res
    },

    /**
     * 部署選択イベント
     */
    changeDepartment() {
      const id = this.departmentList.find((d) => {
        return this.list.departmentName == d.name
      }).departmentId
      this.list.departmentId = id
      this.saveInfo('departmentId')
    },

    /**
     * 現在の部署判定
     */
    async setNowDepartment() {
      if (this.boss) {
        return
      }
      // 評価対象者がログインユーザーの場合のみ
      const name = utils.deleteKana(this.$store.getters.user.user_name)
      if (this.userName != name) {
        return
      }
      // 本日の年度、期が同じ場合のみ
      let yy = Number(moment().format('YYYY'))
      const mm = Number(moment().format('M'))
      let period = null
      if (mm == 1) {
        yy--
        period = 2
      } else {
        if (mm <= 7) {
          period = 1
        } else {
          period = 2
        }
      }
      if (yy != this.year || period != this.period) {
        return
      }

      // ユーザーの現在の所属部署
      const userInfo = this.$store.getters.loginUserData
      const depName = userInfo.departmentName

      // 編集の場合
      if (this.list.id) {
        if (this.list.departmentName) {
          if (this.list.departmentName != depName) {
            const res = await dialogs.showConfirmDialog('部署が変わりましたか？', depName + 'に変わったのであれば、登録している『部署名』を変更します。')
            if (res == 'YES') {
              this.list.departmentName = depName
              this.changeDepartment()
            }
          }
        } else {
          this.list.departmentName = depName
          this.changeDepartment()
        }
      }
    },

    /**
     * 上司更新
     */
    async saveBoss() {
      await backend.postData('evaluation/saveOrganization', { boss: this.bossName, name: this.userName, userId: this.$store.getters.user.id })
    },

    /**
     * 入力不可の理由をアラートで表示
     * @param num インデックス
     */
    showAlert(num) {
      let res = this.isDisable(num)
      if (res && res != '-') {
        alert(res)
      }
    },

    /**
     * プロセス評価入力可能判定
     */
    isDisable(num) {
      let res = ''
      if (this.boss) {
        res = this.setDisableReason(num, 'Boss')
      } else {
        res = this.setDisableReason(num, 'Self')
      }
      if (res && res != '-') {
        res = res + 'のため、入力できません。'
        if (res.indexOf('15日を過ぎている') > -1) {
          res += '\r\n反省文を本人、上司ともに提出してください。'
        } else if (res.indexOf('面談未完了者がいる') > -1) {
          res += '\r\n諸事情により面談不可能な部下がいる場合は、一覧画面の「解除」ボタンでその部下の面談を免除できます。'
        }
      }
      return res
    },

		/**
		 * 対象者よりも上のグループの上司に絞り込む
		 */
		setBossList() {
			if (this.list.groupNum) {
				this.bossList2 = this.bossList.filter((b) => {
					return (!b.groupNum || Number(this.list.groupNum) < b.groupNum) && b.name != this.userName
				})
        if (!this.bossList2) {
          this.bossList2 = []
          this.bossName = '削除'
        } else {
          const res = this.bossList2.find((b) => {
            return b.name == this.bossName
          })
          if (!res || !res.name) {
            this.bossName = '削除'
          }
        }
			} else {
				this.bossList2 = []
        this.bossName = '削除'
			}
      console.log('どうですか？？')
      console.log(this.bossName)
		},
  }
}
</script>

<style scoped>

.input-form {
  min-width: 760px;
  max-width: 1310px;
  height: calc(100vh - 117px);
}

td {
  height: 60px;
}

.hover-color:hover {
  background-color: rgb(228, 250, 253);
}

.hover-color2:hover {
  background-color: rgba(255, 238, 240);
}

.select-disp {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white;
}

.bg-bl {
  background: rgb(231, 247, 250);
}

.bg-bl2 {
  background: rgb(247, 250, 249);
}

.bg-gr {
  background: #e1f5e17a;
}

.process { 
  max-width: 100%;
}

.last-center {
  text-align-last: center;
}

</style>