<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div class="overflow-hidden rounded main-height">
      <div class="main-height overflow-auto">
        <div class="flex justify-between">
          <div class="font-bold title mb-3">
            環境整備 ChatworkルームID 設定
          </div>
          <div class="py-1 px-2 mt-auto cursor-pointer" @click="add()">
            <Icon
              iconName="PlusCircle"
              class="w-8 h-8 text-blue-800"
            />
          </div>
        </div>
        <!-- 切り替え -->
        <div class="flex justify-center p-2 my-2">
          <PrimaryButton text="毎日" size="normal" class="w-full mx-1" :class="type=='day'?'':'bg-gray-400'" @click="changeType('day')" />
          <PrimaryButton text="月１" size="normal" class="w-full mx-1" :class="type=='month'?'':'bg-gray-400'" @click="changeType('month')" />
        </div>
        <table class="overflow-auto main table-auto mb-3">
          <thead class="main sticky top-0 bg-white">
            <tr v-if="list && list.length" class="border border-gray-200">
              <th v-for="(c, index) in columns" :key="index" scope="col" class="pl-4 pr-1 py-3 text-xs font-medium text-gray-500 tracking-wider bg-colu text-left" :class="c.class">
                {{c.title}}
              </th>
            </tr>
          </thead>

          <tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider hover-color">

            <!-- 部署名 -->
            <td class="pl-3 pr-1 py-0.5 text-sm text-gray-500">
              <select
                name="department"
                v-model="row.departmentId"
                class="focus:ring-indigo-500 focus:border-indigo-500 block border-gray-300 w-full text-sm rounded"
                @change="edit(row, 'department')"
              >
                <option
                  v-for="c in departmentList" :key='c.id'
                  :value="c.departmentId"
                  class="bg-white">
                  {{ c.name }}
                </option>
              </select>
            </td>

            <!-- ルームID -->
            <td class="pl-3 pr-1 py-0.5 text-gray-500">
              <input
                type="number"
                class="border border-gray-300 rounded-md focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-32 py-1.5"
                v-model="row.roomId"
                @input="edit(row)"
              />
            </td>

            <!-- トークン -->
            <td class="pl-3 pr-1 py-0.5 text-gray-500">
              <input
                type="text"
                class="border border-gray-300 rounded-md focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 text-xs w-60 py-2.5" 
                v-model="row.apiToken"
                @input="edit(row)"
              />
            </td>

            <td class="px-1 py-0.5 text-center text-sm font-medium border-gray">
              <div class="flex justify-center">
                <PrimaryButton text="保存" size="normal" class="w-16 p-1" :disabled="!row.editFlg" :class="!row.editFlg?'bg-gray-300':'bg-blue-800'" @click="save(row)" />
                <PrimaryButton text="削除" size="normal" class="ml-2 w-16 p-1 bg-red-700" @click="remove(row, i)" />
              </div>
            </td>
          </tr>
        </table>

      </div>
      <div v-if="!loading && (!list || !list.length)" class="flex justify-center items-center text-gray-500 font-bold nothing">
        <p class="text-gray-400 font-bold">対象データがありません</p>
      </div>
      <div class="text-transparent">{{reload}}</div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import Icon from '@components/Icon.vue'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import * as backend from '@libs/backend'

const ROWS = { editFlg: false, departmentId: '', roomId: null, apiToken: null }

export default {
  components: {
    Modal,
    PrimaryButton,
    Icon
  },

  props: ['open'],

  data() {
    return {
      // ローディング
      loading: false,
      // カラム
      columns: [
        {title: '所属部署名'},
        {title: 'ルームID'},
        {title: 'APIトークン'},
        {title: ''},
      ],
      // リスト
      list: [utils.clone(ROWS)],
      // 部署名リスト
      departmentList: [],
      // 毎日か月1か
      type: 'day',
      reload: 0
    }
  },

  computed: {
  },

  watch: {
    open() {
      if (this.open) {
        this.getData()
      }
    }
  },

  methods: {
    /**
     * 初期にリセット
     */
    closed() {
      // リスト
      this.list = [utils.clone(ROWS)]
      this.departmentList = []
      this.loading = false
      this.$emit('close')
    },

    /**
     * リスト取得
     */
    async getData() {
      this.loading = true
      // chatworkルームIDデータ取得
      const res = await backend.searchData('photoReport/getChatwork', { type: this.type })
      if (res.data.data && res.data.data.length) {
        this.list = res.data.data
      } else {
        this.list = []
      }
      // 部署データ取得
      this.departmentList = this.$store.getters.departmentList
      // console.log(this.departmentList)
      this.loading = false
    },

    /**
     * 切り替えイベント
     * @param val 切り替え値
     */
    changeType(val) {
      this.type = val
      this.getData()
    },

    /**
     * 編集ボタンイベント
     * @param row 編集対象データ
     */
    edit(row, item) {
      // 部署選択の場合
      if (item == 'department') {
        const res = this.departmentList.find((d) => {
          return row.departmentId == d.departmentId
        })
        row.departmentName = res.name
      }
      row.editFlg = true
      this.reload++
    },

    /**
     * 保存
     * @param row 対象データ
     */
    async save(row) {
      if (!row.departmentId) {
        await dialogs.showErrorDialog('部署', '未選択です。部署を選択してください。')
        return
      }
      if (!row.roomId) {
        await dialogs.showErrorDialog('ルームID', '未入力です。ルームIDを入力してください。')
        return
      }

      // ダブりがないかチェック
      const double = this.list.filter((l) => {
        return l.departmentId == row.departmentId
      })
      // 2件以上あればNG
      if (double.length > 1) {
        await dialogs.showErrorDialog('重複のため保存不可', 'すでに同じ部署のデータがあります。')
        return
      }

      // 同じルームIDがないかチェック
      const room = this.list.filter((r) => {
        return r.roomId == row.roomId
      })
      // 2件以上あればNG
      if (room.length > 1) {
        const dia = await dialogs.showConfirmDialog('ルームID重複あり', 'すでに同じルームIDが登録されていますが、よろしいでしょうか？')
        if (dia != 'YES') {
          return
        }
      }
      const saveData = utils.clone(row)
      saveData.user = this.$store.getters.user.id
      saveData.type = this.type
      delete saveData.editFlg

      const res = await backend.postData('photoReport/saveChatwork', saveData)
      if (res.status == 200) {
        await dialogs.showSuccessDialog('保存完了', 'データ保存しました。')
        row.editFlg = false
        this.getData()
        // ログ登録
        let newFlag = true
        if (row.id) {
          newFlag = false
        }
        let name = '環境整備（毎日）：'
        if (this.type == 'month') {
          name = '環境整備（月１）：'
        }
        logManager.recordingByRegist(this, newFlag, '環境整備Chatwork', `${name}${row.departmentName} のルームID`)
      }
    },

    /**
     * 削除
     * @param row 対象データ
     * @param i インデックス
     */
    async remove(row, i) {
      if (row.id) {
        const res = await dialogs.showConfirmDialog('削除してよろしいですか？', `${row.departmentName} を削除します。`)
        if (res != 'YES') {
          return
        }
        await backend.postData('photoReport/deleteChatwork', { id: row.id })
        await dialogs.showSuccessDialog('削除完了', '削除しました。')
        // ログ登録
          logManager.recording(this, logManager.Loglevel.INFO, '写真報告書用ChatworkルームID', '削除', `${row.departmentName} を削除しました。`)
      }
      this.list.splice(i, 1)
      this.getData()
    },

    /**
     * 追加ボタン
     */
    add() {
      let row = utils.clone(ROWS)
      row.apiToken = '12e6247b22aa5826875a69997587ea01'
      this.list.push(row)
    }
  }
}
</script>
<style scoped>
.main-height {
  height: 85vh;
}

.h-95vh {
  height: 95vh;
}

.h-93vh {
  height: 93vh;
}

.main {
  min-width: 700px;
}

.b-top {
  border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
  background: #edecf7;
}

.hover-color:hover {
  background: #edecf7 !important;
}

.title {
  color: #928ec7;
}
</style>