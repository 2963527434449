<template>
  <div>
    <DownloadComponent
      typeName='品質巡回'
      :type=1
    >
    </DownloadComponent>
  </div>
</template>

<script>
import DownloadComponent from '../components/DownloadComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    DownloadComponent,
  },
}
</script>