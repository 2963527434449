<template>
  <div>
    <EvaluationInputComponent
      :target="target"
    />
  </div>
</template>
<script>

import EvaluationInputComponent from '../components/EvaluationInputComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    EvaluationInputComponent
  },

  props: ['target'],

  data() {
    return {
    }
  },

  created() {
  },
}
</script>

<style scoped>
</style>