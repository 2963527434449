<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed()">
      <template #action>
        <div class="content-container">
          <h3 class="title">{{removeNewLine(categoryName)}}</h3>

          <div v-if="memo!=''" class="memo font-medium text-gray-500 mb-3">{{memo}}</div>

          <!-- ----------日常、管理、定期----モーダルの切替------ -->
          <div v-if="['3', '4', '5'].includes(categoryCode)" class="tab border-none">
            <div class="inline text-xs text-gray-500 mb-1" v-if="['3', '4'].includes(categoryCode)">清掃時間・清掃回数</div>
            <PlusCircleIcon v-if="['3', '4'].includes(categoryCode)" class="ml-2 inline text-indigo-500 hover:text-indigo-700 active:text-indigo-600 cursor-pointer col-actions mr-auto" @click="addModalTab()"/>

            <div class="flex tab-group">
              <div class="flex items-center mx-1" v-for="(tab, i) in changeModalTab" :key="i" >
                <div>
                  <div
                    class="items-center cursor-pointer tab-w"
                    :class="[tab.active ? 'bg-light-blue-800' : 'hover:bg-light-blue-800', 'bg-gray-300 rounded-md py-2 px-3 text-sm font-medium text-white']" 
                    @click="tabChanged(tab, i)"
                  >
                    {{ tab.name }}
                  </div>
                  <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5 ml-auto" @click="deleteModalTab(i)"/>
                </div>
              </div>
            </div>
            
            <div class="msg mt-2">{{tabMsg}}</div>
          </div>

          <!-- 特別清掃時のみ -->
          <table v-if="categoryCode=='14'" class="border border-gray-200 rounded sp-owner mx-auto">
            <thead>
              <tr>
                <!-- オーナー契約 -->
                <th class="px-10 text-center text-xs font-medium tracking-wider border-gray" :class="setEmptyColorSp(selectedDetails['spOwner'])">
                  {{ spItemName[0] }}
                </th>
                <td class="border-left bg-white text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray">
                  <div class="m-auto flex justify-around">
                    <form class="ml-4">
                      <input
                        type="radio"
                        id="no"
                        value="0"
                        class="mt-3 mb-3 radio-width"
                        v-model="selectedDetails[spItem[0]]"
                      />
                      <label for="no" class="radio-range">なし</label>
                    </form>
                    <form class="mx-4">
                      <input
                        type="radio"
                        id="yes"
                        value="1"
                        class="mt-3 mb-3 radio-width"
                        v-model="selectedDetails[spItem[0]]"
                      />
                      <label for="yes" class="radio-range">あり</label>
                    </form>
                  </div>
                </td>
              </tr>
              <tr>
                <!-- 竣工係数 -->
                <th class="px-10 text-center text-xs font-medium tracking-wider border-gray" :class="setEmptyColorSp(selectedDetails[spItem[1]])">
                  {{ spItemName[1] }}
                </th>
                <td class="border-left bg-white text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray">
                  <div class="m-auto flex justify-around">
                    <NumericTextInput
                      name="spFinishPoint"
                      v-model="selectedDetails[spItem[1]]"
                      class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full input" 
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <!-- 協力費 -->
                <th class="px-10 text-center text-xs font-medium tracking-wider border-gray" :class="setEmptyColorSp(selectedDetails[spItem[2]])">
                  {{ spItemName[2] }}
                </th>
                <td class="border-left bg-white text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray">
                  <div class="m-auto flex justify-around">
                    <NumericTextInput
                      name="spCompe"
                      v-model="selectedDetails[spItem[2]]"
                      class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full input" 
                    />
                  </div>
                </td>
              </tr>
            </thead>
          </table>

          <!-- 項目表 -->
          <table class="table" v-if="categoryCode!='14'">
            <thead>
              <tr v-show="input(item) || radio(item) || dropDown(item)" v-for="(item, index) in displaySubItems" :key="index">
                <th class="text-center text-xs font-medium tracking-wider border-gray table-width1" :class="[setEmptyColor(index), !categoryCode||categoryCode==''?' table-width3':'']">
                  {{ item.name }}
                </th>
                <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height table-width2" :class="!categoryCode||categoryCode==''?' table-width4':''">
                  <!-- 数値入力 -->
                  <input
                    v-if="input(item)"
                    type="number"
                    v-model="displaySubItems[index].value"
                    @change="setItem(item, index, displaySubItems[index].value)"
                    class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full input" 
                  />

                  <!-- ラジオボタン -->
                  <div class="m-auto select-form flex justify-around px-2" v-if="radio(item)">
                    <form v-for="(key, radioIndex) in Object.keys(item.itemCode)" :key='key.id'>
                      <input
                        type="radio"
                        :name="item.item + radioIndex"
                        v-model="displaySubItems[index].value"
                        :id="item.item + radioIndex"
                        :value="key"
                        @change="setItem(item, index, item.itemCode[key])"
                        @blur="checkRadio(null)"
                        class="mt-3 mb-3 radio-width"
                      />
                      <label :for="item.item + radioIndex" class="radio-range">{{ item.itemCode[key] }}</label>
                    </form>
                  </div>

                  <!-- ドロップダウン -->
                  <div class="m-auto select-form" v-if="dropDown(item)">
                    <form>
                      <select
                        :name="item.item"
                        v-model="displaySubItems[index].value"
                        @change="setItem(item, index, item.itemCode)"
                        class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full text-xs drop"
                      >
                        <option
                          v-for="key in Object.keys(item.itemCode)" :key='key.id'
                          :value="key"
                          class="bg-white">
                          {{ item.itemCode[key] }}
                        </option>
                      </select>
                    </form>
                  </div>
                </td>
              </tr>
            </thead>
          </table>

          <!-- 特記事項や契約書で使用する内容 -->
          <div v-if="['3', '4'].includes(categoryCode)">
            <div class="text-xs text-gray-500 remark-msg" v-if="displaySubItems[4]&&displaySubItems[4].value!='0'">特記事項などで使用</div>
            <div class="text-sm text-blue-600 remark-msg" v-if="displaySubItems[4]&&displaySubItems[4].value=='0'">日曜割増料金が含まれる際は日曜を選択してください</div>
            <div v-for="(r , i) in selectedDetails.remarks" :key=i>
              <div v-if="i == 1 && displaySubItems[4] && displaySubItems[4].value == '1' && displaySubItems[8] && displaySubItems[8].value != '1'" class="text-center text-blue-600 text-sm font-bold w-full">------以下は必須ではありません。パターンが違う場合のみご記入ください。------</div>
              <table class="border border-gray-200 rounded remark-table" :class="displaySubItems[4]&&displaySubItems[4].value!='0'?'mb-2':''" v-tooltip="i!=0?'必須ではありません':''">
                <thead v-if="isAllWeek(i)">
                  <th class="b-right-gray px-3 text-center text-xs font-medium tracking-wider min-w-61px" :class="setEmptyColorWeek(i, r)">
                    曜日<span v-if="displaySubItems[4]&&displaySubItems[4].value=='0'"> ※省略可</span>
                  </th>
                  <td class="b-top-gray bg-white text-center text-xs font-medium text-gray-500 tracking-wider py-1 pl-1">
                    <div class="m-auto flex justify-around">
                      <div class="flex items-center">
                        <div v-for="tab in week[i]" :key="tab.id" class="mr-1 mb-auto">
                          <!-- 曜日 -->
                          <div
                            class="items-center mx-auto cursor-pointer bg-gray-300 rounded-md py-2 px-3 text-sm font-medium text-white"
                            :class="tab.active ? 'bg-light-blue-800' : 'hover:bg-gray-400'" 
                            @click="weekChanged(tab, i, true)"
                          >
                            {{ tab.name }}
                          </div>
                          <!-- 曜日の詳細（毎週、隔週など） -->
                          <div v-if="displaySubItems[4]&&displaySubItems[4].value!='0'">
                            <multiselect
                              :disabled="!tab.active"
                              v-model="tab.weekDetail"
                              :options="weekDetailList"
                              :multiple="true"
                              :close-on-select="false"
                              :show-labels="false"
                              :searchable="false"
                              :allow-empty="false"
                              :hide-selected="true"
                              class="multiselect"
                              @input="weekChanged(tab, i, false)"
                            >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </thead>

                <thead v-if="displaySubItems[4]&&displaySubItems[4].value!='0'">
                  <th class="b-top-gray b-right-gray px-2 text-center text-xs font-medium tracking-wider min-w-61px" :class="setEmptyColorTime(i, r['start'], r['end'])">
                    時間帯
                  </th>
                  <td class="b-top-gray bg-white px-3 pb-1 pt-1 text-xs font-medium text-gray-500 tracking-wider flex justify-center">
                    <WmsTimeInput2
                      name="start" 
                      v-model="r.start"
                      :hourItems="hourItems"
                      :minuteItems="minuteItems"
                    />
                    
                    <span class="remark-text">~</span>

                    <WmsTimeInput2
                      name="end" 
                      v-model="r.end"
                      :hourItems="hourItems"
                      :minuteItems="minuteItems"
                    />

                    <!-- 休憩時間 -->
                    <div class="flex ml-6 my-auto justify-end">
                      <div class="my-auto mr-3">（うち休憩</div>
                      <NumericTextInput
                        :name="'breakHour' + i"
                        v-model="r.breakHour"
                        class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded w-11 h-9 text-sm" 
                      />
                      <div class="my-auto ml-1 mr-3">時間</div>
                      <NumericTextInput
                        :name="'breakHour' + i"
                        v-model="r.breakMinutes"
                        class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded w-11 h-9 text-sm" 
                      />
                      <div class="my-auto ml-1">分 含む</div>
                      <div class="my-auto ml-1">）</div>

                      <!-- 実働時間 -->
                      <div class="ml-2 my-auto border bg-blue-100 text-blue-800 font-bold rounded p-1">
                        実働 {{getWorkingTime(i)}} 時間
                      </div>
                      
                      <!-- クリアボタン -->
                      <div class="ml-5 p-2 cursor-pointer justify-end" @click="clearTimes(i)">
                        <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
                      </div>
                    </div>
                  </td>
                </thead>
              </table>
            </div>
            <!-- 年末年始割増設定日 -->
            <div v-if="displaySubItems[18]&&displaySubItems[18].value=='1'">
              <div class="text-sm text-blue-600 remark-msg">年末年始に勤務する日を選択してください</div>
              <table class="border border-gray-200 rounded remark-table">
                <thead>
                  <th class="b-right-gray px-10 text-center text-xs font-medium tracking-wider w-56" :class="setEmptyColorNewYearHoliday(displaySubItems[18])">
                    年末年始勤務日
                  </th>
                  <td class="b-top-gray bg-white text-center px-3 py-3 text-xs font-medium text-gray-500 tracking-wider">
                    <div class="m-auto flex justify-around">
                      <div class="flex items-center">
                        <a v-for="tab in newYearHoliday" 
                          :key="tab.value" 
                          class="items-center mx-1 cursor-pointer w-10"
                          :class="[selectedDetails.newYearHoliday && selectedDetails.newYearHoliday.includes(tab.value)? 'bg-light-blue-800' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-2 px-3 text-sm font-medium text-white']" 
                          @click="holidayChanged(tab.value)"
                        >
                          {{ tab.value }}
                        </a>
                      </div>
                    </div>
                  </td>
                </thead>
              </table>
            </div>
          </div>
      
          <!-- 計算結果を即画面に反映するためだけの変数 -->
          <span class="text-transparent">{{forBind}}</span>

          <!-- 単価取得ボタン -->
          <PrimaryButton v-if="displaySubItems.length" text="単価を取得" size="sm bg-indigo-200 text-indigo-700 hover:bg-indigo-300 hover:text-indigo-900 px-10 py-3 place-items-end block mt-3 btn" @click="getPriceMasterData" />

          <!-- ------------------------------------------------RLS計算式------------------------------------------------ -->
          <div v-if="categoryCode=='1'" class="formula-left">

            <!-- トイレ -->
            <div>
              <div class="cof-price-width text-xs text-gray-500 mb-2">トイレ回数</div>
              <span class="pt-5 number">
                <span v-if="displaySubItems[5]">
                  {{ displaySubItems[5].value }}
                </span>
              </span>
            </div>
            <span class="pt-5">×</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500 mb-2">トイレ箇所</div>
              <span class="pt-5 number">
                <span v-if="displaySubItems[6]">
                  {{ displaySubItems[6].value }}
                </span>
              </span>
            </div>
            <span class="pt-5">×</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">トイレ単価</div>
              <NumericTextInput
                name="toilet"
                v-model="selectedDetails.conf['toilet']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['toiletBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'toilet')"
              />
            </div>
            <span class="pt-5">×</span>
            <div>
              <div class="cof-price-width text-xs text-gray-400 mb-2 font-bold">週/月</div>
              <span class="pt-5 number font-bold">
                4.3
              </span>
            </div>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">トイレ合計</div>
              <NumericTextInput
                name="totalUnitToilet"
                v-model="selectedDetails.price['toilet']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
              />
            </div>
          </div>

          <!-- メーター -->
          <div v-if="categoryCode=='1'" class="formula-left">
            <div>
              <div class="cof-price-width text-xs text-gray-500 mb-2">メーター回数</div>
              <span class="pt-5 number">
                <span v-if="displaySubItems[7]">
                  {{ displaySubItems[7].value }}
                </span>
              </span>
            </div>
            <span class="pt-5">×</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500 mb-2">メーター箇所</div>
              <span class="pt-5 number">
                <span v-if="displaySubItems[8]">
                  {{ displaySubItems[8].value }}
                </span>
              </span>
            </div>
            <span class="pt-5">×</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">メーター単価</div>
              <NumericTextInput
                name="meter"
                v-model="selectedDetails.conf['meter']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['meterBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'meter')"
              />
            </div>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">メーター合計</div>
              <NumericTextInput
                name="totalUnitMeter"
                v-model="selectedDetails.price['meter']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
              />
            </div>
          </div>
          
          <!-- 写真報告 -->
          <div v-if="categoryCode=='1'" class="formula-left">
            <!-- 月1回の場合 -->
            <div v-if="displaySubItems[9] && displaySubItems[9].value=='m1'">
              <div class="cof-price-width text-xs text-gray-500 mb-2">写真回数</div>
              <span class="pt-5 number">
                1
              </span>
            </div>
            <!-- 月1回以外の場合 -->
            <div v-else>
              <div class="cof-price-width text-xs text-gray-500 mb-2">写真回数</div>
              <span class="pt-5 number">
                <span v-if="displaySubItems[9]">
                  {{ displaySubItems[9].value }}
                </span>
              </span>
            </div>
            <span class="pt-5">×</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">写真単価</div>
              <NumericTextInput
                name="photo"
                v-model="selectedDetails.conf['photo']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['photoBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'photo')"
              />
            </div>
            <span class="pt-5">×</span>
            <!-- 月1回の場合 -->
            <div v-if="displaySubItems[9] && displaySubItems[9].value=='m1'">
              <div class="cof-price-width text-xs text-gray-500 mb-2">1回/月</div>
              <span class="pt-5 number">
                1
              </span>
            </div>
            <!-- 月1回以外の場合 -->
            <div v-else>
              <div class="cof-price-width text-xs text-gray-400 mb-2">週/月</div>
              <span class="pt-5 number font-bold">
                4.3
              </span>
            </div>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">写真合計</div>
              <NumericTextInput
                name="totalUnitPhoto"
                v-model="selectedDetails.price['photo']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
              />
            </div>
          </div>
          
          <!-- 最終金額 -->
          <div v-if="categoryCode=='1'" class="formula-left border-none">
            <span class="symbol">&#040;</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">トイレ合計</div>
              <NumericTextInput
                name="totalUnitToilet"
                v-model="selectedDetails.price['toilet']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
              />
            </div>
            <span class="pt-5">+</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">メーター合計</div>
              <NumericTextInput
                name="totalUnitMeter"
                v-model="selectedDetails.price['meter']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
              />
            </div>
            <span class="pt-5">+</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">写真合計</div>
              <NumericTextInput
                name="totalUnitPhoto"
                v-model="selectedDetails.price['photo']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
              />
            </div>
            <span class="pt-5">+</span>
            <div>
              <div class="price-width text-xs text-gray-500">単価</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width"
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="setUnitPriceChange()"
              />
            </div>
            <span class="symbol mr-4">&#041;</span>
            <span class="pt-5">×</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
              <NumericTextInput
                name="finishPoint"
                v-model="selectedDetails.conf['finishPoint']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['finishPointBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'finishPoint')"
              />
            </div>
            <span class="pt-5">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>
            <span class="pt-5">×</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'owner')"
              />
            </div>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-400">*10円単位を切上*</div>
              <NumericTextInput
                name="totalUnitPrice"
                v-model="totalUnitPrice"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>
          <!-- ---------------------------------------------RLS計算式---end--------------------------------------------- -->

          <!-- ------------------------------------------日常清掃-管理員業務--------------------------------------------- -->
          <!-- 月合計時間 -->
          <PrimaryButton v-if="categoryCode=='3' || categoryCode=='4'" text="計算式の詳細" size="sm bg-purple-200 text-purple-700 hover:bg-purple-300 hover:text-purple-900 px-10 py-3 place-items-end block mt-3 btn" @click="formulaDispClick()" />
          
          <div :class="formulaDisp">

            <!-- 月の合計時間 -->
            <div v-if="categoryCode=='3' || categoryCode=='4'" class="formula-left">
              <div>
                <div class="cof-price-width text-xs text-gray-500 mb-2">清掃時間</div>
                <span class="pt-5 number">
                  <span>
                    {{ cleaningHours }}
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="cof-price-width text-xs text-gray-500 mb-2">清掃回数</div>
                <span class="pt-5 number">
                  <span v-if="displaySubItems[7]">
                    {{ displaySubItems[7].value }}
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="cof-price-width text-xs text-gray-500 mb-2">人数</div>
                <span class="pt-5 number">
                  <span v-if="displaySubItems[8]">
                    {{ displaySubItems[8].value }}
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="cof-price-width text-xs text-gray-400 mb-2">週/月</div>
                <span class="pt-5 number font-bold">
                  4.3
                </span>
              </div>
              <span class="pt-5">＝</span>
              <div class="inline">
                <div class="cof-price-width text-xs text-teal-500">月の合計時間</div>
                <NumericTextInput
                  name="hours"
                  v-model="selectedDetails.price['hours']"
                  :decimalPlace="decimal2"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
              </div>
            </div>

            <!-- 日曜選択の場合 月の合計時間 日曜以外 -->
            <div v-if="(categoryCode=='3' || categoryCode=='4') && isSunday" class="formula-left">
              <div>
                <div class="cof-price-width text-xs text-gray-500 mb-2">清掃時間</div>
                <span class="pt-5 number">
                  <span>
                    {{ cleaningHours }}
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="cof-price-width text-xs text-gray-500 mb-2">週{{ String(Number(displaySubItems[7].value) - 1) }}回分/月</div>
                <span class="pt-5 number">
                  <span v-if="displaySubItems[7]">
                    {{ String(Number(displaySubItems[7].value) - 1) }}
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="cof-price-width text-xs text-gray-500 mb-2">人数</div>
                <span class="pt-5 number">
                  <span v-if="displaySubItems[8]">
                    {{ displaySubItems[8].value }}
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="cof-price-width text-xs text-gray-400 mb-2">週/月</div>
                <span class="pt-5 number font-bold">
                  4.3
                </span>
              </div>
              <span class="pt-5">＝</span>
              <div class="inline">
                <div class="cof-price-width text-xs text-gray-500">週{{ String(Number(displaySubItems[7].value) - 1) }}回の合計時間</div>
                <NumericTextInput
                  name="hours"
                  v-model="selectedDetails.price['hours6']"
                  :decimalPlace="decimal2"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
              </div>
            </div>

            <!-- 日曜選択の場合 月の合計時間 日曜の週1回分 -->
            <div v-if="(categoryCode=='3' || categoryCode=='4') && isSunday" class="formula-left">
              <div>
                <div class="cof-price-width text-xs text-gray-500 mb-2">清掃時間</div>
                <span class="pt-5 number">
                  <span>
                    {{ cleaningHours }}
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="cof-price-width text-xs text-gray-500 mb-2">週1回分/月</div>
                <span class="pt-5 number">
                  <span class="pt-5 number font-bold">
                    1
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="cof-price-width text-xs text-gray-500 mb-2">人数</div>
                <span class="pt-5 number">
                  <span v-if="displaySubItems[8]">
                    {{ displaySubItems[8].value }}
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="cof-price-width text-xs text-gray-400 mb-2">週/月</div>
                <span class="pt-5 number font-bold">
                  4.3
                </span>
              </div>
              <span class="pt-5">＝</span>
              <div class="inline">
                <div class="cof-price-width text-xs text-gray-500">週1回の合計時間</div>
                <NumericTextInput
                  name="hours"
                  v-model="selectedDetails.price['hours1']"
                  :decimalPlace="decimal2"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
              </div>
            </div>

            <!-- トイレ金額 -->
            <div class="formula-left">
              <div>
                <div class="cof-price-width text-xs text-gray-500 mb-2">トイレ回数</div>
                <span class="pt-5 number">
                  <span v-if="displaySubItems[2]">
                    {{ displaySubItems[2].value }}
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="cof-price-width text-xs text-gray-500 mb-2">トイレ箇所</div>
                <span class="pt-5 number">
                  <span v-if="displaySubItems[3]">
                    {{ displaySubItems[3].value }}
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="cof-price-width text-xs text-gray-500">トイレ単価</div>
                <NumericTextInput
                  name="toilet"
                  v-model="selectedDetails.conf['toilet']"
                  class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                  :class="setBgColor(selectedDetails.conf['toiletBgColor'])"
                  @change="changeCalcAndPrice(selectedDetails.conf, 'toilet')"
                />
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="cof-price-width text-xs text-gray-400 mb-2">週/月</div>
                <span class="pt-5 number font-bold">
                  4.3
                </span>
              </div>
              <span class="pt-5">÷</span>
              <div class="inline">
                <div class=" cof-price-width text-xs text-teal-500">月の合計時間</div>
                <NumericTextInput
                  name="hours"
                  v-model="selectedDetails.price['hours']"
                  :decimalPlace="decimal2"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
              </div>
              <span class="pt-5">＝</span>
              <div class="inline">
                <div class="cof-price-width text-xs text-pink-700">トイレ代/時間</div>
                <NumericTextInput
                  name="totalUnitToilet"
                  v-model="selectedDetails.price['toilet']"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
              </div>
            </div>

            <!-- バス代 -->
            <div v-if="categoryCode=='3' || categoryCode=='4'" class="formula-left">
              <div class="inline">
                <div class="cof-price-width text-xs text-gray-500">バス代単価</div>
                <NumericTextInput
                  name="busUnit"
                  v-model="selectedDetails.conf['bus']"
                  class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                  :class="setBgColor(selectedDetails.conf['busBgColor'])"
                  @change="changeCalcAndPrice(selectedDetails.conf, 'bus')"
                />
              </div>
              <span class="pt-5">÷</span>
              <div>
                <div class="cof-price-width text-xs text-gray-500 mb-2">清掃時間</div>
                <span class="pt-5 number">
                  <span>
                    {{ cleaningHours }}
                  </span>
                </span>
              </div>
              <span class="pt-5">＝</span>
              <div class="inline">
                <div class="cof-price-width text-xs text-red-300">バス代/時間</div>
                <NumericTextInput
                  name="totalUnitBus"
                  v-model="selectedDetails.price['bus']"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
              </div>
            </div>

            <!-- 定期概算 -->
            <div v-if="(categoryCode=='3' || categoryCode=='4') && uncertain">
              <div class="formula-left">
                <div>
                  <div class="price-width-sm text-xs text-gray-500 mb-2">戸数</div>
                  <span class="pt-5 number">
                    <span v-if="selectedDetails.conf">
                      {{ selectedDetails.conf['house'] }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">×</span>
                <div class="inline">
                  <div class="price-width-sm text-xs text-gray-500">長さ</div>
                  <NumericTextInput
                    name="wide"
                    v-model="selectedDetails.conf['wide']"
                    :disabled="true"
                    class="text-center py-1 border-none input disabled-back price-width-sm mb-2"
                  />
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="price-width-sm text-xs text-gray-400 mb-2">幅</div>
                  <span class="pt-5 number font-bold">
                    1.5
                  </span>
                </div>
                <span class="pt-5">+</span>
                <div>
                  <div class="price-width-sm text-xs text-gray-500 mb-2">階数</div>
                  <span class="pt-5 number">
                    <span v-if="selectedDetails.conf">
                      {{ selectedDetails.conf['floor'] }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="price-width-sm text-xs text-gray-500 mb-2">本数</div>
                  <span class="pt-5 number">
                    <span v-if="selectedDetails.conf">
                      {{ selectedDetails.conf['num'] }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="price-width-sm text-xs text-gray-400 mb-2">長さ</div>
                  <span class="pt-5 number font-bold">
                    10
                  </span>
                </div>
                <span class="pt-5">×</span>
                <div>
                  <div class="price-width-sm text-xs text-gray-400 mb-2">幅</div>
                  <span class="pt-5 number font-bold">
                    1.2
                  </span>
                </div>
                <span class="pt-5">+</span>
                <div>
                  <div class="price-width-sm text-xs text-gray-500 mb-2">戸数</div>
                  <span class="pt-5 number">
                    <span v-if="selectedDetails.conf">
                      {{ selectedDetails.conf['house'] }}
                    </span>
                  </span>
                </div>
                <span class="pt-5">＝</span>
                <div class="inline">
                <div class="cof-price-width text-xs text-blue-400">概算</div>
                <NumericTextInput
                  name="approxPre"
                  v-model="selectedDetails.price['approxPre']"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
                </div>
              </div>
              <!-- どの式を使用するかメッセージ表示 -->
              <div class="text-center text-xs text-blue-500">
                {{ selectedDetails.conf['msg'] }}
              </div>
            </div>

            <!-- 追加料金（その他、業務なし） -->
            <div v-if="categoryCode=='3' || categoryCode=='4'" class="formula-left">
              <span class="symbol">&#040;</span>

              <!-- 300㎡超え -->
              <div v-if="(displaySubItems[11] && displaySubItems[11].value=='3')" class="inline">
                <div class="cof-price-width text-xxs text-gray-500 mb-2">㎡数（300㎡越え）</div>
                  {{ selectedDetails.price['approx'] }}
              </div>
              <!-- ㎡数不明で300㎡超え -->
              <div v-else-if="(selectedDetails.conf['meter']=='3')" class="inline">
                <div class="cof-price-width text-xs text-blue-400">概算</div>
                <NumericTextInput
                  name="approx"
                  v-model="selectedDetails.price['approx']"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
              </div>
              <!-- ㎡数その他 -->
              <div v-else class="inline">
                <div v-if="(displaySubItems[11] && displaySubItems[11].value=='1') || selectedDetails.conf['meter']=='1'" class="cof-price-width text-xs text-blue-400">200㎡以下</div>
                <div v-else-if="(displaySubItems[11] && displaySubItems[11].value=='2') || selectedDetails.conf['meter']=='2'" class="cof-price-width text-xs text-blue-400">300㎡以下</div>
                <div v-else class="cof-price-width text-xs text-blue-400">定期概算</div>
                <NumericTextInput
                  name="approx"
                  v-model="selectedDetails.price['approx']"
                  class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray  cof-price-width mb-2"
                  :class="setBgColor(selectedDetails.price['approxBgColor'])"
                  @change="changeCalcAndPrice(selectedDetails.price, 'approx')"
                />
              </div>

              <span class="pt-5">×</span>
              <div>
                <div class="price-width-sm text-xs text-gray-400 mb-2">回/年</div>
                <span class="pt-5 number font-bold">
                  4
                </span>
              </div>
              <span class="pt-5">×</span>
              <div class="inline">
                <div class=" cof-price-width text-xs text-gray-500">エリア単価</div>
                <NumericTextInput
                  name="eria"
                  v-model="selectedDetails.conf['eria']"
                  class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray  cof-price-width mb-2"
                  :class="setBgColor(selectedDetails.conf['eriaBgColor'])"
                  @change="changeCalcAndPrice(selectedDetails.conf, 'eria')"
                />
              </div>
              <span class="symbol mr-4">&#041;</span>
              <span class="pt-5">÷</span>
              <div class="pt-4">
                <div class="price-width-sm text-xs text-gray-400 mb-2"></div>
                <span class="pt-5 number font-bold">
                  12
                </span>
              </div>
              <span class="pt-5">×</span>
              <div class="pt-4">
                <div class="price-width-sm text-xs text-gray-400 mb-2"></div>
                <span class="pt-5 number font-bold">
                  0.3
                </span>
              </div>
              <span class="pt-5">÷</span>
              <div class="inline">
                <div class=" cof-price-width text-xs text-teal-500">月の合計時間</div>
                <NumericTextInput
                  name="hours"
                  v-model="selectedDetails.price['hours']"
                  :decimalPlace="decimal2"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
              </div>
              <span class="pt-5">＝</span>
              <div class="inline">
                <div class="cof-price-width text-xss text-indigo-600">追加料金(単独発注)</div>
                <NumericTextInput
                  name="add"
                  v-model="selectedDetails.price['add']"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
              </div>
            </div>

            <!-- 祝日対応時間 -->
            <div v-if="categoryCode=='3' || categoryCode=='4'" class="formula-left">
              <div>
                <div class="price-width text-xs text-gray-500">{{ selectedDetails.toolTip['祝日対応'] }}(祝日/月)</div>
                <NumericTextInput
                  name="holidayHours"
                  v-model="selectedDetails.conf['holidayHours']"
                  :decimalPlace="decimal1"
                  class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                  :class="setBgColor(selectedDetails.conf['holidayHoursBgColor'])"
                  @change="changeCalcAndPrice(selectedDetails.conf, 'holidayHours')"
                />
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="price-width text-xs text-gray-500 mb-2">人数</div>
                <span class="pt-5 number">
                  <span v-if="displaySubItems[8]">
                    {{ displaySubItems[8].value }}
                  </span>
                </span>
              </div>
              <span class="pt-5">＝</span>
              <div class="inline">
                <div class="price-width text-xs text-teal-700">祝日対応時間</div>
                <NumericTextInput
                  name="holidayHours"
                  v-model="selectedDetails.price['holidayHours']"
                  :decimalPlace="decimal1"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back price-width mb-2"
                />
              </div>
            </div>

            <!-- 祝日追加料金 -->
            <div v-if="categoryCode=='3' || categoryCode=='4'" class="formula-left">
              <div class="inline">
                <div class="cof-price-width text-xs text-teal-700">祝日対応時間</div>
                <NumericTextInput
                  name="holidayHours"
                  v-model="selectedDetails.price['holidayHours']"
                  :decimalPlace="decimal1"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
              </div>
              <span class="pt-5">×</span>
              <span class="symbol ml-4">&#040;</span>
              <div class="inline">
                <div class="cof-price-width text-xs text-red-300">バス代/時間</div>
                <NumericTextInput
                  name="totalUnitBus"
                  v-model="selectedDetails.price['bus']"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
              </div>
              <span class="pt-5">+</span>
              <div class="inline">
                <div class="cof-price-width text-indigo-600 text-xss">追加料金(単独発注)</div>
                <NumericTextInput
                  name="add"
                  v-model="selectedDetails.price['add']"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
              </div>
              <span class="pt-5">+</span>
              <div>
                <div class="price-width text-xs text-pink-400">単価</div>
                <NumericTextInput
                  name="unitPrice"
                  v-model="selectedDetails.unitPrice"
                  class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-pink-300 price-width mb-2"
                  :class="setBgColor(selectedDetails.unitPriceBgColor)"
                  @change="setUnitPriceChange()"
                />
              </div>
              <span class="pt-5">×</span>
              <div class="inline">
                <div class="cof-price-width text-xs text-gray-500">早朝割増</div>
                <NumericTextInput
                  name="early"
                  v-model="selectedDetails.conf['early']"
                  :decimalPlace="decimal2"
                  class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                  :class="setBgColor(selectedDetails.conf['earlyBgColor'])"
                  @change="changeCalcAndPrice(selectedDetails.conf, 'early')"
                />
              </div>
              <span class="symbol mr-4">&#041;</span>
              <span class="pt-5">＝</span>
              <div class="inline">
                <div class="cof-price-width text-xs text-gray-500">祝日料金</div>
                <NumericTextInput
                  name="holiday"
                  v-model="selectedDetails.price['holiday']"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
              </div>
            </div>
          </div>

          <!-- 日常清掃,管理員業務 料金決定 -->
          <div v-if="categoryCode=='3' || categoryCode=='4'" class="formula-left">
            <span class="symbol ml-4">&#040;</span>
            <div>
              <div class="price-width text-xs text-pink-400">単価</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-pink-300 price-width mb-2"
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="setUnitPriceChange()"
              />
            </div>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">早朝割増</div>
              <NumericTextInput
                name="early"
                v-model="selectedDetails.conf['early']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['earlyBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'early')"
              />
            </div>
            <span class="pt-5">+</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-pink-700">トイレ代/時間</div>
                <NumericTextInput
                  name="toilet"
                  v-model="selectedDetails.price['toilet']"
                  :disabled="true"
                  class="text-center py-1 border-none input  disabled-back cof-price-width mb-2"
                />
            </div>
            <span class="pt-5">+</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-red-300">バス代/時間</div>
                <NumericTextInput
                  name="totalUnitBus"
                  v-model="selectedDetails.price['bus']"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
            </div>
            <span class="pt-5">+</span>
            <div class="inline">
              <div class="cof-price-width text-indigo-600 text-xss">追加料金(単独発注)</div>
              <NumericTextInput
                name="add"
                v-model="selectedDetails.price['add']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
              />
            </div>
            <span class="symbol mr-4">&#041;</span>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'owner')"
              />
            </div>
            <span class="pt-5">＝</span>
            <div class="inline" v-if="isSunday">
              <div class="price-width text-xs text-indigo-400">週{{ String(Number(displaySubItems[7].value) - 1) }}回 単価</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.price['totalUnitPricePre6']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <div class="inline" v-else>
              <div class="price-width text-xs text-purple">合計単価</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.price['totalUnitPricePre']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <!-- 日曜 割増し分の単価 -->
          <div v-if="(categoryCode=='3' || categoryCode=='4') && isSunday" class="formula-left">
            <span class="symbol ml-4">&#040;</span>
            <div>
              <div class="price-width text-xs text-pink-400">単価</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-pink-300 price-width mb-2"
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="setUnitPriceChange()"
              />
            </div>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">早朝割増</div>
              <NumericTextInput
                name="early"
                v-model="selectedDetails.conf['early']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['earlyBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'early')"
              />
            </div>
            <span class="pt-5">×</span>
            <div>
              <div class="cof-price-width text-xs text-gray-400 mb-2">日曜 割増し分</div>
              <span class="pt-5 number font-bold">
                1.25
              </span>
            </div>
            <!-- トイレ清掃は管理員業務はなし -->
            <!-- <span v-if="categoryCode=='3'" class="pt-5">+</span>
            <div v-if="categoryCode=='3'" class="inline">
              <div class="cof-price-width text-xs text-pink-700">トイレ合計</div>
                <NumericTextInput
                  name="toilet"
                  v-model="selectedDetails.price['toilet']"
                  :disabled="true"
                  class="text-center py-1 border-none input  disabled-back cof-price-width mb-2"
                />
            </div> -->
            <span class="pt-5">+</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-pink-700">トイレ代/時間</div>
                <NumericTextInput
                  name="toilet"
                  v-model="selectedDetails.price['toilet']"
                  :disabled="true"
                  class="text-center py-1 border-none input  disabled-back cof-price-width mb-2"
                />
            </div>
            <span class="pt-5">+</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-red-300">バス代/時間</div>
                <NumericTextInput
                  name="totalUnitBus"
                  v-model="selectedDetails.price['bus']"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
            </div>
            <span class="pt-5">+</span>
            <div class="inline">
              <div class="cof-price-width text-indigo-600 text-xss">追加料金(単独発注)</div>
              <NumericTextInput
                name="add"
                v-model="selectedDetails.price['add']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
              />
            </div>
            <span class="symbol mr-4">&#041;</span>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'owner')"
              />
            </div>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-indigo-400">週1回 割増し単価</div>
              <NumericTextInput
                name="totalUnitPricePre1"
                v-model="selectedDetails.price['totalUnitPricePre1']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <!-- 日曜あり 日曜以外 -->
          <div v-if="(categoryCode=='3' || categoryCode=='4') && isSunday" class="formula-left">
            <div class="inline">
              <div class="price-width text-xs text-indigo-400">週{{ String(Number(displaySubItems[7].value) - 1) }}回 単価</div>
              <NumericTextInput
                name="totalUnitPricePre6"
                v-model="selectedDetails.price['totalUnitPricePre6']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">週{{ String(Number(displaySubItems[7].value) - 1) }}回の合計時間</div>
              <NumericTextInput
                name="hours6"
                v-model="selectedDetails.price['hours6']"
                :decimalPlace="decimal2"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
              />
            </div>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-purple">週{{ String(Number(displaySubItems[7].value) - 1) }}回 合計金額</div>
              <NumericTextInput
                name="totalUnitPrice6"
                v-model="selectedDetails.price['totalUnitPrice6']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <!-- 日曜 割り増し分 -->
          <div v-if="(categoryCode=='3' || categoryCode=='4') && isSunday" class="formula-left">
            <div class="inline">
              <div class="price-width text-xs text-indigo-400">週1回 割増し単価</div>
              <NumericTextInput
                name="totalUnitPricePre1"
                v-model="selectedDetails.price['totalUnitPricePre1']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">週1回の合計時間</div>
              <NumericTextInput
                name="hours1"
                v-model="selectedDetails.price['hours1']"
                :decimalPlace="decimal2"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
              />
            </div>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-purple">週1回 合計金額</div>
              <NumericTextInput
                name="totalUnitPrice1"
                v-model="selectedDetails.price['totalUnitPrice1']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <!-- 最終金額 日曜あり -->
          <div v-if="(categoryCode=='3' || categoryCode=='4') && isSunday" class="formula-left">
            <span class="symbol ml-4">&#040;</span>
            <div class="inline">
              <div class="price-width text-xs text-purple">週{{ String(Number(displaySubItems[7].value) - 1) }}回 合計単価</div>
              <NumericTextInput
                name="totalUnitPrice6"
                v-model="selectedDetails.price['totalUnitPrice6']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-5">+</span>
            <div class="inline">
              <div class="price-width text-xs text-purple">週1回 合計単価</div>
              <NumericTextInput
                name="totalUnitPrice1"
                v-model="selectedDetails.price['totalUnitPrice1']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <span class="symbol mr-4">&#041;</span>
            <span class="pt-5">+</span>
            <span class="symbol ml-4">&#040;</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">祝日料金</div>
              <NumericTextInput
                name="holiday"
                v-model="selectedDetails.price['holiday']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'owner')"
              />
            </div>
            <span class="symbol mr-4">&#041;</span>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">金額/月</div>
              <NumericTextInput
                name="byMonth"
                v-model="selectedDetails.price['byMonth']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <!-- 最終金額 日曜含まない -->
          <div v-if="(categoryCode=='3' || categoryCode=='4') && !isSunday" class="formula-left">
            <span class="symbol ml-4">&#040;</span>
            <div class="inline">
              <div class="price-width text-xs text-purple">合計単価</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.price['totalUnitPricePre']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-teal-500">月の合計時間</div>
              <NumericTextInput
                name="hours"
                v-model="selectedDetails.price['hours']"
                :decimalPlace="decimal2"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
              />
            </div>
            <span class="symbol mr-4">&#041;</span>
            <span class="pt-5">+</span>
            <span class="symbol ml-4">&#040;</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">祝日料金</div>
              <NumericTextInput
                name="holiday"
                v-model="selectedDetails.price['holiday']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'owner')"
              />
            </div>
            <span class="symbol mr-4">&#041;</span>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-blue-500">金額/月</div>
              <NumericTextInput
                name="byMonth"
                v-model="selectedDetails.price['byMonth']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <!-- 年末年始追加時間 -->
          <div v-if="(categoryCode=='3' || categoryCode=='4') && displaySubItems[18] && displaySubItems[18].value=='1'" class="formula-left">
            <div class="inline">
              <div class="price-width text-xs text-gray-500 mb-2">年末年始日数</div>
              <span class="pt-5 number">
                <span v-if="selectedDetails.newYearHoliday&&selectedDetails.newYearHoliday.length">
                  {{ selectedDetails.newYearHoliday.length }}
                </span>
                <span v-else>
                  0
                </span>
              </span>
            </div>
            <span class="pt-5">×</span>
            <div>
              <div class="text-xs text-gray-500 mb-2">清掃時間</div>
              <span class="pt-5 number">
                <span>
                  {{ cleaningHours }}
                </span>
              </span>
            </div>
            <span class="pt-5">÷</span>
            <div class="pt-4">
              <div class="cof-price-width text-xs text-gray-500 mb-2"></div>
              <span class="pt-5 number font-bold">
                <span>
                  12
                </span>
              </span>
            </div>
            <span class="pt-5">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500 mb-2">週/月</div>
              <span class="pt-5 number font-bold">
                <span>
                  4.3
                </span>
              </span>
            </div>
            <span class="pt-5">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500 mb-2">清掃回数</div>
              <span class="pt-5 number">
                <span v-if="displaySubItems[7]">
                  {{ displaySubItems[7].value }}
                </span>
              </span>
            </div>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-red-600">年末年始加算時間</div>
              <NumericTextInput
                name="newYearHours"
                v-model="selectedDetails.price['newYearHours']"
                :decimalPlace="3"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <!-- 時給算出 年末年始なし -->
          <div v-if="(categoryCode=='3' || categoryCode=='4')" class="formula-left">
            <div class="inline">
              <div class="price-width text-xs text-blue-500">金額/月</div>
              <NumericTextInput
                name="byMonth"
                v-model="selectedDetails.price['byMonth']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
              <NumericTextInput
                name="finishPoint"
                v-model="selectedDetails.conf['finishPoint']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['finishPointBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'finishPoint')"
              />
            </div>
            <span class="pt-5">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>
            <span class="pt-5">÷</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-teal-500">月の合計時間</div>
              <NumericTextInput
                name="hours"
                v-model="selectedDetails.price['hours']"
                :decimalPlace="decimal2"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
              />
            </div>
            <span class="pt-5">＝</span>
            <!-- 年末年始あり -->
            <div v-if=" displaySubItems[18] && displaySubItems[18].value=='1'" class="inline">
              <div class="text-xs text-orange">通常時給(年末年始加算前)</div>
              <NumericTextInput
                name="hourlyWagePre"
                v-model="selectedDetails.price['hourlyWagePre']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <!-- 年末年始なし -->
            <div v-else class="inline">
              <div class="price-width text-xs text-purple-700">時給 *1円単位を切上*</div>
              <NumericTextInput
                name="hourlyWage"
                v-model="selectedDetails.price['hourlyWage']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <!-- 時給算出 年末年始あり -->
          <div v-if="(categoryCode=='3' || categoryCode=='4') && displaySubItems[18] && displaySubItems[18].value=='1'" class="formula-left">
            <span class="symbol ml-4">&#040;</span>
            <div class="inline">
              <div class="price-width text-xs text-orange">通常時給</div>
              <NumericTextInput
                name="hourlyWagePre"
                v-model="selectedDetails.price['hourlyWagePre']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-5">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500 mb-2">清掃時間</div>
              <span class="pt-5 number">
                <span>
                  {{ cleaningHours }}
                </span>
              </span>
            </div>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="price-width text-xs text-red-600">年末年始加算時間</div>
              <NumericTextInput
                name="newYearHours"
                v-model="selectedDetails.price['newYearHours']"
                :decimalPlace="3"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <span class="symbol mr-4">&#041;</span>
            <span class="pt-5">+</span>
            <div class="inline">
              <div class="price-width text-xs text-orange">通常時給</div>
              <NumericTextInput
                name="hourlyWagePre"
                v-model="selectedDetails.price['hourlyWagePre']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-purple-700">時給 *1円単位を切上*</div>
              <NumericTextInput
                name="hourlyWage"
                v-model="selectedDetails.price['hourlyWage']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <!-- 最終金額 -->
          <div v-if="(categoryCode=='3' || categoryCode=='4')" class="formula-left border-none">
            <div class="inline">
              <div class="price-width text-xs text-purple-700">時給</div>
              <NumericTextInput
                name="hourlyWage"
                v-model="selectedDetails.price['hourlyWage']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-teal-500">月の合計時間</div>
              <NumericTextInput
                name="hours"
                v-model="selectedDetails.price['hours']"
                :decimalPlace="decimal2"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
              />
            </div>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">最終金額</div>
              <NumericTextInput
                name="totalUnitPrice"
                v-model="totalUnitPrice"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <!-- --------------------------------------日常清掃-管理員業務---end------------------------------------------- -->

          <!-- --------------------------------------定期清掃・スポット清掃----------------------------------------------- -->

          <!-- ㎡数が不明の場合 -->
          <div v-if="['5', '28'].includes(categoryCode)&&uncertain" class="formula border-none mr-2">
              <div>
                <div class="price-width-sm text-xs text-gray-500 mb-2">戸数</div>
                <span class="pt-5 number">
                  <span v-if="selectedDetails.conf">
                    {{ selectedDetails.conf['house'] }}
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div class="inline">
                <div class="price-width-sm text-xs text-gray-500">長さ</div>
                <NumericTextInput
                  name="wide"
                  v-model="selectedDetails.conf['wide']"
                  :disabled="true"
                  class="text-center py-1 border-none input disabled-back price-width-sm mb-2"
                  />
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="price-width-sm text-xs text-gray-400 mb-2">幅</div>
                <span class="pt-5 number font-bold">
                  1.5
                </span>
              </div>
              <span class="pt-5">+</span>
              <div>
                <div class="price-width-sm text-xs text-gray-500 mb-2">階数</div>
                <span class="pt-5 number">
                  <span v-if="selectedDetails.conf">
                    {{ selectedDetails.conf['floor'] }}
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="price-width-sm text-xs text-gray-500 mb-2">本数</div>
                <span class="pt-5 number">
                  <span v-if="selectedDetails.conf">
                    {{ selectedDetails.conf['num'] }}
                  </span>
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="price-width-sm text-xs text-gray-400 mb-2">長さ</div>
                <span class="pt-5 number font-bold">
                  10
                </span>
              </div>
              <span class="pt-5">×</span>
              <div>
                <div class="price-width-sm text-xs text-gray-400 mb-2">幅</div>
                <span class="pt-5 number font-bold">
                  1.2
                </span>
              </div>
              <span class="pt-5">+</span>
              <div>
                <div class="price-width-sm text-xs text-gray-500 mb-2">戸数</div>
                <span class="pt-5 number">
                  <span v-if="selectedDetails.conf">
                    {{ selectedDetails.conf['house'] }}
                  </span>
                </span>
              </div>
              <span class="pt-5">＝</span>
              <div class="inline">
              <div class="cof-price-width text-xs text-blue-500">概算</div>
              <NumericTextInput
                name="approx"
                v-model="selectedDetails.price['approx']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back cof-price-width mb-2"
                />
            </div>
          </div>

          <div v-if="['5', '28'].includes(categoryCode) && uncertain && selectedDetails.conf['msg']" class="text-center text-xs text-blue-500">
            {{ selectedDetails.conf['msg'] }}
          </div>

          <!-- 300㎡越え -->
          <div v-if="['5', '28'].includes(categoryCode) && (exceed300 || selectedDetails.conf['meter'] == '3')" class="formula border-none">
            <div class="inline">
              <div class="price-width text-xs text-purple-500">単価</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width border-purple-500"
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="changeForFixedExeed300UnitPrice()"
              />
            </div>
            <!-- スポット清掃のみ×1.2する -->
            <span v-if="categoryCode=='28'" class="pt-4">×</span>
            <div v-if="categoryCode=='28'">
              <div class="cof-price-width text-xs text-gray-400 mb-2">スポット対応</div>
              <span class="pt-4 number font-bold">
                1.2
              </span>
            </div>
            <!-- 竣工係数（300㎡超えの場合はここに入れる）スポット清掃以外 -->
            <span v-if="categoryCode != '28' && (exceed300 || selectedDetails.conf['meter'] == '3')" class="pt-4">×</span>
            <div v-if="categoryCode != '28' && (exceed300 || selectedDetails.conf['meter'] == '3')">
              <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
              <NumericTextInput
                name="finishPoint"
                v-model="selectedDetails.conf['finishPoint']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['finishPointBgColor'])"
                @change="changeForFixedExeed300('finishPoint')"
              />
            </div>
            <span class="pt-4">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>
            <span class="pt-4">×</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeForFixedExeed300('owner')"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div class="flex text-xs text-purple-600">合計単価<div class="text-xs">（5円単位）</div></div>
              <NumericTextInput
                v-if="exceed300 || selectedDetails.conf['meter'] == '3'"
                name="totalUnitPrice"
                v-model="totalUnitPrice"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width "
              />
            </div>
          </div>
          
          <!-- 300㎡越え -->
          <div v-if="['5', '28'].includes(categoryCode) && (exceed300 || selectedDetails.conf['meter'] == '3')" class="formula border-none">
            <div class="inline">
              <div class="price-width text-xs text-yellow-600">駐車場 単価</div>
              <NumericTextInput
                name="parking"
                v-model="selectedDetails.conf['parking']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2 border-yellow-500"
                :class="setBgColor(selectedDetails.conf['parkingBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'parking')"
              />
            </div>
            <!-- 竣工係数（300㎡超えの場合はここに入れる）スポット清掃以外 -->
            <span v-if="categoryCode != '28' && (exceed300 || selectedDetails.conf['meter'] == '3')" class="pt-4">×</span>
            <div v-if="categoryCode != '28' && (exceed300 || selectedDetails.conf['meter'] == '3')">
              <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
              <NumericTextInput
                name="finishPoint"
                v-model="selectedDetails.conf['finishPoint']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['finishPointBgColor'])"
                @change="changeForFixedExeed300('finishPoint')"
              />
            </div>
            <span class="pt-4">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>
            <span class="pt-4">×</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeForFixedExeed300('owner')"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div class="flex text-xs text-yellow-600">駐車場代<div class="text-xs">（500円単位）</div></div>
              <NumericTextInput
                name="parkingPrice"
                v-model="selectedDetails.price['parking']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2 border-yellow-500"
              />
            </div>
          </div>
          
          <!-- 300㎡越え -->
          <div v-if="['5', '28'].includes(categoryCode) && (exceed300 || selectedDetails.conf['meter'] == '3')" class="formula border-none">
            <div class="inline">
              <div class="price-width text-xs text-green-500">発電機 単価</div>
              <NumericTextInput
                name="priceGenerator"
                v-model="selectedDetails.conf['generator']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2 border-green-500"
                :class="setBgColor(selectedDetails.conf['generatorBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'generator')"
              />
            </div>
            <!-- 竣工係数（300㎡超えの場合はここに入れる）スポット清掃以外 -->
            <span v-if="categoryCode != '28' && (exceed300 || selectedDetails.conf['meter'] == '3')" class="pt-4">×</span>
            <div v-if="categoryCode != '28' && (exceed300 || selectedDetails.conf['meter'] == '3')">
              <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
              <NumericTextInput
                name="finishPoint"
                v-model="selectedDetails.conf['finishPoint']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['finishPointBgColor'])"
                @change="changeForFixedExeed300('finishPoint')"
              />
            </div>
            <span class="pt-4">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>
            <span class="pt-4">×</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeForFixedExeed300('owner')"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div class="flex text-xs text-green-600">発電機代<div class="text-xs">（500円単位）</div></div>
              <NumericTextInput
                name="generatorPrice"
                v-model="selectedDetails.price['generator']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <!-- 300㎡以下 発電機 -->
          <div v-if="['5', '28'].includes(categoryCode) && !exceed300 && selectedDetails.conf['meter'] != '3'" class="formula border-none">
            <div class="inline">
              <div class="price-width text-xs text-green-500">発電機 単価</div>
              <NumericTextInput
                name="priceGenerator"
                v-model="selectedDetails.conf['generator']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-green-500 price-width mb-2"
                :class="setBgColor(selectedDetails.conf['generatorBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'generator')"
              />
            </div>
            <span class="pt-4">×</span>
            <div>
              <div class="price-width text-xs text-gray-500 mb-2">台数</div>
              <span class="pt-4 number">
                <span v-if="displaySubItems[10]">
                  {{ displaySubItems[10].value }}
                </span>
              </span>
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-green-600">発電機代</div>
              <NumericTextInput
                name="generatorPrice"
                v-model="selectedDetails.price['generator']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <!-- 300㎡以下 -->
          <div v-if="['5', '28'].includes(categoryCode) && !exceed300 && selectedDetails.conf['meter'] != '3'" class="formula border-none">
            <div class="inline">
              <div class="price-width text-xs text-purple-500">単価</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-purple-500 price-width"
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="changeForFixedExeed300UnitPrice()"
              />
            </div>
            <!-- スポット清掃のみ×1.2する -->
            <span v-if="categoryCode=='28'" class="pt-4">×</span>
            <div v-if="categoryCode=='28'">
              <div class="price-width text-xs text-gray-400 mb-2">スポット対応</div>
              <span class="pt-4 number font-bold">
                1.2
              </span>
            </div>
            <span class="pt-4">+</span>
            <div class="inline">
              <div class="price-width text-xs text-yellow-600">駐車場代</div>
              <NumericTextInput
                name="parking"
                v-model="selectedDetails.conf['parking']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-yellow-500 price-width mb-2"
                :class="setBgColor(selectedDetails.conf['parkingBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'parking')"
              />
            </div>
            <span class="pt-4">+</span>
            <div class="inline">
              <div class="price-width text-xs text-green-600">発電機代</div>
              <NumericTextInput
                name="generator"
                v-model="selectedDetails.price['generator']"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-purple-500">合計単価</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.price['totalUnitPricePre']"
                :disabled="true"
                class="text-center py-1 input disabled-back price-width border-none"
              />
            </div>
          </div>

          <!-- 300㎡以下 -->
          <div v-if="['5', '28'].includes(categoryCode) && !exceed300 && selectedDetails.conf['meter'] != '3'" class="formula border-none">
            <div class="inline">
              <div class="price-width text-xs text-purple-500">合計単価</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.price['totalUnitPricePre']"
                :disabled="true"
                class="text-center py-1 input disabled-back price-width border-none"
              />
            </div>
            <span v-if="categoryCode != '28'" class="pt-4">×</span>
            <div v-if="categoryCode != '28'">
              <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
              <NumericTextInput
                name="finishPoint"
                v-model="selectedDetails.conf['finishPoint']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['finishPointBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'finishPoint')"
              />
            </div>
            <span class="pt-4">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>
            <span class="pt-4">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeForFixedExeed300('owner')"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">  
              <div class="price-width text-xs text-gray-400">*10円単位を切上*</div>
              <NumericTextInput
                name="totalUnitPrice"
                v-model="totalUnitPrice"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <div v-if="['5', '28'].includes(categoryCode) && !exceed300 && selectedDetails.conf['meter'] != '3'" class="list mt-6">
            <div class="text-xs font-medium text-gray-500 text-center">作業範囲（特記事項に使用）</div>

            <!-- テーブル -->
            <table class="table-fixed table mt-2">
              <thead>
                <tr>
                  <th class="py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                    作業部位
                  </th>
                  <th colspan="2" class="py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                    数量
                  </th>
                  <th class="py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray">
                    <!-- プラスボタン -->
                    <PlusCircleIcon class="text-indigo-500 hover:text-indigo-700 active:text-indigo-600 cursor-pointer col-actions" @click="addRowDetailFixed" />
                  </th>
                </tr>
              </thead>

              <tr v-for="(display, index) in selectedDetails.displayFixed" :key="index">
                <!-- 内容 定期清掃 -->
                <td class="col-ll text-center px-1 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height">
                  <!-- ドロップダウン -->
                  <div class="m-auto">
                    <WmsSuggestInput
                      name="fixedName"
                      :embed="true"
                      :dynamic="false"
                      displayField="name"
                      :incrementalSearch="false"
                      :selectionItems="nameList"
                      class="embed"
                      v-model="selectedDetails.displayFixed[index].name"
                      @selected="getFixedRange($event, index)"
                      @blur="getFixedRange($event, index)"
                    />
                  </div>
                </td>
                <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height col-small">
                  <NumericTextInput
                    name="qty"
                    v-model="selectedDetails.displayFixed[index].qty"
                    class="w-full text-right py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                  />
                </td>
                <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray td-height col-sm">
                  <!-- ドロップダウン -->
                  <div class="m-auto">
                    <WmsSuggestInput
                      name="unitName"
                      :embed="true"
                      :dynamic="false"
                      displayField="name"
                      :incrementalSearch="false"
                      :selectionItems="unitNameList"
                      class="embed"
                      v-model="selectedDetails.displayFixed[index].unitName"
                    />
                  </div>
                </td>
                <td class="px-0.5 py-0.5 whitespace-nowrap text-center text-sm font-medium border-gray">
                  <div class="flex justify-center">
                    <a href="#" @click="deleteFixedRow(index)">
                      <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
                    </a>
                  </div>
                </td>
              </tr>
            </table>
            <div class="text-xs font-medium text-gray-400 text-center mt-2">※未記入時は「アプローチ・エントランス・共用廊下・共用階段・管理室・エレベーター」を特記事項に記載します。</div>
          </div>

          <!-- --------------------------------------------定期清掃---end-------------------------------------------- -->

          <!-- ----------------------------------ガラス清掃-植栽剪定-防火対象物点検もしくは-建築設備点検-特定建築物定期検査で発注額決定の場合------------------------------------------------ -->
          <div v-if="['10', '13', '27'].includes(categoryCode)||(['16', '17'].includes(categoryCode)&&displaySubItems&&displaySubItems[0]&&displaySubItems[0].value=='3')" class="formula">
            <div v-if="['10', '13', '27'].includes(categoryCode)">
              <div class="price-width text-xs text-gray-500">発注単価</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width" 
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="setOrderPriceChange(selectedDetails.unitPrice)"
              />
            </div>
            <!-- 建築設備点検・特定建築物定期検査は2024年9月から式が変わったため、過去のと混同しないようにモデルを変更 -->
            <div v-else>
              <div class="price-width text-xs text-gray-500">発注単価</div>
              <NumericTextInput
                name="unitPurchasePrice"
                v-model="selectedDetails.conf['unitPurchasePrice']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width" 
                :class="setBgColor(selectedDetails.conf['unitPurchasePriceBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'unitPurchasePrice')"
              />
            </div>
            <span class="pt-4">×</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
              <NumericTextInput
                name="finishPoint"
                v-model="selectedDetails.conf['finishPoint']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['finishPointBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'finishPoint')"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-blue-400">発注額</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.price['purchasePre']"
                :disabled="true"
                class="text-center py-1 border-blue-500 input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <div v-if="['10', '13', '27'].includes(categoryCode)||(['16', '17'].includes(categoryCode)&&displaySubItems&&displaySubItems[0]&&displaySubItems[0].value=='3')" class="formula">
            <div class="inline">
              <div class="price-width text-xs text-blue-400">発注額</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.price['purchasePre']"
                :disabled="true"
                class="text-center py-1 border-blue-500 input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-4">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-400 mb-2">発注額として</div>
              <span class="pt-5 number font-bold">
                0.7
              </span>
            </div>
            <span class="pt-4">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>
            <span class="pt-4">×</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'owner')"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <!-- ガラス、植栽、建築設備点検、特定建築物定期検査の場合は切上 -->
              <div v-if="['10', '13', '16', '17'].includes(categoryCode)" class="price-width text-xs text-gray-400">*100円単位を切上*</div>
              <div v-else class="price-width text-xs text-gray-400">&nbsp;</div>
              <NumericTextInput
                name="totalUnitPrice"
                v-model="totalUnitPrice"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>
          <!-- ----------------------------------ガラス清掃-植栽剪定----end--------------------------------------- -->

          <!-- ---------------------フロント・ゴミサポ-排水管清掃-給排水ポンプ点検,簡易専用水道,緊急対応もしくは-建築設備点検-特定建築物定期検査で発注額未定、もしくはカテゴリーコードなしの場合------------------------ -->
          <div v-if="['6', '7', '8', '9', '11', '21', '22', '26'].includes(categoryCode)||(['16', '17'].includes(categoryCode)&&displaySubItems&&displaySubItems[0]&&displaySubItems[0].value!='3')||(!categoryCode)" class="formula">
            <div>
              <div class="price-width text-xs text-gray-500">単価</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width"
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="setUnitPriceChange()"
              />
            </div>

            <!-- 排水管のみ -->
            <span v-if="categoryCode=='11'" class="pt-4">×</span>
            <div v-if="categoryCode=='11'">
              <div class="price-width text-xs text-gray-500">施工回数による係数</div>
              <NumericTextInput
                name="drainage"
                v-model="selectedDetails.conf['drainage']"
                :decimalPlace="decimal1"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['drainageBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'drainage')"
              />
            </div>

            <!-- 緊急対応以外、竣工係数かける -->
            <span v-if="categoryCode!='26'" class="pt-5">×</span>
            <div  v-if="categoryCode!='26'" >
              <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
              <NumericTextInput
                name="finishPoint"
                v-model="selectedDetails.conf['finishPoint']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['finishPointBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'finishPoint')"
              />
            </div>

            <span class="pt-4">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>

            <span class="pt-4">×</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'owner')"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div v-if="['6', '7', '8', '9', '11'].includes(categoryCode)" class="price-width text-xs text-gray-400">*10円単位を切上*</div>
              <div v-else class="price-width text-xs text-gray-400">&nbsp;</div>
              <NumericTextInput
                name="totalUnitPrice"
                v-model="totalUnitPrice"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>
          <!-- ---------------------フロント・ゴミサポ-排水管清掃-建築設備点検-特定建築物定期検査-給排水ポンプ点検,簡易専用水道--end--------------------- -->

          <!-- --------------------------------------------貯水槽清掃----------------------------------------------- -->
          <div v-if="categoryCode=='12'" class="formula-left2 border-none">
            <span class="symbol ml-4">&#040;</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">発注単価</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width"
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="setUnitPriceChange()"
              />
            </div>
            <span class="pt-5">+</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">ポンプ点検</div>
              <NumericTextInput
                name="pump"
                v-model="selectedDetails.conf['pump']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['pumpBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'pump')"
              />
            </div>
            <span class="pt-5">+</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">水質点検</div>
              <NumericTextInput
                name="inspection"
                v-model="selectedDetails.conf['inspection']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['inspectionBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'inspection')"
              />
            </div>
            <span class="symbol mr-2">&#041;</span>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
              <NumericTextInput
                name="finishPoint"
                v-model="selectedDetails.conf['finishPoint']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['finishPointBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'finishPoint')"
              />
            </div>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-blue-400">発注額</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.price['purchasePre']"
                :disabled="true"
                class="text-center py-1 border-blue-500 input disabled-back price-width mb-2"
              />
            </div>
          </div>
          <div v-if="categoryCode=='12'" class="formula-left2 border-none">
            <span class="symbol ml-4">&#040;</span>
            <div class="inline">
              <div class="price-width text-xs text-blue-400">発注額</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.price['purchasePre']"
                :disabled="true"
                class="text-center py-1 border-blue-500 input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-5">+</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">交通費</div>
              <NumericTextInput
                name="parking"
                v-model="selectedDetails.conf['parking']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['parkingBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'parking')"
              />
            </div>
            <span class="symbol mr-2">&#041;</span>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">2槽式</div>
              <NumericTextInput
                name="twoTank"
                v-model="selectedDetails.conf['twoTank']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['twoTankBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'twoTank')"
              />
            </div>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-purple-400">合計発注額</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.totalUnitPricePre"
                :disabled="true"
                class="text-center py-1 border-purple input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <div v-if="categoryCode=='12'" class="formula-left2 border-none">
            <div class="inline ml-8">
              <div class="price-width text-xs text-purple-400">合計発注額</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.totalUnitPricePre"
                :disabled="true"
                class="text-center py-1 border-purple input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-5">÷</span>
            <div>
              <div class="price-width-sm text-xs text-gray-400 mb-2">発注額として</div>
              <span class="pt-5 number font-bold">
                0.7
              </span>
            </div>
            <span class="pt-5">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'owner')"
              />
            </div>
            <span class="pt-5">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-400">*100円単位を切上*</div>
              <NumericTextInput
                name="totalUnitPrice"
                v-model="totalUnitPrice"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>
          <!-- --------------------------------------------貯水槽清掃---end-------------------------------------------- -->
          
          <!-- --------------------------------------------消防点検----------------------------------------------- -->
          <div v-if="categoryCode=='15'" class="formula-left2">
            <div class="inline">
              <div class="price-width text-xs text-gray-500">発注額</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width"
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="setUnitPriceChange()"
              />
            </div>
            <span class="pt-6">×</span>
            <div>
              <div class="price-width text-xs text-gray-500">再点検</div>
              <NumericTextInput
                name="recheck"
                v-model="selectedDetails.conf['recheck']"
                :decimalPlace="decimal1"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['recheckBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'recheck')"
              />
            </div>
            <span class="pt-6">×</span>
            <div>
              <div class="price-width text-xs text-gray-500">土日あり回数</div>
              <NumericTextInput
                name="qty1"
                v-model="selectedDetails.conf['qty1']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['qty1BgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'qty1')"
              />
            </div>
            <span class="pt-6">×</span>
            <div>
              <div class="price-width-sm text-xs text-gray-400 mb-2">土日対応あり</div>
              <span class="pt-6 number font-bold">
                1.2
              </span>
            </div>
            <span class="pt-6">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-blue-400">発注額 土日あり</div>
              <NumericTextInput
                name="purchasePre1"
                v-model="selectedDetails.price['purchasePre1']"
                :disabled="true"
                class="text-center py-1 border-blue-500 input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <div v-if="categoryCode=='15'" class="formula-left2">
            <div class="inline">
              <div class="price-width text-xs text-gray-500">発注額</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width"
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="setUnitPriceChange()"
              />
            </div>
            <span class="pt-6">×</span>
            <div>
              <div class="price-width text-xs text-gray-500">再点検</div>
              <NumericTextInput
                name="recheck"
                v-model="selectedDetails.conf['recheck']"
                :decimalPlace="decimal1"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['recheckBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'recheck')"
              />
            </div>
            <span class="pt-6">×</span>
            <div>
              <div class="price-width text-xs text-gray-500">土日なし回数</div>
              <NumericTextInput
                name="qty0"
                v-model="selectedDetails.conf['qty0']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['qty0BgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'qty0')"
              />
            </div>
            <span class="pt-6">×</span>
            <div>
              <div class="price-width-sm text-xs text-gray-400 mb-2">土日対応なし</div>
              <span class="pt-6 number font-bold">
                1
              </span>
            </div>
            <span class="pt-6">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-green-400">発注額 土日なし</div>
              <NumericTextInput
                name="purchasePre0"
                v-model="selectedDetails.price['purchasePre0']"
                :disabled="true"
                class="text-center py-1 border-green-500 input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <div v-if="categoryCode=='15'" class="formula-left2">
            <span class="symbol ml-4 pt-1">&#040;</span>
            <div class="inline">
              <div class="price-width text-xs text-blue-400">発注額 土日あり</div>
              <NumericTextInput
                name="purchasePre1"
                v-model="selectedDetails.price['purchasePre1']"
                :disabled="true"
                class="text-center py-1 border-blue-500 input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-6">+</span>
            <div class="inline">
              <div class="price-width text-xs text-green-400">発注額 土日なし</div>
              <NumericTextInput
                name="purchasePre0"
                v-model="selectedDetails.price['purchasePre0']"
                :disabled="true"
                class="text-center py-1 border-green-500 input disabled-back price-width mb-2"
              />
            </div>
            <span class="symbol mr-2 pt-1">&#041;</span>
            <span class="pt-6">÷</span>
            <div>
              <div class="price-width text-xs text-gray-500">合計回数</div>
              <NumericTextInput
                name="qty"
                v-model="selectedDetails.conf['qty']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['qtyBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'qty')"
              />
            </div>
            <span class="pt-6">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-yellow-600">発注単価</div>
              <NumericTextInput
                name="purchasePre2"
                v-model="selectedDetails.price['purchasePre2']"
                :disabled="true"
                class="text-center py-1 border-yellow-500 input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <div v-if="categoryCode=='15'" class="formula-left2">
            <div class="inline">
              <div class="price-width text-xs text-yellow-600">発注単価</div>
              <NumericTextInput
                name="purchasePre2"
                v-model="selectedDetails.price['purchasePre2']"
                :disabled="true"
                class="text-center py-1 border-yellow-500 input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-6">+</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">駐車場代</div>
              <NumericTextInput
                name="parking"
                v-model="selectedDetails.conf['parking']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width"
                :class="setBgColor(selectedDetails.conf['parkingBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'parking')"
              />
            </div>
            <span class="pt-6">+</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">エリア加算</div>
              <NumericTextInput
                name="eria"
                v-model="selectedDetails.conf['eria']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width"
                :class="setBgColor(selectedDetails.conf['eriaBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'eria')"
              />
            </div>
            <span class="pt-6">-</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">減額</div>
              <NumericTextInput
                name="reduction"
                v-model="selectedDetails.conf['reduction']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width"
                :class="setBgColor(selectedDetails.conf['reductionBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'reduction')"
              />
            </div>
            <span class="pt-6">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-pink-600">発注単価</div>
              <NumericTextInput
                name="purchasePre3"
                v-model="selectedDetails.price['purchasePre3']"
                :disabled="true"
                class="text-center py-1 border-pink-500 input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <div v-if="categoryCode=='15'" class="formula-left2">
            <span class="symbol ml-4 pt-1">&#040;</span>
            <div class="inline">
              <div class="price-width text-xs text-pink-600">発注単価</div>
              <NumericTextInput
                name="purchasePre3"
                v-model="selectedDetails.price['purchasePre3']"
                :disabled="true"
                class="text-center py-1 border-pink-500 input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-6">+</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">消火栓</div>
              <NumericTextInput
                name="extinguisher"
                v-model="selectedDetails.conf['extinguisher']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width"
                :class="setBgColor(selectedDetails.conf['extinguisherBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'extinguisher')"
              />
            </div>
            <span class="pt-6">+</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">スプリンクラー</div>
              <NumericTextInput
                name="sprinkler"
                v-model="selectedDetails.conf['sprinkler']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width"
                :class="setBgColor(selectedDetails.conf['sprinklerBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'sprinkler')"
              />
            </div>
            <span class="symbol mr-2 pt-1">&#041;</span>
            <span class="pt-5">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
              <NumericTextInput
                name="finishPoint"
                v-model="selectedDetails.conf['finishPoint']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['finishPointBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'finishPoint')"
              />
            </div>
            <span class="pt-6">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-purple-400">合計発注額</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.totalUnitPricePre"
                :disabled="true"
                class="text-center py-1 border-purple input disabled-back price-width mb-2"
              />
            </div>
          </div>
          
          <div v-if="categoryCode=='15'" class="formula-left2 border-none">
            <div class="inline">
              <div class="price-width text-xs text-purple-400">合計発注額</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.totalUnitPricePre"
                :disabled="true"
                class="text-center py-1 border-purple input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-6">÷</span>
            <div>
              <div class="price-width-sm text-xs text-gray-400 mb-2">発注額として</div>
              <span class="pt-6 number font-bold">
                0.7
              </span>
            </div>
            <span class="pt-6">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>
            <span class="pt-6">×</span>
            <div>
              <div class="price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'owner')"
              />
            </div>
            <span class="pt-6">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-400">*100円単位を切上*</div>
              <NumericTextInput
                name="totalUnitPrice"
                v-model="totalUnitPrice"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <!-- --------------------------------------------消防点検---end-------------------------------------------- -->

          <!-- --------------------------------------------共用部設備----------------------------------------------- -->
          <div v-if="categoryCode=='18'" class="formula">
            <div class="inline">
              <div class="price-width text-xs text-gray-500">単価</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width"
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="setUnitPriceChange()"
              />
            </div>
            <span class="pt-4">×</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">竣工係数</div>
              <NumericTextInput
                name="finishPoint"
                v-model="selectedDetails.conf['finishPoint']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['finishPointBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'finishPoint')"
              />
            </div>
            <span class="pt-4">÷</span>
            <div>
              <div class="price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>
            <span class="pt-4">×</span>
            <div>
              <div class="price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'owner')"
              />
            </div>
            <span class="pt-4">＝</span>
            <NumericTextInput
              name="totalUnitPrice"
              v-model="totalUnitPrice"
              :disabled="true"
              class="text-center py-1 border-none input disabled-back price-width mt-4"
            />
          </div>

          <!-- --------------------------------------------共用部設備---end-------------------------------------------- -->
          <!-- ---------------------------------------------EV点検--------------------------------------------------- -->
          <div v-if="categoryCode=='19'" class="formula">
            <div>
              <div class="price-width text-xs text-gray-500">発注単価</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width" 
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="setOrderPriceChange(selectedDetails.unitPrice)"
              />
            </div>
            <span class="pt-4">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
              <NumericTextInput
                name="finishPoint"
                v-model="selectedDetails.conf['finishPoint']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['finishPointBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'finishPoint')"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div class="price-width text-xs  text-purple-600">合計発注額</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.totalUnitPricePre"
                :disabled="true"
                class="text-center py-1 border-purple input disabled-back price-width mb-2"
              />
            </div>
          </div>
          <div v-if="categoryCode=='19'" class="formula">
            <div class="inline">
              <div class="price-width text-xs text-purple-600">合計発注額</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.totalUnitPricePre"
                :disabled="true"
                class="text-center py-1 border-purple input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-4">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-400 mb-2">発注額として</div>
              <span class="pt-5 number font-bold">
                0.8
              </span>
            </div>
            <span class="pt-4">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>
            <span class="pt-4">×</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'owner')"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-400">*10円単位を切上*</div>
              <NumericTextInput
                name="totalUnitPrice"
                v-model="totalUnitPrice"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>
          <!-- ---------------------------------------EV点検---end---------------------------------------------- -->
          <!-- ---------------------------------------増圧ポンプ点検--------------------------------------------- -->
          <div v-if="['20'].includes(categoryCode)" class="formula">
            <span class="symbol ml-4 pt-4">&#040;</span>
            <div>
              <div class="price-width text-xs text-gray-500">発注単価</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width"
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="setUnitPriceChange()"
              />
            </div>

            <span class="pt-4">+</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-500">駐車場代</div>
              <NumericTextInput
                name="parking"
                v-model="selectedDetails.conf['parking']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width"
                :class="setBgColor(selectedDetails.conf['parkingBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'parking')"
              />
            </div>
            <span class="symbol pt-4">&#041;</span>
            <span class="pt-4">×</span>
            <div class="inline">
              <div class="cof-price-width text-xs text-gray-500">竣工係数</div>
              <NumericTextInput
                name="finishPoint"
                v-model="selectedDetails.conf['finishPoint']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['finishPointBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'finishPoint')"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-purple-600">合計発注額</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.totalUnitPricePre"
                :disabled="true"
                class="text-center py-1 border-purple input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <div v-if="['20'].includes(categoryCode)" class="formula">
            <div class="inline">
              <div class="price-width text-xs text-purple-600">合計発注額</div>
              <NumericTextInput
                name="totalUnitPricePre"
                v-model="selectedDetails.totalUnitPricePre"
                :disabled="true"
                class="text-center py-1 border-purple input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-4">÷</span>
            <div>
              <div class="price-width-sm text-xs text-gray-400 mb-2">発注額として</div>
              <span class="pt-6 number font-bold">
                0.7
              </span>
            </div>
            <span class="pt-4">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>
            <span class="pt-4">×</span>
            <div>
              <div class="price-width text-xs text-gray-500">オーナー契約</div>
              <NumericTextInput
                name="owner"
                v-model="selectedDetails.conf['owner']"
                :decimalPlace="decimal2"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['ownerBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'owner')"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-gray-400">*10円単位を切上*</div>
              <NumericTextInput
                name="totalUnitPrice"
                v-model="totalUnitPrice"
                :disabled="true"
                class="text-center py-1 border-none input disabled-back price-width mb-2"
              />
            </div>
          </div>
          <!-- ---------------------------------------増圧ポンプ点検---------------------end--------------------- -->
          <!-- --------------------------------------------MRS-------------------------------------------------- -->
          <div v-if="['29'].includes(categoryCode)" class="formula">
            <div>
              <div class="price-width text-xs text-gray-500 mb-2">40戸超過分</div>
              <span class="pt-5 number">
                <span v-if="selectedDetails.conf">
                  {{ selectedDetails.conf['over40'] }} 
                </span>
                <span class="text-xs ml-2">
                  戸
                </span>
              </span>
            </div>
            <span class="pt-4">×</span>
            <div>
              <div class="price-width text-xs text-gray-500">戸数単価</div>
              <NumericTextInput
                name="households"
                v-model="selectedDetails.conf['households']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['householdsBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'households')"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-teal-500">追加戸数分</div>
              <NumericTextInput
                name="over40"
                v-model="selectedDetails.price['over40']"
                :disabled="true"
                class="text-center py-1 border-teal-500 input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <div v-if="['29'].includes(categoryCode)" class="formula">
            <span class="symbol ml-4 pt-4">&#040;</span>
            <div>
              <div class="price-width text-xs text-gray-500 mb-2">清掃回数 週3超過分</div>
              <span class="pt-5 number">
                <span v-if="selectedDetails.conf">
                  {{ selectedDetails.conf['cleaningOver3'] }}
                </span>
              </span>
            </div>
            <span class="pt-4">×</span>
            <div>
              <div class="price-width text-xs text-gray-500">清掃単価</div>
              <NumericTextInput
                name="dailycleaning"
                v-model="selectedDetails.conf['cleaning']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['dailycleaningBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'dailycleaning')"
              />
            </div>
            <span class="symbol pt-4">&#041;</span>
            <span class="pt-4">+</span>
            <span class="symbol ml-4 pt-4">&#040;</span>
            <div>
              <div class="text-xs text-gray-500 mb-2">ゴミ搬出 週4超過分</div>
              <span class="pt-5 number">
                <span v-if="selectedDetails.conf">
                  {{ selectedDetails.conf['garbageOver4'] }}
                </span>
              </span>
            </div>
            <span class="pt-4">×</span>
            <div>
              <div class="text-xs text-gray-500">ゴミ搬出</div>
              <NumericTextInput
                name="garbage"
                v-model="selectedDetails.conf['garbage']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width mb-2"
                :class="setBgColor(selectedDetails.conf['garbageBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'garbage')"
              />
            </div>
            <span class="symbol pt-4">&#041;</span>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div class="price-width text-xs text-pink-600">オプション</div>
              <NumericTextInput
                name="add"
                v-model="selectedDetails.price['add']"
                :disabled="true"
                class="text-center py-1 border-pink-500 input disabled-back price-width mb-2"
              />
            </div>
          </div>

          <div v-if="['29'].includes(categoryCode)" class="formula">
            <span class="symbol ml-4 pt-4">&#040;</span>
            <div>
              <div class="price-width text-xs text-gray-500">基本単価</div>
              <NumericTextInput
                name="unitPrice"
                v-model="selectedDetails.unitPrice"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width"
                :class="setBgColor(selectedDetails.unitPriceBgColor)"
                @change="setUnitPriceChange()"
              />
            </div>
            <span class="pt-4">+</span>
            <div class="inline">
              <div class="price-width text-xs text-teal-500">追加戸数分</div>
              <NumericTextInput
                name="over40"
                v-model="selectedDetails.price['over40']"
                :disabled="true"
                class="text-center py-1 border-teal-500 input disabled-back price-width mb-2"
              />
            </div>
            <span class="pt-4">+</span>
            <div class="inline">
              <div class="price-width text-xs text-pink-600">オプション</div>
              <NumericTextInput
                name="add"
                v-model="selectedDetails.price['add']"
                :disabled="true"
                class="text-center py-1 border-pink-500 input disabled-back price-width mb-2"
              />
            </div>
            <span class="symbol pt-4">&#041;</span>
            <span class="pt-4">÷</span>
            <div>
              <div class="cof-price-width text-xs text-gray-500">協力費</div>
              <input
                v-model="selectedDetails.conf['compe']"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray cof-price-width mb-2"
                :class="setBgColor(selectedDetails.conf['compeBgColor'])"
                @change="changeCalcAndPrice(selectedDetails.conf, 'compe')"
              />
            </div>
            <span class="pt-4">＝</span>
            <NumericTextInput
              name="totalUnitPrice"
              v-model="totalUnitPrice"
              :disabled="true"
              class="text-center py-1 border-none input disabled-back price-width mt-4"
            />
          </div>
          <!-- --------------------------------------------MRS-----------------------------------------end--------------------- -->
          <!-- 発注 -->
          <div class="sm:col-span-full pt-4 table2 mr-3">
            <div class="flex justify-between items-end">
              <label class="text-xs font-bold text-gray-700 flex justify-between">
                発注明細
              </label>
              <div class="text-xs ml-10 font-normal text-gray-500" v-if="!selectedDetails.purchaseRateAlwaysChange&&purchaseMsgFlag && selectedDetails.totalUnitPricePre != 'NaN'">見積り単価を手動で変更した場合、式と合わせているため発注率が変わります。</div>
              <!-- <div class="text-xs ml-10 font-normal text-gray-500" v-if="['12', '15', '19', '20'].includes(categoryCode)">式の「発注額」と合わせているため、発注明細を手動で変更できません。</div> -->

              <!-- 常に発注率を変更 -->
              <div class="ml-auto mt-auto w-fit mr-8" v-tooltip="'発注金額を変更しても、受注金額は変わらずに発注率が変わるようにします'">
                <input
                  type="checkbox"
                  id="purchaseRateAlwaysChange"
                  class="cursor-pointer"
                  v-model="selectedDetails.purchaseRateAlwaysChange"
                >
                <label for="purchaseRateAlwaysChange" class="font-bold text-sm ml-1 p-1 cursor-pointer" :class="selectedDetails.purchaseRateAlwaysChange?'text-blue-700':'text-gray-500'"> 常に発注率を変更</label>
              </div>

              <button class="w-auto py-0 text-sm text-indigo-800 hover:text-indigo-500 mr-4" @click="openPurchaseModal()">発注先マスタ編集</button>
            </div>
            <div class="mt-1">
              <EstimatePurchase
                :purchase="purchase"
                :allDisabled="false"
                :purchaseModalShow="purchaseModalShow"
                :purchaseReload="purchaseReload"
                :typeName="false"
                :detailModal="true"
                @getPurchasePrice="getPurchasePrice"
                @changeOpenInit="changeOpenInit"
                @changeInitRate="changeInitRate"
              >
              </EstimatePurchase>
            </div>
          </div>

          <!-- 手数料 -->
          <div class="sm:col-span-full pt-4 table2 mr-3">
            <div v-if="commissionMst" class="text-xs font-bold text-center text-commission">{{ commissionMst.msg }}</div>
            <div class="flex justify-between items-end">
              <label class="text-xs font-bold text-gray-700 flex justify-between">手数料明細</label>
              <button class="w-auto py-0 text-sm ml-auto mr-3 text-indigo-800 hover:text-indigo-500" @click="commissionModalShow=true">手数料マスタ編集</button>
            </div>
            <div class="mt-1">
              <EstimateCommission
                :commission="commission"
                :commissionReload="commissionReload"
                @rateSelected="rateSelected"
                @rateBlur="rateBlur"
                @changeCommissionPrice="changeCommissionPrice"
              >
              </EstimateCommission>
            </div>
          </div>

          <div class="toggle flex items-center mt-8">
            <span class="text-gray-500 font-bold text-xs mt-auto">並べ替え</span>
            <Toggle v-model="sortableDetail" size="small" class="ml-1 mt-auto"/>

            <div v-if="selectedDetails.monthlyFeeFlag&&selectedDetails.monthlyFee" class="ml-3">
              <p class="text-gray-400 text-xs text-center w-full">*1円単位を切捨*</p>
              <div class="py-1 px-3 border border-gray-300 rounded font-bold">月額：{{ (selectedDetails.monthlyFee).toLocaleString() }}円</div>
            </div>

            <div v-if="monthlyError" class="text-red-700 font-bold m-auto">{{ monthlyError }}</div>

            <!-- 月額計算ボタン（日常・管理員・過去仕様の定期清掃、特別清掃ではボタンは非表示） -->
            <div class="ml-auto mt-auto w-fit mr-8" v-if="!disableMonthly">
              <input
                type="checkbox"
                id="monthlyFee"
                class="cursor-pointer"
                @change="changeMonthlyFee()"
                v-model="selectedDetails.monthlyFeeFlag"
              >
              <label for="monthlyFee" class="font-bold text-sm ml-1 p-1 cursor-pointer" :class="selectedDetails.monthlyFeeFlag?'text-blue-700':'text-gray-500'"> 月額請求</label>
            </div>
          </div>

          <!-- 特別、管球、ゴミ、物販 -->
          <span v-if="['14', '23', '24', '25'].includes(categoryCode)" class="text-xs text-gray-400 msg-info font-medium">
            <span v-if="categoryCode=='14'">{{ msg }}</span>
            <span v-if="categoryCode=='23'">{{ msg1 }}</span>
            <span class="text-purple-400">基本単価</span>に入力してください。オーナー係数等が自動で計算されます。
          </span>

          <!-- テーブル -->
          <table class="table-fixed table2 mt-2">
            <thead>
              <tr>
                <th class="py-2 text-center text-xs font-medium text-gray-500 tracking-wider bg-gray-50 border-gray">
                  No.
                </th>
                <th v-if="categoryCode=='23'" class="py-2 text-center text-xs font-medium text-indigo-400 tracking-wider bg-gray-50 border-gray">
                  種類
                </th>
                <th class="py-2 text-center text-xs font-medium text-gray-500 tracking-wider bg-gray-50 border-gray">
                  内容
                </th>
                <th colspan="2" class="py-2 text-center text-xs font-medium text-gray-500 tracking-wider bg-gray-50 border-gray">
                  数量
                </th>
                <!-- 定期清掃/スポット清掃 300㎡超えの場合のみ表示 -->
                <th v-if="['5', '28'].includes(categoryCode) && (exceed300 || selectedDetails.conf['meter'] == '3')" class="py-2 text-center text-xs font-medium text-purple-400 tracking-wider bg-gray-50 border-gray">
                  基本単価
                </th>
                <!-- 定期清掃/スポット清掃 300㎡超えの場合のみ表示 -->
                <th v-if="['5', '28'].includes(categoryCode) && (exceed300 || selectedDetails.conf['meter'] == '3')" class="py-2 text-center text-xs font-medium text-blue-300 tracking-wider bg-gray-50 border-gray">
                  項目による係数
                </th>
                <!-- 特別、管球、ゴミ、物販のみ表示 -->
                <th v-if="['14', '23', '24', '25'].includes(categoryCode)" class="py-2 text-center text-xs font-medium text-purple-400 tracking-wider bg-gray-50 border-gray">
                  基本単価
                </th>
                <!-- 特別、管球、ゴミ、物販のみ表示 -->
                <th v-if="['14', '23', '24', '25'].includes(categoryCode)" class="py-2 text-center text-xs font-medium text-blue-400 tracking-wider bg-gray-50 border-gray">
                  オーナー係数
                </th>
                <!-- 特別のみ表示 -->
                <th v-if="['14'].includes(categoryCode)" class="py-2 text-center text-xs font-medium text-yellow-600 tracking-wider bg-gray-50 border-gray">
                  竣工係数
                </th>
                <!-- 特別、管球、ゴミ、物販のみ表示 -->
                <th v-if="['14', '23', '24', '25'].includes(categoryCode)" class="py-2 text-center text-xs font-medium text-green-400 tracking-wider bg-gray-50 border-gray">
                  ÷ 協力費
                </th>
                <th class="py-2 text-center text-xs font-medium text-gray-500 tracking-wider bg-gray-50 border-gray">
                  単価
                </th>
                <th colspan="2" class="py-2 text-center text-xs font-medium text-gray-500 tracking-wider bg-gray-50 border-gray">
                  数量
                </th>
                <th class="py-2 text-center text-xs font-medium text-gray-500 tracking-wider bg-gray-50 border-gray">
                  単価
                </th>
                <th class="py-2 text-center text-xs font-medium text-gray-500 tracking-wider bg-gray-50 border-gray">
                  <!-- プラスボタン -->
                  <PlusCircleIcon class="text-indigo-500 hover:text-indigo-700 active:text-indigo-600 cursor-pointer col-actions" @click="addRowDetail" />
                </th>
              </tr>
            </thead>

            <draggable 
              :key="renderKey"
              ref="tableBody"
              :list="selectedDetails.display"
              direction="vertical"
              handle=".row-drag-handle"
              tag="tbody" 
              class="bg-white divide-y divide-gray-200"
              @change="detailOrderChanged"
            >

              <tr v-for="(display, index) in selectedDetails.display" :key="index" :class="setDisableColor(false, display.type)">
                <td class="text-center px-0.5 py-0.5 whitespace-nowrap text-sm font-medium text-gray-900 border-gray col-no">
                  <template v-if="sortableDetail">
                    <div class="flex justify-center items-center row-drag-handle cursor-pointer">
                      <MenuIcon class="text-gray-400" />
                    </div>
                  </template>
                  <template v-else>
                    {{ index + 1 }}
                  </template>
                </td>
                <!-- 管球交換 種類 -->
                <td v-if="categoryCode=='23' && display.kind!=undefined" class="col-midle text-center px-1 py-0.5 text-xs font-medium text-gray-400 tracking-wider border-gray td-height">
                  <!-- ドロップダウン -->
                  <div>
                    <WmsSuggestInput
                      name="kind"
                      :embed="true"
                      :dynamic="false"
                      displayField="kind"
                      :incrementalSearch="false"
                      :selectionItems="[{code:'0', kind:'全て'},{code:'1', kind:'LED以外'},{code:'2', kind:'LED'}]"
                      class="embed"
                      v-model="display.kind"
                      :disabled="setDisabled(display.type)"
                      @selected="selectedKind($event, index)"
                      @change="changeKind(index)"
                    />
                  </div>
                </td>
                <!-- 内容 定期清掃、植栽、スポット清掃、特別、管球、以外 -->
                <td v-if="!['5', '13', '14', '23', '28'].includes(categoryCode)" class="col-xl text-center px-1 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height">
                  <textarea
                    :rows="['3', '4'].includes(categoryCode)?2:1"
                    autocomplete="off"
                    type="text" 
                    name="itemName"
                    v-model="display.name"
                    class="w-full focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                    :class="[setDisableColor(false, display.type), setBgColor(display.nameBgColor)]"
                    :disabled="setDisabled(display.type) || display.name == '調整費'"
                    @change="changeDisplayName(display)"
                  >
                  </textarea>
                </td>
                <!-- 内容 定期清掃,植栽,スポット清掃 -->
                <td v-else-if="['5', '13', '28'].includes(categoryCode)" class="col-xl text-center px-1 text-xs font-medium text-gray-500 tracking-wider border-gray td-height">
                  <!-- ドロップダウン -->
                  <div class="m-auto">
                    <WmsSuggestInput
                      name="fixedName"
                      :embed="true"
                      :dynamic="false"
                      displayField="name"
                      :incrementalSearch="false"
                      :selectionItems="nameList"
                      class="embed"
                      :disabled="setDisabled(display.type) || display.name == '調整費'"
                      v-model="display.name"
                      @selected="getPlantingNote($event, index)"
                      @blur="getPlantingNote($event, index)"
                    />
                  </div>
                </td>
                <!-- 特別清掃、管球交換 項目名 -->
                <td v-else class="col-xl text-center px-1 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height">
                  <!-- ドロップダウン -->
                  <div :class="setWidth(display.spFlag)">
                    <WmsSuggestInput
                      name="name"
                      :embed="true"
                      :dynamic="false"
                      displayField="name"
                      :incrementalSearch="false"
                      :selectionItems="selectionLists(display.kindCode)"
                      class="embed"
                      v-model="display.name"
                      :disabled="setDisabled(display.type) || display.name == '調整費'"
                      @selected="selectedName($event, index)"
                    />
                  </div>
                  <Icon
                      v-if="display.spFlag"
                      iconName="PencilAlt" 
                      :clickable="true" 
                      class="w-4 h-4 mr-0.5 inline"
                      :class="[setSpBgColor(display),setDisableColor(false, display.type)]"
                      :disabled="setDisabled(display.type)"
                      @click="selectedSpecial(null, index)"
                    />
                </td>

                <!-- 詳細数 -->
                <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height col-small">
                  <NumericTextInput
                    name="qty"
                    v-model="display.qty"
                    class="w-full text-right focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                    :class="setDisableColor(display.qty=='', display.type)"
                    :disabled="setDisabled(display.type)"
                    :decimalPlace="display.qty&&!Number.isInteger(Number(display.qty))?decimal1 : 0"
                    @change="changeCategoryPrice()"
                  />
                </td>

                <!-- 詳細単位 -->
                <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height col-sm">
                  <!-- ドロップダウン -->
                  <div class="m-auto">
                    <WmsSuggestInput
                      name="unitName"
                      :embed="true"
                      :dynamic="false"
                      displayField="name"
                      :incrementalSearch="false"
                      :selectionItems="unitNameList"
                      class="embed"
                      :disabled="setDisabled(display.type)"
                      v-model="display.unitName"
                      @selected="changeCategoryPrice()"
                    />
                  </div>
                </td>
                <!-- 定期・スポット清掃 300㎡超えの場合のみ表示（基本単価） -->
                <td v-if="['5', '28'].includes(categoryCode) && (exceed300 || selectedDetails.conf['meter'] == '3')" class="px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height col-smm">
                  <NumericTextInput
                    name="exceed300PriceKind"
                    v-model="display.priceKind"
                    class="text-gray-700 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-none cursor-not-allowed"
                    :class="[setDisableColor(display.priceKind==''&&display.name.indexOf('駐車場代')==-1&&display.name.indexOf('発電機')==-1&&display.name.indexOf('調整費')==-1, display.type), setBgColor(display.priceKindBgColor)]"
                    :disabled="true"
                  />
                </td>
                <!-- 定期・スポット清掃 300㎡超えの場合のみ表示（項目による係数） -->
                <td v-if="['5', '28'].includes(categoryCode) && (exceed300 || selectedDetails.conf['meter'] == '3')" class="px-0.5 py-0.5 text-xs text-gray-500 tracking-wider border-gray td-height col-l">
                  <!-- ドロップダウン -->
                  <div class="m-auto">
                    <select
                      :name="'exceed300KindCoef' + index"
                      v-model="selectedDetails.display[index].kindCoef"
                      class="text-xs border-0 w-full"
                      @change="changeKindCoef()"
                    >
                      <option
                        v-for="key in selectedDetails.conf.kindCoef" :key='key.id'
                        :value="key.name"
                        class="bg-white">
                        {{ key.name }}
                      </option>
                    </select>
                  </div>
                </td>

                <!-- 特別、管球、ゴミ、物販のみ表示（基本単価） -->
                <td v-if="['14', '23', '24', '25'].includes(categoryCode)" class="px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height col-smm">
                  <NumericTextInput
                    name="priceKind"
                    v-model="display.priceKind"
                    class="sm:text-sm focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full border-none text-right"
                    :class="[setDisableColor(!display.priceKind&&display.name.indexOf('調整費')==-1, ''), setBgColor(display.priceKindBgColor)]"
                    :disabled="setDisabled('')||display.name.indexOf('調整費')>-1"
                    @change="setDisplayTrashOrSaleChange(display, 'priceKindBgColor')"
                  />
                </td>

                <!-- 特別、管球、ゴミ、物販のみ表示（オーナー係数） -->
                <td v-if="['14', '23', '24', '25'].includes(categoryCode)" class="px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height col-smm">
                  <NumericTextInput
                    name="owner"
                    :decimalPlace="decimal2"
                    v-model="display.owner"
                    class="sm:text-sm focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full border-none text-right"
                    :class="[setDisableColor(false, ''), setBgColor(display.ownerBgColor)]"
                    :disabled="setDisabled('')||display.name.indexOf('調整費')>-1"
                    @change="setDisplayTrashOrSaleChange(display, 'ownerBgColor')"
                  />
                </td>

                <!-- 特別、のみ表示（竣工係数） -->
                <td v-if="['14'].includes(categoryCode)" class="px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height col-smm">
                  <NumericTextInput
                    name="owner"
                    :decimalPlace="decimal2"
                    v-model="display.finishPoint"
                    class="sm:text-sm focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full border-none text-right"
                    :class="[setDisableColor(false, ''), setBgColor(display.finishPointBgColor)]"
                    :disabled="setDisabled('')||display.name.indexOf('調整費')>-1"
                    @change="setDisplayTrashOrSaleChange(display, 'finishPointBgColor')"
                  />
                </td>

                <!-- 特別、管球、ゴミ、物販のみ表示（協力費） -->
                <td v-if="['14', '23', '24', '25'].includes(categoryCode)" class="px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height col-smm">
                  <NumericTextInput
                    name="owner"
                    :decimalPlace="decimal2"
                    v-model="display.compe"
                    class="sm:text-sm focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full border-none text-right"
                    :class="[setDisableColor(false, ''), setBgColor(display.compeBgColor)]"
                    :disabled="setDisabled('')||display.name.indexOf('調整費')>-1"
                    @change="setDisplayTrashOrSaleChange(display, 'compeBgColor')"
                  />
                </td>

                <!-- 詳細金額 -->
                <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height col-midle">
                  <NumericTextInput
                    name="price"
                    v-model="display.price"
                    :class="[setBgColor(display.detailBgColor || display.trashTimesBgColor), setDisableColor(!display.detailBgColor && display.price=='', display.type)]"
                    :disabled="setDisabled(display.type)"
                    @change="setPriceChange(display, 1)"
                    class="w-full text-right focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                  />
                </td>

                <!-- カテゴリー数 -->
                <td v-if="index==0" :rowspan="selectedDetails.display.length" class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height col-small">
                  <NumericTextInput
                    name="qty2"
                    v-model="display.qty2"
                    :class="setDisableColor(display.qty2=='', '')"
                    class="max-h w-full text-right py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                    :disabled="setDisabled('')"
                    @change="changeQty2()"
                  />
                </td>

                <!-- カテゴリー単位 -->
                <td v-if="index==0" :rowspan="selectedDetails.display.length" class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height col-sm" :class="!selectedDetails.getPriceFlag?'bg-gray-200':'bg-gray-100'">
                  <!-- ドロップダウン -->
                  <div class="m-auto">
                    <WmsSuggestInput
                      name="unitName2"
                      :embed="true"
                      :dynamic="false"
                      displayField="name"
                      :incrementalSearch="false"
                      :selectionItems="unitNameList"
                      class="embed"
                      :class="setDisableColor(false,'')"
                      :disabled="setDisabled('')"
                      v-model="display.unitName2"
                      @change="changeUnitName2()"
                    />
                  </div>
                </td>
                
                <!-- カテゴリー金額 -->
                <td v-if="index==0" :rowspan="selectedDetails.display.length" class="text-right px-3 py-0.5 text-sm text-gray-500 border-gray td-height col-midle" :class="[setBgColor(display.categoryBgColor),setDisableColor(false, '')]">
                  <!-- <NumericTextInput
                    name="price2"
                    v-model="display.price2"
                    :class="[setBgColor(display.categoryBgColor),setDisableColor(false, '')]"
                    :disabled="true"
                    @change="setPriceChange(display, 2)"
                    class="max-h w-full text-right py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                  /> -->
                  {{ numberToLocale(display.price2) }}
                </td>
                <td class="px-0.5 py-0.5 whitespace-nowrap text-center text-sm font-medium border-gray">
                  <div class="flex justify-center">
                    <div @click="deleteDetailRow(index)">
                      <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
                    </div>
                  </div>
                </td>
              </tr>
            </draggable>
          </table>

          <div class="note pt-4" v-if="show">
            <div class="mt-1" @copy="onCopyNote()">
              <EstimateRichTextInput
                name="note" 
                caption="特記事項"
                :value="selectedDetails.noteHTML"
                :pasteHtml="noteStyle"
                @change="noteChange($event)"
              >
                <template #additionalToolbar>
                  <button class="w-auto px-2 py-1 m-auto rounded text-xs" :class="noteStyle?'bg-indigo-600 text-white':'bg-gray-300 text-white'" @click="noteStyle=!noteStyle">スタイルを維持してコピペ</button>
                  <button class="w-auto py-0 text-sm ml-auto text-indigo-800 hover:text-indigo-500" @click="selectNote">特記事項を追加</button>
                </template>
              </EstimateRichTextInput>
            </div>
          </div>

          <!-- 完了ボタン -->
          <PrimaryButton text="完了" size="sm px-10 py-3 place-items-end block mt-3 mb-3 btn cmp-btn" @click="saveDetail" />

          <!-- 特別清掃モーダル -->
          <EstimateDetailSpecialModal 
            v-model="specialShow" 
            :selectItem="param.select"
            :editData="param.editData"
            :title="param.name"
            :households="param.households"
            :residenceType="param.residenceType"
            :lighting="param.lightingUnit"
            :lightingBgColor="param.lightingBgColor"
            :lightingTab="param.lightingTab"
            :open="specialCount"
            :selectCallback="param.spCallback"
          />

          <!-- 特記事項を追加 -->
          <EstimateNoteSelectorModal
            v-model="noteSelectorShow" 
            @apply="applyNotes"
          />
          
          <!-- 発注マスタ編集 -->
          <EstimatePurchaseModal 
            v-model="purchaseModalShow"
            :purchaseModalShow="purchaseModalShow"
            @closePurchaseModal="closePurchaseModal"
          />

          <!-- 手数料マスタ編集 -->
          <EstimateCommissionModal 
            v-model="commissionModalShow"
            :open="commissionModalShow"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import draggable from "vuedraggable"
import Modal from '@components/Modal.vue'
import Toggle from '@components/Toggle.vue'
import { TrashIcon, PlusCircleIcon, MenuIcon } from '@vue-hero-icons/solid'
import NumericTextInput from '@components/NumericTextInput.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import EstimateDetailSpecialModal from './EstimateDetailSpecialModal.vue'
import WmsTimeInput2 from '@wmscomponents/WmsTimeInput2.vue'
import EstimateNoteSelectorModal from '../components/EstimateNoteSelectorModal.vue'
import EstimateRichTextInput from '../components/EstimateRichTextInput.vue'
import EstimatePurchase from '../components/EstimatePurchase.vue'
import EstimatePurchaseModal from '../components/EstimatePurchaseModal.vue'
import EstimateCommission from '../components/EstimateCommission.vue'
import EstimateCommissionModal from '../components/EstimateCommissionModal.vue'
// import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import Icon from '@components/Icon.vue'
import Multiselect from 'vue-multiselect'
import * as eriaManager from '@managers/eriaManager'
import * as estimateManager from '@managers/estimateManager'
import * as constants from '@libs/constantsEstimate'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import BigNumber from "bignumber.js"

const WEEK_LIST = [
  {id: '0', name: '月', active: false, weekDetail: ['毎週']},
  {id: '1', name: '火', active: false, weekDetail: ['毎週']},
  {id: '2', name: '水', active: false, weekDetail: ['毎週']},
  {id: '3', name: '木', active: false, weekDetail: ['毎週']},
  {id: '4', name: '金', active: false, weekDetail: ['毎週']},
  {id: '5', name: '土', active: false, weekDetail: ['毎週']},
  {id: '6', name: '日', active: false, weekDetail: ['毎週']},
]

export default {
  components: {
    WmsSuggestInput,
    draggable,
    Icon,
    Modal,
    Toggle,
    TrashIcon,
    PlusCircleIcon,
    MenuIcon,
    NumericTextInput,
    PrimaryButton,
    EstimateDetailSpecialModal,
    EstimateRichTextInput,
    EstimateNoteSelectorModal,
    WmsTimeInput2,
    EstimatePurchase,
    EstimatePurchaseModal,
    EstimateCommission,
    EstimateCommissionModal,
    Multiselect
    // WmsTextInput
  },

  inheritAttrs: false,

  props: {
    categoryCode: {
      type: String,
      default: null
    },
    categoryName: {
      type: String,
      default: null
    },
    buildingInfo: {
      type: Object,
      default: null
    },
    priceChange: {
      type: Function,
    },
    modalData: {
      type: Object,
      default: null
    },
    rows: {
      type: Array
    },
    index: {
      type: Number,
      default: null
    },
    open: {
      type: Number,
      default: null
    },
    selectCallback: {
      type: Function,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    },
    purchaseData: {
      type: Array,
      default: null
    },
    commissionData: {
      type: Object,
      default: null
    },
    commissionMst: {
      type: Object,
      default: null
    },
  },

  data() {
    return {
      renderKey: 0,
      active: false,

      selectedDetails: {
        // 親画面表示内容
        display:[
          {
            // 内容
            name: '',
            // 数量
            qty: '',
            // 単位
            unitName: 'ヶ月',
            // 金額
            price: '',
            // 金額変更
            detailBgColor: false,
            // カテゴリー数量
            qty2: '',
            // カテゴリ－単位
            unitName2: 'ヶ月',
            // カテゴリー金額
            price2: '',
            // カテゴリー金額変更
            categoryBgColor: false,
            // 計算結果を表示している行 calc
            type: 'calc'
          }
        ],
        // 選択内容履歴(ツールチップ用)
        toolTip: {},
        // 係数
        conf: {},
        // 特記事項
        noteHTML: '',
        // 単価表の単価
        unitPrice: '',
        // その他、計算結果の金額
        price: {},
        // 上の項目表の選択、入力した値
        selectedValue: [],
        // 特別清掃のオーナー契約
        spOwner: '',
        // 単価変更フラグ
        unitPriceBgColor: false,
        // 価格変更フラグ（どれか1つでも変更したらtrue）
        iconBgColor: false,
        // 日常・管理 特記事項で使用➡仕様変更：人数分表示
        // remarks: {start: '', end: '', week: []},
        remarks: [
          {start: '', end: '', week: [], weekDetail: [], breakHour: '', breakMinutes: ''}
        ],
        // 日常・管理 年末年始休み
        newYearHoliday: [],
        // 定期・スポット清掃の表
        displayFixed:[
          {
            // 内容
            name: '',
            // 数量
            qty: '',
            // 単位
            unitName: '㎡'
          }
        ],
        // 単価取得ボタン false:一度も押下していない
        getPriceFlag: false,
        // 親画面に渡すモーダル配列（日常、管理、定期）
        modalTabData: [],
        // 発注金額を手動で変更
        changePurchasePrice: false,
        // 月額計算ONOFF
        monthlyFeeFlag: false,
        // 月額金額
        monthlyFee: null,
        // オーナー係数抜きの金額
        // withoutOwner: 0,
      },
      // 表示する項目
      displaySubItems: [],
      // 計算結果
      totalUnitPrice: '',
      // 並べ替え
      sortableDetail: false,
      // 計算式表示フラグ
      formulaDispFlag: false,
      // 小数の表示
      decimal1: 1,
      decimal2: 2,
      // 画面にバインドするための変数
      valueArr: [],
      forBind: 0,
      // 特別清掃モーダルを開くための変数
      specialCount: 0,
      // 特別清掃モーダルに渡すデータ
      param: [],
      // 特別清掃モーダル表示・非表示
      specialShow: false,
      // 単位
      unitNameList: [
        {name: '㎡'},
        {name: '回'},
        {name: '個'},
        {name: '式'},
        {name: 'ｍ'},
        {name: '箇所'},
        {name: '系統'},
        {name: 'ヶ月'},
        {name: '回/年'},
        {name: '回/2年'},
        {name: '回/3年'}
      ],
      // 定期清掃 作業範囲 表示内容
      fixedContentSelection: [
        {value: '0', name: '合計作業範囲のみ'},
        {value: '1', name: '作業部位も表示'},
        {value: '2', name: '表示なし'}
      ],
      // 日常、管理、定期用の切替タブ
      changeModalTab: [{id: '', name: '', active: true, data: {}, msg: '', changeDisp: false, disabledDisp: true}],
      // メッセージ
      msg: '',
      // 特記事項追加
      noteSelectorShow: false,
      // 曜日選択
      week: [utils.clone(WEEK_LIST)],
      // 曜日の詳細選択肢
      weekDetailList: [
        '毎週',
        '隔週',
        '第1',
        '第2',
        '第3',
        '第4',
        '第5'
      ],
      // 時間帯
      hourItems: [
        { text: '7' },
        { text: '8' },
        { text: '9' },
        { text: '10' },
        { text: '11' },
        { text: '12' },
        { text: '13' },
        { text: '14' },
        { text: '15' },
        { text: '16' },
        { text: '17' },
        { text: '18' },
        { text: '19' },
        { text: '20' },
        { text: '21' },
        { text: '22' },
        { text: '23' }
      ],
      minuteItems: [
        { text: '00' },
        { text: '15' },
        { text: '30' },
        { text: '45' }
      ],
      // 年末年始選択
      newYearHoliday: [
        { value: 30 },
        { value: 31 },
        { value: 1 },
        { value: 2 },
        { value: 3 },
      ],
      // 発注モーダル
      purchaseModalShow: false,
      // 発注明細（過去の見積りにはないため、ここで宣言）
      purchase: [{
        categoryCode: '',
        categoryName: '',
        groupName: '',
        supplier: '',
        rate: '',
        price: '',
        priceBgColor: false,
        rateBgColor: false
      }],
      // カテゴリ金額合計
      categoryAmount: 0,
      // 発注明細表示のための変数
      purchaseReload: 0,
      // 発注金額手動で変更後、自動計算直後
      changePurchasePrice: false,
      // 発注率超えた
      overPurchaseRate: false,
      // 初期発注率
      initRate: 0,
      // モーダルを開いたばかり
      openInit: true,
      // 手数料モーダル
      commissionModalShow: false,
      // 手数料明細（過去の見積りにはないため、ここで宣言）
      commission: [{
        categoryCode: '',
        categoryName: '',
        groupName: '',
        estimatePrice: '',
        rate: '',
        commissionPrice: '',
        price: ''
      }],
      // 手数料明細表示のための変数
      commissionReload: 100,
      // 特別清掃のデータが新仕様のものか
      newData: false,
      // 月額請求計算不可
      monthlyError: null,
      // 特記事項スタイルそのままコピペ
      noteStyle: false,
      // 単価を取得ボタンを押した直後
      getPriceMasterBtn: false,
      // 特別清掃の選択項目
      spItem: ['spOwner', 'spFinishPoint', 'spCompe'],
      spItemName: ['オーナー契約', '竣工(●年後)', '協力費(%)']
    }
  },

  watch: {
    async open() {
      this.fetchEstimateSubItem(this.modalData, this.rows[0].modal)

      // 発注金額
      this.categoryAmountCalc()
      this.purchase[0] = utils.clone(this.purchaseData[0])
      this.getCommissionData(this.modalData, this.rows[0].modal)
      this.getInitPurchaseRate()
      this.purchaseReload++
    },

    'totalUnitPrice': {
      handler: function() {
        if ((['14'].includes(this.categoryCode) && !this.newData ) || this.exceed300 || (['5', '28'].includes(this.categoryCode) && this.selectedDetails.conf['meter'] == '3')) {
          return
        }

        this.setUnitPrice()
      }
    },

    'displaySubItems': {
      handler: function() {
        if (!this.displaySubItems.length) {
          return
        }
        this.calc()
      },
      deep: true
    },

    'selectedDetails.unitPrice': {
      handler: function() {
        if (this.selectedDetails.unitPrice == '') {
          return
        }
        this.calc()
      },
      deep: true
    },
    
    'selectedDetails.display': {
      handler: function() {
        if (this.changePurchasePrice || (!this.active && this.selectedDetails.changePurchasePrice)) {
          return
        }
        // 定期・スポット清掃の300㎡超えの場合、再計算
        if (['5', '28'].includes(this.categoryCode) && (this.exceed300 || this.selectedDetails.conf['meter'] == '3') && this.active) {
          this.calc()
        }
        // 管球の計算がされなくなるため、ここでも計算
        this.categoryCalc()
      },
      deep: true
    },

    'categoryAmount': {
      handler: async function() {
        await this.getCommissionPrice()
        
        this.openInit = false

        // 月額請求ONであれば、再計算
        this.changeMonthlyFee()
      }
    },

    'uncertain': {
      handler: async function() {
        // 定期・スポット清掃
        if (['5', '28'].includes(this.categoryCode)) {
          let target = this.displaySubItems
          // ㎡数不明
          if (this.uncertain) {
            if (target && target[4]) {
              target[4].name = '戸数（作業時間の目安・概数算出）'
            }
          } else {
            if (target && target[4]) {
              target[4].name = '戸数（作業時間の目安）省略可'
            }
          }
        }
      }
    }
  },

  computed: {
    // 計算式の詳細の表示、非表示CSSクラス
    formulaDisp() {
      if (this.formulaDispFlag) {
        return 'formula-open-normal'
      } else {
        return 'formula-close'
      }
    },

    // 日常清掃,管理員業務_計算式で使う清掃時間
    cleaningHours() {
      if ((this.categoryCode == '3' || this.categoryCode=='4') && this.displaySubItems[4]) {
        if (this.displaySubItems[4].value == '0') {
          let val = this.displaySubItems[6].value
          if (val) {
            val = val.replace('以内', '')
          }
          return val
        } else {
          return this.displaySubItems[5].value
        }
      } else {
        return '0'
      }
    },

    // 定期・スポット清掃_300㎡超えの場合のテーブル表示
    exceed300() {
      if (['5', '28'].includes(this.categoryCode) && this.displaySubItems[1]) {
        if (this.displaySubItems[1].value == '3') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },

    // 日常・管理・定期・スポット清掃_㎡数不明の場合、フラグ立てる
    uncertain() {
      if (['3', '4'].includes(this.categoryCode) && this.displaySubItems[11]) {
        if (this.displaySubItems[11].value == '4') {
          return true
        } else {
          return false
        }

      } else if (['5', '28'].includes(this.categoryCode) && this.displaySubItems[1]) {
        if (this.displaySubItems[1].value == '4') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },

    /**
     * コメント
     */
    msg1() {
      if (this.categoryCode == '23'){
        return '「種類」、「内容」セルをクリックすると候補が出てきます。'
      } else {
        return ''
      }
    },

    /**
     * 管球リスト
     */
    bulbArr() {
      if (this.categoryCode == '23' && this.displaySubItems.length) {
        
        // 管球データ全て
        let all = this.$store.getters.estimateItemPriceList.filter((p) => {
          return p.parentCode == this.categoryCode
        })

        // LED以外のデータ
        let other = all.filter((a) => {
          return a.priceCode == '1'
        })

        // LEDのデータ
        let led = all.filter((a) => {
          return a.priceCode == '2'
        })
        let arr = []
        arr.push(all)
        arr.push(other)
        arr.push(led)

        return arr
      } else {
        return []
      }
    },

    /**
     * 特別清掃、管球交換 選択肢
     * @param kindCode 管球交換 種類
     */
    selectionLists() {
      return (kindCode) => {
        if (this.categoryCode == '14' && this.displaySubItems.length) {
          return this.displaySubItems
        } else if (this.categoryCode == '23' && this.displaySubItems.length && this.bulbArr.length) {
          if (kindCode == '1') {
            return this.bulbArr[1]
          } else if (kindCode == '2') {
            return this.bulbArr[2]
          } else {
            return this.bulbArr[0]
          }
        } else {
          return []
        }
      }
    },

    /**
     * 定期・スポット清掃 「内容」候補
     */
    nameList() {
      let list = []
      if (['5', '28'].includes(this.categoryCode)) {
        list = [
          {name: 'エントランス'},
          {name: '廊下'},
          {name: '階段'},
          {name: '管理室'}
        ]
      } else if (this.categoryCode == '13') {
        list = [
          {name: '除草・剪定・消毒・施肥'},
          {name: '除草・剪定・消毒'},
          {name: '除草・剪定'},
          {name: '剪定'},
          {name: '除草'}
        ]
      }
      return list
    },

    memo() {
      let m = this.buildingInfo.memo
      let text = ''
      switch (this.categoryCode) {
        case '1':
          text = m.rls
          break;

        case '3':
          text = m.normal
          break;

        case '4':
          text = m.mgt
          break;

        case '5':
          text = m.fixed
          break;

        case '6':
        case '7':
        case '8':
        case '9':
        case 'rlsSub':
          text = m.rlsSub
          break;

        case '10':
          text = m.glass
          break;

        case '11':
          text = m.dorainPipe
          break;

        case '12':
          text = m.waterTank
          break;

        case '13':
          text = m.plant
          break;

        case '14':
          text = m.sp
          break;
      
        default:
          break;
      }
      return text
    },

    /**
     * タブの下に表示するメッセ
     */
    tabMsg() {
      if (['3', '4', '5', '28'].includes(this.categoryCode)) {
        return this.changeModalTab[this.getActiveTab()].msg
      } else {
        return null
      }
    },

    /**
     * 消防、貯水、増圧、EVの場合、発注明細表の上にコメント
     */
    purchaseMsgFlag() {
      // 貯水槽、消防、EV、増圧の場合
      if (['12', '15', '19', '20'].includes(this.categoryCode)) {
        if (this.selectedDetails.display[0].categoryBgColor) {
          return true
        }
        
        for (let i = 0; i < this.selectedDetails.display.length; i++) {
          if (this.selectedDetails.display[i].detailBgColor) {
            return true
          }
        }
      }
      return false
    },

    /**
     * 日曜の選択が含まれているか
     */
    isSunday() {
      for (let i = 0; i < this.selectedDetails.remarks.length; i++) {
        const r = this.selectedDetails.remarks[i]
        if (r.week && r.week.includes('日')) {
          return true
        }
      }
      return false
    },

    /**
     * 月額請求チェックボックス非表示
     */
    disableMonthly() {
      // 日常・管理員
      if (['3', '4'].includes(this.categoryCode)) {
        return true
      // 定期清掃で過去仕様の場合
      } else if (this.categoryCode == '5' && this.changeModalTab.length > 1) {
        return true
      // 特別清掃で過去仕様＆複数行があるもの
      } else if (this.categoryCode == '14' && !this.newData && this.selectedDetails.display.length > 1) {
        return true
      }
      return false
    },
  },

  methods: {

    /**
     * 詳細選択項目を取得
     */
    fetchEstimateSubItem(modalData, modal) {
      this.active = true
      this.displaySubItems = []
      this.openInit = true

      this.selectedDetails.changePurchasePrice = this.rows[0].changePurchasePrice

      // 親に関する中項目を取得
      const list = this.$store.getters.estimateSubItemList
      let parentCode = this.categoryCode
      if (!this.categoryCode || this.categoryCode == '') {
        parentCode = ''
      }
      this.displaySubItems = list.filter((s) => {
        return s.parentCode == parentCode
      })

      // オブジェクトに変換
      let parse = this.displaySubItems.map((original) => {
        if (original.selectValue != '') {
          let itemCode = JSON.parse(original.selectValue.replace(/\//g, ''))
          // 日常清掃の時間指定なしの場合
          if (this.categoryCode == '3' && original.order == '06') {
            // 並び替え
            itemCode = this.sortItemCode(itemCode)
          }
          
          return {...original, itemCode}
        } else {
          return {...original}
        }
      })
      this.displaySubItems = parse

      // 編集であれば、内容を反映(modal:RPのを反映しただけ⇒false、この場合初期値の反映が必要)
      if (Object.keys(modalData).length) {

        let data = modalData
        // 日常、管理、定期の場合
        if (modalData.modalTabData.length && ['3', '4', '5'].includes(this.categoryCode)) {
          this.changeModalTab = utils.clone(modalData.modalTabData)
          
          for (let i = 0; i < this.changeModalTab.length; i++) {
            // タブデータを取得
            if (this.changeModalTab[i].active) {
              data = this.changeModalTab[i].data
              // ディープコピーでエラーになるので、一旦空にする
              this.changeModalTab[i].data.modalTabData = []

              if (['3', '4'].includes(this.categoryCode)) {
                // 表の内容だけ親画面からのデータの反映もあるので、別に取得
                data.display = modalData.display
                // 仕様変更のため、過去のremarksの形を変更
                this.setRemarks(data)
              }
            }
          }
          // 人数分の曜日を作成
          this.setWeeks(data)
        }
        // 特別清掃の場合
        if (this.categoryCode == '14') {
            // 過去仕様も強制的に新仕様に変更
            this.newData = true
            data.newData = true
        }
        this.selectedDetails = utils.clone(data)
        // console.log(this.selectedDetails)
        this.calc()

        for (let i = 0; i < this.selectedDetails.selectedValue.length; i++) {
          this.displaySubItems[i].value = this.selectedDetails.selectedValue[i]
        }

        // 編集の場合
        if (modal) {
          // 2024/09まで単価を取得ボタンがなかったもの
          // 編集した履歴があれば、フラグ解除
          if (!this.categoryCode || ['23', '24', '25', '26'].includes(this.categoryCode)) {
            this.selectedDetails.getPriceFlag = true
          }
          return
        }
      }

      this.selectedDetails.selectedValue = new Array(this.displaySubItems.length)
      
      // 初期値設定
      this.setValue()
    },

    /**
     * itemCodeを並べ替える
     * @param itemCode アイテムコード
     */
    sortItemCode(itemCode) {
      const item = {}
      const inai = {}
      const keys = Object.keys(itemCode)
      for (let i = 0; i < keys.length; i++) {
        const k = keys[i]
        if (k.indexOf('以内') > -1) {
          inai[k] = itemCode[k]
        } else {
          item[k] = itemCode[k]
        }
      }

      for (let i = 0; i < Object.keys(inai).length; i++) {
        const key = Object.keys(inai)[i]
        item[key] = inai[key]
      }
      return item
    },

    /**
     * 初期値を設定
     */
    async setValue() {
      
      for (let i = 0; i < this.displaySubItems.length; i++) {
        // オーナー契約
        if (this.displaySubItems[i].item == 'owner') {
          this.setInitValue(this.displaySubItems[i], this.buildingInfo.owner)

        // 単独発注
        } else if (this.displaySubItems[i].item == 'other') {
          if (this.displaySubItems[i].value == '') {
            this.setInitValue(this.displaySubItems[i], this.buildingInfo.other)
          }

        // 竣工（スポット清掃、MRS以外）
        } else if (this.categoryCode != '28' && this.categoryCode != '29' && this.displaySubItems[i].item == 'finishPoint') {
          if (this.buildingInfo.finishPoint) {
            this.setInitValue(this.displaySubItems[i], this.buildingInfo.finishPoint)
          }
        }
      }

      switch (this.categoryCode) {
        // RLS
        case '1':

          // 戸数をセット
          this.setInitValue(this.displaySubItems[0], this.buildingInfo.households)

          // 居住タイプをセット
          this.setInitValue(this.displaySubItems[1], this.buildingInfo.residenceType)
          
          // 清掃回数をセット
          this.setInitValue(this.displaySubItems[2], this.buildingInfo.rlsCleaningTimes)

          // ゴミ出し回数をセット
          this.setInitValue(this.displaySubItems[3], this.buildingInfo.rlsTrash)
          
          // トイレをセット
          this.setInitValue(this.displaySubItems[5], this.buildingInfo.rlsToiletTimes)
          this.setInitValue(this.displaySubItems[6], this.buildingInfo.rlsToiletPlace)

          // 検針、写真
          // ツールチップ生成
          this.selectedDetails.toolTip[this.displaySubItems[7].name] = this.displaySubItems[7].value
          this.selectedDetails.toolTip[this.displaySubItems[8].name] = this.displaySubItems[8].value
          this.selectedDetails.toolTip[this.displaySubItems[9].name] = this.displaySubItems[9].itemCode[this.displaySubItems[9].value]
          break
      
        // 日常清掃,管理員業務
        case '3':
        case '4': {
          
          // エリア
          let eria = await eriaManager.getEria(this, this.buildingInfo.address, 'normal')
          if (eria) {
            // 値からキーを取得し保存、ツールチップ生成
            this.setInitValue(this.displaySubItems[0], eria)
          }

          let info = []
          // 日常
          if (this.categoryCode == '3') {
            info = this.buildingInfo.normal
          // 管理
          } else {
            info = this.buildingInfo.mgt
          }

          // タブ、行を生成
          for (let i = 0; i < info.length; i++) {
            if (i != 0) {
              this.changeModalTab.push({id: '', name: '', active: false, data: {}, msg: '', changeDisp: false, disabledDisp: true})
              this.addRowDetail()
            }
            // IDをタイムスタンプで生成
            this.changeModalTab[i].id = Math.random() + i

            // タブ名
            this.changeModalTab[i].name = info[i].hours.trim() + ' ・ ' + info[i].times.replace('週 ', '').trim()

            // 時間
            this.selectedDetails.remarks = [{start: info[i].startTime, end: info[i].endTime, week: [], weekDetail: [], breakHour: '', breakMinutes: ''}]
            
            // データ
            this.changeModalTab[i].data = JSON.parse(JSON.stringify(this.selectedDetails))
            
            // 行のタイプ
            this.selectedDetails.display[i].type = 'modalTab' + this.changeModalTab[i].id

            // 親画面の内容を反映
            this.setInitDisplay(i, i)

            // 日常清掃のみ該当
            if (this.categoryCode == '3') {

              // トイレ使用
              this.setInitValue(this.displaySubItems[1], this.buildingInfo.availableToilet)
              // トイレ回数
              this.setInitValue(this.displaySubItems[2], info[i].toiletTimes)
              // トイレ箇所
              this.setInitValue(this.displaySubItems[3], info[i].toiletPlace)
            }

            // 時間指定
            this.displaySubItems[4].value = '0'
            this.displaySubItems[5].value = '0'
            this.displaySubItems[6].value = '0'
            this.setInitValue(this.displaySubItems[4], info[i].specific)

            // 時間指定なしの場合の清掃時間
            if (this.displaySubItems[4].value == '0') {
              this.setInitValue(this.displaySubItems[6], info[i].hours)
              if (this.displaySubItems[6].value == '0') {
                this.changeModalTab[i].msg = 'メインでは（時間指定なしの）選択肢にない「' + info[i].hours + '」が入力されています。'
                this.displaySubItems[6].value = ''
              }

            // 時間指定ありの場合の清掃時間
            } else if (this.displaySubItems[4].value == '1') {
              this.setInitValue(this.displaySubItems[5], info[i].hours)
              if (this.displaySubItems[5].value == '0') {
                this.changeModalTab[i].msg = 'メインでは（時間指定ありの）選択肢にない「' + info[i].hours + '」が入力されています。'
                this.displaySubItems[5].value = ''
              }
              // 早朝割増初期値設定
              if (info[i].startTime && (info[i].startTime.replace(':', ''))) {
                if (Number(info[i].startTime.replace(':', '')) <= 700) {
                  this.setInitValue(this.displaySubItems[22], 'あり')
                } else {
                  this.setInitValue(this.displaySubItems[22], 'なし')
                }
              }
            // 時間指定不明の場合
            } else {
              this.changeModalTab[i].msg = 'メインでは時間指定「不明」のため、清掃時間を反映できません。清掃時間は「' + info[i].hours + '」が入力されています。'
            }

            // 清掃回数
            this.displaySubItems[7].value = ''
            this.setInitValue(this.displaySubItems[7], info[i].times)
            if (info[i].times != '' && this.displaySubItems[7].value == '') {
              if (this.changeModalTab[i].msg != '') {
                this.changeModalTab[i].msg = this.changeModalTab[i].msg + '\r\n'
              }
              let msg = ''
              if (info[i].specific != '') {
                msg = '（時間指定' + info[i].specific + 'の）'
              }
                this.changeModalTab[i].msg = this.changeModalTab[i].msg + 'メインでは' + msg +'選択肢にない「' + info[i].times + '」が入力されています。'
                this.displaySubItems[7].value = ''
            }

            // 祝日対応
            this.setInitValue(this.displaySubItems[16], info[i].holiday)

            // 単独発注
            if (this.buildingInfo.other == '単独') {
              this.displaySubItems[11].value = ''
            }

            // タイプ
            this.setInitValue(this.displaySubItems[12], this.buildingInfo.residenceType)

            // 戸数
            this.setInitValue(this.displaySubItems[13], this.buildingInfo.households)

            // 階数
            this.setInitValue(this.displaySubItems[14], this.buildingInfo.stairs)

            this.selectedDetails.conf['hourType'] = this.setHolidayHours(this.displaySubItems[16].value)

            // 取得した値をタブのデータに保存
            this.setSelectedValue(this.changeModalTab[i].data)
          }
          // 1番目を活性にしているので、データを反映
          this.setModalTabData(0)
        }
          return

        // 定期・スポット清掃
        case '5':
        case '28': {

          // 定期・スポット清掃用の行にする
          this.selectedDetails.display = [{name: '', qty: '', unitName: '㎡', priceKind: '', kindCoef:'なし (×1)', price: '', detailBgColor: false, type: 'calc', qty2: '', unitName2: '', price2: '', categoryBgColor: false}]
          
          // エリア
          let eria = await eriaManager.getEria(this, this.buildingInfo.address, 'fixed')
          if (eria) {
            // 値からキーを取得し保存、ツールチップ生成
            this.setInitValue(this.displaySubItems[0], eria)
          }

          // ㎡数は初期値なし
          this.displaySubItems[1].value = ''

          // 駐車場代をセット
          this.setInitValue(this.displaySubItems[3], this.buildingInfo.parking)

          // 戸数をセット
          this.setInitValue(this.displaySubItems[4], this.buildingInfo.households)
          
          if (Number(this.displaySubItems[4].value) <= 20) {
            this.setInitValue(this.displaySubItems[5], '1~2時間(300㎡以下)')
          } else if (Number(this.displaySubItems[4].value) <= 60) {
            this.setInitValue(this.displaySubItems[5], '半日')
          } else if (Number(this.displaySubItems[4].value) > 60) {
            this.setInitValue(this.displaySubItems[5], '1日')
          }

          // タイプ
          this.setInitValue(this.displaySubItems[7], this.buildingInfo.residenceType)

          // 階数
          this.setInitValue(this.displaySubItems[8], this.buildingInfo.stairs)

          // 300㎡超え用のテーブル項目追加
          // 単価の種類
          this.selectedDetails.display[0].priceKind = ''
          // 項目による係数
          this.selectedDetails.display[0].kindCoef = 'なし (×1)'
          this.selectedDetails.conf.kindCoef = []

          // タブ、行を生成
          for (let i = 0; i < this.rows.length; i++) {
            if (i != 0) {
              this.changeModalTab.push({id: '', name: '', active: false, data: {}, msg: '', changeDisp: false, disabledDisp: true})
            }
            // IDを生成
            this.changeModalTab[i].id = Math.random() + i

            // タブ名
            let name = this.rows[i].categoryQty + ' ' + this.rows[i].categoryUnitName
            this.changeModalTab[i].name = name

            // 作業部位の反映
            this.selectedDetails.displayFixed[0].name = this.rows[i].itemName

            // 清掃回数をセット
            this.setInitValue(this.displaySubItems[2], this.rows[i].categoryQty)

            // 親画面の内容を反映
            this.setInitDisplay(0, i)
            
            // 行のタイプ
            this.selectedDetails.display[0].type = 'modalTab' + this.changeModalTab[i].id

            // 行の内容は空文字に
            this.selectedDetails.display[0].name = ''

            // データ
            this.changeModalTab[i].data = JSON.parse(JSON.stringify(this.selectedDetails))
            
            // 取得した値をタブのデータに保存
            this.setSelectedValue(this.changeModalTab[i].data)
          }
          // 1番目を活性にしているので、データを反映
          this.setModalTabData(0)
        }
          return
        
        // フロント・ゴミサポ
        case '6':
        case '7':
        case '8':
        case '9':
          
          // 戸数をセット
          this.setInitValue(this.displaySubItems[0], this.buildingInfo.households)

          // 居住タイプをセット
          this.setInitValue(this.displaySubItems[1], this.buildingInfo.residenceType)

          break

        // 排水管清掃
        case '11':

          // 戸数をセット
          this.setInitValue(this.displaySubItems[0], this.buildingInfo.households)
          
          break

        // 貯水槽清掃
        case '12': {

          // エリア
          let eria = await eriaManager.getEria(this, this.buildingInfo.address, 'waterStorage')
          if (eria) {
            // 値を保存
            if (eria.eriaNittsu) {
              this.selectedDetails.conf['eriaNittsu'] = eria.eriaNittsu
            }
            if (eria.eriaBesper) {
              this.selectedDetails.conf['eriaBesper'] = eria.eriaBesper
            }
          }

          // *アポロ様ご要望 ポンプ代、水質検査代はデフォルトで表示*

          // ポンプ点検
          this.selectedDetails.conf['pump'] = this.$store.getters.estimateItemPriceList.find((l) => {
            if (l.parentCode == this.categoryCode && l.priceCode == 'pump') {
              return true
            }
          }).price

          // 水質検査
          this.selectedDetails.conf['inspection'] = this.$store.getters.estimateItemPriceList.find((l) => {
            if (l.parentCode == this.categoryCode && l.priceCode == 'inspection') {
              return true
            }
          }).price
        }
          break

        // 特別清掃
        case '14':{
          // 仕様変更後のデータの場合はフラグを立てる
          this.selectedDetails.newData = true
          this.newData = true

          // オーナー契約
          if (this.buildingInfo.owner == 'なし') {
            this.selectedDetails['spOwner'] = '0'
          } else if (this.buildingInfo.owner == 'あり') {
            this.selectedDetails['spOwner'] = '1'
          }
          // 竣工係数
          this.selectedDetails['spFinishPoint'] = '0'
          if (this.buildingInfo.finishPoint) {
            this.selectedDetails['spFinishPoint'] = this.buildingInfo.finishPoint
          }
          this.msg = '「内容」セルをクリックすると候補が出てきます。'

          // 特記事項反映
          let priceCode = ''
          this.displaySubItems.forEach((d) => {
            if (d.name == this.rows[0].itemName) {
              priceCode = d.item
            } 
          })
          // 駐輪場清掃・ルーバーは特殊で選択肢があるので、条件から外す
          if (priceCode != '' && !['bicycle', 'louver'].includes(priceCode)) {
            // 単価表から特記事項コードを取得
            let list = this.$store.getters.estimateItemPriceList.filter((l) => {
              if (l.parentCode == this.categoryCode && l.priceCode == priceCode) {
                return true
              }
            })

            if (list != undefined && list.length) {
              if (list[0].noteCode != '') {
                // 特記事項取得
                this.getEstimateNote([this.setNoteCode(list[0].noteCode, '')])
              }
            }
          }
        }
          break

        // 消防点検
        case '15': {
          // エリア
          let fireEria = await eriaManager.getEria(this, this.buildingInfo.address, 'fire')
          if (fireEria) {
            // 値からキーを取得し保存、ツールチップ生成
            this.setInitValue(this.displaySubItems[0], fireEria)
          }
          // 駐車場代をセット
          this.selectedDetails.conf['parkingValue'] = this.buildingInfo.parking
          if (fireEria && fireEria.indexOf('港区') > -1) {
            this.setInitValue(this.displaySubItems[1], this.buildingInfo.parking)
          }
          // 戸数をセット
          this.setInitValue(this.displaySubItems[4], this.buildingInfo.households)

          // 居住タイプをセット
          this.setInitValue(this.displaySubItems[5], this.buildingInfo.residenceType)
        }
          break
        
        // 増圧ポンプ
        case '20': {
          
          // エリア
          let boosterEria = await eriaManager.getEria(this, this.buildingInfo.address, 'booster')
          if (boosterEria) {
            // 値からキーを取得し保存、ツールチップ生成
            this.setInitValue(this.displaySubItems[1], boosterEria)
          }
          // 駐車場代をセット
          this.setInitValue(this.displaySubItems[2], this.buildingInfo.parking)
        }
          break
        
        // 給排水ポンプ点検,簡易専用水道
        // 単価が一つのみなので、すぐに取得、計算する
        case '21':
        case '22':
          // 単価取得
          this.selectedDetails.unitPrice = this.$store.getters.estimateItemPriceList.find((l) => {
            return l.parentCode == this.categoryCode
          }).price
          
          // オーナー契約
          this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + this.displaySubItems[0].value, '0')

          this.setDisplayItem({qty: '1', unitName: '回', name: this.categoryName}, '')
          this.calc()
          break

        // 管球交換
        case '23': 
          
          // 対応費をセット
          this.setInitValue(this.displaySubItems[0], 'あり')
          
          // 管球交換用の行にする
          this.selectedDetails.display = [{kind: '', kindCode: '', name: '', qty: '', unitName: '個', price: '', detailBgColor: false, qty2: '', unitName2: 'ヶ月', price2: '', categoryBgColor: false, type: 'calc'}]
          // this.selectedDetails.display.push({kind: '', kindCode: '', name: '対応費', qty: '1', unitName: '式', price: constants.responseCost, detailBgColor: false, qty2: '', unitName2: 'ヶ月', price2: '', categoryBgColor: false, type: ''})
          break

        // 粗大ごみ、物販
        case '24': 
        case '25':
          
          // 対応費をセット
          this.setInitValue(this.displaySubItems[0], 'あり')

          this.selectedDetails.display = [{name: '', qty: '', unitName: '個', price: '', detailBgColor: false, qty2: '', unitName2: 'ヶ月', price2: '', categoryBgColor: false, type: 'calc'}]
          // this.selectedDetails.display.push({name: '対応費', qty: '1', unitName: '式', price: constants.responseCost, detailBgColor: false, qty2: '', unitName2: 'ヶ月', price2: '', categoryBgColor: false, type: ''})
          break

        // 緊急対応
        case '26': 

          this.selectedDetails.display = [{name: '緊急対応費', qty: '1', unitName: '式', price: '', detailBgColor: false, qty2: '', unitName2: 'ヶ月', price2: '', categoryBgColor: false, type: 'calc'}]
          break

        // MRS
        case '29':

          // 戸数をセット
          this.setInitValue(this.displaySubItems[0], this.buildingInfo.households)
          break

        default:
          break
      }

      // カテゴリーコードがないもの
      if (!this.categoryCode) {
        this.selectedDetails.display = [{name: this.categoryName, qty: '1', unitName: 'ヶ月', price: '', detailBgColor: false, qty2: '', unitName2: 'ヶ月', price2: '', categoryBgColor: false, type: 'calc'}]
      }
      
      // 親画面の内容を反映
      this.setInitDisplay(0, 0)
    },

    /**
     * 初期の行の設定
     * @param i 番号
     * @param j 親画面の行番号
     */
    setInitDisplay(i, j) {
      let disp = this.selectedDetails.display[i]
      if (this.rows[j].itemName != '') {
        disp.name = this.rows[j].itemName
      }
      if (this.rows[j].itemQty != '' && this.rows[j].itemQty != '0') {
        disp.qty = this.rows[j].itemQty
      }
      if (this.rows[j].itemUnitName != '') {
        disp.unitName = this.rows[j].itemUnitName
      }
      if (this.rows[j].itemUnitPrice != '' && this.rows[j].itemUnitPrice != '0') {
        disp.price = this.rows[j].itemUnitPrice
      }
      if (this.rows[j].categoryQty != '' && this.rows[j].categoryQty != '0') {
        disp.qty2 = this.rows[j].categoryQty
      }
      if (this.rows[j].categoryUnitName != '') {
        disp.unitName2 = this.rows[j].categoryUnitName
      }
      if (this.rows[j].categoryUnitPrice != '' && this.rows[j].categoryUnitPrice != '0') {
        disp.price2 = this.rows[j].categoryUnitPrice
      }
      disp.detailBgColor = this.rows[j].detailBgColor
      disp.categoryBgColor = this.rows[j].categoryBgColor
    },

    /**
     * 初期値のセット、ツールチップの保存
     * @param item 対象項目
     * @param value 値
     */
    setInitValue(item, value) {
      // ラジオボタン、ドロップダウンの場合（値からキーを取得）
      if (item.selectType == 'radio' || item.selectType == 'dropDown') {
        let keyArry = Object.keys(item.itemCode)
        for (let i = 0; i < keyArry.length; i++) {
          let key = keyArry[i]
          if (value == item.itemCode[key]) {
            item.value = key
            // ツールチップ生成
            this.selectedDetails.toolTip[item.name] = value
            return
          }
        }
      } else if (item.selectType == 'input') {
        item.value = value
        // ツールチップ生成
        this.selectedDetails.toolTip[item.name] = value
      }
    },

    /**
     * 入力・選択した値をツールチップ用に保存
     * @param item 対象項目
     * @param index 番号
     * @param value 値 ドロップボックスの場合は、itemCodeオブジェクト
     */
    setItem(item, index, value) {
      let code = this.displaySubItems[index].value
      if (code != '') {
        this.restriction(item, index)

        // ツールチップ用
        if (item.selectType == 'dropDown') {
          this.selectedDetails.toolTip[item.name] = value[code]
          this.setWeekArr(index)
        } else {
          this.selectedDetails.toolTip[item.name] = value
        }
      }
    },

    /**
     * 曜日配列セット
     * @param 選択肢インデックス
     */
    setWeekArr(index) {
      // 日常・管理の場合のみ
      if (['3', '4'].includes(this.categoryCode)) {
        // 人数の選択の場合
        if (index == 8) {
          // 人数-1回分、曜日配列生成
          let num = Number(this.displaySubItems[8].value)
          // 曜日の配列数
          let numW = this.week.length
          if (!isNaN(num)) {
            // 人数と曜日配列数が同じなら何もしない
            if (num == numW) {
              return
            
            // 曜日が多い場合
            } else if (num < numW) {
              // 曜日配列削除
              this.week.splice(num, numW - num)
              this.selectedDetails.remarks.splice(num, numW - num)

              // 人数が多い場合
            } else if (num > numW) {
              // 曜日追加
              for (let i = 0; i < num - numW; i++) {
                this.week.push(utils.clone(WEEK_LIST))
                this.selectedDetails.remarks.push({start: '', end: '', week: [], weekDetail: [], breakHour: '', breakMinutes: ''})
              }
            }
          }
        }
      }
    },

    /**
     * 曜日を表示するか判定
     * @param i インデックス
     */
    isAllWeek(i) {
      if (['3', '4'].includes(this.categoryCode)) {
        let tar = this.displaySubItems
        // 時間指定の場合は表示
        if (tar[4] && tar[4].value != '0') {
          return true
        }
        // 時間指定なしの場合
        if (tar[4] && tar[4].value == '0') {
          // 1つだけ表示
          if (i == 0) {
            return true
          }
        }
        return false
      } else {
        return false
      }
    },

    /**
     * 選択した項目によって他の項目の値が決まる
     * @param item 対象項目
     * @param index 番号
     * **ドロップダウンの場合は、値はitem.valueで取得**
     */
    restriction(item, index) {
      let target = this.displaySubItems
      let tip = this.selectedDetails.toolTip

      switch (this.categoryCode) {
        // ラウンドサービス
        case '1':
          this.checkRadio(index)
          break;

        // 日常清掃,管理員業務
        case '3':
        case '4':

          // 時間指定なし
          if (index == 4 && item.value == '0') {
            // 時間指定ありに0をセット
            this.setZeroValue(target[5])
            // 不要なツールチップ削除
            delete tip['清掃時間(指定あり)']

            if (target[6].value == '0') {
              target[6].value = ''
            }

          // 時間指定あり
          } else if (index == 4 && item.value == '1') {
            // 時間指定なしに0をセット
            this.setZeroValue(target[6])
            // 不要なツールチップ削除
            delete tip['清掃時間(指定なし)']
            if (target[5].value == '0') {
              target[5].value = ''
            }

          // 他、業務あり 定期概算のツールチップ削除
          } else if (index == 10 && item.value == '1') {
            target[11].value = ''

          // 他、業務なし
          } else if (index == 10 && item.value == '0') {
            target[11].value = ''
            if (target[12].value == '0') {
              target[12].value = ''
            }
          }

          break;

        // 定期・スポット清掃
        case '5':
        case '28':

          // 駐車場代ありの場合、戸数によって作業時間の目安を決める
          if ((index == 3 || index == 4) && target[3].value == '1') {
            if (Number(target[4].value) <= 20) {
              this.setInitValue(target[5], '1~2時間(300㎡以下)')
            } else if (Number(target[4].value) <= 60) {
              this.setInitValue(target[5], '半日')
            } else if (Number(target[4].value) > 60) {
              this.setInitValue(target[5], '1日')
            }
          } else if ((index == 3 || index == 4) && target[3].value == '0') {
            target[5].value == '0'
            delete tip['作業時間（駐車場代）']
          }
          break;
              
          // フロント
          case '6':
            
            if (Number(target[0].value) > 20) {
              // 戸数が21以上の場合、月1回ではシングル以外選択不可
              if (target[1].value != '1' && target[2].value == 'm1') {
                alert('21戸以上では、月1回の「シングル」単価しかありません。\n清掃回数または、タイプをシングルに変更してください。')
              }
              // 戸数が31以上の場合、月1回は選択不可
              if (Number(target[0].value) > 30) {
                if (target[2].value == 'm1') {
                  alert('31戸以上では、月1回は対応していません。\n清掃回数を変更してください。')
                }
                this.setInitValue(target[2], '週 1 回')
                target[2].itemCode = {"1":"週 1 回"}
              } else {
                target[2].itemCode = {"m1":"月 1 回","1":"週 1 回"}
              }
            } else {
              target[2].itemCode = {"m1":"月 1 回","1":"週 1 回"}
            }
            break

          // 貯水槽
          case '12':
            // 発注先、もしくは駐車場代選択時
            if ([1,2].includes(index)) {
              // 駐車場代ありの場合
              if (target[2].value == '1') {
                // ニッツ
                if (target[1].value == '1') {
                  // ニッツのエリアをセット
                  if (this.selectedDetails.conf['eriaNittsu'] && (!target[3].value || target[3].value == '')) {
                    this.setInitValue(target[3], this.selectedDetails.conf['eriaNittsu'])
                  }
                  
                // ベスパー
                } else if (target[1].value == '2') {
                  // ベスパーのエリアをセット
                  if ( this.selectedDetails.conf['eriaBesper'] && (!target[4].value || target[4].value == '')) {
                    this.setInitValue(target[4], this.selectedDetails.conf['eriaBesper'])
                  }
                }

              // 駐車場代なしの場合
              } else {
                // 不要なツールチップ削除
                delete tip['エリア（ニッツ）']
                delete tip['エリア（ベスパーTP）']
              }
            }
            break

          // 消防
          case '15':
            // エリアが超超都心の場合
            if (index == 0 && item.value == '1') {
              // 駐車場代をセット
              if ( this.selectedDetails.conf['parkingValue'] && (!target[1].value || target[1].value == '')) {
                this.setInitValue(target[1], this.selectedDetails.conf['parkingValue'])
              }
            }
            break

          // 管球交換
          // case '23':

          //   if (index == 0) {
          //     let No = null
          //     for (let i = 0; i < this.selectedDetails.display.length; i++) {
          //       if (this.selectedDetails.display[i].name == '対応費') {
          //         No = i
          //         break
          //       }
          //     }
          //     // 対応費なし
          //     if (item.value == '0') {
          //       if (No || No == 0) {
          //         this.selectedDetails.display.splice(No, 1)
          //       }
              
          //     // 対応費あり
          //     } else if (item.value == '1') {
          //       if (!No && No != 0) {
          //         this.selectedDetails.display.push({kind: '', kindCode: '', name: '対応費', qty: '1', unitName: '式', price: constants.responseCost, detailBgColor: false, qty2: '', unitName2: 'ヶ月', price2: '', categoryBgColor: false, type: ''})
          //       }
          //     }
          //   }
          // break

          // 粗大ごみ、物販
          // case '24':
          // case '25':

          //   if (index == 0) {
          //     let No = ''
          //     for (let i = 0; i < this.selectedDetails.display.length; i++) {
          //       if (this.selectedDetails.display[i].name == '対応費') {
          //         No = i
          //         break
          //       }
          //     }
          //     // 対応費なし
          //     if (item.value == '0') {
          //       if (No != '') {
          //         this.selectedDetails.display.splice(No, 1)
          //       }
              
          //     // 対応費あり
          //     } else if (item.value == '1') {
          //       if (No == '') {
          //         this.selectedDetails.display.push({name: '対応費', qty: '1', unitName: '式', price: constants.responseCost, detailBgColor: false, qty2: '', unitName2: 'ヶ月', price2: '', categoryBgColor: false, type: ''})
          //       }
          //     }
          //   }
          // break

        default:
          break;
      }
    },

    /**
     * 空文字なら0をセット
     * @param target 対象データ
     */
    setZeroValue(target) {
      if (!target.value || target.value == '') {
        target.value = '0'
      }
    },

    /**
     * 入力タイプ表示条件
     * @param item 列データ
     */
    input(item) {
      if (item.selectType == 'input') {
        // RLS、日常清掃、管理業務、定期清掃、消防、スポット清掃_前項で選択した値によって表示
        if (['1', '3', '4', '5', '15', '28'].includes(this.categoryCode)) {
          return this.JudgmentDisp(item)
        } else {
          return true
        }
      } else {
        return false
      }
    },

    /**
     * ラジオボタン表示条件
     * @param item 列データ
     */
    radio(item) {
      if (item.selectType == 'radio') {
        // RLS_点検_戸数&清掃回数に応じて表示
        if (this.categoryCode == '1' && item.item == 'inspection') {
          if (this.displaySubItems[0].value <= Number(item.disable) && ['m1', 'm2', '1', '2'].includes(this.displaySubItems[2].value)) {
            return true
          } else {
            return false
          }

        // 日常清掃、管理業務、定期清掃、消防、スポット清掃_前項で選択した値によって表示
        } else if (['3', '4', '5', '7', '15', '28'].includes(this.categoryCode)) {
          return this.JudgmentDisp(item)

        } else {
          return true
        }
      } else {
        return false
      }
    },

    /**
     * ドロップダウン表示条件
     * @param item 列データ
     */
    dropDown(item) {
      if (item.selectType == 'dropDown') {
        if (['3', '4', '5', '12', '28'].includes(this.categoryCode)){
          return this.JudgmentDisp(item)
        } else {
          return true
        }
      }
    },

    /**
     * 選択肢を項目表に表示するか判断
     * @param item 中項目
     */
    JudgmentDisp (item) {
      let result = false
      let No
      let value
      if (item.disable != '') {
        // データは【選択項目index】-【値】の形になっている
        let disp = item.disable.split('-')
        No = Number(disp[0])
        value = String(disp[1])

        // 前項で選択した値が該当するなら、表示する
        if (this.displaySubItems[No].value == value) {
          result = true
          // 貯水
          if (this.categoryCode == '12') {
            // 発注先がニッツなら、ニッツのエリア
            if (item.item == 'eriaNittsu') {
              if (this.displaySubItems[1].value == '2') {
                result = false
              }
            // 発注先がベスパ―なら、ベスパ―のエリア
            } else if (item.item == 'eriaBesper') {
              if (this.displaySubItems[1].value == '1') {
                result = false
              }
            }
          }
        } else {
          
          // 定期・スポットの㎡数不明の場合も戸数表示
          if (['5', '28'].includes(this.categoryCode)) {
            if (item.item == 'households') {
              if (this.uncertain) {
                result = true
              }
            }
          }
        }
      } else {
        result = true
      }
      return result
    },

    /**
     * 定期・スポットの基本単価変更フラグ
     * @param display 対象データ
     */
    changeFixedPrePrice(display) {
      this.changeCategoryPrice()
      display.priceKindBgColor = true
    },

    /**
     * 定期清掃、スポット清掃（300㎡越え）の単価を変更した場合
     */
    changeForFixedExeed300UnitPrice() {
      this.setUnitPriceChange()
      if (this.exceed300 || this.selectedDetails.conf['meter'] == '3') {
        // 行がなかったり、1行のままの場合
        if (!this.selectedDetails.display || !this.selectedDetails.display.length || this.selectedDetails.display.length == 1) {
          // 行を増やす
          for (let i = 0; i < 3; i++) {
            this.addRowDetail()
          }
        }
      }
    },

    /**
     * 定期清掃、スポット清掃（300㎡越え）のオーナー契約、竣工係数を変更した場合
     */
    changeForFixedExeed300(target) {
      this.changeCalcAndPrice(this.selectedDetails.conf, target)
      if (this.exceed300 || this.selectedDetails.conf['meter'] == '3') {
        // 行がなかったり、1行のままの場合
        if (!this.selectedDetails.display || !this.selectedDetails.display.length || this.selectedDetails.display.length == 1) {
          // 行を増やす
          for (let i = 0; i < 3; i++) {
            this.addRowDetail()
          }
        }
      }
    },

    /**
     * 単価表の値段を手動で変更したらフラグを立てる
     * @param display
     * @param kind 1：詳細、2：カテゴリー
     */
    setPriceChange(display, kind) {
      this.openInit = false
      this.changePurchasePrice = false
      if (kind == 1) {
        display.detailBgColor = true
        this.categoryCalc()
      } else if (kind == 2) {
        display.categoryBgColor = true
        // カテゴリ－総合計金額計算
        this.categoryAmountCalc()
      }
    },

    /**
     * 単価の値段を変更したらフラグを立てる
     */
    setUnitPriceChange() {
      this.selectedDetails.iconBgColor = true
      this.selectedDetails.unitPriceBgColor = true
    },

    /**
     * 特別・管球・粗大ごみ・物品販売で表示されている基本単価・オーナー契約・竣工係数を手動で変更
     */
    setDisplayTrashOrSaleChange(display, name) {
      display[name] = true
      this.selectedDetails.iconBgColor = true
      this.calcCoeffToTable()
    },

    /**
     * 発注額の値段を変更したらフラグを立てる
     */
    setOrderPriceChange(price) {
      if (price == '' || price == '0') {
        this.selectedDetails.iconBgColor = false
        this.selectedDetails.unitPriceBgColor = false
      } else {
        this.selectedDetails.iconBgColor = true
        this.selectedDetails.unitPriceBgColor = true
      }
    },

    /**
     * 再計算＆単価変更フラグを立てる
     * @param target フィールド
     * @param value キー
     */
    changeCalcAndPrice(target, value) {
      let name = value + 'BgColor'
      target[name] = true
      this.selectedDetails.iconBgColor = true
      this.calc()
    },

    /**
     * 単価を手動で変更されていたら色を変える
     * @param flag 変更フラグ
     */
    setBgColor(flag) {
      if (flag) {
        return 'bg-yellow-200'
      }
    },

    /**
     * 単価を手動で変更されていたら色を変える
     * @param row 行データ
     */
    setSpBgColor(row) {
      if (row.lightingUnitBgColor) {
        return 'bg-yellow-200'
      }
    },

    /**
     * 削除ボタンイベント
     * @param index 番号
     */
    deleteDetailRow(index) {

      // セル結合しているため、1行目が削除されたら内容を2行目に書き換える
      if (index == 0 && this.selectedDetails.display.length > 1 && (this.categoryCode != '14' && !this.newData)) {
        let d = this.selectedDetails.display
        d[1].qty2 = d[0].qty2
        d[1].unitName2 = d[0].unitName2
        d[1].price2 = d[0].price2
        d[1].categoryBgColor = d[0].categoryBgColor
      }
      this.selectedDetails.display.splice(index, 1)
      if (this.categoryCode == '14' && !this.newData) {
        if (this.selectedDetails.display.length <= 1) {
          this.newData = true
        }
      }
      if (!this.selectedDetails.display.length) {
        this.addRowDetail()
      }

      // 単価2計算
      this.categoryCalc()

      // 植栽剪定の特記事項
      this.getPlantingNote(null, index)

      // WAXの剥離作業の特記事項（定期・スポット）
      this.noteForWax()
    },

    /**
     * 削除ボタンイベント 定期清掃
     */
    deleteFixedRow(index) {
      if (this.selectedDetails.displayFixed.length == 1) {
        this.selectedDetails.displayFixed[0].name = ''
        this.selectedDetails.displayFixed[0].qty = ''
        this.selectedDetails.displayFixed[0].unitName = '㎡'
      } else {
        this.selectedDetails.displayFixed.splice(index, 1)
      }
      this.getFixedRange(null, index)
    },

    /**
     * プラスボタン押下イベント
     */
    addRowDetail() {

      let row = {name: '', qty: '', unitName: 'ヶ月', price: '', detailBgColor: false, qty2: '', unitName2: 'ヶ月', price2: '', categoryBgColor: false, type: ''}
      // 定期清掃の場合
      if (['5', '28'].includes(this.categoryCode)) {
        row = {name: '', qty: '', unitName: '㎡', priceKind: '', kindCoef:'なし (×1)', price: '', detailBgColor: false, type: '', qty2: '', unitName2: 'ヶ月', price2: '', categoryBgColor: false}
        if (this.exceed300 || this.selectedDetails.conf['meter'] == '3') {
          if (this.selectedDetails.unitPriceBgColor || this.selectedDetails.conf['ownerBgColor'] || this.selectedDetails.conf['finishPointBgColor']) {
            row.priceKindBgColor = true
          }
        }
      // 特別、管球交換、粗大ごみ、物品販売の場合
      } else if (['14', '23', '24', '25'].includes(this.categoryCode)) {
        // 基本単価
        row.priceKind = ''
        // オーナー契約
        row.owner = this.selectedDetails.conf['owner'] || '1'
        // 協力費
        row.compe = this.selectedDetails.conf['compe'] || '1'

        // 特別清掃
        if (this.categoryCode == '14') {
          // 竣工係数
          row.finishPoint = this.selectedDetails.conf['finishPoint'] || '1'

        // 管球交換
        } else if (this.categoryCode == '23') {
          row.unitName2 = '式'
          row.kind = ''
          row.kindCode = ''
        }

        // 月額表示がOFFの場合
        if (!this.selectedDetails.monthlyFeeFlag) {
          for (let i = 0; i < this.selectedDetails.display.length; i++) {
            // 対応費がある場合、対応費の行は一番下になるようにする
            if (this.selectedDetails.display[i].name == '対応費') {
              this.selectedDetails.display.splice(i, 0, row)
              return
            }
          }
        }
      }
      // 行がない場合は、typeを計算結果用の行にする
      if (!this.selectedDetails.display.length) {
        row.type = this.setTypeName()
      }
      this.selectedDetails.display.push(row)
    },

    /**
     * プラスボタン押下イベント
     */
    addRowDetailFixed() {
      let row = {name: '', qty: '', unitName: '㎡'}

      this.selectedDetails.displayFixed.push(row)
    },

    /**
     * 完了ボタン入力チェック
     */
    async validate() {
      if (!this.purchase[0].supplier) {
        await dialogs.showErrorDialog('発注先未入力', '発注先を入力してください。')
        return false
      }
      if (!this.purchase[0].price || this.purchase[0].price == '0') {
        let res = await dialogs.showConfirmDialog('発注金額', '発注金額がありませんがよろしいでしょうか？')
        if (res != 'YES') {
          return false
        }
        this.purchase[0].rate = '0'
      } else if (!this.purchase[0].rate || this.purchase[0].rate == '0') {
        let res = await dialogs.showConfirmDialog('発注率', '発注率がありませんがよろしいでしょうか？')
        if (res != 'YES') {
          return false
        }
        this.purchase[0].price = '0'
      }

      if (this.buildingInfo.commission && this.buildingInfo.commission == 'あり') {
        if (!this.commission[0].commissionPrice || ['', '0'].includes(this.commission[0].commissionPrice) || ['', '0'].includes(this.commission[0].rate)) {
          let comMsg = await dialogs.showWarningConfirmDialog('手数料の確認', 'サブタスクでは手数料「あり」になっていますが、手数料なしでよろしいでしょうか？')
          if (comMsg != 'YES') {
            return false
          }
        }
      }
      return true
    },

    /**
     * 発注単価を手動で変更していた場合、単価1と2が合っているか確認
     */
    async checkPrice2() {
      // 金額が小数になっていないか
      if (this.commission[0].price) {
        if (!Number.isInteger(Number(this.commission[0].price))) {
          await dialogs.showErrorDialog('単価2が小数です', '単価2に小数があります。数量1か単価1を修正してください。')
          return false
        }
      }
      let purTxt = ''
      if (this.changePurchasePrice || this.selectedDetails.changePurchasePrice) {
        purTxt = '発注金額を手動で変更しています。\r\n'
      }
      let val = 0
      const rows = utils.clone(this.selectedDetails.display)
      for (let i = 0; i < rows.length; i++) {
        const d = rows[i]
        // 単価1×数量
        let p = Number(d.price)
        let q = Number(d.qty)
        if (!p || isNaN(p)) {
          p = 0
        }
        if (!q || isNaN(q)) {
          q = 0
        }
        let price = BigNumber(p).times(q)
        val = Number(BigNumber(price).plus(val))
      }
      // 定期と特別清掃は昔の仕様のデータがあるため
      if (['5', '14'].includes(this.categoryCode)) {
        // 手数料込金額で比較
        if (!isNaN(this.commission[0].price) && Number(this.commission[0].price) != val) {
          if (!val && this.commission[0].price == '') {
            return true
          }
          let text = '単価2'
          if ((this.categoryCode == '5' && this.changeModalTab.length == 1) || this.categoryCode == '14' && this.newData) {
            text = '手数料込'
          }
          if (this.categoryCode == '5' && this.changeModalTab.length > 1) {
            
            // 他のタブ
            for (let i = 0; i < this.changeModalTab.length; i++) {
              let tab = this.changeModalTab[i];
              // 今活性になっているタブは除く
              if (!tab.active && tab.data.display) {
                const rows2 = utils.clone(tab.data.display)
                for (let i = 0; i < rows2.length; i++) {
                  const d2 = rows2[i]
                  // 単価1×数量
                  let p = Number(d2.price)
                  let q = Number(d2.qty)
                  if (!p || isNaN(p)) {
                    p = 0
                  }
                  if (!q || isNaN(q)) {
                    q = 0
                  }
                  let price = BigNumber(p).times(q)
                  val = Number(BigNumber(price).plus(val))
                }
              }
            }
          }
          if (Number(this.commission[0].price) == Math.floor(val)) {
            return true
          }
          await dialogs.showErrorDialog('単価の金額', purTxt + '全ての単価1の合計と' + text + 'の金額が合っていません。\r\n単価1を修正するかやり直ししてください。')
          return false
          
        }
      } else {
        if (rows[0].price2 != String(Math.floor(val))) {
          if (!val && rows[0].price2 == '') {
            return true
          }
          await dialogs.showErrorDialog('単価の金額', purTxt + '単価1と単価2の計算が合っていません。単価1を修正してください。')
          return false
        }
      }
      return true
    },

    async checkPrice3() {
      // 発注率・発注金額・手数料なしの金額が正しいか確認
      const pur = utils.clone(this.purchase[0])
      const com = utils.clone(this.commission[0])
      // 発注金額
      let purPrice = Number(pur.price)
      // 発注率
      let rate = Number(pur.rate)
      // 手数料なし金額
      let apolo = Number(com.estimatePrice)

      if (pur.price == '') {
        return true
      }
      if (pur.rate == '') {
        rate = 0
      }
      if (com.estimatePrice == '') {
        apolo = 0
      }

      // 発注金額と手数料なしから発注率を出す
      let res1 = BigNumber(purPrice).div(apolo)
      res1 = Math.floor(BigNumber(res1).times(1000)) / 10

      // 発注金額と発注率から手数料なしを出す
      let r2 = BigNumber(rate).div(100)
      let res2 = BigNumber(purPrice).div(r2)
      if (!res2 || isNaN(res2)) {
        res2 = 0
      }
      // 少数以下切り捨て
      res2 = Math.floor(res2)

      // 発注金額を出す
      let res3 = Math.floor(BigNumber(apolo).times(r2))
      // 比較
      if (String(res1) == pur.rate || String(res2) == com.estimatePrice || String(res3) == pur.price) {
        // 問題なしでも初期発注率オーバーかどうか確認する
        await this.overInitRate(res1)
        return true
      }
      // 問題あり
      await dialogs.showErrorDialog('発注金額・率が合いません。', '発注金額・発注率・手数料なし金額（アポロ管財）が合っていません。\r\n発注率を正しい値に変更します。')
      this.purchase[0].rate = String(res1)
      await this.overInitRate(res1)
      this.purchaseReload++
      return false
    },

    /**
     * 手数料込と単価2が合っているか確認する
     */
    async checkPrice4() {
      let comPrice = utils.clone(this.commission[0].price)
        let val = 0
        const rows = utils.clone(this.selectedDetails.display)
        for (let i = 0; i < rows.length; i++) {
          const d = rows[i]
          // 単価1×数量
          let p = Number(d.price)
          let q = Number(d.qty)
          if (!p || isNaN(p)) {
            p = 0
          }
          if (!q || isNaN(q)) {
            q = 0
          }
          let price = BigNumber(p).times(q)
          val = Number(BigNumber(price).plus(val))
        }
        // 定期と特別清掃は昔の仕様のデータがあるため
        if (['5', '14'].includes(this.categoryCode)) {
          // 手数料込金額で比較
          if (!isNaN(this.commission[0].price) && Number(this.commission[0].price) != val) {
            if (!val && this.commission[0].price == '') {
              return true
            }
            if (this.categoryCode == '5' && this.changeModalTab.length > 1) {
              
              // 他のタブ
              for (let i = 0; i < this.changeModalTab.length; i++) {
                let tab = this.changeModalTab[i];
                // 今活性になっているタブは除く
                if (!tab.active && tab.data.display) {
                  const rows2 = utils.clone(tab.data.display)
                  for (let i = 0; i < rows2.length; i++) {
                    const d2 = rows2[i]
                    // 単価1×数量
                    let p = Number(d2.price)
                    let q = Number(d2.qty)
                    if (!p || isNaN(p)) {
                      p = 0
                    }
                    if (!q || isNaN(q)) {
                      q = 0
                    }
                    let price = BigNumber(p).times(q)
                    val = Number(BigNumber(price).plus(val))
                  }
                }
              }
            }
            if (Number(this.commission[0].price) == Math.floor(val)) {
              return true
            }
            await dialogs.showErrorDialog('単価の金額', '手数料込の金額と単価2が合いません。手数料込の金額を入れ直すか、やり直して下さい。')
            return false
            
          }
        } else {
          if (rows[0].price2 != comPrice) {
            if (!comPrice && rows[0].price2 == '') {
              return true
            }
            await dialogs.showErrorDialog('手数料の金額', '手数料込の金額と単価2が合いません。手数料込の金額を入れ直すか、やり直して下さい。')
            return false
          }
        }
      return true
    },

    /**
     * 月額請求がONの場合、月額と単価2が合っているか確認
     */
    async checkMonthlyFee() {
      if (this.selectedDetails.monthlyFeeFlag) {
        const price2 = this.selectedDetails.display[0].price2
        const monthly = this.selectedDetails.monthlyFee

        // 月額から単価2を出す
        const price = this.getPrice2FromMonthly(monthly)

        if (price2 != String(price)) {
          await dialogs.showErrorDialog('月額確認', '月額と単価2が不整合ですので、計算し直します。')
          this.changeMonthlyFee()
          return false
        }
      }
      return true
    },

    /**
     * 手数料率と協力費の％が同じか確認
     */
    async checkCompe() {
      let compe = ''

      // 手数料率
      let rate = utils.hankaku(this.commission[0].rate)
      if (!rate) {
        rate = '0'
      }

      console.log(this.selectedDetails.conf)

      // 協力費項目がある場合（古いデータにはない）
      if (this.categoryCode == '14') {
        if (this.selectedDetails['spCompe']) {
          // 協力費の％
          compe = this.selectedDetails['spCompe']

          if (compe != rate) {
            const log = await dialogs.showWarningConfirmDialog('協力費と手数料が違います', `協力費：${compe}％、手数料：${rate}％で一致しません。\r\nよろしいでしょうか`)
            if (log != 'YES') {
              return false
            }
          }
        }
      } else if (['3', '4'].includes(this.categoryCode)) {
        for (let i = 0; i < this.changeModalTab.length; i++) {
          const mt = this.changeModalTab[i]
          if (mt.data.conf.compe) {
            compe = mt.data.selectedValue[20]
            if (compe != rate) {
              const log = await dialogs.showWarningConfirmDialog((i + 1) + 'つ目のタブの協力費と手数料が違います', `協力費：${compe}％、手数料：${rate}％で一致しません。\r\nよろしいでしょうか`)
              if (log != 'YES') {
                return false
              }
            }
          }
        }
      } else if (this.selectedDetails.conf && this.selectedDetails.conf['compe']) {
        let co = this.displaySubItems.find((c) => {
          return c.item == 'compensation'
        })
        if (co.item) {
          // 協力費の％
          compe = co.value

          if (compe != rate) {
            const log = await dialogs.showWarningConfirmDialog('協力費と手数料が違います', `協力費：${compe}％、手数料：${rate}％で一致しません。\r\nよろしいでしょうか`)
            if (log != 'YES') {
              return false
            }
          }
        }
      }
      return true
    },

    /**
     * 完了ボタンイベント
     */
    async saveDetail() {
      this.active = false
      // 特記事項の未記入確認
      if (!await this.checkNote(this.selectedDetails.noteHTML)) {
        this.active = true
        return
      }

      // バリデート
      if (!await this.validate()) {
        this.active = true
        return
      }

      // 何も入力されていない行は親画面に表示しない
      this.checkDisplay()

      // 単価1と単価2の計算が合っているか確認する
      if (!await this.checkPrice2()) {
        this.active = true
        return
      }

      // 発注金額・発注率・手数料なし金額が正しいか確認する
      if (!await this.checkPrice3()) {
        this.active = true
        return
      }

      // 手数料込と単価2が合っているか確認する
      if (!await this.checkPrice4()) {
        this.active = true
        return
      }

      // 月額請求がONの場合、月額と単価2が合っているか確認する
      if (!await this.checkMonthlyFee()) {
        this.active = true
        return
      }

      if (!['3', '4', '5'].includes(this.categoryCode)) {
        // 手数料率と協力費の％が同じか確認
        if (!await this.checkCompe()) {
          this.active = true
          return
        }
      }

      this.setSelectedValue(this.selectedDetails)

      switch (this.categoryCode) {

        // 定期・スポット清掃
        case '5':
        case '28': {
          let ok = true
          ok = await this.checkFixed()
          if (!ok) {
            this.active = true
            return
          }
        }
          break;
      
        default:
          break;
      }

      // 日常、管理、定期
      if (['3', '4', '5'].includes(this.categoryCode)) {

        // モーダルのデータを保存
        // アイコンの背景色の判定
        let Icon = false
        // 定期の表データを1つにまとめる
        let dispF = []
        // 週の合計回数
        let count = 0
        // 特記事項
        let text = ''

        for (let i = 0; i < this.changeModalTab.length; i++) {
          if (this.changeModalTab[i].active) {
            this.changeModalTab[i].data = JSON.parse(JSON.stringify(this.selectedDetails))
          }

          if (this.changeModalTab[i].disabledDisp) {

            const dialogResult1 = await dialogs.showWarningConfirmDialog('完了確認', i+1 + '番目のタブが完了していませんがよろしいでしょうか')
            if (dialogResult1 != 'YES') {
              this.active = true
              return
            }
          }
          
          // 日常、管理
          if (['3', '4'].includes(this.categoryCode)) {
            let v = this.changeModalTab[i].data.selectedValue[7]
            if (v != '') {
              count += Number(v)
            }

          // 定期
          } else {
            this.changeModalTab[i].data.display[0]['fixedRowspan'] = this.changeModalTab[i].data.display.length

            dispF = [...dispF, ...this.changeModalTab[i].data.display]
          }

          if (this.changeModalTab[i].data.iconBgColor) {
            Icon = true
          }

          this.changeModalTab[i].changeDisp = false
        }

        // 手数料率と協力費の％が同じか確認
        if (!await this.checkCompe()) {
          this.active = true
          return
        }

        for (let i = 0; i < this.changeModalTab.length; i++) {
          // 特記事項をまとめる
          text = text + this.changeModalTab[i].data.noteHTML
          if (i != this.changeModalTab.length - 1 && this.changeModalTab[i+1] && this.changeModalTab[i+1].data.noteHTML != '') {
            // 改行を追加
            text += '<br><br>'
          }
        }

        // 特記事項
        this.selectedDetails.noteHTML = text

        if (count >= 8) {
          const dialogResult2 = await dialogs.showWarningConfirmDialog('清掃回数', '清掃回数が合計「' + count + '回」ですがよろしいでしょうか')
          if (dialogResult2 != 'YES') {
            this.active = true
            return
          }
        }

        // 定期・スポット
        if (['5', '28'].includes(this.categoryCode)) {
          // 集計行を先頭に追加
          let row = {name: '', qty: '', unitName: '', priceKind: '', kindCoef:'', price: '', detailBgColor: false, type: 'fixedCalc', qty2: '', unitName2: '', price2: '', categoryBgColor: false, fixedRowspan: 1}
          dispF.unshift(row)

          this.selectedDetails.display = dispF
        }
        // 特別清掃の場合（編集も開いた時に強制的にtrueにしているが念のため）
        if (this.categoryCode == '14') {
          this.selectedDetails.newData = true
        }

        this.selectedDetails.modalTabData = this.changeModalTab
        this.selectedDetails.iconBgColor = Icon
      }

      // 特別、管球、粗大ごみ、物品販売
      if (['14', '23', '24', '25'].includes(this.categoryCode)) {
        // 黄色いアイコンを出すか判定
        this.selectedDetails.iconBgColor = false
        for (let i = 0; i < this.selectedDetails.display.length; i++) {
          const disp = this.selectedDetails.display[i]
          if (disp.priceKindBgColor || disp.ownerBgColor || disp.finishPointBgColor || disp.compeBgColor) {
            this.selectedDetails.iconBgColor = true
            break
          }
        }
      }

      this.selectedDetails.purchase = this.purchase[0]

      // 手数料率が空文字なら0にする
      if (this.commission[0].rate == '') {
        this.commission[0].rate = '0'
      }
      this.selectedDetails.commission = this.commission[0]
      this.selectCallback(this.selectedDetails)
      this.closed()
    },

    /**
     * 選択した値を保存
     */
    setSelectedValue(selectedDetails) {
      for (let i = 0; i < this.displaySubItems.length; i++) {
        selectedDetails.selectedValue[i] = this.displaySubItems[i].value
      }
    },

    /**
     * 初期化
     * @param type save:完了ボタン押下、それ以外:値を保存しない
     */
    closed() {
      this.active = false
      this.selectedDetails = {
        // 親画面表示内容
        display: [
          {
            // 内容
            name: '',
            // 数量
            qty: '',
            // 単位
            unitName: 'ヶ月',
            // 金額
            price: '',
            // 金額変更
            detailBgColor: false,
            // カテゴリー数量
            qty2: '',
            // カテゴリ－単位
            unitName2: 'ヶ月',
            // カテゴリー金額
            price2: '',
            // カテゴリー金額変更
            categoryBgColor: false,
            // 計算結果を表示している行 calc
            type: 'calc'
          }
        ],
        // 選択内容履歴(ツールチップ用)
        toolTip: {},
        // 係数
        conf: {},
        // 特記事項
        noteHTML: '',
        // 単価表の単価
        unitPrice: '',
        // その他、計算結果
        price: {},
        // 上の項目表の選択、入力した値
        selectedValue: [],
        // 特別清掃のオーナー契約
        spOwner: '',
        // 単価変更フラグ
        unitPriceBgColor: false,
        // 価格変更フラグ（どれか1つでも変更したらtrue）
        iconBgColor: false,
        // 日常・管理 特記事項で使用
        remarks: [{start: '', end: '', week: [], weekDetail: [], breakHour: '', breakMinutes: ''}],
        // 日常・管理 年末年始休み
        newYearHoliday: [],
        // 定期清掃の表
        displayFixed:[
          {
            // 内容
            name: '',
            // 数量
            qty: '',
            // 単位
            unitName: '㎡'
          }
        ],
        // 単価取得ボタン false:一度も押下していない
        getPriceFlag: false,
        // 親画面に渡すモーダル配列（日常、管理、定期）
        modalTabData: [],
        // 発注金額を手動で変更
        changePurchasePrice: false,
        // 月額計算ONOFF
        monthlyFeeFlag: false,
        // 月額金額
        monthlyFee: null
      },
      // 表示する項目
      this.displaySubItems = [],
      // 計算結果
      this.totalUnitPrice = '',
      // 並べ替え
      this.sortableDetail = false,
      // 計算式の詳細表示
      this.formulaDispFlag = false
      // 画面にバインドするための変数
      this.valueArr = [],
      this.forBind = 0,
      // 特別清掃モーダルに渡すデータ
      this.param = [],
      // 特別清掃モーダル表示・非表示
      this.specialShow = false,
      // 日常、管理、定期、スポット用の切替タブ
      this.changeModalTab = [{id: '', name: '', active: true, data: {},  msg: '', changeDisp: false, disabledDisp: true}],
      // メッセージ
      this.msg = '',
      // 曜日選択
      this.week = [utils.clone(WEEK_LIST)]
      // 発注モーダル
      this.purchaseModalShow = false,
      // 発注明細（過去の見積りにはないため、ここで宣言）
      this.purchase = [{
        categoryCode: '',
        categoryName: '',
        groupName: '',
        supplier: '',
        rate: '',
        price: '',
        priceBgColor: false,
        rateBgColor: false
      }]
      // カテゴリ金額合計
      this.categoryAmount = 0
      // 発注金額手動で変更後、自動計算直後
      this.changePurchasePrice = false
      // 発注明細表示のための変数
      this.purchaseReload = 0
      // 発注率超えた
      this.overPurchaseRate = false
      // 初期発注率
      this.initRate = 0,
      // モーダルを開いたばかり
      this.openInit = true,
      // 手数料明細（過去の見積りにはないため、ここで宣言）
      this.commission = [{
        categoryCode: '',
        categoryName: '',
        groupName: '',
        estimatePrice: '',
        rate: '',
        commissionPrice: '',
        price: ''
      }]
      // 手数料明細表示のための変数
      this.commissionReload = 100
      // 特別清掃のデータが新仕様のものか
      this.newData = false
      // 月額と単価2との違い
      this.monthlyError = null
      // 単価を取得ボタンを押した直後
      this.getPriceMasterBtn = false
    },

    /**
     * アラート表示
     * @param target 取得データ
     * @param errMsg エラーメッセージ
     */
    setAlert(target, errMsg) {

      if (Array.isArray(target)) {
        if (!target.length) {
          alert(errMsg)
          return true
        }
      } else {
        if (!target && target != 0) {
          alert(errMsg)
          return true
        }
      }
      return false
    },

    /**
     * 係数などの単価を取得
     * @param name 取得する単価コード
     * @param category カテゴリコード
     */
    getUnitPrice(priceCode, category) {
      let priceList = this.$store.getters.estimateItemPriceList
      let list = priceList.find((p) => {
        return p.parentCode == category && p.priceCode == priceCode
      })

      if (!list || !list.price) {
        alert('取得できなかった単価があります。')
        return ''
      }

      return list.price
    },

    /**
     * DBにないデータをconstantsEstimate.jsから取得
     */
    getConstantsData(name, code, listName) {
      let list = constants[listName]
      if (this.setAlert(list, '単価取得できませんでした。')) return

      let result = list.filter((l) => {
        return l[name] == code
      })
      if (result.length) {
        return result[0].item
      } else {
        return null
      }
    },

    /**
     * 未入力を青くする
     * @param i 番号
     */
    setEmptyColor(i) {
      if (['5', '28'].includes(this.categoryCode) && i == 4) {
        return 'text-gray-500 bg-gray-50'
      }
      if (this.displaySubItems[i].value == '' || this.displaySubItems[i].value == undefined) {
        return 'text-white bg-blue-800'
      } else {
        return 'text-gray-500 bg-gray-50'
      }
    },

    /**
     * 特別清掃のオーナ契約、竣工係数 色設定
     */
    setEmptyColorSp(val) {
      if (!val || val == '') {
        return 'text-white bg-blue-800'
      } else {
        return 'text-gray-500 bg-gray-50'
      }
    },

    /**
     * 曜日選択 色設定
     * @param r 対象
     */
    setEmptyColorWeek(i, r) {
      if (i != 0) {
        return 'text-gray-500 bg-gray-50'
      }
      if (r == undefined || !r || !r.week.length) {
        return 'text-white bg-blue-800'
      } else {
        return 'text-gray-500 bg-gray-50'
      }
    },

    /**
     * 特記事項の時間 色設定
     */
    setEmptyColorTime(i, s, e) {
      if (i != 0) {
        return 'text-gray-500 bg-gray-50'
      }
      if (!s || !e) {
        return 'text-white bg-blue-800'
      }
      let start = s.split(':')
      let end = e.split(':')
      if (start.length == 2 && end.length == 2 && start[0] != '' && start[1] != '' && end[0] != '' && end[1] != '') {
        return 'text-gray-500 bg-gray-50'
      } else {
        return 'text-white bg-blue-800'
      }
    },

    /**
     * 年末年始の勤務日選択 色設定
     * @param target 対象の項目
     */
    setEmptyColorNewYearHoliday(target) {
      if (target && target.value == '1') {
        if (this.selectedDetails.newYearHoliday && this.selectedDetails.newYearHoliday.length > 0) {
          return 'text-gray-500 bg-gray-50'
        } else {
          return 'text-white bg-blue-800'
        }
      } else {
        return 'text-gray-500 bg-gray-50'
      }
    },

    /**
     * 単価取得
     */
    async getPriceMasterData() {
      this.openInit = false

      // 日常・管理員の値チェック
      if(!await this.checkNormalAndMgm()) {
        return
      }

      let zero = []
      
      // 全て入力されているかチェック
      // 特別清掃
      if (this.categoryCode == '14') {
        for (let i = 0; i < this.spItem.length; i++) {
          const sp = this.spItem[i]
          const name = this.spItemName[i]
          if (!this.selectedDetails[sp]) {
            await dialogs.showErrorDialog('入力確認', name + ' が入力されていません。')
            return
          }
        }
      // 特別清掃以外
      } else {
        for (let i = 0; i < this.displaySubItems.length; i++) {

          // 日常・管理
          if (['3', '4'].includes(this.categoryCode)) {
            // 他ありでは、㎡数は未記入でOK
            if (i == 11 && this.displaySubItems[10].value == '1') {
              continue
            }
            // ㎡数不明以外は、タイプ・戸数・回数・本数は未記入でOK
            if ([12, 13, 14, 15].includes(i) && this.displaySubItems[11].value != '4') {
              continue
            }
            // ㎡数300㎡超え以外は、㎡数記入は未記入でOK
            if (i == 19 && this.displaySubItems[11].value != '3') {
              continue
            }
          }
          
          // 定期・スポット清掃で
          if (['5', '28'].includes(this.categoryCode)) {
            // ㎡数不明以外は「戸数」「タイプ」「階数」「本数」は空文字でもOk
            if ([4, 7, 8, 9].includes(i) && !this.uncertain) {
              continue
            } else if (this.uncertain) {
              if (i == 4 && this.displaySubItems[4].value == '0') {
                zero.push('戸数')
              } else if (i == 8 && this.displaySubItems[8].value == '0') {
                zero.push('階数')
              } else if (i == 9 && this.displaySubItems[9].value == '0') {
                zero.push('本数')
              }
            }
          }

          // フロントサポートプラス
          if (this.categoryCode == '7') {
            // 作業回数は未入力でOK（週1固定）
            if (i == 2) {
              continue
            }
          }

          // 貯水槽
          if (this.categoryCode == '12') {
            // 駐車場代無しの場合,エリアは未入力でOK
            if ([3,4].includes(i) && this.displaySubItems[2].value == '0') {
              continue
            }
            // 発注先がベスパーの場合、ニッツのエリアは未入力でOK
            if (i == 3 && this.displaySubItems[1].value == '2') {
              continue
            }
            // 発注先がニッツの場合、ベスパーのエリアは未入力でOK
            if (i == 4 && this.displaySubItems[1].value == '1') {
              continue
            }

          // 消防
          } else if (this.categoryCode == '15') {
            // エリアが超超都心以外、駐車場代は未入力でOK
            if (i == 1 && this.displaySubItems[0].value != '1') {
              continue
            }
            // 消火器・避難器具のみでないの場合、減額は未入力でOK
            if (i == 3 && this.displaySubItems[2].value == '0') {
              continue
            }
            // 消火器・避難器具のみの場合、戸数、タイプ、消火栓箇所、スプリンクラーは未入力でOK
            if ([4, 5, 8, 9].includes(i) && this.displaySubItems[2].value == '1') {
              continue
            }
          }
          
          if (this.displaySubItems[i].value == '' || this.displaySubItems[i].value == undefined) {
            await dialogs.showErrorDialog('入力確認', this.displaySubItems[i].name + ' が入力されていません。')
            return
          }
        }
      }
      if (zero && zero.length) {
        let uncertainDlog = await dialogs.showConfirmDialog(zero.join('・'), `㎡数不明ですが、${zero.join('・')}が0です。\r\nよろしいですか？`)
        if (uncertainDlog != 'YES') {
          return
        }
      }

      for (let c in this.selectedDetails.conf) {
        if (c.indexOf('BgColor') != -1) {
          this.selectedDetails.conf[c] = false
        }
      }

      for (let p in this.selectedDetails.price) {
        if (p.indexOf('BgColor') != -1) {
          this.selectedDetails.price[p] = false
        }
      }

      this.selectedDetails.iconBgColor = false

      this.selectedDetails.unitPrice = ''
      this.selectedDetails.unitPriceBgColor = false

      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        let disp = this.selectedDetails.display[i]
        let typeName = this.setTypeName()
        if (disp.type == typeName) {
          disp.nameBgColor = false
          disp.detailBgColor = false
          disp.price = ''
          disp.categoryBgColor = false
          disp.price2 = ''
          // ラウンドトラッシュの場合
          if (this.categoryCode == '8') {
            // 作業回数が週4以下の場合のフラグ
            disp.trashTimesBgColor = false
          }
          break
        }
      }

      // 初期化
      this.selectedDetails.changePurchasePrice = false
      // 単価取得ボタン押下直後フラグ
      this.priceMasterBtnOn()

      // 日常・管理・定期のモーダル切替
      this.setTabName()

      // 日常・管理・定期のモーダルの表変更フラグをtrueにする
      if (this.getActiveTab() != -1) {
        this.changeModalTab[this.getActiveTab()].changeDisp = true
        this.changeModalTab[this.getActiveTab()].disabledDisp = false
      }

      // 「常に発注率を変更」をON
      if (['10', '13', '27', '12', '15', '19', '20'].includes(this.categoryCode) || (['16', '17'].includes(this.categoryCode) && this.displaySubItems && this.displaySubItems[0] && this.displaySubItems[0].value == '3')) {
        this.selectedDetails.purchaseRateAlwaysChange = true
      }
      
      switch (this.categoryCode) {
        // RLS
        case '1':
          await this.getRlsCleaning()
          break;

        // 日常清掃,管理員業務
        case '3':
        case '4':
          await this.getDailyCleaningOrMgt()
          break;
          
        // 定期・スポット清掃
        case '5':
        case '28':
          await this.getFixedCleaning()
          break;
      
        // フロント・ゴミサポ
        case '6':
        case '7':
        case '8':
        case '9':
          await this.getFrontOrTrash()
          break;

        // ガラス清掃
        case '10':
          this.getGlassCleaning()
          break;

        // 排水管清掃
        case '11':
          this.getDrainageCleaning()
          break;

        // 貯水槽清掃
        case '12':
          await this.getStorageCleaning()
          break;

        // 植栽剪定,給排水ポンプ点検,簡易専用水道,緊急対応,防火対象物点検
        case '13':
        case '21':
        case '22':
        case '26':
        case '27':
          await this.getOwner()
          break;

        // 特別清掃
        case '14':
          this.getSp()
          break;

        // 消防点検
        case '15':
          await this.getFirefighting()
          break;

        // 建築設備点検
        case '16':
          await this.getArchitecture()
          break;

        // 特定建築物定期調査
        case '17':
          await this.getSpArchitecture()
          break;

        // 共用部設備点検
        case '18':
          await this.getCommonArea()
          break;

        // EV点検
        case '19':
          await this.getEv()
          break;

        // 増圧ポンプ
        case '20':
          await this.getBooster()
          break;

        // 管球交換
        case '23':
          this.getBulb()
          break;

        // 粗大ごみ・物品販売
        case '24':
        case '25':
          this.getTrashOrSale()
          break;

        // MRS
        case '29':
          await this.getMRS()
          break;

        // カテゴリーコードなし
        default:
          await this.getOwner()
          break;
      }
      this.selectedDetails.getPriceFlag = true
      // 初期化（ここでも計算しないと、計算式の値が変わらなかったときにリセットされない）
      this.setInitPurchase()

      // 式の中に「発注金額」があるもの
      if (['10', '13', '27', '12', '15', '19', '20'].includes(this.categoryCode) || (['16', '17'].includes(this.categoryCode) && this.displaySubItems && this.displaySubItems[0] && this.displaySubItems[0].value == '3')) {
        // 式の中に「発注金額」があるものは率を変更する
        await this.getPurchasePrice(null, false, false)
      } else {
        await this.getPurchasePrice(this.purchaseData[0].groupName, false, true)
      }
      
      this.purchaseReload++
    },

    /**
     * 単価取得ボタン押下直後フラグON
     */
    priceMasterBtnOn() {
      // 式の中に「発注金額」があるもの以外
      if (!['10', '13', '27', '12', '15', '19', '20'].includes(this.categoryCode) && !(['16', '17'].includes(this.categoryCode) && this.displaySubItems && this.displaySubItems[0] && this.displaySubItems[0].value == '3')) {
        this.getPriceMasterBtn = true
      }
    },

    /**
     * 計算する
     */
    calc() {
      // 計算結果を即画面に反映させるためだけの変数（常に値が変わるようにインクリメント）
      this.forBind ++

      switch (this.categoryCode) {
        // RLS
        case '1':
          this.calcRlsCleaning()
          break;

        // 日常清掃,管理員業務
        case '3':
        case '4':
          this.calcDailyCleaningOrMgt()
          break;

        // 定期・スポット清掃
        case '5':
        case '28':
          this.calcFixedCleaning()
          break;

        // フロント・ゴミサポ,給排水ポンプ点検,簡易専用水道
        case '6':
        case '7':
        case '8':
        case '9':
        case '21':
        case '22':
        case '26':
          this.calcOwner()
          break;

        // ガラス,植栽,防火
        case '10':
        case '13':
        case '27':
          this.calcOrder()
          break;

        // 建築設備点検,特定建築物定期検査
        case '16':
        case '17':
          // 発注額が決まっている
          if (this.displaySubItems && this.displaySubItems[0] && this.displaySubItems[0].value == '3') {
            this.calcOrder2()
          // 決まっていない
          } else {
            this.calcOwner()
          }
          break;

        // 排水管清掃
        case '11':
          this.calcDrainageCleaning()
          break;
      
        // 貯水槽清掃
        case '12':
          this.calcStorageCleaning()
          break;
        
        // 消防点検
        case '15':
          this.calcFirefighting()
          break;

        // 共用部設備点検
        case '18':
          this.calcCommonArea()
          break;

        // EV点検
        case '19':
          this.calcEV()
          break;

        // 増圧ポンプ
        case '20':
          this.calcBooster()
          break;

        // 特別・管球・粗大ごみ・物品販売
        case '14':
        case '23':
        case '24':
        case '25':
          this.calcCoeffToTable()
          break;

        // MRS
        case '29':
          this.calcMRS()
          break;

        // カテゴリーコードなし
        default:
          this.calcOwner()
          break;
      }
      this.forBind ++

      // 特別清掃or定期の300㎡越えでは反映しない
      if ((['14'].includes(this.categoryCode)) || (['5', '28'].includes(this.categoryCode) && (this.exceed300 || this.selectedDetails.conf['meter'] == '3'))) {
        return
      }
      this.setUnitPrice()
      this.categoryCalc()
    },

    /**
     * 計算結果を表に挿入
     */
    setUnitPrice() {

      // 日常、管理、定期の場合、そのモーダルで一度でも単価取得ボタンを押していないなら表への反映はしない
      if (this.getActiveTab() != -1) {
        if (!this.changeModalTab[this.getActiveTab()].changeDisp) {
          return
        }
      }

      // 計算結果を使用していないもの（独自の式がないもの）は何もしない、特別・管球・粗大ゴミ・物販
      if (['14', '23', '24', '25'].includes(this.categoryCode)) {
        return
      }

      let typeName = this.setTypeName()

      let isCalc = false
      let price = String(Math.floor(this.totalUnitPrice))
      // 計算結果が空文字、0の際は、空文字を表に入れる
      if (this.totalUnitPrice == '' || this.totalUnitPrice == '0' || isNaN(this.totalUnitPrice)) {
        price = ''
      }
      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        if (this.selectedDetails.display[i].type == typeName) {
          isCalc = true
          if (this.selectedDetails.display[i].detailBgColor) {
            return
          }
          this.selectedDetails.display[i].price = price
          return
        }
      }
      if (!isCalc && this.selectedDetails.display[0].name == '' && this.selectedDetails.display[0].price == '') {
        this.selectedDetails.display[0].type = typeName
        this.selectedDetails.display[0].price = price
      } else {
        
        this.addRowDetail()
        let i = this.selectedDetails.display.length - 1
        this.selectedDetails.display[i].type = typeName
        this.selectedDetails.display[i].price = price
      }
    },

    /**
     * 竣工係数取得
     */
    getFinishPointConf() {

      if (this.displaySubItems) {
        let idx = null
        let value = ''
        this.selectedDetails.conf['finishPoint'] = '1'

        // 特別清掃、スポット清掃、管球、粗大ごみ、物販、緊急、MRS以外
        if (!['14', '28', '23', '24', '25', '26', '29'].includes(this.categoryCode)) {
          for (let i = 0; i < this.displaySubItems.length; i++) {
            const d = this.displaySubItems[i]
            if (d.item == 'finishPoint') {
              idx = i
              value = d.value
              break
            }
          }
        // 特別清掃の場合
        } else if (this.categoryCode == '14' && this.selectedDetails['spFinishPoint']) {
          idx = 1
          value = this.selectedDetails['spFinishPoint']
        }

        // スポット清掃、MRS以外
        if (this.categoryCode != '28' && this.categoryCode != '29') {  
          if (idx != null && value && value != '0') {
            // 1年ごとに5％増
            let res = BigNumber(0.05).times(Number(value)).plus(1)
            this.selectedDetails.conf['finishPoint'] = String(res)
          }
        }
      }
    },

    /**
     * RLS計算
     */
    calcRlsCleaning() {
      let conf = this.selectedDetails.conf
      let price = this.selectedDetails.price

      // トイレ ＝ 回数 × 箇所 × トイレ単価 × 4.3
      price['toilet'] = String(Math.floor(BigNumber(Number(this.displaySubItems[5].value || 0)).times(BigNumber(Number(this.displaySubItems[6].value || 0))).times(BigNumber(Number(conf['toilet'] || 0))).times(BigNumber(4.3))))

      // メーター ＝ 回数 × 箇所 × メーター単価
      price['meter'] = String(Math.floor(BigNumber(Number(this.displaySubItems[7].value || 0)).times(BigNumber(Number(this.displaySubItems[8].value || 0))).times(BigNumber(Number(conf['meter'] || 0)))))

      // 写真 月1回の場合
      if (this.displaySubItems[9].value == 'm1') {
        price['photo'] = conf['photo']
      
      // 写真 月1回以外の場合
      } else {
        // 写真 ＝ 回数 × 写真単価 × 4.3
        price['photo'] = String(Math.floor(BigNumber(Number(this.displaySubItems[9].value || 0)).times(BigNumber(Number(conf['photo'] || 0))).times(4.3)))
      }

      // 金額 ＝ （トイレ + メーター + 写真 + 単価）× 竣工 × オーナー
      // this.totalUnitPrice = String(Math.ceil((BigNumber(Number(price['toilet'] || 0)).plus(BigNumber(Number(price['meter'] || 0))).plus(BigNumber( Number(price['photo'] || 0))).plus(BigNumber(Number(this.selectedDetails.unitPrice || 0)))).times(BigNumber(Number(conf['finishPoint'] || 1))).div(Number(conf['compe']) || 1).times(BigNumber(Number(conf['owner'] || 0))) / 100) * 100)

      // 金額 ＝ （トイレ + メーター + 写真 + 単価）× 竣工 ÷ 協力費
      let withoutOwner = (BigNumber(Number(price['toilet'] || 0)).plus(BigNumber(Number(price['meter'] || 0))).plus(BigNumber( Number(price['photo'] || 0))).plus(BigNumber(Number(this.selectedDetails.unitPrice || 0)))).times(BigNumber(Number(conf['finishPoint'] || 1))).div(Number(conf['compe']) || 1)
      this.totalUnitPrice = String(Math.ceil(BigNumber(withoutOwner).times(BigNumber(Number(conf['owner'] || 1))) / 100) * 100)

      // this.selectedDetails.withoutOwner = withoutOwner
    },

    /**
     * RLS単価取得
     */
    async getRlsCleaning() {
      let h = Number(this.displaySubItems[0].value)
      if (h >= 51) {
        h = 50
      }
      let cleaningTimes = this.displaySubItems[2].value
      let ins = this.displaySubItems[4].value

      if (!['m1', 'm2', '1', '2'].includes(cleaningTimes)) {
        ins = '0'
      }

      if (this.displaySubItems[1].value != '1' && Number(this.displaySubItems[0].value) <= 20 && ['m1', 'm2'].includes(cleaningTimes)) {
        alert( this.selectedDetails.toolTip['清掃回数'] + 'の場合、単価は「シングル」単価で取得します。')
        this.setInitValue(this.displaySubItems[1], 'シングル')
      }

      // 単価コードを生成(タイプ-清掃回数-ゴミ出し回数-点検)
      let priceCode = this.setType(1) + '-' + cleaningTimes + '-' + this.displaySubItems[3].value + '-' + ins
      // 単価表から単価を取得
      let list = this.$store.getters.estimateItemPriceList.filter((l) => {
        if (l.parentCode == this.categoryCode && l.priceCode == priceCode && Number(l.minHouseholds) <= h && Number(l.maxHouseholds) >= h) {
          return true
        }
      })

      // 特記事項コード
      let noteCode = []

      // 組み合わせが単価表にない場合
      if (!list.length) {
        alert('単価表にない組み合わせです。単価は直接ご記入ください。')

        // 表示内容
        let t = ''
        if (this.selectedDetails.toolTip['ゴミ出し回数(週)']) {
          t = this.selectedDetails.toolTip['ゴミ出し回数(週)']
          if (t == 'ゴミ出し無し') {
            t = '（ゴミ搬出無し）'
          } else {
            t = t.replace(/\s+/g, '')
            t = '（' + t + 'ゴミ搬出）'
          }
        }

        let n = ''
        // 週3以上
        if (!['m1', 'm2', '1', '2'].includes(cleaningTimes) && Number(cleaningTimes) >= 3) {
          if (this.selectedDetails.toolTip['ゴミ出し回数(週)']) {
            if (this.selectedDetails.toolTip['ゴミ出し回数(週)'] == 'ゴミ出し無し') {
              n = '1-1'
            } else {
              n = '1-2'
            }
          }
        
        // 週1，2
        } else if (['1', '2'].includes(cleaningTimes)) {
          n = '1-3'

        // 月1,2
        } else {
          n = '1-4'
        }

        if (['1', '2'].includes(cleaningTimes) && this.displaySubItems[4].value == '1') {
          noteCode.push(this.setNoteCode2(n, '', '週1回灯具点灯確認含みます。', '週1回灯具点灯確認･月次目視点検含みます。'))
        } else if (['m1', 'm2'].includes(cleaningTimes) && this.displaySubItems[4].value == '1') {
          noteCode.push(this.setNoteCode2(n, '', '灯具点灯確認含みます。', '灯具点灯確認・月次目視点検含みます。'))
        } else {
          noteCode.push(this.setNoteCode(n, ''))
        }

        this.setDisplayItem({name:this.selectedDetails.toolTip['清掃回数'].replace(/\s+/g, '') + '巡回清掃' + t, qty:'1', unitName:'ヶ月'}, '')

      // 単価表にあった場合
      } else {
        // 金額無し
        if (list[0].price == '') {
          alert('単価表に金額がありません。単価は直接ご記入ください。')
        }
        
        if (h >= 16 && h <= 20 && ['m1', 'm2', '1', '2'].includes(cleaningTimes)) {
          alert('16～20戸 かつ ' + this.selectedDetails.toolTip['清掃回数'] + 'は特例です。\r\n15戸までの単価を表示しています。\r\n必要に応じて単価を書き換えてください。')
        } else if (Number(this.displaySubItems[0].value) >= 51) {
          alert('51戸以上の単価はデータにありません。\r\n50戸の単価を表示しています。\r\n必要に応じて単価を書き換えてください。')
        }

        // 単価
        this.selectedDetails.unitPrice = list[0].price

        if (['1', '2'].includes(cleaningTimes) && this.displaySubItems[4].value == '1') {
          noteCode.push(this.setNoteCode2(list[0].noteCode, '', '週1回灯具点灯確認含みます。', '週1回灯具点灯確認･月次目視点検含みます。'))
        } else if (['m1', 'm2'].includes(cleaningTimes) && this.displaySubItems[4].value == '1') {
          noteCode.push(this.setNoteCode2(list[0].noteCode, '', '灯具点灯確認含みます。', '灯具点灯確認・月次目視点検含みます。'))
        } else {
          noteCode.push(this.setNoteCode(list[0].noteCode, ''))
        }

        if (list[0].name) {
          list[0].name = list[0].name.replace('ｺﾞﾐ', 'ゴミ')
        }

        this.setDisplayItem(list[0], '')
      }

      // 週3より少なく、ゴミ搬出がある場合、特記事項に追加（週3以上は、すでに特記事項にあり）
      if (['m1', 'm2', '1', '2'].includes(cleaningTimes) && this.displaySubItems[3].value != '0') {
        noteCode.push(this.setNoteCode('1-9', ''))
      }

      // バイク駐車 不明
      if (this.displaySubItems[11].value == '0') {
        noteCode.push(this.setNoteCode('99-2', ''))
      
      // バイク駐車 あり
      } else {
        noteCode.push(this.setNoteCode('99-1', ''))
      }

      // トイレ清掃の特記事項
      if (Number(this.displaySubItems[6].value) > 0) {
        noteCode.push(this.setNoteCode('100-1', 'トイレ清掃：' + this.displaySubItems[6].value + '箇所想定'))
      }

      // 検針の特記事項
      if (Number(this.displaySubItems[8].value) > 0) {
        noteCode.push(this.setNoteCode('100-2', '検針業務：共用部' + this.displaySubItems[8].value + '箇所想定'))
      }

      await this.getEstimateNote(noteCode)

      // *--その他の単価を取得--* //

      // トイレ
      this.selectedDetails.conf['toilet'] = this.getUnitPrice('toilet', '0')
      // メーター
      this.selectedDetails.conf['meter'] = this.getUnitPrice('meter', '0')
      // 写真
      this.selectedDetails.conf['photo'] = this.getUnitPrice('photo', '0')
      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + this.displaySubItems[10].value, '0')
      // 竣工係数
      this.getFinishPointConf()

      // 写真報告ありの場合
      if (this.displaySubItems[9].value != '0') {
        // 特記事項から削除
        if (this.selectedDetails.noteHTML && this.selectedDetails.noteHTML.indexOf('写真報告書') > -1) {
          // console.log(this.selectedDetails.noteHTML)
          let a = ''
          a = this.selectedDetails.noteHTML.slice(this.selectedDetails.noteHTML.indexOf('写真報告書') - 2, this.selectedDetails.noteHTML.indexOf('円となります。') + 7)
          this.selectedDetails.noteHTML = this.selectedDetails.noteHTML.replace(a, '')
        }
      // 写真無しの場合
      } else {
        // 特記事項に写真報告の金額を入れる
        // 月1回
        let a = Number(this.selectedDetails.conf['photo']).toLocaleString()
        // 週1回
        let b = Math.floor(BigNumber(Number(this.selectedDetails.conf['photo'])).times(4.3)).toLocaleString()
        this.selectedDetails.noteHTML = this.selectedDetails.noteHTML.replace('<span style="background-color: rgb(217, 107, 107);">●●</span>', a).replace('<span style="background-color: rgb(217, 107, 107);">●●</span>', b)
      }

      // 協力費
      this.getCompe()

      // 計算
      this.calc()
    },

    /**
     * 日常清掃・管理員業務の特記事項変更
     */
    setNoteForDailyCleaningOrMgt() {
      let target = this.displaySubItems
      let ny = this.selectedDetails.newYearHoliday
      let non = []
      let holi = []
      let res = []
      // 年末年始がある場合のみ
      if (target[18].value && target[18].value == '1') {
        // 年末年始全て選択している場合
        if (ny.length == 5) {
          return 'all'
        }

        // 選択していない日を取得
        const days = [30, 31, 1, 2, 3]
        for (let i = 0; i < days.length; i++) {
          const d = days[i]
          if (!ny.includes(d)) {
            non.push(d)
          }
        }

        for (let i = 0; i < non.length; i++) {
          const n = non[i]
          switch (n) {
            case 30:
              holi.push('12月30日')
              break

            case 31:
              holi.push('12月31日')
              break

            case 1:
              holi.push('1月1日')
              break

            case 2:
              holi.push('1月2日')
              break

            case 3:
              holi.push('1月3日')
              break
          }
        }
        // 年末年始選択していないのが1つのみ
        if (holi.length == 1) {
          return holi.join('')
        }

        // 表示する形を生成
        for (let j = 0; j < holi.length; j++) {
          let h = holi[j]
          let before = holi[j-1]
          let next = holi[j+1]
            switch (h) {
              case '12月30日':
                if (next == '12月31日') {
                  if (holi[j+2] == '1月1日') {
                    res.push(h + '～')
                  } else {
                    res.push('12月30～')
                  }
                } else {
                  res.push(holi[j] + '・')
                }
                
                break

              case '12月31日':
                if (next == '1月1日') {
                  if (before == '12月30日') {
                    res.push('')
                  } else {
                    res.push(holi[j] + '～')
                  }
                } else if (before == '12月30日') {
                  if (next) {
                    res.push('31日・')
                  } else {
                    res.push('31日')
                  }
                } else if (next) {
                  res.push(holi[j] + '・')
                } else {
                  res.push(h)
                }
                
                break

              case '1月1日':
                if (next == '1月2日') {
                  if (before == '12月31日') {
                    res.push('')
                  } else {
                    res.push('1月1' + '～')
                  }
                } else if (next) {
                  res.push('1月1' + '・')
                } else {
                  res.push(h)
                }
                
                break

              case '1月2日':
                if (next == '1月3日') {
                  if (before == '1月1日') {
                    res.push('')
                  } else {
                    res.push('1月2～')
                  }
                } else {
                  if (res.includes('1月1～')) {
                    res.push('2日')
                  } else {
                    res.push(h)
                  }
                }
                
                break

              case '1月3日':
                console.log(res)
                if (before == '1月2日') {
                  if (!holi.includes('12月30日') && !holi.includes('12月31日')) {
                    res.push('3日')
                  } else if (res.includes('1月1～') || res.includes('1月2～')) {
                    res.push('3日')
                  } else {
                    res.push(h)
                  }
                } else if (holi.includes('1月1日')) {
                  res.push('3日')
                } else {
                  res.push(h)
                }
                
                break
            
              default:
                break;
            }
          }
        return res.join('')
      }
      return null
    },

    /**
     * 日常清掃,管理員業務 計算 
     */
    calcDailyCleaningOrMgt() {
      let price = this.selectedDetails.price
      let conf = this.selectedDetails.conf
      let target = this.displaySubItems

      // 下の行に表記する時間
      let name = ''

      // 月の合計時間 ＝ 清掃時間 × 清掃回数 × 人数 × 週/月
      price['hours'] = String(BigNumber(Number(this.cleaningHours)).times(Number(target[7].value)).times(Number(target[8].value)).times(4.3))
      price['hours'] = String(Math.floor(BigNumber(Number(price['hours'])).times(100)) / 100)
      if (!isNaN( price['hours']) &&  price['hours'] != '' &&  price['hours'] != '0') {
        name = Number(price['hours']) + '時間/月'
      }
    
      // トイレ合計 ＝ トイレ回数 × トイレ箇所 × トイレ単価
      let toilet = Number(target[2].value) * Number(target[3].value) * Number(conf['toilet'])
      toilet = BigNumber(toilet).times(4.3)
      toilet = BigNumber(toilet).div(Number(price['hours']))
      price['toilet'] = String(Math.floor(toilet))
      // price['toilet'] = String(Math.floor(Number(target[2].value) * Number(target[3].value) * Number(conf['toilet']) * 4.3 / Number(price['hours'])))

      // 日曜含む
      if (this.isSunday) {
        price['hours6'] = String(BigNumber(Number(this.cleaningHours)).times((Number(target[7].value) - 1)).times(Number(target[8].value)).times(4.3))
        price['hours6'] = String(Math.floor(BigNumber(Number(price['hours6'])).times(100)) / 100)
        price['hours1'] = String(Number(price['hours']) - Number(price['hours6']))
      }

      // バス代 ＝ バス代単価 ÷ 清掃時間
      price['bus'] =  String(Math.floor(Number(conf['bus'] / Number(this.cleaningHours))))

      // 追加料金 ＝ （定期概算 × 4 × エリア単価）÷ 12 × 0.3 ÷ 月の合計時間
      price['add'] = String(Math.floor(BigNumber(BigNumber(price['approx'] || 0).times(4).times(Number(conf['eria']))).div(12).times(0.3).div(Number(price['hours']))))

      // 祝日対応時間 ＝ 祝日時間 × 人数
      price['holidayHours'] = String(BigNumber(Number(conf['holidayHours'] || 0)).times(Number(target[8].value)))

      // 単価 × 早朝割増
      let unitPrice = BigNumber(Number(this.selectedDetails.unitPrice)).times(Number(conf['early'] || 1))

      // バス代/時間 + 追加料金_他業務無し + 単価 × 早朝割増
      let price1 = BigNumber(Number(price['bus'])).plus(Number(price['add'])).plus(unitPrice)

      // 祝日料金 ＝ 祝日対応時間 × （バス代/時間 + 追加料金_他業務無し + 単価 × 早朝割増）
      price['holiday'] = String(Math.floor(BigNumber(Number(price['holidayHours']) || 0).times(price1)))

      // 合計単価 ＝ （単価 + トイレ合計 + バス代/時間 + 追加料金_他業務なし）× オーナー契約
      price['totalUnitPricePre'] = String(Math.floor(BigNumber(BigNumber(unitPrice).plus(Number(price['toilet'])).plus(Number(price['bus'])).plus(Number(price['add']))).times(BigNumber(Number(conf['owner'])))))

      // 日曜含む
      if (this.isSunday) {
        // 日曜以外 合計単価 ＝ （単価 + トイレ合計 + バス代/時間 + 追加料金_他業務なし）× オーナー契約
        let price2 = BigNumber(Number(unitPrice)).plus(Number(price['toilet'])).plus(Number(price['bus'])).plus(Number(price['add']))
        price['totalUnitPricePre6'] = String(Math.floor(BigNumber(price2).times(BigNumber(Number(conf['owner'])))))
        // 日曜 割増単価 ＝ （単価 × 1.25 + トイレ合計 + バス代/時間 + 追加料金_他業務なし）× オーナー契約
        let value3 = BigNumber(unitPrice).times(1.25)
        price['totalUnitPricePre1'] = String(Math.floor(BigNumber(BigNumber(value3).plus(Number(price['toilet'])).plus(Number(price['bus'])).plus(Number(price['add']))).times(BigNumber(Number(conf['owner'])))))

        // 日曜以外 合計金額 ＝ 週6日単価 × 週6日時間
        price['totalUnitPrice6'] = String(Math.floor(BigNumber(Number(price['totalUnitPricePre6'])).times(Number(price['hours6']))))
        // 日曜 合計金額 ＝ 週1日単価 × 週1日時間
        price['totalUnitPrice1'] = String(Math.floor(BigNumber(Number(price['totalUnitPricePre1'])).times(Number(price['hours1']))))

        // 金額/月 ＝ （日曜以外金額 + 日曜金額）+（祝日料金 × オーナー契約）
        let value4 = BigNumber(Number(price['totalUnitPrice6'])).plus(Number(price['totalUnitPrice1']))
        let value5 = BigNumber(Number(price['holiday'])).times(Number(conf['owner']))
        price['byMonth'] = String(Math.floor(BigNumber(value4).plus(value5)))
      } else {
        // 金額/月 ＝ (合計単価 × 月の合計時間) + (祝日料金 × オーナー契約)
        let value6 = BigNumber(Number(price['totalUnitPricePre'])).times(Number(price['hours']))
        let value7 = BigNumber(Number(price['holiday'])).times(Number(conf['owner']))
        price['byMonth'] = String(Math.floor(BigNumber(value6).plus(value7)))
      }

      // 時給 1円単位で切上
      let wage = BigNumber(Number(price['byMonth'])).times(Number(conf['finishPoint'] || 1)).div(Number(conf['compe']) || 1).div(Number(price['hours']))

      // 年末年始ありの場合
      if (target[18] && target[18].value == '1') {
        let nyh = 0
        if (this.selectedDetails.newYearHoliday && this.selectedDetails.newYearHoliday.length) {
          nyh = this.selectedDetails.newYearHoliday.length
        }
        // 年末年始加算時間＝年末年始日数×清掃時間（FULLか1h）÷12か月÷4.3÷清掃回数（回/週）
        let value8 = BigNumber(Number(nyh)).times(Number(this.cleaningHours)).div(12).div(4.3).div(Number(target[7].value))
        // 小数第3位まで
        let value9 = Math.floor(value8 * 1000) / 1000
        price['newYearHours'] = String(value9)

        // 通常時給
        price['hourlyWagePre'] = String(Math.floor(wage))

        // 年末年始込の時給＝通常時給 ÷ 清掃時間 × 年末年始加算時間 + 通常時給
        let value10 = Math.floor(BigNumber(price['hourlyWagePre']).div(Number(this.cleaningHours)).times(Number(price['newYearHours'])))
        // console.log(value10)
        let value11 = Math.ceil((value10 + (Number(price['hourlyWagePre']))) / 10) * 10
        price['hourlyWage'] = String(value11)

      // 年末年始なしの場合
      } else {
        // 時給 1円単位で切上
        price['hourlyWage'] = String(Math.ceil(wage / 10) * 10)
      }

      // 最終金額＝時給（1円単位切上げた時給）×時間
      let total = Math.floor(BigNumber(Number(price['hourlyWage'])).times(Number(price['hours'])))
      this.totalUnitPrice = String(total)
      
      // 時給を表記
      if (!isNaN(price['hourlyWage']) && price['hourlyWage'] != '' && price['hourlyWage'] != '0' && Number(price['hourlyWage']) > 0) {
        name = name + '　' + price['hourlyWage'].toLocaleString() + '円/時間'
      }

      if (!this.rowTitle()) {
        return
      }
      name = this.rowTitle() + '\r\n' + '　' + name

      // 表の「内容」に時間と時給を追記
      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        let disp = this.selectedDetails.display[i]
        if (disp.type == this.setTypeName()) {
          disp.name = name
          break
        }
      }

    },

    /**
     * 日常清掃,管理員業務 単価取得
     */
    async getDailyCleaningOrMgt() {
      let target = this.displaySubItems
      let tip = this.selectedDetails.toolTip
      let conf = this.selectedDetails.conf

      // 祝日対応種類に応じて祝日時間をセット
      this.selectedDetails.conf['hourType'] = this.setHolidayHours(target[16].value)

      // 祝日対応
      let holi = target[16].value

      // 週1回、週1.5回は祝日割増料金無
      if (target[7].value == '1' || target[7].value == '1.5') {
        holi = '0'
      }

      if ((Number(target[2].value) == 0 && Number(target[3].value) > 0) || Number(target[2].value) > 0 && Number(target[3].value) == 0) {
        alert('トイレ回数、トイレ箇所が不正な値です。\nトイレ清掃なしの場合はどちらも0を入力してください。')
      }

      // 清掃回数が週7日の場合は、週6日の単価を取得
      let times = target[7].value
      if (target[7].value == '7') {
        times = '6'
      }
      
      // 時間が3時間以上の場合、.5がつくものは、.5なしと同じ単価を取得（3.5→3となる）
      let hours = this.cleaningHours
      if (Number(hours) >= 3 && hours.indexOf('.5') > -1) {
        hours = hours.replace('.5', '')
      }
      // 単価コード生成 （エリア-トイレ有無-時間指定-時間-回数）
      let priceCode = target[0].value + '-' + target[1].value + '-' + target[4].value + '-' + hours + '-' + times

      let parentCode = this.categoryCode
      // 日常清掃で「トイレ使用不可」は、管理員業務の単価を取得する
      if (this.categoryCode == '3' && target[1].value == '0') {
        parentCode = '4'
      }
      // 単価表から単価を取得
      let list = this.$store.getters.estimateItemPriceList.filter((l) => {
        if (l.parentCode == parentCode && l.priceCode == priceCode) {
          return true
        }
      })

      // 表示内容
      let name = ''
      if (this.rowTitle()) {
        name = this.rowTitle()
      }
      if (this.setAlert(list, '単価取得できませんでした。\n組み合わせを確認してください。')) {
        this.setDisplayItem({name: name, qty: '1', unitName: 'ヶ月'}, '')

      } else {
        // 単価
        this.selectedDetails.unitPrice = list[0].price
        
        this.setDisplayItem({name: name, qty: list[0].qty, unitName: list[0].unitName}, '')
      }


      // 特記事項(日常清掃は、祝日対応によって特記事項が特定される)
      let noteCode = []
      let r = this.selectedDetails.remarks
      let weekAndTime = ''

      if (target[4].value == 0) {
        weekAndTime = '清掃開始時間・曜日は弊社にて設定させて頂きます。'
      } else {
        for (let i = 0; i < r.length; i++) {
          // 曜日
          const w = r[i].week
          // 曜日の詳細
          const wd = r[i].weekDetail
          // 開始時間
          const start = r[i].start
          // 終了時間
          const end = r[i].end
          // 休憩時間
          const breakHour = r[i].breakHour
          const breakMinutes = r[i].breakMinutes
          let breakTime = ''
          if (breakHour && breakHour != '0') {
            breakTime = breakHour + '時間'
          }
          if (breakMinutes && breakMinutes != '0') {
            breakTime += breakMinutes + '分'
          }

          // 毎週以外は、曜日の前に文言を加える
          const weekArr = w.map((name, wIdx) => {
            if (wd[wIdx] && wd[wIdx].length && !wd[wIdx].includes('毎週')) {
              name = name + '(' + wd[wIdx].join('・') + ')'
            }
            return name
          })

          // 清掃員1人の場合
          if (r.length == 1) {
            weekAndTime = weekArr.join('・') + '（' + start + '～' + end + '）'
            if (breakTime) {
              weekAndTime = weekAndTime + ` (内、${breakTime}休憩含む)`
            }
          // 清掃員複数の場合
          } else {
            let addMsg = ''
            addMsg = weekArr.join('・')

            if ((start + end).replace(/:/g, '')) {
              addMsg += '（' + start + '～' + end + '）'
            }

            if (breakTime) {
              addMsg += ` (内、${breakTime}休憩含む)`
            }

            weekAndTime += addMsg

            // 最後の行でもなく、メッセージがあれば改行を入れる
            if (i+1 != r.length && addMsg) {
              weekAndTime += '\n'
            }
          }
        }
      }

      // 時間指定なし＆祝日FULL
      if (target[4].value == '0' && target[16].value == '1') {
        noteCode.push(this.setNoteCode2('3-4', this.categoryName + '：' + this.rowTitle(), '●●・●●・●●・●●\n●●：●●～●●：●●', weekAndTime))

      // 祝日ゴミ出しのみ
      } else if (target[16].value == '2') {
        noteCode.push(this.setNoteCode2('3-1', this.categoryName + '：' + this.rowTitle(), '●●・●●・●●・●●\n●●：●●～●●：●●', weekAndTime))

      // 祝日FULL
      } else if (target[16].value == '1') {
        noteCode.push(this.setNoteCode2('3-3', this.categoryName + '：' + this.rowTitle(), '●●・●●・●●・●●\n●●：●●～●●：●●', weekAndTime))

      // 祝日なし
      } else if (target[16].value == '0') {
        noteCode.push(this.setNoteCode2('3-2', this.categoryName + '：' + this.rowTitle(), '●●・●●・●●・●●\n●●：●●～●●：●●', weekAndTime))
      }

      // 他、業務あり
      if (target[10].value == '1') {
        noteCode.push(this.setNoteCode('3-5', ''))
        this.selectedDetails.conf['approx'] = '0'
      }
      
      // トイレ清掃の特記事項
      if (Number(target[3].value) > 0) {
        noteCode.push(this.setNoteCode('100-1', 'トイレ清掃：' + target[3].value + '箇所想定'))
      }

      await this.getEstimateNote(noteCode)

      // 特記事項置換
      // 年末年始対応のみ
      let newYearNote = this.setNoteForDailyCleaningOrMgt()
      if (newYearNote && newYearNote != 'all') {
        this.selectedDetails.noteHTML = this.selectedDetails.noteHTML.replace('12月30日～1月3日', newYearNote)
      } else if (newYearNote == 'all') {
        if (target[16].value == '0') {
          this.selectedDetails.noteHTML = this.selectedDetails.noteHTML.replace('、12月30日～1月3日', '')
        } else {
          this.selectedDetails.noteHTML = this.selectedDetails.noteHTML.replace('12月30日～1月3日は休務とさせて頂きます。', '')
        }
      }
      
      // ㎡数不明の際の特記事項追加（2024/5/7不要とのご指示）
      // this.noteForMeterUncertain()

      // *--その他の単価を取得--* //

      // エリア
      this.selectedDetails.conf['eria'] = this.getUnitPrice(tip['エリア'], '3')
      // トイレ単価
      this.selectedDetails.conf['toilet'] = this.getUnitPrice('toilet', '0')
      // バス代単価
      this.selectedDetails.conf['bus'] = this.getUnitPrice('bus' + target[9].value, '0')
      // 早朝割増
      this.selectedDetails.conf['early'] = this.getUnitPrice('early' + target[22].value, '0')

      // 長さ
      this.selectedDetails.conf['wide'] = '0'
      // 概算
      this.selectedDetails.price['approx'] = '0'
      conf['meter'] = null
      conf['msg'] = null

      // 単独発注の場合
      if (target[10].value == '0') {
        // ㎡数が不明
        if (target[11].value == '4') {
          
          // タイプのツールチップ生成
          this.selectedDetails.toolTip[`${target[12].name}`] = target[12].itemCode[target[12].value]
          // 単価取得ボタンを押してもらうために、変数に入れる（式に即時反映すると、計算されていると勘違いされる）
          conf['house'] = Number(target[13].value)
          conf['floor'] = Number(target[14].value)
          conf['num'] = Number(target[15].value)

          // 長さ
          this.selectedDetails.conf['wide'] = this.getUnitPrice(tip['タイプ'], '3')
          
          // 概算を出す
          // 戸数 × 長さ × 幅
          let value1 = BigNumber(Number(conf['house'])).times(Number(this.selectedDetails.conf['wide'])).times(1.5)
          // 階数 × 本数 × 長さ × 幅
          let value2 = BigNumber(Number(conf['floor'])).times(Number(conf['num'])).times(10).times(1.2)

          // 定期概算 ＝ value1 + value2 + 戸数
          let un1 =  BigNumber(value1).plus(value2).plus(Number(conf['house']))
          this.selectedDetails.price['approxPre'] = String(Math.floor(un1))

          // どの式にするか判定
          this.setApproxform(conf, un1)
        } else {
          conf['meter'] = '0'
          conf['msg'] = null
        }
        
        // ㎡数、もしくは概算結果が200・300㎡以下
        if (target[11].value == '1' || target[11].value == '2' || conf['meter'] == '1' || conf['meter'] == '2') {
          let m = '300㎡以下'
          if (target[11].value == '1' || conf['meter'] == '1') {
            m = '200㎡以下'
          }
          this.selectedDetails.price['approx'] = this.getUnitPrice(m, '3')
        
        // ㎡数が300㎡数超え
        } else if (target[11].value == '3') {
          this.selectedDetails.price['approx'] = target[19].value

        // 概算結果が300㎡超え
        } else if (conf['meter'] == '3') {
          // 概算をそのまま使用
          this.selectedDetails.price['approx'] = this.selectedDetails.price['approxPre']
        }
      }

      // 祝日時間
      // 祝日対応なしは0を設定
      if (holi == '0') {
        this.selectedDetails.conf['holidayHours'] = '0'
      
      // それ以外は単価マスタから取得
      } else {
        let hTimesList = this.$store.getters.estimateItemPriceList.filter((l) => {
          if (l.parentCode == 'holidayTimes' && l.week == times && l.hours == this.selectedDetails.conf['hourType']) {
            return true
          }
        })
        if (this.setAlert(hTimesList, '祝日時間を取得できませんでした。\n単価マスタを確認してください。')) return
        this.selectedDetails.conf['holidayHours'] = hTimesList[0].value
      }

      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + target[17].value, '0')
      
      // 竣工係数
      this.getFinishPointConf()

      // 協力費
      this.getCompe()

      // 他の項目によって値を変更

      // 時間指定なし
      if (target[4].value == '0') {
        target[5].value = '0'
        // チップ消す
        delete tip['清掃時間(指定あり)']
      } else if (target[4].value == '1') {
        target[6].value = '0'
        delete tip['清掃時間(指定なし)']
      }
      
      // 単価取得ボタン押下直後フラグ（ここでももう1回）
      this.priceMasterBtnOn()

      // 計算
      this.calc()
    },

    /**
     * 概算結果により、どの式を利用するか判定
     * @param conf 結果値を収納
     * @param num 概算値
     */
    setApproxform(conf, num) {
      // どの式にするか判定
      if (num <= 200) {
        conf['meter'] = '1'
        conf['msg'] = '概算結果より200㎡以下の単価を使用'
      } else if (num <= 300) {
        conf['meter'] = '2'
        conf['msg'] = '概算結果より300㎡以下の単価を使用'
      } else {
        conf['meter'] = '3'
        if (['5', '28'].includes(this.categoryCode)) {
          conf['msg'] = '概算結果より300㎡超えの計算式を使用'
        } else {
          conf['msg'] = null
        }
      }
    },

    /**
     * ㎡数不明の場合、特記事項の下に追加
     */
    async noteForMeterUncertain() {
      // ㎡数が不明の場合
      if (this.uncertain) {
        await this.addEstimateNote('5-3')
      }
    },

    /**
     * 定期・スポット清掃_単価取得
     */
    async getFixedCleaning() {
      let target = this.displaySubItems
      let conf = this.selectedDetails.conf
      // 特記事項コード
      let noteCode = []
      this.selectedDetails.conf['parking'] = '0'
      this.selectedDetails.conf['generator'] = '0'

      // 現在の数量2の値を取得
      let q2 = this.selectedDetails.display[0].qty2
      let u2 = this.selectedDetails.display[0].unitName2
      
      // 清掃回数判定 6回より多い場合は6回と同じ
      let times = Number(target[2].value)
      if (times > 6) {
        times = '6'
      }

      // 竣工係数取得（この後使用するため、ここで取得）
      this.getFinishPointConf()
      // 協力費
      this.getCompe()

      // ㎡数不明
      if (this.uncertain) {
        let tip = this.selectedDetails.toolTip
        // 単価取得ボタンを押してもらうために、変数に入れる（式に即時反映すると、計算されていると勘違いされる）
        conf['house'] = Number(target[4].value)
        conf['floor'] = Number(target[8].value)
        conf['num'] = Number(target[9].value)
        // 長さ
        conf['wide'] = this.getUnitPrice(tip['タイプ'], '3')
        // 戸数 × 長さ × 幅
        let un1 = BigNumber(conf['house']).times(Number(conf['wide'])).times(1.5)
        // 階数 × 本数 × 長さ × 幅
        let un2 = BigNumber(conf['floor']).times(conf['num']).times(10).times(1.2)      
        // 定期概算 ＝ un1 + un2 + 戸数
        let un3 = Math.floor(BigNumber(un1).plus(un2).plus(conf['house']))
        this.selectedDetails.price['approx'] = String(un3)

        // どの式にするか判定
        this.setApproxform(conf, un3)
      } else {
        conf['meter'] = '0'
        conf['msg'] = null
        this.selectedDetails.price['approx'] = '0'
      }

      // 300㎡超えの場合
      if (this.exceed300 || conf['meter'] == '3') {

        // 定期清掃_300㎡超え 基本単価 コード生成
        let code = target[0].value + '-' + times + '-exceed300'

        if (times == 5) {
          alert('5回/年の単価はデータにありませんので、下の表に直接ご記入ください。')
          this.selectedDetails.unitPrice = ''
        } else {
          // 単価表から単価を取得
          let p = this.$store.getters.estimateItemPriceList.find((l) => {
            if (l.parentCode == '5' && l.priceCode == code) {
              return true
            }
          })
          if (p && p.price) {
            this.selectedDetails.unitPrice = p.price
          } else {
            this.selectedDetails.unitPrice = ''
          }
        }

        this.selectedDetails.conf['kindCoef'] = this.getConstantsData('kindCoef', 'kindCoef', 'FIXED_EXCEED300')

        // 特記事項
        noteCode.push(this.setNoteCode('5-2', ''))
        
        // 1行しかない場合は行を増やす
        if (!this.selectedDetails.display || !this.selectedDetails.display.length || this.selectedDetails.display.length == 1) {
          let addNum = 4 - this.selectedDetails.display.length
          for (let i = 0; i < addNum; i++) {
            this.addRowDetail()
          }
        }

        // this.setDisplayItem({name: this.selectedDetails.displayFixed[0].name, qty: this.selectedDetails.displayFixed[0].qty, unitName: this.selectedDetails.displayFixed[0].unitName}, '')

      // 200㎡以下、300㎡以下の場合
      } else {
        // 行を全て削除
        this.selectedDetails.display.splice(0)
        this.addRowDetail()

        let meter = target[1].value
        // 不明の場合
        if (this.uncertain) {
          meter = conf['meter']
        }

        // 単価コード生成 （エリア-㎡数-回数-f）
        let priceCode = target[0].value + '-' + meter + '-' + times + '-f'

        if (times == 5) {
          alert('5回/年の単価はデータにありませんので、下の表に直接ご記入ください。')

          this.setDisplayItem({name: '床面機械洗浄', qty: '1', unitName: '式'}, '')

        } else {
        
          // 単価表から単価を取得
          let list = this.$store.getters.estimateItemPriceList.filter((l) => {
            if (l.parentCode == '5' && l.priceCode == priceCode) {
              return true
            }
          })
          if (this.setAlert(list, '単価取得できませんでした。\n組み合わせを確認してください。')) return

          // 単価
          this.selectedDetails.unitPrice = list[0].price

          this.setDisplayItem({name: list[0].name, qty: list[0].qty, unitName: list[0].unitName}, '')
          
          // 特記事項
          let text = ''
          if (this.selectedDetails.displayFixed.length == 1 && this.selectedDetails.displayFixed[0].name == '') {
            text = 'アプローチ・エントランス・共用廊下・共用階段・管理室・エレベーター'
          } else {
            let arr = []
            this.selectedDetails.displayFixed.forEach((d) => {
              if (d.name != '') {
                arr.push(d.name)
              }
            })
            text = arr.join('・')
          }

          if (this.categoryCode == '5') {
            noteCode.push(this.setNoteCode2(list[0].noteCode, '', '●●',  text))
          } else if (this.categoryCode == '28') {
            noteCode.push(this.setNoteCode2(list[0].noteCode, '', '●●', text))
          }
        }
      }
      // 数量2に元の値を入れる
      this.selectedDetails.display[0].qty2 = q2
      this.selectedDetails.display[0].unitName2 = u2

      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + target[6].value, '0')

      // 駐車場代
      if (target[3].value == '1') {
        // 単価コードを生成（エリア-作業時間）
        let parkCode = target[0].value + '-' + target[5].value
        let parkList = this.$store.getters.estimateItemPriceList.filter((p) => {
          if (p.parentCode == '5' && p.priceCode == parkCode) {
            return true
          }
        })
        let value = parkList[0]

        // 駐車場代
        this.selectedDetails.conf['parking'] = value.price
        
        let fixedType = ''
        // 定期清掃の場合のみ
        if (this.categoryCode == '5') {
          fixedType = 'modalTab' + this.changeModalTab[this.getActiveTab()].id
        }

        if (this.exceed300 || conf['meter'] == '3') {
          let parkFlag = false

          // 既に「駐車場代」が表示されていればそこに上書き
          for (let i = 0; i < this.selectedDetails.display.length; i++) {
            if (this.selectedDetails.display[i].name == value.name) {
              this.selectedDetails.display[i].name = value.name
              this.selectedDetails.display[i].qty = value.qty
              this.selectedDetails.display[i].unitName = value.unitName
              this.selectedDetails.display[i].price = ''
              this.selectedDetails.display[i].priceKind = ''
              this.selectedDetails.display[i].kindCoef = ''
              this.selectedDetails.display[i].type = fixedType
              parkFlag = true
              break
            }
          }
          // 初めて「駐車場代」を表示する場合は、一番最後に表示
          if (!parkFlag) {
            this.selectedDetails.display.push(
              {
                name: value.name,
                qty: value.qty,
                unitName: value.unitName,
                priceKind:  '',
                kindCoef:'',
                price: '',
                detailBgColor: false,
                type: fixedType,
                qty2: '',
                unitName2: 'ヶ月',
                price2: '',
                categoryBgColor: false
              }
            )
          }
        }

      } else {
        for (let i = 0; i < this.selectedDetails.display.length; i++) {
          // 行に駐車場代があれば削除
          if (this.selectedDetails.display[i].name == '燃料駐車場代') {
            this.selectedDetails.display.splice(i, 1)
          }
        }
      }

      // 発電機
      let generator = {}
      if (target[10] && Number(target[10].value) > 0) {
        generator = this.$store.getters.estimateItemPriceList.find((p) => {
          if (p.parentCode == '5' && p.priceCode == 'generator') {
            return true
          }
        })
        if (generator && generator.price) {
          this.selectedDetails.conf['generator'] = generator.price
        }
      }

      // 300㎡超えの場合
      if (this.exceed300 || conf['meter'] == '3') {
        // 発電機がある場合
        if (target[10] && Number(target[10].value) > 0) {
          let flg = false

          // 既に「発電機代」が表示されていればそこに上書き
          for (let i = 0; i < this.selectedDetails.display.length; i++) {
            if (this.selectedDetails.display[i].name == generator.name) {
              this.selectedDetails.display[i].name = generator.name
              this.selectedDetails.display[i].qty = target[10].value
              this.selectedDetails.display[i].unitName = generator.unitName
              this.selectedDetails.display[i].price = ''
              this.selectedDetails.display[i].priceKind = ''
              this.selectedDetails.display[i].kindCoef = ''
              this.selectedDetails.display[i].type = ''
              flg = true
              break
            }
          }
          // 初めて「発電機代」を表示する場合は、一番最後に表示
          if (!flg) {
            this.selectedDetails.display.push(
              {
                name: generator.name,
                qty: target[10].value,
                unitName: generator.unitName,
                priceKind:  '',
                kindCoef:'',
                price: '',
                detailBgColor: false,
                qty2: '',
                unitName2: 'ヶ月',
                price2: '',
                type: '',
                categoryBgColor: false
              }
            )
          }
        } else {
          for (let i = 0; i < this.selectedDetails.display.length; i++) {
            // 行に発電機代があれば削除
            if (this.selectedDetails.display[i].name.indexOf('発電機') > -1) {
              this.selectedDetails.display.splice(i, 1)
            }
          }
        }
      }

      // 駐車場代がない（敷地内に停める場合）の特記事項
      if (target[3].value == '0') {
        noteCode.push(this.setNoteCode('5-6', ''))
      }

      // 現地調査の無し、かつ、㎡数が不明以外
      if (target[12] && target[12].value == '0' && target[1].value != '4') {
        noteCode.push(this.setNoteCode('5-5', ''))
      }

      // 特記事項取得
      await this.getEstimateNote(noteCode)

      // ㎡数不明の際の特記事項追加
      await this.noteForMeterUncertain()
      // WAXの剥離作業の特記事項
      this.noteForWax()
      // 発電機の特記事項
      if (target[10] && Number(target[10].value) > 0) {
        this.selectedDetails.noteHTML = this.selectedDetails.noteHTML.replace('<p>共用部の電源が使用出来ない場合、発電機使用料として追加費用がかかります。</p>', '')
      }

      // 作業月に関しての特記事項（最後に追加）
      // 最後に記載した方がいい文言
      // 12回未満の場合のみ
      if (target[2] && target[2].value && Number(target[2].value) < 12) {
        let note = await estimateManager.getEstimateNoteText(this, '5-8')
        if (note) {
          note = `<p>${note.replace('\n', '<br>')}</p>`
          this.selectedDetails.noteHTML += note
        }
      }
      // 数量2に「清掃回数」を入れる
      this.selectedDetails.display[0].qty2 = target[2].value

      this.calc()
      // 数量2が変更されている可能性もあるので、月額などを再計算する必要あり
      this.changeQty2()
    },

    /**
     * 定期清掃の部位を取得
     */
    getFixedRange(e, i) {
      let valueArr = []
      let value = ''

      if (this.selectedDetails.noteHTML == '') {
        return
      }

      let list = JSON.parse(JSON.stringify( this.selectedDetails.displayFixed))
      if (e) {
        list[i].name = e.name
      }
      valueArr = list.map((d) => {
        return d.name
      }).filter((f) => {
        if (f && f != '') {
          return f
        }
      })

      if (!valueArr.length) {
        value = 'アプローチ・エントランス・共用廊下・共用階段・管理室・エレベーター'
      } else {
        value = valueArr.join('・')
      }

      let text = ''
      let a = ''
      a = this.selectedDetails.noteHTML.slice(0, this.selectedDetails.noteHTML.indexOf('設定させて頂きます。')+10)
      text = a + '<br><br>'+ value + 'の洗浄を実施致します。<br>状況によって作業内容が変わる事がございます。<br>汚れ具合によっては汚れが落ち切らない場合があります。'

      // 駐車場代がない（敷地内に停める場合）の特記事項
      if (this.displaySubItems[3].value == '0') {
        text = text + '<br><br>敷地内に作業車両を停めさせていただく想定となります。'
      }

      this.selectedDetails.noteHTML = text
    },

    /**
     * 定期清掃_計算
     */
    calcFixedCleaning() {
      let disp = this.selectedDetails.display
      let target = this.displaySubItems
      let sd = this.selectedDetails
      let spot = 1.2
      // 定期清掃の場合は、スポット係数はなし
      if (this.categoryCode == '5') {
        spot = 1
      }

      let isExceed = false
      if (this.uncertain && this.selectedDetails.conf['meter'] == '3') {
        isExceed = true
      }

      let v1 = 0

      // 300㎡超え
      if ((this.exceed300 || isExceed) && Object.keys(this.selectedDetails.conf.kindCoef).length) {
        // 合計金額 ＝ 単価 ÷ 協力費 × オーナー契約 × スポット係数
        v1 = BigNumber(Number(this.selectedDetails.unitPrice)).div(Number(this.selectedDetails.conf['compe'])).times(Number(this.selectedDetails.conf['owner'])).times(Number(spot))
        // さらに竣工係数をかける
        // 定期清掃の場合のみ
        if (this.categoryCode == '5') {
          v1 = Math.floor(BigNumber(v1).times(Number(this.selectedDetails.conf['finishPoint'] || 1)))
        }
        // 切りよく5円単位にする
        v1 = this.neatlyPrice5(String(v1))
        this.totalUnitPrice = String(v1)

        for (let i = 0; i < disp.length; i++) {
          // 基本単価に初期値設定
          if (disp[i].name.indexOf('駐車場代') == -1 && disp[i].name.indexOf('発電機') == -1 && disp[i].name.indexOf('調整費') == -1) {
            disp[i].priceKind = this.totalUnitPrice
          }

          if (disp[i].priceKind == '') {
            continue
          }

          let kind = '1'

          if (disp[i].kindCoef != '') {
            kind = disp[i].kindCoef.substring(disp[i].kindCoef.indexOf('×')+1, disp[i].kindCoef.indexOf(')'))
          }
          
          if (disp[i].detailBgColor) {
            continue
          }
          
          // 単価、もしくはオーナー契約、竣工係数が手動で変更された場合、セル背景を黄色にする
          if (this.selectedDetails.unitPriceBgColor || this.selectedDetails.conf['ownerBgColor'] || this.selectedDetails.conf['finishPointBgColor'] || this.selectedDetails.conf['compeBgColor']) {
            disp[i].priceKindBgColor = true
          } else {
            disp[i].priceKindBgColor = false
          }

          // 最終単価 ＝ 基本単価 × 項目による係数
          let v2 = BigNumber(Number(disp[i].priceKind)).times(Number(kind))
          disp[i].price = String(Math.floor(v2))
        }

        // 駐車場代 ＝ 駐車場代単価 × 竣工 ÷ 協力費 × オーナー
        let val = Math.floor(BigNumber(Number(sd.conf['parking'])).times(Number(sd.conf['finishPoint'])).div(Number(sd.conf['compe'])).times(Number(sd.conf['owner'])))
        // 切りよくするために500円単位にする
        val = this.neatlyPrice500(String(val))
        sd.price['parking'] = String(val)
        
        // 発電機代 ＝ 発電機代単価 × 竣工 ÷ 協力費 × オーナー
        let val2 = Math.floor(BigNumber(Number(sd.conf['generator'])).times(Number(sd.conf['finishPoint'])).div(Number(sd.conf['compe'])).times(Number(sd.conf['owner'])))
        // 切りよくするために500円単位にする
        val2 = this.neatlyPrice500(String(val2))
        sd.price['generator'] = String(val2)

        // 駐車場代がある場合、単価を表示
        if (target[3].value == '1') {
          this.setParkingOrGenerator('燃料駐車場代', 'parking')
        }
        // 発電機代がある場合、単価を表示
        if (target[10] && target[10].value != '0') {
          this.setParkingOrGenerator('発電機', 'generator')
        }

      } else if (!this.exceed300 && !isExceed) {
        // 発電機
        let generator = 0
        let generatorNum = 0
        if (this.selectedDetails.conf['generator']) {
          generator = Number(this.selectedDetails.conf['generator'])
        }
        if (target[10] && target[10].value) {
          generatorNum = Number(target[10].value)
        }
        generator = generator * generatorNum
        this.selectedDetails.price['generator'] = String(generator)

        // 合計単価 ＝ 単価 × スポット係数 ＋ 発電機 ＋ 駐車場
        v1 = BigNumber(Number(this.selectedDetails.unitPrice)).times(spot).plus(generator).plus(Number(this.selectedDetails.conf['parking']))
        v1 = Math.floor(v1)
        this.selectedDetails.price['totalUnitPricePre'] = String(v1)

        // さらに竣工係数
        // 定期清掃の場合のみ
        if (this.categoryCode == '5') {
          v1 = BigNumber(v1).times(Number(this.selectedDetails.conf['finishPoint'] || 1))
        }

        // さらに、÷ 協力費 × オーナー契約
        v1 = BigNumber(v1).div(Number(this.selectedDetails.conf['compe'] || 1)).times(Number(this.selectedDetails.conf['owner']))
        
        // 10円単位を切上げて100円単位にする
        v1 = Math.ceil(BigNumber(v1).div(100)) * 100
        this.totalUnitPrice = String(v1)
        this.categoryCalc()
      }
    },
    
    /**
     * 切りよく5円単位にする
     * @param val 対象数字
     */
    neatlyPrice5(val) {
      if (val && val != '0') {
        let v = val.slice(-1)
        // 下1桁が5より大きい場合
        if (v > 5) {
          // 10の位に繰り上げ
          return Math.ceil(Number(val) / 10) * 10
        } else if (v != '0') {
          // 下1桁を5にする
          return Number(val.slice(0, -1) + '5')
        }
      }
      return val
    },

    /**
     * 切りよく500円単位にする
     * @param val 対象数字
     */
    neatlyPrice500(val) {
      if (val && val != '0') {
        let v = val.slice(-3)
        // 下3桁が500より大きい場合
        if (v > 500) {
          // 1000の位に繰り上げ
          return Math.ceil(Number(val) / 1000) * 1000
        } else if (v != '000') {
          // 下3桁を500にする
          return Number(val.slice(0, -3) + '500')
        }
      }
      return val
    },

    /**
     * 駐車場代、発電機代を行にセット
     */
    setParkingOrGenerator(name, tar) {
      const sd = this.selectedDetails
      // 300㎡越えの場合
      if (this.exceed300 || sd.conf['meter'] == '3') {  
        for (let i = 0; i < this.selectedDetails.display.length; i++) {
          const det = this.selectedDetails.display[i]
          // 対象行に値段を入れる
          if (det.name.indexOf(name) > -1) {
            if (!det.detailBgColor && !det.categoryBgColor) {
              det.price = sd.price[tar]
            }
            break
          }
        }
      }
    },

    /**
     * 項目による係数変更イベント
     */
    changeKindCoef() {
      this.calcFixedCleaning()
      this.openInit = false
      this.categoryCalc()
      this.noteForWax()
    },

    /**
     * フロント・ゴミサポ 単価取得
     */
    async getFrontOrTrash() {
      // 特記事項
      let noteCode = []

      let house = Number(this.displaySubItems[0].value)
      if (house > 50) {
        house = 50
      }
      // 単価コードを生成(タイプ-作業回数)
      let priceCode = this.setType(1) + '-' + this.displaySubItems[2].value
      // フロントサポートプラスは、作業回数➡週1固定
      if (this.categoryCode == '7') {
        priceCode = this.setType(1) + '-1'
      }
      // 単価表から単価を取得
      let list = this.$store.getters.estimateItemPriceList.filter((l) => {
        if (l.parentCode == this.categoryCode && l.priceCode == priceCode && Number(l.minHouseholds) <= house && Number(l.maxHouseholds) >= house) {
          return true
        }
      })

      if (Number(this.displaySubItems[0].value) > 50) {
        alert('51戸以上はデータに単価がありません。下の表に直接ご記入ください。')
      // } else {
      //   if (this.setAlert(list, '単価取得できませんでした。\n組み合わせを確認してください。'))
      }

      let price = ''
      // 51戸以上は価格を空文字
      if (Number(this.displaySubItems[0].value) > 50 || !list || !list.length) {
        price = ''
      } else {
        price = Number(list[0].price)
      }

      // 単価
      this.selectedDetails.unitPrice =  String(Math.floor(price))

      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + this.displaySubItems[3].value, '0')

      // 竣工係数
      this.getFinishPointConf()
      // 協力費
      this.getCompe()
  
      // 特記事項
      if (list && list[0]) {
        noteCode.push(this.setNoteCode(list[0].noteCode, ''))
        this.setDisplayItem(list[0], '')
      } else {
        noteCode.push(this.setNoteCode('8-1', ''))
      }

      // バイク駐車 不明
      if (this.displaySubItems[4].value == '0') {
        noteCode.push(this.setNoteCode('99-2', ''))
      
      // バイク駐車 あり
      } else {
        noteCode.push(this.setNoteCode('99-1', ''))
      }

      // 特記事項取得
      this.getEstimateNote(noteCode)
      
      this.calc()

      // ラウンドトラッシュの場合
      if (this.categoryCode == '8') {
        // 回数が週1～3.5までの場合
        if (Number(this.displaySubItems[2].value) < 4) {
          // 計算結果の行を見つける
          for (let i = 0; i < this.selectedDetails.display.length; i++) {
            const disp = this.selectedDetails.display[i]
            if (disp.type == 'calc') {
              // セルを黄色にする
              disp.trashTimesBgColor = true
              break
            }
          }
          // console.log(this.selectedDetails.display)
          await dialogs.showConfirmDialog('作業回数が週3.5回以下', '作業回数が週3.5回以下の場合は\r\n承認申請が必要なため単価が黄色になります。')
        }
      }
    },

    /**
     * オーナー契約、竣工係数の計算のみ
     */
    calcOwner() {
      let v1 = 0
      // 緊急対応以外
      if (this.categoryCode != '26') {
        // 金額 ＝ 単価 × 竣工係数
        v1 = BigNumber(Number(this.selectedDetails.unitPrice)).times(Number(this.selectedDetails.conf['finishPoint'] || 1))
      } else {
        v1 = Number(this.selectedDetails.unitPrice)
      }
      // 金額 ＝ 金額 ÷ 協力費 × オーナー契約 
      v1 = BigNumber(v1).div(Number(this.selectedDetails.conf['compe'] || 1)).times(Number(this.selectedDetails.conf['owner']))

      // フロント・フロントプラス・トラッシュ・クオリティーレポートは10円単位切上で100円までの単位にする
      if (['6', '7', '8', '9'].includes(this.categoryCode)) {
        v1 = Math.ceil(BigNumber(v1).div(100)) * 100
      }
      this.totalUnitPrice = String(Math.floor(v1))
      // console.log(this.selectedDetails.display)
    },

    /**
     * 計算式の詳細を開く、閉じる
     */
    formulaDispClick() {
      this.formulaDispFlag = !this.formulaDispFlag
    },

    /**
     * ガラス清掃 単価取得
     */
    getGlassCleaning() {
      let target = this.displaySubItems
      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + target[0].value, '0')
      // 竣工係数
      this.getFinishPointConf()
      // 協力費
      this.getCompe()

      let list = {qty: '1', unitName: '回', name: 'ガラス清掃', noteCode: '10-1'}
      // 特記事項取得
      this.getEstimateNote([this.setNoteCode(list.noteCode, '')])

      this.setDisplayItem(list, '')
      this.calc()
    },

    /**
     * 排水管清掃 単価取得
     */
    getDrainageCleaning() {
      let target = this.displaySubItems
      let house = Number(target[0].value)

      // 単価コード（作業日-施工日数）
      let priceCode = target[1].value + '-' + target[2].value
      
      // 単価取得
      let list = this.$store.getters.estimateItemPriceList.filter((l) => {
        if (l.parentCode == this.categoryCode && l.priceCode == priceCode && Number(l.minHouseholds) <= house && Number(l.maxHouseholds) >= house) {
          return true
        }
      })
      if (this.setAlert(list, '単価取得できませんでした。\n単価マスタを確認してください。')) return

      let price = Number(list[0].price)
      // 6戸以上
      if (house >= 6) {

        // 1戸ごとの追加料金取得
        let addList = this.$store.getters.estimateItemPriceList.filter((l) => {
          if (l.parentCode == this.categoryCode && l.priceCode == priceCode + '-add' && Number(l.minHouseholds) <= house && Number(l.maxHouseholds) >= house) {
            return true
          }
        })

        let add = Number(addList[0].price)

        // 単日の場合
        if (target[2].value == '1') {
          // 10戸以下
          if (house <= 10) {
            // 金額 ＝ 最低金額 + （戸数 - 5） × 1戸ごとの追加料金
            price = price + (house - 5) * add

          // 11戸以上
          } else {
            // 金額 ＝ 10戸の金額 + （戸数 - 10） × 1戸ごとの追加料金
            price = price + (house - 10) * add
          }

        // 複数日の場合
        } else {
          // 金額 ＝ 最低金額 + （戸数 - 5） × 1戸ごとの追加料金
          price = price + (house - 5) * add
        }
      }
      // 単価
      this.selectedDetails.unitPrice = String(price)
      // 施工回数による係数
      this.selectedDetails.conf['drainage'] = this.getUnitPrice('drainage' + target[3].value, this.categoryCode)

      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + target[4].value, '0')

      // 竣工係数
      this.getFinishPointConf()
      // 協力費
      this.getCompe()

      // 特記事項取得
      this.getEstimateNote([this.setNoteCode(list[0].noteCode, '')])
      this.setDisplayItem(list[0], '')
      this.calc()
    },

    /**
     * 排水管清掃
     */
    calcDrainageCleaning() {
      // 金額 ＝ 単価 × 施工回数 × 竣工係数 ÷ 協力費 ×  オーナー契約 （10円単位切り上げ）
      let value = BigNumber(Number(this.selectedDetails.unitPrice)).times(Number(this.selectedDetails.conf['drainage'])).times(Number(this.selectedDetails.conf['finishPoint'] || 1)).div(Number(this.selectedDetails.conf['compe'] || 1)).times(Number(this.selectedDetails.conf['owner']))
      this.totalUnitPrice = String(Math.ceil(BigNumber(value).div(100)) * 100)
    },

    /**
     * 貯水槽清掃 単価取得
     */
    async getStorageCleaning() {
      let capa = Number(this.displaySubItems[0].value)
      this.selectedDetails.conf['over10Price'] = '0'
      this.selectedDetails.conf['over10Capa'] = '0'

      // 3t未満は3t単価を使用
      if (capa < 3) {
        capa = 3
      }
      
      // 11t以上は10ｔ単価+（2000×10ｔ超えた分）
      if (capa >= 11) {
        this.selectedDetails.conf['over10Price'] = this.getUnitPrice('over10', this.categoryCode)
        this.selectedDetails.conf['over10Capa'] = String(capa - 10)
        capa = 10
      }

      // 単価取得
      let list = this.$store.getters.estimateItemPriceList.filter((l) => {
        if (l.parentCode == this.categoryCode && l.priceCode == String(capa)) {
          return true
        }
      })

      // 駐車場代
      if (this.displaySubItems[2].value == '1') {
        // 単価コードを生成（発注先-エリア）
        let parkCode = ''
        // ニッツ
        if (this.displaySubItems[1].value == '1') {
          parkCode = '1-' + this.displaySubItems[3].value
        // ベスパー
        } else if (this.displaySubItems[1].value == '2') {
          parkCode = '2-' + this.displaySubItems[4].value
        }
        let parkList = this.$store.getters.estimateItemPriceList.filter((l) => {
          if (l.parentCode == this.categoryCode && l.priceCode == parkCode) {
            return true
          }
        })
        this.selectedDetails.conf['parking'] = parkList[0].price
      } else {
        this.selectedDetails.conf['parking'] = '0'
      }

      // 2槽式
      this.selectedDetails.conf['twoTank'] = this.getUnitPrice('twoTank' + this.displaySubItems[5].value, this.categoryCode)
      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + this.displaySubItems[6].value, '0')
      // 竣工係数
      this.getFinishPointConf()
      // 協力費
      this.getCompe()

      if (this.setAlert(list, '単価取得できませんでした。\n単価マスタを確認してください。')) {
        return
      } else {
        // 単価
        let p = Number(list[0].price)
        let p2 = p + (Number(this.selectedDetails.conf['over10Price']) * Number(this.selectedDetails.conf['over10Capa']))
        this.selectedDetails.unitPrice = String(p2)
      }
      
      // 特記事項取得
      await this.getEstimateNote([this.setNoteCode(list[0].noteCode, '')])
      this.setDisplayItem(list[0], '')
      this.calc()
    },

    /**
     * 貯水槽清掃 計算
     */
    calcStorageCleaning() {
      let conf = this.selectedDetails.conf
      // 発注額 ＝ （単価 + ポンプ点検 + 水質検査 ） × 竣工係数
      let v1 = Number(this.selectedDetails.unitPrice) + Number(conf.pump || 0) + Number(conf.inspection || 0) 
      this.selectedDetails.price['purchasePre'] = String(BigNumber(v1).times(Number(conf['finishPoint'] || 1)))

      // 発注額 ＋ 交通費
      let v2 = Number(this.selectedDetails.price['purchasePre']) + Number(conf.parking || 0)
      // さらに × 2槽式係数
      let v3 = Math.floor(BigNumber(v2).times(Number(conf.twoTank || 1)))
      // 合計発注額
      this.selectedDetails.totalUnitPricePre = String(v3)

      // 金額 ＝ 合計発注額 ÷ 0.7 ÷ 協力費 × オーナー係数
      let v4 = BigNumber(Number(this.selectedDetails.totalUnitPricePre)).div(Number(conf['compe'] || 1)).div(0.7).times(Number(conf.owner || 1))
      this.totalUnitPrice = String(Math.ceil(BigNumber(v4).div(1000)) * 1000)
    },

    /**
     * 単価がなく、オーナー契約・竣工係数のみ取得
     */
    async getOwner() {
      let target = this.displaySubItems
      let ownerIdx = 0

      let list = {qty: '1', unitName: '', name: this.categoryName}

      // 給排水、簡易
      if (['21', '22'].includes(this.categoryCode)) {
        list = {qty: '1', unitName: '回', name: this.categoryName}
        // 単価取得
        this.selectedDetails.unitPrice = this.$store.getters.estimateItemPriceList.find((l) => {
          return l.parentCode == this.categoryCode
        }).price

      // 植栽
      } else if (['13'].includes(this.categoryCode)) {
        this.selectedDetails.getPriceFlag = true
        list = {qty: '1', unitName: '式', name: this.categoryName}

      // 緊急対応
      } else if (['26'].includes(this.categoryCode)) {
        this.selectedDetails.getPriceFlag = true
        list.name = '緊急対応費'
        list.unitName = '式'

      // 防火対象物点検
      } else if (['27'].includes(this.categoryCode)) {
        this.selectedDetails.getPriceFlag = true
        await this.getEstimateNote([this.setNoteCode('27-1', '')])
        list.unitName = '式'

      // カテゴリーコードなし
      } else if (!this.categoryCode) {
        this.selectedDetails.getPriceFlag = true
        list.unitName = 'ヶ月'
        ownerIdx = 1
      }

      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + target[ownerIdx].value, '0')
      // 竣工係数
      this.getFinishPointConf()
      // 協力費
      this.getCompe()

      this.setDisplayItem(list, '')
      this.calc()
      // 植栽の特記事項
      this.getPlantingNote(null, null)
    },

    /**
     * 植栽剪定の特記事項に項目名をセット
     * @param e 選択した項目
     * @param j インデックス
     */
    async getPlantingNote(e, j) {
      if (this.categoryCode == '13' && this.selectedDetails.getPriceFlag) {
        // 表の内容を取得
        let arr = []

        let list = JSON.parse(JSON.stringify( this.selectedDetails.display))
        if (e) {
          list[j].name = e.name
        }
        list.forEach((d) => {
          let nameArr = []
          nameArr = d.name.split('・')
          for (let i = 0; i < nameArr.length; i++) {
            const n = nameArr[i];
            if (!arr.includes(n) && n.indexOf('調整費') == -1) {
              arr.push(n)
            }
          }
        })
        // 特記事項取得
        if (this.selectedDetails.noteHTML == '') {
          await this.getEstimateNote([this.setNoteCode2('13-1', '', '建物周りの植栽の●●', '建物周りの植栽の' + arr.join('・'))])
        }
        
        // 置換
        let index = 0
        let index2 = 0
        index =  this.selectedDetails.noteHTML.indexOf('建物周り')
        index2 = this.selectedDetails.noteHTML.indexOf('実施月は')

        let t = ''
        let t2 = ''
        if (index > 0 && index2 > 0) {
          t = this.selectedDetails.noteHTML.slice(0, index)
          t2 = this.selectedDetails.noteHTML.slice(index2)
        } else {
          this.selectedDetails.noteHTML = ''
          await this.getEstimateNote([this.setNoteCode2('13-1', '', '建物周りの植栽の●●', '建物周りの植栽の' + arr.join('・'))])
          return
        }

        this.selectedDetails.noteHTML = t + '建物周りの植栽の' + arr.join('・') + 'を実施致します。<br>' + t2
      }
    },

    /**
     * 発注額から 計算
     */
    calcOrder() {
      if (this.selectedDetails.unitPrice == '' || isNaN(this.selectedDetails.unitPrice)) {
        this.totalUnitPrice = ''
        this.selectedDetails.price['purchasePre'] = ''
        return
      }

      // 発注額 ＝ 発注単価 × 竣工係数
      let v1 = BigNumber(Number(this.selectedDetails.unitPrice)).times(Number(this.selectedDetails.conf['finishPoint'] || 1))
      v1 = Math.floor(v1)
      this.selectedDetails.price['purchasePre'] = String(v1)

      // 金額 ＝ 発注額 ÷ 0.7 ÷ 協力費 × オーナー契約
      let v2 = BigNumber(v1).div(0.7).div(Number(this.selectedDetails.conf['compe'] || 1)).times(Number(this.selectedDetails.conf.owner))

      // ガラス、植栽の場合、切上
      if (['10', '13'].includes(this.categoryCode)) {
        // 100円単位を切上げて1000円にする
        v2 = Math.ceil(BigNumber(v2).div(1000)) * 1000
        this.totalUnitPrice = String(v2)
      } else {
        this.totalUnitPrice = String(Math.floor(v2))
      }
    },

    /**
     * 発注額から 計算
     * 建築設備点検-特定建築物定期検査は2024年9月から式が変更
     */
    calcOrder2() {
      if (this.selectedDetails.conf['unitPurchasePrice'] == '' || isNaN(this.selectedDetails.conf['unitPurchasePrice'])) {
        this.totalUnitPrice = ''
        this.selectedDetails.price['purchasePre'] = ''
        return
      }

      // 発注額 ＝ 発注単価 × 竣工係数
      let v1 = BigNumber(Number(this.selectedDetails.conf['unitPurchasePrice'])).times(Number(this.selectedDetails.conf['finishPoint'] || 1))
      v1 = Math.floor(v1)
      this.selectedDetails.price['purchasePre'] = String(v1)

      // 金額 ＝ 発注額 ÷ 0.7 ÷ 協力費 × オーナー契約
      let v2 = BigNumber(v1).div(0.7).div(Number(this.selectedDetails.conf['compe'] || 1)).times(Number(this.selectedDetails.conf.owner))

      // 100円単位を切上げて1000円にする
      v2 = Math.ceil(BigNumber(v2).div(1000)) * 1000
      this.totalUnitPrice = String(v2)
    },

    /**
     * 特別清掃
     */
    getSp() {
      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + this.selectedDetails['spOwner'], '0')
      // 竣工係数
      this.getFinishPointConf()
      // 協力費
      this.getCompe()

      const conf = this.selectedDetails.conf
      this.setCoeffToTable(conf.owner, conf.finishPoint, conf.compe)
      this.calc()
    },

    /**
     * 消防点検 単価取得
     */
    async getFirefighting() {
      let target = this.displaySubItems
      // 戸数
      let house = Number(target[4].value)
      // 特記事項
      let noteCode = ''
      // 消火器数
      let capaE = Number(target[8].value)
      // スプリンクラー数
      let capaS = Number(target[9].value)
      // 31戸以上
      let houseOver30 = false

      if (house > 30) {
        alert('31戸以上の単価はありません。')
        houseOver30 = true
      }

      let priceCode = target[5].value
      if (priceCode == '3') {
        priceCode = '2'
      }
      if (houseOver30) {
        house = 30
      }

      let list
      // 消火器・避難器具のみの場合
      if (target[2].value == '1') {
        if (target[5].value == '1') {
          alert('「消火器・避難器具のみ」の場合「シングル」単価はありません。\r\n「ファミリー」単価で取得します。')
        }
        // 単価取得
        list = this.$store.getters.estimateItemPriceList.find((l) => {
          if (l.parentCode == this.categoryCode && l.priceCode == '2' && l.min == '') {
            return true
          }
        })
      } else {
        // 単価取得
        list = this.$store.getters.estimateItemPriceList.find((l) => {
          if (l.parentCode == this.categoryCode && l.priceCode == priceCode && Number(l.min) <= house && l.max >= house) {
            return true
          }
        })
      }

      if (list) {
        // 発注単価
        let price = list.price
        if (houseOver30) {
          price = '0'
        }
        this.selectedDetails.unitPrice = price
          
        // 特記事項取得
        await this.getEstimateNote([this.setNoteCode(noteCode, '')])
        
        this.setDisplayItem({name: list.name, qty: list.qty, unitName: list.unitName}, '')
      } else {
        this.setAlert(list, '単価取得できませんでした。\n単価マスタを確認してください。')
        return
      }

      // 消火器
      if (capaE) {
        let ex = this.$store.getters.estimateItemPriceList.find((l) => {
            if (l.parentCode == this.categoryCode && l.priceCode == 'extinguisher') {
              return true
            }
          })
          this.selectedDetails.conf['extinguisher'] = String(ex.price)
        if (20 < capaE) {
          let overE = capaE - 20
          // 追加料金
          this.selectedDetails.conf['extinguisher'] = String((Number(this.getUnitPrice('extinguisherOver20', this.categoryCode)) * overE) + Number(this.selectedDetails.conf['extinguisher']))
        }
      } else {
        this.selectedDetails.conf['extinguisher'] = '0'
      }

      // スプリンクラー
      if (capaS) {
        let sp = this.$store.getters.estimateItemPriceList.find((l) => {
          if (l.parentCode == this.categoryCode && l.priceCode == 'sprinkler') {
            return true
          }
        })
        this.selectedDetails.conf['sprinkler'] = String(sp.price)
        if (20 < capaS) {
          let overS = capaS - 20
          this.selectedDetails.conf['sprinkler'] = String((Number(this.getUnitPrice('sprinklerOver20', this.categoryCode)) * overS) + Number(this.selectedDetails.conf['sprinkler']))
        }
      } else {
        this.selectedDetails.conf['sprinkler'] = '0'
      }

      // 駐車場代
      if (target[0].value == '1' && target[1].value == '1') {
        let parking = this.$store.getters.estimateItemPriceList.find((l) => {
          if (l.parentCode == this.categoryCode && l.priceCode == 'parking') {
            return true
          }
        })
        this.selectedDetails.conf['parking'] = parking.price
      } else {
        this.selectedDetails.conf['parking'] = '0'
      }

      // エリア
      this.selectedDetails.conf['eria'] = this.getUnitPrice('eria' + target[0].value, this.categoryCode)

      // 消火器のみの場合
      if (target[2].value == '1') {
        // 減額
        this.selectedDetails.conf['reduction'] = this.getUnitPrice('reduction' + target[3].value, this.categoryCode)
        // 消火栓
        this.selectedDetails.conf['extinguisher'] = '0'
        // スプリンクラー
        this.selectedDetails.conf['sprinkler'] = '0'
      } else {
        // 減額
        this.selectedDetails.conf['reduction'] = '0'
      }

      // 土日祝対応
      const holidayWork = target[6].value
      let holidayWorkMsg = '平日'
      if (holidayWork == '1') {
        holidayWorkMsg = '土日祝日1日を含む、平日'
      } else if (holidayWork == '2') {
        holidayWorkMsg = '土日祝日'
      }

      // 再点検
      this.selectedDetails.conf['recheck'] = this.getUnitPrice('recheck' + target[7].value, this.categoryCode)

      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + target[10].value, '0')

      // 竣工係数
      this.getFinishPointConf()

      // 協力費
      this.getCompe()

      // 再点検によって特記事項が変わる
      if (target[7].value == '0') {
        noteCode = '15-2'
      } else {
        noteCode = '15-1'
      }
      await this.getEstimateNote([this.setNoteCode2(noteCode, '', '●●●', holidayWorkMsg)])
      this.calc()
    },

    /**
     * 消防点検 計算
     */
    calcFirefighting() {
      let target = this.displaySubItems
      let conf = this.selectedDetails.conf
      let qty = this.selectedDetails.display[0].qty2
      let cPrice = this.selectedDetails.price
      
      // 手動で変更されていない場合＆回数がある場合
      if (!conf['qty1BgColor']) {
        // 土日あり回数
        if (target[6].value == '0') {
          conf['qty1'] = '0'
        // 土日1回
        } else if (target[6].value == '1') {
          conf['qty1'] = '1'
        } else {
          conf['qty1'] = qty
        }
      }
      if (!conf['qtyBgColor']) {
        // 合計回数
        conf['qty'] = qty
      }
      if (!conf['qty0BgColor'] && conf['qty1'] != null && conf['qty1'] != undefined && qty != null && qty != undefined) {
        // 土日なし回数
        conf['qty0'] = String(Number(qty) - Number(conf['qty1']))
      }

      // 発注額 土日あり ＝ 単価 × 再点検 × 1.2 × 回数 土日あり
      cPrice['purchasePre1'] = String(BigNumber(BigNumber(Number(this.selectedDetails.unitPrice || 0)).times(Number(conf.recheck || 0))).times(Number(1.2)).times(Number(conf['qty1'])))
      // 発注額 土日なし ＝ 単価 × 再点検 × 1 × 回数 土日なし
      cPrice['purchasePre0'] = String(BigNumber(BigNumber(Number(this.selectedDetails.unitPrice || 0)).times(Number(conf.recheck || 0))).times(Number(conf['qty0'])))
      // 発注単価 ＝ （発注額 土日あり + 発注額 土日なし）÷ 合計回数
      let v1 = BigNumber(Number(cPrice['purchasePre1'])).plus(Number(cPrice['purchasePre0']))
      if (conf['qty'] && conf['qty'] != '0') {
        cPrice['purchasePre2'] = String(Math.floor(BigNumber(v1).div(Number(conf['qty']))))
      } else {
        cPrice['purchasePre2'] = '0'
      }
      // 発注単価 ＝ 発注単価 + 駐車場代 + エリア加算 - 減額
      cPrice['purchasePre3'] = String(Math.floor(BigNumber(cPrice['purchasePre2']).plus(Number(conf['parking'] || 0)).plus(Number(conf['eria'] || 0)).minus(Number(conf['reduction']))))
      // 合計発注額 ＝ （発注単価 ＋ 消火栓 ＋ スプリンクラー） × 竣工係数
      let v2 = Number(cPrice['purchasePre3']) + Number(conf['extinguisher']) + Number(conf['sprinkler'])
      let v3 = Math.floor(BigNumber(v2).times(conf['finishPoint'] || 1))
      this.selectedDetails.totalUnitPricePre = String(v3)
      // 受注金額 ＝ 発注金額 ÷ 0.7 ÷ 協力費 × オーナー契約
      let v4 =  Math.floor(BigNumber(v3).div(0.7).div(conf['compe'] || 1).times(conf['owner']))
      // 100円単位を切上げして、1000円単位にする
      v4 = Math.ceil(BigNumber(v4).div(1000)) * 1000

      this.totalUnitPrice = String(v4)
    },

    /**
     * 建築設備点検 単価取得
     */
    async getArchitecture() {
      delete this.selectedDetails.conf['unitPurchasePrice']

      let priceCode = 'architecture' + '-' + this.displaySubItems[0].value
      // 単価取得
      let list = this.$store.getters.estimateItemPriceList.find((l) => {
        if (l.parentCode == this.categoryCode && l.priceCode == priceCode) {
          return true
        }
      })

      // 発注額未定の場合
      if (this.displaySubItems[0].value != '3') {
        // 単価
        this.selectedDetails.unitPrice = list.price
      } else {
        this.getPriceMasterBtn = false
      }
      
      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + this.displaySubItems[1].value, '0')
      // 竣工係数
      this.getFinishPointConf()
      // 協力費
      this.getCompe()

      // 特記事項取得
      await this.getEstimateNote([this.setNoteCode(list.noteCode, '')])
      this.setDisplayItem(list, '')
      this.calc()
    },

    
    /**
     * 特定建築物定期調査 単価取得
     */
    async getSpArchitecture() {
      delete this.selectedDetails.conf['unitPurchasePrice']

      let priceCode = 'spArchitecture' + '-' + this.displaySubItems[0].value
      // 単価取得
      let list = this.$store.getters.estimateItemPriceList.find((l) => {
        if (l.parentCode == this.categoryCode && l.priceCode == priceCode) {
          return true
        }
      })

      // 発注額未定の場合
      if (this.displaySubItems[0].value != '3') {
        // 単価
        this.selectedDetails.unitPrice = list.price
      } else {
        this.getPriceMasterBtn = false
      }

      // 特記事項取得
      await this.getEstimateNote([this.setNoteCode(list.noteCode, '')])

      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + this.displaySubItems[1].value, '0')
      // 竣工係数
      this.getFinishPointConf()
      // 協力費
      this.getCompe()

      this.setDisplayItem(list, '')
      this.calc()
    },

    /**
     * 共用部設備点検 単価取得
     */
    async getCommonArea() {
      let target = this.displaySubItems
      
      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + this.displaySubItems[2].value, '0')
      // 竣工係数
      this.getFinishPointConf()
      // 協力費
      this.getCompe()

      let priceCode = ''
      priceCode = target[0].value + '-' + target[1].value
      // 単価取得
      let list = this.$store.getters.estimateItemPriceList.find((l) => {
        if (l.parentCode == this.categoryCode && l.priceCode == priceCode) {
          return true
        }
      })

      if (this.setAlert(list, '単価取得できませんでした。\n単価マスタを確認してください。')) {
        return
      }

      this.setDisplayItem({name: list.name, qty: list.qty, unitName: list.unitName}, '')

      // 特記事項取得
      await this.getEstimateNote([this.setNoteCode('18-1', '')])
      // 単価
      this.selectedDetails.unitPrice = list.price
      this.calc()
    },

    /**
     * 共用部設備点検 計算
     */
    calcCommonArea() {
      let conf = this.selectedDetails.conf
      // 金額 ＝ 単価 × 竣工係数 ÷ 協力費 × オーナー契約
      let v1 = BigNumber(Number(this.selectedDetails.unitPrice || 0)).times(Number(conf['finishPoint'] || 1)).div(Number(this.selectedDetails.conf['compe'] || 1)).times(Number(conf.owner || 0))
      this.totalUnitPrice = String(Math.floor(v1))
    },

    /**
     * エレベーター点検 単価取得
     */
    async getEv() {
      let priceCode = this.displaySubItems[0].value + '-' + this.displaySubItems[1].value
      // 単価取得
      let list = this.$store.getters.estimateItemPriceList.find((l) => {
        if (l.parentCode == this.categoryCode && l.priceCode == priceCode) {
          return true
        }
      })      
      // 単価(発注金額)
      this.selectedDetails.unitPrice = list.price
      
      // 特記事項取得
      let n1 = 'POG契約。'
      if (this.displaySubItems[0].value == '2') {
        n1 = 'FM契約。'
      }
      let n2 = '年4回'
      if (this.displaySubItems[1].value == '12') {
        n2 = '年12回'
      }
      let n3 = n1 + n2
      await this.getEstimateNote([this.setNoteCode2(list.noteCode, '', 'POG契約/FM契約。年4回', n3)])
      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + this.displaySubItems[2].value, '0')
      // 竣工係数
      this.getFinishPointConf()
      // 協力費
      this.getCompe()

      this.setDisplayItem(list, '')
      this.calc()
    },

    /**
     * EV 計算
     */
    calcEV() {
      if (this.selectedDetails.unitPrice == '' || isNaN(this.selectedDetails.unitPrice)) {
        this.totalUnitPrice = ''
        return
      }
      // 合計発注額 ＝ （発注単価 × 竣工係数)
      let v1 = Math.floor(BigNumber(Number(this.selectedDetails.unitPrice)).times(Number(this.selectedDetails.conf['finishPoint'] || 1)))
      this.selectedDetails.totalUnitPricePre = String(v1)
      // 受注単価 ＝ 合計発注額 ÷ 0.8 ÷ 協力費 × オーナー契約
      let v2 = BigNumber(v1).div(0.8).times(Number(this.selectedDetails.conf.owner)).div(Number(this.selectedDetails.conf['compe'] || 1))
      // 10円単位切り上げ
      this.totalUnitPrice = String(Math.ceil(BigNumber(v2).div(100)) * 100)
    },

    /**
     * 増圧ポンプ 単価取得
     */
    async getBooster() {
      let priceCode = ''
      // 単価取得
      let list = this.$store.getters.estimateItemPriceList.find((l) => {
        if (l.parentCode == this.categoryCode && l.priceCode == priceCode) {
          return true
        }
      })
      
      if (this.setAlert(list, '単価取得できませんでした。\n単価マスタを確認してください。')) {
        return
      } else {
        // 単価
        this.selectedDetails.unitPrice = list.price
      }
      
      // 特記事項取得
      await this.getEstimateNote([this.setNoteCode(list.noteCode, '')])

      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + this.displaySubItems[0].value, '0')
      // 竣工係数
      this.getFinishPointConf()
      // 協力費
      this.getCompe()

      // 駐車場代
      if (this.displaySubItems[2].value == '1') {
        // 単価コードを生成
        let parkCode = 'parking-' + this.displaySubItems[1].value
        let parkList = this.$store.getters.estimateItemPriceList.filter((p) => {
          if (p.parentCode == this.categoryCode && p.priceCode == parkCode) {
            return true
          }
        })
        if (this.setAlert(parkList, '駐車場代を取得できませんでした。\n単価マスタを確認してください。')) {
          return
        } else {
          // 単価
          this.selectedDetails.conf['parking'] = parkList[0].price
        }
        
      } else {
        this.selectedDetails.conf['parking'] = '0'
      }

      this.setDisplayItem(list, '')
      this.calc()
    },

    /**
     * 増圧 計算
     */
    calcBooster() {
      if (this.selectedDetails.unitPrice == '' || isNaN(this.selectedDetails.unitPrice)) {
        this.totalUnitPrice = ''
        return
      }
      // 合計発注額 ＝ （単価  + 駐車場代) × 竣工係数
      let v1 = BigNumber(Number(this.selectedDetails.unitPrice || 0)).plus(Number(this.selectedDetails.conf['parking'] || 0))
      this.selectedDetails.totalUnitPricePre = String(Math.floor(BigNumber(v1).times(Number(this.selectedDetails.conf['finishPoint'] || 1))))
      // 受注単価 ＝ 合計発注額 ÷ 0.7 ÷ 協力費 × オーナー契約
      let v2 = BigNumber(Number(this.selectedDetails.totalUnitPricePre)).div(0.7).times(Number(this.selectedDetails.conf.owner || 0)).div(Number(this.selectedDetails.conf['compe'] || 1))
      // 10円単位繰り上げ
      v2 = Math.ceil(BigNumber(v2).div(100)) * 100
      this.totalUnitPrice = String(v2)
    },

    /**
     * 管球交換
     */
    async getBulb() {
      const target = this.displaySubItems

      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + target[1].value, '0')
      // 協力費
      this.getCompe()

      const conf = this.selectedDetails.conf
      this.setCoeffToTable(conf.owner, '1', conf.compe)

      // 対応費の行を調整
      this.setResponseRow(target[0].value, constants.responseCost)
    },

    /**
     * 対応費の行をセット
     * @param value 対応費有無（0：なし、1：あり）
     * @param price 対応費
     */
    setResponseRow(value, price) {
      let No = null
      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        if (this.selectedDetails.display[i].name == '対応費') {
          No = i
          break
        }
      }
      // 対応費なし
      if (value == '0') {
        if (No || No == 0) {
          this.selectedDetails.display.splice(No, 1)
        }
      
      // 対応費あり
      } else if (value == '1') {
        // 行がない場合
        if (!No && No != 0) {
          let row = {name: '対応費', qty: '1', unitName: '式', priceKind: String(price), price: '', detailBgColor: false, qty2: '', unitName2: 'ヶ月', price2: '', categoryBgColor: false, type: ''}
          // オーナー契約
          row.owner = this.selectedDetails.conf['owner'] || '1'
          // 協力費
          row.compe = this.selectedDetails.conf['compe'] || '1'

          if (this.categoryCode == '23') {
            row.kind = ''
            row.kindCode = ''
          }
          this.selectedDetails.display.push(row)

        // 行が既にある場合、金額を変える
        } else {
          this.selectedDetails.display[No].priceKind = String(price)
        }
      }
      this.calc()
    },

    /**
     * 粗大ごみ・物品販売
     */
    getTrashOrSale() {
      const target = this.displaySubItems

      // オーナー契約
      this.selectedDetails.conf['owner'] = this.getUnitPrice('owner' + target[1].value, '0')
      // 協力費
      this.getCompe()
      // 対応費の行をセット
      this.setResponseRow(target[0].value, constants.responseCost)

      const conf = this.selectedDetails.conf
      this.setCoeffToTable(conf.owner, '1', conf.compe)
      this.calc()
    },

    /**
     * MRS 単価取得
     */
    async getMRS() {
      let basic = {}
      // 単価取得
      let list = this.$store.getters.estimateItemPriceList.filter((l) => {
        if (l.parentCode == this.categoryCode) {
          return true
        }
      })
      
      if (this.setAlert(list, '単価取得できませんでした。\n単価マスタを確認してください。')) {
        return
      } else {
        list.forEach((l) => {
          if (l.priceCode == '1') {
            // 単価
            this.selectedDetails.unitPrice = l.price
            basic = l
          } else {
            this.selectedDetails.conf[l.priceCode] = l.price
          }
        })
      }

      // 特記事項
      let noteCode = []
      // ゴミ搬出なしの場合
      if (this.displaySubItems[2].value == '0') {
        noteCode.push(this.setNoteCode2(basic.noteCode, '', '祝日はゴミ搬出のみ対応致します。', ''))
      } else {
        noteCode.push(this.setNoteCode(basic.noteCode, ''))
      }

      // バイク駐車 不明
      if (this.displaySubItems[3].value == '0') {
        noteCode.push(this.setNoteCode('99-2', ''))
      
      // バイク駐車 あり
      } else {
        noteCode.push(this.setNoteCode('99-1', ''))
      }

      // 特記事項取得
      await this.getEstimateNote(noteCode)

      // 内容
      let name = ''
      // ゴミ搬出
      let trash = `（週${this.displaySubItems[2].value}回ゴミ搬出）`
      if (this.displaySubItems[2].value == '0') {
        trash = '（ゴミ搬出なし）'
      }
      // 清掃回数が週3の場合
      if (this.displaySubItems[1].value == '3') {
        name = `週${this.displaySubItems[1].value}回巡回清掃・週1回巡回${trash}`
      } else {
        name = `週${this.displaySubItems[1].value}回巡回清掃${trash}`
      }
      basic.name = name
      
      // 協力費
      this.getCompe()

      this.setDisplayItem(basic, '')
      this.calc()
    },

    /**
     * MRS計算
     */
    calcMRS() {
      if (!this.selectedDetails.unitPrice || isNaN(this.selectedDetails.unitPrice)) {
        this.totalUnitPrice = ''
        return
      }

      // 40戸超過戸数
      this.selectedDetails.conf['over40'] = '0'
      if (this.displaySubItems[0].value && !isNaN(this.displaySubItems[0].value)) {
        const num = Number(this.displaySubItems[0].value) - 40
        if (num > 0) {
          this.selectedDetails.conf['over40'] = String(num)
        }
      }

      // 清掃回数_週3超過分
      let cleaning = Number(this.displaySubItems[1].value) - 3
      this.selectedDetails.conf['cleaningOver3'] = String(cleaning)

      // ゴミ搬出追加回数
      this.selectedDetails.conf['garbageOver4'] = ''
      // 週4まで、加算無し
      if (this.displaySubItems[2].value && Number(this.displaySubItems[2].value) <= 4) {
        this.selectedDetails.conf['garbageOver4'] = '0'
      // 週4.5以上の場合、追加分を出す
      } else if (this.displaySubItems[2].value) {
        let garbageOver4 = BigNumber(Number(this.displaySubItems[2].value)).minus(4)
        this.selectedDetails.conf['garbageOver4'] = String(garbageOver4)
      }

      // 追加戸数分 ＝ 40戸超過分 × 戸数単価
      let v1 = Number(this.selectedDetails.conf['over40']) * Number(this.selectedDetails.conf['households'])
      v1 = Math.floor(v1)
      this.selectedDetails.price['over40'] = String(v1)

      // オプション料 ＝ （清掃回数_週3超過分 × 清掃単価）＋ ゴミ搬出単価
      // 追加清掃
      let seiso = BigNumber(Number(this.selectedDetails.conf['cleaningOver3'])).times(Number(this.selectedDetails.conf['cleaning']))
      // 追加ゴミ
      let gomi = BigNumber(Number(this.selectedDetails.conf['garbageOver4'])).times(Number(this.selectedDetails.conf['garbage']))
      // 追加清掃 + ゴミ
      let v2 = BigNumber(seiso).plus(gomi)
      v2 = Math.floor(v2)
      this.selectedDetails.price['add'] = String(v2)

      // 単価合算 ＝ 単価 + 追加戸数分 + オプション料
      let v3 = Number(this.selectedDetails.unitPrice) + v1 + v2
      // ÷ 協力費
      v3 = BigNumber(v3).div(Number(this.selectedDetails.conf['compe'] || 1))
      // 切り上げ
      v3 = Math.ceil(v3)
      this.totalUnitPrice = String(v3)
    },

    /**
     * 表にオーナー係数などをセット
     * @param owner オーナー係数 
     * @param finishPoint 竣工係数
     * @param compe 協力費
     */
    setCoeffToTable(owner, finishPoint, compe) {
      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        const disp = this.selectedDetails.display[i]
        // オーナー係数の背景色をリセット
        delete disp.ownerBgColor
        // 竣工係数の背景色をリセット
        delete disp.finishPointBgColor
        // 協力費の背景色をリセット
        delete disp.compeBgColor
        // 単価の背景色をリセット
        delete disp.detailBgColor

        if (disp.name.indexOf('調整費') == -1) {
          // オーナー係数を入れる
          disp.owner = owner
          // 協力費を入れる
          disp.compe = compe

          // 特別清掃のみ
          if (this.categoryCode == '14') {
            // 竣工係数を入れる
            disp.finishPoint = finishPoint
          }
        }
      }
    },

    /**
     * 表のオーナー係数などを計算
     */
    calcCoeffToTable() {
      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        const disp = this.selectedDetails.display[i]
        // 単価が手動で変更している場合は何もしない
        if (disp.detailBgColor) {
          continue
        } else {
          // 基本単価が入力されている場合
          if (disp.priceKind) {
            // オーナー係数、協力費（管球・ゴミ・物販で竣工係数はない）
            let v1 = BigNumber(Number(disp.priceKind)).times(Number(disp.owner || 1)).div(Number(disp.compe || 1))

            // 特別清掃のみ、さらに竣工係数
            if (this.categoryCode == '14') {
              v1 = BigNumber(v1).times(Number(disp.finishPoint) || 1)
            }
            v1 = Math.floor(v1)
            disp.price = String(v1)
          }
        }
        this.forBind ++
      }
    },

    /**
     * 表にデータを反映、特記事項をセット
     * @param list 取得データ
     * @param price 金額
     * 
     */
    setDisplayItem(list, price) {
      let isCalc = false

      let typeName = this.setTypeName()

      for (let i = 0; i < this.selectedDetails.display.length; i++) {

        // 計算結果表示用の行に記入
        if (this.selectedDetails.display[i].type == typeName) {
          isCalc = true

          // 数量 初期値 既に入力されていなければ
          if (this.selectedDetails.display[i].qty == '') {
            this.selectedDetails.display[i].qty = list.qty
          }
          
          // 単位 既に親画面で入力されていなければ
          if (this.selectedDetails.display[i].unitName == '' || this.selectedDetails.display[i].unitName == 'ヶ月') {
            this.selectedDetails.display[i].unitName = list.unitName
          }

          // 定期清掃の場合は、初期値の「㎡」ならば上書き
          if (['5', '28'].includes(this.categoryCode) && (this.selectedDetails.display[i].unitName == '' || this.selectedDetails.display[i].unitName == '㎡')) {
            this.selectedDetails.display[i].unitName = list.unitName
          }
          
          // 表示内容
          this.selectedDetails.display[i].name = list.name

          // 排水管清掃のみ単位判定
          this.getDrainageUnitName(i)

          // 単価を手動で変更していなければ、反映（単価取得ボタン押下時は、手動変更がリセットされる）
          if (this.selectedDetails.display[i].detailBgColor) {
            return
          }
          if (price == '') {
            this.selectedDetails.display[i].price = ''
          } else {
            this.selectedDetails.display[i].price = String(Math.floor(price))
          }
          return
        }
      }
      if (!isCalc && this.selectedDetails.display[0].name == '' && this.selectedDetails.display[0].price == '') {
        this.selectedDetails.display[0].type = typeName
        // 数量 初期値
        this.selectedDetails.display[0].qty = list.qty
        // 単位
        this.selectedDetails.display[0].unitName = list.unitName
        // 表示内容
        this.selectedDetails.display[0].name = list.name
        // 排水管清掃のみ単位2
        this.getDrainageUnitName(0)
        // 価格
        if (price == '') {
          this.selectedDetails.display[0].price = ''
        } else {
          this.selectedDetails.display[0].price = String(Math.floor(price))
        }
      } else {
        this.addRowDetail()
        let i = this.selectedDetails.display.length - 1
        this.selectedDetails.display[i].type = typeName
        // 数量 初期値
        this.selectedDetails.display[i].qty = list.qty
        // 単位
        this.selectedDetails.display[i].unitName = list.unitName
        // 表示内容
        this.selectedDetails.display[i].name = list.name
        // 排水管清掃のみ単位2
        this.getDrainageUnitName(i)
        // 価格
        if (price == '') {
          this.selectedDetails.display[i].price = ''
        } else {
          this.selectedDetails.display[i].price = String(Math.floor(price))
        }
      }
    },

    /**
     * 排水管清掃の単位を設定
     */
    getDrainageUnitName(i) {
      // 排水管清掃の場合、回数によって単位を変更
      if (this.categoryCode == '11') {
        let un = this.displaySubItems[3].value

        // スポットの場合、式、回に変更
        if (un == '4') {
          this.selectedDetails.display[i].unitName = '式'
          this.selectedDetails.display[i].unitName2 = '回'
        } else {
          if (un == '1') {
            un = ''
          }
          un = '回/' + un + '年'
          this.selectedDetails.display[i].unitName = '回'
          this.selectedDetails.display[i].unitName2 = un
        }
      }
    },

    /**
     * タイプ名を判定
     */
    setTypeName() {
      let typeName = 'calc'

      // 日常、管理、定期
      if (['3', '4', '5'].includes(this.categoryCode)) {
        let id = ''
        id = this.changeModalTab[this.getActiveTab()].id
        typeName = 'modalTab' + id
      }

      return typeName
    },

    /**
     * 管球交換 種類選択イベント
     * @param event 選択内容
     * @param index インデックス
     */
    selectedKind(event, index) {
      let target = this.selectedDetails.display[index]
      if (event) {
        target.kindCode = event.code
      }
    },

    /**
     * 管球交換 種類変更イベント
     * @param event 選択内容
     * @param index インデックス
     */
    changeKind(index) {
      this.openInit = false
      let target = this.selectedDetails.display[index]
      if (target.kind == '') {
        target.kindCode = ''
      }
    },

    /**
     * 特別清掃、管球交換 項目名選択イベント
     * @param event 選択内容
     * @param index インデックス
     */
    async selectedName(event, index) {
      this.openInit = false
      // 特別清掃
      if (this.categoryCode == '14') {
        await this.selectedSpecial(event, index)
      // 管球対応
      } else {
        if (!this.displaySubItems || !this.displaySubItems[1] || !this.displaySubItems[1].value) {
          await dialogs.showErrorDialog('計算不可', 'オーナー契約 が選択されていません。')
          return
        }
        let target = this.selectedDetails.display[index]
        // 数量
        target.qty = event.qty
        // 単位
        target.unitName = event.unitName
        // 単価反映
        target.priceKind = event.price
        this.calcCoeffToTable()
      }
    },

    /**
     * 特別清掃 項目名選択イベント
     * @param event 選択内容
     * @param index インデックス
     */
    async selectedSpecial(event, index) {
      // if (this.selectedDetails['spOwner'] == '') {
      //   await dialogs.showErrorDialog('計算不可', 'オーナー契約 が選択されていません。')
      //   return
      // }
      // if (this.selectedDetails['spFinishPoint'] == '') {
      //   await dialogs.showErrorDialog('計算不可', '竣工（●年後）が入力されていません\r\n「1」以上で割増になります。')
      //   return
      // }
      let priceCode = ''
      let number = null
      let target = {}
      // 「内容」選択時
      if (event) {
        priceCode = event.item
        number = Number(event.order)
        target = event

      // 編集アイコンクリック時
      } else {
        number = Number(this.selectedDetails.display[index].number)
        priceCode = this.displaySubItems[number].item
        target = this.displaySubItems[number]
      }

      if (target.selectValue != "") {
        this.specialShow = true
        let spModalValue = ''
        let house = this.buildingInfo.households
        let type = this.buildingInfo.residenceType
        let lightingUnit = ''
        let lightingBgColor = false
        let lightingTab = false

        if (this.selectedDetails.display[index].spModalValue) {
          spModalValue = this.selectedDetails.display[index].spModalValue
        }
        if (this.selectedDetails.display[index].house) {
          house = this.selectedDetails.display[index].house
        }
        if (this.selectedDetails.display[index].type && this.selectedDetails.display[index].type != 'calc') {
          type = this.selectedDetails.display[index].type
        }
        if (this.selectedDetails.display[index].lightingUnit) {
          lightingUnit = this.selectedDetails.display[index].lightingUnit
        }
        if (this.selectedDetails.display[index].lightingUnitBgColor) {
          lightingBgColor = this.selectedDetails.display[index].lightingUnitBgColor
        }
        if (this.selectedDetails.display[index].lightingTab) {
          lightingTab = this.selectedDetails.display[index].lightingTab
        }

        this.param = {
          select: target.itemCode,
          editData: spModalValue,
          name: target.name,
          households: house,
          residenceType: type,
          lightingUnit: lightingUnit,
          lightingBgColor: lightingBgColor,
          lightingTab: lightingTab,

          spCallback: async (decisionData) => {
            let row = {
              // 内容
              name: '',
              // 数量
              qty: '',
              // 単位
              unitName: 'ヶ月',
              // 基本単価
              priceKind: '',
              // オーナー係数
              owner: this.selectedDetails.conf['owner'],
              // 竣工係数
              finishPoint: this.selectedDetails.conf['finishPoint'],
              // 協力費
              compe: this.selectedDetails.conf['compe'],
              // 金額
              price: '',
              // 金額変更
              detailBgColor: false,
              // カテゴリー数量
              qty2: '',
              // カテゴリ－単位
              unitName2: '',
              // カテゴリー金額
              price2: '',
              // カテゴリー金額変更
              categoryBgColor: false,
              spModalValue: decisionData.selectedValue,
              number: number,
              house: '',
              type: '',
              lightingUnit: '',
              lightingBgColor: '',
              lightingTab: false
            }

            // 自転車置き場、ルーバー 以外
            if (target.item != 'bicycle' && target.item != 'louver') {

              priceCode += '-' + decisionData.selectedValue

            // 自転車置き場、ルーバー で追加料金発生時
            } else if (decisionData.selectedValue == '2') {
              // 単価表から単価を取得
              let list = this.$store.getters.estimateItemPriceList.find((l) => {
                if (l.parentCode == this.categoryCode && l.priceCode == target.item + 2) {
                  return true
                }
              })

              if (list && list.name) {
                row.name = list.name
                row.unitName = list.unitName
                row.priceKind = list.price
                row.unitName2 = list.uniitName2
              }
              
              this.selectedDetails.display.splice(index+1, 0, row)
            }
            
            // 単価表から単価を取得
            await this.setDisplayItemSpecial(priceCode, index, true, decisionData.selectedValue, number, decisionData.house, decisionData.type, decisionData.lightingUnit, decisionData.totalLightingPrice, decisionData.lightingUnitBgColor, decisionData.lightingTab)
            this.specialShow = false
          }
        }
        this.specialCount++

      // 特別清掃モーダル開く必要なし
      } else {
        // 単価表から単価を取得
        await this.setDisplayItemSpecial(priceCode, index, false, '', number, '', '', '', '', false, false)
      }
    },

    /**
     * 単価表からデータ取得、データを表に反映 表示名なし
     * @param priceCode 単価コード
     * @param index インデックス
     * @param spFlag アイコン表示フラグ
     * @param value モーダルで選択した値
     * @param number 編集時に使用する選択肢の番号
     * @param house 戸数（照明器具清掃業務）
     * @param type 居住タイプ（照明器具清掃業務）
     * @param lightingUnit 単価（照明器具清掃業務）
     * @param totalLightingPrice 最終金額（照明器具清掃業務）
     * @param lightingUnitBgColor 価格変更フラグ
     * @param lightingTab 照明 箇所数不明フラグ
     */
    async setDisplayItemSpecial(priceCode, index, spFlag, value, number, house, type, lightingUnit, totalLightingPrice, lightingUnitBgColor, lightingTab) {
      
      // 単価表から単価を取得
      let list = this.$store.getters.estimateItemPriceList.filter((l) => {
        if (l.parentCode == this.categoryCode && l.priceCode == priceCode) {
          return true
        }
      })

      if (this.setAlert(list, '単価取得できませんでした。\n単価マスタを確認してください。')) {
        console.log('特別清掃単価取得不可')
      }

      let price = ''
      if (list.length && list[0].price) {
        price = list[0].price
      }
      let name = this.selectedDetails.display[index].name
      if (list.length && list[0].name) {
        name = list[0].name
      }
      let qty = ''
      if (list.length && list[0].qty) {
        qty = list[0].qty
      }
      let unitName = ''
      if (list.length && list[0].unitName) {
        unitName = list[0].unitName
      }
      let unitName2 = ''
      if (list.length && list[0].unitName2) {
        unitName2 = list[0].unitName2
      }
      // 照明器具清掃業務の拭き上げの場合は、モーダルで出した金額を使用する
      if (number == 1)  {
        if (lightingTab) {
          unitName = '式'
        }
        price = totalLightingPrice
      }

      // 内容
      this.selectedDetails.display[index].name = name
      // 数量 初期値
      this.selectedDetails.display[index].qty = qty
      // 単位
      this.selectedDetails.display[index].unitName = unitName
      // 基本単価
      this.selectedDetails.display[index].priceKind = price
      // 単位2
      if (unitName2) {
        this.selectedDetails.display[index].unitName2 = unitName2
        // 「式」の場合、1式にする
        if (unitName2 == '式' && !this.selectedDetails.display[index].qty2) {
          this.selectedDetails.display[index].qty2 = '1'
        }
      }
      // アイコン表示フラグ
      this.selectedDetails.display[index].spFlag = spFlag
      // 定期清掃のモーダルデータ（編集時）
      this.selectedDetails.display[index].spModalValue = value
      // 定期清掃の選択した項目番号
      this.selectedDetails.display[index].number = number
      // 戸数
      this.selectedDetails.display[index].house = house
      // 居住タイプ
      this.selectedDetails.display[index].type = type
      // 照明器具清掃業務 単価
      this.selectedDetails.display[index].lightingUnit = lightingUnit
      // 照明器具清掃業務 手動変更フラグ
      this.selectedDetails.display[index].lightingUnitBgColor = lightingUnitBgColor
      // 照明器具清掃業務 箇所数不明フラグ
      this.selectedDetails.display[index].lightingTab = lightingTab
      
      if (lightingUnitBgColor) {
        this.selectedDetails.iconBgColor = true
      }
      
      // 駐輪場清掃で拭き上げありの場合
      if (priceCode == 'bicycle' && value == '2') {
        await this.getEstimateNote([this.setNoteCode2(list[0].noteCode, '', 'ラックの拭き上げは含んでおりません。', '')])
      } else {
        await this.getEstimateNote([this.setNoteCode(list[0].noteCode, '')])
      }
      this.calcCoeffToTable()
    },

    /**
     * 特別清掃 内容セルのCSS設定
     * @param flag 特別清掃のモーダル用アイコンがあるか
     */
    setWidth(flag) {
      if (flag) {
        return 'itemname-width'
      } else {
        return 'm-auto'
      }
    },

    /**
     * 祝日の清掃時間をセット
     * @param value 祝日対応
     */
    setHolidayHours(value) {
      // FULLの場合、清掃時間
      if (value == '1') {
        return this.cleaningHours
      // ゴミ出しのみの場合、1
      } else if (value == '2') {
        return '1'
      // 祝日対応なしの場合、0
      } else {
        return '0'
      }
    },

    /**
     * 単価表にない値を変更_changeイベントでは変更できないため
     */
    checkRadio(index) {
      let target = this.displaySubItems
      if (index == 1) {
        return
      }
      if (this.categoryCode == '1' && target[1].value != '1' && Number(target[0].value) <= 20 && target[2].value == 'm1') {
        alert('月1回の場合、単価は「シングル」単価しかありません。「シングル」にします。')
        this.setInitValue(target[1], 'シングル')
      }
      if (this.categoryCode == '1' && target[1].value != '1' && Number(target[0].value) <= 20 && target[2].value == 'm2') {
        alert('月2回の場合、単価は「シングル」単価しかありません。「シングル」にします。')
        this.setInitValue(target[1], 'シングル')
      }
    },

    /**
     * 日常清掃・管理員業務の値チェック
     */
    async checkNormalAndMgm() {
      if (this.categoryCode == '3' || this.categoryCode == '4') {
        
        let target = this.displaySubItems
        let tip = this.selectedDetails.toolTip

        // 時間指定なし
        if (target[4].value == '0') {
          this.setZeroValue(target[5])
          delete tip['清掃時間(指定あり)']

        // 時間指定あり
        } else if (target[4].value == '1') {
          this.setZeroValue(target[6])
          delete tip['清掃時間(指定なし)']
        }

        // 他業務あり
        if (target[10].value == '1') {

        // 単独＆㎡不明
        } else if (target[11].value == '4') {
          // タイプが選択されていない
          if (target[12].value == '0') {
            target[12].value = ''
          }
          let zero = []
          // 戸数が0のまま
          if (target[13].value == '0') {
            zero.push(target[13].name)
          }
          // 階数が0のまま
          if (target[14].value == '0') {
            zero.push(target[14].name)
          }
          // 本数が0のまま
          if (target[15].value == '0') {
            zero.push(target[15].name)
          }

          if (zero.length) {
            let dlogR = await dialogs.showConfirmDialog(zero.join('・'), `㎡数不明ですが、${zero.join('・')}が0です。\r\nよろしいですか？`)
            if (dlogR != 'YES') {
              return false
            }
          }
        // 300超えで300以上でない場合
        } else if (target[11].value == '3' && target[19] && target[19].value != '' && 300 > Number(target[19].value)) {
            let dlogR = await dialogs.showConfirmDialog('㎡数確認', '「300㎡超え」を選択していますが、300以下の値を記入しています。\r\nよろしいですか？')
            if (dlogR != 'YES') {
              return false
            }
        }
        // 週7なのに日曜を選択していない場合
        if (target[7].value == '7' && !this.isSunday) {
          let dlogR = await dialogs.showConfirmDialog('日曜割増計算', '週7日勤務ですが、「日曜」を選択していません。\r\nよろしいですか？')
          if (dlogR != 'YES') {
            return false
          }
        }

        let remarks = this.selectedDetails.remarks

        // 開始時間が7時以前の設定があるか
        let isEarly = false

        // 時間指定なしは曜日・時間入力不要
        // 受注で人数分の曜日・時間の配列が必要なので、ここではその配列を削除など敢えてしない
        if (target[4].value == '1') {

          // 時間指定あり
          let weekKind = []
          let sunDayFlg = false
          for (let i = 0; i < remarks.length; i++) {
            const r = remarks[i]

            // 早朝勤務がある場合
            if (r.start) {
              let startNum = r.start.replace(':', '')
              if (startNum) {
                if (Number(startNum) <= 700) {
                  isEarly = true
                }
              }
            }

            // 曜日の詳細の矛盾
            if (r.weekDetail && r.weekDetail.length) {
              for (let j = 0; j < r.weekDetail.length; j++) {
                const wd = r.weekDetail[j]
                const wn = r.week[j]
                if (wd.includes('毎週') || wd.includes('隔週')) {
                  if (wd.length > 1) {
                    await dialogs.showErrorDialog(`${i + 1}人目の${wn}曜日詳細が矛盾`, wn + '曜日に毎週もしくは隔週が含まれています。\r\n' + wd.join('と') + 'は矛盾しています。')
                    return false
                  }
                }
              }
            }

            // 日曜を毎週以外に設定している場合
            if (!sunDayFlg && r.week) {
              for (let wIdx = 0; wIdx < r.week.length; wIdx++) {
                const w = r.week[wIdx]
                if (w == '日') {
                  if (r.weekDetail && r.weekDetail[wIdx] && !r.weekDetail[wIdx].includes('毎週')) {
                    await dialogs.showInfoDialog('日曜割増計算は毎週で計算', `日曜が「${r.weekDetail[wIdx].join('・')}」ですが、計算は毎週で計算しています。\r\n変更する場合は手動で単価を変更してください。`)
                    sunDayFlg = true
                  }
                }
              }
            }

            // 清掃員1人の場合
            if (remarks.length == 1) {
              // 曜日を選択していない
              if (!r.week || !r.week.length) {
                await dialogs.showErrorDialog('曜日が未選択', '曜日を清掃回数に合うように選択してください。')
                return false
              }
              // 曜日の数が合わない
              if (r.week.length != Math.round(Number(target[7].value))) {
                let weekLength = await dialogs.showConfirmDialog('清掃回数と選択した曜日の確認', '週' + target[7].value + '回です。曜日は' + r.week.length + 'つ選択していますがよろしいですか？')
                if (weekLength != 'YES') {
                  return false
                }
              }
            
            // 清掃員複数の場合
            } else {
              // 曜日未入力
              if (i == 0 && !r.week.length) {
                await dialogs.showErrorDialog('1行目の曜日は必須です', '1行目の曜日が選択されていません。')
                return false
              } else {
                weekKind = weekKind.concat(r.week)
              }
            }

            // 時間
            if (r.start && r.start != '' && r.end && r.end != '') {
              let start = r.start.split(':')
              let end = r.end.split(':')
              if (start.length == 2 && end.length == 2 && start[0] != '' && start[1] != '' && end[0] != '' && end[1] != '') {
                let dif = 0
                let s = 0
                let e = 0
                s = (Number(start[0]) * 60) + Number(start[1])
                e = (Number(end[0]) * 60) + Number(end[1])
                // 時間帯から何分なのか取得（時間差分用）
                dif = e - s

                // 休憩時間が何分なのか取得
                let breakTime = 0
                if (r.breakHour && r.breakHour != '0') {
                  breakTime = Number(r.breakHour) * 60
                }
                if (r.breakMinutes && r.breakMinutes != '0') {
                  breakTime += Number(r.breakMinutes)
                }

                // 6時間以上の時間帯なのに、休憩時間が足りない場合
                if (dif >= 360) {
                  let rowNum = ''
                  if (remarks.length > 1) {
                    rowNum = (i + 1) + '行目の'
                  }
                  // 休憩時間0の場合
                  if (!breakTime) {
                    let dif2 = (dif - 60) / 60
                    let res = await dialogs.showConfirmDialog(rowNum + '休憩時間', rowNum + '入力した時間帯は実働6時間以上になっていますが、\r\n休憩時間が入っていません。\r\n休憩時間を1時間に設定しますか？\r\nその場合、実働時間は' + dif2 + '時間に変わります。')
                    if (res == 'YES') {
                      r.breakHour = '1'
                      breakTime = 60
                    } else {
                      return false
                    }

                  // 8時間未満の場合は最低45分
                  } else if (dif < 480) {
                    if (breakTime < 45) {
                      await dialogs.showErrorDialog(rowNum + '休憩時間', rowNum + '時間帯が6時間以上です。休憩時間を45分以上設定してください。')
                      return false
                    }
                  // 8時間以上
                  } else {
                    if (breakTime < 60) {
                      await dialogs.showErrorDialog(rowNum + '休憩時間', rowNum + '時間帯が8時間以上です。休憩時間を60分以上設定してください。')
                      return false
                    }
                  }
                }

                let cleaningMinutes = Number(this.cleaningHours) * 60
                // 休憩なしの時間（分）を取得（実働時間）
                dif = BigNumber(dif).minus(breakTime)

                // 清掃員1人の場合
                if (remarks.length == 1) {
                  if (dif < 0) {
                    await dialogs.showErrorDialog('時間確認', '時間帯が不正です。\r\n実働時間がマイナスになります。')
                    return false
                  }
                  if (cleaningMinutes != dif) {
                    let times = Math.floor((dif / 60) * 10000) / 10000
                    let timeString = String(times)
                    if (timeString && timeString.length > 4) {
                      timeString = timeString.substring(0, 4) + '...'
                    }
                    
                    let res = await dialogs.showWarningConfirmDialog('時間確認', '選択している「清掃時間」と「時間帯」の実働時間が一致しません。\r\nよろしいでしょうか？\r\n選択している清掃時間：' + this.cleaningHours + '\r\n時間帯：' + timeString + '時間（休憩含まず）')
                    if (res != 'YES') {
                      return false
                    }
                  }
                // 清掃員複数の場合
                } else {
                  if (dif < 0) {
                    await dialogs.showErrorDialog((i + 1) + '行目の時間確認', (i + 1) + '行目の時間帯が不正です。\r\n実働時間がマイナスになります。')
                    return false
                  }
                }
                
              } else {
                // １行目のみ未入力確認
                if (i == '0') {
                  await dialogs.showErrorDialog('入力確認', '時間帯を入力してください。')
                  return false
                }
              }
            } else {
              // 1行目のみ未入力確認
              if (i == '0') {
                await dialogs.showErrorDialog('入力確認', '時間帯を入力してください。')
                return false
              }
            }
          }

          // 複数人の場合
          if (remarks.length && remarks.length != 1) {

            let set = new Set(weekKind)
            if (set.size != Math.round(Number(target[7].value))) {
              const weekResult = await dialogs.showWarningConfirmDialog('曜日', '週' + target[7].value + '回です。\r\n全パターン合わせて週' + set.size + '回になっていますがよろしいですか？')
              if (weekResult != 'YES') {
                return false
              }
            }
          }
        }

        // 年末年始対応ありの場合
        if (target[18] && target[18].value == '1') {
          let holi = this.selectedDetails.newYearHoliday
          if (!holi || !holi.length) {
            await dialogs.showErrorDialog('年末年始勤務', '年末年始に勤務する日を選択してください。')
            return false
          }
        } else {
          this.selectedDetails.newYearHoliday = []
        }

        // 早朝割増の確認
        if (target[22]) {
          // 7時以前の設定があり、早朝割増がなしになっている場合
          if (isEarly && target[22].value == '0') {
            if (await dialogs.showConfirmDialog('早朝割増「なし」を選択しています', '開始時間が7時以前になっていますが、早朝割増が「なし」になっています。\r\nよろしいですか？') != 'YES') {
              return false
            }
          // 7時以前の設定がないにも関わらず、早朝割増がありになっている場合
          } else if (!isEarly && target[22].value == '1') {
            if (await dialogs.showConfirmDialog('早朝割増「あり」を選択しています', '開始時間が7時以前の設定がありませんが、早朝割増が「あり」になっています。\r\nよろしいですか？') != 'YES') {
              return false
            }
          }
        }
      }
      return true
    },

    /**
     * 単価2の計算
     */
    categoryCalc() {
      let price = 0
      let d = this.selectedDetails.display

      // 昔の特別清掃以外
      if (this.categoryCode != '14' || this.newData) {
        // 手動で単価2が変更されている場合、計算しない
        if (d[0].categoryBgColor) {
          return
        }

        for (let i = 0; i < d.length; i++) {
          if (d[i].price != '' && d[i].qty != '' && !isNaN(d[i].price)) {
            let subPrice = BigNumber(Number(d[i].qty)).times(Number(d[i].price))
            price = BigNumber(subPrice).plus(Number(price))
          }
        }
        // 少数以下は切り捨て（2024/09/20ご要望）
        d[0].price2 = String(Math.floor(Number(price)))

      // 特別清掃
      } else {
        for (let i = 0; i < d.length; i++) {
          // 手動で単価2が変更されていない場合、計算する
          if (!d[i].categoryBgColor && d[i].price != '' && d[i].qty != '' && !isNaN(d[i].price)) {
            price = 0
            price = (BigNumber(Number(d[i].qty)).times(Number(d[i].price)))
            price = (Math.floor(Number(price)))
            d[i].price2 = String(price)
          }
        }
      }
      
      // カテゴリ－金額計算
      this.categoryAmountCalc()
      this.forBind++
    },

    /**
     * カテゴリ－金額(price2)計算
     */
    categoryAmountCalc() {
      let p = 0
      // 定期・スポット
      if (['5'].includes(this.categoryCode)) {
        
        // 現在のタブ
        p = Number(this.selectedDetails.display[0].price2)

        // 他のタブ
        let otherTabs = 0
        for (let i = 0; i < this.changeModalTab.length; i++) {
          let tab = this.changeModalTab[i];
          // 今活性になっているタブは除く
          if (!tab.active && tab.data.display) {
            let otherPrice = Number(tab.data.display[0].price2)
            if (otherPrice && !isNaN(otherPrice)) {
              let other = Number(otherPrice)
              otherTabs += other
            }
          }
        }
        p += otherTabs

      // 特別清掃
      } else if (this.categoryCode == '14' && !this.newData) {
        
        const list = this.selectedDetails.display

        // 単価2の合計を計算
        for (let i = 0; i < list.length; i++) {
          const l = list[i]
          let row = Math.floor(Number(l.price2))

          if (row && !isNaN(row)) {
            p += row
          }
        }

      } else {
        p = Math.floor(Number(this.selectedDetails.display[0].price2))
      }
      
      if (p != null && !isNaN(p) && p >= 0) {
        this.categoryAmount = p
      }
    },

    /**
     * 非活性の色設定
     * @param blue 背景色を青にするか判定
     * @param type 行のタイプ名
     */
    setDisableColor(blue, type) {
      // 元は「単価取得ボタン」がなかったもの
      // if (!this.displaySubItems.length || ['23', '24', '25', '26'].includes(this.categoryCode) || !this.categoryCode) {
      //   return ''
      // }
      
      if (!this.displaySubItems.length) {
        return ''
      }

      if (type == undefined ) {
        return ''
      }

      // 日常、管理、定期のタブと紐づく行
      if (['3', '4', '5'].includes(this.categoryCode) && type.indexOf('modalTab') != -1) {

        let tab = this.changeModalTab.find((t) => {
          return type == 'modalTab' + t.id
        })

        if (tab == undefined) {
          return ''

        // 紐づくタブで単価取得している
        } else if (!tab.disabledDisp) {
          if (blue) {
            return 'bg-blue-200'
          
          // それ以外
          } else {
            return ''
          }
        
        // 紐づくタブで単価取得していない
        } else {
          return 'bg-gray-200'
        }
      
      } else if (!this.selectedDetails.getPriceFlag) {
        return 'bg-gray-200'
      } else if (blue) {
        return 'bg-blue-200'
      } else {
        return ''
      }
    },

    /**
     * 非活性判定
     * @param type 行のタイプ名
     */
    setDisabled(type) {
      // if (!this.displaySubItems.length || ['14', '23', '24', '25', '26'].includes(this.categoryCode) || !this.categoryCode) {
      //   return false
      // }
      if (!this.displaySubItems.length || !this.categoryCode) {
        return false
      }

      if (type == undefined ) {
        return true
      }

      // 日常、管理のタブと紐づく行
      if (['3', '4'].includes(this.categoryCode) && type.indexOf('modalTab') != -1) {
        
        let tab = this.changeModalTab.find((t) => {
          return type == 'modalTab' + t.id
        })

        if (tab == undefined) {
          return ''

        // 紐づくタブで単価取得している
        } else if (!tab.disabledDisp) {
          return false

        // 紐づくタブで単価取得していない
        } else {
          return true
        }

      } else if (!this.selectedDetails.getPriceFlag) {
        return true
      } else {
        return false
      }
    },

    /**
     * 表の「内容」変更イベント
     * @param row 対象行
     */
    changeDisplayName(row) {
      // 日常・管理は「内容」に時間や時給が記載されているため黄色にする
      if (['3', '4'].includes(this.categoryCode)) {
        row.nameBgColor = true
        this.forBind++
      }
    },

    /**
     * モーダルの切替
     * @param tab タブ
     * @param i 番号
     */
    async tabChanged(tab, i) {
      
      // 特記事項の未記入確認
      if (!await this.checkNote(this.selectedDetails.noteHTML)) {
        return
      }

      // 定期
      if (['5'].includes(this.categoryCode)) {
        let ok = true
        ok = await this.checkFixed()
        if (!ok) {
          return
        }
      }
      // 切り替わる前のモーダルのデータを保存
      this.setSelectedValue(this.selectedDetails)
      this.changeModalTab.forEach((t) => {
        if (t.active) {
          t.data = JSON.parse(JSON.stringify(this.selectedDetails))
          t.active = false
        }
      })

      tab.active = true

      // 初期化
      this.week = [utils.clone(WEEK_LIST)]

      if (['3', '4'].includes(this.categoryCode)) {
        // 表の内容は引き継ぐ
        tab.data.display = JSON.parse(JSON.stringify(this.selectedDetails.display))
      }

      this.setModalTabData(i)
    },

    /**
     * モーダル切替のタブの名前をセット
     */
    setTabName() {
      let tab = ''
      // 日常・管理
      if (['3', '4'].includes(this.categoryCode)) {
        tab = this.cleaningHours + ' 時間 ・ ' + this.displaySubItems[7].value + ' 回'

      // 定期・スポット
      } else if (['5'].includes(this.categoryCode)) {
        tab = this.displaySubItems[2].value + ' ' + this.selectedDetails.display[0].unitName2
      }

      this.changeModalTab.forEach((t) => {
        if (t.active) {
          t.name = tab
        }
      })
    },

    /**
     * モーダル切替タブ追加
     */
    addModalTab() {
      if (['3', '4'].includes(this.categoryCode) && this.changeModalTab.length == 7) {
        alert('最大7つのタブしか生成できません。')
        return
      }

      // 現在のモーダル内容を反映
      this.setSelectedValue(this.selectedDetails)
      let name = ''
      name = this.changeModalTab[this.getActiveTab()].name

      let tab = {id: Math.random(), name: name, active: false, data: JSON.parse(JSON.stringify(this.selectedDetails)), msg: '', changeDisp: false, disabledDisp: true}
      this.changeModalTab.push(tab)
    },

    /**
     * タブ
     * @param i 番号
     */
    deleteModalTab(i) {

      // 最後の1つを削除しようとしたら、中身を空にするだけ
      if (this.changeModalTab.length == 1) {
        alert('初期状態に戻します。')
        this.closed()
        this.fetchEstimateSubItem(this.changeModalTab[0].data, this.rows[0].modal)
        this.openInit = false
        return
      } else {
        // 削除対象が活性中か判定
        let active = false
        if (!this.changeModalTab[i].active) {
          active = true
        }
        // 削除対象が活性中の場合
        if (!active) {
          // 削除対象を非活性
          this.changeModalTab[i].active = false

          let index = 0
          // 削除対象ではないタブ番号を取得
          for (let j = 0; j < this.changeModalTab.length; j++) {
            if (j != i) {
              index = j
              break
            }
          }
          this.changeModalTab[index].active = true
          // そのモーダルを反映
          this.setModalTabData(index)
        }
        // 紐づく下の表も削除
        let typeName = 'modalTab' + this.changeModalTab[i].id
        for (let index = 0; index < this.selectedDetails.display.length; index++) {
          if (this.selectedDetails.display[index].type == typeName) {
            this.selectedDetails.display.splice(index, 1)
          }
        }
        
        // 削除
        this.changeModalTab.splice(i, 1)
      }
    },

    /**
     * タブを切り替えた際にデータも切り替える
     * @param index 対象のタブデータ番号
     */
    setModalTabData(index) {
      if (['3', '4'].includes(this.categoryCode)) {
        // 現在の表のデータを取得
        this.changeModalTab[index].data.display = JSON.parse(JSON.stringify(this.selectedDetails.display))
      }
      this.selectedDetails = JSON.parse(JSON.stringify(this.changeModalTab[index].data))
      for (let i = 0; i < this.selectedDetails.selectedValue.length; i++) {
        this.displaySubItems[i].value = this.selectedDetails.selectedValue[i]
      }

      // 曜日
      // 仕様変更前のデータなら、配列に変える
      let data = this.selectedDetails
      // 仕様変更のため、過去のremarksの形を変更
      this.setRemarks(data)
      // 人数分の曜日を作成
      this.setWeeks(data)
    },

    /**
     * 過去データを仕様変更後のremarksに変更
     * @param data 対象データ
     */
    setRemarks(data) {
      // 曜日
      // 仕様変更前のデータなら、配列に変える
      let rem = [{start: '', end: '', week: [], weekDetail: []}]
      if (!Array.isArray(data.remarks)) {
        if (data.remarks.week && data.remarks.week.length) {
          rem[0].week = data.remarks.week
        }
        
        if (data.remarks.start) {
          rem[0].start = data.remarks.start
          rem[0].end = data.remarks.end
        }
        data.remarks = [{start: '', end: '', week: [], weekDetail: []}]
        data.remarks = rem
        // 人数分remarksを生成
        if (data.selectedValue[8] && data.selectedValue[8] != '1') {
          let num = Number(data.selectedValue[8])
          for (let i = 0; i < num-1; i++) {
            data.remarks.push({start: '', end: '', week: [], weekDetail: []})
          }
        }
      }
    },

    /**
     * 曜日選択を人数分＆見た目をセット
     * @param data 対象データ
     */
    setWeeks(data) {
      // 人数分の曜日を作成
      if (data.selectedValue[8] && data.selectedValue[8] != '1') {
        let num = Number(data.selectedValue[8])
        for (let i = 0; i < num-1; i++) {
          this.week.push(utils.clone(WEEK_LIST))
        }
      }
      // 該当する曜日を活性
      if (data.remarks && data.remarks.length) {
        for (let wIdx = 0; wIdx < data.remarks.length; wIdx++) {
          const week = data.remarks[wIdx].week
          // 途中追加になったため、ないものは追加
          if (!data.remarks[wIdx].weekDetail) {
            data.remarks[wIdx].weekDetail = []
          }
          const weekDetail = data.remarks[wIdx].weekDetail
          const weekList = this.week[wIdx]

          if (week && week.length) {
            week.forEach((name, idx) => {
              weekList.forEach((w) => {
                if (w.name == name) {
                  w.active = true
                  // 曜日の詳細をセット
                  if (weekDetail && weekDetail[idx]) {
                    w.weekDetail = weekDetail[idx]
                  } else {
                    w.weekDetail = ['毎週']
                  }
                }
              })
            })
          }
        }
      }
    },

    /**
     * 日常、管理、定期の活性中のタブを取得
     */
    getActiveTab() {
      if (['3', '4', '5'].includes(this.categoryCode)) {
        for (let i = 0; i < this.changeModalTab.length; i++) {
          if (this.changeModalTab[i].active) {
            return i
          }
        }
      } else {
        return -1
      }
    },

    /**
     * 定期清掃のバリデート
     */
    async checkFixed() {
      let isExceed = false
      // ㎡数不明で概算が300㎡超えている場合
      if (this.uncertain && this.selectedDetails.conf['meter'] == '3') {
        isExceed = true
      }

      // 300㎡超える場合の係数選択済みかのチェック
      if ((this.exceed300 || isExceed) && this.selectedDetails.getPriceFlag) {
        let empty = []
        for (let i = 0; i < this.selectedDetails.display.length; i++) {
          let d = this.selectedDetails.display[i]
          if (d.name.indexOf('駐車場') == -1 && d.name.indexOf('発電機') == -1 &&d.name.indexOf('調整費') == -1) {
            if (!d.kindCoef || d.kindCoef == undefined || d.kindCoef == '') {
              empty.push(i+1)
            }
          }
        }
        if (empty.length) {
          const dialogResult = await dialogs.showWarningConfirmDialog('係数チェック', empty.join(',') + '行目の「係数」が選択されていませんがよろしいでしょうか。')
          if (dialogResult != 'YES') {
            return false
          }
        }
      }

      // 何も入力されていない行は削除（最終的に親画面に反映させないため）
      this.checkDisplay()

      return true
    },

    /**
     * 何も入力されていない行は削除
     */
    checkDisplay() {
      let disp = this.selectedDetails.display
      let arr = []

      for (let i = 0; i < disp.length; i++) {
        if (disp.length != 1) {
          if (disp[i].name != '' || (disp[i].price != '' && disp[i].price != '0')) {
            arr.push(disp[i])
          }
        }
      }

      if (disp.length != 1) {
        this.selectedDetails.display = arr
      }
      
      if (!this.selectedDetails.display.length) {
        this.addRowDetail()
      } 
    },

    /**
     * 日常・管理の行に表示する内容
     */
    rowTitle() {
      let name = null
      let target = this.displaySubItems
      if (['3', '4'].includes(this.categoryCode) && this.changeModalTab[this.getActiveTab()].changeDisp) {
        if (!target.length) return name

        let inai = ''
        // 時間指定なしで〇時間以内の場合
        if (target[4].value == '0' && target[6].value.indexOf('以内') > -1) {
          inai = '以内'
        }

        // 祝日対応の場合
        let holidays = ''
        if (target[16].value == '1') {
          holidays = '（祝日通常勤務）'
        } else if (target[16].value == '2') {
          holidays = '（祝日ゴミ搬出のみ）'
        }

        name = '週' + target[7].value + '回' + ' ' + this.cleaningHours + '時間' + inai +  ' ' + target[8].value + '名' + holidays
      }
      return name
    },


    /**
     * 特記事項コピーイベント
     */
    async onCopyNote() {
      if (this.noteStyle) {
        return
      }
      await utils.onCopyNoStyle()
    },

    /**
     * 特記事項変更
     */
    noteChange({html}) {
      // console.log(html)
      this.selectedDetails.noteHTML = html
    },

    /**
     * 特記事項を追加イベント
     */
    selectNote() {
      this.noteSelectorShow = true
    },

    /**
     * 見積特記事項を特記事項欄に反映
     */
    applyNotes(notes) {
      this.noteSelectorShow = false
      const notesText = notes.map((note) => {
        note.note = note.note.replaceAll('●●', '<span style="background-color: rgb(217 107 107);">●●</span>')
        return `${note.note.split(/\r\n|\n/).join('<br>')}`
      }).join('')
      this.selectedDetails.noteHTML += notesText
    },

    /**
     * 選択した見積内容に関する特記事項を取得
     */
    async getEstimateNote(code) {
      if (this.categoryCode != '14') {
        this.selectedDetails.noteHTML = ''
      }

      for (let i = 0; i < code.length; i++) {
        if (code[i].code != '') {
          let text = ''
          text = await estimateManager.getEstimateNoteText(this, code[i].code)

          if (code[i].title != '') {
            text = code[i].title + '<br>' + text
          }

          // 置換
          if (code[i].before != '') {
            text = text.replaceAll(code[i].before, code[i].after)
          }
        
          text = text.replaceAll('●●', '<span style="background-color: rgb(217 107 107);">●●</span>')
          text = text.split(/\r\n|\n/).join('<br>') + '<br>'

          // 特別清掃
          if (this.categoryCode == '14') {
            if (this.selectedDetails.noteHTML != '') {
              this.selectedDetails.noteHTML = this.selectedDetails.noteHTML + '<br><br>' + text
            } else {
              this.selectedDetails.noteHTML = text
            }
            
          } else {
            if (i != 0 && text != '') {
              text = '<br>' + text
            }
            this.selectedDetails.noteHTML = this.selectedDetails.noteHTML + text
          }
        }
      }
    },

    /**
     * 特記事項に追加
     * @param code 特記事項コード
     */
    async addEstimateNote(code) {
      let text = ''
      if (code) {
        text = await estimateManager.getEstimateNoteText(this, code)
      }
      if (text) {
        text = text.split(/\r\n|\n/).join('<br>') + '<br>'
        this.selectedDetails.noteHTML += '<br>' + text
      }
    },

    /**
     * 特記事項コードを生成_置換なし
     * @param c コード
     * @param t タイトル
     */
    setNoteCode(c, t) {
      return {code: c, title: t, before: '', after: ''}
    },

    /**
     * 特記事項コードを生成_置換あり
     * @param c コード
     * @param t タイトル
     * @param b 置換前
     * @param a 置換後
     */
    setNoteCode2(c, t, b, a) {
      return {code: c, title: t, before: b, after: a}
    },

    /**
     * 曜日選択イベント
     * @param tab 対象曜日
     * @param i インデックス
     * @param activeChange active変更か否か
     */
    weekChanged(tab, i, activeChange) {
      if (activeChange) {
        tab.active = !tab.active
      }

      let name = []
      let weekDetail = []

      this.week[i].forEach((w) => {
        if (w.active) {
          name.push(w.name)
          weekDetail.push(w.weekDetail)
        }
      })
      this.selectedDetails.remarks[i]['week'] = name
      this.selectedDetails.remarks[i]['weekDetail'] = weekDetail
    },

    /**
     * 年末年始の出勤日
     * @param tab 選択した日
     */
    holidayChanged(tab) {
      if (!this.selectedDetails.newYearHoliday) {
        this.selectedDetails.newYearHoliday = []
      }
      // 2度クリックしたら、選択解除
      for (let i = 0; i < this.selectedDetails.newYearHoliday.length; i++) {
        const h = this.selectedDetails.newYearHoliday[i]
        if (h == tab) {
          this.selectedDetails.newYearHoliday.splice(i, 1)
          return
        }
      }

      // 選択したものを追加
      this.selectedDetails.newYearHoliday.push(tab)

      // 並べ替え
      let holi = []
      for (let i = 0; i < this.newYearHoliday.length; i++) {
        const ny = this.newYearHoliday[i].value
        if (this.selectedDetails.newYearHoliday.includes(ny)) {
          holi.push(ny)
        }
      }
      this.selectedDetails.newYearHoliday = utils.clone(holi)
      this.forBind ++
    },

    /**
     * 特記事項の未記入の確認
     */
    async checkNote(note) {
      // 特記事項未記入
      if (note.indexOf('●●') != -1) {
        await dialogs.showErrorDialog('特記事項確認', '特記事項に記載されていない箇所があります。')
        return false
      } else {
        return true
      }
    },

    /**
     * タイプが混合の場合はファミリーの値を取得
     * @param i インデックス
     */
    setType(i) {
      if (this.displaySubItems[i].value == '3') {
        return '2'
      } else {
        return this.displaySubItems[i].value
      }
    },

    /**
     * 発注マスタオープン
     */
    openPurchaseModal() {
      this.purchaseModalShow = true
    },

    /**
     * 発注マスタクローズ
     */
    closePurchaseModal() {
      this.purchaseModalShow = false
    },
    
    /**
     * 発注金額を算出
     * @param gName グループ名
     * @param changePrice 発注金額変更
     * @param changeRate 発注率変更
     */
    async getPurchasePrice(gName, changePrice, changeRate) {
      if (!this.active) {
        return
      }
      
      // モーダルを開いてすぐは何も計算しない
      if (this.openInit) {
        return
      }
      // 初期値として、貯水槽、消防、EV、増圧の場合、発注額（式中の）を表の発注額に
      if (['12', '15', '19', '20'].includes(this.categoryCode) && this.selectedDetails.totalUnitPricePre && this.selectedDetails.totalUnitPricePre != '0' && this.selectedDetails.totalUnitPricePre != 'NaN' && (!this.purchase[0].price || this.purchase[0].price == '0')) {
        this.purchase[0].price = this.selectedDetails.totalUnitPricePre
      }
      let rate = 0
      let price = 0
      let ePrice = 0

      // 発注金額手動変更計算直後は何もしない
      if (this.changePurchasePrice) {
        this.changePurchasePrice = false
        return
      }

      // 発注率
      rate = Number(this.purchase[0].rate)
      // 発注金額
      price = Number(this.purchase[0].price)
      // もとにする金額は、手数料なしの金額
      ePrice = Number(this.commission[0].estimatePrice)
      if (!ePrice || isNaN(ePrice)) {
        ePrice = 0
      }

      // 発注率もしくは発注金額が変更の場合
      if (gName) {
        // 率を変更した場合
        if (changeRate) {
          // 発注金額変更
          let r = BigNumber(rate).div(100)
          price = Math.floor(BigNumber(ePrice).times(r))
          this.purchase[0].price = String(price)

        // 発注金額を手動で変更した場合
        } else if (changePrice) {
          // 単価取得ボタン押下直後を解除（価格が変更しなかった際に発注金額を変更すると消えてしまうため、ここに必要）
          this.getPriceMasterBtn = false
          // 過去の定期・定期（300㎡越）・特別データ、月額請求ON、常に発注率をONの場合は率を変更する
          if ((this.categoryCode == '5' && this.changeModalTab.length > 1) || (this.categoryCode == '14' && !this.newData) || this.selectedDetails.monthlyFeeFlag || this.selectedDetails.purchaseRateAlwaysChange) {
            if ((this.categoryCode == '5' && this.changeModalTab.length > 1) || (this.categoryCode == '14' && !this.newData)) {
              let name = '定期清掃'
              if (this.categoryCode == '14') {
                name = '特別清掃'
              }
              await dialogs.showInfoDialog('発注率を変更します', '仕様変更前の' + name + 'のため、発注率を変更します。')
            } else if (this.selectedDetails.monthlyFeeFlag) {
              await dialogs.showInfoDialog('発注率を変更します', '月額請求ONのため、発注率を変更します。')
            }
            // 元となる手数料なしの金額が0の場合
            if (ePrice == 0) {
              this.purchase[0].rate = String(this.initRate)
              this.purchase[0].price = '0'
            } else {
              rate = BigNumber(price).div(ePrice)
              rate = Math.floor(BigNumber(rate).times(1000)) / 10
              this.purchase[0].rate = String(rate)
            }
            
            await this.overInitRate(rate)
            this.purchaseReload++
            return
          }
          
          if (rate && !isNaN(rate)) {
            // 見積り金額変更
            let r2 = BigNumber(rate).div(100)
            let p2 = BigNumber(price).div(r2)
            if (!p2 || isNaN(p2)) {
              p2 = 0
            }

            // 少数以下切り捨て
            p2 = Math.floor(p2)
            // アポロ管財金額に反映
            this.commission[0].estimatePrice = String(p2)

            // 手数料込の金額を算出
            let p3 = p2
            let comRate = Number(utils.hankaku(this.commission[0].rate))
            if (comRate && !isNaN(comRate)) {
              // 手数料込金額
              let cr = 1 - (comRate / 100)
              p3 = Math.floor(BigNumber(p2).div(cr))

              // 手数料
              comRate = comRate / 100
              let p4 = BigNumber(p3).times(comRate)
              this.commission[0].commissionPrice = String(p4)
            }
            this.categoryAmount = p3
            this.selectedDetails.display[0].price2 = String(p3)
            this.changePurchasePrice = true
            this.selectedDetails.changePurchasePrice = true
            this.commissionReload++
          }
        }
        
      // 見積金額が変更になった場合
      } else {

        // ガラス清掃-植栽剪定・防火対象物点検、発注額（式中の）を表の発注額に表示
        if (['10', '13', '27'].includes(this.categoryCode) && this.selectedDetails.price['purchasePre'] && this.selectedDetails.price['purchasePre'] != '' && this.selectedDetails.price['purchasePre'] != '0' && this.selectedDetails.price['purchasePre'] != 'NaN') {
          this.purchase[0].price = this.selectedDetails.price['purchasePre']
          price = Number(this.purchase[0].price)
          this.purchaseReload++
        }

        // 建築設備点検・特定建築物定期検査で発注額決定の場合、発注額（式中の）を表の発注額に表示
        if (['16', '17'].includes(this.categoryCode) && this.displaySubItems && this.displaySubItems[0] && this.displaySubItems[0].value == '3' && this.selectedDetails.price['purchasePre'] && this.selectedDetails.price['purchasePre'] != '' && this.selectedDetails.price['purchasePre'] != '0' && this.selectedDetails.price['purchasePre'] != 'NaN') {
          this.purchase[0].price = this.selectedDetails.price['purchasePre']
          price = Number(this.purchase[0].price)
          this.purchaseReload++
        }

        this.selectedDetails.changePurchasePrice = false
        // 「常に発注率を変更」がOFFで、発注金額がない OR 定期・スポット（300㎡超え）の場合、発注金額を計算
        if (!this.selectedDetails.purchaseRateAlwaysChange && (this.purchase[0].price == '' || this.purchase[0].price == '0' || (['5', '28'].includes(this.categoryCode) && (this.exceed300 || this.selectedDetails.conf['meter'] == '3')))) {
          let r = BigNumber(rate).div(100)
          price = Math.floor(BigNumber(ePrice).times(r))
          this.purchase[0].price = String(price)

        // 発注金額がすでにある場合は発注率を変更
        } else {
          // 元となる手数料なしの金額が0の場合
          if (ePrice == 0) {
            this.purchase[0].rate = String(this.initRate)
            this.purchase[0].price = '0'
          } else {
            rate = BigNumber(price).div(ePrice)
            rate = Math.floor(BigNumber(rate).times(1000)) / 10
            this.purchase[0].rate = String(rate)
          }
        }
      }
      if (!this.getPriceMasterBtn) {
        await this.overInitRate(rate)
      }
      // this.getPriceMasterBtn = false
      this.purchaseReload++
    },

    /**
     * 発注率がオーバーしているか確認
     */
    async overInitRate(rate) {
      // 発注先によって初期発注率が変わる
      let displaySubItems = null
      if ((!this.categoryCode || this.categoryCode == '') && this.displaySubItems && this.displaySubItems.length && this.displaySubItems[0].value) {
        displaySubItems = this.displaySubItems[0].value
      }
      this.initRate = utils.getPurchaseRate(this.categoryCode, this.purchase[0].supplier, this.$store.getters.purchaseRate, displaySubItems)
      // マスタ値を超えている場合
      if (this.initRate < rate) {
        // 何度も警告しないようにする
        if (!this.overPurchaseRate) {
          await dialogs.showErrorDialog('発注率オーバー', '発注率が設定値を超えています。\r\n設定値：' + this.initRate + '%')
          this.overPurchaseRate = true
        }
        
        this.purchase[0].priceBgColor = true
        this.purchase[0].rateBgColor = true
      } else {
        this.overPurchaseRate = false
        this.purchase[0].priceBgColor = false
        this.purchase[0].rateBgColor = false
      }
    },

    /**
     * 初期発注率設定
     */
    getInitPurchaseRate() {
      if (!this.active) {
        return
      }
      
      // 発注先名設定
      let rateData
      if (this.categoryCode && this.categoryCode != '') {
        
        rateData = this.$store.getters.purchaseRate.find((p) => {
          return p.categoryCode == this.categoryCode
        })

        // 発注先デフォルト（2023/04/03発注先デフォルト設定）
        if (rateData && rateData.supplier && this.purchase[0].supplier == '') {
          this.purchase[0].supplier = rateData.supplier
        }
      }

      // デフォルト発注率設定
      let displaySubItems = null
      if ((!this.categoryCode || this.categoryCode == '') && this.displaySubItems && this.displaySubItems.length && this.displaySubItems[0].value) {
        displaySubItems = this.displaySubItems[0].value
      }
      this.initRate = utils.getPurchaseRate(this.categoryCode, this.purchase[0].supplier, this.$store.getters.purchaseRate, displaySubItems)

      // 発注率がまだ設定されていないもの
      if (this.purchase[0].rate == '') {
        this.purchase[0].rate = String(this.initRate)
      }
      this.purchaseReload++
    },

    /**
     * 発注先変更イベント
     * 発注先によって初期発注率が変更となる
     */
    async changeInitRate(val) {
      this.purchase[0].supplier = val.sup
      const rate = this.initRate
      // 発注先によって初期発注率が変わる
      let selectedValue = null
      if (!val.code || val.code == '') {
        if (this.selectedDetails.selectedValue && this.selectedDetails.selectedValue[0]) {
          selectedValue = this.selectedDetails.selectedValue[0]
        }
      }
      this.initRate = utils.getPurchaseRate(val.code, val.sup, this.$store.getters.purchaseRate, selectedValue)
      // 100%になった時は発注率を変更
      if (this.initRate == 100) {
        this.purchase[0].rate = String(this.initRate)
        await this.getPurchasePrice(this.purchaseData[0].groupName, false, true)

      // 発注先が変更になり、初期発注率も変わったら計算し直す（アラート表示をするため）
      } else if(rate != this.initRate) {
        await this.getPurchasePrice(this.purchaseData[0].groupName, false, true)
      }
      this.purchaseReload++
    },

    /**
     * 発注内容を初期化
     */
    setInitPurchase() {
      // 発注先
      // this.purchase[0].supplier = ''
      // 発注金額
      this.purchase[0].price = ''
      // 発注率
      this.purchase[0].rate = ''
      // 発注率超えフラグ
      this.overPurchaseRate = false
      // 発注率越え背景色
      this.purchase[0].priceBgColor = false
      this.purchase[0].rateBgColor = false

      this.getInitPurchaseRate()
    },

    /**
     * 数量2変更イベント
     */
    changeQty2() {
      // 消防点検
      if (this.categoryCode == '15') {
        // 土日対応 1回のみ
        if (this.displaySubItems[6].value == '1') {
          this.calc()
        }  
      }
      // 月額請求ONなら計算
      this.changeMonthlyFee()
      this.changeCategoryPrice()
    },

    /**
     * 単位2変更イベント
     */
    changeUnitName2() {
      // 月額請求ONであれば、再計算
      this.changeMonthlyFee()
    },

    /**
     * 単価2再計算
     */
    changeCategoryPrice() {
      this.openInit = false
      this.categoryCalc()
    },

    changeOpenInit() {
      this.openInit = false
    },

    /**
     * 親画面の手数料明細取得
     */
    getCommissionData(modalData, modal) {
      this.commission[0] = utils.clone(this.commissionData)

      // まだ詳細画面を開いて1度も編集していない場合
      if (!Object.keys(modalData).length || !modal) {
        if (this.commission[0].rate == '') {
          // 手数料の初期値セット
          this.setInitCommission()
          // 協力費入力欄にも反映
          this.setCompeVal()
        }
      }
      this.commissionReload++
    },

    /**
     * 手数料の初期値セット
     */
    setInitCommission() {
      if (this.buildingInfo.commission && this.buildingInfo.commission == 'なし') {
        this.commission[0].rate = '0'
        this.commission[0].commissionPrice = '0'
        this.commissionReload++
      } else if (this.buildingInfo.commission && this.buildingInfo.commission == 'あり' && this.commissionMst.rate) {
        this.commission[0].rate = String(this.commissionMst.rate)
        // 手数料計算
        this.getCommissionPrice()
      }
    },

    /**
     * 手数料率変更イベント
     */
    rateSelected(rate) {
      console.log('rateSelected')
      this.commission[0].rate = rate
      console.log(this.commission[0].rate)
      // 手数料計算
      this.getCommissionPrice()
    },

    /**
     * 手数料率変更イベント
     */
    rateBlur() {
      console.log('rateBlur')
      console.log(this.commission[0].rate)
      // 手数料計算
      this.getCommissionPrice()
    },

    /**
     * 手数料変更イベント
     */
    changeCommissionPrice() {
      console.log('changeCommissionPrice')
      const comPrice = Number(this.commission[0].commissionPrice)
      const price = Number(this.commission[0].price)
      if (this.commission[0].commissionPrice && comPrice && !isNaN(comPrice) && price && !isNaN(price)) {
        const rate = comPrice / price

        let rate1 = rate
        const res = this.getCommissionRate(rate1, comPrice, rate, price)
        this.commission[0].rate = String(res)
      } else {
        this.commission[0].rate = '0'
      }
      this.getCommissionPrice()
    },

    /**
     * 指定された手数料金額と同じになる手数料率を取得
     */
    getCommissionRate(rate, comPrice, comRate, price) {
      const decimalLength = this.getDecimalLength(rate)
      if (decimalLength <= 3) {
        return rate
      } else {
        for (let i = 3; i < decimalLength; i++) {
          // 少数の桁数を切る
          let r = Number(comRate.toFixed(i))
          let res = Math.floor(BigNumber(r).times(price))
          // 同じ桁数の少数
          const few = Number(BigNumber(0.1).pow(i))

          if (res == comPrice) {
            return Number(BigNumber(r).times(100))
          } else if (res > comPrice) {
            r = BigNumber(r).minus(few)
            res = Math.floor(BigNumber(r).times(price))
            if (res == comPrice) {
              return Number(BigNumber(r).times(100))
            }
          } else {
            r = BigNumber(r).plus(few)
            res = Math.floor(BigNumber(r).times(price))
            if (res == comPrice) {
              return Number(BigNumber(r).times(100))
            }
          }
        }
      }
    },

    /**
     * 少数点以下の桁数
     * @param number 対象数値
     */
    getDecimalLength(number) {
      const num = String(number).split('.')
      if (num[1]) {
        return num[1].length
      } else {
        return 0
      }
    },

    /**
     * 手数料計算
     */
    async getCommissionPrice() {
      if (!this.active) {
        return
      }
      // console.log('手数料計算します！')
      // if (this.openInit) {
      //   return
      // }
      let commissionPrice = 0
      let rate = Number(utils.hankaku(this.commission[0].rate))
      // アポロ金額
      let ePrice = 0
      // 手数料込
      let price = Number(this.categoryAmount)

      if (!rate || isNaN(rate)) {
        // this.commission[0].rate = '0'
        commissionPrice = 0
        rate = 0
        ePrice = price
      } else if (!price || isNaN(price)) {
        commissionPrice = 0
        ePrice = 0
      } else {
        rate = rate / 100
        // 手数料＝手数料込×率
        commissionPrice = BigNumber(price).times(rate)
        commissionPrice = Math.floor(commissionPrice)
        ePrice = price - commissionPrice
      }

      if (commissionPrice == 0) {
        this.commission[0].commissionPrice = '0'
      } else {
        this.commission[0].commissionPrice = String(commissionPrice)
      }
      if (ePrice == 0) {
        this.commission[0].estimatePrice = '0'
      } else {
        this.commission[0].estimatePrice = String(ePrice)
      }
      this.commission[0].price = String(this.categoryAmount)
      this.commissionReload++

      // 単価取得ボタン押下直後
      if (this.getPriceMasterBtn) {
        // 初期化
        this.setInitPurchase()
        await this.getPurchasePrice(this.purchaseData[0].groupName, false, true)
        this.purchaseReload++
        this.getPriceMasterBtn = false
      } else {
        await this.getPurchasePrice(null, false, false)
      }
    },

    /**
     * 月額チェックイベント
     */
    changeMonthlyFee() {
      // ONの場合
      if (this.selectedDetails.monthlyFeeFlag) {
        this.calcMonthlyFee()
      // OFFの場合
      } else {
        this.deleteMonthly()
      }
    },

    /**
     * 月額を計算し、表の内容に出力
     */
    calcMonthlyFee() {
      this.changePurchasePrice = false
      this.selectedDetails.display[0].categoryBgColor = false
      this.categoryCalc()

      // 単価2
      let price2 = this.selectedDetails.display[0].price2
      const idx = this.adjustmentPriceRowIdx()
      if (idx >= 0) {
        price2 = String(Number(this.selectedDetails.display[0].price2) - Number(this.selectedDetails.display[idx].price))
      }
      // 数量2
      const qty2 = this.selectedDetails.display[0].qty2
      // 単位2
      const unitName2 = this.selectedDetails.display[0].unitName2
      // 数量2に記入がなければ、アラートを出して終了
      if (!qty2 || qty2 == '0') {
        this.monthlyError = '数量2が未入力のため月額が計算できません'
        return
      // 単位2がない場合
      } else if (!unitName2) {
        this.monthlyError = '単位2が未入力のため月額が計算できません'
        return
      // 単価2がない場合
      } else if (!price2 || price2 == '0') {
        this.monthlyError = '単価2の金額が0のため月額が計算できません'
        return
      // 数値か確認
      } else if (isNaN(price2) || isNaN(qty2)) {
        this.monthlyError = '数量2もしくは単価2が不正な値のため月額が計算できません'
        return
      } else {
        this.monthlyError = ''
      }

      // 単価2÷（12÷数量2）で月額を出す
      let monthly = Number(price2) / (12 / Number(qty2))
      // 単位2が「回/2年」「回/3年」などの場合（ただの回/年は特に顧慮しなくて良い）
      if (unitName2.indexOf('回/') != -1 && unitName2.indexOf('年') != -1 && unitName2.indexOf('回/年') == -1) {
        // 〇年の部分を取得
        const num = unitName2.replace(/[^0-9]/g, '')
        if (num && !isNaN(num) && Number(num) > 1) {
          monthly = BigNumber(monthly).div(Number(num))
        }
      }
      // 月額の1円単位は切り捨て BYアポロ管財の大野様
      monthly = Math.floor(monthly / 10) * 10

      this.selectedDetails.monthlyFee = this.adjustmentPrice2(monthly)
      this.forBind++
    },

    /**
     * 月額と単価2を調整する
     * ※月額を10円単位で切上げた場合に単価2と合わない場合は単価2を変更する
     * @param monthly 月額
     */
    adjustmentPrice2(val) {
      let monthly = 0
      // 引数に月額がある場合
      if (val) {
        monthly = val
      // 引数に無い場合
      } else {
        monthly = utils.clone(this.selectedDetails.monthlyFee)
      }
      
      // 表から単価2を計算
      let price2 = this.getPrice2FromPrice1()
      const idx = this.adjustmentPriceRowIdx()
      if (idx >= 0) {
        price2 = (Number(price2) - Number(this.selectedDetails.display[idx].price))
      }
      // 数量2
      const qty2 = this.selectedDetails.display[0].qty2

      if (monthly && qty2 && qty2 != '0') {
        // 月額から単価2を出す
        let price = this.getPrice2FromMonthly(monthly)

        // 月額から計算した単価2の結果が小数の場合
        if (!Number.isInteger(price)) {
          // 月額を10円下げて再計算
          monthly = this.monthlyRecast(price, monthly)

          price = this.getPrice2FromMonthly(monthly)
        }

        // 月額から計算で出した単価2と単価1から求めた単価2・表の単価2に誤差がある、もしくは単価1から求めた単価2と表の単価2に誤差がある
        if (price != price2 || String(price) != this.selectedDetails.display[0].price2 || String(price2) != this.selectedDetails.display[0].price2) {
          // 誤差
          const diffPrice = String(price - price2)
          // 既に調整費の行があるか
          const idx = this.adjustmentPriceRowIdx()
          if (idx >= 0) {
            if (diffPrice == '0') {
              this.selectedDetails.display.splice(idx, 1)
            } else {
              this.selectedDetails.display[idx].qty = '1'
              this.selectedDetails.display[idx].price = diffPrice
            }
          } else if (diffPrice != '0') {
            // 調整費の行を挿入
            this.addAdjustmentPriceRow(diffPrice)
          }
        }
      }
      return monthly
    },

    /**
     * 単価2を単価1から求める
     * 手動で変更の考慮無し
     * 日常・管理員・タブが複数ある定期清掃・過去仕様で複数行がある特別清掃はこの関数では不可
     */
    getPrice2FromPrice1() {
      let val = 0
      const rows = utils.clone(this.selectedDetails.display)
      for (let i = 0; i < rows.length; i++) {
        const d = rows[i]
        // 単価1×数量
        let p = Number(d.price)
        let q = Number(d.qty)
        if (!p || isNaN(p)) {
          p = 0
        }
        if (!q || isNaN(q)) {
          q = 0
        }
        let price = BigNumber(p).times(q)
        val = Number(BigNumber(price).plus(val))
      }
      return Math.floor(val)
    },

    /**
     * 月額のため、単価2が小数になるので再計算
     * @param price2 単価2
     */
    monthlyRecast(price2, monthly) {
      if (!price2) {
        return monthly
      }
      
      // 月額から計算した結果が小数の場合
      if (!Number.isInteger(price2)) {
        // 月額を10円ダウン
        monthly -= 10
        return this.monthlyRecast(this.getPrice2FromMonthly(monthly), monthly)
      } else {
        return monthly
      }
    },

    /**
     * 月額から単価2を算出
     */
    getPrice2FromMonthly(monthly) {
      // 数量2
      const qty2 = this.selectedDetails.display[0].qty2
      // 単位2
      const unitName2 = this.selectedDetails.display[0].unitName2

      if (monthly && qty2 && qty2 != '0') {
        // 月額から単価2を出す
        let kaisu = 12 / Number(qty2)
        let price = Number(BigNumber(monthly).times(kaisu))

        // 単位2が「回/2年」「回/3年」などの場合（ただの回/年は特に顧慮しなくて良い）
        if (unitName2 && unitName2.indexOf('回/') != -1 && unitName2.indexOf('年') != -1 && unitName2.indexOf('回/年') == -1) {
          // 〇年の部分を取得
          const num = unitName2.replace(/[^0-9]/g, '')
          if (num && !isNaN(num) && Number(num) > 1) {
            price = Number(BigNumber(price).times(Number(num)))
          }
        }
        return price
      }
      return 0
    },

    /**
     * 月額を削除
     */
    deleteMonthly() {
      this.monthlyError = ''
      this.selectedDetails.monthlyFee = null
    },

    /**
     * 調整費の行のインデックスを検索
     * ない場合は、-1を返却
     */
    adjustmentPriceRowIdx() {
      for (let i = 0; i < this.selectedDetails.display.length; i++) {
        const d = this.selectedDetails.display[i]
        if (d.name == '調整費') {
          d.detailBgColor =  false
          // 定期清掃の場合
          if (['5', '28'].includes(this.categoryCode)) {
            this.selectedDetails.display[i].priceKind = ''
            this.selectedDetails.display[i].kindCoef = ''
            this.selectedDetails.display[i].priceKindBgColor = false

          // 特別、管球、粗大ごみ、物品販売の場合
          } else if (['14', '23', '24', '25'].includes(this.categoryCode)) {
            this.selectedDetails.display[i].priceKind = ''
            this.selectedDetails.display[i].owner = ''
            this.selectedDetails.display[i].compe = ''
            this.selectedDetails.display[i].priceKindBgColor = false
            this.selectedDetails.display[i].ownerBgColor = false
            this.selectedDetails.display[i].compeBgColor = false

            if (this.categoryCode == '14') {
              this.selectedDetails.display[i].finishPoint = ''
              this.selectedDetails.display[i].finishPointBgColor = false
            }
          }
          return i
        }
      }
      return -1
    },

    /**
     * 調整費の行を挿入
     * @param price 調整費
     */
    addAdjustmentPriceRow(price) {
      this.addRowDetail()
      const i = this.selectedDetails.display.length - 1
      this.selectedDetails.display[i].name = '調整費'
      this.selectedDetails.display[i].qty = '1'
      this.selectedDetails.display[i].unitName = '式'
      this.selectedDetails.display[i].price = String(price)
      // 定期清掃の場合
      if (['5', '28'].includes(this.categoryCode)) {
        this.selectedDetails.display[i].priceKind = ''
        this.selectedDetails.display[i].kindCoef = ''
        this.selectedDetails.display[i].priceKindBgColor = false

      // 特別、管球、粗大ごみ、物品販売の場合
      } else if (['14', '23', '24', '25'].includes(this.categoryCode)) {
        this.selectedDetails.display[i].priceKind = ''
        this.selectedDetails.display[i].owner = ''
        this.selectedDetails.display[i].compe = ''
        this.selectedDetails.display[i].priceKindBgColor = false
        this.selectedDetails.display[i].ownerBgColor = false
        this.selectedDetails.display[i].compeBgColor = false

        if (this.categoryCode == '14') {
          this.selectedDetails.display[i].finishPoint = ''
          this.selectedDetails.display[i].finishPointBgColor = false
        }
      }
    },

    /**
     * カテゴリー名から改行コードを削除
     * @param val カテゴリー名
     */
    removeNewLine(val) {
      return utils.removeNewLine(val)
    },

    /**
     * 単価2の3桁区切り表示
     * @param val 金額
     */
    numberToLocale(val) {
      return utils.numberToLocale(val)
    },

    /**
     * 表の項目による係数でWAXが選択していれば、WAXについての特記事項を記載
     * なければ削除
     * 定期清掃・スポット
     */
    noteForWax() {
      if (!['5', '28'].includes(this.categoryCode)) {
        return
      }
      if (!this.selectedDetails.noteHTML) {
        return
      }
      const d = this.selectedDetails.display
      let wax = {}
      wax = d.find((row) => {
        return (row.kindCoef && utils.hankaku(row.kindCoef).indexOf('WAX') > -1)
      })

      if (wax && Object.keys(wax).length) {
        // 特記事項にWAXがなければ追記
        if (this.selectedDetails.noteHTML.indexOf('剥離作業は含まれておりません') == -1) {
          this.selectedDetails.noteHTML += '<p>剥離作業は含まれておりません。別途お見積りとさせて頂きます。</p>'
        }
      } else {
        // WAX係数がない場合は、特記事項から剥離作業を削除
        this.selectedDetails.noteHTML = this.selectedDetails.noteHTML.replace('<p>剥離作業は含まれておりません。別途お見積りとさせて頂きます。</p>', '')
      }
    },

    /**
     * 時間帯をクリア（日常清掃・管理員）
     * @param i インデックス
     */
    clearTimes(i) {
      this.selectedDetails.remarks[i].start = ''
      this.selectedDetails.remarks[i].end = ''
      this.selectedDetails.remarks[i].breakHour = ''
      this.selectedDetails.remarks[i].breakMinutes = ''
    },

    /**
     * 実働時間を表示
     */
    getWorkingTime(i) {
      const rem = this.selectedDetails.remarks[i]
      if (rem.start && rem.end) {
        let time = utils.getHours(rem.start, rem.end)
        if (time) {
          let breakTime = 0
          if (rem.breakHour) {
            breakTime = Number(rem.breakHour)
          }
          if (rem.breakMinutes && Number(rem.breakMinutes)) {
            breakTime += Math.floor((Number(rem.breakMinutes) / 60) * 10000) / 10000
          }
          time -= breakTime
          if (time && String(time).length > 4) {
            let timeS = String(time)
            return timeS.substring(0, 4) + '...'
          }
          return time
        }
      }
      return 0
    },

    /**
     * 並べ替えイベント
     */
    detailOrderChanged(arg) {
      // 並べ替え実行した場合
      if (arg.moved) {
        // console.log(arg.moved)
        // 移動前のインデックス
        const oldIndex = arg.moved.oldIndex
        // 移動後のインデックス
        const newIndex = arg.moved.newIndex
        // 移動したデータの内容
        const element = arg.moved.element

        const d = this.selectedDetails.display

        // 1行目を移動した場合
        if (oldIndex == 0 || newIndex == 0) {
          // 移動前が1行目の場合
          if (oldIndex == 0) {
            // 1行目のデータを2行目に移行
            d[0].qty2 = element.qty2
            d[0].unitName2 = element.unitName2
            d[0].price2 = element.price2
            d[0].categoryBgColor = element.categoryBgColor
            // fixedRowspanを削除
            delete d[newIndex].fixedRowspan

          // 移動後が1行目の場合
          } else {
            // 2行目のデータを1行目に移行
            d[0].qty2 = d[1].qty2
            d[0].unitName2 = d[1].unitName2
            d[0].price2 = d[1].price2
            d[0].categoryBgColor = d[1].categoryBgColor
            // 2行目のfixedRowspanを削除
            delete d[1].fixedRowspan
          }
        }
      }

    },

    /**
     * 協力費の入力値から係数を求める
     */
    getCompe() {
      const selected = this.displaySubItems
      const conf = this.selectedDetails.conf
      let val = ''
      
      // 協力費
      let compe = 1
      if (this.categoryCode != '14' && selected && selected.length) {
        const se = selected.find((s) => {
          return s.item == 'compensation'
        })
        if (se && se.value) {
          val = se.value
        }
      } else if (this.categoryCode == '14') {
        if (this.selectedDetails['spCompe']) {
          val = this.selectedDetails['spCompe']
        }
      }

      if (val && !isNaN(val)) {
        let compe2 = BigNumber(Number(val)).div(100)
        compe = BigNumber(1).minus(compe2)
      }
      conf['compe'] = String(compe)
    },

    /**
     * 協力費の初期値を設定
     */
    setCompeVal() {
      const selected = this.displaySubItems
      if (this.commission[0].rate && this.commission[0].rate != '0') {
        if (this.categoryCode == '14') {
          this.selectedDetails['spCompe'] = this.commission[0].rate
        } else {  
          const se = selected.find((s) => {
            return s.item == 'compensation'
          })
          if (se && se.item) {
            if (!se.value || se.value == '0') {
              se.value = this.commission[0].rate
            }
          }
        }
      }
    },
  }
}
</script>
<style scoped>
.content-container {
  min-width: 900px;
  max-width: calc(100vw - 185px);
  min-height: 450px;
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.border-gray {
  border: solid #d3cfcf 1px;
}

.td-height {
  height: 40px;
}

.input {
  height: 40px;
}

.formula {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  text-align:center
}

.formula-left {
  margin-top: 30px;
  display: flex;
  margin-left: 56px;
  text-align:center;
  border-bottom: 1px solid #dbe6f1;
  margin-right: 56px;
  overflow-x: auto;
}

.formula-left2 {
  margin-top: 30px;
  display: flex;
  margin-left: 100px;
  text-align:center;
  border-bottom: 1px solid #dbe6f1;
  margin-right: 100px;
}

.formula-left3 {
  margin-top: 30px;
  display: flex;
  margin-left: 78px;
  text-align:center;
  border-bottom: 1px solid #dbe6f1;
}

.formula-left div {
  margin: 0 7px;
}

.formula-left2 div {
  margin: 0 7px;
}

.formula-left3 div {
  margin: 0 7px;
}

.formula span {
  font-size: 17px;
  margin: 8px 15px;
}

.price-width {
  width: 120px;
}

.cof-price-width {
  width: 100px;
}

.price-width-sm {
  width: 70px;
}

.radio-width {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.table {
  width: auto;
  margin: auto;
}

.table2 {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.table-width1 {
  width: 330px;
}

.table-width2 {
  width: 392px;
}

.table-width3 {
  width: 270px !important;
}

.table-width4 {
  width: 430px !important;
}

.number {
  width: 40px;
}

.btn {
  width: 700px;
  margin-right: auto;
  margin-left: auto;
}

.col-no {
  width: 35px;
}

.col-small {
  width: 60px;
}

.col-sm {
  width: 70px;
}

.col-smm {
  width: 100px
}

.col-midle {
  width: 120px;
}

.col-l {
  width: 150px;
}

.col-xl {
  min-width: 430px;
}

.drop {
  padding-left: 33px;
}

.symbol {
  margin-top: 16px;
  margin-bottom: auto;
  font-size: larger
}

.text-xxs {
  font-size: 0.5rem;
}

.formula-open-normal {
  height: 90%;
  opacity: 1;
  transition: all .5s ease;
}

.formula-open-mgt {
  height: 75%;
  opacity: 1;
  transition: all .5s ease;
}

.formula-close {
  height: 0;
  width: 0;
  opacity: 0;
  transition: all .5s ease;
}

.border-purple {
  border: 1px solid rgb(185, 142, 241);
}

.text-purple {
  color: rgb(185, 142, 241);
}

.disabled-back {
  background: #f6f8fb;
}

.exceed {
  width: 76%;
  border: solid 1px #c0c0e7;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
}

.title {
  margin-left: 100px;
  font-weight: bold;
  color: #21217e;
  margin-bottom: 10px;
}

.msg {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  font-size: small;
  color: #717e8b;
  white-space: pre-wrap;
  letter-spacing: 1px;
}

.sp-owner {
  margin-top: 10px;
  margin-bottom: 20px;
}

.note {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 30px;
}

.toggle {
  margin-left: 40px;
}

.itemname-width {
  display: inline-block;
  width: 95%;
}

.mb-cell {
  margin-bottom: 8px;
}

.msg-coef {
  margin-left: 50%;
}

.msg-info {
  margin-left: 37%;
}

.radio-range {
  padding: 10px 15px;
  cursor: pointer;
}

.remark-msg {
  width: max-content;
  margin: 30px auto 0px auto;
}

.remark-text {
  font-size: x-large;
  width: max-content;
  margin-top: auto;
  margin-bottom: auto;
  margin: auto 10px;
}

.memo { 
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  overflow-wrap: break-word;
}

.list {
  margin-bottom: 20px;
  min-width: 1010px;
}

.sp-range {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 22px;
}

.max-h {
  height: -webkit-fill-available;
}

.tab{
  width: max-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.tab-w {
  width: 100%;
  height: 30px;
  text-align: center;
  min-width: 68px;
}

.tab-group {
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  margin-bottom: 20px;
}

.note {
  width: 1000px;
}

.remark-table {
  width: 710px;
  margin: auto;
}

.b-right-gray {
  border-right: solid 1px #dfdfe1;
}

.b-top-gray {
  border-top: solid 1px #dfdfe1;
}

.text-orange {
  color: orange;
}

textarea {
  resize: both; /* 手動リサイズを許可 */
  width: 100%;
}

.multiselect >>> .multiselect__tags {
  padding-right: 22px !important;
}

.multiselect >>> .multiselect__select {
  padding-right: 4px !important;
}

.min-w-61px {
  min-width: 61px;
}

.text-commission {
  color: rgb(224 130 130);
}

@keyframes appear {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {
  .cmp-btn {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 1000px) {
  .content-container {
    min-width: 90vw !important;
  }
}

</style>