<template>
	<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
		<div class="flex justify-between">
			<div class="font-bold">※青文字は編集可能</div>
			<!-- 出力ボタン -->
			<div class="">
				<PrimaryButton text="PDF出力" class="m-1" :disabled="loading" @click="pdfBtn()">
					<LoadingIcon v-if="loading" slot="before" class="h-4 w-4" />
					<Icon v-if="!loading" iconName="Printer" slot="before" class="h-4 w-4" />
				</PrimaryButton>
			</div>
		</div>

		<div id="output" style="width: 210mm; padding: 10px">
			<div style="display: flex;">
				<!-- 相手先 -->
				<div style="width: 60%;">
					<!-- 〒 -->
					<div style="display: flex; font-size: 17px;">
						<span style="align-self: center; margin-right: 4px;">〒</span>
						<div v-if="loading" style="align-self: center; margin: 6px 0;">
							{{ info.clientPostalCode }}
						</div>
						<input
							v-else
							placeholder="得意先郵便番号"
							type="tel"
							autocomplete="off"
							class="border-0 rounded text-blue-700 my-1"
							:class="!info.clientPostalCode?'bg-red-100':''"
							v-model="info.clientPostalCode"
						/>
					</div>

					<!-- 住所 -->
					<div style="">
						<div v-if="loading" style="align-self: center; margin-top: 10px; white-space: pre-line; font-size: 17px;">
							{{ info.clientAddress }}
						</div>
						<textarea
							v-else
							placeholder="得意先住所"
							:rows="2"
							class="border-0 rounded text-blue-700 my-1 w-full"
							style="field-sizing: content;"
							:class="!info.clientAddress?'bg-red-100':''"
							v-model="info.clientAddress"
						></textarea>
					</div>

					<!-- 得意先名 -->
					<div style="">
						<div v-if="loading" style="align-self: center; font-weight: bold; font-size: 17px; margin: 15px 0 30px 0; white-space: pre-line;">
							{{ info.clientName }}
						</div>
						<textarea
							v-else
							placeholder="得意先名"
							:rows="2"
							class="border-0 rounded text-blue-700 my-1 w-full"
							style="field-sizing: content;"
							:class="!info.clientName?'bg-red-100':''"
							v-model="info.clientName"
						></textarea>
					</div>

					<!-- 担当者様 -->
					<div style="display: flex; width: 100%; text-align: center; border-bottom: solid; justify-content: center; padding-bottom: 3px; font-size: large; font-weight: bold;">
						<div v-if="loading" style="align-self: center; ">
							{{ info.clientPersonName }}
						</div>
						<input
							v-else
							placeholder="ご担当者"
							type="text"
							autocomplete="off"
							class="border-0 rounded block text-center mr-1 text-blue-700"
							:class="!info.clientPersonName?'bg-red-100':''"
							v-model="info.clientPersonName"
						/>
						<span style="align-self: center; margin-left: 3px;">様</span>
					</div>
				</div>

				<!-- アポロ管財 -->
				<div style="width: 40%;">
					<!-- 日付 -->
					<div style="text-align: right; margin-bottom: 20px;">{{ today }}</div>
					<div style="width: fit-content; margin-left: auto;">
						<div style="font-size: 17px; margin-bottom: 4px;">アポロ管財株式会社</div>
						<div>〒 201-0015</div>
						<div>東京都狛江市猪方3-36-3</div>
						<div>TEL： 03-5438-8804</div>
						<div>FAX： 03-5438-8809</div>
						<div v-if="loading">
							担当： {{ info.taskStaff }}
						</div>
						<div v-else class="flex">
							<div class="my-auto w-fit">担当：</div>
							<input
								type="text"
								autocomplete="off"
								class="border-0 rounded ml-1 text-blue-700 w-fit"
								:class="!info.taskStaff?'bg-red-100':''"
								:size="info.taskStaff?info.taskStaff.length + 2:8"
								v-model="info.taskStaff"
							/>
						</div>
					</div>
				</div>
			</div>

			<!-- 定型文 -->
			<div style="margin-top: 55px;">
				<p>いつも大変お世話になっております。</p>
				<p>この度は清掃業務をご発注頂きまして誠にありがとうございます。</p>
				<p>略儀ではございますが、郵送にて契約書の取り交わしをさせて頂きます。</p>
				<p>ご確認の程よろしくお願い致します。</p>
			</div>

			<!-- 表 -->
			<div style="margin-top: 35px;">
				<table style="border: solid 1px gray; width: 100%; table-layout: auto; border-collapse: collapse;" border="1">
					<thead>
						<tr style="border: solid 1px gray;">
							<th style="border: solid 1px gray;">書類名</th>
							<th style="border: solid 1px gray;">数量</th>
							<th style="border: solid 1px gray;">備考</th>
						</tr>
					</thead>
					<tbody v-if="loading">
						<tr v-for="(row, i) in list" :key="i" style="border: solid 1px gray;">
							<td style="border: solid 1px gray; padding-left: 8px; padding-right: 8px; height: 40px;">{{ row.name }}</td>
							<td style="border: solid 1px gray; width: 100px; padding-left: 8px; padding-right: 8px; text-align: center; height: 40px;">{{ row.qty }}</td>
							<td style="border: solid 1px gray; padding-left: 8px; padding-right: 8px; height: 40px;">{{ row.note }}</td>
						</tr>
					</tbody>
					<tbody v-else>
						<tr v-for="(row, i) in list" :key="i">
							<td style="border: solid 1px gray;">
								<input
									type="text"
									autocomplete="off"
									class="border-0 rounded text-blue-700 w-full"
									v-model="row.name"
								/>
							</td>
							<td style="border: solid 1px gray; width:" class="w-28">
								<input
									type="number"
									autocomplete="off"
									class="border-0 rounded text-blue-700 w-28 text-center"
									:class="row.name&&!row.qty?'bg-red-100':''"
									v-model="row.qty"
								/>
							</td>
							<td style="border: solid 1px gray;">
								<input
									type="text"
									autocomplete="off"
									class="border-0 rounded text-blue-700 w-full"
									v-model="row.note"
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- 定型文 -->
			<div style="margin-top: 40px;">
				<p>お手数お掛け致しますが、収入印紙の貼付とご捺印（印紙割印含め４箇所）をお願い致します。</p>
				<p>収入印紙を貼付した頂いた方を、同封の返信用封筒にてご返送下さい。</p>
				<p></p>
				<p>恐れ入りますがご確認の程よろしくお願い致します。</p>
			</div>
		</div>
	</Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import Icon from '@components/Icon.vue'
import LoadingIcon from '@assets/loading-circle.svg'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import * as moment from 'moment'
// import * as orderManager from '@managers/orderManager'

const ROW = {
	name: '',
	qty: null,
	note: ''
}

export default {
	components: {
		Modal,
		PrimaryButton,
		Icon,
		LoadingIcon,
	},

	props: ['target', 'open'],

	data() {
		return {
			loading: false,
			// 見積りデータ
			info: {},
			// 表
			list: []
		}
	},

	computed: {
		/**
		 * 本日日付
		 */
		today() {
			return moment().format('YYYY年M月D日')
		}
	},

	watch: {
		async open() {
			if (this.open) {
				await this.getData()
			}
		},
	},

	methods: {
		/**
		 * モーダルclose
		 */
		closed() {
			this.loading = false
			this.info = {}
			this.list = []
			this.$emit('close')
		},

		/**
		 * データ取得
		 */
		async getData () {
			this.info = utils.clone(this.target)
			// 読み仮名を消す
			this.info.taskStaff = utils.deleteKana(this.info.taskStaff)
			// 全角に変換
			this.info.clientAddress = utils.zenkaku(this.info.clientAddress1) + '\r\n' + utils.zenkaku(this.info.clientAddress2)
			this.info.clientName = utils.zenkaku(this.info.clientName)
			// 契約書が何枚になるかカウント
			// const page = this.getPageNum(this.info)
			// 初期値は2で良いとのご指示のため、カウントせずに2を入れる
			const page = 2
			// 表を生成
			this.list = []
			this.list.push({ name: this.info.buildingName, qty: page || null, note: '契約書' })
			this.list.push({ name: '返信用封筒', qty: 1, note: '' })
			this.list.push(utils.clone(ROW))
			this.list.push(utils.clone(ROW))
			this.list.push(utils.clone(ROW))
			this.list.push(utils.clone(ROW))
		},

		/**
		 * 契約書のページ数を取得
		 */
		// getPageNum(list) {
		// 	// デフォルトで4枚
		// 	let num = 4
		// 	let other = 0
		// 	for (let i = 0; i < list.details.length; i++) {
		// 		const l = list.details[i]
		// 		const isData = []
		// 		if (l.rowspan > 0) {
		// 			if (!isData.includes(l.categoryCode) && !isData.includes(l.categoryName)) {
		// 				if (('1', '2', '3', '4', '10', '13', '14').includes(l.categoryCode)) {
		// 					isData.push(l.categoryCode)
		// 					num++
		// 					if (l.categoryCode == '2' && l.categoryName.indexOf('クリーン') == -1) {
		// 						// 床面洗浄のシートを加算
		// 						num++
		// 					}
		// 				} else if (('20', '12', '21', '22', '16', '17', '15', '27', '19').includes(l.categoryCode)) {
		// 					isData.push(l.categoryCode)
		// 					other++
		// 				} else if (('汚水槽清掃', '防火設備定期検査', '連結送水管耐圧性能点検').includes(l.categoryName)) {
		// 					isData.push(l.categoryName)
		// 					other++
		// 				}
		// 			}
		// 		}
		// 	}
		// 	if (other > 0) {
		// 		num++
		// 		if (other > 9) {
		// 			num++
		// 		}
		// 	}
		// 	return num
		// },

		/**
		 * PDF出力
		 */
		async pdfBtn() {
			this.loading = true
			await utils.wait(500)
      const html = document.querySelector("#output").outerHTML
      const res = await backend.postBlob2('estimateDownload/pdf', { html })
      try {
        const uint8Array = res.data
        const blob = new Blob([uint8Array], { type : "application/pdf" })
        const url = URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = '送付状_見積No' + this.target.estimateNo + '.pdf'
        a.click()
        a.remove()
        window.open(URL.createObjectURL(blob))
        this.loading = false
				// this.closed()
      } catch (e) {
        console.log(e)
        alert(e)
        this.loading = false
      }
		},
	}
}
</script>
<style scoped>
.main-height1 {
	max-height: 66vh;
}

.main-height2 {
	max-height: 66vh;
}

.main {
	min-width: 700px;
}

.col-no {
	width: 70px;  
}

.min-300 {
	min-width: 300px;
}

.min-70 {
	min-width: 70px;
}

.b-top {
	border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
	background: #ebf3fb;
}

.hover-color:hover {
	background: rgba(183, 238, 236, 0.67);
	cursor: pointer;
}

.mt-27px {
	margin-top: 27px;
}

.mb-5px {
	margin-bottom: 5px;
}

.bg-count {
  background: rgb(230 244 234);
}

.bg-price {
  background: rgb(255, 247, 219);
}

.bg-fast {
  background: rgb(249, 240, 255);
}

@media screen and (max-width: 1200px) {
	.main {
		overflow: auto;
	}
}
</style>