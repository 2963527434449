<template>
  <div class="min-w-1225px">
    <div v-if="loading||sending" class="flex justify-center items-center loading-dialog3">
      <p class="text-gray-400 font-bold animate-pulse" v-if="loading">Loading...</p>
      <p class="text-gray-400 font-bold animate-pulse" v-if="sending">Sending...</p>
    </div>

    <div class="bg-header-blue fixed z-50 w-full h-65px flex justify-end">
      <!-- mailer-daemon用アドレス -->
      <!-- <div v-tooltip="'送信先が間違っていた際にメッセージが届くアドレスです（未入力時は送信元メールに届きます）'" class="mt-1 ml-5 mr-10 w-fit">
        <label class="block text-xs mt-auto text-white opacity-80">
          MAILER-DAEMON用アドレス
        </label>
        <WmsTextInput
          name="daemon"
          class="opacity-80 w-250px"
          v-model="info.daemon"
        />
      </div> -->

      <!-- 一括送信ボタン -->
      <div class="mt-auto mb-2 mr-5 w-fit" v-tooltip="orderStatus=='done'?'ステータスが完了しているため送信できません':''">
        <PrimaryButton
          text="一括送信"
          :disabled="orderStatus=='done'"
          @click="allSendMail()"
          :class="orderStatus=='done'?'bg-gray-400':'bg-blue-500'"
        >
          <Icon slot="before" iconName="Mail" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
        </PrimaryButton>
      </div>
    </div>

    <div class="px-5 pt-70px pb-16">
      <div v-if="!loading && (!purchase || !purchase.length)" class="mt-5 w-full flex justify-center items-center text-gray-400">
        <EmptyMessage iconName="Document" message="対象の発注先がありません。" />
      </div>
      <div v-for="(row, i) in purchase" :key="i" class="mb-5">
        <div class="flex justify-between w-full mb-5 min-h-900px">
          <!-- メール -->
          <div class="w-45per">
            <!-- 開始日がない場合 -->
            <div v-if="!row.startDay || row.startDay == ''" class="text-red-600 text-sm font-bold">
              開始日が指定されていないカテゴリーが含まれています。
            </div>
            <!-- 発注先名がない場合 -->
            <div v-if="!row.supplier || row.supplier == ''" class="text-red-600 text-sm font-bold">
              発注先名が入力されていません。
            </div>
            <!-- 発注先名 -->
            <div class="text-lg font-bold text-blue-600 mb-5">
              {{ row.supplier }}
            </div>
            <!-- 発注先アドレス -->
            <div class="mb-3">
              <div class="flex justify-between">
                <div class="text-xs font-bold mt-auto">メールアドレス
                  <span class="-top-0.5 ml-1 text-red-500"> * </span>
                </div>
                <PrimaryButton
                  text="メール追加"
                  @click="mailAdd(row)"
                  class="h-7 bg-blue-50 text-blue-900 border-blue-900 "
                >
                </PrimaryButton>

              </div>
              <div v-for="(m, j) in row.mail" :key="j">
                <div class="flex">
                  <WmsTextInput
                    name="email" 
                    class="w-full"
                    :error="!m||m==''"
                    v-model="row.mail[j]"
                  />
                  <div class="m-auto ml-2">
                    <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" @click="mailDelete(row, j)"/>
                  </div>
                </div>
              </div>
            </div>
            <!-- 件名 -->
            <div class="mb-3">
              <WmsTextInput
                name="title" 
                caption="件名" 
                :required="true"
                :error="row.title==''"
                v-model="row.title"
              />
            </div>
            <!-- 本文 -->
            <div class="mb-3">
              <div class="flex justify-between">
                <label class="block text-xs font-bold mb-1 mt-auto">
                  本文
                  <span class="-top-0.5 ml-1 text-red-500"> * </span>
                </label>
                <PrimaryButton
                  text="編集"
                  @click="edit(i, row)"
                  class="h-7 bg-blue-50 text-blue-900 border-blue-900 mb-1"
                >
                </PrimaryButton>
              </div>

              <div v-tooltip="'編集する際は「編集」ボタンを押してください'" v-if="!row.edit" v-html="row.content" class="cursor-not-allowed border border-gray-300 h-320px overflow-auto p-3"></div>
              <OrderRichTextInput
                v-else
                name="template" 
                :value="purchase[i].content"
                @change="contentChange($event, i)"
              >
              </OrderRichTextInput>
            </div>

            <!-- 送信ボタン -->
            <div class="text-end mb-3">
              <div class="w-fit ml-auto" v-tooltip="disabledSendBtn(row)">
                <PrimaryButton
                  text="送信"
                  :disabled="disabledSendBtn(row)"
                  :class="disabledSendBtn(row)?'bg-gray-300':''"
                  @click="sendmail(i, row)"
                >
                <Icon slot="before" iconName="Mail" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                </PrimaryButton>
              </div>
            </div>
            
            <!-- 履歴 -->
            <div class="w-full">
              <label class="block text-xs font-bold text-green-700 mb-1">
                送信履歴
              </label>

              <div v-if="row.history && row.history.length" class="h-265px border border-gray-dg rounded overflow-auto">
                <table class="w-full">
                  <thead>
                    <tr>
                      <th v-for="(c, ci) in colums" :key="ci" class="sticky font-normal text-xs py-3 text-green-700 mb-auto" :class="[1, 2, 3].includes(ci)?'text-center':'text-left'">
                        {{ c }}
                      </th>
                    </tr>
                  </thead>
                  <tr v-for="(h, ri) in row.history" :key="ri" class="text-gray-500 text-sm h-35px">
                    <!-- インデックス -->
                    <td class="text-left pl-3">
                      {{ ri + 1 }}.
                    </td>
                    <!-- 送信日時 -->
                    <td class="text-center">
                      {{ h.date }}
                    </td>
                    <!-- 送信者 -->
                    <td class="text-center">
                      {{ h.staff }}
                    </td>
                    <!-- 送信結果 -->
                    <td class="text-center">
                      {{ h.sendStatus }}
                    </td>
                    <!-- 詳細ボタン -->
                    <td class="text-right pr-3">
                      <PrimaryButton
                        v-if="h.sendStatus=='成功'"
                        text="詳細"
                        @click="historyDetail(h)"
                        class="h-7 bg-teal-50 text-teal-900 border-teal-900 mb-1"
                      >
                      </PrimaryButton>
                    </td>
                  </tr>

                </table>
              </div>
              <div v-else class="ml-3 text-xs text-gray-400">
                なし
              </div>
            </div>
          </div>

          <div :id="`temp${i}`" class="w-45per p-2 border border-gray-200 rounded overflow-auto min-h-900px max-h-90vh">
            <!-- 添付内容 ※PDFに変換するため、cssは全てstyleで書いています※ -->
            <PurchaseTemp
              :purchase="row"
              :info="info"
            />
          </div>
        </div>
        <div v-if="i+1!=purchase.length || (history && Object.keys(history).length)" class="w-full boder border-b border-4 border-blue-900"></div>
      </div>

      <!-- 履歴のみの発注先 -->
      <div v-if="history && Object.keys(history).length">
        <div class="text-sm font-bold text-gray-700">
          ここから下は、現在の受注にはない発注先で履歴だけがあるものです。
        </div>
        
        <div v-for="(h, hIdx) in Object.keys(history)" :key="hIdx">
          <div class="text-lg font-bold text-teal-600 mt-5 mb-3">
            {{ h }}
          </div>
          <div class="h-265px w-45per border border-gray-dg rounded overflow-auto">
            <table class="w-full">
              <thead>
                <tr>
                  <th v-for="(c, ci) in colums" :key="ci" class="sticky font-normal text-xs py-3 text-green-700 mb-auto" :class="[1, 2, 3].includes(ci)?'text-center':'text-left'">
                    {{ c }}
                  </th>
                </tr>
              </thead>
              <tr v-for="(hd, ri) in history[h]" :key="ri" class="text-gray-500 text-sm h-35px">
                <!-- インデックス -->
                <td class="text-left pl-3">
                  {{ ri + 1 }}.
                </td>
                <!-- 送信日時 -->
                <td class="text-center">
                  {{ hd.date }}
                </td>
                <!-- 送信者 -->
                <td class="text-center">
                  {{ hd.staff }}
                </td>
                <!-- 送信結果 -->
                <td class="text-center">
                  {{ hd.sendStatus }}
                </td>
                <!-- 詳細ボタン -->
                <td class="text-right pr-3">
                  <PrimaryButton
                    v-if="hd.sendStatus=='成功'"
                    text="詳細"
                    @click="historyDetail(hd)"
                    class="h-7 bg-teal-50 text-teal-900 border-teal-900 mb-1"
                  >
                  </PrimaryButton>
                </td>
              </tr>
            </table>

          </div>
        </div>

      </div>
    </div>
    <!-- 履歴 -->
    <PurchaseHistoryModal 
      v-model="historyModal"
      :historyNo="historyNo"
      :open="historyModal"
      @modalClose="historyModalClose"
    />
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import PurchaseTemp from '../components/PurchaseTemp.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import OrderRichTextInput from '../../Order/components/OrderRichTextInput.vue'
import BigNumber from "bignumber.js"
import PurchaseHistoryModal from '../components/PurchaseHistoryModal.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import { TrashIcon } from '@vue-hero-icons/solid'
import * as purchaseManager from '@managers/purchaseManager'
import * as estimateManager from '@managers/estimateManager'
import * as taskRequestManager from '@managers/taskRequestManager'
import * as logManager from '@managers/logManager'
import * as dialogs from '@libs/dialogs'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import * as moment from 'moment'
import * as constants from '@libs/constants'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    Icon,
    PrimaryButton,
    PurchaseTemp,
    WmsTextInput,
    OrderRichTextInput,
    PurchaseHistoryModal,
    EmptyMessage,
    TrashIcon
  },
  
  data() {
    return {
      // 発注書のデータ
      purchase: [],
      // 基本データ
      info: {},
      // 履歴表ヘッダ
      colums: ['', '送信日時', '送信者', '送信結果'],
      // 件名デフォルト
      title: '【発注書】',
      // 発注履歴
      history: {},
      // 発注履歴数
      historyLength: 0,
      // 受注に対して1回目の送信か
      firstTime: false,
      // メアドが正しい形か
      resultAddress: [],
      // ローディング中
      loading: false,
      // 送信中
      sending: false,
      // 履歴詳細モーダルオープン
      historyModal: false,
      // 履歴詳細モーダル表示対象
      historyNo: '',
      // 明細はないが履歴だけある発注先
      onlyHistoryList: [],
      // 受注メインのステータス
      orderStatus: false,
      // 受注ID
      orderId: null
    }
  },

  async created() {
    this.loading = true
    const PURCHASE_ORDER = sessionStorage.getItem('PURCHASE_ORDER')
    const order = JSON.parse(PURCHASE_ORDER)
    await this.getHistory(order.orderNo)
    await this.initialize(order)
    this.orderId = order._id
    this.loading = false
  },

  methods: {
    /**
     * 履歴取得
     * @param orderNo 受注番号
     */
    async getHistory(orderNo) {
      this.historyLength = 0
      const res = await purchaseManager.getHistoryByOrderNo(this, orderNo)
      if (res && res.length) {
        // 発注先ごとにまとめる
        let h = {}

        for (let i = 0; i < res.length; i++) {
          const re = res[i]
          let row = {
            date: re.date,
            staff: re.staff,
            purchaseNo: re.purchaseNo,
            sendStatus: re.sendStatus,
            historyNo: re.historyNo
          } 

          if (h[re.supplier]) {
            h[re.supplier].push(row)
          } else {
            h[re.supplier] = [row]
          }

          // 発注先マスタに紐づかなかった（Pで終わっている発注番号を見つける）
          if (re.purchaseNo.slice(-1) == 'P') {
            let num = re.purchaseNo.slice(re.purchaseNo.indexOf('-')+1, re.purchaseNo.length-1)
            if (this.historyLength < Number(num)) {
              this.historyLength = Number(num)
            }
          }
        }

        this.history = h
      } else {
        this.firstTime = true
      }
    },

    /**
     * 発注データ取得
     * @param o 受注データ
     */
    async initialize(o) {
      if (o) {
        // 発注先マスタ取得
        const master = await estimateManager.getPurchase(this)

        // 区分
        let classification = ''
        if (o.classification && o.classification.length && o.classification[0]) {
          switch (o.classification[0]) {
            case '1':
              classification = '新規'
              break;
          
            case '4':
              classification = '追加'
              break;
          
            case '5':
              classification = '変更'
              break;
          
            case '3':
              classification = 'スポット'
              break;
          
            default:
              break;
          }
        }
        // ステータス
        this.orderStatus = o.status

        // 基本情報
        this.info = {
            // 物件名
            site: o.site
            // 物件住所
          , siteAddress: o.siteAddress
            // 得意先
          , client: o.clientName
            // 送信者ID
          , staffId: this.$store.getters.user.id
            // 送信者名
          , staff: utils.deleteKana(this.$store.getters.user.user_name)
            // 受注番号
          , orderNo: o.orderNo
            // リクエストNo
          , requestNo: o.requestNo
            // 本日日付
          , sendDate: moment().format('YYYY 年 MM 年 DD 日')
            // 区分
          , classification
            // MAILER-DAEMON先のアドレス
          // , daemon: this.$store.getters.loginUserData.eMailAdress
        }
        // 発注書に必要なデータを生成
        let detail = []
        
        // 見積明細カテゴリのコードから入力されたカテゴリを取得
        const code = o.estimationDetailsCategoryCode

        // 新受注データ（すべて配列）
        if (o.orderNew) {
          const propSet = constants.PROPSET
          for (let i = 0; i < propSet.length; i++) {
            const p = propSet[i]
            if (o[p.val] && o[p.val].length) {
              for (let j = 0; j < o[p.val].length; j++) {
                const op = o[p.val][j]
                detail = await this.setDetailNew(op, detail)
              }
            }
          }
          
        // 古い受注データ
        } else {
          // ラウンドサービス
          if (code.includes('1')) {
            detail = this.setDetail(o, 'ラウンドサービス', 'estimationRound', true, '1', detail)
          }
          // ラウンドプラス
          if (code.includes('2')) {
            let plus = 'ラウンドプラス'
            // 発注明細が新仕様の場合
            if (o.estimationRoundPlusPurchase && o.estimationRoundPlusPurchase.length) {
              detail = this.setDetailForRlsPlus(o.estimationRoundPlusPurchase, o.estimationRoundPlusStartDate, o.rlsPlusNum, detail)
            } else {
              if ( o.estimationRoundPlusCategorySelect &&  o.estimationRoundPlusCategorySelect.length) {
                plus =  o.estimationRoundPlusCategorySelect[0]
              }
              detail = this.setDetail(o, plus, 'estimationRoundPlus', true, '2', detail)
            }
          }
          // 日常清掃
          if (code.includes('3')) {
            detail = this.setDetail(o, '日常清掃', 'estimationEveryday', true, '3', detail)
          }
          // 管理員業務
          if (code.includes('4')) {
            detail = this.setDetail(o, '管理員業務', 'managerWork', true, '4', detail)
          }
          // 定期清掃
          if (code.includes('5')) {
            detail = this.setDetailFromEstimate(o.orderRegularCleans, o.fixed, 'regularClean', detail)
          }
          // フロントサポート
          if (code.includes('6')) {
            detail = this.setDetail(o, 'フロントサポート', 'frontSupport', true, '6', detail)
          }
          // フロントサポートプラス
          if (code.includes('7')) {
            detail = this.setDetail(o, 'フロントサポートプラス', 'frontSupportPlus', true, '7', detail)
          }
          // ラウンドトラッシュ
          if (code.includes('8')) {
            detail = this.setDetail(o, 'ラウンドトラッシュ', 'roundTrash', true, '8', detail)
          }
          // クオリティレポート
          if (code.includes('9')) {
            detail = this.setDetail(o, 'クオリティレポート', 'qualityReport', false, '9', detail)
          }
          // ガラス清掃
          if (code.includes('10')) {
            detail = this.setDetail(o, 'ガラス清掃', 'glassClean', false, '10', detail)
          }
          // 排水管清掃
          if (code.includes('11')) {
            detail = this.setDetail(o, '排水管清掃', 'estimationDorainPipe', false, '11', detail)
          }
          // 貯水槽清掃
          if (code.includes('12')) {
            detail = this.setDetail(o, '貯水槽清掃', 'estimationWaterTank', false, '12', detail)
          }
          // 植栽剪定
          if (code.includes('13')) {
            detail = this.setDetail(o, '植栽剪定', 'planting', false, '13', detail)
          }
          // 特別清掃
          if (code.includes('14')) {
            detail = this.setDetailFromEstimate(o.orderSpecialCleans, o.sp, 'specialClean', detail)
          }
          // 消防設備点検
          if (code.includes('15')) {
            detail = this.setDetail(o, '消防設備点検', 'fireInspect', false, '15', detail)
          }
          // 建築設備定期検査
          if (code.includes('16')) {
            detail = this.setDetail(o, '建築設備定期検査', 'constructionEquipmentInspect', false, '16', detail)
          }
          // 特定建築物定期調査
          if (code.includes('17')) {
            detail = this.setDetail(o, '特定建築物定期調査', 'specificInspect', false, '17', detail)
          }
          // 共用部設備点検
          if (code.includes('18')) {
            detail = this.setDetail(o, '共用部設備点検', 'commonAreaFacilityInspection', false, '18', detail)
          }
          // エレベーター保守点検
          if (code.includes('19')) {
            detail = this.setDetail(o, 'エレベーター保守点検', 'evInspect', false, '19', detail)
          }
          // 増圧ポンプ点検
          if (code.includes('20')) {
            detail = this.setDetail(o, '増圧ポンプ点検', 'pressPumpInspect', false, '20', detail)
          }
          // 給排水ポンプ点検
          if (code.includes('21')) {
            detail = this.setDetail(o, '給排水ポンプ点検', 'waterSupplyDrainagePumpInspection', false, '21', detail)
          }
          // 簡易専用水道
          if (code.includes('22')) {
            detail = this.setDetail(o, '簡易専用水道', 'simpleWaterSupply', false, '22', detail)
          }
          // 管球対応
          if (code.includes('23')) {
            detail = this.setDetail(o, '管球対応', 'tubeBulbSupport', false, '23', detail)
          }
          // 粗大ゴミ対応
          if (code.includes('24')) {
            detail = this.setDetail(o, '粗大ゴミ対応', 'bulkyWasteSupport', false, '24', detail)
          }
          // 物品販売
          if (code.includes('25')) {
            detail = this.setDetail(o, '物品販売', 'merchandiseSales', false, '25', detail)
          }
          // 緊急対応
          if (code.includes('26')) {
            detail = this.setDetail(o, '緊急対応', 'emergencyResponse', false, '26', detail)
          }
          // 防火対象物点検
          if (code.includes('27')) {
            detail = this.setDetail(o, '防火対象物点検', 'firePreventionObjectInspection', false, '27', detail)
          }
          // 汚水槽清掃
          if (code.includes('101')) {
            detail = this.setDetail(o, '汚水槽清掃', 'sewageTankCleaning', false, '101', detail)
          }
          // 防火設備定期検査
          if (code.includes('102')) {
            detail = this.setDetail(o, '防火設備定期検査', 'fireProtectionInspection', false, '102', detail)
          }
          // 連結送水管耐圧性能点検
          if (code.includes('103')) {
            detail = this.setDetail(o, '連結送水管耐圧性能点検', 'connectingWaterPipePressureInspection', false, '103', detail)
          }
          // スポット清掃
          if (o.orderSpot && o.orderSpot.length) {
            o.orderSpot.forEach(os => {
              detail = this.setDetailForArr(os, os.name, false, '28', detail)
            })
          }
          // カテゴリコードがないもの
          if (o.orderOthers && o.orderOthers.length) {
            o.orderOthers.forEach(oo => {
              detail = this.setDetailForArr(oo, oo.name, false, 'none', detail)
            })
          }
        }


        let sup = []
        let res = []
        for (let i = 0; i < detail.length; i++) {
          const de = detail[i]

          // 同じ発注先がある場合
          if (sup.includes(de.supplier)) {
            for (let j = 0; j < res.length; j++) {
              const r = res[j]
              if (r.supplier == de.supplier) {
                // 開始日は早い方を採用
                r.startDay = this.getStartDay(r.startDay, de.startDay)
                // 発注金額(税抜き)
                r.withoutTax += de.amount
                // 消費税分
                r.tax = this.calcTax(r.withoutTax)
                // 発注金額（税込み）
                r.amount = r.withoutTax + r.tax
                // 発注内容
                r.detail.push(this.setDetailRow(de))
              }
            }

          // 同じ発注先がない場合
          } else {
            sup.push(de.supplier)
            // 入力された発注先名と紐づく発注先データをマスタから取得
            let val = this.getPurchaseMst(de.supplier, master)

            let row = {
              // 発注No
              purchaseNo: '',
              // 発注先コード
              purchaseCode: val.purchaseCode,
              // 発注先
              supplier: de.supplier,
              // 発注書記載社名
              purchaseOrderName: val.purchaseOrderName,
              // メアド
              mail: val.mail,
              // 担当者
              pic: val.pic,
              // 開始日
              startDay: de.startDay,
              // 発注金額(税抜き)
              withoutTax: de.amount,
              // 発注内訳
              detail: [this.setDetailRow(de)]
            }
            // 消費税分
            row.tax = this.calcTax(row.withoutTax)
            // 発注金額（税込み）
            row.amount = row.withoutTax + row.tax
            res.push(row)
          }
        }

        // 空行を挿入
        for (let i = 0; i < res.length; i++) {
          const ope = res[i]
          for (let j = ope.detail.length; j < 16; j++) {
            const emp = {
              category: '',
              num: '',
              price: '',
              amount: ''
            }
            ope.detail.push(emp)
          }
        }

        // 最終行を挿入
        for (let i = 0; i < res.length; i++) {
          const pur = res[i]

          // 件名
          pur.title = this.title + this.info.site
          // 本文
          let name = pur.purchaseOrderName
          if (!name || name == '') {
            name = pur.supplier
          }
          let mailTxt = '<br>'
          // if (temp.indexOf('★お客様宛') != -1) {
          //   temp = o.template.substr(o.template.indexOf('★お客様宛') + 9)
          // }
          pur.content = `<p>${name} 御中<br>${pur.pic}</p><br>`
          pur.content += `<p>いつも大変お世話になっております。<br>発注書を送らせて頂きますのでよろしくお願い致します。</p>`
          pur.content += mailTxt
          pur.content += `<br><p> ※こちらのメールは送信専用となっております。<br>ご連絡の際はアポロ管財 ${utils.getSurname(this.info.staff)}までお願いいたします。<br>TEL.03-5438-8804（代）</p>`
          pur.content += `<p>アポロ管財株式会社<br>${utils.getSurname(this.info.staff)}</p>`

          pur.detail.push({
            category: `消費税額 （課税対象額：${utils.numberToLocale(pur.withoutTax)}）`,
            num: '',
            price: '',
            amount: pur.tax
          })
          pur.detail.push({
            category: '合　　　計',
            num: '',
            price: '',
            amount: pur.amount
          })

          // 履歴
          if (this.history && this.history[pur.supplier]) {
            pur.history = this.history[pur.supplier]
            delete this.history[pur.supplier]
          }
          if (!pur.history) {
            pur.history = []
          }

          // 受注番号から先頭の2文字とハイフンを削除
          let orderNo = o.orderNo.substr(2).replace('-', '')
          // 履歴数+インデックス+1
          let history = pur.history.length + 1
          // 発注コード
          let code = ''
          // 発注コードある場合
          if (pur.purchaseCode && pur.purchaseCode != '') {
            code = `${pur.purchaseCode}-${history}`

          // 発注コードなしの場合
          } else {
            let index = this.historyLength + 1
            code = String(index) + 'P'
            this.historyLength++
          }
          // 発注番号生成
          pur.purchaseNo = `${orderNo}-${code}`

          this.resultAddress.push(false)
        }
        // 発注内訳
        this.purchase = res
      }
    },

    /**
     * 発注内訳のもととなるデータを生成(新受注データ)
     * @param o 受注データ
     * @param detail 生成データ
     */
    async setDetailNew(o, detail) {
      if (o.supplier && !this.judgeSupplier(o.supplier)) {
        return detail
      }
      // 開始日
      let startDay = ''
      // 作業月・開始月（発注書の内容に記載する内容）
      let month = ''

      // 日常・管理の場合
      if (['3', '4'].includes(o.categoryCode)) {
        for (let i = 0; i < o.details.length; i++) {
          const det = o.details[i]
          // 日常・管理の場合
          // 初期値セット
          if (i == 0) {
            startDay = det.startDate
          }
          // 一番早い開始日をセット
          startDay = this.getStartDay(startDay, det.startDate)
        }
      
      // RLSプラスの場合
      } else if (o.categoryCode == '2') {
        return this.setDetailNew3(o, detail)

      // 定期の場合
      } else if (['5'].includes(o.categoryCode)) {
        return await this.setDetailNew2(o, detail)
        
      // 特別の場合
      } else if (o.categoryCode == '14') {
        // 新仕様の場合
        if (o.newData) {
          return this.setDetailNew4(o, detail)
        } else {
          return await this.setDetailNew2(o, detail)
        }
      
      // 開始日がある場合
      } else if (o.startDate) {
        startDay = o.startDate
        month = '（初回 ' + moment(o.startDate).format('YYYY年M月D日') + '）'
        // スポットの場合は、初回を削除
        if (this.info.classification == 'スポット') {
          month = month.replace('初回 ', '')
        }
        
      // 開始年、開始月がある場合
      } else if (o.firstWorkYear && o.firstWorkMonth) {
        startDay = this.generateStartDay(o.firstWorkYear, o.firstWorkMonth)
        month = this.getWorkMonth(o.firstWorkYear, o.firstWorkMonth, o.implementationMonth)
      }

      let row = {
        // 発注先
          supplier: o.supplier
        // 開始日
        , startDay
        // 内容
        , category: o.name + month
        // 回数
        , num: o.count
        // 単価
        , price: o.orderPrice
        // カテゴリコード
        , code: o.categoryCode
      }
      // 契約金額 ＝ 回数 × 単価
      if (!isNaN(row.price) && !isNaN(row.num)) {
        row.amount = row.num * Number(row.price)
      } else {
        row.amount = 0
      }
      detail.push(row)
      return detail
    },

    /**
     * 発注内訳の元となるデータを生成（新受注データ 定期・特別）
     * @param tar 受注データ
     * @param detail 生成データ
     */
    async setDetailNew2(tar, detail) {
      const t = utils.clone(tar)

      // 発注率
      let purRate = t.purchaseRate
      if (!isNaN(t.purchaseRate)) {
        purRate = Number(t.purchaseRate)
      }
      // 手数料率
      let comRate = 0
      if (!isNaN(t.commissionRate)) {
        comRate = Number(t.commissionRate)
      }

      let total = 0
      for (let j = 0; j < t.details.length; j++) {
        const det = t.details[j]
        let row = {}

        let name = t.name
        if (t.categoryCode == 14) {
          name = det.content
        }

        // 発注先名
        row.supplier = t.supplier
        // カテゴリー名
        row.category = name + this.getWorkMonth(det.firstWorkYear, det.firstWorkMonth, det.implementationMonth)
        // カテゴリーコード
        row.code = t.categoryCode
        // グループネーム
        row.groupName = t.groupName
        // 開始日
        row.startDay = this.generateStartDay(det.firstWorkYear, det.firstWorkMonth)
        // 回数
        row.num = det.count
        // 単価
        row.price = 0

        // 受注単価（手数料込）
        let estimatePrice = 0
        if (!isNaN(det.unitPrice)) {
          estimatePrice = Number(det.unitPrice)
        }

        // 詳細が複数ある場合
        if (t.details.length >= 2) {
          // 発注金額を再計算する
          // 受注単価も手数料率もあった場合、手数料抜きを計算
          if (estimatePrice && comRate) {
            let rate1 = BigNumber(comRate).div(100)
            rate1 = BigNumber(1).minus(rate1)
            estimatePrice = Math.floor(BigNumber(estimatePrice).times(rate1))
          }
          // 発注率から発注金額を求める
          if (estimatePrice && purRate) {
            let rate2 = BigNumber(purRate).div(100)
            estimatePrice = Math.floor(BigNumber(estimatePrice).times(rate2))
          } else {
            estimatePrice = 0
          }
          row.price = estimatePrice
          total += estimatePrice

        // 詳細が1つしかない場合は再計算せずに発注金額のまま
        } else {
          row.price = t.orderPrice
        }

        // 契約金額 ＝ 回数 × 単価
        if (!isNaN(row.price) && !isNaN(row.num)) {
          row.amount = row.num * Number(row.price)
        } else {
          row.amount = 0
        }
        detail.push({...row})
      }
      if (total) {
        let dif = Number(t.orderPrice) - total
        if (!isNaN(dif)) {
          dif = Math.abs(dif)
          if (dif > 3000) {
            this.loading = false
            await dialogs.showErrorDialog(t.supplier + 'の' + t.name + ' の単価をご確認ください', '単価が再計算されましたが、見積りの発注金額と' + dif + '円の差があります。')
            
          }
        }
      }
      this.loading = true
      return detail
    },

    /**
     * 発注内訳の元となるデータを生成（新受注データ 特別清掃）
     * @param tar 受注データ
     * @param detail 生成データ
     */
    setDetailNew4(tar, detail) {
      const t = utils.clone(tar)

      // 調整費があるか確認
      let discount = false
      for (let i = 0; i < t.details.length; i++) {
        const row = t.details[i]
        if (row.content && row.content.indexOf('調整費') > -1) {
          discount = true
          break
        }
      }

      for (let j = 0; j < t.details.length; j++) {
        const det = t.details[j]
        // 調整費は省く
        if (det.content.indexOf('調整費') > -1) {
          continue
        }
        let row = {}

        let name = det.content
        name = this.deleteMonthlyFee(name)

        let num = '0'
        let price = 0
        if (j == 0) {
          num = t.count
          price = t.orderPrice
        }

        // 発注先名
        row.supplier = t.supplier
        // カテゴリー名
        row.category = name + this.getWorkMonth(det.firstWorkYear, det.firstWorkMonth, det.implementationMonth)
        // カテゴリーコード
        row.code = t.categoryCode
        // グループネーム
        row.groupName = t.groupName
        // 開始日
        row.startDay = this.generateStartDay(det.firstWorkYear, det.firstWorkMonth)
        // 回数
        row.num = num
        // 単価
        row.price = price
        // セル結合用rowspan
        let rowspan = det.rowspan
        // 調整費がある場合、rowspanをマイナス1する
        if (discount && rowspan && rowspan > 0) {
          rowspan--
        }
        row.rowspan = rowspan

        // 契約金額 ＝ 回数 × 単価
        if (!isNaN(row.price) && !isNaN(row.num)) {
          row.amount = row.num * Number(row.price)
        } else {
          row.amount = 0
        }
        detail.push({...row})
      }
      return detail
    },

    /**
     * 発注内訳の元となるデータを生成（新受注データ RLSプラス）
     * @param o 受注データ
     * @param detail 生成データ
     */
    setDetailNew3(o, detail) {
      for (let i = 0; i < o.purchase.length; i++) {
        const p = o.purchase[i]
        if (p.supplier && !this.judgeSupplier(p.supplier)) {
          continue
        }
        let category = o.content
        if (p.typeName) {
          category = p.typeName
        }

        let month = ''
        let startDay = null
        // 定期清掃以外の場合（定期清掃以外は自社発注なのでありえないが一応）
        if (category.indexOf('定期') == -1 && o.startDate) {
          month = '（初回 ' + moment(o.startDate + ' 00:00:00').format('YYYY年M月D日') + '）'
          startDay = o.startDate

        // 定期清掃の場合
        } else if (category.indexOf('定期') > -1 && o.firstWorkYear && o.firstWorkMonth && o.firstWorkMonth.length) {
          const day = this.generateStartDay(o.firstWorkYear, o.firstWorkMonth)
          month = '（初回 ' + moment(day).format('YYYY年M月') + '）'
          startDay = this.generateStartDay(o.firstWorkYear, o.firstWorkMonth) 
        }

        if (month) {
          // スポットの場合は、初回を削除
          if (this.info.classification == 'スポット') {
            month = month.replace('初回 ', '')
          }
        }

        let row = {
          // 発注先
            supplier: p.supplier
          // 開始日
          , startDay
          // 内容
          , category: category + month
          // 回数
          , num: o.count
          // 単価
          , price: p.price
          // カテゴリコード
          , code: o.categoryCode
        }
        // 契約金額 ＝ 回数 × 単価
        if (!isNaN(row.price) && !isNaN(row.num)) {
          row.amount = row.num * Number(row.price)
        } else {
          row.amount = 0
        }
        detail.push(row)
      }
      return detail
    },

    /**
     * 発注内訳の元となるデータを生成
     * @param o 受注データ
     * @param category 内容
     * @param property プロパティ名の共通部分
     * @param isDay 開始日が日付の形をしているか
     * @param code カテゴリコード
     * @param detail 生成データ
     */
    setDetail(o, category, property, isDay, code, detail) {
      // 日常または管理員の場合
      let normalOrMng = false
      if (['3', '4'].includes(code)) {
        normalOrMng = true
      }

      // 	フロントサポート、フロントサポートプラス、ラウンドトラッシュ、クオリティレポートどれかの場合
      let support = false
      if (['6', '7', '8', '9'].includes(code)) {
        support = true
      }

      // 発注先がアポロ管財の場合は内訳不要
      let sup = ''
      // ラウンドプラスの場合(過去の発注明細データ)
      if (code == '2') {
        for (let i = 0; i < o.estimationRoundPlusSupplier.length; i++) {
          const rps = o.estimationRoundPlusSupplier[i].supplier
          // 現状だと、ラウンドプラスの外注先は1社のみなので、最初に出てきた外注先とする
          if (this.judgeSupplier(rps)) {
            sup = rps
            break
          }
        }
        if (sup == '') {
          return detail
        }
      // ラウンドプラス以外
      } else {
        if (this.judgeSupplier(o[`${property}Supplier`])) {
          sup = o[`${property}Supplier`]
        } else {
          return detail
        }
      }

      
      let num = null
      // 12固定（基本、発注明細は作成されない）
      if (normalOrMng || support || ['1', '2'].includes(code)) {
        num = 12

      // 回数が配列になっている場合
      } else if (Array.isArray(o[`${property}Count`])) {
        if (o[`${property}Count`] && o[`${property}Count`].length) {
          num = Number(o[`${property}Count`][0].replace(/週 | 月 | 回/g, '').replace(/ /g, ''))
        }

      // 文字列の場合
      } else {
        num = Number(o[`${property}Count`])
      }

      let startDay = ''
      let workMonth = ''
      // 開始日
      // 日常・管理の場合は、早い方の開始日にする
      if (normalOrMng) {
        startDay = this.getStartDayForArr(o, property)
      
      // 開始日がある場合
      } else if (isDay) {
        startDay = o[`${property}StartDate`]
        workMonth = o[`${property}StartDate`]
        if (workMonth) {
          workMonth = moment(workMonth).format('YYYY年M月D日')
          if (workMonth && this.info.classification != 'スポット') {
            workMonth = '初回 ' + workMonth
          }

          // 作業月がある場合
          if (o[`${property}ImplementationMonth`] && o[`${property}ImplementationMonth`].length) {
            let wm = o[`${property}ImplementationMonth`].join('・').replace(/月/g, '') + '月'
            if (workMonth) {
              workMonth = '：' + workMonth
            }
            workMonth = wm + workMonth
          }

          if (workMonth) {
            workMonth = '（ ' + workMonth + ' ）'
            category += '　' + workMonth
          }
        }

      // 初回作業年、月しかない場合
      } else if (!isDay) {
        // 建築設備定期検査は、初回作業年のみプロパティ名が違うので、別に取得
        if (property == 'constructionEquipmentInspect') {
          startDay = this.generateStartDay(o[`constructionEquipmentFirstWorkYear`], o[`${property}FirstWorkMonth`])
          category += this.getWorkMonth(o[`constructionEquipmentFirstWorkYear`], o[`${property}FirstWorkMonth`], o[`${property}ImplementationMonth`])

        } else {
          startDay = this.generateStartDay(o[`${property}FirstWorkYear`], o[`${property}FirstWorkMonth`])
          category += this.getWorkMonth(o[`${property}FirstWorkYear`], o[`${property}FirstWorkMonth`], o[`${property}ImplementationMonth`])
        }
      }

      let row = {
        // 発注先
          supplier: sup
        // 開始日
        , startDay
        // 内容 + 作業月など
        , category
        // 回数
        , num
        // 単価
        , price: o[`${property}Price`]
        // カテゴリコード
        , code
      }
      // 契約金額 ＝ 回数 × 単価
      if (!isNaN(row.price)) {
        row.amount = row.num * Number(row.price)
      } else {
        row.amount = 0
      }
      detail.push(row)
      return detail
    },

    setDetailForRlsPlus(target, startDay, num, detail) {
      for (let i = 0; i < target.length; i++) {
        const t = target[i]
        if (this.judgeSupplier(t.supplier)) {

          let row = {
            // 発注先
              supplier: t.supplier
            // 開始日
            , startDay
            // 内容
            , category: t.typeName
            // 回数
            , num
            // 単価
            , price: t.price
            // カテゴリコード
            , code: '2'
          }
          // 契約金額 ＝ 回数 × 単価
          if (!isNaN(row.price)) {
            row.amount = row.num * Number(row.price)
          } else {
            row.amount = 0
          }
          detail.push(row)
        }
      }
      return detail
    },

    /**
     * 発注内訳の元となるデータを生成_定期清掃
     * @param o 受注データ
     * @param target 対象データ
     * @param property プロパティー
     * @param detail 詳細データ
     */
    setDetailFromEstimate(o, target, property, detail) {
      // 見積りデータから取得したデータ
      for (let i = 0; i < target.length; i++) {
        const t = target[i]
        if (this.judgeSupplier(t.supplier)) {
          // 発注率
          let rate = BigNumber(t.rate).div(100)
          // 発注単価 ＝ 受注 × 率
          let price = t.price
          if (!price) {
            price = Math.floor(BigNumber(t.estimatePrice).times(rate))
          }

          let category = t.category
          category += this.getWorkMonth(o[i][`${property}FirstWorkYear`], o[i][`${property}FirstWorkMonth`], o[i][`${property}ImplementationMonth`])

          let row = {
            // 発注先
              supplier: t.supplier
            // 開始日
            , startDay: this.generateStartDay(o[i][`${property}FirstWorkYear`], o[i][`${property}FirstWorkMonth`])
            // 内容 + 作業月など
            , category
            // 回数
            , num: t.num
            // 単価
            , price
            // カテゴリコード
            , code: t.code
          }
          // 契約金額 ＝ 回数 × 単価
          if (!isNaN(row.price)) {
            row.amount = row.num * Number(row.price)
          } else {
            row.amount = 0
          }
          detail.push(row)
        }
      }
      return detail
    },

    /**
     * 発注内訳の元となるデータを生成_配列になっているカテゴリ用
     * @param target 対象受注データ
     * @param category 内容
     * @param isDay 開始日が日付の形をしているか
     * @param code カテゴリコード
     * @param detail 詳細データ
     */
    setDetailForArr(target, category, isDay, code, detail) {
      let supplier = ''
      if (this.judgeSupplier(target['supplier'])) {
        supplier = target['supplier']
      } else {
        return detail
      }

      // 回数を数字に変換
      let num = Number(target['count'])

      let startDay = null
      // 開始日
      if (isDay && target['startDay'] && target['startDay'] != '') {
        startDay = target['startDay']

      // 初回作業年、月しかない場合
      } else if (!isDay) {
        startDay = this.generateStartDay(target['firstWorkYear'],  target['firstWorkMonth'])
      }
      category += this.getWorkMonth(target['firstWorkYear'], target['firstWorkMonth'], target['implementationMonth'])

      let row = {
        // 発注先
          supplier
        // 開始日
        , startDay
        // 内容
        , category
        // 回数
        , num
        // 単価
        , price: target['orderPrice']
        // カテゴリコード
        , code
      }
      // 契約金額 ＝ 回数 × 単価
      if (!isNaN(row.price)) {
        row.amount = row.num * Number(row.price)
      } else {
        row.amount = 0
      }
      detail.push(row)
      return detail
    },

    /**
     * 作業月等を生成
     * @param year 初回年
     * @param month 初回月
     * @param imp 作業月
     */
    getWorkMonth(year, month, imp) {
      let workMonth = year + month
      if (workMonth && this.info.classification != 'スポット') {
        workMonth = '初回 ' + workMonth
      }
      
      // 作業月がある場合
      if (imp && imp.length) {
        let imp2 = imp.map(im => {
          im = im.replace(/月/g, '')
          im = ('00' + im).slice(-2)
          return im
        })
        // 昇順に並べ替え
        imp2 = imp2.sort()
        let imp3 = imp2.map(im => {
          return Number(im)
        })
        let wm = imp3.join('・') + '月'
        if (workMonth) {
          workMonth = '：' + workMonth
        }
        workMonth = wm + workMonth
      }

      if (workMonth) {
        workMonth = '（ ' + workMonth + ' ）'
        workMonth = '　' + workMonth
        return workMonth
      } else {
        return ''
      }
    },

    /**
     * 消費税算出
     */
    calcTax(val) {
      return Math.floor(BigNumber(val).times(0.1))
    },

    /**
     * 作業開始年・月から開始日を生成
     * @param year 年
     * @param month 月
     */
    generateStartDay(year, month) {
      if (!year || year == '') {
        return null
      }
      if (!month || !month.length || month[0] == '') {
        return null
      }
      let y = year.replace('年', '')
      let m = month[0].replace('月', '')
      let d = `${y}-${m}-01`
      return moment(d).format('YYYY-MM-DD')
    },

    /**
     * 早い方の開始日を取得
     * @param val1 日付
     * @param val2 比較対象日付
     * @param target 比較対象データ
     */
    getStartDay(val1, val2) {
      if (!val1 || val1 == '' || val1 == 'Invalid date') {
        return ''
      }
      if (!val2 || val2 == '' || val2 == 'Invalid date') {
        return ''
      }

      // 比較しやすいように日付を数値に変換
      let v1 = Number(moment(val1).format('YYYYMMDD'))
      let v2 = Number(moment(val2).format('YYYYMMDD'))
      if (v1 >= v2) {
        return val2
      } else {
        return val1
      }
    },

    /**
     * 日常、管理用の開始日取得
     * @param o 受注データ
     * @param property プロパティ名
     */
    getStartDayForArr(o, property) {
      let tar = ''
      let sDay = ''

      // 日常
      if (property == 'estimationEveryday') {
        tar = 'orderEstimationEverydays'
        sDay = 'everydayStartDate'
      }
      // 管理
      if (property == 'managerWork') {
        tar = 'orderManagerWorks'
        sDay = 'managerWorkStartDate'
      }

      let res = ''
      for (let i = 0; i < o[tar].length; i++) {
        const t = o[tar][i]

        // 開始日の記入がないものが1つでもあれば空文字を返却
        if (!t[sDay] || t[sDay] == '') {
          return ''
        }
        // 初期値セット
        if (i == 0) {
          res = t[sDay]
        }
        // 開始日が早い方を取得
        if (i+1 < o[tar].length) {
          res = this.getStartDay(t[sDay], o[tar][i+1][sDay])
        }
      }
      return res
    },

    /**
     * 詳細のデータを生成
     * @param de 詳細データ
     */
    setDetailRow(de) {
      return { category: de.category, num: de.num, price: de.price, amount: de.amount, code: de.code, rowspan: de.rowspan }
    },

    /**
     * 発注先判定_自社のは作成しない
     * @param val 対象発注先
     */
    judgeSupplier(val) {
      // console.log(val)
      if (val.indexOf('アポロ') == -1 && val.indexOf('RLS') == -1 && val.indexOf('ラウンド') == -1 && val.indexOf('建物営業部') == -1) {
        return true
      } else {
        return false
      }
    },

    /**
     * アドレスが正しい形か確認
     */
    checkAddress(val, i) {
      let res = false
      if(val) {
        const reg = new RegExp(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)
        res = !reg.test(val)
      }
      this.resultAddress[i] = res
    },

    /**
     * 入力された発注先名と紐づく発注先データを取得
     * @param name 入力値
     * @param mst マスタデータ
     */
    getPurchaseMst(name, mst) {
      for (let i = 0; i < mst.length; i++) {
        const m = mst[i]
        if (name) {
          // ひらがなのべをカタカナのベに変換
          name = name.replace('べスパ', 'ベスパ').trim()
        }
        if (m.supplier == name) {
          let mail = []
          let picArr = []
          let pic = ''

          for (let j = 0; j < m.details.length; j++) {
            const d = m.details[j]
            mail.push(d.mail)
            if (d.pic) {
              picArr.push(d.pic)
            }
          }
          pic = picArr.join('、　')
          return { ...m, mail, pic }
        }
      }
      return {purchaseCode: null, supplier: '', purchaseOrderName: '', mail: [''], pic: ''}
    },

    /**
     * メアド追加ボタンイベント
     * @param row 対象行
     */
    mailAdd(row) {
      row.mail.push('')
    },

    /**
     * メアド削除
     * @param row 対象行
     * @param j インデックス
     */
    mailDelete(row, j) {
      row.mail.splice(j, 1)
      if (!row.mail || !row.mail.length) {
        row.mail.push('')
      }
    },

    /**
     * 本文編集ボタン
     * @param i インデックス
     */
    edit(i, row) {
      this.purchase.forEach((p) => {
        p.edit = false
      })

      row.edit = true
      this.$set(this.purchase, i, row)
    },

    /**
     * 本文変更
     */
    contentChange({html}, i) {
      this.purchase[i].content = html
    },

    /**
     * 送信ボタン非活性判定
     * @param row 対象データ
     */
    disabledSendBtn(row) {
      if (this.firstTime) {
        return '初回送信時は一括送信しかできません'
      }
      
      if (!row.supplier || row.supplier == '') {
        return '発注先名が入力されていません'
      }

      if (!row.startDay || row.startDay == '') {
        return '受注画面にて開始日を指定してください'
      }
      
      if (!row.mail || !row.mail.length || row.mail.includes('')) {
        return 'メールアドレスを入力してください'
      }

      if (this.orderStatus == 'done') {
        return 'ステータスが完了しているため送信できません'
      }

      return null
    },

    /**
     * 一括送信
     */
    async allSendMail() {
      let params = []
      for (let i = 0; i < this.purchase.length; i++) {
        const row = this.purchase[i]
        // バリデート
        if (!await this.validate(row)) {
          return
        }
        // パラム生成
        params.push(this.setSendParams(row, i))
      }
      let send = ''
      if (this.firstTime) {
        send = await dialogs.showConfirmDialog('一括送信しますか？', 'メールアドレスに間違いはないでしょうか？\r\n一括送信してもよろしいですか？')
        if (send != 'YES') {
          return
        }
      } else {
        send = await dialogs.showConfirmDialog('一括送信しますか？', 'こちらの受注は既に送信履歴がありますが、一括送信してもよろしいですか？\r\nメールアドレスに間違いはないでしょうか？')
        if (send != 'YES') {
          return
        }
      }
      
      this.sending = true

      const result = await backend.postData('purchaseMail/sendmail', params)

      // 履歴を保存
      await this.saveHistory(result, this.purchase)
      
      this.sending = false

      // 結果をダイアログで表示
      await this.getSendResult(result, true)
    },

    /**
     * メール送信
     */
    async sendmail(i, row) {
      // バリデート
      if (!await this.validate(row)) {
        return
      }

      let send = await dialogs.showConfirmDialog('送信しますか？', `メールアドレスに間違いはないでしょうか？\r\n「${row.supplier}」に送信してもよろしいですか？`)
      if (send != 'YES') {
        return
      }

      this.sending = true
      // パラム生成
      let params = this.setSendParams(row, i)

      let result = await backend.postData('purchaseMail/sendmail', [params])
      
      // 履歴を保存
      await this.saveHistory(result, [row])

      this.sending = false
      
      // 送信結果をダイアログで表示
      await this.getSendResult(result, false)
    },

    /**
     * 送信パラム生成
     * @param row 対象
     * @param i インデックス
     */
    setSendParams(row, i) {
      let fileName = this.info.site
      if (this.info.classification && this.info.classification != '') {
        fileName += '（' + this.info.classification + '）'
      }
      return {
        to: row.mail,
        sub: row.title,
        text: row.content,
        html: document.querySelector("#temp"+i).outerHTML,
        fileName,
        supplier: row.supplier
      }
    },

    /**
     * 履歴パラム生成
     * @param row 対象
     */
    setHistoryParams(row) {
      let saveRow = utils.clone(row)
      delete saveRow.history
      return {
        ...this.info,
        ...saveRow,
        historyNo: row.purchaseNo + '-' + row.history.length,
        date: moment().format('YYYY/MM/DD HH:mm')
      }
    },

    /**
     * バリデート
     * @param row 対象データ
     */
    async validate(row) {
      // 発注先名
      if (!row.supplier || row.supplier == '') {
        await dialogs.showErrorDialog('発注先名', `発注先名がない発注書があります。\r\n受注画面に戻って発注先名を入力してください。`)
        return false
      }
      // メアドチェック
      for (let i = 0; i < row.mail.length; i++) {
        const m = row.mail[i]
        if (m == '') {
          await dialogs.showErrorDialog('メールアドレス', `「${row.supplier}」の${i + 1}番目のメールアドレスを入力してください。`)
          return false
        }
        
        const reg = new RegExp(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)
        if (!reg.test(m)) {
            await dialogs.showErrorDialog('メールアドレス不正', `「${row.supplier}」の${i + 1}番目のメールアドレスが不正です。`)
            return false
        }
      }
      // 件名
      if (!row.title || row.title == '') {
        await dialogs.showErrorDialog('件名', `「${row.supplier}」の件名を入力してください。`)
        return false
      }
      // 本文
      if (!row.content || row.content == '') {
        await dialogs.showErrorDialog('本文', `「${row.supplier}」の本文を入力してください。`)
        return false
      } else if(row.content.indexOf('〇〇') > -1) {
        await dialogs.showErrorDialog('本文が未完成です', `「${row.supplier}」の本文に「〇〇」があります。`)
        return false
      }
      // 開始日
      if (!row.startDay || row.startDay == '') {
        await dialogs.showErrorDialog('施工期間', '「' + row.supplier + '」の施工期間が不明です。\r\n受注画面に戻って全ての開始日、もしくは作業開始年・月を入力してください。')
        return false
      }
      // 既に同じ発注書を送信したか
      let isYes = false
      if (row.history && row.history.length) {
        for (let i = 0; i < row.history.length; i++) {
          const h = row.history[i]
          if (row.purchaseNo == h.purchaseNo && h.sendStatus == '成功' && !isYes) {
            let resDlog = await dialogs.showConfirmDialog(`${row.supplier}には既に送信済`, `発注書番号：${row.purchaseNo} は既に送信済です。\r\n本当に送信しますか？`)
            if (resDlog != 'YES') {
              return false
            } else {
              // 一度YESにしたら2回目からは聞かない
              isYes = true
            }
          }
        }
      }
      return true
    },

    /**
     * 履歴保存
     */
    async saveHistory(result, rows) {
      
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i]
        
        // 送信状態を判定
        // 成功結果がある場合
        if (result.data.success && result.data.success.length) {
          const suc = result.data.success
            if (suc.includes(row.supplier)) {
              row.sendStatus = '成功'
            } else {
              row.sendStatus = '失敗'
            }

        // 全件エラー
        } else if (result.data == 'Internal Server Error') {
          row.sendStatus = '失敗'

        // 成功結果がなく、エラーメッセのみの場合
        } else if (result.data.errMsg && result.data.errMsg.length) {
          row.sendStatus = '失敗'
        }

        let params = this.setHistoryParams(row)
        // 履歴に加える
        const purHis = await purchaseManager.historyRegister(this, params)
        if (!purHis) {
          await dialogs.showErrorDialog('送信履歴保存エラー', params.supplier + 'の送信履歴保存中にエラーが発生しました。')
          this.sending = false
        }
        // 表示画面の履歴表に追加
        row.history.push({
          date: params.date,
          staff: this.info.staff,
          purchaseNo: row.purchaseNo,
          sendStatus: row.sendStatus,
          historyNo: row.purchaseNo + '-' + row.history.length
        })

        // ログに保存
        logManager.recording(this, logManager.Loglevel.INFO, '発注メール', row.sendStatus, `発注メール（発注No：${row.purchaseNo}、発注先：${row.supplier} ）を送信しました。（お問い合わせ番号：${this.info.requestNo}）`)
      }
    },

    /**
     * 送信結果をダイアログで表示
     * @param result 結果
     * @param all 一括送信か
     */
    async getSendResult(result, all) {
      
      // 一括送信結果
      if (all) {
        this.firstTime = false
        if (result.data.errMsg && result.data.errMsg.length) {
          const err = result.data.errMsg
          await dialogs.showErrorDialog('送信に失敗した発注先あり', '下記の発注先はエラーのため送信できませんでした。\r\n' + err.join('\r\n'))
        } else if (result.data.success && result.data.success.length) {
          const suc = result.data.success
          await dialogs.showSuccessDialog('送信完了', `${suc.length}件 送信しました。\r\n${suc.join('\r\n')}`)
          // サブタスク完了
          await this.subTaskStatusDone()
        } else if (result.data == 'Internal Server Error') {
          await dialogs.showErrorDialog('送信失敗', '送信中にエラーが発生したため、送信できませんでした。')
        }

      // 個別結果
      } else {
        if (result.data.errMsg && result.data.errMsg.length) {
          const err = result.data.errMsg
          await dialogs.showErrorDialog('送信失敗', err[0])
        } else if (result.data.success && result.data.success.length) {
          const suc = result.data.success
          await dialogs.showSuccessDialog('送信完了', `「${suc[0]}」に送信しました。`)
          // サブタスク完了
          await this.subTaskStatusDone()
        } else if (result.data == 'Internal Server Error') {
          await dialogs.showErrorDialog('送信失敗', '送信中にエラーが発生したため、送信できませんでした。')
        }
      }
    },

    /**
     * 発注書送信のサブを完了にする
     */
    async subTaskStatusDone () {
      let sub = {}
      // 受注メインに紐づくサブタスクがあるか確認
      const subList = await taskRequestManager.getByOrderId(this, this.orderId, null)
      if (subList && subList.length) {
        for (let i = 0; i < subList.length; i++) {
          const s = subList[i]
          // 発注書送信のタスクがあり、未完了の場合
          if (s.requestTask.indexOf('発注書送信') > -1 && s.status == 'open') {
            sub = s
            break
          }
        }
      }
      // 該当のサブタスクがあった場合
      if (sub && Object.keys(sub).length) {
        // 対象のサブが最終完了タスクの場合
        if (sub.finalCompletionTask == '1') {
          for (let j = 0; j < subList.length; j++) {
            const su = subList[j]
            // 他のサブが未完了の場合
            if (su._id != sub._id && su.status == 'open') {
              await dialogs.showInfoDialog(`サブタスク（${sub.requestTask}）を完了にできません。`, '最終完了タスクになっており、他に未完了のサブタスクがあります。')
              // 終了
              return
            }
          }
        }
        const res = await dialogs.showConfirmDialog('サブタスクを完了しますか？', `【${sub.requestTask}】\r\n 担当者：${sub.toStaffName}\r\n 期限：${sub.limitDate}`)
        if (res == 'YES') {
          sub.status = 'done'
          // 該当サブを完了
          sub.doneDate = moment().format('YYYY-MM-DD HH:mm:ss')
          sub.doneStaffName = this.$store.getters.user.user_name
          const subDoneRes = await taskRequestManager.orderTaskRequestUpdate(this, sub)

          if (!subDoneRes) {
            alert('エラーが発生しました。')
            console.log('発注書送信完了のため、サブを完了中にエラーが発生しました。')
            return
          }
          // ログ登録
          logManager.recording(this, logManager.Loglevel.INFO, 'サブタスク', 'ステータス完了', 'サブタスク（お問い合わせ番号：' + this.info.requestNo + '、サブタスク：' + sub.requestTask + '）のステータスを完了しました。')

          // サブが全て完了したか確認
          let flag = false
          for (let i = 0; i < subList.length; i++) {
            const t = subList[i]
            // 未完了のサブ
            if (t.status != 'done') {
              flag = true
              break
            }
          }
          // 全て完了した場合
          if (!flag && subList.length) {
            // メインを完了にする
            const main = await taskRequestManager.statusDone(this, this.orderId)

            if (main) {
              await dialogs.showInfoDialog('サブタスク・メインタスク完了', 'サブタスクが全て完了したので、メインタスクも完了しました。')
              logManager.recording(this, logManager.Loglevel.INFO, 'メインタスク', 'ステータス完了', 'メインタスク（お問い合わせ番号：' + this.info.requestNo + '）のステータスを完了しました。')
            } else {
              alert('エラーが発生しました。')
              console.log('発注書送信完了・サブ完了のため、メインを完了中にエラーが発生しました。')
              return
            }
          } else {
            await dialogs.showInfoDialog('サブタスク完了', 'サブタスクを完了しました。')
          }
        }
      }
    },

    /**
     * 文言から月額を省く
     * @param name 対象文言
     */
    deleteMonthlyFee(name) {
      if (name && name.indexOf('月額') > -1) {
        let res = name.split('月額')
        if (res && res[0]) {
          return res[0].trim()
        }
      }
      return name
    },

    /**
     * 履歴詳細ボタン
     * @param val 対象履歴
     */
    historyDetail(val) {
      this.historyModal = true
      this.historyNo = val.historyNo
    },

    /**
     * 履歴詳細モーダルclose
     */
    historyModalClose() {
      this.historyModal = false
    }
  }
}
</script>

<style>
</style>