<template>
  <div>
    <PageHeader title="入力制限解除" class="main-form">
      <template #title-header-content>
        <div class="flex-1 flex justify-end items-center">
          <div class="text-white mx-auto">
            人事経理総務・管理者のみ閲覧、解除可能
          </div>
          <!-- 年度検索 -->
          <div class="mr-2 mt-auto">
            <input
              type="number"
              name="filterYear" 
              min="2024"
              max="2999"
              placeholder="年度 検索"
              class="py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 font-bold w-28 text-center" 
              v-model="filter.year"
            /> 
            <span class="pl-1 pr-2 font-bold text-white text-sm">年度</span>
          </div>

          <!-- 切替 -->
          <div class="bg-white rounded mr-5">
            <div class="flex items-center py-1 rounded mx-5 ">
              <div v-for="tab in typeList" 
                :key="tab.id" 
                class="w-28 text-center mx-1 cursor-pointer"
                :class="[type == tab.id ? 'bg-light-blue-700' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-1 px-3 text-sm font-medium text-white']" 
                @click="typeChanged(tab)"
              >
                {{ tab.title }}
              </div>
            </div>
          </div>

          <!-- 検索ボタン -->
          <PrimaryButton
            text="検索"
            class="button-contents mr-5"
            @click="getData()"
          >
          </PrimaryButton>

          <!-- リロードアイコン -->
          <Icon
            iconName="Refresh"
            :clickable="false"
            :class="{ 'animate-spin': loading }"
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer" @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="flex justify-end">
          <div class="flex items-stretch">
            <div class="mr-2 mt-auto">
              <input
                type="search"
                name="filterUser" 
                placeholder="名前 検索"
                class="py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-36" 
                v-model="filter.user"
              /> 
            </div>

            <div class="text-white font-bold text-sm items-stretch my-auto">
              ※解除したい行を選択してください。
            </div>

          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-28 bottom-4 leading-8 w-full main-form mx-auto">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full w-full">
        <div v-if="list.length" class="bg-white rounded-md border border-gray-200 table-h overflow-auto h-full">
          <table class="table overflow-auto text-sm">
            <thead class="th sticky top-0 z-20 bg-colu">
              <tr class="text-gray-500 text-xs border border-gray-200 bg-colu">
                <th v-for="(c, i) in columns" :key="'col'+i" class="text-left p-2" :class="c.class">{{ c.val }}</th>
              </tr>
            </thead>
            <tbody v-for="(row, i) in list" :key="i">
              <tr class="border-b border-gray-200 hover-color">

                <!-- 年度 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer text-center" @click="saveUnlock(row)">
                  {{ row.year }} <span>{{ row.periodName }}</span>
                </td>

                <!-- 月 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer text-center" @click="saveUnlock(row)">
                  {{ row.month }} 月
                </td>

                <!-- 名前 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit px-2 py-4 cursor-pointer font-bold" @click="saveUnlock(row)">
                  {{ row.userName }}
                </td>

                <!-- 部署名 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" @click="saveUnlock(row)">
                  {{ row.departmentName }}
                </td>

                <!-- 本人入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.ps1==0?'bg-red-100':''" @click="saveUnlock(row)">
                  <div v-if="row.ps1==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.ps1==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.ps1==-1" class="text-center text-gray-500 text-xs">入社前</div>
                </td>

                <!-- 上司入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.pb1==-10?'bg-red-100':''" @click="saveUnlock(row)">
                  <div v-if="row.pb1==1">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-6 w-6 text-blue-700 m-auto" />
                  </div>
                  <div v-else-if="row.pb1==-10" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.pb1==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.pb1!=2" class="text-center text-gray-500 text-xs">本人入力待ち</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="!loading && !list.length" class="w-full h-full flex justify-center items-center text-gray-400">
          <EmptyMessage
            iconName="Document"
            message="該当するデータはありません。"
          />
        </div>
      </div>
    </main>
  </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as logManager from '@managers/logManager'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import * as moment from 'moment'
import * as dialogs from '@libs/dialogs'
import PageModel from '../../main/pages/PageModel.vue'

// 評価シート
const COL = [
  { val: '期', class: 'text-center' },
  { val: '月', class: 'text-center' },
  { val: '名前' },
  { val: '部署' },
  { val: 'プロセス評価 本人', class: 'text-center' },
  { val: 'プロセス評価 上司', class: 'text-center' },
]

export default {
  extends: PageModel,
  components: {
    Icon,
    PageHeader,
    EmptyMessage,
    PrimaryButton,
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * タブレット
     */
    isTabletDevice() {
      return utils.deviceInfo.isTablet
    },
  },

  data() {
    return {
      loading: false,
      // 切替ボタン
      typeList: [
        { id: 1, title: '上期' },
        { id: 2, title: '下期' }
      ],
      type: null,
      // リスト
      list: [],
      // カラム
      columns: utils.clone(COL),
      // 検索条件
      filter: {
        // 年度
        year: null,
        // 部署絞込み
        // departmentId: '',
        // 対象者絞込み
        user: '',
      },
      // 部署リスト
      // departmentList: utils.clone(this.$store.getters.departmentList),
    }
  },

  async created() {
    let auth = false
    const dep = this.$store.getters.dualPosition
    if (dep && dep.length) {
      // 人事部か確認
      const isDep = dep.find((d) => {
        return (d.departmentName.indexOf('人事') > -1)
      })
      if (isDep && isDep.departmentName) {
        auth = true
      }
    }
    // 管理者権限の場合
    let auth2 = this.$store.getters.auth
    if (auth2 && auth2.adminAuth) {
      auth = true
    }

    // 権限
    if (!auth) {
      alert('この画面は権限のある人しか見れません。\r\nメニューへ戻ります。')
      this.$router.push('/')
      return
    }

    // 年度
    this.filter.year = Number(moment().format('YYYY'))
    const mm = Number(moment().format('M'))
    if (mm == 1) {
      this.filter.year--
      this.type = 2
    } else if (mm <= 7) {
      this.type = 1
    } else {
      this.type = 2
    }
    // 2月、8月の場合、中旬までは前の期にする
    if ([2, 8].includes(mm)) {
      if (Number(moment().format('D')) <= 16) {
        if (mm == 2) {
          this.type = 2
          this.filter.year--
        } else {
          this.type = 1
        }
      }
    }
    await this.getData()
  },

  methods: {
    /**
     * リフレッシュアイコンイベント
     */
    async refresh() {
      await this.getData()
    },

    /**
     * データ取得
     */
    async getData() {
      this.list = []
      if (!this.filter.year) {
        await dialogs.showErrorDialog('年度未入力', '年度を入力してください。')
        return
      }
      if (!this.type) {
        await dialogs.showErrorDialog('期未選択', '期を選択してください。')
        return
      }

      let yy = Number(moment().format('YYYY'))
      let mm = Number(moment().format('M'))
      const dd = Number(moment().format('D'))
      if (mm == 1) {
        yy - 1
      }
      // 今期よりも未来の年度の場合
      if (this.filter.year > yy) {
        return
      }

      // 検索開始日
      let start = ''
      // 検索終了日
      let end = ''
      // 本日日付
      let today = Number(moment().format('YYYYMMDD'))

      if (this.type == 1) {
        start = this.filter.year + '-02-01'
        end = this.filter.year + '-07-31'
      } else {
        start = this.filter.year + '-08-01'
        end = (this.filter.year + 1) + '-01-31'
      }

      // 開始が本日よりも未来は何もしない
      if (Number(start.replace(/-/g, '')) > Number(end.replace(/-/g, ''))) {
        return
      }

      // 表示してOKの月（〇月まで）
      let byMonth = 13
      // 本日の期の場合
      if (Number(start.replace(/-/g, '')) <= today && Number(end.replace(/-/g, '')) >= today) {
        // 15日を過ぎたら、先月分まで対象
        if (dd > 15) {
          byMonth = Number(moment().add(-1, 'month').format('M'))
          
          // 上期の場合
          if (this.type == 1) {
            // 2月の場合
            if (mm == 2) {
              byMonth = 0
            }
          }

        // 15日以前は、先々月までが対象
        } else {
          byMonth = Number(moment().add(-2, 'month').format('M'))
        }
      }

      // 期初月は特別に前期の場合も考慮する
      if (mm == 2 && this.filter.year == (yy - 1) && this.type == 2 && dd <= 15) {
        // 1月はまだ対象外
        byMonth = 12
      } else if (mm == 8 && this.filter.year == yy && this.type == 1 && dd <= 15) {
        // 7月はまだ対象外
        byMonth = 6
      }

      // データ取得
      this.loading = true

      const res = await backend.postData('evaluation/getList', { ...this.filter, period: this.type, unlock: true })
      if (res.data.data.list && res.data.data.list.length) {
        const all = res.data.data.list
        for (let i = 0; i < all.length; i++) {
          let month = Number(moment(start + ' 00:00:00').format('M'))
          const a = all[i]
          for (let j = 1; j <= 6; j++) {
            // 未入力がある場合
            if (a['lock' + j] && (a['ps' + j] == 0 || a['pb' + j] == -10)) {
              let month2 = month
              if (month2 <= byMonth) {
                if (month2 == 13) {
                  month2 = 1
                  month = 1
                }
                let row = { id: a.id, num: j, year: a.year, period: a.period, periodName: a.periodName, userName: a.userName, departmentName: a.departmentName, month: month2, ps1: a['ps' + j], pb1: a['pb' + j] }
                this.list.push(row)
              }
            }
            month++
          }
        }
      }

      this.loading = false
    },

    /**
     * 解除保存
     */
    async saveUnlock(row) {
      const res = await dialogs.showConfirmDialog('解除確認', `${row.userName}さんの ${row.month} 月を入力できるようにしますか？`)
      if (res != 'YES') {
        return
      }
      await backend.postData('evaluation/saveUnlock', row)
      logManager.recording(this, logManager.Loglevel.INFO, '評価シートロック解除', '解除', `${row.userName}さんの ${row.month} 月（${row.year}年度）を入力できるようにしました。`)
      this.getData()
    },

    /**
     * 切替イベント
     * @paran tab 選択したタブ
     */
    async typeChanged(tab) {
      this.type = tab.id
      await this.getData()
    },
  }
}
</script>
  
<style scoped>
.main-form {
  min-width: 1100px;
}

.table-h {
  max-height: calc(100% - 3px);
}

.table {
  width: 100%;
}

.bg-colu {
  background: #ebf0fb;
}

.hover-color {
  background: white;
}

.hover-color:hover {
  background: rgb(239, 250, 250);
}
</style>