<template>
  <div>
    <PosterComponent
      typeName="定期清掃 張り紙"
      :type="3"
      settionStrageName="FixedPosterStorageItemName">
    </PosterComponent>
  </div>
</template>

<script>
import PosterComponent from '../components/PosterComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    PosterComponent
  },
}
</script>