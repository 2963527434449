<template>
  <div v-if="estimate" class="bg-white shadow sm:rounded-lg h-full">

    <div v-if="!showDetailOnly" class="grid grid-cols-1 gap-x-4 gap-y-0 sm:grid-cols-2 top-0 z-10 bg-white border-b border-gray-200 list">

      <!-- 得意先 -->
      <div class="sm:col-span-1">
        <div class="px-4 pt-5 pb-3 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900 flex items-center">
            <span>得意先名：<span v-if="estimate.clientName&&estimate.clientName!=''" >{{ estimate.clientName }}</span><span v-else>---</span></span>
            &ensp;
            <span v-if="estimate.clientName&&estimate.clientName!=''" >{{ honorific }}</span>
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            得意先住所：{{ clientAdd(estimate.clientPostalCode, estimate.clientAddress1, estimate.clientAddress2)}}
          </p>
        </div>
      </div>

      <!-- 現場 -->
      <div class="sm:col-span-1">
        <div class="px-4 pt-5 pb-3 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            物件名：{{ estimate.buildingName || '---' }}
          </h3>
          <p v-if="estimate.buildingAddress" class="mt-1 max-w-2xl text-sm text-gray-500">
            物件住所：{{ estimate.buildingAddress2 || estimate.buildingAddress.replace(estimate.buildingName, '') || '---' }}<br>
            <span v-if="estimate.buildingInfo && estimate.buildingInfo.stairs != undefined && estimate.buildingInfo.stairs != '0'">階数：{{ estimate.buildingInfo.stairs}}</span>
            <span v-if="estimate.buildingInfo && estimate.buildingInfo.basement && estimate.buildingInfo.basement != '0'" class="ml-2">地下：{{ estimate.buildingInfo.basement}}</span>
            <span v-if="estimate.buildingInfo && estimate.buildingInfo.households != undefined && estimate.buildingInfo.households !='0'" class="ml-2">戸数：{{ estimate.buildingInfo.households}}</span>
          </p>
        </div>
      </div>

      <!-- 担当 -->
      <div class="sm:col-span-2">
        <div class="grid grid-cols-1 gap-x-4 gap-y-0 sm:grid-cols-4 pb-4">

          <div class="px-4 py-1 sm:px-6 col-span-1">
            <span class="text-sm font-medium text-gray-500">
              ご担当者：
            </span>
            <span class="mt-1 text-sm text-gray-900">
              {{ estimate.clientPersonName }} <span class="ml-1 text-gray-500">様</span>
            </span>
          </div>

          <div class="px-4 py-1 sm:px-6 col-span-1">
            <span class="text-sm font-medium text-gray-500">
              TEL：
            </span>
            <span class="mt-1 text-sm text-gray-900">
              {{ estimate.clientPhoneNumber }}
            </span>
          </div>

          <div class="px-4 py-1 sm:px-6 col-span-1">
            <span class="text-sm font-medium text-gray-500">
              FAX：
            </span>
            <span class="mt-1 text-sm text-gray-900">
              {{ estimate.clientFax }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 py-5 sm:px-6">

      <div v-if="!showDetailOnly">
        <!-- 有効期限 -->
        <div class="sm:col-span-1 mt-3">
          <span class="text-sm font-medium text-gray-500">
            有効期限：
          </span>
          <span class="mt-1 text-sm text-gray-900">
            {{ formatDate(estimate.expireDate) }}
          </span>
        </div>

        <!-- 集計対象否か -->
        <div class="sm:col-span-1 mt-3">
          <span class="text-sm font-medium text-gray-500">
            見積集計：
          </span>
          <span class="mt-1 text-sm text-gray-900">
            {{ estimateChart }}
          </span>
          <span v-if="estimateChart2" class="mt-1 text-xs text-gray-700">
            {{ estimateChart2 }}
          </span>
          <span v-if="estimateChartEstimateNo" class="mt-1 ml-2 py-1 bg-blue-700 text-sm text-center cursor-pointer rounded-md px-3 font-medium text-white" @click="openEstimateView(estimateChartEstimateNo)">
            {{ '見積No.' + estimateChartEstimateNo }}を開く
          </span>
          <span v-if="estimateChartChangeUser" class="mt-1 text-xs text-green-800 font-bold mr-3">
            ※{{ '変更者：' + estimateChartChangeUser }}
          </span>
          <span v-if="estimateChartBtn" class="mt-1 ml-2 py-1 bg-green-700 text-sm text-center cursor-pointer rounded-md px-3 font-medium text-white" @click="changeEstimateSummary(estimateChartBtn)">
            {{ estimateChartBtn }}
          </span>
          <span v-if="estimateChart&&estimateChart.indexOf('強制的')>-1" class="mt-1 ml-2 py-1 bg-green-700 text-sm text-center cursor-pointer rounded-md px-3 font-medium text-white" @click="changeEstimateSummary('元に戻す')">
            元に戻す
          </span>
        </div>

        <!-- 区分 -->
        <div class="sm:col-span-1 mt-3 flex">
          <div class="text-sm font-medium text-gray-500 my-auto">
            区分：
          </div>
          <div class="flex items-center py-1 rounded">
            <div v-for="tab in type" 
              :key="tab.id" 
              class="w-20 text-center mx-1 cursor-pointer"
              :class="[estimate.classification == tab.id ? 'bg-light-blue-800' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-1 px-3 text-sm font-medium text-white']" 
              @click="typeChanged(tab)"
            >
              {{ tab.name }}
            </div>
          </div>
        </div>
      </div>

      <dl class="mt-3">
        <!-- 詳細モーダルで選択した内容を表示 -->
        <div v-if="selectedDetails && selectedDetails.length">
          <div class="font-bold text-xs text-blue-700">詳細</div>
          <div class="flex flex-wrap">
            <div v-for="(row, i) in selectedDetails" :key="i">
              <div class="text-gray-600 mb-0.5 mr-1 text-sm rounded border border-blue-700 w-fit mt-1.5 h-99p">
                <div class="text-center bg-blue-700 text-white px-3">{{ row.categoryName }}</div>
                <div v-if="row.details && row.details.length" class="table my-1 mx-3">
                  <div v-for="(de, j) in row.details" :key="'de' + j" class="table-row">
                    <div class="table-cell">
                      {{ de.name }}
                    </div>
                    <div class="table-cell">
                      ：{{ de.value }}
                    </div>
                    
                  </div>
                </div>
                <!-- ラウンドプラスの場合 -->
                <div v-else-if="row.rlsPlus1" class="flex">
                  <div v-for="idx in [1, 2, 3]" :key="'rlsPlus' + idx">
                    <div v-if="row['rlsPlus' + idx]" :class="idx!=1?'border-l border-blue-200 h-full':''">
                      <div class="text-center bg-blue-600 text-white px-1">{{ row['rlsPlus' + idx].categoryName }}</div>
                      <div class="table py-1 px-2">  
                        <div v-for="(de, j) in row['rlsPlus' + idx].details" :key="'de' + idx + j" class="table-row">
                          <div class="table-cell">
                            {{ de.name }}
                          </div>
                          <div class="table-cell">
                            ：{{ de.value }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 詳細モーダルで選択するものがない -->
                <div v-else>
                  <div class="my-1 mx-3 text-center">---</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 見積明細 -->
        <div class="sm:col-span-2 mt-4">
          <dt class="text-sm font-medium text-gray-500 flex">
            見積明細
          <div class="text-xs text-gray-400 msg"><span class="bg-yellow-200">&emsp;&emsp;</span>：単価を手動で変更した箇所です。</div>
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="---overflow-hidden ---border ---border-gray-300 ---rounded">
                    <table class="min-w-full divide-y divide-gray-200 table-fixed border-collapse">
                      <thead class="bg-blue-50 border-t border-l border-r border-gray-200">
                        <tr>
                          <th v-if="detailSelectable" :rowspan="2" scope="col" class="border border-gray-200 w-20 px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            <input 
                              type="checkbox" 
                              class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" 
                              @change="allDetailsSelectChange($event.target.checked)"
                            />
                          </th>
                          <th v-for="(c, index) in columns" :key="index" scope="col" :rowspan="c.rowspan" :colspan="c.colspan" :class="c.class" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border">
                            {{c.title}}
                          </th>
                        </tr>
                        <tr class="divide-x divide-gray-200">
                          <th scope="col" class="px-6 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-left">
                            内容
                          </th>
                          <th v-if="!estimate.columnDragFlagOne" scope="col" class="w-m px-6 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            数量
                          </th>
                          <th scope="col" class="w-m px-6 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            単価
                          </th>
                          <th v-if="estimate.columnDragFlagOne" scope="col" class="w-m px-6 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            数量
                          </th>
                        </tr>
                      </thead>
                      <tbody class="border border-gray-200">
                        <tr v-for="(detail, detailIndex) in estimate.details" :key="detailIndex" :class="setNo(detailIndex + 1) % 2 === 0 ? 'bg-teal-10' : 'bg-teal-50'">
                          <td v-if="detailSelectable && detail.rowspan!=-1" :rowspan="detail.rowspan" class="border border-gray-200 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                            <input 
                              type="checkbox" 
                              class="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" 
                              v-model="detail.selected"
                              @change="detailSelectChange(detail)"
                            />
                          </td>
                          <!-- 番号 -->
                          <td v-if="detail.rowspan!=-1" :rowspan="detail.rowspan" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                            {{ setNo(detailIndex + 1) }}
                          </td>
                          <!-- カテゴリー -->
                          <td v-if="detail.rowspan!=-1" :rowspan="detail.rowspan" class="border border-gray-200 px-4 py-2 whitespace-pre-line break-words text-sm text-gray-500">
                            {{ detail.categoryName }}
                          </td>
                          <!-- 詳細 内容 -->
                          <td class="border border-gray-200 px-4 py-2 text-sm text-gray-500 h-m whitespace-pre-line">
                            
                            <span :class="setBgColor(detail.nameBgColor)" class="name-width inline-block" >{{ detail.itemName }}</span>
                            <span v-if="detail.rowspan>0" class="border-radius align-middle" :class="setBgColor(detail.iconBgColor)"></span>
                          </td>
                          <!-- 詳細1 数量 -->
                          <td :rowspan="setRowspanQty(detail)" v-if="!estimate.columnDragFlagOne && setRowspanQty(detail)>0" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                            {{ (detail.itemQty) }} {{ detail.itemUnitName}}
                          </td>

                          <!-- 詳細1 単価 ラウンドプラス以外 -->
                          <td v-if="detail.categoryCode!='2'" :class="setPrice1BgColor(detail.detailBgColor, detail.iconBgColor, detail.categoryCode, detailIndex, detail.type, detail)" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
                            {{ formatValue(detail.itemUnitPrice) }}
                          </td>
                          <!-- 詳細1 単価 ラウンドプラス 単価表示しない -->
                          <td v-else-if="detail.categoryCode=='2' && !detail.isUnitPrice && detail.rowspan!=-1" :rowspan="detail.rowspan" :class="setBgColor(detail.detailBgColor || detail.iconBgColor)" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
                            {{ formatValue(detail.itemUnitPrice) }}
                          </td>
                          <!-- 詳細1 単価 ラウンドプラス 単価表示する -->
                          <td v-else-if="detail.categoryCode=='2' && detail.isUnitPrice && detail.fixedRowspan!=-1" :rowspan="!detail.fixedRowspan?1:detail.fixedRowspan" :class="setBgColor(detail.detailBgColor || detail.iconBgColor2)" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
                            {{ formatValue(detail.itemUnitPrice) }}
                          </td>

                          <!-- 詳細1 数量 -->
                          <td :rowspan="setRowspanQty(detail)" v-if="estimate.columnDragFlagOne && setRowspanQty(detail)>0" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                            {{ (detail.itemQty) }} {{ detail.itemUnitName}}
                          </td>

                          
                          <!-- 単価2 特別清掃以外 -->
                          <td v-if="!estimate.columnDragFlag && !(detail.categoryCode=='14' && !detail.newData) && setRowspan(detail)!=-1" :rowspan="setRowspan(detail)" :class="setBgColor(detail.categoryBgColor)" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
                            {{ formatValue(detail.categoryUnitPrice) }}
                          </td>
                          <!-- 単価2 特別清掃 -->
                          <td v-if="!estimate.columnDragFlag && (detail.categoryCode=='14' && !detail.newData)" :class="setBgColor(detail.categoryBgColor)" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
                            {{ formatValue(detail.categoryUnitPrice) }}
                          </td>


                          <!-- 数量2 特別清掃以外 -->
                          <td v-if="!(detail.categoryCode=='14' && !detail.newData) && setRowspan(detail)!=-1" :rowspan="setRowspan(detail)" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                            {{ formatValue(detail.categoryQty) }} {{ detail.categoryUnitName}}
                          </td>
                          <!-- 数量2 特別清掃 -->
                          <td v-if="(detail.categoryCode=='14' && !detail.newData)" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                            {{ formatValue(detail.categoryQty) }} {{ detail.categoryUnitName}}
                          </td>


                          <!-- 単価2 特別清掃以外 -->
                          <td v-if="estimate.columnDragFlag && !(detail.categoryCode=='14' && !detail.newData) && setRowspan(detail)!=-1" :rowspan="setRowspan(detail)" :class="setBgColor(detail.categoryBgColor)" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
                            {{ formatValue(detail.categoryUnitPrice) }}
                          </td>
                          <!-- 単価2 特別清掃 -->
                          <td v-if="estimate.columnDragFlag && (detail.categoryCode=='14' && !detail.newData)" :class="setBgColor(detail.categoryBgColor)" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
                            {{ formatValue(detail.categoryUnitPrice) }}
                          </td>
                          <!-- 合計金額 特別清掃以外 -->
                          <td v-if="!(detail.categoryCode=='14' && !detail.newData) && setRowspan(detail)!=-1" :rowspan="setRowspan(detail)" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
                            {{ formatValue(detail.categoryAmount) }}
                          </td>
                          <!-- 合計金額 特別清掃 -->
                          <td v-if="(detail.categoryCode=='14' && !detail.newData)" class="border border-gray-200 px-4 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
                            {{ formatValue(detail.categoryAmount) }}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot v-if="!showDetailOnly">
                        <tr v-if="estimate.discount && estimate.discount != '0'">
                          <td colspan="5" class="px-6 py-2"></td>
                          <td colspan="2" class="px-6 py-2 border-l border-b border-gary-200 text-center">調整費</td>
                          <td class="px-4 py-2 border border-gary-200 text-right" :class="estimate.discount!=''&&estimate.discount!='0'?'bg-yellow-200':''">
                            {{ estimate.discount ? formatValue(Math.abs(estimate.discount || ''), 0, '▲ ', '') : '' }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="5" class="px-6 py-2"></td>
                          <td colspan="2" class="px-6 py-2 border-l border-b border-gary-200 text-center">小計</td>
                          <td class="px-4 py-2 border border-gary-200 text-right">
                            {{ formatValue(estimate.withoutTax) }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="5" class="px-6 py-2"></td>
                          <td colspan="2" class="px-6 py-2 border-l border-b border-gary-200 text-center">消費税</td>
                          <td class="px-4 py-2 border border-gary-200 text-right">
                            {{ formatValue(estimate.tax) }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="5" class="px-6 py-2"></td>
                          <td colspan="2" class="px-6 py-2 border-l border-b border-gary-200 text-center">合計</td>
                          <td class="px-4 py-2 border border-gary-200 text-right">
                            {{ formatValue(estimate.totalAmount, 0, '¥' , '') }}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </dd>
        </div>

        <div v-if="!showDetailOnly && estimate.purchase" class="sm:col-span-2">
          <div class="flex items-end">
            <label class="text-xs font-bold text-gray-700 flex items-center">
              発注明細
            </label>
          </div>
          <div class="mt-1">
            <EstimatePurchase
              :purchase="estimate.purchase"
              :allDisabled="true"
            >
            </EstimatePurchase>
          </div>
        </div>
        
        <div v-if="!showDetailOnly && estimate.commission" class="sm:col-span-2">
          <div class="flex items-end">
            <label class="text-xs font-bold text-gray-700 flex items-center">
              手数料明細
            </label>
          </div>
          <div class="mt-1">
            <EstimateCommission
              :commission="estimate.commission"
              :allDisabled="true"
            >
            </EstimateCommission>
          </div>
        </div>

        <div v-if="!showDetailOnly" class="sm:col-span-2">
          <div class="mt-1">
            <EstimateNote
              :notes="estimate.noteList"
              bgColor="bg-white"
            >
            </EstimateNote>
          </div>
        </div>

        <div v-if="!showDetailOnly&&estimate.commonNote" class="sm:col-span-2 mb-1">
          <div class="mt-1 w-full border border-gray-300 rounded">
            <div class="light-pl w-full px-6 py-3 text-center text-xs font-medium text-gray-500 border-b border-gray-300">
              全カテゴリーに共通する特記事項
            </div>
            <div class="px-2 py-5 whitespace-pre-wrap text-sm text-gray-700">
              {{ estimate.commonNote }}
            </div>
          </div>
        </div>
        
        <div v-if="!showDetailOnly" class="sm:col-span-2">
          <dt class="text-sm font-medium text-gray-500">
            添付ファイル
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <ul v-if="attachments.length > 0" class="border border-gray-200 rounded divide-y divide-gray-200">
              <EstimateAttachment v-for="attachment in attachments" :key="attachment.fileId" :readonly="true" view="list" :value="attachment" />
            </ul>
            <p v-else class="text-gray-300" >
              添付ファイルはありません。
            </p>
          </dd>
        </div>

        <div v-if="!showDetailOnly" class="sm:col-span-2">
          <dt class="text-sm font-medium text-gray-500">
            送付状追記文
          </dt>
          <dd 
            v-if="estimate.coverPageAdditionalText"
            class="mt-1 text-sm text-gray-900 bg-gray-50 border-gray-300 border p-4 rounded whitespace-pre-line" 
          >
            {{ estimate.coverPageAdditionalText }}
          </dd>
          <dd 
            class="mt-1 text-sm"
            v-else
          >
            <p class="text-gray-300" >
              追記文はありません。
            </p>
          </dd>
        </div>

      </dl>
    </div>
  </div>
</template>
<script>
import EstimateAttachment from './EstimateAttachment.vue'
import * as estimateManager from '@managers/estimateManager'
import EstimateNote from '../components/EstimateNote.vue'
import EstimatePurchase from '../components/EstimatePurchase.vue'
import EstimateCommission from '../components/EstimateCommission.vue'
import * as utils from '@libs/utils'
import * as constants from '@libs/constantsEstimate'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'

export default {
  components: {
    EstimateAttachment,
    EstimateNote,
    EstimatePurchase,
    EstimateCommission
  },
  props: {
    estimateNo: {
      type: String,
      default: ''
    },

    estimateData: {
      type: Object,
      default: null
    },

    detailSelectable: {
      type: Boolean,
      default: false
    },
    
    showDetailOnly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      estimate: null,
      buildingAddress: '---',
      // parking: [],
      // 詳細モーダルでの選択内容
      selectedDetails: [],
      // 見積りの区分
      type: utils.clone(constants.type),
      // 見積集計の対象か否かの結果
      estimateChart: null,
      // 見積集計の対象か否かの結果詳細
      estimateChart2: null,
      // 対象外の場合、カウントされている見積もり
      estimateChartEstimateNo: null,
      // 見積集計カウント対象変更ボタン
      estimateChartBtn: null,
      // 見積集計カウントを強制的に変更した人
      estimateChartChangeUser: null
    }
  },

  computed: {
    formatValue() {
      return (val) => {
        if (val && val != '') {
          // マイナスの場合
          if (Number(val) < 0) {
            return '▲ ' + Math.abs(Number(val)).toLocaleString()
          } else {
            return Number(val).toLocaleString()
          }
        } else {
            return ''
        }
      }
    },

    attachments() {
      return [ this.estimate.attachment1, this.estimate.attachment2, this.estimate.attachment3].filter((attachment) => {
        return (attachment && attachment.fileId)
      })
    },

    /**
     * 行No表示
     * @param index 番号
     */
    setNo() {
      return (index) => {
      let No = 0
      for (let i = 0; i < index; i++) {
        if (this.estimate.details[i].rowspan < 0) {
          No += this.estimate.details[i].rowspan
        }
      }
      return index + No
      }
    },

    /**
     * カラム名
     */
    columns() {
      if (this.estimate.columnDragFlag) {
        return [
          {title: 'No.', rowspan: 2, colspan: 1, class: 'w-s'},
          {title: 'カテゴリー', rowspan: 2, colspan: 1, class: ''},
          {title: '詳細', rowspan: 1, colspan: 3, class: 'border-bt'},
          {title: '数量', rowspan: 2, colspan: 1, class: 'w-m'},
          {title: '単価', rowspan: 2, colspan: 1, class: 'w-m'},
          {title: '金額', rowspan: 2, colspan: 1, class: 'w-m'}
        ]
      } else {
        return [
          {title: 'No.', rowspan: 2, colspan: 1, class: 'w-s'},
          {title: 'カテゴリー', rowspan: 2, colspan: 1, class: ''},
          {title: '詳細', rowspan: 1, colspan: 3, class: 'border-bt'},
          {title: '単価', rowspan: 2, colspan: 1, class: 'w-m'},
          {title: '数量', rowspan: 2, colspan: 1, class: 'w-m'},
          {title: '金額', rowspan: 2, colspan: 1, class: 'w-m'}
        ]
      }
    },

    /**
     * 敬称
     */
    honorific() {
      if (this.estimate.clientHonorific) {
        return this.estimate.clientHonorific
      } else {
        return '御中'
      }
    },
  },

  watch: {
    estimateNo() {
      this.setEstimate()
    },

    estimateData() {
      this.setEstimate()
    }
  },

  async created() {
    await this.setEstimate()
  }, 

  methods: {
    async setEstimate() {
      if (this.estimateData) {
        this.estimate = this.estimateData
        // this.getParking(this.estimate)
        this.getSelectedDetails(this.estimate)

        if (this.showDetailOnly) {
          // 引用のチェックを初期化
          if (this.estimate.details) {
            this.estimate.details.forEach((detail) => {
            detail.selected = false
            })
          }
        } else {
          await this.getEstimateChartResult(this.estimate)
        }
      // } else if (this.estimateNo) {
        // const estimate = await estimateManager.getEstimate(this, this.estimateNo)
        // if (estimate) {
        //   this.estimate = estimate
          
        //   // 引用のチェックを初期化
        //   if (this.estimate.details) {
        //     this.estimate.details.forEach((detail) => {
        //     detail.selected = false
        //     })
        //   }
        // }
      }
    },

    /**
     * 駐車場代を取得
     * @param v 見積データ
     */
    // getParking(v) {
    //   if (!v || v == undefined || !v.details) {
    //     return
    //   }
    //   this.parking = []
    //   for (let i = 0; i < v.details.length; i++) {
    //     const e = v.details[i]
    //     if (e.rowspan > 0) {
    //       // 詳細モーダルデータがあるもののみ
    //       if (Object.keys(e.subItemModalData).length) {
    //         // 貯水・消防・増圧・スポット（300㎡越えは見積表に表示されているので不要）
    //         if (['12', '15', '20'].includes(e.categoryCode) || (e.categoryCode == '28' && e.subItemModalData.selectedValue[1] != '3' && e.subItemModalData.conf.meter != '3')) {
    //           if (e.subItemModalData.conf && e.subItemModalData.conf.parking) {
    //             this.parking.push({ categoryName: e.categoryName, price: utils.numberToLocale(e.subItemModalData.conf.parking) })
    //           }
    //         // 定期
    //         } else if (e.categoryCode == '5') {
    //           if (e.subItemModalData.modalTabData && e.subItemModalData.modalTabData) {
    //             for (let j = 0; j < e.subItemModalData.modalTabData.length; j++) {
    //               const t = e.subItemModalData.modalTabData[j]
    //               // 300㎡越えは見積表に表示されているので不要
    //               if (t.data && t.data.conf && t.data.conf.parking && t.data.selectedValue[1] != '3' && t.data.conf.meter != '3') {
    //                 let name = ''
    //                 if (t.data.display && t.data.display[0] && t.data.display[0].name) {
    //                   name = ` (${t.data.display[0].name})`
    //                 }
    //                 this.parking.push({ categoryName: e.categoryName + name, price: utils.numberToLocale(t.data.conf.parking) })
    //               }
    //             }
    //           }
    //         // ラウンドプラス（定期がある場合のみ）
    //         } else if (e.categoryCode == '2') {
    //           if (e.subItemModalData.tabId && e.subItemModalData.tabId != 'clean') {
    //             if (e.subItemModalData.conf && e.subItemModalData.conf.parkValue) {
    //               this.parking.push({ categoryName: e.categoryName, price: utils.numberToLocale(e.subItemModalData.conf.parkValue) })
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // },

    /**
     * チェックしたデータを引用
     * @param detail 対象データ
     */
    detailSelectChange(detail) {
      this.$emit('selectionChange', detail)
    },

    /**
     * 全てを引用
     * @param selected チェックの状態
     */
    allDetailsSelectChange(selected) {
      if (!this.estimate.details) {
        return
      }
      this.estimate.details.forEach((detail) => {
        if ((detail.selected || false) !== selected) {
          detail.selected = selected
        }
      })
      this.estimate.details.splice()
      this.$emit('selectionChange')
    },

    /**
     * 日付生成
     */
    formatDate(dateString) {
      if (!dateString) {
        return '---'
      } else {
        const d = utils.stringToDate(dateString)
        return utils.formatDate(d, '{yyyy}年{MM}月{dd}日')
      }
    },

    /**
     * 単価表の値段を手動で変更したら、承認申請ボタンを表示するためフラグを立てる
     */
    setBgColor(target) {

      if (target) {
        return 'bg-yellow-200'
      }
    },

    /**
     * 式内の値を変更したら、単価1の色も変更 2023.04.11仕様変更
     * @param price 単価変更
     * @param icon 式内変更
     * @param code カテゴリコード
     * @param index インデックス
     * @param type タイプ
     */
    setPrice1BgColor(price, icon, code, index, type, detail) {
      // 日常・管理
      if (['3', '4'].includes(code)) {
        if (price) {
          return 'bg-yellow-200'
        } else {
          // modalTabData内で変更しているか確認
          for (let i = index; i >= 0; i--) {
            const v = this.estimate.details[i]
            if (v.categoryCode == code && v.rowspan > 0) {
              if (v.subItemModalData && v.subItemModalData.modalTabData) {
                for (let j = 0; j < v.subItemModalData.modalTabData.length; j++) {
                  const m = v.subItemModalData.modalTabData[j]
                  if (type.indexOf(m.id) > -1 && m.data) {
                    if (m.data.iconBgColor == true) {
                      return 'bg-yellow-200'
                    } else {
                      return ''
                    }
                  }
                }
              } else {
                return ''
              }
            }
          }
        }
      // 定期
      } else if (code == '5') {
        if (type == 'fixedCalc') {
          return ''
        }
        if (price) {
          return 'bg-yellow-200'
        } else {
          // modalTabData内で変更しているか確認
          for (let i = index; i >= 0; i--) {
            const v = this.estimate.details[i]
            if (v.categoryCode == code && v.rowspan > 0) {
              if (type == '') {
                type = v.type
              }
              if (v.subItemModalData && v.subItemModalData.modalTabData) {
                for (let j = 0; j < v.subItemModalData.modalTabData.length; j++) {
                  const m = v.subItemModalData.modalTabData[j]
                  if ((type.indexOf(m.id) > -1 && m.data)) {
                    if (m.data.iconBgColor == true) {
                      return 'bg-yellow-200'
                    } else {
                      return ''
                    }
                  }
                }
              } else {
                return ''
              }
            }
          }
        }
      // 特別
      } else if (code == '14') {
        if (price) {
          return 'bg-yellow-200'
        } else {
          // modalTabData内でlightingUnitBgColorがtrueか確認
          for (let i = index; i >= 0; i--) {
            const v = this.estimate.details[i]
            if (v.categoryCode == code && v.rowspan > 0) {
              if (type == '') {
                type = v.type
              }
              if (v.subItemModalData && v.subItemModalData.display) {
                for (let j = 0; j < v.subItemModalData.display.length; j++) {
                  const m = v.subItemModalData.display[j]
                  if ((m.name == detail.itemName && m.price == detail.itemUnitPrice)) {
                    if (m.lightingUnitBgColor == true) {
                      return 'bg-yellow-200'
                    } else {
                      return ''
                    }
                  }
                }
              } else {
                return ''
              }
            }
          }
        }
      } else {
        if (price || icon) {
          return 'bg-yellow-200'
        }
      }
      return ''
    },

    /**
     * 詳細数量 rowspanセット
     * @param detail 対象行
     */
    setRowspanQty(detail) {
      if (!detail.isUnitPrice && detail.plusRowspan) {
        return detail.plusRowspan
      } else {
        return 1
      }
    },

    /**
     * rowspanセット
     * @param detail 対象行
     */
    setRowspan(detail) {
      if (detail.categoryCode == '5') {
        return detail.fixedRowspan
      } else {
        return detail.rowspan
      }
    },

    /**
     * 得意先住所
     * @param post 郵便番号
     * @param ad1 住所1
     * @param ad2 住所2
     */
    clientAdd(post, ad1, ad2) {
      let result = ''
      if (post && post != '') {
        result = post
      }
      if (ad1 && ad1 != '') {
        if (result != '') {
          result += ' '
        }
        result += ad1
      }
      if (ad2 && ad2 != '') {
        if (result != '') {
          result += ' '
        }
        result += ad2
      }
      if (result && result != '') {
        return result
      } else {
        return '---'
      }
    },

    /**
     * 見積集計対象か否か判定
     * @param e 見積データ
     */
    async getEstimateChartResult(e) {
      this.estimateChart = null
      this.estimateChart2 = null
      this.estimateChartEstimateNo = null
      this.estimateChartBtn = null
      this.estimateChartChangeUser = null
      // console.log(e)
      if (!e || !e.estimateNo) {
        return
      }

      const res = await backend.searchData('estimateApproved/isSuject', { estimateNo: e.estimateNo, departmentId: e.departmentIdSubTask, type: e.classification })
      // 集計テーブルにあった場合
      if (res && res.data && res.data.data) {
        // 結果
        this.estimateChart = res.data.data.res
        this.estimateChart2 = res.data.data.res2
        // 別の見積もりNo
        this.estimateChartEstimateNo = res.data.data.estimateChartEstimateNo
        // 強制的に変更した人
        this.estimateChartChangeUser = res.data.data.changeUser

        // ボタン文言を変更
        if (this.estimateChart && this.estimateChart.indexOf('対象外') > -1) {
          this.estimateChartBtn = '強制的に対象にする'
        } else if (this.estimateChart) {
          this.estimateChartBtn = '集計データから省く'
        }

      // 集計テーブルに無い場合
      } else {  
        if (e.test) {
          this.estimateChart = '対象外（テストデータ）'
        } else if (e.estimateStatus && e.estimateStatus != '作成完了' && e.estimateStatus.indexOf('受注') == -1) {
          this.estimateChart =  '対象外（ステータスが' + e.estimateStatus + '）'
        } else if (!e.classification) {
          this.estimateChart =  '対象外（区分未選択）'
        } else if (!e.buildingAddress2 && !e.buildingAddress) {
          this.estimateChart =  '対象外（物件住所未入力のため）'
        }
      }
    },

    /**
     * 見積り参照画面に遷移
     */
    openEstimateView(num) {
      if (num) {
        let resolvedRoute = this.$router.resolve({ 
          name: 'EstimateView',
          query: {
            estimateNo: num
          }
        });
        window.open(resolvedRoute.href, '_blank')
      }
    },

    /**
     * 集計変更ボタン
     */
    async changeEstimateSummary(val) {
      if (!val) {
        return
      }
      if (val == '集計データから省く') {
        const res = await dialogs.showConfirmDialog('集計から省いてよろしいですか？', '集計データの中にこの見積りは存在しないことになります。\r\n戻すこともできますが本当に変更しますか？')
        if (res != 'YES') {
          return
        }
      } else if (val == '強制的に対象にする') {
        const res = await dialogs.showConfirmDialog('強制的にカウント対象にしてよろしいですか？', '他に重複データがあっても強制的にカウントされます。\r\n戻すこともできますが本当に変更しますか？')
        if (res != 'YES') {
          return
        }
      } else {
        const res = await dialogs.showConfirmDialog('強制的に変更した内容を戻しますか？', '本当に変更しますか？')
        if (res != 'YES') {
          return
        }
      }
      await this.updateEstimateSummary(val)
    },

    /**
     * 見積り集計変更
     * @param val 集計データから省く、対象にする、元に戻す
     */
    async updateEstimateSummary(val) {
      if (!val) {
        return
      }
      // 変更した人
      let changeUser = this.$store.getters.user.user_name
      changeUser = utils.deleteKana(changeUser)

      let param = {
        estimateNo: this.estimate.estimateNo,
        // 集計から省く
        deleteFlag: false,
        // 強制的にカウント
        countingFlag: false,
        changeUser,
        insertUser: this.$store.getters.user.id
      }
      // ログ用文言
      let log = '元に戻しました。'

      if (val == '集計データから省く') {
        param.deleteFlag = true
        log = '強制的に集計対象外にしました。'
      } else if (val == '強制的に対象にする') {
        param.countingFlag = true
        log = '強制的に集計対象にしました。'
      }
      
      await backend.postData('estimateApproved/updateIsSuject', param)
      // ログに保存
      logManager.recording(this, logManager.Loglevel.INFO, '見積集計', '集計対象変更', '見積（見積番号：' + this.estimate.estimateNo + '）の集計方法を' + log)
      await dialogs.showInfoDialog('変更しました。', log)
      // 画面更新
      await this.getEstimateChartResult(this.estimate)
    },

    /**
     * 区分変更
     * @param type 選択した区分
     */
    async typeChanged(type) {
      if (!type || !type.id) {
        return
      }
      if (isNaN(type.id)) {
        return
      }
      // 既に区分が同じ場合は何もしない
      if (type.id == this.estimate.classification) {
        return
      }
      const res = await dialogs.showConfirmDialog(type.name + 'に変更しますか？', '区分を変更してよろしいですか？')
      if (res != 'YES') {
        return
      }
      this.estimate.classification = type.id
      // 見積りのデータを更新
      const res2 = await estimateManager.updateEstimate(this, this.estimate)
      if (res2) {
        const param = {
          estimateNo: this.estimate.estimateNo,
          type: type.id,
          insertUser: this.$store.getters.user.id
        }
        // 集計データを変更
        await backend.postData('estimateApproved/updateType', param)
        // ログに保存
        logManager.recording(this, logManager.Loglevel.INFO, '見積', '区分変更', '見積（見積番号：' + this.estimate.estimateNo + '）の区分を「' + type.name + '」に変更しました。')
        await dialogs.showInfoDialog('変更しました。', '区分を' + type.name + 'に変更しました。')
        // 画面更新
        this.$emit('getEstimate')
      } else {
        alert('エラーが発生しました。')
        return
      }
    },

    /**
     * 詳細モーダルで選択した値を画面に表示
     */
    getSelectedDetails(estimate) {
      this.selectedDetails = utils.getSelectedDetails(estimate, this.$store.getters.estimateSubItemList, true)
    },
  }
}
</script>
<style scoped>

th.border-left {
  border-left-width: 1px;
}

.w-s {
  width: 60px;
}

.w-sm {
  width: 120px;
}

.w-m {
  width: 150px;
}

.h-m {
  height: 35px;
}

.name-width {
  width: calc(100% - 30px);
}

.border-radius {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  float: right;
  margin-left: auto;
}

.msg {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.h-99p {
  height: 99%;
}

.light-pl {
  background: #efebfb;
}

@media screen and (max-width: 1400px) {
  .list {
    position: static !important;
    z-index: 0 !important;
  }
}
</style>