<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app overflow-auto p-5">
          <div class="text-lg text-blue-800 font-bold" v-if="isNew">新規登録</div>
          <div class="text-lg text-blue-800 font-bold" v-else>編集
            <div v-if="nowShowing" class="text-red-700 text-sm">
              現在、公開中の動画です。タイトル・実施項目を変更しても保存済みの感想文は再判定されません。<br>
              有効無効の変更は感想文一覧画面で手動変更してください。
            </div>
          </div>

          <div class="mt-5">
            <div class="w-full mt-5">
              <!-- 説明 -->
              <div class="text-xs text-blue-700 ml-auto w-fit font-bold cursor-pointer border-b border-blue-700" @click="modalShow=true">
                「共有埋め込み」とは
              </div>
              <!-- URL -->
              <div class="">
                <WmsTextInput
                  name="movieUrl"
                  caption="動画URL もしくは「共有埋め込み」" 
                  v-model="data.movieUrl"
                  class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block rounded "
                />
              </div>
            </div>

            <!-- タイトル -->
            <div class="w-full mt-5">
              <WmsTextInput
                name="title"
                caption="タイトル" 
                v-model="data.title"
                class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block rounded w-full"
              />
            </div>

            <div class="w-full mt-5">
              <!-- 実施項目 -->
              <div class="text-xs font-bold text-gray-700 ">実施項目（未入力欄は無視します）</div>
              <div v-for="(row, i) in data.keyword" :key="i" class="flex items-end">
                <WmsTextInput
                  name="keyword"
                  v-model="data.keyword[i]"
                  class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block rounded w-full"
                />
                <!-- 削除 -->
                <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-7 h-7 ml-auto mb-1" @click="deleteRow(i)"/>
              </div>
              <!-- 追加 -->
              <PlusCircleIcon class="cursor-pointer text-blue-500 w-8 h-8 ml-auto mt-1 hover:text-blue-600" @click="add()"/>
            </div>

            <div class="w-full mt-5">
              <!-- 期限開始日 -->
              <label class="block text-xs font-bold text-gray-700">
                  期限日
              </label>
              <div class="mt-1 flex">
                <div class="w-40">
                  <input
                    type="date"
                    v-model="data.startDay"
                    class="w-full rounded border-gray-300 h-9"
                    @change="data.endDay=data.startDay"
                  >
                </div>
                <div class="ml-2 mr-2 mt-2">～</div>
                <!-- 期限終了日 -->
                <div class="w-40">
                  <input
                    type="date"
                    v-model="data.endDay"
                    class="w-full rounded border-gray-300 h-9"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="text-sm font-bold text-blue-700 ml-1 mt-5">【注意点】</div>
          <div class="text-xs font-bold text-blue-700 ml-1">・ Gemini（AI）で有効・無効を判定します。</div>
          <div class="text-xs font-bold text-blue-700 ml-1">・ タイトル・実施項目が同義かつ、感想文が具体的である場合に有効となります。</div>
          <div class="text-xs font-bold text-blue-700 ml-1">・ <span class="text-red-700">感想文を保存した時点で判定しますので、入力間違いのないように</span>ご注意ください。</div>

          <div class="mt-10 p-1">
            <PrimaryButton text="登録" size="normal px-10 py-3 w-full" @click="register()"/>
          </div>
        </div>
      </template>
    </Modal>

    <!-- 共有埋め込み説明 -->
    <InfoOfMovieModal
      v-model="modalShow"
      @closed="modalShow=false"
    />
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import InfoOfMovieModal from '../components/InfoOfMovieModal.vue'
import { TrashIcon, PlusCircleIcon } from '@vue-hero-icons/solid'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import * as utils from '@libs/utils'
import * as moment from 'moment'

const ROW = {
  id: null,
  movieUrl: '',
  title: '',
  keyword: ['', '', '', ''],
  startDay: moment().add(1, 'days').format('YYYY-MM-DD'),
  endDay: moment().add(1, 'days').format('YYYY-MM-DD')
}

export default {
  components: {
    Modal,
    PrimaryButton,
    WmsTextInput,
    InfoOfMovieModal,
    TrashIcon,
    PlusCircleIcon
  },

  props: {
    target: {
      type: Object
    },
    open: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      // データ
      data: utils.clone(ROW),
      // 新規作成
      isNew: false,
      // 公開中
      nowShowing: false,
      // 説明モーダル
      modalShow: false
    }
  },

  computed: {},

  watch: {
    open() {
      if (this.open) {
        this.getTarget()
      }
    },
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      // 編集の場合
      if (this.target && Object.keys(this.target).length) {
        this.data = utils.clone(this.target)
        this.isNew = false
        // 公開中か否か
        let start = Number(this.data.startDay.replace(/-/g, ''))
        let end = Number(this.data.endDay.replace(/-/g, ''))
        let today = Number(moment().format('YYYYMMDD'))
        if (start <= today && end >= today) {
          this.nowShowing = true
        }
      } else {
        this.isNew = true
        this.nowShowing = false
      }
    },

    /**
     * 削除ボタン
     */
    deleteRow(i) {
      this.data.keyword.splice(i, 1)
      if (!this.data.keyword || !this.data.keyword.length) {
        this.add()
      }
    },

    /**
     * 追加
     */
    add() {
      this.data.keyword.push('')
    },

    /**
     * 初期化
     */
    closed() {
      // データ
      this.data = utils.clone(ROW)
      // 新規作成
      this.isNew = false
      this.nowShowing = false

      this.$emit('modalClose')
    },

    /**
     * 登録処理
     */
    async register() {
      if (!this.data.movieUrl) {
        await this.errRequired('動画URL')
        return
      }

      if (!this.data.title) {
        await this.errRequired('タイトル')
        return
      }

      // 入力済みの実施項目のみ保存
      const filter = this.data.keyword.filter((k) => {
        return k
      })
      if (filter && filter.length) {
        this.data.keyword = filter
      } else {
        await this.errRequired('実施項目')
        return
      }

      if (!this.data.startDay) {
        await this.errRequired('期限の開始日')
        return
      }

      if (!this.data.endDay) {
        await this.errRequired('期限の終了日')
        return
      }

      // 開始日チェック
      if (this.data.startDay > this.data.endDay) {
        await dialogs.showErrorDialog('期限', '開始日と終了日が逆になっています。')
        return
      }

      this.data.insertUserId = this.$store.getters.user.id

      let id = this.data.id
      // 登録
      const res = await backend.postData('evaluationMovie/save', this.data)
      if (!id && res.data.data) {
        id = res.data.data
        this.data.id = id
      }
      console.log(id+'!!!!')

      logManager.recordingByRegist(this, this.isNew, '動画設定', '動画（ID：' + id + '）')
      this.$emit('modalClose')
      this.closed()
    },

    /**
     * 必須項目アラート
     * @param item 項目名
     */
    async errRequired(item) {
      await dialogs.showErrorDialog(item + 'は必須です', item + 'が入力されていません。')
    },
  }
}
</script>
<style scoped>
/* .content-container-app {
  width: 988px;
  height: calc(100vh - 230px);
  overflow: auto;
  padding: 10px;
} */

</style>