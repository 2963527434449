<template>
  <div>
    <PhotoReportListComponent
      menu="photoReport"
      settionStrageName="photoReportListStrage"
    >
    </PhotoReportListComponent>
  </div>
</template>
<script>

import PhotoReportListComponent from '../components/PhotoReportListComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    PhotoReportListComponent
  },
  
  data() {
    return {
    }
  },

}
</script>