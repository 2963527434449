<template>
  <div>
    <PhotoReportItemComponent
      menu="photoReport"
    >
    </PhotoReportItemComponent>
  </div>
</template>
<script>

import PhotoReportItemComponent from '../components/PhotoReportItemComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    PhotoReportItemComponent
  },
  
  data() {
    return {
    }
  },

}
</script>