<template>
  <div class="min-h-screen bg-white">
    <template v-if="autoSigning">
      <div class="w-screen h-screen flex justify-center items-center">
        <p class="text-gray-400 font-bold animate-pulse">Checking for authentication...</p>
      </div>
    </template>
    <template v-else>
      <!-- ヘッダー -->
      <header>
        <h1 class="signin-header" :class="colorCode=='bg-light-blue-700'?'purple-header':colorCode=='bg-pink-400'?'orange-header':'green-header'"><img src="images/logo.png" class="header-logo"></h1>
      </header>
      <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-auto">
          <div>
            <!-- リクエストポストロゴ -->
            <div class="flex items-center">
              <img src="images/requestpostlogo.png" />
            </div>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <div class="space-y-6">
                <!-- Email address入力欄 -->
                <div>
                  <label for="email" class="block text-sm font-medium text-gray-700">
                    Email address
                  </label>
                  <div class="mt-1">
                    <input
                      v-model="email"
                      id="email" 
                      name="email" 
                      type="text" 
                      autocomplete="off" 
                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <!-- Password入力欄 -->
                <div class="space-y-1">
                  <label for="password" class="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <div class="mt-1">
                    <input 
                      v-model="password"
                      id="password" 
                      name="password" 
                      type="password" 
                      autocomplete="new-password" 
                      required="" 
                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                    />
                  </div>
                </div>
                <div class="space-y-1">
                  <p v-if="signinError" class="text-sm text-red-600">サインインできませんでした。メールアドレスとパスワードを確認してください。</p>
                </div>
                <br>
                <!-- サインインボタン -->
                <div>
                  <PrimaryButton text="サインイン" class="w-full" @click="signin" />
                </div>
                <div class="text-center">
                  <a href="./reminder" class="text-xs text-gray-600">パスワードを忘れた方はこちら</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import PrimaryButton from '@components/PrimaryButton.vue'
import { PIGEON_CONFIG } from '@/config'
import * as utils from '@libs/utils'

export default {
  components: {
    PrimaryButton,
  },
  data() {
    return {
      autoSigning: true,
      email: '',
      password: '',
      signinError: false,
      // ヘッダカラー
      colorCode: PIGEON_CONFIG.colorCode
    }
  },
  async created() {
    this.email = ''
    this.password = ''
    console.log('Signin.vue created.')
    utils.sendAuthInfo()
    try {
      await this.autoSignin()
    } catch (e) {
      console.log(e)
    }
    this.autoSigning = false
  },

  mounted() {
  },

  methods: {
    /**
     * オートサインイン
     */
    async autoSignin() {
      const userJson = utils.getPigeonAuthInfo()
      if (userJson) {
        const user = JSON.parse(userJson)
        this.email = user.e_mail_adress
        this.password = user.user_password
        await this.signin()
        console.log('Auto signin.')
      }
    },

    /**
     * サインイン
     */
    async signin() {
      try {
        const authInfo = await this.$pigeon.signin(this.email, this.password)
        if (authInfo) {
          this.signinError = false
          await this.$store.dispatch('initialize', this.$pigeon)
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect)
          } else {
            this.$router.push('/')
          }
        } else {
          this.signinError = true
        }
      } catch (e) {
        console.log(e)
        this.signinError = true
      }
    }
  }
}
</script>