<template>
  <div>
    <!-- <div v-if="loading" class="flex justify-center items-center loading-dialog">
      <p class="text-gray-600 font-bold animate-pulse" style="font-size: 30px">Loading...</p>
    </div> -->
    
      <PageHeader title="Patrol Staff Master" class="list">
        <template #title-header-content>
          <div class="flex-1 pl-2 flex ml-6 justify-end items-center">
            <Icon iconName="Refresh" :clickable="false" :class="{ 'animate-spin': loading }"
              class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer" @click.native="refresh" />
          </div>
        </template>
        <template #page-header-content>
          <div class="pl-4 flex">
            <h1 class="text-lg font-bold text-white flex mb-auto">
              <Icon iconName="UserGroup" class="w-7 h-7 mr-2" /> <span class="mt-0">巡回関連スタッフ一覧</span>
            </h1>
            <!-- 切替 -->
            <div class="bg-white rounded ml-7">
              <!-- <div class="font-bold text-xs mb-1 text-teal-900 ml-2 mt-2">切替</div> -->
              <div class="flex items-center py-1 rounded mx-5 mt-2 mb-2">
                <div v-for="tab in typeList" 
                  :key="tab.id" 
                  class="w-28 text-center mx-1 cursor-pointer"
                  :class="[tab.active ? 'bg-light-blue-700' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-1 px-3 text-sm font-medium text-white']" 
                  @click="typeChanged(tab)"
                >
                  {{ tab.name }}
                </div>
              </div>
            </div>
            <div class="flex-1 pl-2 flex ml-6 justify-end items-center">
              <PrimaryButton text="新規" size="normal hover:bg-pink-400" class="new" @click="edit({})" />
            </div>
          </div>
        </template>
      </PageHeader>

      <main class="absolute top-32 w-screen bottom-4 leading-8 row">
        <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
          <div class="bg-white rounded-md border border-gray-200 max-h-full overflow-auto list">
            <table class="table text-sm h-full">
              <thead class="th sticky top-0 z-20">
                <tr class="column">
                  <th class="th2 text-center">表示順</th>
                  <th class="th2 text-center">ユーザーID</th>
                  <th class="th2 min-w-130">名前</th>
                  <th v-if="type==1" class="th2 text-center min-w-150">指導員講習受講番号</th>
                  <th v-if="type==1" class="th2 text-center min-w-150">担当エリア</th>
                  <th class="th1"></th>
                  <th class="th1"></th>
                </tr>
              </thead>
              <tbody v-for="(row, index) in staffList" :key="index">
                <tr :class="setColor(index)" class="hover-color">
                  <!-- 表示順 -->
                  <td class="td2 text-center">{{ row.sortNo }}</td>
                  <!-- ID -->
                  <td class="td2 text-gray-400 font-bold text-center">{{ row.userId }}</td>
                  <!-- 名前 -->
                  <td class="td2">
                    <pre>{{ row.name }}</pre>
                  </td>
                  <!-- 指導員講習受講番号 -->
                  <td v-if="type==1" class="td2 text-center">
                    <pre>{{ row.patrolNumber }}</pre>
                  </td>
                  <!-- 担当エリア -->
                  <td v-if="type==1" class="td2 text-center">
                    <pre>{{ row.area }}</pre>
                  </td>
                  <td class="td1" style="width: 150px">
                    <PrimaryButton text="編集" @click="edit(row)" />
                  </td>
                  <td class="td1" style="width: 150px">
                    <PrimaryButton text="削除" @click="deleteData(row)"
                      size="normal bg-indigo-200 text-indigo-700 hover:bg-indigo-300" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>

      <!-- 編集・新規登録モーダル -->
      <PatrolStaffMasterModal
        v-model="modalShow"
        :target="editData"
        :type="type"
        :open="openModal"
        :newList="newList"
        :staffList="staffList"
        @modalClose="modalClose"
      />
    </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import PatrolStaffMasterModal from '../components/PatrolStaffMasterModal.vue'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    Icon,
    PageHeader,
    PrimaryButton,
    PatrolStaffMasterModal
  },

  data() {
    return {
      loading: false,
      // 切替ボタン
      typeList: [
        {id: 1, name: '品質巡回', active: true},
        {id: 2, name: '巡回清掃', active: false},
        {id: 3, name: '定期清掃', active: false},
        {id: 4, name: '設備点検', active: false}
      ],
      type: 1,
      // モーダル表示
      modalShow: false,
      // 編集する内容
      editData: {},
      // モーダルオープン
      openModal: 0,
      // 権限はあるが巡回スタッフとして未登録の人
      newList: [],
      // 巡回スタッフ登録済みリスト
      staffList: [],
    }
  },

  async created() {
    await this.getData()
  },

  methods: {
    /**
     * リフレッシュアイコンイベント
     */
    async refresh() {
      await this.getData()
    },

    /**
     * データ取得
     */
    async getData() {
      this.loading = true
      let res1 = await backend.searchData('patrolStaff/getPatrolAuth', { type: this.type })
      let res2 = await backend.searchData('patrolStaff/get', { type: this.type })
      this.newList = res1.data.data
      this.staffList = res2.data.data
      this.loading = false
    },

    /**
     * 背景色設定
     */
    setColor(i) {
      if (i % 2 != 0) {
        return 'bg-blue'
      }
    },

    /**
     * 編集イベント
     * @param data 編集内容
     */
    edit(data) {
      this.openModal++
      this.modalShow = true
      this.editData = data
    },

    /**
     * モーダルを閉じる
     */
    async modalClose() {
      this.modalShow = false
      await this.getData()
    },

    /**
     * 削除ボタン イベント
     * @param row 対象データ
     */
    async deleteData(row) {
      const deleteConf = await dialogs.showWarningConfirmDialog('削除', '削除してよろしいですか？')
      if (deleteConf != 'YES') {
        return false
      }

      // mst_patrol_staff物理削除
      await backend.deleteFlag('patrolStaff/delete', { ...row, updataUserId: this.$store.getters.user.id })

      let typeName = this.typeList.find((t) => {
        return t.active
      }).name

      logManager.recording(this, 2, '巡回スタッフ一覧', '削除', typeName + ' ユーザー（ID : ' + row.userId + '、 ユーザー名 : ' + row.name + '）を削除しました。')
      this.refresh()
    },

    /**
     * 切替イベント
     * @paran tab 選択したタブ
     */
    async typeChanged(tab) {
      this.typeList.map((t) => {
        t.active = false
      })
      tab.active = true

      this.type = tab.id
      await this.getData()
    }

  }
}
</script>
  
<style scoped>
.table {
  width: 100%;
}

.column th {
  height: 50px;
  background: #edecf7;
}

.bg-blue {
  background: rgb(243 247 249);
}

.th1 {
  width: 100px;
  text-align: center;
}

.th2 {
  text-align: left;
  padding-left: 10px;
}

.td1 {
  text-align: center;
}

.td2 {
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.new {
  width: 200px;
  background: #eb94c8;
}

.w-fit {
  width: fit-content;
}

.hover-color:hover {
  background: rgba(184, 244, 242, 0.67);
}

.green-check {
  color: rgb(39, 152, 39);
}

@media screen and (max-width: 1000px) {
  .list {
    min-width: 1000px !important;
  }
}
</style>