<template>
  <div>
    <PatrolInspectionComponent
      typeName="定期清掃 報告書項目設定"
      :type="3"
      settionStrageName="FixedInspectionStorageItemName">
    </PatrolInspectionComponent>
  </div>
</template>

<script>
import PatrolInspectionComponent from '../../Patrol/components/PatrolInspectionComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    PatrolInspectionComponent
  },
}
</script>