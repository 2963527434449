<template>
	<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
		<div class="rounded overflow-auto w-fit">
			<div class="">
				<div class="font-medium text-sm text-gray-500 mb-1">
					新規作成
				</div>
				<div class="text-sm text-gray-500 mb-2">
					テンプレートを選択してください。
				</div>
				<div v-if="menu=='photoReport'" class="text-sm text-red-500 mb-2 font-bold">
					環境整備は「評価」メニューに移動しました。
				</div>
				<div v-for="(row) in list" :key="row.key" class="mb-2 mx-2">
					<PrimaryButton :text="row.typeName" size="normal" class="w-full" :class="row.typeName.indexOf('毎日')>-1?'bg-green-600 hover:bg-green-700':''" @click="templateSelected(row)" />
				</div>
			</div>
		</div>
	</Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'

const ENV = [
	'バイク（毎日）',
	'車（毎日）',
	'事務所（毎日）',
]

export default {
	components: {
		Modal,
		PrimaryButton,
		// Icon,
	},

	props: ['photoReportModalShow', 'menu'],

	data() {
		return {
			list: [
				{ id: 1, type: 1, typeName: 'フリー入力', commonItem: false, title: 'フリー入力' }
			],

		}
	},

	computed: {
	},

	watch: {
		photoReportModalShow() {
			if (this.photoReportModalShow) {
				this.getData()
			}
		}
	},

	methods: {
		/**
		 * モーダルclose
		 */
		closed() {
		},

		async getData() {
			const res = await backend.searchData('photoReport/getType', { menu: this.menu })
			if (res.data.data && res.data.data.length) {
				this.list = res.data.data
				if (this.menu == 'evaluation') {
					this.list = []
					for (let i = 0; i < res.data.data.length; i++) {
						const e = res.data.data[i]
						if (e.id != 5) {
							this.list.push(e)
						} else {
							for (let j = 0; j < ENV.length; j++) {
								const v = ENV[j]
								e.typeName = v
								e.omit = v
								e.title = v
								this.list.push(utils.clone(e))
							}
						}
					}
				}
			}
		},

		/**
		 * テンプレート選択イベント
		 */
		async templateSelected(row) {
      this.$router.push({ 
        name: 'PhotoReport',
        params: {
          target: row
        },
        query: {
          menu: this.menu
        }
			})
			// this.$router.push({ name: row.key })
			this.$emit('close')
		}
	}
}
</script>
<style scoped>
</style>