<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app overflow-auto">
          <div class="flex text-lg text-blue-800 font-bold">
            <div>マスタ編集</div>
            <div class="ml-8 text-red-500" v-if="isDefault">※この点検表マスタはデフォルトです。</div>
          </div>

          <div class="mt-5 ml-12 mr-12 mb-12">

            <div class="flex text-gray-600 font-bold mr-auto">
              <div class="w-24">シート名 : </div>
              <input
                v-if="!detail"
                autocomplete="off"
                type="text" 
                name="sheetName"
                :class="list.emptyName?'border-red-600':''"
                v-model="list.name"
                class="w-full border border-gray-200 rounded py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div v-else>
                {{ list.name }}
              </div>
            </div>

            <div class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton v-if="!isDefault&&!detail" text="削除" size="normal" class="w-full mr-1 bg-red-400 hover:bg-red-500" @click="deleteBtn()" />
              <PrimaryButton v-if="checkList&&checkList.length" text="登録" size="normal" class="w-full ml-1" @click="register()" />
            </div>
          </div>

          <PlusCircleIcon class="cursor-pointer text-blue-500 w-8 h-8 ml-auto mr-3 hover:text-blue-600" @click="add()"/>
          <div class="toggle flex items-center mt-8">
            <span class="text-gray-500 font-bold text-xs">並べ替え</span>
            <Toggle v-model="sortable" size="small"/>
          </div>
          <div class="overflow-hidden rounded main-height">
            <table class="main table-auto">
              <thead class="main">
                <tr v-if="checkList && checkList.length">
                  <th v-for="(c, index) in columns" :key="'c2'+index" scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-colu">
                    {{c.title}}
                  </th>
                </tr>
              </thead>

              <draggable 
                key="checkList"
                :list="checkList"
                direction="vertical"
                handle=".row-drag-handle"
                tag="tbody"
                class="bg-white"
              >

                <tr v-for="(row, i) in checkList" :key="'r'+i" class="ml-auto mr-auto">

                  <!-- No -->
                  <td class="px-4 py-2 text-blue-700 font-bold col-no">
                    <div v-if="sortable" class="row-drag-handle cursor-pointer col-no">
                      <MenuIcon class="text-gray-400" />
                    </div>
                    <div v-else>
                      {{ i + 1 }}.
                    </div>
                  </td>

                  <!-- 区分 -->
                  <td class="px-4 py-2 whitespace-prerap text-sm min-210">
                    <WmsSuggestInput
                      name="classification"
                      :embed="true"
                      displayField="name"
                      :dynamic="false"
                      :incrementalSearch="false"
                      :selectionItems="classificationList"
                      class="embed border rounded"
                      :class="row.emptyClassification?'border-red-600':''"
                      v-model="row.classification"
                    />
                  </td>
                  <!-- 清掃区分 -->
                  <td v-if="type==3">
                    <select
                      v-model="row.cleanType"
                      class="text-center focus:bg-yellow-100 border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs py-1 h-8"
                    >
                      <option
                        v-for="(ct, cti) in ['定期清掃', '特別清掃']" :key="'ct'+cti"
                        :value="ct"
                        class="bg-white text-base text-left">
                        {{ ct }}
                      </option>
                    </select>
                  </td>
                  <!-- 詳細1 -->
                  <td v-if="detail" class="py-2 whitespace-nowrap text-sm text-gray-700 w-36">
                    <input
                      autocomplete="off"
                      :type="checkList[0].checkListId==3?'number':'text'"
                      name="detail1"
                      v-model="row.detail1"
                      class="w-full border border-gray-200 rounded py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </td>
                  <!-- 詳細2 -->
                  <td v-if="detail&&checkList[0].checkListId!=3&&checkList[0].checkListId!=4" class="py-2 whitespace-nowrap text-sm text-gray-700 w-36">
                    <input
                      autocomplete="off"
                      type="text" 
                      name="detail2"
                      v-model="row.detail2"
                      class="w-full border border-gray-200 rounded py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </td>
                  <!-- 項目 -->
                  <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-700 min-500">
                    <input
                      autocomplete="off"
                      type="text" 
                      name="item"
                      v-model="row.item"
                      class="w-full border border-gray-200 rounded py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      :class="row.emptyItem?'border-red-600':''"
                    />
                  </td>
                  <!-- 削除ボタン -->
                  <td class="px-4 py-0.5 text-center text-sm font-medium border-gray cursor-pointer hover:text-gray-500 active:text-gray-600" @click="deleteRow(i)">
                    <div class="flex justify-center">
                      <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5"/>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import { TrashIcon, PlusCircleIcon, MenuIcon } from '@vue-hero-icons/solid'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import Toggle from '@components/Toggle.vue'
import draggable from "vuedraggable"
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
import * as logManager from '@managers/logManager'

const ckeckListRow = [{checkListId: null, name: '', sortNo: null, classification: '', item: ''}]

export default {
  components: {
    Modal,
    TrashIcon,
    PlusCircleIcon,
    MenuIcon,
    PrimaryButton,
    WmsSuggestInput,
    Toggle,
    draggable
  },

  props: {
    target: {
      type: Object
    },
    mode: {
      type: String,
      default: null
    },
    masterDataEdit: {
      type: Array,
      default: null
    },
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    // 区分（1：品質巡回、2：巡回清掃、3：定期清掃、4：設備点検）
    type: {
      type: Number,
      default: null
    },
    open: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      // 編集対象データ
      list: { checkListId: null, name: '', emptyName: false },
      // チェック項目
      checkList: utils.clone(ckeckListRow),
      // カラム
      columns: [
        {title: 'No.'},
        {title: '区分'},
        {title: 'チェックポイント'},
        {title: ''}
      ],
      // 区分リスト
      classificationList: [
        {name: 'エントランス周り'},
        {name: 'エントランスホール'},
        {name: '共用廊下'},
        {name: '共用階段'},
        {name: 'エレベーター'},
        {name: 'ゴミ置場'},
        {name: '外周'},
        {name: '自転車置場'},
        {name: '駐車場'},
        {name: '日常清掃員'},
        {name: '定期清掃'},
      ],
      // 並べ替え
      sortable: false,
      // デフォルトのマスタ
      isDefault: false,
      // 詳細項目あり
      detail: false
    }
  },

  computed: {
  },

  watch: {
    open() {
      this.getTarget()
    },
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      if (this.target && Object.keys(this.target).length) {
        this.list = this.target
        this.checkList = utils.clone(ckeckListRow)
        this.isDefault = false
        this.detail = false

        // マスタ編集
        if (this.mode == 'edit') {
          // マスタの点検表既存データ取得
          this.checkList = this.masterDataEdit
          if (this.checkList && this.checkList.length && this.checkList[0].checkListId == 1) {
            this.isDefault = true
          }
        }
        this.setColumn()
      }
    },

    /**
     * カラムをセット
     */
    setColumn() {
      if (this.mode == 'edit' && this.masterDataEdit && this.masterDataEdit.length && (this.masterDataEdit[0].detail1 || this.masterDataEdit[0].detail2)) {
        this.detail = true
      } else {
        this.detail = false
      }

      // 定期清掃
      if (this.type == 3) {        
        // 区分リスト
        this.classificationList = utils.setClassification(this.type)
        // カラム
        this.columns = utils.setColumn(this.detail, this.checkList[0].checkListId)
      }
    },

    /**
     * 初期化
     */
    closed() {
      this.list = {}
      // チェック項目
      this.checkList = []
      // デフォルトのマスタ
      this.isDefault = false
      // カラム
      this.columns = [
        {title: 'No.'},
        {title: '区分'},
        {title: 'チェックポイント'},
        {title: ''}
      ]
      // 区分リスト
      this.classificationList = [
        {name: 'エントランス周り'},
        {name: 'エントランスホール'},
        {name: '共用廊下'},
        {name: '共用階段'},
        {name: 'エレベーター'},
        {name: 'ゴミ置場'},
        {name: '外周'},
        {name: '自転車置場'},
        {name: '駐車場'},
        {name: '日常清掃員'},
        {name: '定期清掃'},
      ]
      // 詳細項目あり
      this.detail = false
      
      this.$emit('modalClose')
    },

    /**
     * 削除ボタンイベント
     */
    async deleteBtn() {
      const dialogResult = await dialogs.showConfirmDialog('削除', '本当に削除してよろしいですか？')
      if (dialogResult !== 'YES') {
        return false
      }
      await this.deleteData()
      this.$emit('modalClose')
      this.closed()
    },

    /**
     * 削除
     */
    async deleteData() {
      const deleteR = await backend.deleteDataByKey('checkListTemplate/deleteByCheckListId', { key: this.list.checkListId, type: this.type }, false)
      if (deleteR.data == 'Internal Server Error') {
        alert('エラーが発生しました。\r\n詳細はコンソールを確認してください。【checkListTemplate/deleteByCheckListId】')
        return
      }
    },

    /**
     * 登録ボタンイベント
     */
    async register() {
      if (this.isDefault) {
        const dialogResult = await dialogs.showWarningConfirmDialog('「該当なし」履歴も消えます', '項目をデフォルトにしている物件の「該当なし」履歴も削除されます。\r\nよろしいでしょうか？')
        if (dialogResult != 'YES') {
          return
        }
      }
      // 未入力チェック
      if (!this.list.name || this.list.name == '') {
        this.errRequired('シート名')
        this.list.emptyName = true
        return
      }

      for (let vI = 0; vI < this.checkList.length; vI++) {
        const vC = this.checkList[vI]
        if (vC.classification == '') {
          this.errRequired(this.columns[1].title)
          vC.emptyClassification = true
          return
        } else if (vC.item == '' && this.type != 3) {
          this.errRequired('チェックポイント')
          vC.emptyItem = true
          return
        }

        // 定期清掃で三井の場合
        if (this.type == 3 && this.checkList[0].checkListId == 4) {
          if (vC.detail2 != '定期' && vC.detail2 != '特別') {
            await dialogs.showErrorDialog('清掃区分', `${vI+1}番目の清掃区分が不正です。\r\n「定期」もしくは「特別」を記入してください。\r\nダウンロードの際にフォーマットが崩れます。`)
            return
          }
          // 特別の下に定期があったらNG
          if (vC.detail2 == '特別') {
            if (this.checkList[vI+1] && this.checkList[vI+1].detail2 == '定期') {
              await dialogs.showErrorDialog('清掃区分', `${vI+1}番目の「特別」次に「定期」があります。\r\n「定期」は「特別」の上になるように並べ替えをお願い致します。`)
              return
            }
          }
        }
      }
      
      let getMaxcheckListId = this.list.checkListId
      // 新規登録の場合
      if (this.mode == 'new') {
        // checkListIdの大値+1取得
        const cliR = await backend.getData('checkListTemplate/getMaxcheckListId')
        if (cliR.data && cliR.data.data) {
          getMaxcheckListId = cliR.data.data
        } else {
          alert('保存中にエラーが発生しました。')
          return
        }
      } else if (this.isDefault) {
        getMaxcheckListId = 1
        // デフォルトの点検表で「該当なし」を設定しているデータを削除
        const delR = await backend.deleteDataByKey('checkListHistory/deleteByDefaultCheckList', {key: 'デフォルト点検表マスタ変更', type: this.type})
        if (delR.data == 'Internal Server Error') {
          alert('保存中にエラーが発生しました。\r\n詳細はコンソールを確認してください。【checkListHistory/deleteByDefaultCheckList】')
          return
        }
      }

      // 登録
      let save = []
      for (let i = 0; i < this.checkList.length; i++) {
        const c = this.checkList[i]
        if (c.classification == '' && c.item == '') {
          break
        }
        c.sortNo = i + 1
        c.checkListId = getMaxcheckListId
        c.name = this.list.name
        c.insertUser = this.$store.getters.user.id
        c.type = this.type
        save.push(c)
      }

      const result = await backend.postData('checkListTemplate/save', { save, key: getMaxcheckListId, type: this.type })
      if (result.data == 'Internal Server Error') {
        alert('保存中にエラーが発生しました。\r\n詳細はコンソールを確認してください。【checkListTemplate】')
        return
      }
      
      //ログ出力
      logManager.recordingByRegist(this, this.mode == 'new', this.typeName, '項目マスタ（シート名：' + this.list.name + '）')

      this.$emit('modalClose')
      this.closed()
    },

    /**
     * 必須項目アラート
     * @param item 項目名
     */
    async errRequired(item) {
      await dialogs.showErrorDialog(item + 'は必須です', item + 'が入力されてません。')
    },

    /**
     * 追加
     */
    async add() {
      // 巡回清掃の場合
      if (this.type == 2) {
        if (this.checkList.length == 3) {
          await dialogs.showErrorDialog('３行までです', '巡回清掃は3行までの設定です。')
          return
        }
      }
      this.checkList.push({checkListId: null, name: '', sortNo: null, classification: '', item: ''})
    },

    /**
     * 削除
     */
    deleteRow(i) {
      this.checkList.splice(i, 1)
      if (!this.checkList.length) {
        this.checkList = utils.clone(ckeckListRow)
      }
    },
  }
}
</script>
<style scoped>
.content-container-app {
  /* width: 930px; */
  height: calc(100vh - 130px);
  overflow: auto;
  padding: 10px;
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.max-w-lg {
  max-width: none !important;
}

.col-no {
  width: 70px;
}

.hover-color:hover {
  color: rgb(11, 118, 200);
}

.min-210 {
  min-width: 210px;
}

.min-300 {
  min-width: 300px;
}

.min-500 {
  min-width: 555px;
}


@media screen and (max-width: 910px) {
  .content-container-app {
    width: 900px !important;
    height: calc(100vh - 90px);
    overflow: auto;
  }

}
</style>