<template>
  <div>
    <ScheduleListByStaffComponent
      typeName='設備点検'
      :type=4
      settionStrageName='FacilitySlbsStorageItemName'
      :checkListDate="checkListDate"
      :userId="userId"
      :name="name"
      routerName='FacilityCheckListTable'
    >
    </ScheduleListByStaffComponent>
  </div>
</template>

<script>
import ScheduleListByStaffComponent from '../../Patrol/components/ScheduleListByStaffComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    ScheduleListByStaffComponent,
  },

  props: {
    // 点検表から遷移した際の予定日
    checkListDate: {
      type: String,
      default: null
    },
    // 点検表から遷移した際のユーザーID
    userId: {
      type: Number,
      default: null
    },
    // 点検表から遷移した際のユーザー名
    name: {
      type: String,
      default: null
    },
  },

}
</script>