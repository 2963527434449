<template>
  <DropdownButton text="印刷" size="font-bold text-white rounded normal" :menus="menu" @select="select">
    <LoadingIcon v-if="isLoading" slot="before" class="h-4 w-4" />
    <Icon v-if="!isLoading" iconName="Printer" slot="before" class="h-4 w-4" />
    <Icon iconName="ChevronDown" slot="after" class="h-4 w-4" />
  </DropdownButton>
</template>

<script>
import DropdownButton from '@components/DropdownButton.vue'
import Icon from '@components/Icon.vue'
import LoadingIcon from '@assets/loading-circle.svg'
import * as utils from '@libs/utils'

const menu = [
  {
    value: 'Estimate',
    text: '見積書'
  },
  {
    value: 'Order',
    text: '注文書'
  },
  {
    value: 'Cover',
    text: '送付状'
  },
]
const menu2 = [
  {
    value: 'Estimate',
    text: '見積書'
  },
  {
    value: 'Order',
    text: '注文書'
  }
]

export default {
  components: {
    DropdownButton,
    Icon,
    LoadingIcon
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: null
    }
  },

  data() {
    return {
    }
  },

  computed: {
    menu() {
      console.log(this.status)
      if (this.status == '受注') {
        return utils.clone(menu)
      } else {
        return utils.clone(menu2)
      }
    }
  },

  methods: {
    select(menuItem) {
      this.$emit('select', menuItem.value)
    }
  }
}
</script>