<template>
  <div>
    <PageHeader title="組織図" class="main-form">
      <template #title-header-content>
        <div class="flex-1 mx-auto flex justify-between items-center">
          <div></div>

          <div v-if="auth" class="font-bold text-white">
            名前をクリックすると上司を選択できます。<div class="mt-2 text-sm">※上司、もしくは人事経理総務部のみ編集可能</div>
          </div>
          <div v-else class="font-bold text-white">
            上司、もしくは人事経理総務部のみ編集可能
          </div>

          <div class="flex">
            <!-- リロードアイコン -->
            <Icon
              iconName="Refresh"
              :clickable="false"
              :class="{ 'animate-spin': loading }"
              class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer" @click.native="refresh"
            />
          </div>

        </div>
      </template>
    </PageHeader>

    <main class="absolute top-20 bottom-4 leading-8 w-full main-form mx-auto bg-white">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full w-full">
        <div class="rounded-md bg-white border border-gray-200 table-h overflow-auto">
          <!-- 未設定スタッフ -->
          <div v-if="notSet.length" class="border border-blue-700 px-2 mb-2 pt-1 pb-2 rounded bg-blue-50 mt-2">
            <div class="font-bold text-blue-600">未設定（上司を選択してください）</div>
            <div class="flex flex-wrap">
              <div v-for="(row, i) in notSet" :key="'not'+i" class="rounded">
                <div class="hover-notset text-sm w-32 cursor-pointer text-center py-2 border border-gray-400 font-bold rounded items-center m-1 flex justify-center" @click="setBoss(row.name)">
                  {{ row.name }}
                </div>
              </div>
            </div>
          </div>
          <!-- 未設定休職中スタッフ -->
          <div v-if="notSetLeave.length" class="border border-green-700 px-2 pt-1 pb-2 rounded bg-gray-100">
            <div class="font-bold text-green-600">休職中（未設定）</div>
            <div class="flex flex-wrap">
              <div v-for="(row, i) in notSetLeave" :key="'notLeave'+i" class="rounded">
                <div class="hover-notset text-sm w-32 cursor-pointer text-center py-2 border border-gray-400 font-bold rounded items-center m-1 flex justify-center" @click="setBoss(row.name)">
                  {{ row.name }}
                </div>
              </div>
            </div>
          </div>

          <!-- 組織図 -->
          <div v-if="list.length" class="mt-3">
            <table border="0" cellpadding="0" cellspacing="0" class="bg-white">
              <thead class="">
                <tr class="sticky bg-white top-0 pt-3 text-indigo-800">
                  <th v-for="c in columns" :key="c" scope="col" class="py-3">
                    {{ c }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, i) in list" :key="'row'+i">
                  <!-- 最上の上司でグループ登録なし（8） -->
                  <td v-if="isDisp(row[0], 8)" :rowspan="row[0].rowspan">
                    <div class="flex" :style="setStyle(row[0])">
                      <div v-if="row[0].name" :class="setClass(row[0].name)" class="w-32 h-full font-bold cursor-pointer hover-color" @click="setBoss(row[0].name)">
                        <div>
                          <span v-if="isLeave(row[0].name)" class="text-xs text-red-700">※休職中<br></span>
                          {{ row[0].name }}
                        </div>
                      </div>
                      <div class="w-8">
                        <div :class="setBorder(row[0])" class="h-1/2"></div>
                        <div class="h-1/2"></div>
                      </div>
                    </div>
                  </td>

                  <!-- グループ7 -->
                  <td v-if="isDisp(row[1], 7)" :rowspan="row[1].rowspan">
                    <div class="flex" :style="setStyle(row[1])">
                      <div :class="row[1].name?'w-8':'w-full'">
                        <div :class="setChildBorder(row[1])" class="h-1/2"></div>
                        <div :class="setChildBorder2(row[1])" class="h-1/2"></div>
                      </div>

                      <div v-if="row[1].name" :class="setClass(row[1].name)" class="w-32 h-full font-bold cursor-pointer hover-color" @click="setBoss(row[1].name)">
                        <div>
                          <span v-if="isLeave(row[1].name)" class="text-xs text-red-700">※休職中<br></span>
                          {{ row[1].name }}
                        </div>
                      </div>

                      <div class="w-8">
                        <div :class="setBorder(row[1])" class="h-1/2"></div>
                        <div class="h-1/2"></div>
                      </div>
                    </div>
                  </td>

                  <!-- グループ6 -->
                  <td v-if="isDisp(row[2], 6)" :rowspan="row[2].rowspan">
                    <div class="flex" :style="setStyle(row[2])">
                      <div :class="row[2].name?'w-8':'w-full'">
                        <div :class="setChildBorder(row[2])" class="h-1/2"></div>
                        <div :class="setChildBorder2(row[2])" class="h-1/2"></div>
                      </div>

                      <div v-if="row[2].name" :class="setClass(row[2].name)" class="w-32 h-full font-bold cursor-pointer hover-color" @click="setBoss(row[2].name)">
                        <div>
                          <span v-if="isLeave(row[2].name)" class="text-xs text-red-700">※休職中<br></span>
                          {{ row[2].name }}
                        </div>
                      </div>

                      <div class="w-8">
                        <div :class="setBorder(row[2])" class="h-1/2"></div>
                        <div class="h-1/2"></div>
                      </div>
                    </div>
                  </td>

                  <!-- グループ5 -->
                  <td v-if="isDisp(row[3], 5)" :rowspan="row[3].rowspan">
                    <div class="flex" :style="setStyle(row[3])">
                      <div :class="row[3].name?'w-8':'w-full'">
                        <div :class="setChildBorder(row[3])" class="h-1/2"></div>
                        <div :class="setChildBorder2(row[3])" class="h-1/2"></div>
                      </div>

                      <div v-if="row[3].name" :class="setClass(row[3].name)" class="w-32 h-full font-bold cursor-pointer hover-color" @click="setBoss(row[3].name)">
                        <div>
                          <span v-if="isLeave(row[3].name)" class="text-xs text-red-700">※休職中<br></span>
                          {{ row[3].name }}
                        </div>
                      </div>

                      <div class="w-8">
                        <div :class="setBorder(row[3])" class="h-1/2"></div>
                        <div class="h-1/2"></div>
                      </div>
                    </div>
                  </td>

                  <!-- グループ4 -->
                  <td v-if="isDisp(row[4], 4)" :rowspan="row[4].rowspan">
                    <div class="flex" :style="setStyle(row[4])">
                      <div :class="row[4].name?'w-8':'w-full'">
                        <div :class="setChildBorder(row[4])" class="h-1/2"></div>
                        <div :class="setChildBorder2(row[4])" class="h-1/2"></div>
                      </div>

                      <div v-if="row[4].name" :class="setClass(row[4].name)" class="w-32 h-full font-bold cursor-pointer hover-color" @click="setBoss(row[4].name)">
                        <div>
                          <span v-if="isLeave(row[4].name)" class="text-xs text-red-700">※休職中<br></span>
                          {{ row[4].name }}
                        </div>
                      </div>

                      <div class="w-8">
                        <div :class="setBorder(row[4])" class="h-1/2"></div>
                        <div class="h-1/2"></div>
                      </div>
                    </div>
                  </td>

                  <!-- グループ3 -->
                  <td v-if="isDisp(row[5], 3)" :rowspan="row[5].rowspan">
                    <div class="flex" :style="setStyle(row[5])">
                      <div :class="row[5].name?'w-8':'w-full'">
                        <div :class="setChildBorder(row[5])" class="h-1/2"></div>
                        <div :class="setChildBorder2(row[5])" class="h-1/2"></div>
                      </div>

                      <div v-if="row[5].name" :class="setClass(row[5].name)" class="w-32 h-full font-bold cursor-pointer hover-color" @click="setBoss(row[5].name)">
                        <div>
                          <span v-if="isLeave(row[5].name)" class="text-xs text-red-700">※休職中<br></span>
                          {{ row[5].name }}
                        </div>
                      </div>

                      <div class="w-8">
                        <div :class="setBorder(row[5])" class="h-1/2"></div>
                        <div class="h-1/2"></div>
                      </div>
                    </div>
                  </td>

                  <!-- グループ2 -->
                  <td v-if="isDisp(row[6], 2)" :rowspan="row[6].rowspan">
                    <div class="flex" :style="setStyle(row[6])">
                      <div :class="row[6].name?'w-8':'w-full'">
                        <div :class="setChildBorder(row[6])" class="h-1/2"></div>
                        <div :class="setChildBorder2(row[6])" class="h-1/2"></div>
                      </div>

                      <div v-if="row[6].name" :class="setClass(row[6].name)" class="w-32 h-full font-bold cursor-pointer hover-color" @click="setBoss(row[6].name)">
                        <div>
                          <span v-if="isLeave(row[6].name)" class="text-xs text-red-700">※休職中<br></span>
                          {{ row[6].name }}
                        </div>
                      </div>

                      <div class="w-8">
                        <div :class="setBorder(row[6])" class="h-1/2"></div>
                        <div class="h-1/2"></div>
                      </div>
                    </div>
                  </td>

                  <!-- グループ未設定部下（-1） -->
                  <td v-if="isDisp(row[7], -1)" :rowspan="row[7].rowspan">
                    <div class="flex" :style="setStyle(row[7])">
                      <div :class="row[7].name?'w-8':'w-full'">
                        <div :class="setChildBorder(row[7])" class="h-1/2"></div>
                        <div :class="setChildBorder2(row[7])" class="h-1/2"></div>
                      </div>

                      <div v-if="row[7].name" :class="setClass(row[7].name)" class="w-32 h-full font-bold cursor-pointer hover-color" @click="setBoss(row[7].name)">
                        <div>
                          <span v-if="isLeave(row[7].name)" class="text-xs text-red-700">※休職中<br></span>
                          {{ row[7].name }}
                        </div>
                      </div>

                      <div class="w-8">
                        <div :class="setBorder(row[7])" class="h-1/2"></div>
                        <div class="h-1/2"></div>
                      </div>
                    </div>
                  </td>

                  <!-- グループ未設定部下（-1） -->
                  <!-- <td v-if="isDisp(row[8], -1)" :rowspan="row[8].rowspan">
                    <div class="flex" :style="setStyle(row[8])">
                      <div :class="row[8].name?'w-8':'w-full'">
                        <div :class="setChildBorder(row[8])" class="h-1/2"></div>
                        <div :class="setChildBorder2(row[8])" class="h-1/2"></div>
                      </div>

                      <div v-if="row[8].name" :class="setClass(row[8].name)" class="w-32 h-full font-bold cursor-pointer hover-color" @click="setBoss(row[8].name)">
                        <div>
                          <span v-if="isLeave(row[8].name)" class="text-xs text-red-700">※休職中<br></span>
                          {{ row[8].name }}
                        </div>
                      </div>

                      <div class="w-8">
                        <div :class="setBorder(row[8])" class="h-1/2"></div>
                        <div class="h-1/2"></div>
                      </div>
                    </div>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          

        </div>
        <div v-if="!loading && !list.length" class="w-full h-full flex justify-center items-center text-gray-400">
          <EmptyMessage
            iconName="Document"
            message="設定された組織データはありません。"
          />
        </div>
      </div>
    </main>
    <SetBossModal
      v-model="openSetBossModal"
      :open="openSetBossModal"
      :target="target"
      :bossList="bossList"
      :leave="leave"
      @getData="getData"
      @closed="closed">
    </SetBossModal>
  </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import SetBossModal from '../components/SetBossModal'
import PageModel from '../../main/pages/PageModel.vue'
import { REGULAR_STAFF_AUTH } from '@/config'

export default {
  extends: PageModel,
  components: {
    Icon,
    PageHeader,
    EmptyMessage,
    SetBossModal
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * タブレット
     */
    isTabletDevice() {
      return utils.deviceInfo.isTablet
    },

    /**
     * 表に表示するかしないか判定
     */
    isDisp() {
      return (row, group) => {
        if (this.isNotGroup.length && this.isNotGroup.includes(group)) {
          return false
        }
        if (row.rowspan == -1) {
          return false
        }
        return true
      }
    },

    /**
     * 休職中か判定
     */
    isLeave() {
      return (name) => {
        if (this.leaveUsers && this.leaveUsers.length) {
          if (this.leaveUsers.includes(name)) {
            return true
          }
        }
        return false
      }
    },

    /**
     * cssセット
     */
    setClass() {
      return (name) => {
        if (name) {
          return 'rounded border border-gray-300 px-2 text-center grid items-center'
        }
        return ''
      }
    },

    /**
     * 罫線
     */
    setChildBorder() {
      return (row) => {
        // 上司がいる
        if (row.isBoss) {
          let bl = ''
          if (!row.top) {
            bl = 'border-l-2'
          }
          return 'border-b-2 border-gray-400 ' + bl
        }
        return ''
      }
    },

    /**
     * 罫線
     */
    setChildBorder2() {
      return (row) => {
        // 上司がいる
        if (row.isBoss) {
          let bl = ''
          if (!row.bottom) {
            bl = 'border-l-2 border-gray-400'
          }
          return bl
        }
        return ''
      }
    },

    /**
     * 罫線
     */
    setBorder() {
      return (row) => {
        // 部下がいる
        if (row.isChild) {
          return 'border-b-2 border-gray-400'
        }
        return ''
      }
    },

    /**
     * スタイルセット
     */
    setStyle() {
      return (row) => {
        return `height: ${row.rowspan * 36}px;`
      }
    },
  },

  data() {
    return {
      loading: false,
      // カラム
      columns: [],
      // 対象グループなし
      isNotGroup: [],
      // リスト
      list: [],
      // 未設定スタッフ
      notSet: [],
      // 未設定休職中スタッフ
      notSetLeave: [],
      // 休職者リスト
      leaveUsers: [],
      // 上司リスト
      bossList: [],
      // 更新権限
      auth: false,
      // 上司選択モーダル
      openSetBossModal: false,
      // クリックされた名前
      target: null,
      // クリックされた人のボス
      boss: null,
      // クリックされた人が休職中
      leave: false
    }
  },

  async created() {
    await this.getData()
  },

  methods: {
    /**
     * リフレッシュアイコンイベント
     */
    async refresh() {
      await this.getData()
    },

    /**
     * データ取得
     */
    async getData() {
      this.list = []
      this.notSet = []
      this.notSetLeave = []
      this.leaveUsers = []
      this.bossList = []
      this.columns = []
      this.isNotGroup = []
      // データ取得
      this.loading = true
      const res = await backend.searchData('evaluation/getOrganization')
      if (res.data.data) {
        this.list = res.data.data.list
        this.notSet = res.data.data.notSet
        this.notSetLeave = res.data.data.notSetLeave
        this.leaveUsers = res.data.data.leaveUsers
        this.bossList = res.data.data.bossList
        this.bossList.push({ name: '設定を削除' })

        // 管理者の場合
        if (REGULAR_STAFF_AUTH.includes(this.$store.getters.user.id)) {
          this.auth = true
        } else {
          // ログインユーザーが上司権限を持っているか確認
          const loginName = utils.deleteKana(this.$store.getters.user.user_name)
          const isData = this.bossList.find((b) => {
            return b.name == loginName
          })

          // 上司の場合
          if (isData && isData.name) {
            this.auth = true

          // 上司ではない場合
          } else {
            // 人事部か確認
            const dep = this.$store.getters.dualPosition
            if (dep && dep.length) {
              const isDep = dep.find((d) => {
                return d.departmentName.indexOf('人事') > -1
              })
              if (isDep && isDep.departmentName) {
                this.auth = true
              }
            }
          }
        }

        // 組織図を成型
        // スタッフ名
        const name = []
        // 階層番号
        const numArr = [1, 2, 3, 4, 5, 6, 7]
        // 成型したデータ
        const d = []
        // デフォルトカラム
        const dc = [8, 7, 6, 5, 4, 3, 2, -1]

        for (let i = 0; i < this.list.length; i++) {
          const l = this.list[i]
          const row = []

          for (let idx = 0; idx < dc.length; idx++) {
            const base = dc[idx]
            let flg = false

            // トップの上司
            if (base == l.bg1) {
              flg = true
              if (!name.includes(l.b1)) {
                // 部下がいるか
                let isChild = false
                if (l.c1) {
                  isChild = true
                }
                row.push({ name: l.b1, g: l.bg1, rowspan: l.num1, isChild, isBoss: false })
                name.push(l.b1)
              } else {
                row.push({ name: l.b1, g: l.bg1, rowspan: -1 })
              }
              continue
            }

            for (let j = 0; j < numArr.length; j++) {
              const num = numArr[j]
              // 上司がいるか
              const isBoss = true
              if (l['cg' + num] && base == l['cg' + num]) {
                flg = true
                if (!name.includes(l['c' + num])) {
                  // 部下がいるか
                  let isChild = false
                  if (l['c' + (num + 1)]) {
                    isChild = true
                  }
                  row.push({ name: l['c' + num], g: l['cg' + num], rowspan: l['num' + (num + 1)] || 1, isChild, isBoss })
                  name.push(l['c' + num])
                } else {
                  row.push({ name: l['c' + num], g: l['cg' + num], rowspan: -1 })
                }
                break
              }
            }

            // 該当グループにいない
            if (!flg) {
              row.push({ name: '', rowspan: 1, g: base })
            }
          }
          d.push(row)
        }
        // 人がいないところのrowspanをその下の部下のrowspanと同じにする
        for (let i = 0; i < d.length; i++) {
          const dd = d[i]
          let span = 1
          let isBoss = false
          let isChild = false
          for (let j = dd.length - 1; j >= 0; j--) {
            const row = dd[j]
            if (row.name) {
              span = row.rowspan
              isBoss = row.isBoss
              isChild = row.isChild
            }
            if (!row.name) {
              row.rowspan = span
              row.isBoss = isBoss
              row.isChild = isChild
              if (!isBoss) {
                row.isChild = false
              } else {
                row.isChild = true
              }
            }
          }
        }
        this.list = d

        // カラム生成
        const col = res.data.data.columns
        for (let i = 0; i < dc.length; i++) {
          const g = dc[i]
          let flg = false
          for (let j = 0; j < col.length; j++) {
            const c = col[j].g
            if (g == c) {
              flg = true  
              if (g == 8) {
                this.columns.push('')
              } else if (g == 2) {
                this.columns.push('グループ2・1')
              } else if (g == -1) {
                this.columns.push('グループ未設定')
              } else {
                this.columns.push('グループ' + g)
              }
            }
          }
          // 1人もいないグループをセット
          if (!flg) {
            this.isNotGroup.push(g)
          }
        }

        console.log(this.isNotGroup)

        // 縦線を入れる
        for (let i = 0; i < this.list.length; i++) {
          const l = this.list[i]
          let top = false
          let bottom = false

          for (let j = 0; j < l.length; j++) {
            const e = l[j]
            if (this.isNotGroup.includes(e.g)) {
              continue
            }
            if (top) {
              e.top = true
              if (e.name) {
                top = false
              }
            }
            if (bottom) {
              e.bottom = true
              if (Number(e.rowspan) == -1) {
                for (let k = i; k >= 0; k--) {
                  const back = this.list[k]
                  if (Number(back[j].rowspan) >= 1) {
                    back[j].bottom = true
                    break
                  }
                }
              }
              if (e.name) {
                bottom = false
              }
            }
            if (Number(e.rowspan) != -1) {
              top = true
            }
            if (Number(e.rowspan) <= 1) {
              const next = this.list[i + 1]
              if (!next) {
                bottom = true
              } else if (next[j].rowspan != -1) {
                bottom = true
              }
            }
          }
        }
      }

      this.loading = false
    },

    /**
     * 名前クリックイベント
     * @param name 対象者名
     */
    setBoss(name) {
      if (!this.auth) {
        return
      }
      this.target = name
      this.leave = this.isLeave(name)
      this.openSetBossModal = true
    },

    /**
     * 上司選択モーダルColse
     */
    closed() {
      this.openSetBossModal = false
    },

  }
}
</script>
  
<style scoped>
.main-form {
  min-width: 1100px;
}

.table-h {
  max-height: calc(100% - 10px);
}

.hover-notset {
  color: black;
  background: white;
}

.hover-notset:hover {
  color: white;
  background: blue;
}

.hover-color {
  background: white;
}

.hover-color:hover {
  color: white;
  background: blue;
}

.bg-orange {
  background: rgb(255 161 49 / 15%);
}
</style>