<template>
  <div>
    <PageHeader title="環境整備 集計" class="list">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">
          <div class="flex items-stretch mx-auto w-fit">
            <!-- 年 -->
            <div class="bg-white rounded mr-5 p-2 text-center flex">
              <select
                name="year"
                v-model="year"
                class="text-center border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block text-sm text-gray-700"
              >
                <option
                  v-for="list in yearList" :key='list.val'
                  :value="list.val"
                  class="bg-white">
                  {{ list.val }}
                </option>
              </select>
              <span class="text-sm font-bold text-gray-500 mx-1 mt-auto">年</span>
              <select
                name="month"
                v-model="month"
                class="text-center border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block text-sm text-gray-700"
              >
                <option
                  v-for="list in monthList" :key='list.val'
                  :value="list.val"
                  class="bg-white">
                  {{ list.val }}
                </option>
              </select>
              <span class="text-sm font-bold text-gray-500 mx-1 mt-auto">月</span>
            </div>

            <!-- 切替 -->
            <div class="bg-white rounded">
              <div class="flex items-center py-2 rounded mx-5 ">
                <div v-for="tab in typeList" 
                  :key="tab.id" 
                  class="text-center mx-1 cursor-pointer my-auto"
                  :class="[tab.id==type ? 'bg-light-blue-700' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-2 px-3 text-sm font-medium text-white']" 
                  @click="typeChanged(tab)"
                >
                  {{ tab.typeName }}
                </div>
              </div>
            </div>

            <!-- 集計ボタン -->
            <PrimaryButton
              text="集計"
              class="w-40 my-auto ml-8 bg-blue-500 hover:bg-blue-600 border-blue-50"
              @click="getData()"
            >
            </PrimaryButton>
          </div>

          <!-- Excelダウンロードボタン -->
          <PrimaryButton text="Excel" class="" @click="downloadBtn()">
            <LoadingIcon v-if="loading" slot="before" class="h-4 w-4" />
            <Icon v-if="!loading" iconName="Printer" slot="before" class="h-4 w-4" />
          </PrimaryButton>
          <Icon iconName="Refresh" :clickable="false" :class="{ 'animate-spin': loading }"
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer" @click.native="refresh" />
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-20 w-screen bottom-4 leading-8">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full w-fit">
        <div class="bg-white rounded-md border border-gray-200 table-form overflow-auto">
          <table v-if="list.length" class="table text-sm">
            <thead class="th sticky top-0 z-20">
              <tr class="column">
                <th class="px-3 py-2 text-left">名前</th>
                <th class="px-3 py-2 text-left">部門</th>
                <th class="px-3 py-2 w-16">件数</th>
              </tr>
            </thead>
            
            <tr v-for="(row, i) in list" :key="i" :class="setColor(i)" class="hover-color">
              <!-- スタッフ名 -->
              <td class="px-3 py-2">
                {{ row.name }}
              </td>

              <!-- 部門（部署） -->
              <td class="px-3 py-2">
                {{ row.departmentName }}
              </td>

              <!-- 件数 -->
              <td class="px-3 py-2 text-right w-16">
                {{ row.counting }}
              </td>
            </tr>
          </table>
          <div v-else-if="!loading && !list.length" class="w-full h-full flex justify-center items-center text-gray-400">
            <EmptyMessage
              iconName="Document"
              message="該当するデータはありません。"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import LoadingIcon from '@assets/loading-circle.svg' 
import * as backend from '@libs/backend'
import * as moment from 'moment'
import * as dialogs from '@libs/dialogs'
import PageModel from '../../main/pages/PageModel.vue'

const MONTHES = [
  { val: '01' },
  { val: '02' },
  { val: '03' },
  { val: '04' },
  { val: '05' },
  { val: '06' },
  { val: '07' },
  { val: '08' },
  { val: '09' },
  { val: '10' },
  { val: '11' },
  { val: '12' }
]

export default {
  extends: PageModel,
  components: {
    Icon,
    PageHeader,
    PrimaryButton,
    EmptyMessage,
    LoadingIcon
  },

  data() {
    return {
      loading: false,
      // 検索条件
      year: moment().format('YYYY'),
      month: moment().format('MM'),
      // 切替ボタン
      typeList: [
        {id: 5, typeName: '環境整備(毎日)'}
      ],
      type: 5,
      // リスト
      list: [],
      // 年リスト
      yearList: [],
      // 月リスト
      monthList: MONTHES
    }
  },

  async created() {
    // 年のリストを2024年から今年の年まで生成
    let year = 2024
    const currentYear = Number(moment().format('YYYY'))
    for (let i = 0; year <= currentYear; i++) {
      this.yearList.push({ val: year++ })
    }
    await this.getData()
  },

  methods: {
    /**
     * リフレッシュアイコンイベント
     */
    async refresh() {
      await this.getData()
    },

    /**
     * データ取得
     */
    async getData() {
      if (!this.year || !this.month) {
        await dialogs.showErrorDialog('検索条件を確認してください', '年・月の条件を確認してください。')
        return
      }
      this.loading = true
      this.list = []

      // 検索条件を年-月の形にする
      const yearMonth = this.year + '-' + this.month

      const res = await backend.searchData('photoReport/getCounting', { type: this.type, yearMonth })
      if (res.data.data && res.data.data.length) {
        this.list = res.data.data
      }

      this.loading = false
    },

    /**
     * 背景色設定
     */
    setColor(i) {
      if (i % 2 != 0) {
        return 'bg-blue'
      }
    },

    /**
     * 切替イベント
     * @paran tab 選択したタブ
     */
    async typeChanged(tab) {
      this.type = tab.id
      // await this.getData()
    },

    /**
     * ダウンロードボタン
     */
    async downloadBtn() {
      this.loading = true
      const yearMonth = this.year + '-' + this.month
      let name = this.typeList.find((t) => {
        return t.id == this.type
      })

      const res = await backend.postBlob2('photoReportDownload/excelCounting', {  type: this.type, list: this.list, yearMonth, name: name.typeName })
      try {
        const uint8Array = res.data
        const blob = new Blob([uint8Array], { "Content-Type" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        const a = document.createElement("a")
        a.href = (window.URL || window.webkitURL).createObjectURL(blob)
        a.download = name.typeName + yearMonth + '.xlsx'
        a.click();
        a.remove();
        this.loading = false
      } catch (e) {
        console.log(e)
        alert(e)
        this.loading = false
      }
    }
  }
}
</script>
  
<style scoped>
.table-form {
  min-height: 50%;
  max-height: calc(100% - 40px);
  min-width: 300px;
}

.table {
  width: 100%;
}

.column th {
  height: 48px;
  background: #edecf7;
}

.bg-blue {
  background: rgb(243 247 249);
}

.hover-color:hover {
  background: rgba(184, 244, 242, 0.67);
}

.edit-btn {
  background: lightseagreen;
  border-color: #3a623e;
}

.edit-btn:hover {
  background: lightseagreen;
  border-color: #3a623e;
}
</style>