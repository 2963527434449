<template>
  <div>
      <!-- <div v-if="processing" class="flex justify-center items-center loading-dialog2">
        <p class="text-gray-600 font-bold animate-pulse" style="font-size: 50px">{{ processingText }}</p>
      </div> -->
      <template>
        <div class="overflow-auto" :class="isMobileDevice?'mobile-content':'pc-content'">
          <div v-if="!processing" class="ml-auto pr-1 pl-5 pb-5 cursor-pointer w-fit" @click="previous()">
            <Icon slot="before" iconName="X" iconType="outline" :strokeWidth="2" class="h-8 w-8 ml-auto" :clickable="true" />
          </div>
          <LoadingIcon v-else slot="before" class="h-8 w-8 ml-auto mr-1 mb-5 text-gray-700" />
          <div class="text-lg text-blue-800 font-bold flex">
            <div class="ml-3">点検表</div>
          </div>

          <div class="mt-5 ml-12 mr-12 mb-12">

            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold mr-auto info1">
                得意先 : {{ list.clientName1 }}
                <div v-if="list.clientName2 && list.clientName2 != ''" class="font-normal ml-20 text-xs text-gray">（{{ list.clientName2 }}）</div>
              </div>
              <div class="text-gray-600 font-bold info2">
                物件名 : {{ list.siteName }}
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold info1">
                点検日 : {{ list.today }}
              </div>
              <div class="text-gray-600 font-bold info2">
                点検時間 : <span v-if="start!=''">{{ start }} ～ {{ end }}</span>
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold info1">
                点検者 : {{ list.staff }}
              </div>
              <div class="text-gray-600 font-bold info2">
                位置情報 : {{ location }}
              </div>
            </div>

            <div v-if="canEdit" class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton text="開始" size="normal" class="w-full mr-1" :class="start!=''?'bg-gray-400':''" v-if="start==''" @click="startBtn()" :disabled="processing" >
                <LoadingIcon v-if="processing" slot="before" class="h-4 w-4" />
              </PrimaryButton>
              <PrimaryButton text="キャンセル" size="normal" class="w-full mr-1 bg-red-400 hover:bg-red-500" v-if="start!=''" @click="cancelBtn()" :disabled="processing" >
                <LoadingIcon v-if="processing" slot="before" class="h-4 w-4" />
              </PrimaryButton>
              <PrimaryButton text="終了" size="normal" class="w-full ml-1" :class="start==''||end!=''?'bg-gray-400':''" :disabled="start==''||end!=''||processing" @click="endBtn()" >
                <LoadingIcon v-if="processing" slot="before" class="h-4 w-4" />
              </PrimaryButton>
            </div>

            <div v-if="completeBtn" class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton text="完了を再表示" size="normal" class="w-full mr-1 bg-teal-50 hover:bg-teal-50 edit text-teal-700" @click="reDisplay()" />
            </div>

            <div v-if="undoBtn" class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton text="完了を非表示に戻す" size="normal" class="w-full mr-1 bg-yellow-50 hover:bg-yellow-50 edit text-yellow-700 border-yellow-600" @click="undo()" />
            </div>

            <div v-if="canEdit" class="flex justify-end mt-2">
              <div class="flex">
                <span class="text-gray-500 font-bold text-xs mr-1 ml-auto my-auto">非表示の「該当なし」</span>
                <Toggle v-model="toggleNA" size="small"/>
              </div>
            </div>
          </div>

            <table v-if="!toggleNA" class="w-11/12 table-auto ml-auto mr-auto mt-3 mb-5">
              <thead class="bg-blue-100">
                <tr class="border border-gray-200">
                  <th v-for="(c, index) in columns" :key="'c'+index" scope="col" class="border border-gray-200 py-1 text-center text-xs font-medium text-gray-500 tracking-wider bg-colu">
                    {{c.title}}
                  </th>
                </tr>
              </thead>
              <tr v-for="(row, i) in checkList.note" :key="'n'+i" class="border border-gray-200 bg-white tracking-wider">
                <!-- 時期 -->
                <td :rowspan="checkList.note.length" v-if="i==0"  class="cursor-pointer hover-color border border-gray-200 text-center py-1 whitespace-pre-line text-sm text-gray-700 w-24">
                  今回
                </td>
                <!-- No -->
                <td class="px-3 py-1 border border-gray-200 text-right col-no h-10 cursor-not-allowed">
                  <div>
                    {{ row.num }}
                  </div>
                </td>
                <!-- 内容 -->
                <td v-if="canEdit" class="px-1 py-1 whitespace-pre-line text-sm min-200">
                  <textarea 
                    name="note" 
                    :rows="getRowCount(row.note, 54)"
                    class="text-gray-700 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-none rounded"
                    v-model="row.note"
                    @change="changeNoteList(row)"
                  />
                </td>
                <td v-else class="px-1 py-1 whitespace-pre-line text-sm min-200">
                  {{ row.note }}
                </td>
              </tr>
              <div></div>
              <tr v-for="(row, i) in checkList.lastNote" :key="'ln'+i" class="border border-gray-200 bg-white tracking-wider">
                <!-- 時期 -->
                <td :rowspan="checkList.lastNote.length" v-if="i==0" class="border border-gray-200 text-center py-1 whitespace-pre-line text-sm text-gray-700 w-24">
                  前回
                </td>
                <!-- No -->
                <td class="px-3 py-1 border border-gray-200 text-right col-no h-10 text-red-700">
                  {{ row.num }}
                </td>
                <!-- 内容 -->
                <td class="px-1 py-1 whitespace-pre-line text-sm min-200 h-10 text-red-700">
                  {{ row.note }}
                </td>
                <td>
                </td>
              </tr>
            </table>

          <div v-if="!toggleNA" class="toggle flex justify-start items-center mt-8 w-11/12 m-auto mb-2">
            <span class="text-gray-500 font-bold text-xs">点検項目並べ替え</span>
            <Toggle v-model="sortableItem" size="small"/>
            <PrimaryButton text="項目順番確定" size="xs" class="py-1 ml-10" :disabled="!isChangeSort||processing" :class="!isChangeSort?'bg-gray-300':'bg-indigo-700'" @click="setPatrolSortNo()" >
              <LoadingIcon v-if="processing" slot="before" class="h-4 w-4" />
            </PrimaryButton>
          </div>

          <draggable 
            key="item"
            :list="checkList.items"
            direction="vertical"
            handle=".row-drag-handle"
            @change="sortChanged"
          >
            <div v-for="(row, i) in checkList.items" :key="'r'+i" class="w-11/12 ml-auto mr-auto">
              <div v-if="(!row.complete || !completeBtn) && !row.disabled && !toggleNA">
                
                <div class="flex">
                  <!-- No -->
                  <div class="w-8 text-blue-700 font-bold">
                    <span v-if="!sortableItem&&row.sortNo>-1">{{ row.sortNo }}.</span>
                    <span v-else-if="row.sortNo<=-1">◆</span>
                    <MenuIcon v-else class="text-gray-400 row-drag-handle cursor-pointer" />
                  </div>

                  <!-- 区分 -->
                  <div class="text-blue-700 font-bold ">{{ row.classification }}</div>
                </div>

                <!-- 点検対象 -->
                <div class="ml-8 mb-1 flex">
                  {{ row.item }}
                  <span v-if="row.item.indexOf('残留塩素')!=-1" class="text-xs text-gray-400 font-bold mt-auto mb-1">（基準値≧0.1mg/L）</span>
                  <!-- カメラ -->
                  <div class="flex ml-auto">
                    <div v-if="!row.disabled && row.sortNo <= -1 && canEdit && start!=''" class="mr-3 flex">
                      <div class="file-btn rounded w-70px bg-darkgreen h-full">
                        <PrimaryButton :buttonContents="'要'" class="bg-darkgreen hover:bg-darkgreen w-70px">
                          <Icon slot="before" iconName="Camera" iconType="solid" :strokeWidth="2" class="text-white h-5 w-8 bg-darkgreen hover:bg-darkgreen" />
                        </PrimaryButton>
                        <input class="file-type w-70px bg-darkgreen" type="file" accept="image/*" capture="environment" @change="imageToBase64(i, $event)" multiple />
                      </div>
                    </div>
                    <!-- 完了ボタン -->
                    <PrimaryButton v-if="canEdit && row.sortNo <= -1" :text="processing?'':'完了'" size="bg-blue-50" class="text-blue-800 border border-blue-800 rounded font-bold w-86px py-1.5 comp-btn" @click="complete(i)" :disabled="processing" >
                      <LoadingIcon v-if="processing" slot="before" class="h-4 w-4 text-blue-600" />
                    </PrimaryButton>
                  </div>
                </div>

                <div v-if="row.item.indexOf('残留塩素')!=-1" class="flex ml-8">
                  <div class="-mt-1">
                    <WmsDateInput name="date" v-model="row.detail1" :min="minDate" />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="採水場所"
                      class="focus:border focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 px-2 w-full mb-1 border border-gray-300"
                      v-model="row.detail2"
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      placeholder="測定値"
                      class="focus:border focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 px-2 w-full mb-1 border border-gray-300"
                      v-model="row.cleaningText"
                    />
                  </div>
                </div>
              </div>

              <!-- 非表示の該当なしを表示した場合 -->
              <div v-if="row.disabled && toggleNA">
                <!-- No -->
                <div class="flex">
                  <div class="w-8 text-blue-700 font-bold">{{ row.sortNo }}.</div>
                  <div class="text-blue-700 font-bold ">{{ row.classification }}</div>
                </div>
              </div>

              <div v-if="(!row.complete || !completeBtn) && !row.disabled && !toggleNA && row.sortNo > -1">
                <!-- 良否 -->
                <div class="ml-8 flex">
                  <PrimaryButton text="要修繕" size="normal" class="w-full mr-2 px-0" :class="row.value!='0'?'bg-gray-400':''" :disabled="start==''||row.disabled||!canEdit" @click="setPoint('0', i)" />
                  <PrimaryButton text="要観察" size="normal" class="w-full mr-2 px-0" :class="row.value!='1'?'bg-gray-400':''" :disabled="start==''||row.disabled||!canEdit" @click="setPoint('1', i)" />
                  <PrimaryButton text="正常" size="normal" class="w-full mr-2 px-0" :class="row.value!='2'?'bg-gray-400':''" :disabled="start==''||row.disabled||!canEdit" @click="setPoint('2', i)" />
                  <PrimaryButton text="処置済" size="normal" class="w-full mr-2 px-0" :class="row.value!='3'?'bg-gray-400':''" :disabled="start==''||row.disabled||!canEdit" @click="setPoint('3', i)" />
                  <PrimaryButton text="点検不可" size="normal" class="w-full mr-2 px-0" :class="row.value!='-1'?'bg-gray-400':'bg-teal-700'" :disabled="start==''||row.disabled||!canEdit" @click="setPoint('-1', i)" />
                  <PrimaryButton v-if="canEdit" text="該当なし" size="normal" class="w-full px-0" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="start==''||row.disabled" @click="setPoint('N/A', i)" />
                  <PrimaryButton v-if="!canEdit" text="該当なし" size="normal" class="w-full px-0" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="true" />
                  <!-- カメラ -->
                  <div v-if="!row.disabled && row.value!='-1' && row.value!='N/A' && canEdit && start!=''" class="flex">
                    <div class="file-btn rounded w-70px bg-darkgreen h-full">
                      <PrimaryButton :buttonContents="'要'" class="bg-darkgreen hover:bg-darkgreen h-full w-70px">
                        <Icon slot="before" iconName="Camera" iconType="solid" :strokeWidth="2" class="text-white h-5 w-8 bg-darkgreen hover:bg-darkgreen" />
                      </PrimaryButton>
                      <input class="file-type w-70px bg-darkgreen" type="file" accept="image/*" capture="environment" @change="imageToBase64(i, $event)" multiple />
                    </div>
                  </div>
                  <!-- 非表示ボタン -->
                  <PrimaryButton v-if="canEdit && (row.value=='N/A' || row.value=='-1')" text="非表示" size="bg-dark-green" class="rounded text-xs text-white font-bold w-full" @click="disabledNA(i)" />
                  <!-- 完了ボタン -->
                  <PrimaryButton v-if="canEdit" :text="processing?'':'完了'" size="bg-blue-50" class="text-blue-800 border border-blue-800 rounded font-bold w-full ml-3 comp-btn" @click="complete(i)" :disabled="processing" >
                    <LoadingIcon v-if="processing" slot="before" class="h-4 w-4 text-blue-600" />
                  </PrimaryButton>
                </div>
              </div>
              
              <!-- 非表示の該当なしを表示した場合 -->
              <div v-if="row.disabled && toggleNA">
                <PrimaryButton text="表示" size="bg-dark-green" class="rounded text-xs text-white font-bold p-5p mb-5 w-1/3 h-10 ml-8" @click="enableNA(i)" />
              </div>

              <!-- 状況 -->
              <div v-if="canEdit && (!row.complete || !completeBtn) && !row.disabled && !toggleNA && row.sortNo > -1" class="mt-3 w-96pa ml-32p">
                <textarea 
                  name="note" 
                  :rows="getRowCount(row.note, 64)"
                  :placeholder="canEdit ? '状況' : ''"
                  class="text-gray-700 py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
                  v-model="row.note"
                  @change="[changeNote(i), temporarilyRegister('item', row)]"
                  :ref="'note_'+i"
                />
              </div>
              <div v-if="!canEdit && row.sortNo > -1" class="text-gray-700 mt-3 w-96pa ml-32p sm:text-sm p-1 whitespace-pre-line">
                {{ row.note }}
              </div>

              <div v-if="(!row.complete || !completeBtn) && !row.disabled && !toggleNA">
                <!-- 添付画像 -->
                <div class="ml-8 mt-3 flex items-start">
                  <div v-for="(p, j) in checkList.items[i].photo" :key="'p'+j" class="flex mr-3">
                    <a :href="fullImage" :data-lightbox="i * 100 + j" @click="clickImage(checkList.items[i].sortNo, i, j)">
                      <img :src="p" width="300">
                    </a>
                    <!-- 削除 -->
                    <div @click="deletePhoto(i, j)" v-if="p && p != '' && canEdit" class="ml-1 mt-auto mb-1">
                      <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-7 h-7" />
                    </div>
                  </div>

                  <!-- 前回の写真 -->
                  <div v-if="canEdit && checkList.items[i].lastPhoto" class="w-1/5 ml-auto p-1 bg-light-blue-100">
                    <div class="text-xs mb-1 text-center">
                      前回の写真
                    </div>
                    <div v-for="(lp, k) in checkList.items[i].lastPhoto" :key="'lp'+ k" class="flex mx-1">
                      <a :href="lp" :data-lightbox="i * 200" class="mb-1">
                        <img :src="lp" width="200">
                      </a>
                    </div>
                  </div>
                </div>
                <div class="text-transparent">{{reload}}</div>
              </div>
            </div>
          </draggable>

            <table v-if="!toggleNA" class="w-11/12 table-auto ml-auto mr-auto mt-3">
              <thead class="bg-blue-100">
                <tr class="border border-gray-200">
                  <th v-for="(c, index) in columns" :key="'c'+index" scope="col" class="border border-gray-200 py-1 text-center text-xs font-medium text-gray-500 tracking-wider bg-colu">
                    {{c.title}}
                  </th>
                </tr>
              </thead>
              <tr v-for="(row, i) in checkList.note" :key="'n'+i" class="border border-gray-200 bg-white tracking-wider">
                <!-- 時期 -->
                <td :rowspan="checkList.note.length" v-if="i==0"  class="cursor-pointer hover-color border border-gray-200 text-center py-1 whitespace-pre-line text-sm text-gray-700 w-24">
                  今回
                </td>
                <!-- No -->
                <td class="px-3 py-1 border border-gray-200 text-right col-no h-10 cursor-not-allowed">
                  <div>
                    {{ row.num }}
                  </div>
                </td>
                <!-- 内容 -->
                <td v-if="canEdit" class="px-1 py-1 whitespace-pre-line text-sm min-200">
                  <textarea 
                    name="note" 
                    :rows="getRowCount(row.note, 54)"
                    class="text-gray-700 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-none rounded"
                    v-model="row.note"
                    @change="changeNoteList(row)"
                  />
                </td>
                <td v-else class="px-1 py-1 whitespace-pre-line text-sm min-200">
                  {{ row.note }}
                </td>
              </tr>
              <div></div>
              <tr v-for="(row, i) in checkList.lastNote" :key="'ln'+i" class="border border-gray-200 bg-white tracking-wider">
                <!-- 時期 -->
                <td :rowspan="checkList.lastNote.length" v-if="i==0" class="border border-gray-200 text-center py-1 whitespace-pre-line text-sm text-gray-700 w-24">
                  前回
                </td>
                <!-- No -->
                <td class="px-3 py-1 border border-gray-200 text-right col-no h-10 text-red-700">
                  {{ row.num }}
                </td>
                <!-- 内容 -->
                <td class="px-1 py-1 whitespace-pre-line text-sm min-200 h-10 text-red-700">
                  {{ row.note }}
                </td>
                <td>
                </td>
              </tr>
            </table>
            <div v-if="!toggleNA" class="mt-3 w-11/12 m-auto mb-6">
              <div class="text-gray-700 text-sm">特記事項</div>
              <textarea 
                name="checkListMemo" 
                :multiline="true"
                :rows="memoCount"
                class="text-gray-700 w-full border-gray-300 rounded"
                v-model="checkList.memo"
                :disabled="!canEdit"
                @change="temporarilyRegister('memo', checkList.memo)"
              />
            </div>
        </div>
      </template>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import { TrashIcon, MenuIcon } from '@vue-hero-icons/solid'
import PrimaryButton from '@components/PrimaryButton.vue'
import Toggle from '@components/Toggle.vue'
import draggable from "vuedraggable"
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import LoadingIcon from '@assets/loading-circle.svg'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import moment from 'moment'
import * as logManager from '@managers/logManager'

const ITEMS = {
    // 区分
    classification: '',
    // 内容
    item: '',
    // 結果
    value: '',
    // 該当なし履歴
    valueNA: false,
    // 該当なし非表示
    disabled: false,
    // 写真
    photo: [],
    // 完了ボタン
    complete: false
  }

export default {
  components: {
    Icon,
    TrashIcon,
    MenuIcon,
    PrimaryButton,
    Toggle,
    draggable,
    WmsDateInput,
    LoadingIcon
  },

  props: {
    // 区分名
    typeName: {
      type: String,
      default: null
    },
    // 区分（1：品質巡回、2：巡回清掃、3：定期清掃、4：設備点検）
    type: {
      type: Number,
      default: null
    },
    // 親画面（予定一覧）
    routerName: {
      type: String,
      default: null
    },
    // 物件位置情報
    siteLocation: {
      type: Object
    },
  },

  data() {
    return {
      // クレーム対応か否か
      claim: false,
      // 表示対象予定詳細情報
      target: {},
      // 表示データ
      list: [],
      // 開始時間
      start: '',
      // 終了時間
      end: '',
      // 位置情報
      location: '',
      // チェック項目
      checkList: {
        items: [
          utils.clone(ITEMS)
        ],
        note:[
          {id: null, checklistId: null, num: '', note: ''}
        ],
        lastNote:[
          {id: null, checklistId: null, num: '', note: ''}
        ],
        memo: ''
      },
      // カラム
      columns: [
          {title: '時期'},
          {title: 'No.'},
          {title: '状況'},
          {title: ''}
      ],
      // 点検項目並べ替え
      sortableItem: false,
      // 編集できるか
      canEdit: true,
      // 備考の大きさ
      memoCount: 5,
      // 処理中
      processing: false,
      processingText: 'Loading...',
      // 写真更新
      reload: 0,
      // 完了ボタンが押されたか
      completeBtn: false,
      // 非表示の該当なしトグル
      toggleNA: false,
      // 添付画像 オリジナルデータ
      fullImage: '',
      // 全添付画像 オリジナルデータ
      fullImages: null,
      // 完了を非表示ボタン活性
      undoBtn: false,
      // 項目の並びを変更したか
      isChangeSort: false,
      // 日付選択最少日
      minDate: moment().format('YYYY-MM-DD')
    }
  },

  async created() {
    let val = this.$route.query.claim
    // booleanでない場合、うまくパラメータが渡せていないので、前画面に戻る
    if (typeof val !== 'boolean') {
      this.previous()
    }
    
    await this.getTarget()
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile || utils.deviceInfo.isMobile
    },
  },

  watch: {
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      if (this.$route.query.scheduleId) {
        this.processing = true
        this.processingText = 'Loading...'

        // スケジュールIDに紐づくデータを取得
        let schedule = await backend.searchData('patrolSchedule/getById', { id: this.$route.query.scheduleId })

        if (schedule.data.data && schedule.data.data.length) {
          this.target = schedule.data.data[0]
        } else {
          await dialogs.showErrorDialog('エラー', '対象データがありません。')
          return
        }
        this.processing = false

        this.list = this.target
        this.list.today = moment().format('YYYY/MM/DD')

        // 点検済み
        if (this.target.completionDate && this.target.completionDate != '') {
          this.canEdit = false
          this.list.today = moment(this.target.completionDate).format('YYYY/MM/DD')
          this.memoCount = 1

          // データ取得
          this.processing = true
          this.processingText = 'Loading...'
          
          let scheduledDate = moment().format('YYYY-MM-DD')
          // 完了している場合
          if (this.target.completionDate) {
            scheduledDate = moment(this.target.completionDate).format('YYYY-MM-DD')
          }
          const compR = await backend.searchData('patrolResult/getByPatrolScheduleId', { patrolScheduleId: this.target.id, clientCode: this.target.clientCode, siteCode: this.target.siteCode, scheduledDate, type: this.type, claim: this.claim, spot: false })
          if (compR.data && compR.data.data) {
            this.setData(compR.data.data)
            // 添付画像 取得
            this.getfullImages()
          }
          this.processing = false

        // 点検まだ
        } else {
          this.canEdit = true
          // 途中保存があり、途中から行う場合
          if (await this.temporary()) {
            return
          }
          
          // データを削除
          await backend.deleteDataByKey('patrolResult/deleteByPatrolScheduleId', { key: this.target.id })
          await this.initCheck()
        }
        this.processing = false
      }
    },

    /**
     * 保存している結果をセット
     */
    setData(r) {
      if (r.result.length) {
        let result = r.result[0]
        this.start = result.startTime
        if (result.endTime) {
          this.end = result.endTime
        } else {
          this.end = ''
        }
        this.location = result.location
      }
      if (r.item) {
        this.checkList.items = r.item
        // まだ終了していない場合
        if (!this.target.completionDate) {
          // 初期値設定
          this.checkList.items.forEach((it) => {
            if (it.item.indexOf('残留塩素') != -1) {
              if (!it.detail1) {
                it.detail1 = moment().format('YYYY-MM-DD')
              }
              return true
            }
          })
        }
      }
      if (r.note) {
        this.checkList.note = r.note
        for (let i = 0; i < this.checkList.items.length; i++) {
          const it = this.checkList.items[i]
          for (let j = 0; j < this.checkList.note.length; j++) {
            const no = this.checkList.note[j]
            if (it.sortNo == no.num) {
              it.note = no.note
              // 行数をセット
              // it.noteRows = this.getRowCount(it.note, 64)
              break
            }
          }
        }
      }
      if (r.lastNote) {
        this.checkList.lastNote = r.lastNote
      }
      if (r.memo && r.memo.length && r.memo[0].memo) {
        this.checkList.memo = r.memo[0].memo
        let c = 1
        if (this.checkList.memo != '') {
          let mIdx = 0
          for (let k = 0; k < this.checkList.memo.length; k++) {
            let memo = this.checkList.memo[k]
            if (memo == '\n') {
              mIdx = 0
            } else {
              mIdx++
              if (mIdx == 56) {
                const a = this.checkList.memo.slice(0, k)
                const b = this.checkList.memo.slice(k)
                this.checkList.memo = a + '\n' + b
                mIdx = 0
              }
            }
          }
          c = ( this.checkList.memo.match( /\n/g ) || [] ).length
        }
        this.memoCount = 5
        if (c + 2 > 5) {
          this.memoCount = c + 2
        }
      }
    },

    /**
     * 途中保存を取得
     */
    async temporary() {
      const temporaryS =  await backend.searchData('patrolTemporarySaving/isSaving', { patrolScheduleId: this.target.id })
      if (temporaryS && temporaryS.data && temporaryS.data.data.length) {
        const dialogR = await dialogs.showConfirmDialog('途中保存データあり', '途中保存しているデータがあります。\r\n途中から再開する場合➡「途中から」\r\n最初からやり直す➡「最初から」\r\nを押してください。', '途中から', '最初から')
        
        // 途中から再開する場合
        if (dialogR == 'YES') {
          this.processing = true
          this.processingText = 'Loading...'
          let temporaryR = []

          let param = { clientCode: this.target.clientCode, siteCode: this.target.siteCode, patrolScheduleId: this.target.id, scheduledDate: moment().format('YYYY-MM-DD'), type: this.type, claim: this.claim, spot: false }

          temporaryR =  await backend.searchData('patrolTemporarySaving/getByPatrolScheduleId', param)
          
          // 完了を表示ボタンを出す
          if (temporaryR.data.data.item && temporaryR.data.data.item.length) {
            for (let i = 0; i < temporaryR.data.data.item.length; i++) {
              const it = temporaryR.data.data.item[i]
              if (it.complete) {
                this.completeBtn = true
                break
              }
            }
          }
          
          this.setData(temporaryR.data.data)
          this.setBuildingPhoto()
          this.setNote()
          // 前回の写真をセット
          this.setLastPhoto(temporaryR.data.data.lastPhoto)
          this.processing = false

          // 添付画像 取得
          this.getfullImages()

          return true
        
        // 最初からやり直す場合
        } else {
          return false
        }
      }
      return false
    },

    /**
     * 点検前の点検表セット
     */
    async initCheck() {
      this.processing = true
      this.processingText = 'Loading...'

      // 物件コードが変更になっていないか確認
      let isChangeCode = await backend.searchData('checkListHistory/isChangeCode', { siteCode: this.target.siteCode, type: this.type, claim: this.claim, siteAddress: this.target.siteAddress, siteName: this.target.siteName, spot: false })
      if (isChangeCode.data && isChangeCode.data.data && isChangeCode.data.data.result) {
        this.processing = false
        const inf = isChangeCode.data.data.info
        const msg = `
          前回完了日：${inf.completionDate}
          物件名：${inf.siteName}
          住所：${inf.siteAddress}
        `
        const chagneCode = await dialogs.showConfirmDialog('物件コードが変わった可能性があります。', '過去の物件コードと新しい物件コードを一致させますか？\r\n新しい物件コードと一致させると過去のデータが引き継がれます。\r\n\r\n【同じと思われる過去のデータ】' + msg)
        this.processing = true
        // 過去の物件コードを変更する
        if (chagneCode == 'YES') {
          const updateRes = await backend.postData('checkListHistory/changingCode', { newSiteCode: this.target.siteCode, oldSiteCode: inf.siteCode, type: this.type, claim: this.claim, id: this.target.id, spot: false })
          if (updateRes && updateRes.status == 200) {
            //ログ出力
            logManager.recording(this, logManager.Loglevel.INFO, this.typeName, '物件コード変更', this.typeName + '（物件名：' + inf.siteName + '、物件コード：' + inf.siteCode + '、完了日：'+ inf.completionDate + '以前全て、予定ID：'+ inf.id +'以前全て）を（物件コード：' + this.target.siteCode + '）に変更しました。') 
          } else {
            alert('エラーが発生しました。')
            this.processing = false
          }
        }
      }

      // 点検表
      let cheR = await backend.searchData('checkListByClient/getByClientCode', { clientCode: this.target.clientCode, siteCode: this.target.siteCode, type: this.type })
      if (cheR.data && cheR.data.data && cheR.data.data.length) {
        this.checkList.items = cheR.data.data
        this.checkList.items.forEach((it) => {
          if (it.item.indexOf('残留塩素') != -1) {
              if (!it.detail1) {
                it.detail1 = moment().format('YYYY-MM-DD')
              }
            return true
          }
        })
      }
      this.setBuildingPhoto()

      // 前回の状況と特記事項と写真を取得
      let scheduledDate = moment().format('YYYY-MM-DD')
      // 完了している場合
      if (this.target.completionDate) {
        scheduledDate = moment(this.target.completionDate).format('YYYY-MM-DD')
      }

      const lastData = await backend.searchData('patrolResultNote/getByPatrolScheduleId', { siteCode: this.target.siteCode, type: this.type, claim: this.claim, scheduledDate, spot: false })
      if (lastData && lastData.data) {
        if (lastData.data.lastNote && lastData.data.lastNote.length) {
          // 前回の状況をセット
          this.checkList.lastNote = lastData.data.lastNote
          // 前回の状況を今回に反映
          this.setNote()
        }
        // 前回の特記事項をセット
        this.setLastMemo(lastData.data.lastMemo)
        // 前回の写真をセット
        this.setLastPhoto(lastData.data.lastPhoto)
      }
      this.processing = false
    },

    /**
     * 前回の状況を反映
     */
    setNote() {
      // 点検終了の場合は、何もしない
      if (this.target.completionDate) {
        return
      }
      for (let i = 0; i < this.checkList.items.length; i++) {
        const it = this.checkList.items[i]
        // 項目が未完了のもの、かつ一時保存でもない
        if (!it.complete && !it.temporarily) {
          for (let j = 0; j < this.checkList.lastNote.length; j++) {
            const l = this.checkList.lastNote[j]
            // 番号と項目名が同じであれば、項目表が変更になっていないということなので、紐づける
            if (it.sortNo == l.num && it.item == l.item) {
              it.note = l.note
              // 状況テーブルの「今回」に反映（画面上下にある表）
              this.changeNote(i)
            }
          }
        }
      }
    },

    /**
     * 前回の特記事項を反映
     * @param memo 特記事項
     */
    setLastMemo(memo) {
      // 前回の特記事項がある場合
      if (memo) {
        this.checkList.memo = memo
      }
    },

    /**
     * 前回の写真をセット
     * @param photo 前回の写真
     */
    setLastPhoto(photo) {
      if (photo && photo.length) {
        this.checkList.items.forEach((it) => {
          for (let i = 0; i < photo.length; i++) {
            const p = photo[i]
            // 番号と項目名が同じであれば、項目表が変更になっていないということなので、前回の写真を紐づける
            if (it.sortNo == p.sortNo && it.item == p.item) {
              if (!it.lastPhoto) {
                it.lastPhoto = []
              }
              it.lastPhoto.push(p.lastPhoto)
            }
          }
        })
      }
    },

    /**
     * 初期化
     */
    initData() {
      // 表示データ
      this.list = []
      // 開始時間
      this.start = ''
      // 終了時間
      this.end = ''
      // チェック項目
      this.checkList = {
        items: [
          {classification: '', item: '', value: '', valueNA: false, disabled: false, photo: [], complete: false}
        ],
        note: [
          {id: null, checklistId: null, num: '', note: ''},
        ],
        lastNote: [
          {id: null, checklistId: null, num: '', note: ''},
        ]
      }
      // カラム
      this.columns = [
          {title: '時期'},
          {title: 'No.'},
          {title: '状況'},
          {title: ''}
      ]
      this.openModal = 0
      // 編集できるか
      this.canEdit = true
      // 備考の大きさ
      this.memoCount = 5
      // 処理中
      this.processing = false
      // 完了ボタン
      this.completeBtn = false
      // 非表示の該当なしトグル
      this.toggleNA = false
      // 保存したデータ
      // this.registeredItem = []
      // 添付画像 オリジナルデータ
      this.fullImage = ''
      // 全添付画像 オリジナルデータ
      this.fullImages = null
      // 完了を非表示ボタン活性
      this.undoBtn = false
    },

    /**
     * 開始ボタン
     */
    async startBtn() {
      // 現在地と物件の位置を比較
      const user = this.$store.getters.user.user_name
      const userId = this.$store.getters.user.id
      this.location = await utils.patrolLocation({ ...this.target, ...this.siteLocation, user, userId })
      // 調査後に以下をリリース（ダイアログメッセージの内容も要変更…utils内）
      // if (!this.location) {
      //   return
      // }
      this.list.start = moment().format('HH:mm')
      this.start = moment().format('HH:mm')

      // 一時保存(特記事項memoは前回の分を引き継いでいるため、最初に引き継いだmemoを保存する)
      this.temporarilyRegister('memo', this.checkList.memo)
    },

    /**
     * 完了したデータを削除
     */
    async deleteCompData() {
      let flg = false
      for (let i = 0; i < this.checkList.items.length; i++) {
        const it = this.checkList.items[i]
        if (it.complete) {
          flg = true
          break
        }
      }
      // 1度でも完了ボタンを押していたら確認ダイアログ出す
      if (flg) {
        const canscelR = await dialogs.showConfirmDialog('キャンセル', '完了しているデータも元に戻ります。よろしいでしょうか？')
        if (canscelR != 'YES') {
          return false

        // 途中保存データを削除
        } else {
          await backend.deleteDataByKey('patrolResult/deleteByPatrolScheduleId', { key: this.target.id })
          this.initData()
          this.list = this.target
          this.list.today = moment().format('YYYY/MM/DD')
          await this.initCheck()
          return false
        }
      }
      // まだ1度も完了した項目がなければ、値のみ空にする
      return true
    },

    /**
     * キャンセルボタン
     */
    async cancelBtn() {
      let cancelR = await this.deleteCompData()
      if (!cancelR) {
        return
      }
      
      this.start = ''
      this.completeBtn = false
      this.checkList.items.forEach(i =>{
        if (!i.valueNA) {
          i.value = null
          i.disabled = false
        }
        i.note = ''
        i.complete = false
        i.photo = []
      })
      this.checkList.note = [
        {id: null, checklistId: null, num: '', note: '', noteRows: 1}
      ]
    },

    /**
     * 終了ボタン
     */
    async endBtn() {
      // 変更後に再保存していないもの
      let dirty = []
      // 完了しているか
      let isComp = true
      for (let i = 0; i < this.checkList.items.length; i++) {
        const it = this.checkList.items[i]
        if (!it.complete && !it.disabled) {
          isComp = false
          break
        }
      }

      // 完了ボタンが1つも押されていない場合は終了不可
      if (!isComp) {
        await dialogs.showErrorDialog('終了できません', '全ての完了ボタンを押してください。')
        return
      }
      // すべての値が選択されているか確認
      for (let i = 0; i < this.checkList.items.length; i++) {
        const ci = this.checkList.items[i]
        let num = `No.${ci.sortNo}`
        if (ci.sortNo < 0) {
          num = '◆'
        }
        const res = await this.validate(ci, '終了', `${num} 【${ci.classification}】の`)
        if (!res) {
          return
        }
        // if (ci.changeFlag) {
        //   let resultMsg = await dialogs.showConfirmDialog('完了している項目を変更しましたか？', `No.${ci.sortNo}【${ci.item}】を変更して「完了」していませんが変更したものに更新しますか？\r\n更新する場合は「はい」を押してください。`)
        //   if (resultMsg == 'YES') {
        //     // 再度保存するデータ
        //     dirty.push(ci)
        //   }
        // }
      }
      if (this.isChangeSort) {
        const dlog = await dialogs.showConfirmDialog('項目順番確定しておりません', '項目を並べ替えた後に「項目順番確定」ボタンを押していないようですが、確定せずに終了してよろしいでしょうか？')
        if (dlog != 'YES') {
          return
        }
      }

      this.end = moment().format('HH:mm')
      
      await this.allRegister(dirty)
    },

    /**
     * 保存時に必要なデータを生成
     */
    setSaveData() {
      return {
        patrolScheduleId: this.target.id,
        clientCode: this.target.clientCode,
        siteCode: this.target.siteCode,
        completionDate: this.list.today,
        startTime: this.start,
        endTime: this.end,
        insertUser: this.$store.getters.user.id,
        location: this.location,
      }
    },

    /**
     * 終了ボタン押下時の最終保存
     */
    async allRegister(dirty) {
      this.processing = true
      this.processingText = '保存中...'
      
      try {
        let save = this.setSaveData()
        // 巡回結果保存
        await backend.postData('patrolResult/save', { save, item: this.checkList.items, note: this.checkList.note, memo: this.checkList.memo, dirty, key: this.target.id, type: this.type, claim: this.claim, spot: false })
        
        this.processing = false
      } catch (err) {
        alert("エラーが発生しました。")
        console.log('エラーが発生しました。')
        console.log(err)
        this.processing = false
        // 再度、終了ボタンが押せるようにする
        this.end = ''
      }

      //ログ出力
      logManager.recording(this, logManager.Loglevel.INFO, this.typeName, '完了', this.typeName + '（期限日：'+ moment(this.list.termDate).format('YYYY-MM-DD')  + '、担当者：'+ this.list.staff + '、物件名：'+ this.list.siteName +'）を完了しました。')  
      this.previous()
      this.initData()
    },

    /**
     * 一時保存処理
     * @param itemName 変更になった項目
     *   （開始ボタン：start、状況表：note、項目：item、特記事項：memo、写真：photo、写真削除：deletePhoto)
     * @param val 登録内容
     */
    temporarilyRegister(itemName, val) {
      try {
        let save = this.setSaveData()
        let note = null
        let item = null
        let memo = null
        let photo = null
        let deletePhoto = null

        // 特記事項の表
        if (itemName == 'note') {
          note = val
        // 項目
        } else if (itemName == 'item') {
          item = val
        // 備考
        } else if (itemName == 'memo') {
          memo = val
        // 写真
        } else if (itemName == 'photo') {
          photo = val
        // 写真削除
        } else if (itemName == 'deletePhoto') {
          deletePhoto = val
        }
        backend.postData('patrolResult/temporarily', { save, note, item, memo, photo, deletePhoto, type: this.type })
      } catch (err) {
        alert("エラーが発生しました。")
        console.log('エラーが発生しました。')
        console.log(err)
        this.processing = false
      }
    },

    /**
     * 各項目の完了ごとの登録処理
     * @param i 対象項目インデックス
     */
    async register(i) {
      this.processing = true
      this.processingText = '保存中...'

      try {
        let save = this.setSaveData()

        // 完了した対象の項目を保存(値・指摘事項・写真)
        await backend.postData('patrolResult/itemAndNoteSave', { save, item: this.checkList.items[i] })
        
        this.processing = false
      } catch (err) {
        alert("エラーが発生しました。")
        console.log('エラーが発生しました。')
        console.log(err)
        this.processing = false
        this.checkList.items[i].changeFlag = true
        this.checkList.items[i].complete = false
      }
    },

    /**
     * バリデート
     * @param row 対象データ
     * @param text 完了・終了
     * @param msg チェック箇所
     */
    async validate(row, text, msg) {
      // 建物全体の場合
      if (row.sortNo == -1) {
        if (!row.photo || !row.photo.length) {
          await dialogs.showErrorDialog(text + 'できません', msg + '建物の写真を添付してください。')
          return false
        }

      // 建物全体・確認項目以外の項目
      } else if (row.value != 'N/A' && row.value != '-1') {
        if (!row.value || row.value == '') {
          await dialogs.showErrorDialog(text + 'できません', msg + '良否が選択されていません。')
          return false
        } 
        if (row.value == '0' || row.value == '1') {
          if (!row.note || row.note == '') {
            await dialogs.showErrorDialog(text + 'できません', msg + '状況を記入してください。')
            return false
          }
          if (row.value == '0' || row.value == '1') {
            if (!row.photo || !row.photo.length) {
              await dialogs.showErrorDialog(text + 'できません', msg + '「要修繕」「要確認」の場合は、写真を添付してください。')
              return false
            }
          }
        }
        if (row.item.indexOf('残留塩素') > -1) {
          if (!row.detail1) {
            await dialogs.showErrorDialog(text + 'できません', msg + '日付を選択してください。')
            return false
          } else if (!row.detail2) {
            await dialogs.showErrorDialog(text + 'できません', msg + '採水場所を記入してください。')
            return false
          } else if (!row.cleaningText) {
            await dialogs.showErrorDialog(text + 'できません', msg + '測定値を記入してください。')
            return false
          }
        }
      }
      return true
    },

    /**
     * 点数ボタン
     * @param value 点
     * @param idx 対象データインデックス
     */
    setPoint(value, idx) {
      // 完了を再表示している場合
      if (this.undoBtn) {
        // 変更したらフラグを立てる
        this.checkList.items[idx].changeFlag = true
      }
      if (this.checkList.items[idx].value == value) {
        this.checkList.items[idx].value = null
      } else {
        this.checkList.items[idx].value = value
      }

      // 指摘事項へフォーカス設定
      this.$nextTick(() => {
        if (this.checkList.items[idx].value=='0' || this.checkList.items[idx].value=='1') {
          this.$refs['note_' + idx][0].focus()
        }
      })
    },

    /**
     * 特記事項変更イベント
     * @param i 項目インデックス
     */
    changeNote(i) {
      const item = this.checkList.items[i]
      
      this.reload++
      // 行の高さを取得
      // item.noteRows = this.getRowCount(item.note, 64)
      for (let j = 0; j < this.checkList.note.length; j++) {
        const note = this.checkList.note[j]
        // 編集の場合
        if (note.num == item.sortNo) {
          note.note = item.note
          if (note.note == '') {
            this.checkList.note.splice(j, 1)
          }
          if (!this.checkList.note.length) {
            this.checkList.note.push({id: null, checklistId: null, num: '', note: ''})
          }
          return
        }
      }

      // 空行を削除
      for (let k = this.checkList.note.length-1; k >= 0; k--) {
        const note2 = this.checkList.note[k]
        if (note2.num == '' && note2.note == '') {
          this.checkList.note.splice(k, 1)
        }
      }
      if (item.note != '') {
        // まだ下の表に同じ番号の記載がない場合
        this.checkList.note.push({id: null, checklistId: null, num: String(item.sortNo), note: item.note})
      }
      if (!this.checkList.note.length) {
        this.checkList.note.push({id: null, checklistId: null, num: '', note: ''})
      }
    },

    /**
     * 指摘事項表変更イベント
     * @param note 対象指摘事項
     */
    changeNoteList(note) {
      // 一時保存
      this.temporarilyRegister('note', this.checkList.note)
      if (!note.num) {
        return
      }
      for (let i = 0; i < this.checkList.items.length; i++) {
        const it = this.checkList.items[i]
        if (it.sortNo == note.num) {
          it.note = note.note
          // it.noteRows = this.getRowCount(note.note, 64)
        }
      }
      this.reload++
    },

    /**
     * 写真添付Base64に変換
     * @param i index
     */
    async imageToBase64 (i, event) {
      // 完了を再表示している場合
      if (this.undoBtn) {
        // 変更したらフラグを立てる
        this.checkList.items[i].changeFlag = true
      }
      // 選択されたファイルの取得
      const file = event.target.files
      if (!this.checkList.items[i].photo) {
        this.checkList.items[i].photo = []
      }
      // Base64に変換する
      for (let j = 0; j < file.length; j++) {
        const reader = new FileReader()
        const f = file[j]

        reader.readAsDataURL(f)
        // 変換
        const compressedImage = await new Promise((resolve) => {
          reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            
            img.onload = () => {
              const compressedDataUrl = this.compressImage(img, null)
              resolve(compressedDataUrl)
            }
          }
        })
        this.checkList.items[i].photo.push(compressedImage)
        this.reload++
        this.temporarilyRegister('photo', this.checkList.items[i])
      }
    },
    /**
     * 画像圧縮
     * @param {dataURL} img 
     * @param dt 写真撮影日時
     */
      compressImage(img, dt) {
      // 画像のサイズを変更するためにリサイズ処理
      const maxWidth = 854 // リサイズ後の最大幅
      let width = img.width
      let height = img.height

      if (width > maxWidth) {
        height *= maxWidth / width
        width = maxWidth
      }
      // 新しいキャンバスを作成
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height

      // キャンバスに描画するためのコンテキストを取得
      const ctx = canvas.getContext('2d')
      // 画像をキャンバスに描画せずに直接リサイズ
      ctx.drawImage(img, 0, 0, width, height)
      // 写真撮影日時を写真に描画
      utils.setDateToImg(dt, canvas, ctx, false)
      // キャンバスの画像を再度DataURLに変換して圧縮率を下げる
      const compressedDataUrl = canvas.toDataURL('image/jpeg', 1.0) // 圧縮率を設定
      return compressedDataUrl
    },

    /**
     * 写真削除
     * @param i 項目インデックス
     * @param j 写真インデックス
     */
    deletePhoto(i, j) {
      // 完了を再表示している場合
      if (this.undoBtn) {
        // 変更したらフラグを立てる
        this.checkList.items[i].changeFlag = true
      }
      this.checkList.items[i].photo.splice(j, 1)
      this.reload++

      // 一時保存
      this.temporarilyRegister('deletePhoto', { item: this.checkList.items[i], deleteIdx: j })
    },

    /**
     * 非表示ボタン
     * @param i 対象項目
     */
    async disabledNA(i) {
      const dr = await dialogs.showConfirmDialog('非表示にします', '次回からもこの項目は非表示にします。\r\n設定変更する場合は、【非表示の「該当なし」】ボタンを押してください。')
      if (dr != 'YES') {
        return
      }
      // 変更フラグをfalseにする
      this.checkList.items[i].changeFlag = false
      this.checkList.items[i].disabled = true
    },

    /**
     * 該当なし表示ボタン
     * @param i 対象項目
     */
    async enableNA(i) {
      this.checkList.items[i].disabled = false
      this.checkList.items[i].complete = false
    },

    /**
     * 完了ボタン
     * @param i 対象項目
     */
    async complete(i) {

      if (this.start == '') {
        await dialogs.showErrorDialog('開始ボタン', '開始ボタンを押してください。')
        return
      }
      const res = await this.validate(this.checkList.items[i], '完了', '')
      if (!res) {
        return
      }

      // 変更フラグをfalseにする
      this.checkList.items[i].changeFlag = false
      this.checkList.items[i].complete = true
      this.undo()
      this.reload++

      // 保存
      this.register(i)
    },

    /**
     * 完了を表示ボタン
     */
    reDisplay() {
      this.completeBtn = false
      this.undoBtn = true
    },

    /**
     * 完了ボタン押下の状態に戻す
     */
    undo() {
      this.completeBtn = true
      this.undoBtn = false
    },

    // 添付画像 取得
    async getfullImages() {
      const res = await backend.searchData('patrolResult/getPhoto', { patrolId: this.target.id, type: this.type })
      if (res.data) this.fullImages = res.data
    },

    /**
     * 画像 クリック イベント
     * @param i 対象項目
     * @param j 画像インデックス
     */
    async clickImage(sortNo, i, j) {
      const key = this.target.id + '/' + sortNo + '/' + j
      if (this.fullImages && this.fullImages[key]) this.fullImage = this.fullImages[key]
      else this.fullImage = this.checkList.items[i].photo[j]
    },

    /**
     * 前の「予定一覧_詳細」画面に戻る
     */
    previous() {
      this.$router.push({ 
        name: this.routerName,
        params: {
          checkListDate: this.target.scheduledDate,
          userId: this.target.userId,
          name: this.target.staff
        },
        query: {
          checkList: 'true'
        }
      })
    },

    /**
     * 項目順番確定ボタン
     */
    async setPatrolSortNo() {
      this.processing = true
      this.processingText = '保存中...'
      this.checkList.items.forEach((item, i) => {
        item.patrolSortNo = i
      })
      // データ保存
      const save = {
        patrolScheduleId: this.target.id,
        clientCode: this.target.clientCode,
        siteCode: this.target.siteCode,
        insertUser: this.$store.getters.user.id
      }
      await backend.postData('checkListHistory/saveSort', { save, item: this.checkList.items, type: this.type, claim: this.claim, spot: false })
      this.processing = false
      this.isChangeSort = false
    },

    /**
     * 項目並べ替え変更イベント
     */
    sortChanged(arg) {
      if (arg.moved) {
        this.isChangeSort = true
      }
    },

    /**
     * 建物全体の写真項目を追加
     */
    setBuildingPhoto() {
      let flg = false
      // let flg2 = false
      for (let i = 0; i < this.checkList.items.length; i++) {
        const item = this.checkList.items[i]
        if (item.sortNo == -1) {
          flg = true
          break
        // } else if (item.sortNo == -2) {
        //   flg2  = true
        }
      }
      // 建物全体の項目が無ければ、一番最初に追加
      if (!flg) {
        const row = [utils.clone(ITEMS)]
        row[0].sortNo = -1
        row[0].classification = '建物全体'
        row[0].item = '建物全体の写真を添付してください。'
        this.checkList.items = row.concat(this.checkList.items)
      }
      // キーボックスの項目が無ければ、一番最初に追加
      // if (!flg2) {
      //   const row = [utils.clone(ITEMS)]
      //   row[0].sortNo = -2
      //   row[0].classification = '確認事項'
      //   row[0].item = 'キーボックス番号を確認し、概要書を更新した写真を添付して下さい。'
      //   this.checkList.items = row.concat(this.checkList.items)
      // }
    },

    /**
     * 状況の文言の長さを取得
     */
    getRowCount(note, num) {
      if (!note) {
        return 1
      }

      return utils.getTextRowCount(note, num)
    },

    /**
     * 状況インプットイベント
     * @param event 入力イベント情報
     * @param i インデックス
     */
    // inputNote(event, i) {
    //   // 改行の場合
    //   if (event.composed && event.data == null && event.inputType.indexOf('Break') > -1) {
    //     this.checkList.items[i].noteRows++
    //     this.reload++
    //   }
    // }
  }
}
</script>
<style scoped>
.mobile-content {
  width: 1000px !important;
  /* height: 98vh !important; */
  overflow: auto;
  padding: 10px;
}

.pc-content {
  width: 1000px;
  height: calc(100vh - 20px);
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: auto;
  padding: 10px;
  border: solid 1px rgb(206, 206, 246);
  border-radius: 10px;
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.max-w-lg {
  max-width: none !important;
}

.col-no {
  width: 70px;
}

.hover-color:hover {
  color: rgb(11, 118, 200);
}

.bg-orange {
  background-color: rgb(231 137 70);
}

.bg-orange:hover {
  background-color: rgb(239 111 20);
}

.vsa-item {
  background-color: rgb(214, 238, 243);
}

.file-btn {
  /* border: 3px solid blue;
  background-color: rgb(75, 191, 152); */
  display: inline-block;
  position: relative;
}

.w-70px {
  width: 70px;
}

.file-type {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

/* safari用のスタイル */
_::-webkit-full-page-media,
_:future,
:root .file-type {
  height: 100%;
  top: 0;
  left: 0;
  width: 70px;
  opacity: 0;
  position: absolute;
}

/* safari用のスタイル */
_::-webkit-full-page-media,
_:future,
:root .comp-btn {
  position: relative;
  z-index: 50;
  width: 100%;
  min-width: 4rem !important;
}

.mr-73px {
  margin-right: 73px;
}

.bg-darkblue {
  background: #010178;
}

.bg-darkgreen {
  background: #010178;
}

.ml-32p {
  margin-left: 32px;
}

.w-96pa {
  width: 96%;
}

.edit {
  border: 1px solid #053a3a;
}

.p-5p {
  padding: 5px;
}

.p-6p {
  padding: 6px;
}

.w-354 {
  width: 354px;
}

.w-370 {
  width: 370px;
}

.info-group {
  display: flex;
}

.info1 {
  width: 50%;
}

.info2 {
  width: 50%;
  margin-left: auto;
}
</style>