<template>
  <div>
    <PatrolTermComponent
      typeName="巡回清掃 施工月設定"
      :type="2"
      settionStrageName="RoundTermStorageItemName">
    </PatrolTermComponent>
  </div>
</template>

<script>
import PatrolTermComponent from '../../Patrol/components/PatrolTermComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    PatrolTermComponent
  },
}
</script>