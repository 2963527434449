<script>
import * as utils from '@libs/utils'

/**
 * ページ 基底 モデル
 */
export default {
  async created() {
    // PC ストレージ間 認証情報 コピー
    utils.sendAuthInfo()
  },

  mounted() {
    // PC ローカルストレージの認証情報 削除イベント設定
    window.addEventListener('beforeunload', () => {
      utils.removeAuthInfoByPc()
    })
  },

  methods: {
  }
}
</script>