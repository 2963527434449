<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app overflow-auto">
            <div class="flex mb-1">
              <PrimaryButton text="マスタ新規作成" size="normal" class="bg-pink-400 hover:bg-pink-500 w-full mr-1" @click="newList()" />
              <PrimaryButton text="このマスタを編集" size="normal" class="bg-teal-500 hover:bg-teal-600 w-full mr-1" @click="edit()" />
              <PrimaryButton text="このマスタを引用" size="normal" class="w-full ml-1" @click="decide()" />
            </div>
          
          <div class="flex flex-col border border-gray-300 rounded bg-white">
            <div class="my-4">  
              <Tab 
                :tabs="nameList" 
                @change="nameChange($event.checkListId)"
              />
            </div>
            <div class="overflow-auto flex-1">
              <table class="min-w-full divide-y divide-gray-200 table-auto">
                <thead class="bg-green-50">
                <tr>
                  <th v-for="(c, index) in columns" :key="'c2'+index" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-colu">
                    {{c.title}}
                  </th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in list" :key="index" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                    <td class="px-6 py-4 text-sm font-medium text-gray-900 whitespace-pre-line">
                      {{ index+1 }}.
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-green-700 font-bold">
                      {{ row.classification }}
                    </td>
                    <td v-if="type==3" class="px-6 py-4 whitespace-nowrap text-sm text-gray-700 font-bold">
                      {{ row.cleanType }}
                    </td>
                    <td v-if="detail" class="px-6 py-4 whitespace-nowrap text-sm text-blue-700 font-bold">
                      {{ row.detail1 }}
                    </td>
                    <td v-if="detail&&list[0].checkListId!=3&&list[0].checkListId!=4" class="px-6 py-4 whitespace-nowrap text-sm text-blue-500 font-bold">
                      {{ row.detail2 }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 min-500">
                      {{ row.item }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <!-- 項目設定 -->
    <CheckListMasterSettingModal 
      v-model="openCheckListMasterSettingModal"
      :target="editData"
      :mode="mode"
      :masterDataEdit="list"
      :typeName="typeName"
      :type="type"
      :open="openModal"
      @modalClose="modalCloseCheckListMasterSettingModal"
    />
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import Tab from '@components/Tab.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import CheckListMasterSettingModal from '../components/CheckListMasterSettingModal.vue'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'

const ckeckListRow = [{checkListId: null, name: '', sortNo: null, classification: '', item: ''}]

export default {
  components: {
    Modal,
    Tab,
    PrimaryButton,
    CheckListMasterSettingModal
  },

  props: {
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    // 区分（1：品質巡回、2：巡回清掃、3：定期清掃、4：設備点検）
    type: {
      type: Number,
      default: null
    },
    open: {
      type: Number,
      default: null
    },
  },

  data() {
    return {
      // 区分リスト
      nameList: [],
      // カラム
      columns: [
        {title: 'No.'},
        {title: '区分'},
        {title: 'チェックポイント'},
      ],
      // 全データ
      original: [],
      // 表示データ
      list: utils.clone(ckeckListRow),
      // 詳細項目あり
      detail: false,
      // モーダル
      openCheckListMasterSettingModal: false,
      editData: {},
      mode: '',
      openModal: 0,
    }
  },

  computed: {
  },

  watch: {
    open() {
      this.getTarget()
    },
    
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      
      const result = await backend.searchData('checkListTemplate/get', { type: this.type })
      const result2 = await backend.searchData('checkListTemplate/getCheckListId', { type: this.type })
      this.original = []
      if (result.data.data) {
        this.original = result.data.data
      }

      // タブ生成
      this.nameList = []
      if (this.original.length) {
        let nl = result2.data.data
        this.nameList = nl.map((n) => {
          return { ...n, active: false }
        })
      }

      // 1番目のタブを表示
      if (this.nameList.length) {
        this.nameList[0].active = true
        this.nameChange(this.nameList[0].checkListId)
      }
    },

    /**
     * カラムをセット
     */
    setColumn() {
      if (this.list[0].detail1 || this.list[0].detail2) {
        this.detail = true
      } else {
        this.detail = false
      }

      // 定期清掃
      if (this.type == 3) {
        this.columns = utils.setColumn(this.detail, this.list[0].checkListId)
      }
    },

    /**
     * 初期化
     */
    closed() {
      // 区分リスト
      this.nameList = [],
      // カラム
      this.columns = [
        {title: 'No.'},
        {title: '区分'},
        {title: 'チェックポイント'},
      ],
      // 全データ
      this.original = [],
      // 表示データ
      this.list = utils.clone(ckeckListRow),
      
      this.$emit('modalClose')
    },

    /**
     * 区分変更イベント
     * @param tab 対象データ
     */
    nameChange(checkListId) {
      this.list = []
      this.list = this.original.filter((o) => {
        return o.checkListId == checkListId
      })
      this.setColumn()
    },

    /**
     * 新規
     */
    newList() {
      this.editData = { checkListId: null, name: '' }
      this.mode = 'new'
      this.openCheckListMasterSettingModal = true
      this.openModal++
    },

    /**
     * 編集
     */
    edit() {
      this.editData = { checkListId: this.list[0].checkListId, name: this.list[0].name }
      this.mode = 'edit'
      this.openCheckListMasterSettingModal = true
      this.openModal++
    },

    /**
     * 決定
     */
    decide() {
      this.$emit('decidedMaster', this.list)
      this.closed()
    },

    /**
     * 項目設定モーダル閉じる
     */
    async modalCloseCheckListMasterSettingModal() {
      this.openCheckListMasterSettingModal = false
      await this.getTarget()
    }
  }
}
</script>
<style scoped>
.content-container-app {
  /* width: 930px; */
  max-width: 90vw;
  height: calc(100vh - 130px);
  overflow: auto;
  /* padding: 10px; */
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.max-w-lg {
  max-width: none !important;
}

.col-no {
  width: 70px;
}

.hover-color:hover {
  color: rgb(11, 118, 200);
}

.min-210 {
  min-width: 210px;
}

.min-300 {
  min-width: 300px;
}

.min-500 {
  min-width: 575px;
}


@media screen and (max-width: 910px) {
  .content-container-app {
    width: 900px !important;
    height: calc(100vh - 90px);
    overflow: auto;
  }

}
</style>