<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div class="overflow-hidden rounded main-height bg-white">
      <div class="main-height overflow-auto">
        <div class="flex justify-between sticky top-0 bg-white">
          <div class="font-bold title mb-3">
            日報のChatworkです
          </div>
          <div class="py-1 px-2 mt-auto cursor-pointer" @click="add()">
            <Icon
              iconName="PlusCircle"
              class="w-8 h-8 text-blue-800"
            />
          </div>
        </div>
        <table class="overflow-auto main table-auto mb-3">
          <thead class="main sticky top-10 bg-white">
            <tr v-if="list && list.length" class="border border-gray-200">
              <th v-for="(c, index) in columns" :key="index" scope="col" class="pl-4 pr-1 py-3 text-xs font-medium text-gray-500 tracking-wider bg-colu text-left" :class="c.class">
                {{c.title}}
              </th>
            </tr>
          </thead>

          <tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider hover-color">

            <!-- ユーザー -->
            <td class="pl-3 pr-1 py-0.5 text-gray-500">
              <!-- 新規 -->
              <select
                v-if="!row.id"
                name="userName"
                v-model="row.userName"
                class="focus:ring-indigo-500 focus:border-indigo-500 block border-gray-300 w-full text-sm rounded"
                @change="edit(row, $event)"
              >
                <option
                  v-for="(c, ci) in userList" :key="'user'+ci"
                  :value="c.userName"
                  class="bg-white">
                  {{ c.userName }}
                </option>
              </select>

              <!-- 登録済み -->
              <div v-else class="text-sm">
                {{ row.userName }}
              </div>
            </td>

            <!-- ルームID -->
            <td class="pl-3 pr-1 py-0.5 text-gray-500">
              <input
                type="number"
                class="border border-gray-300 rounded-md focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-32 py-1.5"
                v-model="row.roomId"
                @input="edit(row)"
              />
            </td>

            <!-- トークン -->
            <td class="pl-3 pr-1 py-0.5 text-gray-500">
              <input
                type="text"
                class="border border-gray-300 rounded-md focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 text-xs w-60 py-2.5" 
                v-model="row.apiToken"
                @input="edit(row)"
              />
            </td>

            <td class="px-1 py-0.5 text-center text-sm font-medium border-gray">
              <div class="flex justify-center">
                <PrimaryButton text="保存" size="normal" class="w-16 p-1" :disabled="!row.editFlg" :class="!row.editFlg?'bg-gray-300':'bg-blue-800'" @click="save(row)" />
                <PrimaryButton text="削除" size="normal" class="ml-2 w-16 p-1 bg-red-700" @click="remove(row, i)" />
              </div>
            </td>
          </tr>
        </table>

      </div>
      <div v-if="!loading && (!list || !list.length)" class="flex justify-center items-center text-gray-500 font-bold nothing">
        <p class="text-gray-400 font-bold">対象データがありません</p>
      </div>
      <div class="text-transparent">{{reload}}</div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import Icon from '@components/Icon.vue'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import * as backend from '@libs/backend'

export default {
  components: {
    Modal,
    PrimaryButton,
    Icon
  },

  props: ['open'],

  data() {
    return {
      // ローディング
      loading: false,
      // カラム
      columns: [
        {title: '対象のユーザー'},
        {title: 'ルームID'},
        {title: 'APIトークン'},
        {title: ''},
      ],
      // リスト
      list: [
        { editFlg: false, userName: '', roomId: null }
      ],
      // ユーザーリスト
      userList: [],
      reload: 0
    }
  },

  computed: {
  },

  watch: {
    open() {
      if (this.open) {
        this.getData()
      }
    }
  },

  methods: {
    /**
     * 初期にリセット
     */
    closed() {
      // リスト
      this.list = [
        { editFlg: false, userName: '', roomId: null }
      ]
      // ユーザーリスト
      this.userList = []
      this.loading = false
      this.$emit('close')
    },

    /**
     * リスト取得
     */
    async getData() {
      this.loading = true
      this.list = []
      this.userList = []
      const res = await backend.getData('evaluation/getDailyReportChat')
      if (res.data.data) {
        this.list = res.data.data.chat
        for (let i = 0; i < res.data.data.userList.length; i++) {
          const u = res.data.data.userList[i]
          const name = (utils.deleteKana(u.userName))
          let flg = false
          for (let j = 0; j < this.list.length; j++) {
            const l = this.list[j]
            if (utils.deleteSpace(l.userName) == utils.deleteSpace(name)) {
              flg = true
            }
          }
          if (!flg) {
            this.userList.push({ userName: name })
          }
        }
      }
      this.loading = false
    },

    /**
     * 編集ボタンイベント
     * @param row 編集対象データ
     */
    edit(row) {
      row.editFlg = true
      this.reload++
    },

    /**
     * 保存
     * @param row 対象データ
     */
    async save(row) {
      if (!row.userName) {
        await dialogs.showErrorDialog('対象のユーザー', '未選択です。対象のユーザーを選択してください。')
        return
      }
      if (!row.roomId) {
        await dialogs.showErrorDialog('ルームID', '未入力です。ルームIDを入力してください。')
        return
      }

      // ダブりがないかチェック
      const double = this.list.filter((l) => {
        return utils.deleteSpace(l.userName) == utils.deleteSpace(row.userName)
      })
      if (double.length > 1) {
        await dialogs.showErrorDialog('重複のため保存不可', 'すでに同じユーザーのデータがあります。')
        return
      }

      // 同じルームIDがないかチェック
      const room = this.list.filter((r) => {
        return r.roomId == row.roomId
      })
      if (room.length > 1) {
        const dia = await dialogs.showConfirmDialog('ルームID重複あり', 'すでに同じルームIDが登録されていますが、よろしいでしょうか？')
        if (dia != 'YES') {
          return
        }
      }
      const saveData = utils.clone(row)
      delete saveData.editFlg
      saveData.userName = utils.deleteSpace(row.userName)
      const res = await backend.postData('evaluation/saveDailyReportChat', saveData)
      if (res.status==200) {
        await dialogs.showSuccessDialog('保存しました')
        row.editFlg = false
        this.reload++
        // ログ登録
        let newFlag = true
        if (row.id) {
          newFlag = false
        }
        logManager.recordingByRegist(this, newFlag, '日報Chatwork', `${row.userName}のChatworkルームID`)
      }
    },

    /**
     * 削除
     * @param row 対象データ
     * @param i インデックス
     */
    async remove(row, i) {
      if (row.id) {
        const res = await dialogs.showConfirmDialog('削除してよろしいですか？', `${row.userName}のChatworkルームIDを削除します。`)
        if (res != 'YES') {
          return
        }
        await backend.deleteData('evaluation/deleteDailyReportChat', row, false)
        await dialogs.showSuccessDialog('削除しました')
      }
      this.list.splice(i, 1)
      this.getData()
      // ログ登録
        logManager.recording(this, logManager.Loglevel.INFO, '日報Chatwork', '削除', `${row.userName}のChatworkルームIDを削除しました。`)
    },

    /**
     * 追加ボタン
     */
    add() {
      this.list.push({ editFlg: false, userName: '', roomId: null, apiToken: '12e6247b22aa5826875a69997587ea01' })
    }
  }
}
</script>
<style scoped>
.main-height {
  height: 85vh;
}

.h-95vh {
  height: 95vh;
}

.h-93vh {
  height: 93vh;
}

.main {
  min-width: 700px;
}

.b-top {
  border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
  background: #edecf7;
}

.hover-color:hover {
  background: #edecf7 !important;
}

.title {
  color: #928ec7;
}
</style>