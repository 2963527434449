<template>
  <div>
    <PageHeader title="User Master" class="list">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">

          <Icon 
            iconName="Refresh" 
            :clickable="false" 
            :class="{'animate-spin': loading}" 
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer"
            @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="pl-4 flex items-center">
          <h1 class="text-lg font-bold text-white flex items-center">            
            <Icon iconName="UserGroup" class="w-7 h-7 mr-2" /> <span class="mt-0">ユーザー 一覧</span>
          </h1>
          
          <!-- 新規登録ボタン 権限のある人のみ表示 -->
          <div class="flex-1 pl-2 flex ml-6 justify-end items-center">
            <div>
              <PrimaryButton text="新規" size="normal hover:bg-pink-400" class="new" @click="edit({})"/>
            </div>
          </div>
        </div>
      </template>
    </PageHeader>

    
    <main class="absolute top-32 w-screen bottom-4 -mt-2 leading-8 row">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full">
          <table class="table text-sm">
            <thead class="th sticky top-0 z-20">
              <tr class="column">
                <th class="th2 col1 th-lock">表示順</th>
                <th class="th2 col2 th-lock">ID</th>
                <th class="th2 text-left col3 th-lock">名前/メールアドレス</th>
                <th class="th1 col4 th-lock"></th>
                <!-- 権限 -->
                <th class="th2" v-tooltip="'マスタメンテ編集可能、ログ一覧閲覧可能'">管理者<br> 権限</th>
                <th class="th2" v-tooltip="'ユーザー設定可能'">ユーザー<br> 権限</th>
                <th class="th2" v-tooltip="'見積りの承認者になる'">見積承認<br> 権限</th>
                <th class="th2" v-tooltip="'メインタスク登録可能'">メインタスク<br> 権限</th>
                <th class="th2" v-tooltip="'サブタスク登録・削除可能'">サブタスク<br> 権限</th>
                <th class="th2" v-tooltip="'見積り登録・削除可能'">見積<br> 権限</th>
                <th class="th2" v-tooltip="'品質巡回担当者・閲覧・登録'">品質巡回<br> 権限</th>
                <th class="th2" v-tooltip="'巡回清掃担当者・閲覧・登録'">巡回清掃<br> 権限</th>
                <th class="th2" v-tooltip="'定期清掃担当者・閲覧・登録'">定期清掃<br> 権限</th>
                <th class="th2" v-tooltip="'設備点検担当者・閲覧・登録'">設備点検<br> 権限</th>
                <th class="th2" v-tooltip="'閲覧・登録'">写真報告<br> 権限</th>
                <th class="th2" v-tooltip="'採否未決、不採用の社員面接データを閲覧・登録'">社員面接<br> 権限</th>
                <th class="th2" v-tooltip="'閲覧・登録'">評価シート<br> 権限</th>
                <!-- 部署関係 -->
                <th class="th2 text-left">部署</th>
                <th class="th2">デンタツ</th>
                <th class="th2">上司</th>
                <th class="th2">パート</th>
                <th class="th2 text-left">課・エリア</th>

                <th class="th1"></th>
              </tr>
            </thead>
            <tbody v-for="(row, index) in list" :key="index">
              <tr :class="setColor(index)" class="hover-color">
                <!-- 表示順 -->
                <td class="td2 text-right pr-5 col1">{{row.sortNo!='null' ? row.sortNo : ''}}</td>
                <!-- ID -->
                <td class="td2 text-gray-400 font-bold col2">{{row.id}}</td>
                <!-- 名前 -->
                <td class="td2 col3">
                  <pre>{{row.userName}}</pre>
                  <pre class="text-indigo-500">{{row.eMailAdress}}</pre>
                </td>
                <td class="td1 col4"><PrimaryButton text="編集" class="w-max" @click="edit(row)" /></td>
                <!-- 管理者権限 -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded" 
                      :disabled="true"
                      v-model="row.adminAuth"
                    />
                  </th>
                </td>
                <!-- ユーザー管理 -->
                <td class="td2 cursor-not-allowed">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded" 
                      :disabled="true"
                      v-model="row.userAuth"
                    />
                  </th>
                </td>
                <!-- 承認 -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded" 
                      :disabled="true"
                      v-model="row.estimateApproveAuth"
                    />
                  </th>
                </td>
                <!-- メイン -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded" 
                      :disabled="true"
                      v-model="row.mainAuth"
                    />
                  </th>
                </td>
                <!-- サブ -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded" 
                      :disabled="true"
                      v-model="row.subAuth"
                    />
                  </th>
                </td>
                <!-- 見積り -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded" 
                      :disabled="true"
                      v-model="row.estimateAuth"
                    />
                  </th>
                </td>
                <!-- 品質巡回 -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded" 
                      :disabled="true"
                      v-model="row.patrolAuth"
                    />
                  </th>
                </td>
                <!-- 巡回清掃 -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded" 
                      :disabled="true"
                      v-model="row.roundCleanAuth"
                    />
                  </th>
                </td>
                <!-- 定期清掃 -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded" 
                      :disabled="true"
                      v-model="row.fixedCleanAuth"
                    />
                  </th>
                </td>
                <!-- 設備点検 -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded" 
                      :disabled="true"
                      v-model="row.facilityPatrolAuth"
                    />
                  </th>
                </td>
                <!-- 写真報告 -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded" 
                      :disabled="true"
                      v-model="row.photoReportAuth"
                    />
                  </th>
                </td>
                <!-- 社員面接 -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded" 
                      :disabled="true"
                      v-model="row.regularStaffAuth"
                    />
                  </th>
                </td>
                <!-- 評価シート -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded" 
                      :disabled="true"
                      v-model="row.evaluationAuth"
                    />
                  </th>
                </td>
                <!-- 部署 -->
                <td class="td2"><pre>{{row.departmentName}}</pre></td>
                <!-- デンタツ -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded green-check" 
                      :disabled="true"
                      v-model="row.transmission"
                    />
                  </th>
                </td>
                <!-- 上司 -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded green-check" 
                      :disabled="true"
                      v-model="row.boss"
                    />
                  </th>
                </td>
                <!-- パート -->
                <td class="td2 cursor-not-allowed'">
                  <th scope="col" class="cursor-not-allowed px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                    <input 
                      type="checkbox" 
                      class="cursor-not-allowed h-4 w-4 border-gray-400 rounded green-check" 
                      :disabled="true"
                      v-model="row.partTimer"
                    />
                  </th>
                </td>
                <!-- 課 -->
                <td class="td2"><pre>{{row.departmentSubName}}</pre></td>
                
                <td class="td1"><PrimaryButton text="削除" class="w-max" @click="deleteData(row)" size="normal bg-indigo-200 text-indigo-700 hover:bg-indigo-300"/></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>

    <!-- 編集・新規登録モーダル -->
    <UserMasterModal 
      v-model="modalShow"
      :target="editData"
      :open="openModal"
      @modalClose="modalClose"
    />
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import UserMasterModal from '../components/UserMasterModal.vue'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    Icon,
    PageHeader,
    PrimaryButton,
    UserMasterModal
  },

  data() {
    return {
      loading: false,
      initial: true,
      freeze: true,
      // モーダル表示
      modalShow: false,
      // 編集する内容
      editData: {},
      openModal: 0,
      // 編集権限
      auth: false,
      // データ
      list: []
    }
  },

  async created() {
    this.freeze = true
    if (this.$store.getters.auth && this.$store.getters.auth.userAuth) {
      this.auth = true
    }
    
    await this.getData()
  },

  mounted() {
    this.$nextTick(() => {
      this.freeze = false
      this.refresh()
    })
  },

  methods: {

    async refresh() {
      this.loading = true
      await utils.wait(500)
      await this.getData()
      await utils.wait(500)
      this.loading = false
    },

    /**
     * データ取得
     */
    async getData() {
      let res = await backend.searchData('user/getUserList', { day: utils.getToday() })
      this.list = res.data.data
      // console.log(this.list)
    },

    /**
     * 背景色設定
     */
    setColor(i) {
      if (i % 2 != 0) {
        return 'bg-blue'
      } else {
        return 'bg-none'
      }
    },

    /**
     * 編集イベント
     * @param data 編集内容
     */
    edit(data) {
      this.openModal++
      this.modalShow = true
      this.editData = data
    },

    /**
     * モーダルを閉じる
     */
    async modalClose() {
      this.modalShow = false
      await this.getData()
    },

    /**
     * 削除ボタン イベント
     * @param row 対象データ
     */
    async deleteData(row) {
      const deleteConf = await dialogs.showWarningConfirmDialog('削除', '削除してよろしいですか？\r\n退職でない限り、ユーザーを削除するのは好ましくありません。\r\n変更箇所がある場合は、削除ではなく編集してください。')
      if (deleteConf != 'YES') {
        return false
      }
      let du = this.$store.getters.user.id
      // mst_user論理削除
      await backend.deleteFlag('user/deleteFlag', { updateUser: du, id: row.id })

      // mst_user_department論理削除
      await backend.deleteFlag('userDepartment/deleteFlag', { updateUser: du, userId: row.id })

      if (row.authId) {
        // mst_auth論理削除
        await backend.deleteFlag('auth/deleteFlag', { updateUser: du, id: row.authId })
      }

      if (row.otherId) {
        // mst_user_other論理削除
        await backend.deleteFlag('userOther/deleteFlag', { updateUser: du, id: row.otherId })
      }
      logManager.recording(this, logManager.Loglevel.INFO, 'ユーザー設定', '削除', 'ユーザー（ID：' + row.id + '、 ユーザー名：' + row.userName + '）を削除しました。')
      this.getData()
    }

  }
}
</script>

<style scoped>
.table {
  width: 100%;
}

.column th {
  height: 50px;
  background: #edecf7;
}

.bg-blue {
  background: rgb(243 247 249);
}

.th1 {
  width: 100px;
  text-align: center;
}

.th2 {
  /* text-align: left; */
  padding-left: 10px;
}

.td1 {
  text-align: center;
}

.td2 {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.th-lock{
  z-index: 12 !important;
}

.bg-blue .col1,
.bg-blue .col2,
.bg-blue .col3,
.bg-blue .col4{
  background: rgb(243 247 249);
}

.bg-none .col1,
.bg-none .col2,
.bg-none .col3,
.bg-none .col4{
  background: white;
}

.hover-color:hover .col1,
.hover-color:hover .col2,
.hover-color:hover .col3,
.hover-color:hover .col4{
  background: rgba(184, 244, 242);
}

.col1{
  position: sticky;
  left: 0;
  z-index: 11;
}

.col2{
  position: sticky;
  left: 60px;
  z-index: 11;
}

.col3{
  position: sticky;
  left: 103px;
  z-index: 11 !important;
}

.col4{
  position: sticky;
  left: 406px;
  z-index: 11;
}



.new {
  width: 200px;
  background: #eb94c8;
}

.w-fit {
  width: fit-content;
}

.hover-color:hover {
  background: rgba(184, 244, 242, 0.67);
}

.green-check {
  color: rgb(39, 152, 39);
}

@media screen and (max-width: 1000px) {
  .list {
    min-width: 1000px !important;
  }
}
</style>