<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div class="overflow-hidden rounded main-height">
      <div v-if="target" class="main-height overflow-auto">
        <div class="font-bold text-green-600">{{ target.userName }} さんの未完了の部下</div>
        <div class="text-sm mt-2">
          ※諸事情で面談が不可能の場合に解除することができます。
        </div>
        <div class="mx-auto w-fit">
          <div v-for="(row, i) in notComp" :key="i">
            <div class="">
              <div class="ml-1 w-full">
                <div v-for="num in [1, 2, 3, 4, 5, 6]" :key="'comp'+num" class="w-full">
                  <div v-if="!row['comp'+num]">
                    <div class="rounded my-3 font-bold text-white bg-green-700 cursor-pointer hover:bg-green-500 py-1 px-2 w-full" @click="unlock(row, getMonth(num), num)">
                      <span>{{ row.junior }}</span><span class="ml-2">{{ getMonth(num) }} 月 を解除</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import * as backend from '@libs/backend'
import * as logManager from '@managers/logManager'
import * as utils from '@libs/utils'

export default {
  components: {
    Modal,
  },

  props: {
    target: {
      type: Object
    },
    open: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array
    }
  },

  data() {
    return {
      notComp: []
    }
  },

  watch: {
    open() {
      if (this.open) {
        this.getData()
      }
    }
  },

  computed: {
    /**
     * 何月かを取得
     */
    getMonth() {
      return (num) => {
        if (this.columns && this.columns.length) {
          let mm = Number(this.columns[3].val.replace('月', ''))
          mm = mm + (num - 1)
          return mm
        }
        return ''
      }
    }
  },

  methods: {
    /**
     * データ取得
     */
    getData() {
      this.notComp = utils.clone(this.target.notComp)
    },

    /**
     * 解除ボタン
     * @param row 対象データ
     * @param month 月
     * @param num インデックス
     */
    async unlock(row, month, num) {
      await backend.postData('evaluation/unlockBoss', { userName: row.junior, year: this.target.year, period: this.target.period, num })
      logManager.recording(this, logManager.Loglevel.INFO, '部下面談免除', '解除', `${row.boss}さんと${row.junior}さんの${month}月 部下面談を免除しました。`)
      row['comp' + num] = true
      this.$emit('getData')
      if (this.notComp.length == 1) {
        this.closed()
      }
    },

    closed() {
      this.$emit('closed')
    }
  }
}
</script>
<style scoped>
</style>