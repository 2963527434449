<template>
  <div>
    <PageHeader title="方針勉強会動画設定" class="main-form">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-between items-center">
          <div></div>

          <div class="text-white text-xs">
            人事経理総務部・管理者のみ閲覧、編集可能
          </div>

          <div class="pl-2 flex items-center">
            <!-- 年度検索 -->
            <div class="mr-2 mt-auto">
              <input
                type="number"
                name="year" 
                min="2024"
                max="2999"
                placeholder="年度 検索"
                class="py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 font-bold w-20 text-center" 
                v-model="year"
              /> 
              <span class="pl-1 pr-2 font-bold text-white text-sm">年度</span>
            </div>

            <!-- 切替 -->
            <div class="bg-white rounded mr-5">
              <div class="flex items-center py-1 rounded mx-5 ">
                <div v-for="tab in typeList" 
                  :key="tab.id" 
                  class="w-28 text-center mx-1 cursor-pointer"
                  :class="[type == tab.id ? 'bg-light-blue-700' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-1 px-3 text-sm font-medium text-white']" 
                  @click="typeChanged(tab)"
                >
                  {{ tab.title }}
                </div>
              </div>
            </div>

            <!-- 検索ボタン -->
            <PrimaryButton
              text="検索"
              class="button-contents mr-5"
              @click="getData()"
            >
            </PrimaryButton>

            <!-- 新規作成 -->
            <div>
              <PrimaryButton text="新規" size="normal hover:bg-pink-400" class="new" @click="edit({})"/>
            </div>
            <Icon 
              iconName="Refresh" 
              :clickable="false" 
              :class="{'animate-spin': loading}" 
              class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer"
              @click.native="refresh"
            />
          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-20 w-screen bottom-4 -mt-2 leading-8 main-form">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div v-if="list.length" class="bg-white rounded-md border border-gray-200 overflow-auto h-full">
          <table class="table text-sm">
            <thead class="th sticky top-0 z-20">
              <tr class="text-gray-500 text-xs border border-gray-200 bg-colu font-bold">
                <th v-for="(c, i) in columns" :key="'col'+i" class="text-left p-2" :class="c.class">{{ c.val }}</th>
              </tr>
            </thead>
            <tbody v-for="(row, index) in list" :key="index">
              <tr class="hover-color">
                <!-- 動画URL -->
                <td class="px-2 text-xs">
                  <!-- 視聴URL -->
                  <div v-if="row.movieUrl&&row.movieUrl.indexOf('watch')>-1">
                    <pre><a :href="row.movieUrl" target="_blank" rel="noopener noreferrer" class="p-2 text-blue-700 border-b border-blue-600">{{row.movieUrl}}</a></pre>
                  </div>
                  <!-- 共有URL -->
                  <div v-else>
                    <div class="text-gray-700 iframe truncate">
                      {{ row.movieUrl }}
                    </div>
                  </div>
                </td>
                <!-- タイトル -->
                <td class="p-2"><pre>{{row.title}}</pre></td>
                <!-- 実施項目 -->
                <td class="p-2"><pre>{{row.keyword.join('、')}}</pre></td>
                <!-- 期限開始日 -->
                <td class="p-2 text-center"><pre>{{row.startDay}}</pre></td>
                <!-- から -->
                <td class="p-2 text-center"><pre>～</pre></td>
                <!-- 期限終了日 -->
                <td class="p-2 text-center"><pre>{{row.endDay}}</pre></td>
                
                <td class="p-2 text-right">
                  <PrimaryButton text="編集" class="w-max mr-1" @click="edit(row)" />
                  <PrimaryButton text="削除" class="w-max" @click="deleteData(row)" size="normal bg-indigo-200 text-indigo-700 hover:bg-indigo-300 ml-1"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="!loading && !list.length" class="w-full h-full flex justify-center items-center text-gray-400">
          <EmptyMessage
            iconName="Document"
            message="該当するデータはありません。"
          />
        </div>
      </div>
    </main>

    <!-- 編集・新規登録モーダル -->
    <EvaluationMovieMasterModal 
      v-model="modalShow"
      :open="modalShow"
      :target="editData"
      @modalClose="modalClose"
    />
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import EvaluationMovieMasterModal from '../components/EvaluationMovieMasterModal.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import * as moment from 'moment'
import PageModel from '../../main/pages/PageModel.vue'

// カラム
const COL = [
  { val: '動画URL もしくは「共有埋め込み」' },
  { val: 'タイトル' },
  { val: '実施項目' },
  { val: '公開開始日', class: 'text-center' },
  { val: '' },
  { val: '公開終了日', class: 'text-center' },
  { val: '' },
]

export default {
  extends: PageModel,
  components: {
    Icon,
    PageHeader,
    PrimaryButton,
    EvaluationMovieMasterModal,
    EmptyMessage
  },

  data() {
    return {
      loading: false,
      // モーダル表示
      modalShow: false,
      // 編集する内容
      editData: {},
      // データ
      list: [],
      // カラム
      columns: utils.clone(COL),
      // 年度
      year: Number(moment().format('YYYY')),
      // 切り替え
      typeList: [
        { id: 1, title: '上期' },
        { id: 2, title: '下期' }
      ],
      // 表示対象
      type: 1
    }
  },

  async created() {
    let auth = false
    const dep = this.$store.getters.dualPosition
    if (dep && dep.length) {
      // 人事部か確認
      const isDep = dep.find((d) => {
        return (d.departmentName.indexOf('人事') > -1)
      })
      if (isDep && isDep.departmentName) {
        auth = true
      }
    }
    // 管理者権限の場合
    let auth2 = this.$store.getters.auth
    if (auth2 && auth2.adminAuth) {
      auth = true
    }

    // 権限
    if (!auth) {
      alert('この画面は権限のある人しか見れません。\r\nメニューへ戻ります。')
      this.$router.push('/')
      return
    }

    // 年度、期の設定
    const mm = Number(moment().format('MM'))
    if (mm == 1) {
      this.year--
      this.type = 2
    } else if (mm <= 7) {
      this.type = 1
    } else {
      this.type = 2
    }

    await this.getData()
  },

  methods: {

    async refresh() {
      await this.getData()
    },

    /**
     * データ取得
     */
    async getData() {
      this.data = []
      if (!this.year) {
        await dialogs.showErrorDialog('年度未入力', '年度を入力してください。')
        return
      }
      if (!this.type) {
        await dialogs.showErrorDialog('期未選択', '期を選択してください。')
        return
      }

      this.loading = true
      let start = ''
      let end = ''

      if (this.type == 1) {
        start = this.year + '-02-01'
      } else {
        start = this.year + '-08-01'
      }
      end = moment(start + ' 00:00:00').add(5, 'months').endOf('month').format('YYYY-MM-DD')

      let res = await backend.searchData('evaluationMovie/get', { start, end })
      this.list = res.data.data
      this.loading = false
    },

    /**
     * 切り替えイベント
     */
    typeChanged(tab) {
      this.type = tab.id
      this.getData()
    },

    /**
     * 編集イベント
     * @param data 編集内容
     */
    edit(data) {
      this.modalShow = true
      this.editData = data
    },

    /**
     * モーダルを閉じる
     */
    async modalClose() {
      this.modalShow = false
      await this.getData()
    },

    /**
     * 削除ボタン イベント
     * @param row 対象データ
     */
    async deleteData(row) {
      const deleteConf = await dialogs.showWarningConfirmDialog('削除', '本当に削除してよろしいですか？')
      if (deleteConf != 'YES') {
        return
      }
      await backend.deleteData('evaluationMovie/deleteData', { id: row.id })

      logManager.recording(this, logManager.Loglevel.INFO, '動画設定', '削除', '動画（ID：' + row.id + '）を削除しました。')
      this.getData()
    }

  }
}
</script>

<style scoped>
.main-form {
  min-width: 1255px;
}

.table {
  width: 100%;
}

.bg-colu {
  height: 40px;
  background: #edecf7;
}

.new {
  width: 100px;
  background: #eb94c8;
}

.hover-color:hover {
  background: rgba(184, 244, 242, 0.67);
}

.iframe {
  max-width: 300px;
  min-width: 200px;
}

</style>