<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div :class="isMobileDevice?'w-full':'w-96'">
          <div class="font-bold text-indigo-700">
            スポット登録
          </div>
          <!-- 得意先名 -->
          <div class="mt-5 flex text-gray-700">
            <div class="mr-2">得意先名：</div>
            <div class="font-bold">
              {{ spotData.clientName1 }}
            </div>
          </div>

          <!-- 支店名 -->
          <div v-if="spotData.clientName2" class="mt-3 flex text-gray-700">
            <div class="mr-2">支店名：</div>
            <div class="font-bold">
              {{ spotData.clientName2 }}
            </div>
          </div>

          <!-- 物件名 -->
          <div class="mt-3 flex text-gray-700">
            <div class="mr-2">物件名：</div>
            <div class="font-bold">
              {{ spotData.siteName }}
            </div>
          </div>

          <!-- 予定日 -->
          <div class="text-xs font-bold text-gray-700 mb-1 mt-5">
            予定日 <span class="-top-0.5 ml-1 text-red-500"> * </span>
          </div>
          <div>
            <input type="date" v-model="scheduledDate" :min="today" class="rounded border-gray-300" />
          </div>

          <!-- 担当者 -->
          <div class="text-xs font-bold text-gray-700 mt-3 mb-1">
            担当者 <span class="-top-0.5 ml-1 text-red-500"> * </span>
          </div>
          <select
            name="staff"
            v-model="staffId"
            @change="selectStaff($event)"
            class="border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block w-full text-gray-700"
          >
            <option
              v-for="list in staffList" :key='list.id'
              :value="list.userId"
              class="bg-white">
              {{ list.name }}
            </option>
          </select>

          <PrimaryButton
            text="登録"
            class="mt-5 mb-3 w-full"
            @click="save()"
          >
          </PrimaryButton>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import * as backend from '@libs/backend'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import * as moment from 'moment'

export default {
  components: {
    Modal,
    PrimaryButton,
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },
    isInfo: {
      type: Boolean,
      default: true
    },
    type: {
      type: Number,
      default: null
    },
    spotData: {
      type: Object
    },
  },

  data() {
    return {
      // 予定日
      scheduledDate: moment().format('YYYY-MM-DD'),
      // 本日日付
      today: moment().format('YYYY-MM-DD'),
      // 責任者
      staff: '',
      // 責任者ID
      staffId: null,
      // 指導員講習受講番号
      patrolNumber: null,
      // 責任者リスト
      staffList: [],
    }
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },
  },

  watch: {
    open() {
      if (this.open) {
        this.getData()
      } else {
        return
      }
    },
    
  },

  methods: {
    /**
     * データ取得
     */
    async getData() {
      // 得意先、物件、スタッフリストを取得
      const res = await backend.searchData('patrolSchedule/getSpotClientAndStaff', { type: this.type })
      if (res.data.staffData) {
        this.staffList = res.data.staffData
      }
      // ログインユーザーを初期値にする
      const staff = utils.deleteKana(this.$store.getters.user.user_name).replace('　', ' ')
      const staffId = this.$store.getters.user.id
      if (this.staffList && this.staffList.length) {
        let staffData = this.staffList.find((s) => {
          return s.userId == staffId
        })
        if (!staffData || !staffData.userId) {
          staffData = this.staffList.find((s) => {
            return s.name == staff
          })
        }
        if (staffData && staffData.userId) {
          this.staff = staffData.name
          this.staffId = staffData.userId
          if (this.type == 1) {
            this.patrolNumber = staffData.patrolNumber
          }
        }
      }
    },

    /**
     * 責任者選択イベント
     */
    selectStaff() {
      const res = this.staffList.find((s) => {
        return s.userId == this.staffId
      })
      if (res && res.name) {
        this.staff = res.name
        this.patrolNumber = res.patrolNumber
      }
    },

    /**
     * 報告書作成ボタン
     */
    async save() {
      if (!this.scheduledDate) {
        await dialogs.showErrorDialog('予定日', '予定日を入力してください。')
        return
      }

      if (Number(this.scheduledDate.replace(/-/g, '')) < Number(this.today.replace(/-/g, ''))) {
        await dialogs.showErrorDialog('予定日が過去日になっています', '予定日は、本日以降にしてください。')
        return
      }
      if (!this.staff) {
        await dialogs.showErrorDialog('責任者', '責任者を選択してください。')
        return
      }
      const saveData = utils.clone(this.spotData)
      saveData.patrolScheduleId = null
      saveData.id = null
      saveData.limit = this.scheduledDate
      saveData.scheduledDate = this.scheduledDate
      saveData.userId = this.staffId
      saveData.staff = this.staff
      saveData.patrolId = this.patrolNumber
      saveData.sortNo = -1
      saveData.insertUser = this.$store.getters.user.id
      saveData.claim = false
      saveData.spot = true
      
      const res = await backend.postData('patrolSchedule/save', [saveData])
      // 報告書画面に遷移
      if (res.data.data) {
        alert('スポット登録完了しました。')
        if (this.scheduledDate == moment(this.spotData.scheduledDate).format('YYYY-MM-DD')) {
          this.$emit('getData', this.spotData)
        }
        this.$emit('closed')
        this.closed()
      }
    },

    /**
     * 閉じる
     */
    closed() {
      // 予定日
      this.scheduledDate = moment().format('YYYY-MM-DD'),
      // 本日日付
      this.today = moment().format('YYYY-MM-DD'),
      // 責任者
      this.staff = ''
      // 責任者ID
      this.staffId = null
      // 責任者リスト
      this.staffList = []
    },
  }
}
</script>
<style scoped>
.content-container-app {
  width: 100%;
}
</style>