<template>
  <div>
    <CheckListComponent
      typeName="品質巡回 点検一覧"
      :type="1"
      :claim="true"
      settionStrageName="ClaimCheckListStorageItemName">
    </CheckListComponent>
  </div>
</template>

<script>
import CheckListComponent from '../components/CheckListComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    CheckListComponent
  },
}
</script>