<template>
  <div>
    <PageHeader :title="!isMobileDevice?'方針勉強会':''" class="main-form">
      <template #title-header-content>
        <div class="flex-1 flex justify-between items-center">
          <div></div>
          <div class="text-white">
            7：00～17：00 のみ感想文の入力が可能<br>（保存時にAIによる判定を行います）
          </div>
          <!-- 一覧 -->
          <div class="min-w-fit">
            <PrimaryButton class="" size="sm" text="感想文一覧" @click="$router.push({ name: 'StudyPolicyList' })">
              <LoadingIcon v-if="loading" slot="before" class="h-4 w-4" />
              <Icon v-else iconName="ClipboardList" slot="before" class="h-4 w-4" />
            </PrimaryButton>
          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-20 bottom-4 leading-8 w-full main-form mx-auto">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full w-full">
        <div v-if="list.length" class="rounded-md border border-gray-200 table-h overflow-auto bg-white p-2">
          <div class="flex flex-wrap justify-between">
            <div v-for="row in list" :key="row.movieId" class="mt-2 mx-auto p-2 mb-10 min-w-560">
              <!-- 期限 -->
              <div class="text-sm font-bold text-gray-700 mb-3">
                {{ row.period }}
              </div>
              <!-- URL -->
              <div class="mb-2">
                <!-- 視聴URL -->
                <div v-if="row.url&&row.url.indexOf('watch')>-1">
                  <a :href="row.url" target="_blank" rel="noopener noreferrer" class="p-1 text-blue-700 border-b border-blue-600">{{row.url}}</a>
                </div>
                <!-- 共有URL -->
                <div v-else-if="row.url" :id="'movie'+row.movieId" style=""></div>
              </div>

              <!-- タイトル -->
              <div v-if="row.status&&row.status[0]">
                <div v-if="row.status[0].indexOf('OK')>-1" class="text-blue-600 text-sm font-bold">OK</div>
                <div v-else class="text-red-600 text-sm font-bold">NG</div>
              </div>
              <div v-else class="h-4"></div>
              <div class="mt-1 mb-2">
                <WmsTextInput
                  caption="タイトル" 
                  v-model="row.title"
                  class="rounded "
                />
              </div>

              <!-- 実施項目 -->
              <div v-if="row.status&&row.status[1]">
                <div v-if="row.status[1].indexOf('OK')>-1" class="text-blue-600 text-sm font-bold">OK</div>
                <div v-else class="text-red-600 text-sm font-bold">NG</div>
              </div>
              <div v-else class="h-4"></div>
              <div class="mt-1 mb-2">
                <WmsTextInput
                  caption="実施項目" 
                  v-model="row.keyword"
                  class="rounded "
                />
              </div>

              <!-- 感想文 -->
              <div v-if="row.status&&row.status[2]">
                <div v-if="row.status[2].indexOf('OK')>-1" class="text-blue-600 text-sm font-bold">OK</div>
                <div v-else class="text-red-600 text-sm font-bold">NG</div>
              </div>
              <div v-else class="h-4"></div>
              <div class="text-xs font-bold text-gray-700 mt-1 mb-1">
                感想文（具体的に書いてください）
              </div>
              <textarea 
                :rows="5"
                class="border border-gray-300 w-full rounded"
                v-model="row.report"
              />

              <!-- 保存ボタン -->
              <div class="p-1">
                <PrimaryButton
                  text="保存・有効無効の判定"
                  class="w-full"
                  :disabled="loading"
                  @click="save(row)"
                >
                <LoadingIcon v-if="loading" slot="before" class="h-5 w-5" />
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="!loading && timeOut" class="w-full h-full flex justify-center items-center text-gray-600">
          時間外のため表示されません。
        </div>
        <div v-else-if="!loading && !list.length" class="w-full h-full flex justify-center items-center text-gray-400">
          <EmptyMessage
            iconName="Document"
            message="該当するデータはありません。"
          />
        </div>
      </div>
    </main>
  </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import LoadingIcon from '@assets/loading-circle.svg'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import * as moment from 'moment'
import * as dialogs from '@libs/dialogs'
import PageModel from '../../main/pages/PageModel.vue'

const ROW = { movieId: null, url: '', title: '', period: '', report: '' }

export default {
  extends: PageModel,
  components: {
    Icon,
    PageHeader,
    EmptyMessage,
    PrimaryButton,
    LoadingIcon,
    WmsTextInput
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * タブレット
     */
    isTabletDevice() {
      return utils.deviceInfo.isTablet
    },

    /**
     * ログインユーザー名
     */
    user() {
      return utils.deleteKana(this.$store.getters.user.user_name)
    },

    /**
     * 時間内か判定
     */
    timeOut() {
      let time = Number(moment().format('Hmm'))
      if (time >= 700 && time <= 1700) {
        return false
      } else {
        return true
      }
    }
  },

  data() {
    return {
      loading: false,
      // リスト
      list: utils.clone(ROW),
    }
  },

  async created() {
    await this.getData()
  },

  methods: {
    /**
     * データ取得
     */
    async getData() {
      if (this.timeOut) {
        return
      }
      this.loading = true
      const res = await backend.searchData('evaluation/getPolicyReport', { user: this.user })
      if (res.data.data) {
        this.list = res.data.data
      }
      this.loading = false
      await this.parse()
    },

    /**
     * 共有URLの場合、HTMLに変換
     */
    async parse() {
      await utils.wait(500)
      for (let i = 0; i < this.list.length; i++) {
        const l = this.list[i]
        if (l.url && l.url.indexOf('embed') > -1) {
          let element = document.getElementById('movie' + l.movieId)
          element.insertAdjacentHTML('afterend', l.url)
        }
      }
    },

    /**
     * 保存
     * @param row 対象データ
     */
    async save(row) {
      if (!row.title) {
        await dialogs.showErrorDialog('保存できません', 'タイトルを記入してください。')
        return
      }
      if (!row.keyword) {
        await dialogs.showErrorDialog('保存できません', '実施事項を記入してください。')
        return
      }
      if (!row.report) {
        await dialogs.showErrorDialog('保存できません', '感想を記入してください。')
        return
      }
      this.loading = true
      // Gemini問い合わせプロンプト生成
      const gemini = []

      // タイトル
      gemini.push(`「${row.title}」は「${row.mstTitle}」と同じ意味か、誤字の可能性がある場合はOKで、結果はOKかNGのみで返して。`)

      // キーワード
      // let key = ''
      // for (let i = 0; i < row.mstKeyword.length; i++) {
      //   const m = row.mstKeyword[i]
      //   key += `「${row.keyword}」の中に、誤字を含め「${m}」と同じ意味の単語があるか、`
      // }
      // key += `「${row.keyword}」に${row.mstKeyword.length}つの単語が書かれているか、`
      // key += '全て当てはまる場合はOK、どれか一つでも当てはまらなければNGで、結果はOKかNGでのみで理由も返して'
      // gemini.push(key)
      gemini.push(`「${row.keyword}」には${row.mstKeyword}つの単語が書かれているか、かつ「${row.keyword}」に「${row.mstKeyword.join('、')}」の${row.mstKeyword}つの意味がすべて含まれているか、順不問、誤字を含め、結果はOKかNGのみで返して。`)

      // 感想文
      gemini.push(`「${row.report}」は具体的な感想文、もしくは決意表明であり、短文ではなく、ふざけた内容ではないか、結果をOKかNGのみで返して。`)

      const res = await backend.postData('evaluation/savePolicyReport', { ...row, user: this.user, userId: this.$store.getters.user.id, gemini })
      if (res && res.data.data) {
        row.id = res.data.data.id
      }
      row.status = res.data.geminiRes
      if (res.data.point == 0) {
        let msg = []
        if (row.status[0].indexOf('NG') > -1) {
          msg.push('■タイトルが無効です。')
        }
        if (row.status[1].indexOf('NG') > -1) {
          msg.push('■実施項目が無効です。（' + row.mstKeyword.length + 'つ必要です）')
        }
        if (row.status[2].indexOf('NG') > -1) {
          msg.push('■感想文が無効です。（具体的に書いてください）')
        }
        alert('無効です。\r\n' + msg.join('\r\n'))
      } else {
        await dialogs.showSuccessDialog('有効です', '保存しました。\r\nタイトル、実施項目、感想文、全て有効です。')
      }
      this.loading = false
    }
  }
}
</script>
  
<style scoped>
.main-form {
  min-width: 650px;
}

.min-w-560 {
  min-width: 560px;
}
</style>