<template>
  <div>
    <PageHeader title="上司 プロセス評価平均" class="main-form">
      <template #title-header-content>
        <div class="flex-1 flex justify-end items-center">
          <!-- 年度検索 -->
          <div class="mr-2 mt-auto">
            <input
              type="number"
              name="filterYear" 
              min="2024"
              max="2999"
              placeholder="年度 検索"
              class="py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 font-bold w-28 text-center" 
              v-model="filter.year"
            /> 
            <span class="pl-1 pr-2 font-bold text-white text-sm">年度</span>
          </div>

          <!-- 切替 -->
          <div class="bg-white rounded mr-5">
            <div class="flex items-center py-1 rounded mx-5 ">
              <div v-for="tab in typeList" 
                :key="tab.id" 
                class="w-28 text-center mx-1 cursor-pointer"
                :class="[type == tab.id ? 'bg-light-blue-700' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-1 px-3 text-sm font-medium text-white']" 
                @click="typeChanged(tab)"
              >
                {{ tab.title }}
              </div>
            </div>
          </div>

          <!-- 検索ボタン -->
          <PrimaryButton
            text="検索"
            class="button-contents mr-2"
            @click="getData()"
          >
          </PrimaryButton>

          <!-- リロードアイコン -->
          <Icon
            iconName="Refresh"
            :clickable="false"
            :class="{ 'animate-spin': loading }"
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer" @click.native="refresh"
          />
        </div>
      </template>
    </PageHeader>

    <main class="absolute bottom-4 w-full main-form mx-auto top-20">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full w-full">
        <div v-if="list.length" class="bg-white rounded-md border border-gray-200 overflow-auto h-full">
          <table class="table text-sm overflow-auto">
            <thead class="th sticky top-0 z-20 bg-colu">
              <tr class="text-gray-500 text-xs border border-gray-200 bg-colu">
                <th v-for="(c, i) in columns" :key="'col'+i" class="text-left p-2" :class="c.class">{{ c.val }}</th>
              </tr>
            </thead>
            <tbody v-for="(row, i) in list" :key="i">
              <tr class="border-b border-gray-200 hover-color">
                <!-- 上司名前 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit px-2 py-4 font-bold">
                  {{ row.boss }}
                </td>

                <!-- 平均 -->
                <td class="whitespace-prerap text-base font-bold text-green-700 min-w-fit px-2 py-4 text-right">
                  {{ row.aveAll }}
                </td>

                <!-- 平均1~10 -->
                <td v-for="num in [1,2,3,4,5,6,7,8,9,10]" :key="'ave'+num" class="whitespace-prerap text-sm text-gray-700 min-w-fit px-2 py-4 text-right">
                  {{ row['ave'+num] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="!loading && !list.length" class="w-full h-full flex justify-center items-center text-gray-400">
          <EmptyMessage
            iconName="Document"
            message="該当するデータはありません。"
          />
        </div>
      </div>
    </main>
  </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import * as moment from 'moment'
import * as dialogs from '@libs/dialogs'
import PageModel from '../../main/pages/PageModel.vue'

// カラム
const COL = [
  { val: '上司' },
  { val: '全体平均', class: 'text-right font-bold text-green-700' },
]

export default {
  extends: PageModel,
  components: {
    Icon,
    PageHeader,
    EmptyMessage,
    PrimaryButton
  },

  computed: {
  },

  data() {
    return {
      loading: false,
      // 切替ボタン
      typeList: [
        { id: 1, title: '上期' },
        { id: 2, title: '下期' }
      ],
      type: null,
      // リスト
      list: [],
      // カラム
      columns: utils.clone(COL),
      // 検索条件
      filter: {
        // 年度
        year: null,
      },
    }
  },

  async created() {
    // 年度
    this.filter.year = Number(moment().format('YYYY'))
    const mm = Number(moment().format('M'))
    if (mm == 1) {
      this.filter.year--
      this.type = 2
    } else if (mm <= 7) {
      this.type = 1
    } else {
      this.type = 2
    }
    // 2月、8月の場合、中旬までは前の期にする
    if ([2, 8].includes(mm)) {
      if (Number(moment().format('D')) <= 16) {
        if (mm == 2) {
          this.type = 2
          this.filter.year--
        } else {
          this.type = 1
        }
      }
    }
    // 管理者権限の場合
    let auth = false
    let auth2 = this.$store.getters.auth
    if (auth2 && auth2.adminAuth) {
      auth = true
    }

    // 権限（管理者権限あり）
    if (!auth) {
      alert('この画面は権限のある人しか見れません。\r\nメニューへ戻ります。')
      this.$router.push('/')
      return
    }

    await this.getData()
  },

  methods: {
    /**
     * リフレッシュアイコンイベント
     */
    async refresh() {
      await this.getData()
    },

    /**
     * データ取得
     */
    async getData() {
      this.list = []
      if (!this.filter.year) {
        await dialogs.showErrorDialog('年度 未入力', '年度を入力してください。')
        return
      }
      if (!this.type) {
        await dialogs.showErrorDialog('期未 選択', '期を選択してください。')
        return
      }

      // データ取得
      this.loading = true

      const res = await backend.postData('evaluation/bossAve', { ...this.filter, period: this.type })
      if (res.data.data) {
        // カラム
        for (let i = 0; i < res.data.data.item.length; i++) {
          const e = res.data.data.item[i]
          this.columns.push({ val: (i + 1) + '. ' + e.title, class: 'text-right' })
        }
        // 一覧
        this.list = res.data.data.list
      }

      this.loading = false
    },

    /**
     * 切替イベント
     * @paran tab 選択したタブ
     */
    async typeChanged(tab) {
      this.type = tab.id
      await this.getData()
    },
  }
}
</script>
  
<style scoped>
.main-form {
  min-width: 930px;
}

.table {
  width: 100%;
}

.bg-colu {
  background: #ebf0fb;
}

.hover-color {
  background: white;
}

.hover-color:hover {
  background: rgb(239, 250, 250);
}
</style>