<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div v-if="loading" class="w-full h-full flex justify-center items-center">
          <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
        </div>
        <div v-if="!selectedHistory && !loading" class="min-600">
          <table class="w-11/12 table-auto ml-auto mr-auto mt-5">
            <thead class="">
              <tr>
                <th v-for="(f, index) in historyColumns" :key="'c'+index" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                  <div v-if="f.title == 'サイクル期限'">
                    <!-- サイクル期限 -->
                    <div class="mr-auto flex">
                      <input
                        type="number"
                        name="year" 
                        placeholder="サイクル期限（年）"
                        v-tooltip="alertYear?'4桁で入力してください':''"
                        class="w-16 py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 inline" 
                        :class="alertYear?'border-red-600':''"
                        v-model="year"
                      />
                      <div class="ml-1 mr-2 my-auto">年</div>
                    </div>
                  </div>
                  <div v-else></div>
                </th>
              </tr>
              <tr class="border border-gray-200 bg-blue-100">
                <th v-for="(c, index) in historyColumns" :key="'c'+index" scope="col" class="py-1 text-center text-xs font-medium text-gray-500 tracking-wider bg-colu">
                  {{c.title}}
                </th>
              </tr>
            </thead>
              <tr v-for="(row, i) in history" :key="'n'+i" class="border border-gray-200 tracking-wider hover-color" @click="selected(row)">
                <!-- サイクル期限 -->
                <td class="px-1 py-1 whitespace-pre-line text-sm text-center min-200">
                  {{ row.termDate }}
                </td>
                <!-- 点検日 -->
                <td class="text-center min-200">
                  {{ row.completionDate }}
                </td>
                <!-- 担当者 -->
                <td class="px-1 py-1 whitespace-pre-line text-sm text-center min-200">
                  {{ row.staff }}
                </td>
                <td class="py-3">
                  <div class="flex justify-center">
                    <a href="#">
                      <Icon slot="before" iconName="ChevronDoubleRight" iconType="solid" :strokeWidth="2" class="h-7 w-7 p-1 bg-indigo-700 text-white rounded-full cursor-pointer hover:bg-indigo-800" @click="selected(row)" />
                    </a>
                  </div>
                </td>
              </tr>
          </table>
          <div v-if="!loading && !history.length" class="m-auto w-fit p-8">
            <p class="text-gray-400 font-bold animate-pulse">履歴はありません。</p>
          </div>

        </div>
        <div v-else class="content-container-app overflow-auto bg-history">
          <div class="text-lg text-blue-800 font-bold flex">
            <div>履歴 （サイクル期限： {{ termDate }}）</div>
            <div class="ml-auto mr-8">{{ point }} / {{ totalPoint }} 点</div>
            <!-- <PrimaryButton text="履歴一覧に戻る" size="normal " class="mr-1 bg-orange" @click="backToList()" /> -->
          </div>

          <div class="mt-5 ml-12 mr-12 mb-12">

            <div class="w-11/12 mr-auto mt-5 flex">
              <div class="text-gray-600 font-bold w-1/2 mr-auto">
                得意先 : {{ list.clientName1 }}
                <div v-if="list.clientName2 && list.clientName2 != ''" class="font-normal ml-20 text-xs text-gray">（{{ list.clientName2 }}）</div>
              </div>
              <div class="text-gray-600 font-bold w-1/2 ml-auto">
                物件名 : {{ list.siteName }}
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 flex">
              <div class="text-gray-600 font-bold w-1/2 mr-auto">
                巡回日 : {{ list.completionDate }}
              </div>
              <div class="text-gray-600 font-bold w-1/2 ml-auto">
                巡回時間 : <span>{{ start }} ～ {{ end }}</span>
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 flex">
              <div class="text-gray-600 font-bold w-1/2 mr-auto">
                巡回指導員 : {{ list.staff }}
              </div>
              <div class="text-gray-600 font-bold w-1/2 ml-auto">
                指導員講習受講番号 : {{ list.patrolId }}
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 flex">
              <div class="text-gray-600 font-bold w-full">
                位置情報 : {{ location }}
              </div>
            </div>
          </div>

          <div v-for="(row, i) in checkList.items" :key="'r'+i" class="w-11/12 ml-auto mr-auto">
            <div>
              <!-- No -->
              <div class="flex">
                <div v-if="row.sortNo>0" class="w-8 text-blue-700 font-bold">{{ row.sortNo }}.</div>
                <div v-else class="w-8 text-blue-700 font-bold">◆</div>
                <div class="text-blue-700 font-bold ">{{ row.classification }}</div>
              </div>
              <!-- 項目 -->
              <div class="ml-8 mb-1 whitespace-pre-line">
                {{ row.item }}
              </div>
            </div>
            <div>
              <!-- 点数 -->
              <div v-if="row.sortNo>-1" class="ml-8 flex">
                <PrimaryButton text="指摘多数" size="normal" class="w-full mr-2" :class="row.value!='0'?'bg-gray-400':''" :disabled="true" />
                <PrimaryButton text="指摘あり" size="normal" class="w-full mr-2" :class="row.value!='1'?'bg-gray-400':''" :disabled="true" />
                <PrimaryButton text="良好" size="normal" class="w-full mr-2" :class="row.value!='2'?'bg-gray-400':''" :disabled="true" />
                <PrimaryButton text="該当なし" size="normal" class="w-full mr-2" :class="row.value!='N/A'&&!row.disabled?'bg-gray-400':'bg-teal-700'" :disabled="true" />
              </div>

              <!-- ◆確認項目のコメント必須のもの -->
              <div class="ml-auto " v-else-if="row.sortNo <= -1 && !row.isValueBtn && row.value=='N/A'">  
                <div class="flex justify-end items-end">
                  <div v-if="row.note" class="text-gray-700  mr-4 w-full ml-32p sm:text-sm border p-1.5 border-gray-300 rounded whitespace-pre-line">
                    {{ row.note }}
                  </div>
                  <PrimaryButton text="該当なし" size="normal" class="w-28 bg-teal-700 mr-2" :disabled="true" />
                </div>
              </div>

              <!-- ◆の項目 -->
              <!-- 評価ボタンありの場合 -->
              <div v-if="row.sortNo <= -1 && row.isValueBtn" class="flex w-full ml-8">
                <div v-for="(val, vIdx) in row.valueBtn" :key="'val'+vIdx" class="w-full mr-2">
                  <PrimaryButton :text="val" size="normal" class="w-full" :class="row.value!=String(vIdx)?'bg-gray-400':''" :disabled="start==''||row.disabled||!canEdit" @click="setPoint(String(vIdx), i)" />
                </div>
                <div v-if="canEdit" class="w-full mr-2">
                  <PrimaryButton text="該当なし" size="normal" class="w-full" :class="row.value!='N/A'?'bg-gray-400':'bg-teal-700'" :disabled="start==''||row.disabled" @click="setPoint('N/A', i)" />
                </div>
                <div v-if="!canEdit" class="w-full mr-2">
                  <PrimaryButton text="該当なし" size="normal" class="w-full" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="true" />
                </div>
              </div>
              
              <!-- 指摘事項 -->
              <div v-if="(row.value=='0'||row.value=='1')" class="text-gray-700 mt-3 w-96pa ml-32p sm:text-sm border p-1 border-gray-300 rounded whitespace-pre-line">
                {{ row.note }}
              </div>
            </div>

            <div>
              <!-- 添付画像 -->
              <div class="ml-8 mt-3 mb-5 flex">
                <div v-for="(p, j) in checkList.items[i].photo" :key="'p'+j" class="flex mr-3">
                  <a :href="p" data-lightbox="group">
                    <img :src="p" width="300">
                  </a>
                </div>
              </div>
            </div>
          </div>

          <table class="w-11/12 table-auto ml-auto mr-auto mt-5">
            <thead class="bg-blue-100">
              <tr class="border border-gray-200">
                <th v-for="(c, index) in columns" :key="'c'+index" scope="col" class="border border-gray-200 py-1 text-center text-xs font-medium text-gray-500 tracking-wider bg-colu">
                  {{c.title}}
                </th>
              </tr>
            </thead>
              <tr v-for="(row, i) in checkList.note" :key="'n'+i" class="border border-gray-200 bg-white tracking-wider">
              <!-- 時期 -->
              <td :rowspan="noteLength" v-if="i==noteFirst"  class="cursor-pointer hover-color border border-gray-200 text-center py-1 whitespace-pre-line text-sm text-gray-700 w-24">
                今回
              </td>
              <!-- No -->
              <td v-if="row.num>-1" class="px-3 py-1 border border-gray-200 text-right col-no h-10 cursor-not-allowed">
                <div>
                  {{ row.num }}
                </div>
              </td>
              <!-- 内容 -->
              <td v-if="row.num>-1" class="px-1 py-1 whitespace-pre-line text-sm min-200">
                {{ row.note }}
              </td>
            </tr>
            <div></div>
            <tr v-for="(row, i) in checkList.lastNote" :key="'ln'+i" class="border border-gray-200 bg-white tracking-wider">
              <!-- 時期 -->
              <td :rowspan="lastNoteLength" v-if="i==lastNoteFirst" class="border border-gray-200 text-center py-1 whitespace-pre-line text-sm text-gray-700 w-24">
                前回
              </td>
              <!-- No -->
              <td v-if="row.num>-1" class="px-3 py-1 border border-gray-200 text-right col-no h-10 text-red-700">
                {{ row.num }}
              </td>
              <!-- 内容 -->
              <td  v-if="row.num>-1" class="px-1 py-1 whitespace-pre-line text-sm min-200 h-10 text-red-700">
                {{ row.note }}
              </td>
              <td v-if="row.num>-1">
              </td>
            </tr>
          </table>
          <div class="mt-3 w-11/12 m-auto mb-6">
            <div class="text-gray-700 text-sm">備考</div>
            <WmsTextInput 
              name="checkListMemo" 
              :multiline="true"
              :rows="memoCount"
              class="text-gray-700"
              v-model="checkList.memo"
              :disabled="true"
            />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import Icon from '@components/Icon.vue'
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import * as backend from '@libs/backend'
import moment from 'moment'

export default {
  components: {
    Icon,
    Modal,
    PrimaryButton,
    WmsTextInput
  },

  props: {
    target: {
      type: Object
    },
    type: {
      type: Number,
      default: null
    },
    // クレームか否か
    claim: {
      type: Boolean,
      default: false
    },
    open: {
      type: Number,
      default: null
    },
  },

  data() {
    return {
      loading: false,
      // 履歴
      history: [],
      // 選択した履歴
      selectedHistory: false,
      // 履歴 年
      year: moment().format('YYYY'),
      alertYear: false,
      // カラム
      historyColumns: [
        {title: 'サイクル期限'},
        {title: '点検日'},
        {title: '担当者'},
        {title: ''}
      ],
      // 表示データ
      list: [],
      // 開始時間
      start: '',
      // 終了時間
      end: '',
      // チェック項目
      checkList: {
        items: [
          {classification: '', item: '', value: '', disabled: false, photo: []}
        ],
        note:[
          {id: null, checklistId: null, num: '', note: ''},
          {id: null, checklistId: null, num: '', note: ''},
          {id: null, checklistId: null, num: '', note: ''},
          {id: null, checklistId: null, num: '', note: ''},
        ],
        lastNote:[
          {id: null, checklistId: null, num: '', note: ''},
        ],
        memo: ''
      },
      // 得点
      point: 0,
      totalPoint: 0,
      // カラム
      columns: [
          {title: '時期'},
          {title: 'No.'},
          {title: '指摘事項および指導内容'},
          {title: ''}
      ],
      // 位置情報
      location: '',
      // 備考の大きさ
      memoCount: 1,
      // 選択した履歴のサイクル期限日
      termDate: ''
    }
  },

  computed: {

    /**
     * 今回の特記事項の長さ（確認事項以外）
     */
    noteLength() {
      let length = 1

      if (this.checkList.note && this.checkList.note.length) {
        // 確認事項以外の項目の数を取得
        let leng = this.checkList.note.filter((l) => {
          return l.num > -1
        })
        if (leng) {
          length = leng.length
        }
      }
      return length || 1
    },

    /**
     * 今回の特記事項の時期を表示するインデックス
     */
    noteFirst() {
      if (this.checkList.note && this.checkList.note.length) {
        for (let i = 0; i < this.checkList.note.length; i++) {
          const n = this.checkList.note[i]
          if (n.num > -1) {
            return i
          }
        }
      }
      return 0
    },

    /**
     * 前回の特記事項の長さ（確認事項以外）
     */
    lastNoteLength() {
      let length = 1

      if (this.checkList.lastNote && this.checkList.lastNote.length) {
        // 確認事項以外の項目の数を取得
        let leng = this.checkList.lastNote.filter((l) => {
          return l.num > -1
        })
        if (leng) {
          length = leng.length
        }
      }
      return length || 1
    },

    /**
     * 前回の特記事項の時期を表示するインデックス
     */
    lastNoteFirst() {
      if (this.checkList.lastNote && this.checkList.lastNote.length) {
        for (let i = 0; i < this.checkList.lastNote.length; i++) {
          const n = this.checkList.lastNote[i]
          if (n.num > -1) {
            return i
          }
        }
      }
      return 0
    },
  },

  watch: {
    async open() {
      await this.getHistory()
    },
    
    /**
     *  サイクル期限（年）桁数
     */
    year() {
      if (this.year.length != 4) {
        this.alertYear = true
      } else {
        this.alertYear = false
        this.getHistory()
      }
    },
  },

  methods: {
    /**
     * この物件の履歴を取得
     */
    async getHistory() {
      this.loading = true
      this.history = []
      if (this.target && Object.keys(this.target).length) {
        const hR = await backend.searchData('patrolResult/getHistory', { ...this.target, termDateForm: moment(this.target.termDate).format('YYYY-MM-DD'), year: this.year, type: this.type, claim: this.claim, spot: false })
        if (hR.data && hR.data.data.length) {
          this.history = hR.data.data
        }
      }
      this.loading = false
    },

    /**
     * 表示するデータ取得
     */
    async getTarget(row) {
      this.list = row
      this.termDate = moment(row.termDate).format('YYYY/MM/DD')
        // データ取得
        let scheduledDate = moment(row.completionDate).format('YYYY-MM-DD')
        const compR = await backend.searchData('patrolResult/getByPatrolScheduleId', { patrolScheduleId: row.patrolScheduleId, siteCode: row.siteCode, scheduledDate, type: this.type, claim: this.claim, spot: false })
        if (compR.data && compR.data.data) {
          let r = compR.data.data
          if (r.result.length) {
            let result = r.result[0]
            this.start = result.startTime
            this.end = result.endTime
            this.location = result.location
            this.point = result.getPoint
            this.totalPoint = result.totalPoint
          }
          if (r.item) {
            this.checkList.items = r.item
          }
          if (r.note) {
            this.checkList.note = r.note
            for (let i = 0; i < this.checkList.items.length; i++) {
              const it = this.checkList.items[i]
              for (let j = 0; j < this.checkList.note.length; j++) {
                const no = this.checkList.note[j]
                if (it.sortNo == no.num) {
                  it.note = no.note
                  break
                }
              }
            }
          }
          if (r.lastNote) {
            this.checkList.lastNote = r.lastNote
          }
          if (r.memo && r.memo.length && r.memo[0].memo) {
            this.checkList.memo = r.memo[0].memo
            let c = 1
            if (this.checkList.memo != '') {
              c = ( this.checkList.memo.match( /\n/g ) || [] ).length
              this.memoCount = c + 2
            } else {
              this.memoCount = 2
            }
          }
        }
    },

    /**
     * 履歴選択
     * @param row 対象
     */
    async selected(row) {
      this.selectedHistory = true
      await this.getTarget(row)
    },

    /**
     * 履歴一覧に戻る
     */
    backToList() {
      this.selectedHistory = false
      this.initCheck()
    },

    /**
     * 点検表初期化
     */
    initCheck() {
      this.loading = false
      // 履歴
      this.history = []
      // 選択した履歴
      this.selectedHistory = false
      // 履歴 年
      this.year = moment().format('YYYY')
      this.alertYear = false
      // 表示データ
      this.list = []
      // 開始時間
      this.start = ''
      // 終了時間
      this.end = ''
      // チェック項目
      this.checkList = {
        items: [
          {classification: '', item: '', value: '', disabled: false, photo: []}
        ],
        note: [
          {id: null, checklistId: null, num: '', note: ''},
        ],
        lastNote: [
          {id: null, checklistId: null, num: '', note: ''},
        ],
        memo: ''
      }
      // 得点
      this.point = 0
      this.totalPoint = 0
      // 位置情報
      this.location = ''
      // 備考の大きさ
      this.memoCount = 1
      // 選択したサイクル期限日
      this.termDate = ''
    },

    /**
     * 初期化
     */
    closed() {
      // 表示データ
      this.list = []
      this.initCheck()
      
      this.$emit('modalClose')
    },

  }
}
</script>
<style scoped>
.content-container-app {
  width: 930px;
  height: calc(100vh - 130px);
  overflow: auto;
  padding: 10px;
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.max-w-lg {
  max-width: none !important;
}

.col-no {
  width: 70px;
}

.hover-color:hover {
  background-color: rgba(198, 233, 239, 0.853);
  cursor: pointer;
}

.bg-orange {
  background-color: rgb(231 137 70);
}

.bg-orange:hover {
  background-color: rgb(239 111 20);
}

.min-600 {
  min-width: 600px;
}

.w-fit {
  width: fit-content;
}

.ml-32p {
  margin-left: 32px;
}

.w-96pa {
  width: 95%;
}

.bg-history {
  background: #fff9f2e0;
}
@media screen and (max-width: 910px) {
  .content-container-app {
    width: 900px !important;
    height: calc(100vh - 90px);
    overflow: auto;
  }

}
</style>