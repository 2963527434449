<template>
  <div>
    <UnPatrolListComponent
      typeName="品質巡回 クレーム対応 未点検一覧"
      :type="1"
      settionStrageName="ClaimUnpatrolListStorageItemName"
      :claim="true">
    </UnPatrolListComponent>
  </div>
</template>

<script>
import UnPatrolListComponent from '../components/UnPatrolListComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    UnPatrolListComponent
  },
}
</script>