<template>
  <div>
    <PageHeader title="日報一覧" class="main-form">
      <template #title-header-content>
        <div class="flex-1 flex justify-end items-center">
          <div v-if="all" class="text-white text-sm my-auto mr-auto">
            （管理者のみ全員分表示）
          </div>
          <div v-else-if="!all" class="text-white text-sm my-auto mr-auto">
            （自分の日報のみ表示）
          </div>

          <!-- 検索ボタン -->
          <PrimaryButton
            text="検索"
            class="button-contents mr-2"
            @click="getData()"
          >
          </PrimaryButton>

          <div class="text-white font-bold">
            {{ list.length }}件
          </div>

          <!-- リロードアイコン -->
          <Icon
            iconName="Refresh"
            :clickable="false"
            :class="{ 'animate-spin': loading }"
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer" @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="flex justify-end items-stretch">
          <!-- 期間 -->
          <div class="flex items-stretch">
            <input type="date" v-model="filter.start" class="text-sm rounded border-gray-300 p-1" />
            <div class="w-fit text-white text-lg font-bold mx-1 my-auto">～</div>
            <input type="date" v-model="filter.end" class="text-sm rounded border-gray-300 p-1" />
            <div class="text-xs py-2 px-3 bg-green-100 text-green-800 border border-green-800 rounded cursor-pointer ml-1 font-bold" @click="deletePeriod()">
              期間なし
            </div>
          </div>
          
          <!-- 管理者のみ -->
          <div v-if="all" class="ml-4 mt-auto">
            <input
              type="search"
              name="filterUser" 
              placeholder="名前 検索"
              class="py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-36" 
              v-model="filter.user"
            /> 
          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute bottom-4 w-full main-form mx-auto top-32">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full w-full">
        <div v-if="list.length" class="rounded-md border border-gray-200 table-h overflow-auto">
          <table class="table text-sm bg-white">
            <thead class="th sticky top-0 z-20 bg-colu">
              <tr class="text-gray-500 text-xs border border-gray-200 bg-colu">
                <th v-for="(c, i) in columns" :key="'col'+i" class="text-left p-2" :class="c.class">{{ c.val }}</th>
              </tr>
            </thead>
            <tbody v-for="(row, i) in list" :key="i">
              <tr class="hover-color cursor-pointer" @click="openData(row)">
                <!-- 作成日 -->
                <td class="whitespace-nowrap text-sm text-gray-700 min-w-fit p-2 text-center">
                  {{ row.daySt }}
                </td>
                <!-- 作成者 -->
                <td class="whitespace-nowrap text-sm text-gray-700 min-w-fit p-2 text-left">
                  {{ row.userName }}
                </td>
                <!-- 完了 -->
                <td class="whitespace-nowrap text-sm text-gray-700 min-w-fit px-2 py-4 text-center">
                  <Badge :color="statusColor[row.complete]">{{ row.complete?'完了':'未完了' }}</Badge>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="!loading && !list.length" class="w-full h-full flex justify-center items-center text-gray-400">
          <EmptyMessage
            iconName="Document"
            message="該当するデータはありません。"
          />
        </div>
        <div class="text-transparent">{{reload}}</div>
      </div>
    </main>
  </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import Badge from '@components/Badge.vue'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import * as moment from 'moment'
import PageModel from '../../main/pages/PageModel.vue'

const settionStrageName = 'dailyListStrage'

// カラム
const COL = [
  { val: '作成日', class: 'text-center' },
  { val: '名前' },
  { val: '状態', class: 'text-center' },
]

export default {
  extends: PageModel,
  components: {
    Icon,
    PageHeader,
    EmptyMessage,
    PrimaryButton,
    Badge
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * タブレット
     */
    isTabletDevice() {
      return utils.deviceInfo.isTablet
    },

    /**
     * ログインユーザー名
     */
    user() {
      return utils.deleteKana(this.$store.getters.user.user_name)
    },

    /**
     * ログインユーザーID
     */
    userId() {
      return this.$store.getters.user.id
    },
  },

  data() {
    return {
      loading: false,
      reload: 0,
      // リスト
      list: [],
      // カラム
      columns: utils.clone(COL),
      // 検索条件
      filter: {
        // 期間
        start: null,
        end: null,
        // 対象者絞込み
        user: '',
      },
      // 全員見れる人
      all: false,
      // ステータスカラー
      statusColor: {
        true: 'blueGray',
        false: 'red',
        null: 'red',
      },
    }
  },

  async created() {
    this.all = false
    
    // 管理者権限あり
    let auth = this.$store.getters.auth
    if (auth && auth.adminAuth) {
      this.all = true
    }

    // 期間は今月にする
    this.filter.start = moment().format('YYYY-MM-01')
    this.filter.end = moment().format('YYYY-MM-DD')

    this.getSettionStrage()
    await this.getData()
  },

  methods: {
    /**
     * リフレッシュアイコンイベント
     */
    async refresh() {
      await this.getData()
    },

    /**
     * データ取得
     */
    async getData() {
      this.list = []

      // データ取得
      this.loading = true

      const res = await backend.postData('evaluation/dailyReportList ', { ...this.filter, all: this.all, loginUser: this.user, userId: this.userId })
      if (res.data.data && res.data.data.length) {
        this.list = res.data.data
      }

      this.setSessionStorage()
      this.loading = false
    },

    /**
     * 期間削除
     */
    deletePeriod() {
      this.filter.start = null
      this.filter.end = null
    },

    /**
     * 日報画面遷移
     * @param row 選択行
     */
    async openData(row) {
      this.$router.push({ 
        name: 'DailyReport',
        params: {
          target: row
        },
        query: {
          id: row.id
        }
      })
    },

    /**
     * セッションストレージ取得
     */
    getSettionStrage() {
      const searchJson = sessionStorage.getItem(settionStrageName)
      const getItem = JSON.parse(searchJson)
      if (getItem) {
        this.filter = getItem
      }
    },

    /**
     * セッションストレージ保存
     */
    setSessionStorage() {
      const searchStr = JSON.stringify(this.filter)
      sessionStorage.setItem(settionStrageName, searchStr)
    },

  }
}
</script>
  
<style scoped>
.main-form {
  min-width: 550px;
}

.table-h {
  max-height: calc(100% - 40px);
}

.table {
  width: 100%;
}

.bg-colu {
  background: #ebf0fb;
}

.hover-color {
  background: white;
}

.hover-color:hover {
  background: rgb(239, 250, 250);
}
</style>