<template>
  <div>
    <PageHeader title="評価関連 インポート" class="main-form">
      <template #title-header-content>
        <div class="flex-1 flex justify-center items-center">
          
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-16 bottom-4 leading-8 w-full main-form mx-auto">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full w-full">
        <div class="bg-white rounded-md border border-gray-200 table-h overflow-auto h-full">
          <div class="text-indigo-600 font-bold w-fit mx-auto mt-3"><span v-if="!target">インポートしたい種類を選択してください。</span><div v-else class="h-8"></div></div>
          <div class="w-80 mx-auto py-3 border border-blue-400 rounded">  
            <div class="w-fit mx-auto">
              <div v-for="(row, i) in list" :key="i">
                <label class="flex py-4 cursor-pointer">
                  <input type="radio" :value="row.target" v-model="target" class="my-auto">
                  <div class="ml-5 my-auto font-bold" :class="target==row.target?'text-indigo-600':''">{{ row.name }}</div>
                </label>

                <!-- 出勤日数の場合のみ対象年月を入力 -->
                <div v-if="target=='workingDays'&&row.name=='出勤日数'">
                  <div class="text-xs">対象年月</div>
                  <div class="flex">
                    <select
                      v-model="year"
                      class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white px-2 py-1.5 last-center"
                    >
                      <option
                        v-for="(val, i) in yearList" :key="'y' + i"
                        :value="val"
                        class="bg-white">
                        {{ val }}
                      </option>
                    </select>
                    <div class="font-bold mx-2 mt-auto text-xs">年</div>
                    <select
                      v-model="month"
                      class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white px-2 py-1.5 last-center"
                    >
                      <option
                        v-for="(val, i) in monthList" :key="'y' + i"
                        :value="val"
                        class="bg-white">
                        {{ val }}
                      </option>
                    </select>
                    <div class="font-bold mx-2 mt-auto text-xs">月</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-10">
            <input type="file" accept=".xlsx" class="w-fit mx-auto block pl-12" @change="fileUpload($event)" />

            <PrimaryButton class="px-20 mt-5 w-fit mx-auto block" size="normal" text="インポート開始" @click="importData()">
              <LoadingIcon v-if="loading" slot="before" class="h-4 w-4" />
              <Icon v-else iconName="Download" slot="before" class="h-5 w-5" />
            </PrimaryButton>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import * as XLSX from 'xlsx'
import PrimaryButton from '@components/PrimaryButton.vue'
import LoadingIcon from '@assets/loading-circle.svg'
import * as logManager from '@managers/logManager'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as moment from 'moment'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    Icon,
    PageHeader,
    PrimaryButton,
    LoadingIcon,
  },

  computed: {
  },

  data() {
    return {
      loading: false,
      // 対象種別
      target: null,
      // 対象データ
      jsonData: null,
      // 対象インポートリスト
      list: [
        { target: 'thanks', name: 'サンクスカード' },
        { target: 'workingDays', name: '出勤日数' }
      ],
      // 対象年
      year: null,
      // 対象月
      month: null,
      // 対象年月リスト
      yearList: [Number(moment().format('YYYY')) - 1, Number(moment().format('YYYY'))],
      monthList: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    }
  },

  async created() {
    // 先月を初期値とする
    const lastMonth = moment().add(-1, 'month')
    this.year = Number(moment(lastMonth).format('YYYY'))
    this.month = moment(lastMonth).format('MM')
  },

  methods: {
    /**
     * ファイル読み込み
     * @param event 選択ファイル
     */
    fileUpload(event) {
      const file = event.target.files[0]
      const reader = new FileReader()

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array', cellText: false, cellDates: true })
        const sheetName = workbook.SheetNames[0]
        const sheet = workbook.Sheets[sheetName]
        this.jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false, dateNF: 'yyyy"-"mm"-"dd" "HH":"mm":"ss' })
      }
      reader.readAsArrayBuffer(file)
    },

    /**
     * インポート開始
     */
    async importData() {
      let msg = ''
      // 種別
      if (!this.target) {
        msg = 'インポートしたい種類を選択してください。'
      }
      // ファイル
      if (!this.jsonData) {
        if (msg) {
          msg += '\r\n'
        }
        msg += 'ファイルを選択してください。'
      }
      // アラート
      if (msg) {
        await dialogs.showErrorDialog ('インポートできません', msg)
        return
      }

      // 選択した種別名
      const name = this.list.find((l) => {
        return l.target == this.target
      }).name

      if (this.target=='workingDays') {
        const log = await dialogs.showConfirmDialog('確認', `インポートするのは、「${this.year}年${this.month}月」のデータでよろしいしょうか？`)
        if (log != 'YES') {
          return
        }
      }

      this.loading = true

      const importData = {
        target: this.target,
        targetDay: this.year + '-' + this.month + '-01',
        jsonData: this.jsonData,
        userId: this.$store.getters.user.id
      }
      const res = await backend.postData('evaluationImport/importData', importData)
      // エラーの場合
      if (!res.data.result) {
        alert(res.data.errMsg)
        this.loading = false
        logManager.recording(this, logManager.Loglevel.INFO, 'インポート', 'エラー', `${name}のインポートに失敗しました。\r\n${res.data.errMsg}`)
      } else {
        await dialogs.showSuccessDialog('インポート完了', name + 'のインポートが完了しました。')
        logManager.recording(this, logManager.Loglevel.INFO, 'インポート', '成功', `${name}をインポートしました。`)
      }
      this.loading = false
    }
  }
}
</script>
  
<style scoped>
</style>