<template>
  <div class="min-h-screen bg-white">
    <template>
      <!-- ヘッダー -->
      <header>
        <h1 class="signin-header" :class="colorCode=='bg-light-blue-700'?'purple-header':colorCode=='bg-pink-400'?'orange-header':'green-header'"><img src="images/logo.png" class="header-logo"></h1>
      </header>
      <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-auto">
          <div>
            <!-- リクエストポストロゴ -->
            <div class="flex items-center">
              <img src="images/requestpostlogo.png" />
            </div>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <div class="space-y-6" v-if="!id && !sendComplete">
                <div class="space-y-1">
                  <p class="text-sm">
                    パスワードの再設定を行います。<br />
                    ご登録のメールアドレスを入力してください。<br />
                    再設定ページのURLを送付いたします。
                  </p>
                </div>

                <!-- Email address入力欄 -->
                <div>
                  <label for="email" class="block text-sm font-medium text-gray-700">
                    Email address
                  </label>
                  <div class="mt-1">
                    <input
                      v-model="email"
                      id="email" 
                      name="email" 
                      type="text" 
                      autocomplete="off"
                      required
                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div class="space-y-1">
                  <p v-if="sendError" class="text-sm text-red-600">メールアドレスを入力してください。</p>
                </div>
                <div class="space-y-1">
                  <p v-if="sendError2" class="text-sm text-red-600">送信できませんでした。</p>
                </div>
                <div class="space-y-1">
                  <p v-if="sendError3" class="text-sm text-red-600">未登録のメールアドレスです。</p>
                </div>
                <br>
                <!-- 送信ボタン -->
                <div>
                  <PrimaryButton text="送信" class="w-full" @click="send" />
                </div>
              </div>

              <div class="space-y-6" v-if="!id && sendComplete">
                <div class="space-y-1">
                  <p>
                    再設定ページのURLを送信しました。<br />
                    このページは閉じてメールをご確認ください。
                  </p>
                </div>
              </div>

              <div class="space-y-6" v-if="id && !remindComplete">
                <div class="space-y-1">
                  <p class="text-sm">
                    新しいパスワードを入力してください。
                  </p>
                </div>
                <!-- Password入力欄 -->
                <div class="space-y-1">
                  <label for="password" class="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <div class="mt-1">
                    <input 
                      v-model="password"
                      id="password" 
                      name="password" 
                      type="password" 
                      autocomplete="current-password" 
                      required
                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                    />
                  </div>
                </div>
                <div class="space-y-1">
                  <p v-if="remindError" class="text-sm text-red-600">パスワードを入力してください。</p>
                </div>
                <div class="space-y-1">
                  <p v-if="remindError2" class="text-sm text-red-600">設定できませんでした。</p>
                </div>
                <br>
                <!-- 再設定ボタン -->
                <div>
                  <PrimaryButton text="設定" class="w-full" @click="remind" />
                </div>
              </div>

              <div class="space-y-6" v-if="id && remindComplete">
                <div class="space-y-1">
                  <p>
                    パスワードを再設定しました。<br />
                    <a href="./signin" class="text-blue-600 hover:text-blue-900 border-b w-fit border-blue-700">ログインページへ</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import PrimaryButton from '@components/PrimaryButton.vue'
import { PIGEON_CONFIG } from '@/config'
import * as backend from '@libs/backend'

export default {
  components: {
    PrimaryButton,
  },
  data() {
    return {
      id: '',
      email: '',
      password: '',
      sendComplete: false,
      remindComplete: false,
      sendError: false,
      sendError2: false,
      sendError3: false,
      remindError: false,
      remindError2: false,
      // ヘッダカラー
      colorCode: PIGEON_CONFIG.colorCode
    }
  },
  async created() {
    console.log('Reminder.vue created.')
    this.id = this.$route.query.id
  },
  mounted() {
    console.log('Reminder.vue mounted.')
  },
  methods: {
    /**
     * URL送信
     */
    async send() {
      try {
        this.sendError2 = false
        this.sendError3 = false

        // 入力チェック
        this.sendError = !this.email
        if (this.sendError) return

        const result = await backend.postData('auth/reminder-mail', { to: this.email })
        if (result.data == 'complete') this.sendComplete = true
        if (result.data == 'no-regist') this.sendError3 = true
      } catch (e) {
        this.sendError2 = true
        console.log(e)
      }
    },

    /**
     * パスワード再設定
     */
    async remind() {
      try {
        this.remindError2 = false

        // 入力チェック
        this.remindError = !this.password
        if (this.remindError) return

        const result = await backend.postData('auth/reminder', { id: this.id, pass: this.password })
        if (result.data == 'complete') this.remindComplete = true
        if (result.data == 'uid-ng') this.remindError2 = true
        console.log(result.data)
      } catch (e) {
        this.remindError2 = true
        console.log(e)
      }
    }
  }
}
</script>