<template>
  <div>
    <PageHeader :title="spot?'スポット登録':typeName" class="list overflow-visible">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-2 items-center">

          <div class="mx-auto flex items-center gap-1 mr-2">
            <PrimaryButton class="bg-green-600 mr-2" text="予定一覧" @click="pageTransition('ScheduleListByStaff')">
              <Icon iconName="ViewGrid" slot="before" class="h-4 w-4" />
            </PrimaryButton>
          </div>

          <div class="ml-auto">
            <!-- スポット（品質） -->
            <PrimaryButton
              v-if="type==1&&!claim"
              text="スポット登録"
              class="button-contents"
              :class="spot?'bg-light-blue-500':'normalButtonColor'"
              @click="clickSpot()"
            >
            </PrimaryButton>

            <PrimaryButton
              text="検索"
              class="button-contents"
              @click="getData"
            >
            </PrimaryButton>
          </div>
          
          <h1 class="text-lg font-bold text-white flex items-center  ml-2">
            <span class="mt-0 ">{{ list.length }} 件</span>
          </h1>

          <div class="ml-2">
            <!-- 地図有無ボタン -->
            <div class="ml-auto">
              <PrimaryButton v-tooltip="'地図を表示・非表示'" text="地図" size="normal px-2 py-2 btn app-btn-w"
                :class="isMap ? 'clickButtonColor2' : 'normalButtonColor'"
                @click="filterBtn('isMap')" />
            </div>
          </div>

          <div class="ml-2">
            <!-- 未予定ボタン -->
            <div class="ml-auto">
              <PrimaryButton v-tooltip="'予定を組んでいない物件を表示'" text="未予定" size="normal px-2 py-2 btn app-btn-w"
                :class="unScheduledDate ? 'clickButtonColor' : 'normalButtonColor'"
                @click="filterBtn('unScheduledDate')" />
            </div>
          </div>
          <div class="ml-2">
            <!-- 未完了ボタン -->
            <div class="ml-auto">
              <PrimaryButton v-tooltip="'完了していない物件を表示'" text="未完了" size="normal px-2 py-2 btn app-btn-w"
                :class="unCompletionDate ? 'clickButtonColor' : 'normalButtonColor'"
                @click="filterBtn('unCompletionDate')" />
            </div>
          </div>

          <PrimaryButton text="クリア" size="normal px-2" class="normalButtonColor flex button-contents ml-2" @click="clear" :buttonContents="'要'" />

          <Icon 
            iconName="Refresh" 
            :clickable="false" 
            :class="{'animate-spin': loading}" 
            class="text-white hover:text-gray-300 active:text-gray-400 ml-3 cursor-pointer"
            @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="w-fit ml-auto mr-80">
          <button 
            class="px-5 py-0 text-xs bg-light-blue-500 mr-3 text-white cursor-pointer rounded font-bold"
            @click="small=!small"
          >
            欄を{{ small?'拡大':'縮小' }}
          </button>
        </div>
          
        <div class="flex" >
          <!-- <h1 v-if="!isMobileDevice" class="font-bold flex text-white items-center mr-8">
            <span class="mt-0 ">予定を組んでください</span>
          </h1> -->
          <div class="flex flex-1 justify-end">
            <!-- 担当エリア（品質巡回のみ） -->
            <div v-if="type==1&&!claim" class="flex justify-end mr-2">
              <div v-if="!areaList||!areaList.length" class="text-white text-xs font-bold my-auto">
                設定された担当エリアがありません。<br>「点検サイクル設定」でエリア設定してください。
              </div>
              <select
                v-else
                v-model="filterArea"
                class="rounded border-gray-400 filter-form"
              >
                <option v-for="(area, index) in areaList" :value="area.id" :key="'area' + index">
                  {{ area.name }}
                </option>
              </select>
            </div>
            <!-- 物件名 -->
            <div class="flex justify-end mr-2">
              <input
                v-tooltip="'記号・スペースは無視します'"
                type="search"
                name="filterSite" 
                placeholder="物件名 検索"
                class="w-full  pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 filter-form"
                v-model="filterSite"
              />
            </div>
            <div class="flex justify-end mr-2">
              <!-- 得意先名 -->
              <WmsSuggestInput
                id="filter-client"
                name="filterClient"
                placeholder="得意先名 検索"
                :embed="true"
                :clearable="true"
                displayField="clientName1"
                :dynamic="false"
                :incrementalSearch="false"
                :selectionItems="clientList || []"
                class="rounded-md leading-5 border-0 filter-form"
                v-model="filterClient"
              />
            </div>
            <div class="flex justify-end mr-2">
              <!-- 住所 -->
              <input
                v-tooltip="'スペースでOR条件になります'"
                type="search"
                name="filterAddress" 
                placeholder="住所 検索"
                class="w-full pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 filter-form"
                v-model="filterAddress"
              />
            </div>
            <div class="mr-2 multi filter-form">
              <!-- 東京23区 -->
              <multiselect
                v-model="filterTokyo"
                :options="tokyo"
                :multiple="true"
                :close-on-select="false"
                class="w-full z-50 multi2 filter-form"
                :class="small?'field-small':''"
                placeholder="東京23区"
                @open="small=false"
              >
              </multiselect>
            </div>
            <div>
              <!-- サイクル期限 -->
              <div class="mr-auto flex h-43px">
                <input type="number" name="termYearFilter" placeholder="サイクル期限（年）"
                  v-tooltip="alertYear ? '4桁で入力してください' : ''"
                  class="w-16 py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 inline"
                  :class="alertYear ? 'border-red-600' : ''" v-model="termYearFilter" />
                <div class="ml-1 mr-2 my-auto text-white">年</div>

                <input type="text" name="termMonthFilter" placeholder="サイクル期限（月）"
                  v-tooltip="alertMonth ? '不正な値が入っています' : 'スペースで複数月検索可能'"
                  class="py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 inline"
                  :class="[alertMonth ? 'border-red-600' : '', isMobileDevice?'w-20':'']" v-model="termMonthFilter" />
                <div class="ml-1 mr-1 my-auto text-white">月</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </PageHeader>

    <!-- <main class="absolute top-40 bottom-4 -mt-2 list" :class="isMobileDevice ? 'h-mobile-full' : 'w-screen'" > -->
    <main class="absolute top-40 bottom-4 -mt-2 list w-full" >
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full flex">
        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full"
          :class="openScheduled && !isMobileDevice ? 'w-70p' : 'w-full'">
          <!-- 一覧 -->
          <div v-if="loading" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
          </div>
          <div v-show="!loading">
            <div class="rounded main-height1">
              <div v-if="isMap" id="map" ref="map">
                <div class="text-center mt-10 font-bold">検索ボタンを押してください</div>
              </div>

              <div class="cursor-pointer text-left text-xs relative text-white bg-teal-500 input-accordion border-gray-200 font-medium" @click="open()" v-if="isMobileDevice">
                <p class="font-bold" v-if="!openScheduled">▼ 予定入力</p>
                <p class="font-bold" v-if="openScheduled">▲ 予定入力</p>
              </div>

              <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full" :class="!openScheduled ? '' : 'h-0'" v-if="isMobileDevice">
                <div v-if="openScheduled" class="w-3/5 input-bottom m-auto">
                  <UnPatrolInput
                    :type="type"
                    :staffList="staffList"
                    :planDate="planDate"
                    :staff="staff"
                    :schedule="schedule"
                    :columns="columns2"
                    @scheduleOrderChange="scheduleOrderChange"
                    @deleteRow="deleteRow"
                    @scheduleSearchClear="scheduleSearchClear"
                    @save="save"
                    @getByDate="getByDate"
                    @changePlanDate="changePlanDate"
                    @staffSelected="staffSelected"
                    @staffChange="staffChange"
                    @selectedMulti="selectedMulti"
                  />
                </div>
              </div>

              <table class="w-full table-auto">
                <thead class="w-full">
                  <tr v-if="list && list.length" class="border border-gray-200">
                    <th v-for="(c, index) in columns" :key="'c2'+index" scope="col" class="px-2 py-3 text-xs font-medium text-gray-500 tracking-wider bg-colu" :class="c.class">
                      {{c.title}}
                    </th>
                  </tr>
                </thead>
                <tr v-for="(row, i) in list" :key="'r' + i" class="border border-gray-200 tracking-wider hover-color" v-bind:class="[{ 'no-map' : isMap&&!row.latitude }, setBgColor(row)]">
                  <!-- 予定ボタン -->
                  <td class="px-2 py-0.5 text-center text-sm font-medium border-gray w-fit"
                    :class="openScheduled ? 'w-20' : ''">
                    <div class="flex justify-center">
                      <PrimaryButton text="予定" size="normal px-2 py-2 btn app-btn-w"
                        :class="canotEdit(i) ? 'bg-gray-500' : ''" :disabled="canotEdit(i)" @click="edit(row, i, true)" />
                    </div>
                  </td>
                  <!-- 物件名 -->
                  <td class="px-2 py-3 whitespace-pre-line text-sm text-gray-700 min-150"
                    v-tooltip="!row.dateCancellation && row.cancelReason && row.cancelReason != '' ? '物件コード： ' + row.siteCode + ' （解約月なしだが解約理由あり）' : '物件コード： ' + row.siteCode">
                    {{ row.siteName }}
                    <span v-if="row.ridgeCode" class="text-red-700 font-bold text-xs">（{{ row.ridgeName }}）</span>
                    <p v-if="row.dateCancellation" class="text-xs text-blue-500">（解約月 : {{ row.dateCancellation }}）</p>
                    <p v-else-if="row.cancelNextFlag && row.cancelNextFlag == '1'" class="text-xs text-green-500">
                      （次年度解約, 備考：{{ row.cancelReason }}）</p>
                  </td>
                  <!-- 得意先名 -->
                  <td class="px-2 py-3 whitespace-pre-line text-sm text-gray-700 min-150"
                    v-tooltip="'得意先コード： ' + row.clientCode">
                    {{ row.clientName1 }}
                  </td>
                  <!-- 住所 -->
                  <td class="px-2 py-3 whitespace-pre-line text-sm text-gray-700 min-180">
                    {{ row.siteAddress }}
                  </td>
                  <!-- 期限 -->
                  <td v-if="!spot" class="px-2 whitespace-nowrap text-sm text-gray-700 w-fit">
                    {{ row.limit }}
                    <div v-if="(row.week || row.week == 0) && row.week != '-1'" class="text-xs text-blue-500">
                      毎週{{ toDayOfWeek(row.week) }}曜日
                    </div>
                  </td>
                  <!-- Weight -->
                  <td v-if="!spot" class="px-2 py-3 whitespace-nowrap text-sm text-gray-700 w-fit text-right">
                    {{ row.weight }}
                  </td>
                  <!-- 平均時間 -->
                  <td v-if="!spot" class="px-2 py-3 whitespace-nowrap text-sm text-gray-700 w-fit text-right">
                    {{ row.times }}
                  </td>
                  <!-- 人数 -->
                  <td v-if="!spot" class="px-2 py-3 whitespace-nowrap text-sm text-gray-700 w-fit text-right">
                    {{ row.people }} <span class="text-xs text-gray-400">人</span>
                  </td>
                  <!-- 予定日 -->
                  <td v-if="!isColumnsReduce" class="px-2 py-3 whitespace-nowrap text-sm text-gray-700 w-fit">
                    {{ row.scheduledDate }}
                  </td>
                  <!-- 完了日 -->
                  <td v-if="!isColumnsReduce" class="px-2 py-3 whitespace-nowrap text-sm text-gray-700 w-fit"
                    :class="openScheduled ? 'w-20' : ''">
                    {{ row.completionDate }}
                  </td>
                </tr>
              </table>
              <div class="text-transparent">{{reload}}</div>
            </div>
          </div>
        </div>

        <div class="w-6 h-full bg-teal-500 hover:bg-teal-600 cursor-pointer text-white text-center text-xs relative" @click="open()" v-if="!isMobileDevice">
          <p class="bar" v-if="!openScheduled">◀<br>◀<br>◀</p>
          <p class="bar" v-if="openScheduled">▶<br>▶<br>▶</p>
        </div>

        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full" :class="openScheduled ? 'w-570px' : 'w-0'" v-if="!isMobileDevice">
          <div v-if="openScheduled">
            <UnPatrolInput
              :type="type"
              :staffList="staffList"
              :planDate="planDate"
              :staff="staff"
              :schedule="schedule"
              :columns="columns2"
              @scheduleOrderChange="scheduleOrderChange"
              @deleteRow="deleteRow"
              @scheduleSearchClear="scheduleSearchClear"
              @save="save"
              @getByDate="getByDate"
              @changePlanDate="changePlanDate"
              @staffSelected="staffSelected"
              @staffChange="staffChange"
              @selectedMulti="selectedMulti"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAH_sBOh9GMJIZA9TuhVu6b6fyKvKWi_RI&callback=initMap&v=weekly" defer></script>
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import UnPatrolInput from './UnPatrolInputComponent.vue'
import Toggle from '@components/Toggle.vue'
import draggable from "vuedraggable"
import { TrashIcon, MenuIcon } from '@vue-hero-icons/solid'
import { WEIGHT } from '@libs/constants'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import * as moment from 'moment'
import * as backend from '@libs/backend'
import * as logManager from '@managers/logManager'
import * as constants from '@libs/constants'
// import { MarkerClusterer } from "@googlemaps/markerclusterer"
import Multiselect from 'vue-multiselect'
import * as requestPostManager from '@managers/requestPostManager'

const APOLLO = constants.APOLLO
const PEOPLE = 1

const COLUMNSALL = [
          {title: ''},
          {title: '物件名', class: 'text-left'},
          {title: '得意先名', class: 'text-left'},
          {title: '住所', class: 'text-left'},
          {title: '期限', class: 'text-left'},
          {title: 'Weight', class: 'text-right'},
          {title: '平均', class: 'text-right'},
          {title: '人数', class: 'text-right'},
          {title: '予定日', class: 'text-left'},
          {title: '完了日', class: 'text-left'},
      ]

const COLUMNSALL2 = [
          {title: ''},
          {title: '物件名', class: 'text-left'},
          {title: '得意先名', class: 'text-left'},
          {title: '住所', class: 'text-left'},
          {title: '期限', class: 'text-left'},
          {title: 'Weight', class: 'text-left'},
          {title: '平均', class: 'text-right'},
          {title: '人数', class: 'text-right'},
      ]

const COLUMNSSPOT = [
          {title: ''},
          {title: '物件名', class: 'text-left'},
          {title: '得意先名', class: 'text-left'},
          {title: '住所', class: 'text-left'},
          {title: '予定日', class: 'text-left'},
          {title: '完了日', class: 'text-left'},
      ]

const COLUMNSSPOT2 = [
          {title: ''},
          {title: '物件名', class: 'text-left'},
          {title: '得意先名', class: 'text-left'},
          {title: '住所', class: 'text-left'},
      ]

var map

export default {
  components: {
    Icon,
    PrimaryButton,
    PageHeader,
    WmsDateInput,
    WmsSuggestInput,
    UnPatrolInput,
    Toggle,
    draggable,
    TrashIcon,
    MenuIcon,
    Multiselect
  },

  data() {
    return {
      loading: false,
      initial: true,
      //検索保持用
      searchCondition: {
        filterArea: '',
        filterSite : '',
        filterClient : '',
        filterAddress : '',
        filterTokyo: [],
        termYearFilter: '',
        termMonthFilter:'',
        isMap: false,
        unScheduledDate: true,
        unCompletionDate: true
      },
      // カラム
      columns: utils.clone(COLUMNSALL),
      // サイクル期限絞り込み
      termYearFilter: moment().format('YYYY'),
      // サイクル期限不正入力
      alertYear: false,
      // サイクル期限絞り込みリスト
      termMonthFilter: '',
      // サイクル期限不正入力
      alertMonth: false,
      // 地図の表示・非表示
      isMap: false,
      // 未予定ボタン
      unScheduledDate: true,
      // 未完了ボタン
      unCompletionDate: true,
      // 担当エリア絞り込み
      filterArea: null,
      // 物件名絞込み
      filterSite: '',
      // 得意先名絞込み
      filterClient: '',
      // 住所絞込み
      filterAddress: '',
      // 東京23区絞り込み
      filterTokyo: [],
      // テーブル全データ
      original: [],
      // 表示するデータ
      list: [],
      // スケジュールopen
      openScheduled: false,
      // スケジュールダイアログ
      schedule: {
        list: []
      },
      // 選択した点検予定日
      planDate: '',
      // 選択したスタッフ
      staff: {
        name: '',
        userId: null,
        patrolId: null
      },
      // スタッフリスト
      staffList: [],
      // スケジュール カラム
      columns2: [
        { title: 'No.' },
        { title: '期限' },
        { title: '物件名' },
        { title: 'Weight' },
        { title: '' },
        { title: '' },
      ],
      // 並べ替え
      sortable: false,
      // 東京23区の欄を小さくする
      small: false,
      // 東京23区リスト
      tokyo: [
        '全て',
        '千代田区',
        '中央区',
        '港区',
        '新宿区',
        '文京区',
        '台東区',
        '墨田区',
        '江東区',
        '品川区',
        '目黒区',
        '大田区',
        '世田谷区',
        '渋谷区',
        '中野区',
        '杉並区',
        '豊島区',
        '北区',
        '荒川区',
        '板橋区',
        '練馬区',
        '足立区',
        '葛飾区',
        '江戸川区'
      ],
      // カラムが減った場合
      isColumnsReduce: false,
      // エリアリスト（品質巡回のみ）
      areaList: [],
      // 画面反映
      reload: 0,
      // 得意先リスト
      clientList: [],
      // スポット登録
      spot: false
    }
  },

  props: {
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    // 区分
    type: {
      type: Number,
      default: null
    },
    // セッションストレージ名
    settionStrageName: {
      type: String,
      default: null
    },
    // クレームか否か
    claim: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    totalWeight() {
      let result = 0
      if (this.schedule.list && this.schedule.list.length) {
        for (let i = 0; i < this.schedule.list.length; i++) {
          const l = this.schedule.list[i]
          const w = Number(l.weight)
          if (w && !isNaN(w)) {
            result += Number(w)
          }
        }
      }
      return result
    },

    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * タブレット
     */
    isTablet() {
      return utils.deviceInfo.isTablet
    },
  },

  watch: {
    /**
     *  サイクル期限（年）桁数
     */
    termYearFilter() {
      if (this.termYearFilter.length != 4) {
        this.alertYear = true
      } else {
        this.alertYear = false
      }
    },

    /**
     * サイクル期限（月）半角変換
     */
    termMonthFilter() {
      this.termMonthFilter = utils.hankaku(this.termMonthFilter)
      let tm = this.termMonthFilter
      if (tm == '') {
        this.alertMonth = false
        return
      }
      tm = tm.trim()
      let arr = tm.split(' ')
      for (let i = 0; i < arr.length; i++) {
        const a = arr[i]
        if (isNaN(a) || a < 1 || a > 12) {
          this.alertMonth = true
          return
        }
      }
      this.alertMonth = false
    }
  },

  async created() {
    // 定期清掃の場合、カラム名を変更
    if (this.type == 3) {
      this.columns2.splice(4, 0, { title: '' })
    }
    // 巡回清掃、定期清掃はカラムの文言を変更
    if (this.type == 2 || this.type == 3) {
      this.changeColumn()
      this.columns2[1].title = '施工月'
    }
    const d = moment().format('MM')
    let tmf = d
    if (this.type != 2) {
      switch (d) {
        case '01':
        case '02':
        case '03':
          tmf = '1 2 3'
          break;

        case '04':
        case '05':
        case '06':
          tmf = '4 5 6'
          break;

        case '07':
        case '08':
        case '09':
          tmf = '7 8 9'
          break;

        case '10':
        case '11':
        case '12':
          tmf = '10 11 12'
          break;
      }
    }
    this.termMonthFilter = tmf
    // 条件取得
    this.getCondition()

    // 品質巡回の担当エリアリスト取得
    await this.getAreaList()
    
    // スタッフリスト取得
    let staff = await backend.searchData('patrolStaff/get', { type: this.type })
    if (staff.data && staff.data.data) {
      this.staffList = staff.data.data
      const userId = this.$store.getters.user.id
      const user = this.staffList.find((s) => {
        return s.userId == userId
      })

      // ログインユーザーを初期値とする
      if (user && user.userId) {
        await this.staffSelected(user)
        // エリア検索初期値設定
        this.getArea(user)
      }
    }
    await this.getData()

    // 得意先リスト取得
    await this.getClientList()
  },

  methods: {
    /**
     * 予定日変更
     */
    async changePlanDate(val) {
      this.planDate = val
    },

    /**
     * 選択した点検予定日
     */
    async selectedDate() {
      await this.getByDate()
    },

    /**
     * スタッフ選択
     * @param e 選択対象
     */
    async staffSelected(e) {
      if (e.userId) {
        this.staff.userId = e.userId
        this.staff.patrolId = e.patrolNumber
        this.staff.name = e.name
        await this.getByDate()
      }
    },

    /**
     * スタッフ変更
     */
    staffChange() {
      if (this.staff.name == '') {
        this.staff.userId = null
        this.staff.patrolId = null
      }
    },

    /**
     * 連日決定イベント
     */
    selectedMulti(val) {
      this.schedule.list[val.idx].subDate = val.save
    },

    /**
     * 対象の日、スタッフの既存予定を取得
     */
    async getByDate() {
      if (!this.planDate || this.planDate == '' || !this.staff.name || this.staff.name == '') {
        return
      }

      // IDのあるスケジュールを削除
      let sch = []
      sch = this.schedule.list.filter((s) => {
        return !s.patrolScheduleId
      })
      this.schedule.list = sch

      const dateR = await backend.searchData('patrolSchedule/getByScheduleDate', { scheduledDate: this.planDate, ...this.staff, type: this.type })
      if (dateR.data && dateR.data.data.length) {
        await dialogs.showInfoDialog('既存の予定あり', `${this.planDate}、${this.staff.name}さんは既に予定があるので表示します。`)
        for (let i = 0; i < dateR.data.data.length; i++) {
          const d = dateR.data.data[i]
          this.schedule.list.push(d)
        }

        // mapのピンを追加するため、地図をリロード
        if (this.isMap) {
          this.initMap(map.getZoom(), map.getCenter())
        }
      }
    },

    /**
     * クリアボタンイベント
     */
    clear(){
      sessionStorage.removeItem(this.settionStrageName)
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },

    /**
     * 検索保持取得
     */
    getCondition(){
      const searchJson = sessionStorage.getItem(this.settionStrageName)
      const getItem = JSON.parse(searchJson)
    
      if(getItem !== null && getItem !== undefined){
        this.filterArea = getItem.filterArea || null
        this.filterSite = getItem.filterSite
        this.filterClient = getItem.filterClient
        this.filterAddress = getItem.filterAddress
        this.termYearFilter = getItem.termYearFilter
        this.termMonthFilter = getItem.termMonthFilter
        this.unScheduledDate = getItem.unScheduledDate
        this.unCompletionDate = getItem.unCompletionDate
        this.filterTokyo = getItem.filterTokyo || []
        this.isMap = getItem.isMap || false
      }
    },

    /**
     * 担当エリア候補を取得
     */
    async getAreaList() {
      // 品質巡回の場合のみ
      if (this.type == 1 && !this.claim) {
        const res = await backend.searchData('patrolDepartmentArea/getGroupByArea')
        if (res.data && res.data.data && res.data.data.length) {
          this.areaList = res.data.data
          const msg = [{ id: null, name: '担当エリア未選択' }]
          this.areaList = msg.concat(this.areaList)
        }
      }
    },

    /**
     * 担当エリア初期値
     */
    getArea(user) {
      // 品質巡回の場合のみ
      if (this.type == 1 && !this.claim) {
        if (this.areaList && this.areaList.length && user.area) {
          // ログインユーザーの担当エリアがあればデフォルトで設定
          if (!this.filterArea) {
            for (let i = 0; i < this.areaList.length; i++) {
              const a = this.areaList[i]
              if (user.area == a.name) {
                this.filterArea = a.id
                return
              }
            }
          }
        }
      }
    },

    /**
     * 未点検一覧データ取得
     */
    async getData() {
      // 検索が未予定かつ完了になっている、あり得ない条件の場合
      if (this.unScheduledDate && !this.unCompletionDate) {
        let filterDialog = await dialogs.showConfirmDialog('0件になります', '予定を組まずに完了している物件は存在しません。\r\n検索を行いますか？')
        if (filterDialog != 'YES') {
          return
        }
      }
      // サイクル期限チェック
      if (this.termYearFilter == '') {
        alert('サイクル期限（年）を入力してください。')
        return
      }
      if (this.alertYear) {
        alert('サイクル期限（年）は4桁で入力してください。')
        return
      }
      if (this.termMonthFilter == '') {
        alert('サイクル期限（月）を入力してください。')
        return
      }
      if (this.alertMonth) {
        alert('サイクル期限（月）の値が不正です。')
        return
      }

      this.loading = true
      this.termMonthFilter = this.termMonthFilter.trim()
      let arr = this.termMonthFilter.split(' ')
      // 重複削除
      arr = utils.unique(arr)
      // ソート
      arr.sort(function (a, b) {
        return a - b
      })
      const sd = ('00' + arr[0]).slice(-2)
      let startDay = moment(this.termYearFilter + '-' + sd + '-' + '01').format('YYYY-MM-DD')
      const ed = ('00' + arr[arr.length - 1]).slice(-2)
      let endDay = moment(this.termYearFilter + '-' + ed + '-' + '01').endOf('month').format('YYYY-MM-DD')

      let ad = ''
      if (this.filterAddress) {
        this.filterAddress = this.filterAddress.trim()
        ad = this.filterAddress.replace(/\s/g, '%\',\'%')
      }
      // Webストレージ保存用
      this.searchCondition.filterArea = this.filterArea
      this.searchCondition.filterSite = this.filterSite
      this.searchCondition.filterClient = this.filterClient
      this.searchCondition.filterAddress = this.filterAddress
      this.searchCondition.filterTokyo = this.filterTokyo
      this.searchCondition.termYearFilter = this.termYearFilter
      this.searchCondition.termMonthFilter = this.termMonthFilter
      this.searchCondition.isMap = this.isMap
      this.searchCondition.unScheduledDate = this.unScheduledDate
      this.searchCondition.unCompletionDate = this.unCompletionDate
      // Webストレージ保存
      this.setSessionStorage(this.searchCondition,this.settionStrageName)

      let tokyoString = ''
      if (this.filterTokyo.length) {
        tokyoString = this.filterTokyo.join('%\',\'%')
        tokyoString = `'%${tokyoString}%'`
      }

      // AKBSから得意先データ取得
      const akbs = await backend.searchData('akbs/getClientAndSiteListOrderByAddress', { startDate: startDay, endDate: endDay, filterSite: this.filterSite, filterClient: this.filterClient, filterAddress: ad, filterTokyo: tokyoString, type: this.type, isMap: this.isMap })

      let client = []

      // 東京23区が絞り込まれている場合は、テストデータヒットしない
      if (!this.filterTokyo || !this.filterTokyo.length) {
        // テストデータを検索にかける（住所の検索ではスペースが入っている時は引っかからない）
        let apollo = []
        apollo = utils.clone(APOLLO)
        for (let i = 0; i < apollo.length; i++) {
          const A = apollo[i]
          if (A.siteName.indexOf(this.filterSite) != -1 && A.clientName1.indexOf(this.filterClient) != -1 && A.siteAddress.indexOf(this.filterAddress) != -1) {
            client.push(A)
          }
        }
      }
      
      if (akbs.data.data) {
        client = client.concat(akbs.data.data)
      }
      // 期限、予定、完了日取得
      const termData = await backend.searchData('patrolTerm/getUnPatrolList', { year: this.termYearFilter, startDate: startDay, endDate: endDay, termMonthFilter: arr.join(','), type: this.type, claim: this.claim, filterArea: this.filterArea, spot: this.spot })
      // console.log( termData.data.data.base)

      let result = []
      // 通常の未予定
      if (!this.claim && !this.spot) {
        // デフォルト値取得
        let def = []
        let bySite = {}
        for (let k = 0; k < termData.data.data.base.length; k++) {
          const t2 = termData.data.data.base[k]
          if (t2.siteCode == '-1') {
            def.push(t2)

          // 物件コードごとにサイクル期限配列を生成
          } else {
            let key = t2.siteCode

            // 定期清掃で棟設定がある場合
            if (this.type == 3 && t2.ridgeCode) {
              key = t2.siteCode + '@' + t2.ridgeCode
              // console.log(t2)
            }
            if (bySite[key]) {
              bySite[key].limitArr.push(t2.term)
              if (t2.isTerm) {
                bySite[key].isTerm = true
              }
            } else {
              bySite[key] = { limitArr: [t2.term], isTerm: t2.isTerm }
            }
          }
        }
        // データ結合 基本情報
        for (let i = 0; i < client.length; i++) {
          const cli = utils.clone(client[i])
          let flag = false
          let termResult = termData.data.data.base
          for (let j = 0; j < termResult.length; j++) {
            const c = utils.clone(cli)
            const t = termResult[j]

            // 定期清掃に棟設定があるので、得意先コードでも紐づけ。棟がない場合は棟コードはNULL、棟コード・得意先コードありの場合は現在設定中の棟、棟コードあり・得意先コードなしは現在設定されていない過去の棟コードで予定or完了している分
            if (c.siteCode == t.siteCode && (!t.ridgeCode || (t.ridgeCode && c.clientCode == t.clientCode) || (t.ridgeCode && !t.clientCode))) {
              flag = true
              c.limit = t.term
              if (!t.weight && t.weight != 0) {
                c.weight = WEIGHT
              } else {
                c.weight = t.weight
              }
              if (!t.people) {
                c.people = PEOPLE
              } else {
                c.people = t.people
              }
              c.scheduledDate = t.scheduledDate
              c.completionDate = t.completionDate
              c.week = t.week

              // 棟設定がある場合
              let key = t.siteCode
              if (this.type == 3 && t.ridgeCode) {
                // サイクル期間設定の有無判断のキー項目変更
                key = t.siteCode + '@' + t.ridgeCode
                // 棟データをセット
                c.ridgeCode = t.ridgeCode
                c.ridgeName = t.ridgeName
              }

              result.push(utils.clone(c))

              // サイクル期限が設定されていない&予定も組まれていない物件（だがweight設定データがあるもの）
              // isTermでデフォルトの期間（false）か、それとも期間は設定しているが画面表示外の期間なのか（true）を判定している
              // 期間がnullだからと、nullのものすべてにデフォルト期間を入れてはいけないのは、上記の理由
              
              if (!bySite[key].isTerm) {
                for (let k = 0; k < def.length; k++) {
                  const de = def[k]
                  if (t.term != de.term) {
                    if (!bySite[key].limitArr.includes(de.term)) {
                      c.limit = de.term
                      c.week = de.week
                      // 他のデフォルトサイクル日には予定や完了日が入っているが、ここに来た場合はどちらもなしにしなければならない
                      c.scheduledDate = null
                      c.completionDate = null

                      bySite[key].limitArr.push(de.term)
                      result.push(utils.clone(c))
                    }
                  }
                }
              }
            }
          }
          // 物件コードがないものは、デフォルト値
          if (!flag && def.length) {
            for (let di = 0; di < def.length; di++) {
              const d = def[di]
              cli.limit = d.term
              cli.weight = WEIGHT
              cli.people = PEOPLE
              cli.scheduledDate = d.scheduledDate
              cli.completionDate = d.completionDate
              cli.week = d.week
              result.push(utils.clone(cli))
            }
          }
        }
      // クレーム対応
      } else if (this.claim) {
        // 期間設定をしている物件のみを表示
        for (let ci = 0; ci < client.length; ci++) {
          const cc = client[ci]
          for (let cj = 0; cj < termData.data.data.base.length; cj++) {
            const cb = termData.data.data.base[cj]
            if (cb.siteCode == cc.siteCode) {
              cc.limit = cb.term
              cc.weight = cb.weight
              cc.people = cb.people
              cc.scheduledDate = cb.scheduledDate
              cc.completionDate = cb.completionDate
              cc.week = cb.week
              result.push(utils.clone(cc))
            }
          }
        }
      } else {
        result = termData.data.data.base
      }

      // データ結合 平均時間
      if (termData.data.data.time) {
        for (let ri = 0; ri < result.length; ri++) {
          const res = result[ri]
          // 平均時間
          for (let ti = 0; ti < termData.data.data.time.length; ti++) {
            const time = termData.data.data.time[ti]
            if (res.siteCode == time.siteCode && ((!time.ridgeCode && !res.ridgeCode || time.ridgeCode == res.ridgeCode))) {
              res.times = time.times + ' 分'
              break
            }
          }
        }
      }

      if (!this.spot) {
        // 表示対象のサイクル期限がある物件のみ取得
        this.original = result.filter((c2) => {
          return c2.limit
        })
      } else {
        this.original = result.concat(client)
      }

      // 担当エリアで絞り込み（品質巡回の場合）
      this.filterlingArea(termData)

      for (let i = 0; i < this.schedule.list.length; i++) {
        const s = this.schedule.list[i]
        for (let j = 0; j < this.original.length; j++) {
          const l = this.original[j]
          if (s.siteCode == l.siteCode && !l.disabled && (!l.ridgeCode || l.ridgeCode == s.ridgeCode)) {
            if (!this.spot && s.limit == l.limit && !s.spot) {
              l.disabled = true
              break
            } else if (this.spot && s.spot) {
              l.disabled = true
              break
            }
          }
        }
      }

      this.filtering()
      this.initMap(null, null)
    },

    /**
     * 検索条件をセッションストレージへ保存
     */
    setSessionStorage(searchCondition,storageName) {
      const searchStr = JSON.stringify(searchCondition)
      sessionStorage.setItem(storageName, searchStr)
    },

    /**
     * ボタン切替
     * @param target 絞込み対象
     */
    async filterBtn(target) {
      if (target == 'unScheduledDate') {
        this.unScheduledDate = !this.unScheduledDate
      } else if (target == 'unCompletionDate') {
        this.unCompletionDate = !this.unCompletionDate
      } else if (target == 'isMap') {
        this.isMap = !this.isMap
      }
    },

    /**
     * 担当エリアで絞り込み
     */
    filterlingArea(termData) {
      // 品質巡回の場合のみ
      if (this.type == 1 && !this.claim && termData.data.data && termData.data.data.area && termData.data.data.area.length) {
        let area = []
        area = termData.data.data.area
        let res = []
        for (let i = 0; i < this.original.length; i++) {
          const o = this.original[i]
          for (let j = 0; j < area.length; j++) {
            const a = area[j]
            if (o.siteCode == a.siteCode && o.clientCode == a.clientCode) {
              res.push(o)
              break
            }
          }
        }
        this.original = res
      }
    },

    /**
     * 検索
     */
    filtering() {
      this.loading = true
      this.list = []
      if (this.original.length) {
        let result = this.original
        let result2 = []
        let result3 = []

        // カラムを変更
        if (this.spot) {
          if (this.unScheduledDate && this.unCompletionDate) {
            this.columns = utils.clone(COLUMNSSPOT2)
            this.isColumnsReduce = true
          } else {
            this.columns = utils.clone(COLUMNSSPOT)
            this.isColumnsReduce = false
          }
        } else {
          if (this.unScheduledDate && this.unCompletionDate) {
            this.columns = utils.clone(COLUMNSALL2)
            this.isColumnsReduce = true
          } else {
            this.columns = utils.clone(COLUMNSALL)
            this.isColumnsReduce = false
          }
        }
        
        this.changeColumn()

        // 未予定 活性
        if (this.unScheduledDate) {
          result2 = result.filter((r) => {
            return !r.scheduledDate || r.scheduledDate == ''
          })
        // 未予定 非活性
        } else {
          result2 = result.filter((r) => {
            return r.scheduledDate && r.scheduledDate != ''
          })
        }

        // 未完了 活性
        if (this.unCompletionDate) {
          result3 = result2.filter((r2) => {
            return !r2.completionDate || r2.completionDate == ''
          })
        // 未完了 非活性
        } else {
          result3 = result2.filter((r2) => {
            return r2.completionDate && r2.completionDate != ''
          })
        }
        this.list = result3
      }
      this.loading = false
    },

    /**
     * 曜日に変更
     * @param num 数字
     */
    toDayOfWeek(num) {
      return utils.toDayOfWeek(Number(num))
    },

    /**
     * スケジュールOpen
     */
    open() {
      this.openScheduled = !this.openScheduled
    },

    /**
     * 予定ボタン
     * @param row 追加する物件
     */
    edit(row, i, reload) {
      this.openScheduled = true

      // スポット登録の場合、同日に同物件があれば警告を出す
      if (this.spot) {
        for (let idx = 0; idx < this.schedule.list.length; idx++) {
          const s = this.schedule.list[idx]
          if (s.spot && s.siteCode == row.siteCode) {
            if (!window.confirm('既に同物件が予定に組まれていますが追加しますか？')) {
              return
            }
          }
        }
      }
      row.claim = this.claim
      row.spot = this.spot
      row.type = this.type
      let tn = '品質'
      if (this.claim) {
        tn = 'ｸﾚｰﾑ'
      } else if (this.type == 2) {
        tn = '巡回'
      } else if (this.type == 3) {
        tn = '定期'
      } else if (this.type == 4) {
        tn = '設備'
      } else if (this.spot) {
        tn = 'ｽﾎﾟｯﾄ'
      }
      row.typeName = tn
      this.schedule.list.push(utils.clone(row))
      this.list[i].disabled = true
      this.list[i].scheduleSortNo = this.schedule.list.length
      // console.log(this.list)

      // mapのピンの色を変更するため、地図をリロード
      if (reload && this.isMap) {
        this.initMap(map.getZoom(), map.getCenter())
      }
      this.reload++
    },

    /**
     * 削除ボタン
     * @param i index
     */
    async deleteRow(i) {
      // 既に保存されている予定
      if (this.schedule.list[i].patrolScheduleId) {
        await dialogs.showWarningDialog('既に保存されています', '既に保存された予定なので削除できません。\r\n「予定一覧」にて削除できます。')
        return
      }
      let sC = this.schedule.list[i].siteCode
      let limit = this.schedule.list[i].limit
      let claim = this.claim
      let rid = this.schedule.list[i].ridgeCode
      for (let j = 0; j < this.list.length; j++) {
        const lJ = this.list[j]
        if (sC == lJ.siteCode && claim == this.schedule.list[i].claim && lJ.disabled && (!rid || rid == lJ.ridgeCode)) {
          if (!this.spot && limit == lJ.limit) {
            this.list[j].disabled = false
            this.schedule.list.splice(i, 1)
            this.reload++
            break
          } else if (this.spot) {
            this.list[j].disabled = false
            this.schedule.list.splice(i, 1)
            this.reload++
            break
          }
        }
      }

      // mapのピンの色を変更するため、地図をリロード
      if (this.isMap) {
        this.initMap(map.getZoom(), map.getCenter())
      }
    },

    /**
     * クリアボタン
     */
    async scheduleSearchClear() {
      const dialogResult = await dialogs.showWarningConfirmDialog('全て削除', '全て削除してもよろしいでしょうか。')
      if (dialogResult != 'YES') {
        return
      }
      this.schedule.list = []
      this.planDate = ''
      this.list.map((l) => {
        l.disabled = false
      })
      this.reload++
    },

    /**
     * バリデート
     */
    async validate() {
      if (!this.planDate || this.planDate == '') {
        await dialogs.showErrorDialog('点検予定日未入力', '点検予定日を入力してください。')
        return false
      }
      if (!this.staff.name || this.staff.name == '') {
        await dialogs.showErrorDialog('担当者未入力', '担当者を入力してください。')
        return false
      }
      if (!this.staff.userId || this.staff.userId == '') {
        await dialogs.showErrorDialog('担当者IDエラー', '担当者を再度選択してください。')
        this.staff.name = ''
        this.staff.userId = null
        return false
      }
      if (!this.schedule.list.length) {
        await dialogs.showErrorDialog('物件なし', '物件を選択してください。')
        return false
      }
      if (this.type == 3) {
        for (let i = 0; i < this.schedule.list.length; i++) {
          const l = this.schedule.list[i]
          if (l.type == 3) {
            if (l.subDate) {
              const sd = Number(moment(this.planDate).format('YYYYMMDD'))
              for (let j = 0; j < l.subDate.length; j++) {
                const s = Number(moment(l.subDate[j]).format('YYYYMMDD'))
                // 連日が作業日以前だったらNG
                if (sd >= s) {
                  await dialogs.showErrorDialog('連日設定が不正です', `「${l.siteName}」の連日設定日が不正です。\r\n${moment(this.planDate).format('M月D日')}より後に設定してください。`)
                  return false
                }
              }
            }
          }
        }
      }
      if (this.totalWeight > 300) {
        const dialogResult = await dialogs.showConfirmDialog('Weight', 'Weightが300を超えていますがよろしいでしょうか？')
        if (dialogResult !== 'YES') {
          return false
        }
      }
      return true
    },

    /**
     * 保存ボタン
     */
    async save() {
      // バリデート
      if (!await this.validate()) {
        return
      }
      
      // RP登録用情報
      const dep = await this.getDepartments(this.staff.userId)
      const limit = this.limitDateRP()
      const staffName = utils.getUserNameById(this.staff.userId, this.$store.getters.userList)
      let log = ''
      let msg = ''
      let saveData = []

      for (let i = 0; i < this.schedule.list.length; i++) {
        let l = this.schedule.list[i]
        let row
        if (this.type == l.type && this.claim == l.claim) {
          row = utils.clone(l)
          row.sortNo = i + 1
          row.scheduledDate = this.planDate
          row.insertUser = this.$store.getters.user.id
          row.staff = this.staff.name
          row.userId = this.staff.userId
          row.patrolId = this.staff.patrolId

          // スポットの場合、期限を予定日と同じ日
          if (l.spot) {
            row.limit = this.planDate
          }

          // 定期清掃の場合、RPメインを新規登録
          if (this.type == 3) {
            const res = await this.mainTask(row, dep, limit, staffName)
            if (res) {
              row.requestPostId = res.id
              let name = row.siteName
              if (row.ridgeCode) {
                name = name + '（' + row.ridgeName + '）'
              }
              log = log + 'メインタスク（お問い合わせ番号：' + res.requestNo + '、物件名：' + name + '）の張り紙タスク\r\n'
              msg = msg + '物件名：' + name + '、お問い合わせ番号：' + res.requestNo + '\r\n'
            }
          }
          saveData.push(row)
        }
      }

      // 該当データがある場合
      if (saveData && saveData.length) {  
        const saveR = await backend.postData('patrolSchedule/save', saveData)
        //ログ出力
        if (log) {
          logManager.recordingByRegist(this, true, this.typeName, log)
          await dialogs.showSuccessDialog('保存', '予定と新規張り紙タスクを保存しました。\r\n新規張り紙タスクは以下の通りです。\r\n' + msg )
        } else {
          await dialogs.showSuccessDialog('保存', '保存しました。')
        }
        logManager.recordingByRegist(this, true, this.typeName, '予定（点検予定日：' + this.planDate + '、担当者：' + this.staff.name + '）')

        // 一覧に反映
        await this.getData()
        // 右側をクリア
        this.schedule.list = []
        this.planDate = ''
      } else {
        let name = this.typeName
        if (this.claim) {
          name = 'クレーム対応'
        }
        await dialogs.showErrorDialog(name + 'の予定がありません', 'この画面は' + name + '専用です。' + name + 'の予定を組んでください。')
      }
    },

    /**
     * 予定ボタン非活性判定
     */
    canotEdit(i) {
      if (this.list[i].scheduledDate || this.list[i].disabled) {
        return true
      } else {
        return false
      }
    },

    /**
     * 行の背景色をセット
     * @param row 対象データ
     */
    setBgColor(row) {
      // 解約月がある場合
      if (row.dateCancellation && row.dateCancellation != '') {
        let target = row.dateCancellation.replace(/年|月/g, '-') + '01'
        target = moment(target).format('YYYYMM')
        let today = moment().format('YYYYMM')
        // 解約月が今月より前の場合、グレー
        if (Number(target) < Number(today)) {
          return 'bg-gray-200'
        }
      }
      // 次年度解約フラグがある場合
      if (row.cancelNextFlag && row.cancelNextFlag == '1') {
        return 'bg-yellow-100'
      }
      return ''
    },

    /**
     * タイプによってカラム名を変更
     */
    changeColumn() {
      if (this.type == 2 || this.type == 3) {
        this.columns[4].title = '施工月'
      }
    },

    /**
     * マップ生成
     */
    async initMap(zoom, center) {
      if (!this.isMap) {
        return
      }
      const locations = []

      var addressMap = []

      let idx = 0
      let maCnt = 0
      let centLat = 0
      let centLng = 0

      for await (const row of this.list) {
        var address = row.siteAddress 
        //検索後同物件数カウント
        var count =  this.list.filter(row => row.siteAddress == address).length
        //同物件数
        row.count = count
        
        if(count > 1) addressMap = locations.map(item => item.address)
        
        //同住所非マーカー用条件
        if(!addressMap.includes(row.siteAddress)){
          if (row.latitude && row.longitude) {
            maCnt ++
            centLat += Number(row.latitude)
            centLng += Number(row.longitude)
          
            locations.push(
              {
                name: row.siteName,
                ridge: row.ridgeName,
                address: row.siteAddress,
                count: row.count,
                position: {
                  lat: Number(row.latitude),
                  lng: Number(row.longitude)
                },
                index: idx, 
                row: row,
              }
            )
          }
        }
        idx ++
      }

      if (maCnt > 0) {
        centLat = centLat / maCnt
        centLng = centLng / maCnt
      } else {
       //検索0の場合 
        centLat += 35.624315
        centLng += 139.579387
      }

      // const icons: Record<string, { icon: string }> = {
      //   parking: {
      //     icon: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
      //   },
      //   library: {
      //     icon: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
      //   },
      //   info: {
      //     icon: "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=|0099cc|",
      //   },
      // };

      let zoomVal = 10
      let centVal = { lat: centLat, lng: centLng }

      if (zoom) {
        zoomVal = zoom
      }
      if (center) {
        centVal = center
      }
  
      map = new google.maps.Map(this.$refs.map, {
        zoom: zoomVal,
          center: centVal,
        })
        const infoWindow = new google.maps.InfoWindow({
          content: "",
          disableAutoPan: true,
        })

        // クラスターアイコン画像のデータURL生成
      //   var getGoogleClusterInlineSvg = function (color) {
      //   var encoded = window.btoa(`
      //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320">
      //   <circle cx="150" cy="150" opacity=".75" r="70" fill="${color}" />
      //   <circle cx="150" cy="150" opacity=".55" r="100" fill="${color}" />
      //   <circle cx="150" cy="150" opacity=".35" r="130" fill="${color}" />
      //   <circle cx="150" cy="150" opacity=".15" r="160" fill="${color}" />
      //   </svg>`)
        
      //   return ('data:image/svg+xml;base64,' + encoded)
      // }

      // Renderer型オブジェクト生成（広域表示のクラスター）
      // const renderer = {
      //   render:({ count, position }) => new google.maps.Marker({
      //           label: { text: String('.'), color: "red", fontSize: "10px" },
      //           position,
      //           icon: {
      //           url: getGoogleClusterInlineSvg('#ff2b00'),
      //           scaledSize: new google.maps.Size(40, 40),
      //           },
      //           // adjust zIndex to be above other markers
      //           zIndex: Number(google.maps.Marker.MAX_ZINDEX) + 1,
      //         })
      // }

      const markers = locations.map((pos, i) => {
        let marker = new google.maps.Marker({
          map: map,
          name: pos.name,
          ridge: pos.ridge,
          position: pos.position,
          index: pos.index,
          row: pos.row,
          label: {
            // text: String(pos.count), // 同住所の件数
            text: ' ',
            color: "#ffffff", // ラベルのテキストの色を白に設定
          },
          optimized: false,
          zIndex: 1
        });

        // 予定に組んだ物件
        if (pos.row.disabled) {
          const p = pos.row
          // 予定リストの順番を取得
          for (let i = 0; i < this.schedule.list.length; i++) {
            const l = this.schedule.list[i]
            if (this.claim == l.claim && p.siteCode == l.siteCode && p.limit == l.limit && (!l.ridgeCode || p.ridgeCode == l.ridgeCode)) {
              pos.row.scheduleSortNo = i + 1
              break
            }
          }
          let blueMarker = this.setBlueMarker(pos)
          marker = new google.maps.Marker({
            map: map,
            name: pos.name,
            ridge: pos.ridge,
            position: pos.position,
            index: pos.index,
            row: pos.row,
            icon: blueMarker,
            optimized: false,
            zIndex: 9999
          })
        }

        marker.addListener("click", async () => {
          if (!this.canotEdit(marker.index)) {
            let limitS = '期限日'
            if (this.type == 2 || this.type == 3) {
              limitS = '施工月'
            }
            let limit = `${limitS}：${marker.row.limit}\r\n`
            let name = marker.name
            if (marker.ridge) {
              name = name + ' （' + marker.ridge + '）'
            }
            // 予定に追加
            const dialogResult = await dialogs.showConfirmDialog('予定に追加しますか？', limit + '物件名：' + name)
            if (dialogResult === 'YES') {
              this.edit(marker.row, marker.index, false)

              // マーカーを変更
              delete marker.label
              marker.row.scheduleSortNo = this.schedule.list.length
              marker.row.disabled = true
              marker.optimized = false
              marker.zIndex = 9999

              marker.setIcon(this.setBlueMarker(marker))
            }
          }
        })
        return marker
      })
      // 既存予定がある場合は、地図に既存予定のピンを立てる
      if (this.schedule.list && this.schedule.list.length) {
        for (let shIdx = 0; shIdx < this.schedule.list.length; shIdx++) {
          const lis = this.schedule.list[shIdx]
          if (lis.patrolScheduleId && lis.latitude && lis.longitude) {
            let pos = lis
            pos.row = { scheduleSortNo: (shIdx + 1) }
            const blueMarker = this.setBlueMarker(pos)
            const marker = new google.maps.Marker({
              map: map,
              name: pos.name,
              position: {
                lat: Number(pos.latitude),
                lng: Number(pos.longitude)
              },
              icon: blueMarker,
              optimized: false,
              zIndex: 9999
            })
            markers.push(marker)
          }
        }
      }
      // new MarkerClusterer({ map, markers, renderer })
    },

    /**
     * 予定に組んだピンのマーカーをセット
     * @param pos 対象データ
     */
    setBlueMarker(pos) {
      //巡回設定後の地図マーカーSVGデータ
      let iconUrl = `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="-20 0 40 40">
                      <!-- カスタムアイコンのSVGパス -->
                      <path d="M0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z" fill="blue" fill-opacity="0.8" stroke-width="0"/> 
                      <text x="0%" y="22%" text-anchor="middle" fill="white" font-size="10" dy=".3em">${ pos.row.scheduleSortNo }</text>
                      </svg>`
                    
      // 予定に組んだ物件用のマーカー（ピン）
      return {
        url: iconUrl,
        fillOpacity: 0.7,
        scaledSize: new google.maps.Size(80, 80),
        anchor: new google.maps.Point(40, 40),
      }
    },

    /**
     * 予定に組んだリストの並べ替え
     */
    scheduleOrderChange() {
      // mapのピンの番号を変更するため、地図をリロード
      if (this.isMap) {
        this.initMap(map.getZoom(), map.getCenter())
      }
    },

    /**
     * リフレッシュボタン
     */
    async refresh() {
      this.loading = true
      await utils.wait(500)
      await this.getData()
      await utils.wait(500)
      this.loading = false
    },

    /**
     * RPメインタスク新規登録
     */
    async mainTask(d, dep, limit, staffName) {
      // まだメインタスクが作られていない＆作業初日の予定のみ
      if (!d.requestPostId && (!d.subCount||d.subCount == '0')) {
        let rp = requestPostManager.create()
        rp.type = 'apollo_request_post'
        rp.category2 = ['社内']
        rp.category = ['張り紙']
        // タイトル
        let ridge = ''
        if (d.ridgeCode) {
          ridge = '（' + d.ridgeName + '）'
        }
        rp.inquiry_title = moment(d.scheduledDate).format('YYYY/MM/DD') + '  ' + d.siteName + ridge
        // 担当者
        rp.toStaffName = staffName
        rp.toStaffId = String(d.userId)
        // 依頼者
        rp.byUser = this.$store.getters.user.user_name
        rp.byUserId = d.insertUser
        // 部署
        rp.toDepartment = dep.departmentName
        rp.toDepartmentId = dep.departmentId
        // 依頼日
        rp.requestDate = moment().format('YYYY-MM-DD')
        // 期限日
        rp.limitDate = limit

        // RPメイン登録
        const res = await requestPostManager.register(this, rp)
        if (res && res.id) {
          return res
        } else {
          return null
        }
      }
      return null
    },

    /**
     * 所属部署 取得
     * @param userId 対象ユーザーID
     */
    async getDepartments(userId) {
      let dep = await backend.searchData('user/getAllDataByUser', {day: utils.getToday(), userId})
      if (dep.data.data && dep.data.data.departmentId) {
        return { departmentId: dep.data.data.departmentId, departmentName: dep.data.data.departmentName }
      } else {
        return { departmentId: '', departmentName: 'なし' }
      }
    },

    /**
     * RPメインの期限日判定
     */
    limitDateRP() {
      // 予定日を数値に変換
      const max = Number(this.planDate.replace(/-/g, ''))
      // 本日より2週間後
      const after2week = moment().add(14, 'days').format('YYYY-MM-DD')
      // 数値に変換
      const target = Number(after2week.replace(/-/g, ''))
      // 本日を数値に変換
      const today = Number(moment().format('YYYYMMDD'))

      // 予定日が2週間後より未来の場合、2週間後
      if (max > target) {
        return after2week
      // 予定日が本日の場合、本日
      } else if (max == today) {
        return moment().format('YYYY-MM-DD')
      // それ以外は、予定より1日前
      } else {
        return moment(this.planDate).add(-1, 'days').format('YYYY-MM-DD')
      }
    },

    /**
     * 得意先名リスト取得
     */
    async getClientList() {
      this.clientList = []
      const startDay = moment().format('YYYY-01-01')
      const endDay = moment().format('YYYY-12-31')
      const res = await backend.searchData('akbs/getPatrolClientName1', {startDate: startDay, endDate: endDay, type: this.type})
      if (res.data.data) {
        this.clientList = res.data.data
      }
    },

    /**
     * メイン一覧、サブ進捗一覧へ遷移
     * @param name 遷移先
     */
    pageTransition(name) {
      let pageType  = ''
      switch (this.type) {
        case 2:
          pageType = 'Round'
          break;
        case 3:
          pageType = 'Fixed'
          break;
        case 4:
          pageType = 'Facility'
          break;
      
        default:
          break;
      }
      this.$router.push({ 
        name: pageType + name
      })
    },

    /**
     * スポット登録ボタンイベント
     */
    clickSpot() {
      this.spot = !this.spot
      this.getData()
    }
  },
}
</script>
<style scoped>
.list {
  overflow: auto;
  min-width: 1100px;
}

.bg-colu {
  background: #ebf0fb;
}

.min-150 {
  min-width: 150px;
}

.min-180 {
  min-width: 180px;
}

.min-164 {
  min-width: 164px;
}

.min-120 {
  min-width: 121px;
}

.min-300 {
  min-width: 300px;
}

.w-70p {
  width: 70%;
}

.main-height1 {
  height: 100%;
}

.bar {
  position: absolute;
  top: 50%;
  left: 25%;
}

.hover-color:hover {
  background: rgb(236, 236, 251);
}

@media screen and (max-width: 1240px) {
  .filter-form {
    width: 150px !important;
  }
  .filter-form2 {
    margin-right: 250px !important;
  }
}

#map {
  height: 750px;
}

.no-map {
  background-color: darkgray;
}

.multi {
  overflow: overlay;
  width: 202px;
  height: 43px;
}

.multi2 {
  max-width: 200px;
  position: absolute;
}

.h-43px {
  height: 43px;
}

.field-small {
  max-height: 43px !important;
  overflow: hidden !important;
}

.input-accordion {
  padding: 20px 10px 20px 20px;
}

.input-bottom {
  margin: -20px 0px 50px 0px;
}

#filter-client >>> input[type=text] {
  width: 201px;
  height: 43px;
  border: none;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>