import { render, staticRenderFns } from "./ItemHistorySettingModal.vue?vue&type=template&id=ed6221ac&scoped=true&"
import script from "./ItemHistorySettingModal.vue?vue&type=script&lang=js&"
export * from "./ItemHistorySettingModal.vue?vue&type=script&lang=js&"
import style0 from "./ItemHistorySettingModal.vue?vue&type=style&index=0&id=ed6221ac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed6221ac",
  null
  
)

export default component.exports