<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app overflow-auto">
          <div class="text-lg text-blue-800 font-bold">項目編集</div>

          <div class="mt-5 ml-12 mr-12 mb-12">

            <div class="w-11/12 mr-auto mt-5 flex">
              <div class="text-gray-600 font-bold mr-auto">
                得意先 : {{ list.clientName1 }} {{ list.clientName2 }}
              </div>
            </div>

            <div class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton v-if="list&&list.item" text="デフォルトに戻す" size="normal" class="bg-yellow-500 hover:bg-yellow-600 w-full" @click="defaultCheckList()" />
              <PrimaryButton text="マスタから引用" size="normal" class="bg-green-500 hover:bg-green-600 w-full ml-1 " @click="selectMaster()" />
              <PrimaryButton v-if="checkList&&checkList.length" text="登録" size="normal" class="w-full ml-1" @click="register()" />
            </div>
          </div>

          <PlusCircleIcon class="cursor-pointer text-blue-500 w-8 h-8 ml-auto mr-3 hover:text-blue-600" @click="add()"/>
          <div class="toggle flex items-center mt-8">
            <span class="text-gray-500 font-bold text-xs">並べ替え</span>
            <Toggle v-model="sortable" size="small"/>
          </div>
          <div class="rounded main-height">
            <table class="overflow-auto main table-auto">
              <thead class="main">
                <tr v-if="checkList && checkList.length">
                  <th v-for="(c, index) in columns" :key="'c2'+index" scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-colu">
                    {{c.title}}
                  </th>
                </tr>
              </thead>

              <draggable 
                key="checkList"
                :list="checkList"
                direction="vertical"
                handle=".row-drag-handle"
                tag="tbody"
                class="bg-white"
              >

                <tr v-for="(row, i) in checkList" :key="'r'+i" class="ml-auto mr-auto">

                  <!-- No -->
                  <td class="px-4 py-2 text-blue-700 font-bold col-no">
                    <div v-if="sortable" class="row-drag-handle cursor-pointer col-no">
                      <MenuIcon class="text-gray-400" />
                    </div>
                    <div v-else>
                      {{ i + 1 }}.
                    </div>
                  </td>

                  <!-- 区分 -->
                  <td class="px-4 py-2 whitespace-prerap text-sm min-210">
                    <WmsSuggestInput
                      name="classification"
                      :embed="true"
                      displayField="name"
                      :dynamic="false"
                      :incrementalSearch="false"
                      :selectionItems="classificationList"
                      class="embed border rounded"
                      :class="row.emptyClassification?'border-red-600':''"
                      v-model="row.classification"
                    />
                  </td>
                  <!-- 清掃区分 -->
                  <td v-if="type==3">
                    <select
                      v-model="row.cleanType"
                      class="text-center focus:bg-yellow-100 border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs py-1 h-8"
                    >
                      <option
                        v-for="(ct, cti) in ['定期清掃', '特別清掃']" :key="'ct'+cti"
                        :value="ct"
                        class="bg-white text-base text-left">
                        {{ ct }}
                      </option>
                    </select>
                  </td>
                  <!-- 詳細1 -->
                  <td v-if="detail" class="py-2 whitespace-nowrap text-sm text-gray-700 w-36">
                    <input
                      autocomplete="off"
                      :type="checkListTempId==3?'number':'text'"
                      name="detail1"
                      v-model="row.detail1"
                      class="w-full border border-gray-200 rounded py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </td>
                  <!-- 詳細2 -->
                  <td v-if="detail&&checkListTempId!=3&&checkListTempId!=4" class="py-2 whitespace-nowrap text-sm text-gray-700 w-36">
                    <input
                      autocomplete="off"
                      type="text" 
                      name="detail2"
                      v-model="row.detail2"
                      class="w-full border border-gray-200 rounded py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </td>
                  <!-- 項目 -->
                  <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-700 min-500">
                    <input
                      autocomplete="off"
                      type="text" 
                      name="item"
                      v-model="row.item"
                      class="w-full border border-gray-200 rounded py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      :class="row.emptyItem?'border-red-600':''"
                    />
                  </td>
                  <!-- 削除ボタン -->
                  <td class="px-4 py-0.5 text-center text-sm font-medium border-gray cursor-pointer hover:text-gray-500 active:text-gray-600" @click="deleteRow(i)">
                    <div class="flex justify-center">
                      <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5"/>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
        </div>
      </template>
    </Modal>
    <!-- マスタ選択 -->
    <CheckListMasterModal 
      v-model="openCheckListMasterModal"
      :typeName="typeName"
      :type="type"
      :open="openModal"
      @decidedMaster="decidedMaster"
      @modalClose="modalClose"
    />
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import { TrashIcon, PlusCircleIcon, MenuIcon } from '@vue-hero-icons/solid'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import CheckListMasterModal from '../components/CheckListMasterModal.vue'
import Toggle from '@components/Toggle.vue'
import draggable from "vuedraggable"
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
import * as logManager from '@managers/logManager'

const ckeckListRow = [{id: null, clientCode: '', sortNo: null, classification: '', item: ''}]

export default {
  components: {
    Modal,
    TrashIcon,
    PlusCircleIcon,
    MenuIcon,
    PrimaryButton,
    WmsSuggestInput,
    CheckListMasterModal,
    Toggle,
    draggable
  },

  props: {
    target: {
      type: Object
    },
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    // 区分（1：品質巡回、2：巡回清掃、3：定期清掃、4：設備点検）
    type: {
      type: Number,
      default: null
    },
    open: {
      type: Boolean,
      default: null
    },
  },

  data() {
    return {
      // 編集対象得意先データ
      list: {},
      // チェック項目
      checkList: utils.clone(ckeckListRow),
      // カラム
      columns: [
        {title: 'No.'},
        {title: '区分'},
        {title: 'チェックポイント'},
        {title: ''}
      ],
      // 区分リスト
      classificationList: [
        {name: 'エントランス周り'},
        {name: 'エントランスホール'},
        {name: '共用廊下'},
        {name: '共用階段'},
        {name: 'エレベーター'},
        {name: 'ゴミ置場'},
        {name: '外周'},
        {name: '自転車置場'},
        {name: '駐車場'},
        {name: '日常清掃員'},
        {name: '定期清掃'},
      ],
      // マスタモーダル
      openCheckListMasterModal: false,
      openModal: 0,
      // 並べ替え
      sortable: false,
      // 備考
      memo: '',
      // 詳細項目あり
      detail: false,
      // テンプレートID
      checkListTempId: null
    }
  },

  computed: {
  },

  watch: {
    open() {
      if (this.open) {
        this.setColumn()
        this.getTarget()
      } else {
        return
      }
    },
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      if (this.target && Object.keys(this.target).length) {
        this.list = this.target
        this.checkList = utils.clone(ckeckListRow)
        let result

        // 得意先の点検表既存データ取得
        result = await backend.searchData('checkListByClient/getTempleteByClientCode', {...this.list, type: this.type})
        
        if (result.data == 'Internal Server Error') {
          alert('エラーが発生しました。\r\n詳細はコンソールを確認してください。【checkListByClient/getTempleteByClientCode】')
          return
        } else if (result.data && result.data.data && result.data.data.length) {
          this.checkList = result.data.data
          this.checkListTempId = this.checkList[0].templateId
          this.setColumn()
        }
      }
    },

    /**
     * カラムをセット
     */
    setColumn() {
      if (this.checkList && this.checkList.length && (this.checkList[0].detail1 || this.checkList[0].detail2)) {
        this.detail = true
      } else {
        this.detail = false
      }

      // 定期清掃
      if (this.type == 3) {        
        // 区分リスト
        this.classificationList = utils.setClassification(this.type)
        // カラム
        this.columns = utils.setColumn(this.detail, this.checkListTempId)
      }
    },

    /**
     * 初期化
     */
    closed() {
      // カラム
      this.columns = [
        {title: 'No.'},
        {title: '区分'},
        {title: 'チェックポイント'},
        {title: ''}
      ],
      // 区分リスト
      this.classificationList = [
        {name: 'エントランス周り'},
        {name: 'エントランスホール'},
        {name: '共用廊下'},
        {name: '共用階段'},
        {name: 'エレベーター'},
        {name: 'ゴミ置場'},
        {name: '外周'},
        {name: '自転車置場'},
        {name: '駐車場'},
        {name: '日常清掃員'},
        {name: '定期清掃'},
      ]
      this.list = {}
      // チェック項目
      this.checkList = utils.clone(ckeckListRow)
      // マスタモーダル
      this.openCheckListMasterModal = false
      // 詳細項目あり
      this.detail = false
      
      this.$emit('modalClose')
    },

    /**
     * 登録処理
     */
    async register() {
      // 未入力チェック
      for (let vI = 0; vI < this.checkList.length; vI++) {
        const vC = this.checkList[vI]
        if (vC.classification == '') {
          this.errRequired('区分')
          vC.emptyClassification = true
          return
        } else if (vC.item == '' && this.type != 3) {
          this.errRequired('チェックポイント')
          vC.emptyItem = true
          return
        }
      }
      let msg = '「該当なし」履歴など次回に引き継ぐデータ'
      if ([1, 4].includes(this.type)) {
        msg = '「該当なし」履歴と「項目順番」など次回に引き継ぐデータ'
      }
      const dialogResult = await dialogs.showWarningConfirmDialog(msg + 'がリセットされます', '項目表を更新するので' + msg + 'がリセットされます。\r\nよろしいでしょうか？')
      if (dialogResult != 'YES') {
        return
      }

      // 該当なしのデータも削除
      await backend.deleteDataByKey('checkListHistory/deleteByClientCode', { key: this.list.clientCode, type: this.type, claim: false, siteCode: this.list.siteCode, spot: false }, false)

      // 登録
      let save = []
      for (let i = 0; i < this.checkList.length; i++) {
        const c = this.checkList[i]
        if (c.classification == '' && c.item == '') {
          continue
        }
        c.sortNo = i + 1
        save.push(c)
      }

      let templateId = null
      if (this.detail) {
        templateId = this.checkListTempId
      }

      let siteCode = null
      let siteName = ''
      if (this.type == 4) {
        siteCode = this.list.siteCode
        siteName = '、 物件名：' + this.list.siteName
      }

      await backend.postData('checkListByClient/save', { save, clientCode: this.list.clientCode, updateUser: this.$store.getters.user.id, type: this.type, templateId, siteCode })

      //ログ出力
      if (this.list.clientName2) {
        logManager.recordingByRegist(this, this.isNew, this.typeName, '項目（得意先名：'+ this.list.clientName1 + '、' + this.list.clientName2 + siteName + '）')
      } else {
        logManager.recordingByRegist(this, this.isNew, this.typeName, '項目（得意先名：'+ this.list.clientName1 + siteName + '）')
      }
      
      this.$emit('modalClose')
      this.closed()
    },

    /**
     * デフォルトに戻す
     */
    async defaultCheckList() {
      const delR = await dialogs.showConfirmDialog('デフォルトに戻す', '点検表を更新するので「該当なし」履歴が削除されます。\r\nよろしいでしょうか？')
      if (delR != 'YES') {
        return
      }
      // すでにあるデータを削除
      const deleteR = await backend.deleteDataByKey('checkListByClient/deleteByClient', { key: this.list.clientCode, type: this.type, siteCode: this.list.siteCode }, false)
      if (deleteR.data == 'Internal Server Error') {
        alert('保存中にエラーが発生しました。\r\n詳細はコンソールを確認してください。【checkListByClient/delete】')
        return
      }
      
      // 履歴のデータも削除
      const deleteNA = await backend.deleteDataByKey('checkListHistory/deleteByClientCode', { key: this.list.clientCode, type: this.type, claim: false, siteCode: this.list.siteCode }, false)
      if (deleteNA.data == 'Internal Server Error') {
        alert('保存中にエラーが発生しました。\r\n詳細はコンソールを確認してください。【checkListHistory/deleteByClientCode】')
        return
      }
      
      this.$emit('modalClose')
      this.closed()
    },

    /**
     * 必須項目アラート
     * @param item 項目名
     */
    async errRequired(item) {
      await dialogs.showErrorDialog(item + 'は必須です', item + 'が入力されていないところがあります。')
    },

    /**
     * 点検表マスタ選択ボタンイベント
     */
    selectMaster() {
      this.openCheckListMasterModal = true
      this.openModal++
    },

    /**
     * マスタモーダル閉じる
     */
    modalClose() {
      this.openCheckListMasterModal = false
    },

    /**
     * 追加
     */
    async add() {
      // 巡回清掃の場合
      if (this.type == 2) {
        if (this.checkList.length == 3) {
          await dialogs.showErrorDialog('３行までです', '巡回清掃は3行までの設定です。')
          return
        }
      }
      this.checkList.push({id: null, clientCode: '', sortNo: null, classification: '', item: ''})
    },

    /**
     * 削除
     */
    deleteRow(i) {
      this.checkList.splice(i, 1)
      if (!this.checkList.length) {
        this.checkList = utils.clone(ckeckListRow)
      }
    },

    /**
     * 選択したマスターを反映
     * @param master マスタデータ
     */
    decidedMaster(master) {
      this.openCheckListMasterModal = false
      this.checkList = master.map((m) => {
        delete m.id
        return m
      })
      this.checkListTempId = this.checkList[0].checkListId
      this.setColumn()
    }
  }
}
</script>
<style scoped>
.content-container-app {
  /* width: 930px; */
  max-width: 90vw;
  height: calc(100vh - 130px);
  overflow: auto;
  padding: 10px;
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.max-w-lg {
  max-width: none !important;
}

.col-no {
  width: 70px;
}

.hover-color:hover {
  color: rgb(11, 118, 200);
}

.min-210 {
  min-width: 210px;
}

.min-300 {
  min-width: 300px;
}

.min-500 {
  min-width: 555px;
}


@media screen and (max-width: 910px) {
  .content-container-app {
    width: 900px !important;
    height: calc(100vh - 90px);
    overflow: auto;
  }

}
</style>