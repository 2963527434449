<template>
  <div>
    <PhotoReportListComponent
      menu="evaluation"
      settionStrageName="envReportListStrage"
    >
    </PhotoReportListComponent>
  </div>
</template>
<script>

import PhotoReportListComponent from '../../PhotoReport/components/PhotoReportListComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    PhotoReportListComponent
  },
  
  data() {
    return {
    }
  },

}
</script>