<template>
  <div>
    <PageHeader title="見積リスト" :class="isMobile?'list-mobile':'list'">
      <template #title-header-content>
        <div class="flex-1 flex ml-6 items-center ">
          <label for="search" class="sr-only">Search</label>

          <div class=" w-full relative text-light-blue-100 focus-within:text-gray-400">
            <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
              <Icon iconName="Search" class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
            </div>

            <!-- キーワード検索 -->
            <input
              type="search"
              v-model="searchKeyword"
              class="block w-full bg-light-blue-700 bg-opacity-50 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 placeholder-light-blue-100 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
              placeholder="キーワード検索"
            />
          </div>

          <!-- 期間検索 -->
          <DateSelect
            ref="dateSelect"
            :presetName="periodPresetName"
            :period="period"
            :none="true"
            class="ml-2"
            @change="periodChange"
          />

          <!-- 区分 -->
          <select
            name="route"
            v-model="classification"
            class="rounded text-sm text-gray-800"
          >
            <option
              v-for="(c, i) in classificationList" :key="'class' + i"
              :value="c.id"
              class="bg-white text-sm">
              {{ c.name }}
            </option>
          </select>

          <!-- 検索ボタン -->
          <PrimaryButton
            text="検索"
            @click="search"
            class="ml-2 min-w-100px"
            :buttonContents="'要'"
          />

          <!-- 作成者絞込み -->
          <PrimaryButton
            v-tooltip="'作成者をログインユーザー名で絞込み'"
            :buttonContents="'要'"
            class="ml-2"
            :class="filterUser ? 'clickButtonColor' : 'normalButtonColor'"
            @click="filter('user')"
          >
            <Icon slot="before" iconName="User" iconType="solid" :strokeWidth="2" class="h-5 w-5 " />
          </PrimaryButton>

          <!-- クリアボタン -->
          <div class="ml-2">
            <PrimaryButton text="クリア" class="normalButtonColor" @click="clear" :buttonContents="'要'" style="width: 80px;"/>
          </div>

          <!-- 新規作成 -->
          <router-link
            v-if="estimateAuth"
            :to="{ name: 'EstimateForm', query: { requestPostId: requestPostId, taskRequestId: taskRequestId } }"
            class="ml-2"
          >
            <PrimaryButton text="新規見積作成" class="w-40">
              <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
            </PrimaryButton>
          </router-link>
        </div>

        <!-- リロードボタン -->
        <Icon 
          iconName="Refresh"
          :clickable="false"
          :class="{ 'animate-spin': loading }"
          class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer" @click.native="refresh"
        />
      </template>

      <template #page-header-content>
        <div class="flex items-center">
          <!-- <h1 class="text-lg font-bold text-white flex items-center">
            <Icon iconName="CurrencyYen" class="w-7 h-7 mr-2" /> <span class="mt-0">見積リスト</span>
          </h1> -->
          <div class="flex items-center ml-5">
            <a v-for="tab in tabs" :key="tab.id" class="flex items-center mx-1 cursor-pointer"
              :class="[tab.current ? 'bg-black bg-opacity-25' : 'hover:bg-light-blue-800', 'rounded-md py-2 px-2 text-sm font-medium text-white']"
              @click="tabChanged(tab)">
              {{ tab.name }}
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-300 text-gray-800 ml-2">
                {{ tab.count }}
              </span>
            </a>
          </div>
          <div class="flex flex-1 ml-5 justify-end">
            <div class="relative w-3/6 focus-within:text-gray-400 search-contents">
                <input
                  v-tooltip="'記号・スペースは無視します'"
                  type="search"
                  name="filterClient" 
                  placeholder="得意先名"
                  class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm" 
                  v-model="clientName"
                />
            </div>
            <div class="relative w-3/6 focus-within:text-gray-400 search-contents">
                <input
                  v-tooltip="'記号・スペースは無視します'"
                  type="search"
                  name="filterClient" 
                  placeholder="建物名"
                  class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm" 
                  v-model="buildingName"
                />
            </div>
            <div class="relative w-3/6 focus-within:text-gray-400 search-contents">
              <input 
                type="search"
                v-model="subject" 
                class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="お問い合わせ内容"
              />
            </div>
            <div class="relative w-3/6 focus-within:text-gray-400 search-contents">
              <input 
                type="search"
                v-model="categoryName" 
                class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="見積りカテゴリー名"
              />
            </div>
          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-32 w-screen bottom-4 -mt-2" :class="isMobile?'list-mobile':'list'">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 ---overflow-auto h-full">

          <EstimateList
            v-show="!loading && dataLength > 0"
            ref="estimateList"
            :searchKeyword="searchKeyword"
            :filterStatus="filterStatus"
            :filterClient="clientName"
            :filterBuliding="buildingName"
            :filterSubject="subject"
            :filterCategoryName="categoryName"
            :period="period"
            :classification="classification"
            :freeze="freeze"
            :taskRequestId="taskRequestId"
            :filterUser="filterUser"
            @search-end="searchEnd"
          />

          <div v-if="loading" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
          </div>

          <div v-else-if="!loading && !initial && overLimit"
            class="w-full h-full flex justify-center items-center text-gray-400">
            <EmptyMessage iconName="ExclamationCircle" message="対象のデータが多いため、絞り込みをして再検索してください。" />
          </div>

          <div v-else-if="!loading && !initial && dataLength === 0"
            class="w-full h-full flex justify-center items-center text-gray-400">
            <EmptyMessage iconName="Document" message="該当する見積りはありません。" />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import EstimateList from '../components/EstimateListTable.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import DateSelect from '@components/DateSelect.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import * as constants from '@libs/constants'
import * as utils from '@libs/utils'
import PageModel from '../../main/pages/PageModel.vue'
import * as constantsEstimate from '@libs/constantsEstimate'

const sessionStorageItemNameReset = 'estimateListConditionReset'
const sessionStorageItemName = 'estimateListCondition'

export default {
  extends: PageModel,
  components: {
    Icon,
    PrimaryButton,
    EstimateList,
    DateSelect,
    PageHeader,
    EmptyMessage
  },

  props: {
    requestPostId: {
      type: String,
      default: null
    },
    taskRequestId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      searchKeyword: '',
      period: null,
      periodPresetName: '6_month',
      tabs: [],
      clientName:'',
      buildingName:'',
      subject:'',
      categoryName: '',
      searchResults: [],
      searchIncomplete: [],
      loading: false,
      initial: true,
      freeze: true,
      user: {},
      isApprover: false,
      filterUser: false,
      estimateAuth: false,
      // dataLength: 0
      // データが多すぎて表示不可
      overData: false,
      incompleteOverData: false,
      // 区分リスト
      classificationList: [{id: null, name: '全ての区分'}].concat(utils.clone(constantsEstimate.type)),
      // 区分絞り込み
      classification: null
    }
  },

  computed: {
    isMobile() {
      return utils.deviceInfo.isMobile
    },

    appVersion() {
      return this.$store.getters.appVersion
    },

    filterStatus() {
      const activeTab = this.tabs.find((t) => {
        return t.current
      })
      if (activeTab) return activeTab.name
      return null
    },

    dataLength() {
      let result = this.searchResults.length
      if (this.filterStatus == 'メインタスク未完了') {
        result = this.numIncomplete
      } else if (this.filterStatus == '作成中') {
        result = this.numInProgress
      } else if (this.filterStatus == '承認申請中') {
        result = this.numAwaitingApproval
      } else if (this.filterStatus == '差戻') {
        result = this.numRejectionApproval
      } else if (this.filterStatus == '受注') {
        result = this.numOrdered
      } else if (this.filterStatus == '担当の承認待ち') {
        result = this.numMyApproval
      }
      return result
    },

    activeFilterTabId() {
      const activeTab = this.tabs.find((t) => {
        return t.current
      })
      return activeTab.id
    },

    numIncomplete() {
      return this.searchIncomplete.length
    },

    numAll() {
      return this.searchResults.length
    },

    numInProgress() {
      return this.searchResults.filter((estimate) => {
        return estimate.estimateStatus === '作成中'
      }).length
    },

    numAwaitingApproval() {
      return this.searchResults.filter((estimate) => {
        return estimate.estimateStatus === '承認申請中'
      }).length
    },

    numRejectionApproval() {
      return this.searchResults.filter((estimate) => {
        return estimate.estimateStatus === '差戻'
      }).length
    },

    numOrdered() {
      return this.searchResults.filter((estimate) => {
        return estimate.estimateStatus === '受注'
      }).length
    },

    numMyApproval() {
      return this.searchResults.filter((estimate) => {
        return estimate.approveAuth
      }).length
    },

    /**
     * 件数が多くて表示できないとき
     */
    overLimit() {
      if (this.filterStatus == 'メインタスク未完了') {
        return this.incompleteOverData
      } else {
        return this.overData
      }
    }
  },

  watch: {
  },

  created() {
    this.freeze = true
    this.loadState()
    this.estimateAuth = this.$store.getters.estimateAuth
    this.fetchEstimate()
    this.freeze = false
  },

  mounted() {
  },

  methods: {
    /**
     * 検索
     */
    async search(){
      this.fetchEstimate()
    },

    /**
     * セッションストレージに保存
     */
    saveState() {
      // メニュー画面に戻ったらリセットされるもの
      const newStateReset = {}
      newStateReset.searchKeyword = this.searchKeyword
      newStateReset.periodPresetName = this.periodPresetName
      newStateReset.period = this.period ? { start: this.period.start, end: this.period.end } : null
      newStateReset.classification = this.classification || null
      newStateReset.clientName = this.clientName
      newStateReset.buildingName = this.buildingName
      newStateReset.subject = this.subject
      newStateReset.categoryName = this.categoryName
      const searchStrReset = JSON.stringify(newStateReset)
      sessionStorage.setItem(sessionStorageItemNameReset, searchStrReset)

      // メニュー画面に戻ってもリセットされないもの
      const newState = {}
      newState.tabs = this.tabs
      newState.filterUser = this.filterUser
      const searchStr = JSON.stringify(newState)
      sessionStorage.setItem(sessionStorageItemName, searchStr)
    },

    /**
     * セッションストレージを取得
     */
    loadState() {
      // メニュー画面に戻ったらリセットされる
      const searchJsonReset = sessionStorage.getItem(sessionStorageItemNameReset)
      let searchConditionReset
      if (searchJsonReset) searchConditionReset = JSON.parse(searchJsonReset)
      if (searchConditionReset) {
        // 前回の検索条件をロード
        this.searchKeyword = searchConditionReset.searchKeyword
        this.periodPresetName = searchConditionReset.periodPresetName
        this.period = searchConditionReset.period ? { start: searchConditionReset.period.start, end: searchConditionReset.period.end } : null
        this.classification = searchConditionReset.classification || null
        this.clientName = searchConditionReset.clientName
        this.buildingName = searchConditionReset.buildingName
        this.subject = searchConditionReset.subject
        this.categoryName = searchConditionReset.categoryName
      }

      // メニュー画面に戻ってもリセットされない
      const searchJson = sessionStorage.getItem(sessionStorageItemName)
      let searchCondition
      if (searchJson) searchCondition = JSON.parse(searchJson)
      if (searchCondition) {
        // 前回の検索条件をロード
        this.tabs = searchCondition.tabs
        this.filterUser = searchCondition.filterUser
      }

      // どのタブを活性にするか判断
      if (this.$route.query.tabId || !searchCondition) {
        // 「constants.js」からタグのリストを取得
        let statusList = []
        statusList = constants.ESTIMATE_STATUS

        // パラメーターがなければ、「全て」タグを活性
        let param = this.$route.query.tabId ? this.$route.query.tabId : 'all'

        // メニュー画面の「承認待ち一覧」から遷移した場合は、「担当の承認待ち」タグ活性
        if (param == 'awaitingApproval') {
          param = 'myApproval'

          // メニュー画面の「メインタスク未完了一覧」から遷移した場合は、「メインタスク未完了」タグ活性
        } else if (param == 'incomplete') {
          param = 'incomplete'
          this.filterUser = true
        }

        // カウント数、選択状態のフィールドを追加
        this.tabs = statusList.map((status) => {
          return { ...status, count: 0, current: status.id === param }
        })
      }
    },

    async refresh(){
      this.loading = true
      await utils.wait(500)
      await this.$refs.estimateList.fetchEstimates()
      await utils.wait(500)
      this.loading = false
    },

    //見積データ取得
    async fetchEstimate() {
      this.loading = true
      await utils.wait(500)
      await this.$refs.estimateList.fetchEstimates()
      await utils.wait(500)
      this.loading = false
    },

    searchEnd(data, incompleteData, isApprover, overData, incompleteOverData) {
      this.isApprover = isApprover

      this.saveState()

      const self = this
      self.initial = false
      self.loading = false
      self.searchResults = data
      self.searchIncomplete = incompleteData
      self.overData = overData
      self.incompleteOverData = incompleteOverData
      self.tabs.forEach((tab) => {
        if (tab.id === 'incomplete') {
          tab.count = self.numIncomplete
        } else if (tab.id === 'all') {
          tab.count = self.numAll
        } else if (tab.id === 'inprogress') {
          tab.count = self.numInProgress
        } else if (tab.id === 'awaitingApproval') {
          tab.count = self.numAwaitingApproval
        } else if (tab.id === 'rejectionApproval') {
          tab.count = self.numRejectionApproval
        } else if (tab.id === 'accepted') {
          tab.count = self.numAccepted
        } else if (tab.id === 'ordered') {
          tab.count = self.numOrdered
        } else if (tab.id === 'myApproval') {
          tab.count = self.numMyApproval
        }

      })
    },

    tabChanged(tab) {
      this.tabs.forEach((t) => {
        t.current = false
      })
      tab.current = true
      this.fetchEstimate()

    },

    searchKeywordChanged(keyword) {
      this.searchKeyword = keyword
    },

    periodChange(range, presetName) {
      this.period = range
      this.periodPresetName = presetName
    },

    newEstimate() {
      this.$router.push({ name: 'EstimateForm' })
    },

      /**
      * 検索期間を初期値にリセット
      */
      periodReset() {
        this.period = {
          start: new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0]
        }
        this.periodPresetName = 'custom'
        this.$refs.dateSelect.setRange(this.period, this.periodPresetName)
      },

    /**
     * 絞込み
     * @param target 絞込み対象
     */
      filter(target) {
      if (target == 'user') {
        this.filterUser = !this.filterUser
      }
    },
    
    // 検索条件をクリア
    clear() {
      window.sessionStorage.clear()
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },
  },
}
</script>
<style scoped>
.list {
  min-width: 1450px;
}

.list-mobile {
  min-width: 1800px;
}
</style>