<template>
  <div>
    <FacilityCheckListTableComponent
      typeName='設備点検'
      :type=4
      :routerName="routerName"
      :siteLocation="siteLocation"
    >
    </FacilityCheckListTableComponent>
  </div>
</template>

<script>
import FacilityCheckListTableComponent from '../components/FacilityCheckListTableComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    FacilityCheckListTableComponent,
  },

  props: {
    // 報告書を閉じた後の遷移画面
    routerName: {
      type: String,
      default: 'FacilityScheduleListByStaff'
    },
    // 位置情報
    siteLocation: {
      type: Object
    },
  },
}
</script>