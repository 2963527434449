import axiosBase from 'axios';
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'

export class Pigeon {

  constructor(baseUrl, router, store) {
    this.axios = axiosBase.create()
    this.axiosSub = axiosBase.create()
    this.pigeonBaseUrl = baseUrl
    this.router = router
    this.store = store
    this.token = null
    this.user = null

    this.axios.interceptors.request.use((config) => {
      if (this.token) {
        config.headers = { 'x-access-token': this.token }
      } else {
        // サインイン以外であれば、アプリトークンを利用
        if (config.url.indexOf('/authenticate') == -1 ) {
          console.log("サービスアカウント利用：", process.env.VUE_APP_PIGEON_SERVICE_ACCOUNT_APP_NAME);
          config.headers = {
            "x-tms-name": process.env.VUE_APP_PIGEON_SERVICE_ACCOUNT_NAME,
            "x-tms-app-id": process.env.VUE_APP_PIGEON_SERVICE_ACCOUNT_APP_ID,
            "x-tms-app-name": process.env.VUE_APP_PIGEON_SERVICE_ACCOUNT_APP_NAME,
            "x-tms-app-token": process.env.VUE_APP_PIGEON_SERVICE_ACCOUNT_APP_TOKEN,
            "x-tms-company-id": process.env.VUE_APP_PIGEON_SERVICE_ACCOUNT_COMPANY_ID
          }
        }
      }
      return config
    })

    this.axios.interceptors.response.use((response) => {
      return response;
    }, async (error) => {
      if (error.response.status == 401) {
        const retryConfig = error.response.config
        if (this.store) {
          const userJson = utils.getPigeonAuthInfo()
          if (userJson) {
            const user = JSON.parse(userJson)
            const loginInfo = { 
              email: user.e_mail_adress,
              password: user.user_password
            }
            const res = await this.axiosSub.post(this.pigeonBaseUrl + `/authenticate`, loginInfo)
            this.token = res.data.user.token
            this.user = res.data.user
            this.store.dispatch('setUser', res.data.user)
            utils.setPigeonAuthInfo(res.data.user)

            return this.axios.request(retryConfig)
          }
        }
        if (this.router) {
          console.log(this.router.currentRoute.path)
          if (!this.router.currentRoute.path.includes('signin')) {
            this.router.push('./signin')
          }
        }
      }
      return Promise.reject(error)
    })
  }

  async signin(email, password) {
    const loginInfo = {
      email,
      password
    }
    const res = await this.axios.post(this.pigeonBaseUrl + `/authenticate`, loginInfo)
    this.token = res.data.user.token
    this.user = res.data.user
    utils.setPigeonAuthInfo(res.data.user)
    // 権限
    const authData = await backend.searchData('auth/getByUser', this.user)
    // ログインユーザーに関するデータ
    const loginUserData = await backend.searchData('user/getAllDataByUser', {day: utils.getToday(), userId: this.user.id})
    
    // メインタスク権限
    let main = false
    // サブタスク権限
    let sub = false
    // 見積り権限
    let estimate = false
    if (authData.data.data) {
      if (authData.data.data.mainAuth) {
        main = true
      }
      if (authData.data.data.subAuth) {
        sub = true
      }
      if (authData.data.data.estimateAuth) {
        estimate = true
      }
    }
    if (this.store) {
      this.store.dispatch('setUser', res.data.user)
      this.store.dispatch('setAuth', authData.data.data)
      this.store.dispatch('setMainAuth', main)
      this.store.dispatch('setSubAuth', sub)
      this.store.dispatch('setEstimateAuth', estimate)
      this.store.dispatch('setLoginUserData', loginUserData.data.data)
      this.store.dispatch('setDualPosition', loginUserData.data.dualPosition)
    }
    return res.data
  }

  signout() {
    this.token = null
    this.user = null
    utils.removePigeonAuthInfo()
    if (this.store) {
      this.store.dispatch('setUser', null)
    }
  }

  async getUserList() {
    const res = await this.axios.get(this.pigeonBaseUrl + `/users`)
    return res.data
  }

  async getFormList() {
    const res = await this.axios.get(this.pigeonBaseUrl + `/forms`)
    return res.data
  }

  async getForm(formId) {
    const res = await this.axios.get(this.pigeonBaseUrl + `/forms/${formId}`)
    return res.data
  }

  async updateForm(form) {
    delete form._id
    const url = `${this.pigeonBaseUrl}/forms`
    const res = await this.axios.put(url, form)
    return res.data
  }

  async updateFormTags(formId, tags) {
    const payload = {
      formId,
      tags
    }
    const url = `${this.pigeonBaseUrl}/forms`
    const res = await this.axios.put(url, payload)
    return res.data
  }

  async getTask(taskId) {
    const res = await this.axios.get(this.pigeonBaseUrl + `/tasks/${taskId}`)
    return res.data
  }

  // async searchTasks(searchCondition) {
  //   try {
  //     const res = await this.axios.post(
  //       this.pigeonBaseUrl + `/tasks/simplesearch`,
  //       searchCondition
  //     )
  //     console.log(res)
  //     return res.data
  //   } catch (error) {
  //     console.log(error)
  //     return error
  //   }
  // }
  
  async searchTasks(searchCondition) {
    let err
    const res = await this.axios.post(
      this.pigeonBaseUrl + `/tasks/simplesearch`,
      searchCondition
    ).catch(function (error) {
      if (error.response) {
        // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました
        console.log(error.response.data.message)
        err = { error: true, msg: error.response.data.message }
      }
    })
    if (err) {
      return err
    } else {
      return res.data
    }
  }

  async searchGeneral(collectionName, searchCondition) {
    const res = await this.axios.post(
      this.pigeonBaseUrl + `/general/search/${collectionName}`,
      searchCondition
    )
    return res.data
  }

  async aggregateTasks(pipeline) {
    let err
    const res = await this.axios.post(
      this.pigeonBaseUrl + `/tasks/aggregate`,
      pipeline
    ).catch(function (error) {
      if (error.response) {
        // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました
        console.log(error.response.data.message)
        err = { error: true, msg: error.response.data.message }
      }
    })
    if (err) {
      return err
    } else {
      return res.data
    }
  }
  
  async getTasksByFormId(formId, sort) {
    const searchCondition = {
      searchCondition: {
        type: formId,
        status: { $ne: "remove" }
      }
    }
    if (sort) {
      searchCondition.sort = sort
    }
    return await this.searchTasks(searchCondition)
  }

  async registerTask(formId, taskData, queryParams) {
    taskData.type = formId
    taskData.user = this.createUserInfo()

    const queryString = this.createQueryString(queryParams)
    const url = `${this.pigeonBaseUrl}/tasks${queryString}`
    const res = await this.axios.post(url, taskData)
    return res.data
  }

  async updateTask(taskId, taskData, queryParams) {
    taskData.user = this.createUserInfo()
    const queryString = this.createQueryString(queryParams)
    const _id = taskData._id
    delete taskData._id
    const url = `${this.pigeonBaseUrl}/tasks/${taskId}${queryString}`
    try {
      const res = await this.axios.put(url, taskData)
      return res.data
    } finally {
      taskData._id = _id
    }
  }

  /**
   * 特定の条件で更新
   * @param {*} filter 更新条件（whereの内容）
   * @param {*} update 更新する内容（$setの内容）
   * @param {*} option 指定なければ、upsertとなる
   * @returns 
   */
  async updateOne(filter, update, option) {
    const url = `${this.pigeonBaseUrl}/tasks/updateOne`
    const res = await this.axios.put(url, { filter: filter, update: update, option: option })
    return res.data
  }

  async deleteTask(taskId) {
    const res = await this.axios.delete(this.pigeonBaseUrl + `/tasks/${taskId}`)
    return res.data
  }

  async getChatWorkRoomMembers(cwApiToken, cwRoomId) {
    const res = await this.axios.get(
      this.pigeonBaseUrl + `/chatwork/memberList/${cwRoomId}/${cwApiToken}`
    )
    return res.data
  }

  async generalSearch(collectionName, serchCondition) {
    const res = await this.axios.post(
      this.pigeonBaseUrl + `/general/search/${collectionName}`,
      serchCondition
    )
    return res.data
  }

  async generalSearchEx(collectionName, searchCondition, collation, sort) {
    const res = await this.axios.post(
      this.pigeonBaseUrl + `/general/search-ex/${collectionName}`,
      {
        searchCondition,
        collation,
        sort
      }
    )
    return res.data
  }

  async generalUpdate(collectionName, updateCondition, updateData) {
    const updateConditionIsEmpty = !updateCondition || Object.keys(updateCondition).length === 0
    if (updateConditionIsEmpty) {
      throw new Error('無条件での更新はできません。');
    } else {
      const res = await this.axios.put(
        this.pigeonBaseUrl + `/general/${collectionName}`,
        {
          updateData,
          updateCondition
        }
      )
      return res.data
    }
  }

  async generalDelete(collectionName, deleteCondition) {
    const q = utils.toQueryString(deleteCondition)
    if (!q) {
      throw new Error('無条件での削除はできません。');
    } else {
      const res = await this.axios.delete(
        this.pigeonBaseUrl + `/general/${collectionName}?${q}`
      )
      return res.data
    }
  }

  async getAutomaticNumber(formId, fieldName, settingId, registerData) {
    const res = await this.axios.post(
      this.pigeonBaseUrl +
        `/automaticNumbering/?formId=${formId}&fieldName=${fieldName}&settingId=${settingId}`,
      { ...registerData, type: formId }
    )
    return res.data
  }

  async confirmTask(taskId) {
    const res = await this.axios.post(
      this.pigeonBaseUrl + `/tasks/${taskId}/confirm`
    )
    return res.data
  }

  async unconfirmTask(taskId) {
    const res = await this.axios.post(
      this.pigeonBaseUrl + `/tasks/${taskId}/unconfirm`
    )
    return res.data
  }

  async completeTask(taskId) {
    const res = await this.axios.post(
      this.pigeonBaseUrl + `/tasks/${taskId}/complete`
    )
    return res.data
  }

  async incompleteTask(taskId) {
    const res = await this.axios.post(
      this.pigeonBaseUrl + `/tasks/${taskId}/incomplete`
    )
    return res.data
  }

  async searchHistories(formId, searchKey, searchValue, matchType) {
    const searchCondition = {
      searchCondition: {
        type: formId,
        status: { $ne: 'remove' }
      },
      sort: {
        createDate: -1
      }
    }

    if (matchType) {
      if (matchType === 'partial') {
        searchValue = { $regex: searchValue }
      } else if (matchType === 'prefix') {
        searchValue = { $regex: '^' + searchValue }
      } else if (matchType === 'backward') {
        searchValue = { $regex: searchValue + '$' }
      }
    }

    searchCondition.searchCondition[searchKey] = searchValue

    const histories = await this.getTasks(searchCondition)
    return histories
  }

  async csvExport(exportCondition) {
    try {
      const csvData = await this.axios.post(
        this.pigeonBaseUrl + `/csvExport`,
        exportCondition,
        {
          responseType: 'blob'
        }
      )
      return { error: null, data: csvData.data }
    } catch (e) {
      return { error: e, data: null }
    }
  }

  async csvImport(formId, csvFile) {
    const formData = new FormData()
    formData.append('file', csvFile)
    const res = await this.axios.post(
      this.pigeonBaseUrl + `/tasks/csvImport/${formId}`,
      formData
    )
    return res.data
  }

  async csvImportProgress(formId) {
    const res = await this.axios.get(
      this.pigeonBaseUrl + `/tasks/csvImport/${formId}`
    )
    return res.data
  }

  async getUserPreferences(userId) {
    try {
      const userPreferences = await this.axios.get(
        this.pigeonBaseUrl + `/userPreferences/${userId}`
      )
      return { error: null, data: userPreferences.data }
    } catch (e) {
      return { error: e, data: null }
    }
  }

  async upDateUserPreferences(userId, updateData) {
    try {
      const result = await this.axios.put(
        this.pigeonBaseUrl + `/userPreferences/${userId}`,
        updateData
      )
      return { error: null, data: result }
    } catch (e) {
      return { error: e, data: null }
    }
  }

  async getTenantPreferences() {
    try {
      const tenantPreferences = await this.axios.get(
        this.pigeonBaseUrl + `/tenantPreferences`
      )
      return { error: null, data: tenantPreferences.data }
    } catch (e) {
      return { error: e, data: null }
    }
  }

  async upDateTenantPreferences(updateData) {
    try {
      const result = await this.axios.put(
        this.pigeonBaseUrl + `/tenantPreferences`,
        updateData
      )
      return { error: null, data: result }
    } catch (e) {
      return { error: e, data: null }
    }
  }

  async hello() {
    try {
      await this.axios.get(this.pigeonBaseUrl + `/hello`)
      return true
    } catch (e) {
      console.log(e)
      this.context.store.$auth.logout()
      this.context.redirect('/menu')
      return false
    }
  }

  createUserInfo() {
    if (this.user) {
      const userInfo = {
        id: this.user.id,
        login_id: this.user.login_id,
        company_id: this.user.company_id,
        user_name: this.user.user_name
      }
      return userInfo
    } else {
      return null
    }
  }

  createQueryString(params) {
    if (!params) {
      return ''
    }

    const queryString =
      '?' +
      Object.entries(params)
        .map((e) => `${e[0]}=${e[1]}`)
        .join('&')

    return queryString
  }
}
