<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div class="overflow-hidden rounded main-height">
      <div class="main-height overflow-auto">
        <div class="font-bold title mb-3">
          「共有埋め込み」の取得方法
        </div>
        <div class="text-sm">
          Youtubeの「共有」から「動画の埋め込み」をコピーして張り付けてください。<br>
          勉強会画面で動画が再生できるようになります。
        </div>
        <div>
          <img src="images/movieInfo.png" class="">
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'

export default {
  components: {
    Modal,
  },

  data() {
    return {
    }
  },
  methods: {
    closed() {
      this.$emit('closed')
    }
  }
}
</script>
<style scoped>
</style>