<template>
  <div>
    <div v-if="downloading" class="flex justify-center items-center loading-dialog3">
      <p class="text-gray-600 font-bold animate-pulse" style="font-size: 30px">Downloading...</p>
    </div>
    <PageHeader :title="isMobileDevice || isTabletDevice ? '':'写真報告書 一覧'" class="main-form">
      <template #title-header-content>
        <div class="flex-1 flex justify-end items-center">

          <!-- 切替 -->
          <div class="bg-white rounded mr-5">
            <div class="flex items-center py-1 rounded mx-5 ">
              <div v-for="tab in typeList" 
                :key="tab.id" 
                class="w-28 text-center mx-1 cursor-pointer"
                :class="[type.includes(tab.id) ? 'bg-light-blue-700' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-1 px-3 text-sm font-medium text-white']" 
                @click="typeChanged(tab)"
              >
                {{ tab.omit }}
              </div>
            </div>
          </div>

          <PrimaryButton text="☑ 一括ダウンロード" @click="download(null, 'check')" :buttonContents="'要'" class="mr-3 bg-blue-600">
            <Icon v-if="!downloading" slot="after" iconName="Download" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
            <LoadingIcon v-else slot="after" class="h-4 w-4 my-auto ml-2 text-white" />
          </PrimaryButton>

          <!-- 検索ボタン -->
          <PrimaryButton
            text="検索"
            class="button-contents"
            @click="getData()"
          >
          </PrimaryButton>

          <!-- 更新者絞込み -->
          <PrimaryButton
            v-tooltip="'更新者をログインユーザー名で絞込み'"
            :text="isMobileDevice||isTabletDevice?'更新者が自分':''"
            :buttonContents="'要'"
            class="ml-2"
            :class="[filter.filterUpdateUser ? 'clickButtonColor2' : 'normalButtonColor', isMobileDevice||isTabletDevice?'px-1':'']"
            @click="filter.filterUpdateUser=!filter.filterUpdateUser"
          >
            <Icon v-if="!isMobileDevice&&!isTabletDevice" slot="before" iconName="User" iconType="solid" :strokeWidth="2" class="h-5 w-5 " />
          </PrimaryButton>

          <!-- 新規作成 -->
          <PrimaryButton class="bg-green-600 ml-5" text="新規作成" @click="photoReportModalShow=true">
            <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>

          <!-- リロードアイコン -->
          <Icon
            iconName="Refresh"
            :clickable="false"
            :class="{ 'animate-spin': loading || downloading }"
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer" @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="flex justify-end">
          <div class="flex items-stretch">

            <div class="ml-2 mr-2">
              <input
                type="search"
                name="filterNo" 
                placeholder="報告書No 検索"
                class="px-1 py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-36" 
                v-model="filter.id"
              /> 
            </div>

            <div class="mr-2 mt-auto">
              <input
                type="search"
                name="filterTitle" 
                placeholder="タイトル 検索"
                class="px-1 py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-36" 
                v-model="filter.title"
              /> 
            </div>

            <div v-if="type.includes(1)||type.includes(2)" class="mr-2 mt-auto">
              <input
                type="search"
                name="filterSite" 
                placeholder="得意先or物件名 検索"
                class="px-1 py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-36" 
                v-model="filter.clientAndSite"
              /> 
            </div>

            <div v-if="type.includes(3)||type.includes(4)" class="mr-2 mt-auto">
              <input
                type="search"
                name="filterOffice" 
                placeholder="支店名or部署名 検索"
                class="px-1 py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-36" 
                v-model="filter.office"
              /> 
            </div>

            <div v-if="type.includes(3)||type.includes(4)" class="mr-2 mt-auto">
              <input
                type="search"
                name="filterImplementation" 
                placeholder="点検月 検索"
                class="px-1 py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-36" 
                v-model="filter.implementation"
              /> 
            </div>

            <div class="mr-2 mt-auto">
              <input
                type="search"
                name="filterUser" 
                placeholder="作成者or更新者 検索"
                class="px-1 py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-36" 
                v-model="filter.user"
              /> 
            </div>

            <div class="mr-2 mt-auto">
              <DateSelect
                id="filterDate"
                v-if="true"
                ref="dateSelect"
                :presetName="filter.periodPresetName"
                :period="filter.period"
                :none="true"
                @change="periodChange"
                titleText="更新日："
              />
            </div>
          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-28 bottom-4 leading-8 w-full main-form mx-auto">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full w-full">
        <div v-if="list.length" class="rounded-md border border-gray-200 table-h overflow-auto bg-white">
          <table class="table text-sm">
            <thead class="th sticky top-0 z-20 bg-colu">
              <tr class="text-gray-500 text-xs border border-gray-200 bg-colu">
                <th class="text-left p-2 bg-colu">報告書No.</th>
                <th class="text-left p-2 bg-colu">タイトル</th>
                <th v-if="type.includes(1)||type.includes(2)" class="text-left p-2 bg-colu">得意先名<br>物件名</th>
                <th v-if="type.includes(3)||type.includes(4)" class="text-left p-2 bg-colu">支店名<br>部署名</th>
                <th v-if="type.includes(3)||type.includes(4)" class="text-left p-2 bg-colu">点検月</th>
                <th class="text-left p-2 bg-colu">作成者<br>更新者</th>
                <th class="text-left p-2 bg-colu">作成日<br>更新日</th>
                <th class="text-center p-2 bg-colu w-24">ステータス</th>
                <th class="text-center p-2 bg-colu w-24">種別</th>
                <th class="text-center p-2 bg-colu w-24">ダウンロード</th>
                <th class="text-center rounded bg-red-200 cursor-pointer w-16" @click="allChecked()">選択</th>
              </tr>
            </thead>
            <tbody v-for="(row, i) in list" :key="i">
              <tr class="hover-color border border-gray-200 bg-white">
                <!-- 報告書No -->
                <td class="td2 cursor-pointer" @click="toPhotoReport(row)">No. {{ row.id }}</td>

                <!-- タイトル -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer" @click="toPhotoReport(row)">
                  {{ row.title }}
                </td>

                <!-- 得意先・物件名 -->
                <td v-if="type.includes(1)||type.includes(2)" class="whitespace-prerap cursor-pointer text-gray-700 min-w-fit p-2 text-xs" @click="toPhotoReport(row)">
                  <div>
                    {{ row.clientName }}
                  </div>
                  <div class="mt-1">
                    {{ row.siteName }}
                  </div>
                </td>

                <!-- 支店名・部署名 -->
                <td v-if="type.includes(3)||type.includes(4)" class="whitespace-prerap text-gray-700 min-w-fit p-2 text-xs cursor-pointer" @click="toPhotoReport(row)">
                  <div>
                    {{ row.officeName }}
                  </div>
                  <div class="mt-1">
                    {{ row.departmentName }}
                  </div>
                </td>

                <!-- 点検年月 -->
                <td v-if="type.includes(3)||type.includes(4)" class="whitespace-prerap text-gray-700 min-w-fit p-2 text-sm cursor-pointer" @click="toPhotoReport(row)">
                  {{ row.implementation }}
                </td>

                <!-- 作成者・更新者 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer" @click="toPhotoReport(row)">
                  <div>
                    {{ row.insertUserName }}
                  </div>
                  <div>
                    {{ row.updateUserName }}
                  </div>
                </td>

                <!-- 作成日・更新日 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 text-left cursor-pointer" @click="toPhotoReport(row)">
                  <div>
                    {{ row.insert }}
                  </div>
                  <div>
                    {{ row.update }}
                  </div>
                </td>

                <!-- ステータス -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 text-center cursor-pointer" @click="toPhotoReport(row)">
                  <div>
                    <Badge :color="statusColor[row.status]">{{ row.status }}</Badge>
                  </div>
                </td>

                <!-- 種別 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 text-center cursor-pointer" @click="toPhotoReport(row)">
                  <div>
                    {{ row.typeName }}
                  </div>
                </td>

                <!-- ダウンロード -->
                <td class="text-center w-24">
                  <PrimaryButton v-tooltip="'ダウンロード'" @click="download(row, null)" :buttonContents="'要'">
                    <Icon v-if="!downloading" slot="before" iconName="Download" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                    <LoadingIcon v-else slot="before" :strokeWidth="2" class="h-5 w-5 m-auto text-white" />
                  </PrimaryButton>
                </td>

                <!-- ダウンロード（選択） -->
                <td class="text-center w-16">
                  <label :for="row.id" class="p-4 cursor-pointer">
                    <input type="checkbox" class="p-2" :id="row.id" :value="row.id" v-model="checkedId">
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="!loading && !list.length" class="w-full h-full flex justify-center items-center text-gray-400">
          <EmptyMessage
            iconName="Document"
            :message="!type.length?'資料の種別を選択してください。':'該当するデータはありません。'"
          />
        </div>
      </div>
    </main>
    <!-- 写真報告テンプレート -->
    <TemplateModal 
      v-model="photoReportModalShow"
      :photoReportModalShow="photoReportModalShow"
      :menu="menu"
      @close="closePhotoReportModal"
    />
  </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import DateSelect from '@components/DateSelect.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import TemplateModal from './TemplateModal.vue'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import Badge from '@components/Badge.vue'
import * as dialogs from '@libs/dialogs'
import * as moment from 'moment'
import LoadingIcon from '@assets/loading-circle.svg' 

export default {
  components: {
    Icon,
    PageHeader,
    EmptyMessage,
    DateSelect,
    PrimaryButton,
    TemplateModal,
    Badge,
    LoadingIcon
  },

  props: ['menu', 'settionStrageName'],

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * タブレット
     */
    isTabletDevice() {
      return utils.deviceInfo.isTablet
    },
  },

  data() {
    return {
      loading: false,
      downloading: false,
      // 切替ボタン
      typeList: [
        { id: 1, type: 1, typeName: 'フリー入力', commonItem: false, title: 'フリー入力' }
      ],
      type: [],
      // リスト
      list: [],
      // 検索条件
      filter: {
        // 「更新者が自分」で絞り込み
        filterUpdateUser: true,
        // 報告書No
        id: '',
        // タイトル絞り込み
        title: '',
        // 得意先・物件名絞込み
        clientAndSite: '',
        // 支店・部署絞込み
        office: '',
        // 点検月絞り込み
        implementation: '',
        // 作成者・更新者絞込み
        user: '',
        // 点検日
        period: {
          start: moment().add(-6, 'M').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD')
        },
        periodPresetName: '6_month',
      },
      // 新規作成モーダル
      photoReportModalShow: false,
      // ステータスカラー
      statusColor: {
        '作成中': 'orange',
        '完了': 'blueGray'
      },
      // チェックを入れたデータのID配列
      checkedId: []
    }
  },

  async created() {
    await this.getType()
    this.getSettionStrage()
    await this.getData()
  },

  methods: {
    /**
     * リフレッシュアイコンイベント
     */
    async refresh() {
      await this.getData()
    },

    /**
     * セッションストレージ取得
     */
    getSettionStrage() {
      const searchJson = sessionStorage.getItem(this.settionStrageName)
      const getItem = JSON.parse(searchJson)
      if (getItem) {
        this.type = getItem.type

        delete getItem.type
        this.filter = getItem
      }
      if (!this.type) {
        this.type = []
      }
      if (!this.filter.periodPresetName) {
        this.filter.periodPresetName = '6_month'
      }
      if (!this.filter.period) {
        this.filter.period = {
          start: moment().add(-6, 'M').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD')
        }
      }
    },

    /**
     * セッションストレージ保存
     */
    setSessionStorage() {
      const searchStr = JSON.stringify({ ...this.filter, type: this.type })
      sessionStorage.setItem(this.settionStrageName, searchStr)
    },

    /**
     * 種別取得
     */
    async getType() {
			const res = await backend.searchData('photoReport/getType', { menu: this.menu })
			if (res.data.data && res.data.data.length) {
				this.typeList = res.data.data
			}
    },

    /**
     * データ取得
     */
    async getData() {
      this.list = []

      if (!this.type || !this.type.length) {
        if (this.menu == 'photoReport') {
          this.type = [1, 2]
        } else {
          this.type = [3, 4, 5]
        }
      }

      // データ取得
      if (this.type && this.type.length) {
        this.loading = true
        // カナを削除したログインユーザー名
        const loginUser = utils.deleteKana(this.$store.getters.user.user_name)
        const res = await backend.postData('photoReport/getList', { type: this.type, ...this.filter, loginUser  })
        if (res.data.data && res.data.data.length) {
          this.list = res.data.data
        }

        this.setSessionStorage()

        this.loading = false
      }
    },

    /**
     * 点検日検索 変更
     */
    periodChange(range, presetName) {
      this.filter.period = range
      this.filter.periodPresetName = presetName
    },

    /**
     * 切替イベント
     * @paran tab 選択したタブ
     */
    async typeChanged(tab) {
      if (this.type.includes(tab.id)) {
        const t = this.type.filter((ty) => {
          return ty != tab.id
        })
        if (t && t.length) {
          this.type = t
        } else {
          this.type = []
        }
      } else {
        this.type.push(tab.id)
      }
      await this.getData()
    },

    /**
     * 報告書へ遷移イベント
     */
    toPhotoReport(row) {
      // 必要なparamsを取得
      const param = this.typeList.find((t) => {
        return t.id == row.type
      })
      if (param) {
        row.commonItem = param.commonItem
        row.editSameMonth = param.editSameMonth
        row.editSameDay = param.editSameDay
        row.editSameUser = param.editSameUser
        row.autoDate = param.autoDate
        row.sendChat = param.sendChat
        row.sendPdf = param.sendPdf
      }
      this.$router.push({ 
        name: 'PhotoReport',
        params: {
          target: row
        },
        query: {
          id: row.id,
          menu: this.menu
        }
      })
    },

    /**
     * 写真報告テンプレートモーダルclose
     */
    closePhotoReportModal() {
      this.photoReportModalShow = false
    },

    /**
     * 選択カラムクリックイベント
     */
    allChecked() {
      if (this.checkedId && this.checkedId.length) {
        this.checkedId = []
      } else {
        this.checkedId = this.list.map((l) => {
          return l.id
        })
      }
    },

    /**
     * ダウンロードイベント
     * @param row 単体ダウンロード
     * @param checked 選択ダウンロード
     */
    async download(row, checked) {
      if (row) {
        await this.downloadlBtn([row.id])
      } else if (checked) {
        if (!this.checkedId || !this.checkedId.length) {
          await dialogs.showErrorDialog('未選択', '選択されたデータがありません。')
          return
        }
        await this.downloadlBtn(this.checkedId)

        this.checkedId = []
      }
    },

    /**
     * 単体ダウンロード
     * @param checkedId ダウンロードするデータのID（配列）
     */
    async downloadlBtn(checkedId) {
      this.downloading = true
      // 昇順に並び替え
      checkedId = utils.numberSort(checkedId)
      const res = await backend.postBlob2('photoReportDownload/excel', { list: true, checkedId })
      try {
        const uint8Array = res.data
        const blob = new Blob([uint8Array], { "Content-Type" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const a = document.createElement("a");
        a.href = (window.URL || window.webkitURL).createObjectURL(blob)
        let name = '写真報告_No.' + checkedId.join('、')

        a.download = name + '.xlsx'
        a.click();
        a.remove();
        this.downloading = false
      } catch (e) {
        console.log(e)
        alert(e)
        this.downloading = false
      }
    },

  }
}
</script>
  
<style scoped>
.main-form {
  min-width: 1175px;
}

.table-h {
  max-height: calc(100% - 40px);
}

.table {
  width: 100%;
}

.bg-colu {
  background: #ebf0fb;
}

.td2 {
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
}

.edit-btn {
  background: lightseagreen;
  border-color: #3a623e;
}

.edit-btn:hover {
  background: lightseagreen;
  border-color: #3a623e;
}

#filterDate {
  /* width: 256px !important; */
  width: auto !important;
}

#filterDate >>> div {
  color: rgb(70, 70, 70) !important;
}

#filterDate >>> input[type=text] {
  background: white !important;
}

input::placeholder {
  font-size: small;
}
</style>