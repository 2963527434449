<template>
  <div>
    <CheckListComponent
      typeName="設備点検 完了一覧"
      :type="4"
      :claim="false"
      settionStrageName="FacilityCheckListStorageItemName">
    </CheckListComponent>
  </div>
</template>

<script>
import CheckListComponent from '../../Patrol/components/CheckListComponent.vue'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    CheckListComponent
  },
}
</script>