<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #title>見積の選択</template>
      <template>
        <div v-if="loading" class="flex justify-center items-center loading-dialog3">
          <p class="text-gray-600 font-bold animate-pulse" style="font-size: 30px">Loading...</p>
        </div>

        <div class="content-container-app">
          <!-- 検索 -->
          <div class="flex mb-1">
            <div class="relative focus-within:text-gray-400 search-contents">
              <input
                type="search"
                name="estimateNo" 
                placeholder="見積番号"
                class="block w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm" 
                v-model="estimateNo"
              />
            </div>
            <div class="relative focus-within:text-gray-400 search-contents">
              <input
                type="search"
                name="clientName" 
                placeholder="得意先名"
                class="block w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm" 
                v-model="clientName"
              />
            </div>
            <div class="relative focus-within:text-gray-400 search-contents">
              <input
                type="search"
                name="filterClient" 
                placeholder="建物名"
                class="block w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm" 
                v-model="buildingName"
              />
            </div>
            <div class="w-180px mr-3" v-tooltip="'作成日'">
              <DateSelect
                id="periodCreate"
                :presetName="periodPresetName"
                ref="dateSelect"
                :period="periodCreate"
                class="search-contents"
                @change="periodChange"
              />
            </div>
            <div class="relative focus-within:text-gray-400 search-contents">
              <input
                type="search"
                name="taskStaff" 
                placeholder="担当者"
                class="block w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm" 
                v-model="taskStaff"
              />
            </div>
            <div class="ml-auto mr-1">
              <PrimaryButton text="検索" class="ml-auto" @click="getData()"/>
            </div>
          </div>
          <!-- 一覧 -->
          <div class="content-container flex flex-col border border-gray-300 rounded bg-white relative">
            <div class="overflow-auto flex-1 relative list">
              <VirtualDataTable
                ref="vdt"
                :columns="columns" 
                :columnWidth="columnWidth" 
                :columnAlignment="columnAlignment" 
                :rowHeight="60"
                :data="list" 
                :selectable="true"
                @selectionChange="selectionChange"
              />
            </div>
            <p 
              v-if="list.length === 0 && !loading"
              class="absolute w-full h-full flex justify-center items-center text-gray-400"
            >
              表示する見積りがありません
            </p>
          </div>

          <div class="mt-3 mb-1 mx-1">
            <PrimaryButton text="選択" class="ml-auto w-full" :disabled="selectedData === null" @click="select()"/>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import VirtualDataTable from '@components/VirtualDataTable.vue'
import DateSelect from '@components/DateSelect.vue'
import * as estimateManager from '@managers/estimateManager'
import * as requestPostManager from '@managers/requestPostManager'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'

// カラム
const columns = [
  [ { name: 'estimateNo', title: '見積番号', type: 'link', blank: true, url: '/estimate?estimateNo=${record.estimateNo}' } ],
  [ { name: 'requestPostName', title: 'サブタスク', type: 'text', } ],
  [ { name: 'clientName', title: '得意先名', type: 'text' }, { name: 'buildingName', title: '建物名', type: 'text' } ],
  [ 
    { name: 'withoutTax', title: '税抜き', type: 'number', formatter: 'currency', align: 'right' },
    { name: 'totalAmount', title: '税込み', type: 'number', formatter: 'currency', align: 'right' }
  ],
  [ 
    { 
      name: 'estimateDate', 
      title: '作成日', 
      type: 'text',
      valueFunc: rowData => {
        const d = utils.stringToDate(rowData.estimateDate)
        return utils.formatDate(d, '{yyyy}年{MM}月{dd}日')
      }
    }, 
    { 
      name: 'expireDate', 
      title: '期限', 
      type: 'text',
      valueFunc: rowData => {
        const d = utils.stringToDate(rowData.expireDate)
        return utils.formatDate(d, '{yyyy}年{MM}月{dd}日')
      }
    } 
  ],
  [
    { name: 'taskStaff',
      title: '担当者',
      type: 'text'
    },
    { name: 'estimateCreaterName',
      title: '作成者',
      type: 'text'
    },
  ],
  [ { name: 'viewLink', fixedName: '参照', title: '', type: 'link', blank: true, url: '/EstimateView?estimateNo=${record.estimateNo}' } ],
]

// カラム幅
const columnWidth = [
  '150px', // 見積番号
  'auto', // リクエストポスト依頼
  'auto', // 得意先・建物名
  '120px', // 見積金額
  '140px', // 作成日・期限
  '130px', // 担当者
  '80px', // 参照
]

// カラム位置
const columnAlignment = [
  'left', // 見積番号
  'left', // お客様・建物名
  'left', // 要件
  'right', // 見積金額
  'center', // 作成日・期限
  'left', // 社内担当者
  'center' // 参照
]

export default {
  components: {
    Modal,
    PrimaryButton,
    VirtualDataTable,
    DateSelect
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      // 見積一覧
      list: [],
      // カラム
      columns,
      // カラムの幅
      columnWidth,
      // カラムの位置
      columnAlignment,
      // 見積番号検索
      estimateNo: '',
      // 得意先検索
      clientName: '',
      // 建物名検索
      buildingName: '',
      // 作成日期間検索 表示期間名
      periodPresetName: '6_month',
      // 作成日期間検索
      periodCreate: {
        start: '',
        end: ''
      },
      // 担当者検索
      taskStaff: '',
      // 選択したデータ
      selectedData: null,
      // ローディング
      loading: false
    }
  },

  async created() {
    // 担当者はログインユーザーを初期値とする
    if (this.$store.getters.user && this.$store.getters.user.user_name) {
      this.taskStaff = utils.deleteKana(this.$store.getters.user.user_name)
    } else {
      this.taskStaff = ''
    }
  },

  watch: {
    open() {
      if (this.open) {
        this.getData()
      } else {
        return
      }
    }
  },
  
  computed: {
    /**
     * ユーザーリスト
     */
    userList() {
      return this.$store.getters.userList
    },
  },

  methods: {
    /**
     * データ取得
     */
    async getData() {
      this.loading = true
      // 見積データ取得
      const res = await estimateManager.getEstimateForOrder(this, this.estimateNo, this.clientName, this.buildingName, this.periodCreate, this.taskStaff)
      if (res && res.length) {
        // 見積担当者・作成者を生成
        res.forEach((i) =>{
          i.taskStaff = utils.deleteKana(i.taskStaff)
          i.estimateCreaterName = this.getEstimateCreater(i.estimateCreater, i.userStamp)
        })
        this.list = res
      } else {
        this.list = []
      }
      this.loading = false
    },

    /**
     * 見積最終作成者
     * @param estimateCreater 作成者
     * @param userStamp 印画像URL
     */
    getEstimateCreater(estimateCreater, userStamp) {
      if (estimateCreater && estimateCreater != '') {
        let name = utils.getUserNameById(estimateCreater, this.userList)
        if (name && name != '') {
          name = utils.deleteKana(name)
        }
        return name

      // estimateCreaterがない場合
      } else {
        if (userStamp && userStamp != '') {
          let result = null
          let num = userStamp.length - 8
          result = userStamp.substr(num, 4)
          let name = utils.getUserNameById(result, this.userList)
          if (name && name != '') {
            name = utils.deleteKana(name)
          }
          return name
        } else {
          return null
        }
      }
    },
    
    /**
     * 作成日期間変更イベント
     * @param range 範囲
     * @param presetName 期間名
     */
    periodChange(range, presetName) {
      this.periodCreate = range
      this.periodPresetName = presetName
    },

    /**
     * ラジオボタン選択イベント
     */
    selectionChange(selectedRow) {
      this.selectedData = selectedRow
    },

    /**
     * 選択ボタンイベント
     */
    async select() {
      // メインが完了しているか確認
      const parentRequest = await requestPostManager.getRequestPost(this, this.selectedData.requestPostId)
      if (parentRequest.status == 'open') {
        let dialogResult = await dialogs.showWarningConfirmDialog('メインタスク未完了', `メインタスクが未完了ですが、受注に進みますか？`)
        if (dialogResult != 'YES') {
          return
        }
      }
      this.$emit('modalEstimateSelected', this.selectedData)
    },

    /**
     * 初期化
     */
    closed() {
      this.selectedData = null
      this.loading = false
    }
  }
  
}
</script>
<style scoped>
  .content-container {
    width: calc(100vw - 185px);
    height: calc(100vh - 300px);
    overflow: auto;
  }
  
  #periodCreate >>> input[type=text] {
    background: white !important;
    color: gray !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
    width: 180px !important;
  }
  
  #periodCreate >>> .pointer-events-none {
    color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .w-180px {
    width: 180px !important;
  }

  @media screen and (max-width: 1500px) {
  .content-container {
    width: 95vw;
    height: calc(100vh - 150px);
    overflow: auto;
  }

  .list {
    width: 1500px !important;
  }
}
</style>