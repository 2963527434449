<template>
  <div>
    <PageHeader title="項目設定" class="list">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">
          <Icon iconName="Refresh" :clickable="false" :class="{ 'animate-spin': loading }"
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer" @click.native="refresh" />
        </div>
      </template>
      <template #page-header-content>
        <div class="">
          <!-- <h1 class="text-lg font-bold text-white flex mb-auto">
            <Icon iconName="DocumentText" class="w-7 h-7 mr-2" /> <span class="mt-0">項目を設定してください</span>
          </h1> -->

          <div class="flex items-stretch mx-auto w-fit">
            <!-- 並べ替え -->
            <div class="toggle flex items-center mt-auto mr-5">
              <span class="mr-1 text-white font-bold text-xs">並べ替え</span>
              <Toggle v-model="sortable" size="small" />
            </div>

            <!-- 切替 -->
            <div class="bg-white rounded">
              <div class="flex items-center py-2 rounded mx-5 ">
                <div v-for="tab in typeList" 
                  :key="tab.id" 
                  class="w-28 text-center mx-1 cursor-pointer"
                  :class="[tab.id==type ? 'bg-light-blue-700' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-1 px-3 text-sm font-medium text-white']" 
                  @click="typeChanged(tab)"
                >
                  {{ tab.typeName }}
                </div>
              </div>
            </div>

            <!-- 項目追加ボタン -->
            <div class="ml-4 mt-auto">
              <PrimaryButton text="項目追加" class="edit-btn" @click="addItem()">
              </PrimaryButton>
            </div>
          </div>

          <div></div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-32 w-screen bottom-4 leading-8">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full w-fit">
        <div class="bg-white rounded-md border border-gray-200 table-h overflow-auto">
          <table class="table text-sm">
            <thead class="th sticky top-0 z-20">
              <tr class="column">
                <th class="th2 text-center"></th>
                <th class="th2 text-center">項目</th>
                <th class="th1"></th>
              </tr>
            </thead>
            
            <draggable
              key="reportItem"
              :list="list"
              direction="vertical"
              handle=".row-drag-handle"
              tag="tbody"
              class="bg-white divide-y divide-gray-200"
            >
              <tr  v-for="(row, i) in list" :key="i" :class="setColor(i)" class="hover-color">
                <!-- 表示順 -->
                <td class="td2 text-center">
                  <div v-if="sortable" class="mx-1.5 items-center row-drag-handle cursor-pointer col-no">
                    <MenuIcon class="text-gray-400" />
                  </div>
                  <div v-else>
                    {{ 'No.' + (i + 1) }}
                  </div>
                </td>

                <!-- 項目 -->
                <td class="px-3 py-2">
                  <textarea
                    rows="1"
                    class="border border-gray-300 w-96 my-0.5 rounded"
                    v-model="row.title"
                  />
                </td>

                <!-- 削除ボタン -->
                <td class="td1">
                  <PrimaryButton text="削除" @click="deleteItem(i)" size="normal bg-indigo-200 text-indigo-700 hover:bg-indigo-300" />
                </td>
              </tr>
            </draggable>
          </table>
        </div>
        <div class="">
          <PrimaryButton text="保存" class="w-full" @click="save()" />
        </div>
      </div>
    </main>
  </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import Toggle from '@components/Toggle.vue'
import draggable from "vuedraggable"
import { MenuIcon } from '@vue-hero-icons/solid'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'

export default {
  components: {
    Icon,
    PageHeader,
    PrimaryButton,
    Toggle,
    draggable,
    MenuIcon
  },

  props: ['menu'],

  data() {
    return {
      loading: false,
      // 切替ボタン
      typeList: [
        {id: 2, typeName: '現地調査'}
      ],
      type: null,
      // 項目リスト
      list: [{ title: '' }],
      // 並べ替え
      sortable: false,
    }
  },

  async created() {
    this.getType()
    this.type = 2
    if (this.menu == 'evaluation') {
      this.type = 3
    }
    await this.getData()
  },

  methods: {
    /**
     * リフレッシュアイコンイベント
     */
    async refresh() {
      await this.getData()
    },

    /**
     * 種別取得
     */
    async getType() {
			const res = await backend.searchData('photoReport/getTypeOfCommonItem', { menu: this.menu })
			if (res.data.data && res.data.data.length) {
				this.typeList = res.data.data
			}
    },

    /**
     * データ取得
     */
    async getData() {
      this.loading = true
      this.list = []

      const res = await backend.searchData('photoReport/getCommonItem', { type: this.type })
      if (res.data.data && res.data.data.length) {
        this.list = res.data.data
      } else {
        for (let i = 0; i < 5; i++) {
          this.addItem()
        }
      }

      this.loading = false
    },

    /**
     * 背景色設定
     */
    setColor(i) {
      if (i % 2 != 0) {
        return 'bg-blue'
      }
    },

    /**
     * 項目追加
     */
    addItem() {
      this.list.push({ item: null })
    },

    /**
     * 削除ボタン イベント
     */
    deleteItem(i) {
      this.list.splice(i, 1)
      if (!this.list || !this.list.length) {
        this.addItem()
      }
    },

    /**
     * 切替イベント
     * @paran tab 選択したタブ
     */
    async typeChanged(tab) {
      this.type = tab.id
      await this.getData()
    },

    /**
     * 保存イベント
     */
    async save() {
      // 入力確認
      for (let i = 0; i < this.list.length; i++) {
        const l = this.list[i]
        if (!l.title) {
          await dialogs.showErrorDialog(`No.${(i + 1)} の項目が未入力です。`, '項目を入力するか、削除してください。')
          return false
        }
      }
      try {
        await backend.postData('photoReport/saveCommonItem', { list: this.list, type: this.type, insertUser: this.$store.getters.user.id })

        const typeName = this.typeList.find((t) => {
          return t.id == this.type
        })
        logManager.recordingByRegist(this, false, '写真報告書 項目設定', typeName.typeName + 'の固定項目')

        await dialogs.showInfoDialog('保存完了', '保存しました。')
      } catch (error) {
        alert(error)
      }
    }
  }
}
</script>
  
<style scoped>
.table-h {
  max-height: calc(100% - 40px);
}

.table {
  width: 100%;
}

.column th {
  height: 50px;
  background: #edecf7;
}

.bg-blue {
  background: rgb(243 247 249);
}

.th1 {
  width: 100px;
  text-align: center;
}

.th2 {
  text-align: left;
  padding-left: 10px;
}

.td2 {
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.hover-color:hover {
  background: rgba(184, 244, 242, 0.67);
}

.edit-btn {
  background: lightseagreen;
  border-color: #3a623e;
}

.edit-btn:hover {
  background: lightseagreen;
  border-color: #3a623e;
}
</style>