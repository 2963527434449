<template>
  <div>
    <PageHeader title="Request Post" :class="isCategory ? 'pageHeader-estimate-contents' : 'pageHeader-noEstimate-contents' ">
      <template #title-header-content>
        <div class="flex-1 flex justify-end items-center" v-tooltip="!canEdit && !isMainStaff && requestPost.status != 'remove'?'権限がない、もしくは担当者でないため更新できません':''">
          <div class="mx-auto">
            <PrimaryButton class="bg-green-600 mr-2" text="メインタスク" @click="toMainTask()">
              <Icon iconName="ViewGrid" slot="before" class="h-4 w-4" />
            </PrimaryButton>
          </div>

          <div class="flex">
            <PrimaryButton v-if="subAuth && requestPost.status != 'done' && requestPost.status != 'remove'" :disabled="isSaving || (!canEdit && !isMainStaff)" class="w-44 bg-green-600 mr-2" :text="isEdit ? '更新' : '登録'" @click="save(false, false)">
              <LoadingIcon v-if="isSaving" slot="before" class="h-4 w-4" />
              <Icon v-if="!isSaving" slot="before" class="h-4 w-4" />
            </PrimaryButton>

            <div v-if="requestPost.status=='done'" class="text-white text-xs mr-1">メインが完了しているため再度保存はできません</div>
            <div v-else-if="requestPost.status=='remove'" class="text-white text-xs mr-1">メインが取消されているため再度保存はできません</div>

            <PrimaryButton text="一覧" class="w-40" @click="toList">
              <Icon slot="before" iconName="ClipboardList" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
            </PrimaryButton>
          </div>

        </div>
      </template>
    </PageHeader>
    <main class="absolute w-screen bottom-4 -mt-2 page-contents" :class="isCategory ? 'main-estimate-contents' : 'main-noEstimate-contents' ">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-gray-100 rounded-md border border-gray-300 overflow-auto h-full">

          <div v-if="initialized" class="px-0">

            <div class="flex">
              <div class="flex-1 p-4 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 self-start">

                <div class="sm:col-span-full">
                  <Category2Select
                    name="category2"
                    caption="社内/お客様"
                    v-model="requestPost.category2"
                    :required="isDentasuRequired"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" id="inquiry-category">
                  <CategorySelect
                    name="category"
                    caption="お問い合わせカテゴリー(複数選択可)"
                    v-model="requestPost.category"
                    :required="true"
                    :disabled="true"
                    :isOrder="isOrder"
                  />
                </div>

                <div class="sm:col-span-full">
                  <WmsTextInput 
                    name="requestNo" 
                    caption="お問い合わせ番号(登録時)"
                    :disabled="true"
                    v-model="requestPost.requestNo"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('追加依頼')">
                  <WmsTextInput 
                    name="lastRequestNo" 
                    caption="お問い合わせ番号(前回問い合わせ時の返信メールに記載がございます)"
                    v-model="requestPost.lastRequestNo"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-if="!isTransmission">
                  <WmsTextInput
                    name="inquiry_title" 
                    caption="タイトル"
                    :required="isDentasuRequired"
                    v-model="requestPost.inquiry_title"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-if="!isTransmission && !isOrder">
                  <WmsTextInput 
                    name="note" 
                    caption="お問い合わせ内容"
                    v-model="requestPost.note"
                    :rows="5"
                    :multiline="true"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full">
                  <WmsSuggestInput
                    name="clientName" 
                    :caption="isTransmission ? 'お客様会社名（物件名）' : 'お客様会社名'"
                    :required="isClientRequired && isDentasuRequired"
                    v-model="requestPost.clientName"
                    :disabled="true"
                  />
                </div>

                <div v-if="!isTransmission" class="sm:col-span-full" >
                  <WmsTextInput
                    name="clientAddress1" 
                    caption="お客様住所（都道府県・市区群・町村・番地）"
                    :disabled="true"
                    v-model="requestPost.clientAddress1"
                  />
                </div>

                <div v-if="!isTransmission" class="sm:col-span-full">
                  <WmsTextInput
                    name="clientAddress2" 
                    caption="お客様住所（建物名）"
                    :disabled="true"
                    v-model="requestPost.clientAddress2"
                  />
                </div>

                <div class="sm:col-span-full">
                  <WmsTextInput
                    name="clientPersonName" 
                    :caption="isTransmission ? 'お客様名' : 'お客様担当者名'"
                    :required="isClientRequired && isDentasuRequired"
                    v-model="requestPost.clientPersonName"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('デンタツ')">
                  <WmsTextInput 
                    name="employeeName" 
                    caption="従業員名"
                    v-model="requestPost.employeeName"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('デンタツ')">
                  <WmsTextInput 
                    name="telephoneNumber" 
                    caption="電話番号"
                    v-model="requestPost.telephoneNumber"
                    :required="true"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('デンタツ')">
                  <WmsTextInput
                    name="inquiry_title" 
                    caption="お問い合わせ内容"
                    v-model="requestPost.inquiry_title"
                    :disabled="true"
                    :multiline="true"
                    :rows="5"
                  />
                </div>

                <div class="sm:col-span-full">
                  <WmsTextInput
                    name="clientMail" 
                    :caption="isTransmission ? 'メールアドレス' : 'メールアドレス(こちらのメールアドレスに回答が届きます)'"
                    :required="isClientRequired && isDentasuRequired"
                    v-model="requestPost.clientMail"
                    :disabled="true"
                  />
                </div>

                <!-- 添付資料 -->
                <div class="sm:col-span-full" v-if="!isOrder && !isShowCategoryItem('面接関連') && !isShowCategoryItem('雇い入れ')">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="commonFile" 
                        :value="requestPost.commonFile" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowAddFileBtnItem('添付資料追加')" v-if="!isOrder">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="commonFile2" 
                        :value="requestPost.commonFile2" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowAddFileBtnItem('添付資料追加')" v-if="!isOrder">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="commonFile3" 
                        :value="requestPost.commonFile3" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowAddFileBtnItem('添付資料追加')" v-if="!isOrder">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料4
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="commonFile4" 
                        :value="requestPost.commonFile4" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowAddFileBtnItem('添付資料追加')" v-if="!isOrder">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料5
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="commonFile5" 
                        :value="requestPost.commonFile5" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true" 
                      />
                    </div>
                  </div>
                </div>

                <!-- 対応内容 -->
                <div class="sm:col-span-full" v-if="!isOrder">
                  <WmsTextInput 
                    name="reason_note" 
                    caption="対応内容"
                    :multiline="true"
                    :rows="5"
                    v-model="requestPost.reason_note"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-3">
                  <WmsSuggestInput
                    name="toDepartment" 
                    caption="アポロ管財依頼先部署" 
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="toDepartmentList"
                    displayField="departmentName"
                    :filter="{}"
                    :readonly="isModileDevice"
                    v-model="requestPost.toDepartment"
                    :disabled="true"
                    :required="true"
                  />
                </div>

                <div class="sm:col-span-full">
                  <WmsSuggestInput
                    name="toStaffName" 
                    caption="担当者" 
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="toStaffNameList"
                    displayField="departmentName"
                    :filter="{}"
                    :readonly="isModileDevice"
                    v-model="requestPost.toStaffName"
                    :disabled="true"
                    :required="true"
                  />
                </div>

                <div class="sm:col-span-full">
                  <WmsSuggestInput
                    name="byUser" 
                    caption="依頼主" 
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="$store.getters.userList"
                    displayField="user_name"
                    :filter="{}"
                    :readonly="isModileDevice"
                    :required="true"
                    v-model="requestPost.byUser"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full">
                  <WmsTextInput
                    name="requestDate"
                    caption="依頼日" 
                    :required="true"
                    v-model="dispRequestDate"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full">
                  <WmsTextInput 
                    name="limitDate" 
                    caption="期限"
                    v-model="dispLimitDate"
                    :required="true"
                    :disabled="true"
                  />
                </div>

              </div>

              <div class="p-4 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 self-start" :class="isCategory ? 'rp-estimate' : 'rp-no-estimate' ">
                <InputGroupSubLabel
                  name="dentatsuRequest"
                  caption="デンタツ依頼"
                  class="sm:col-span-full"
                  v-show="isShowCategoryItem('デンタツ')"
                />

                <div class="sm:col-span-full" v-show="isShowCategoryItem('デンタツ')">
                  <DentatsuCategorySelect
                    name="dentatsuCategory"
                    caption="デンタツ詳細カテゴリー" 
                    v-model="requestPost.dentatsuCategory"
                  />
                </div>

                <div class="sm:col-span-full estimate-category" v-show="isShowCategoryItem('見積依頼')">
                  <EstimationCleanCategorySelect
                    name="estimationCleanCategory"
                    caption="御見積_清掃カテゴリー(複数選択可)" 
                    v-model="requestPost.estimationCleanCategory"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full estimate-category" v-show="isShowCategoryItem('見積依頼')">
                  <EstimationInspectCategorySelect
                    name="estimationInspectCategory"
                    caption="御見積_点検カテゴリー(複数選択可)" 
                    v-model="requestPost.estimationInspectCategory"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full estimate-category" v-show="isShowCategoryItem('見積依頼')">
                  <EstimationOtherCategorySelect
                    name="estimationOtherCategory"
                    caption="御見積_その他カテゴリー" 
                    v-model="requestPost.estimationOtherCategory"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <NewNowFlagSelect
                    name="newNowFlag"
                    caption="契約無/契約有" 
                    v-model="requestPost.newNowFlag"
                    :required="true"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <NewClassSelect
                    name="newClass"
                    caption="新規区分" 
                    v-model="requestPost.newClass"
                    :required="true"
                    :disabled="true"
                  />
                </div>

                <InputGroupLabel
                  name="estimationBasicLabel"
                  caption="見積基本情報"
                  class="mt-4"
                  v-show="isShowCategoryItem('見積依頼')"
                  :disabled="true"
                />

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="buildingName" 
                    caption="建物名"
                    :required="true"
                    v-model="requestPost.buildingName"
                    :disabled="true"
                  />
                </div>

                <InputGroupLabel
                  name="buildingAddressLabel"
                  caption="所在地"
                  class="mt-4"
                  v-show="isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="postalCode" 
                    caption="郵便番号"
                    v-model="requestPost.postalCode"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="state" 
                    caption="都道府県"
                    :required="isBuildingDetailsRequired"
                    v-model="requestPost.state"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="city" 
                    caption="市区郡"
                    :required="isBuildingDetailsRequired"
                    v-model="requestPost.city"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="street" 
                    caption="町村・番地"
                    v-model="requestPost.street"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="building" 
                    caption="建物名"
                    v-model="requestPost.building"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-2" v-show="isShowCategoryItem('見積依頼')">
                  <NumericTextInput2 
                    name="stairs" 
                    caption="階数"
                    v-model="requestPost.stairs"
                    :required="isBuildingDetailsRequired"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-2" v-show="isShowCategoryItem('見積依頼')">
                  <NumericTextInput2 
                    name="basement" 
                    caption="地下"
                    v-model="requestPost.basement"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-2" v-show="isShowCategoryItem('見積依頼')">
                  <NumericTextInput2 
                    name="households" 
                    caption="戸数"
                    v-model="requestPost.households"
                    :required="isBuildingDetailsRequired"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="estimationReason" 
                    caption="見積理由(新規・業者の切り替え・リプレイスなど)"
                    :multiline="true"
                    :rows="5"
                    v-model="requestPost.estimationReason"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <AvailablePowerFlagSelect
                    name="availablePowerFlag"
                    caption="使用できる電源の有無" 
                    v-model="requestPost.availablePowerFlag"
                    :required="true"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <ParkingFlagSelect
                    name="parkingFlag"
                    caption="駐車スペースの有無" 
                    v-model="requestPost.parkingFlag"
                    :required="true"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <AvailableToiletFlagSelect
                    name="availableToiletFlag"
                    caption="使用可能なトイレの有無" 
                    v-model="requestPost.availableToiletFlag"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼')">
                  <ApartmentTypeSelect
                    name="apartmentType"
                    caption="マンションタイプ"
                    v-model="requestPost.apartmentType"
                    :disabled="true"
                  />
                </div>

                <InputGroupSubLabel
                  name="estimationRoundLabel"
                  caption="ラウンドサービス"
                  description="※バイク駐車スペースは見積もり作成の必須条件となります。"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('ラウンドサービス') && isShowCategoryItem('見積依頼')"
                  :disabled="true"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドサービス') && isShowCategoryItem('見積依頼')">
                  <CleanCountSelect
                    name="cleanCount"
                    caption="清掃希望回数(月〇回、週〇回など)"
                    :required="true"
                    v-model="requestPost.cleanCount"
                    :house="requestPost.households"
                    :category2="requestPost.category2"
                    :disabled="true"
                  />
                </div>
                
                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドサービス') && isShowCategoryItem('見積依頼')">
                  <DustRemovalSelect
                    name="dustRemoval"
                    caption="ゴミ搬出有無"
                    :required="true"
                    v-model="requestPost.dustRemoval"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドサービス') && isShowCategoryItem('見積依頼') && requestPost.dustRemoval.length && requestPost.dustRemoval[0]=='有'">
                  <DustCountSelect
                    name="dustCount"
                    caption="ゴミ搬出希望回数"
                    v-model="requestPost.dustCount"
                    :required="true"
                    :category2="requestPost.category2"
                    :cleanCount="requestPost.cleanCount"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドサービス') && isShowCategoryItem('見積依頼')">
                  <ToiletCleaningSelect
                    name="toiletCleaning"
                    caption="有料トイレ清掃あり/有料トイレ清掃無し"
                    v-model="requestPost.rlsToiletCleaning"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼') && isShowtToilet(requestPost.rlsToiletCleaning, '有料トイレ清掃あり') && isShowEstimationCleanCategoryItem('ラウンドサービス')">
                  <NumericTextInput2 
                    name="toiletTimes"
                    caption="有料トイレ清掃回数（回/週）" 
                    placeholder="4"
                    v-model="requestPost.rlsToiletTimes"
                    :required="true"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼') && isShowtToilet(requestPost.rlsToiletCleaning, '有料トイレ清掃あり') && isShowEstimationCleanCategoryItem('ラウンドサービス')">
                  <NumericTextInput2 
                    name="toiletPlace" 
                    caption="トイレ箇所数"
                    v-model="requestPost.rlsToiletPlace"
                    :required="true"
                    :disabled="true"
                  />
                </div>

                <InputGroupSubLabel
                  name="estimationRoundPlusLabel"
                  caption="ラウンドプラス"
                  description="※バイク駐車スペースは見積もり作成の必須条件となります。"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('ラウンドプラス') && isShowCategoryItem('見積依頼')"
                  :disabled="true"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドプラス') && isShowCategoryItem('見積依頼')">
                  <CleanCountSelect
                    name="plusCleanCount"
                    caption="清掃希望回数(月〇回、週〇回など)"
                    :required="true"
                    v-model="requestPost.plusCleanCount"
                    :house="requestPost.households"
                    :category2="requestPost.category2"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドプラス') && isShowCategoryItem('見積依頼')">
                  <PlusDustRemovalSelect
                    name="plusDustRemoval"
                    caption="ゴミ搬出有無"
                    :required="true"
                    v-model="requestPost.plusDustRemoval"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドプラス') && isShowCategoryItem('見積依頼') && requestPost.plusDustRemoval.length && requestPost.plusDustRemoval[0]=='有'">
                  <DustCountSelect
                    name="plusDustCount"
                    caption="ゴミ搬出希望回数"
                    :required="true"
                    v-model="requestPost.plusDustCount"
                    :category2="requestPost.category2"
                    :cleanCount="requestPost.cleanCount"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ラウンドプラス') && isShowCategoryItem('見積依頼')">
                  <ToiletCleaningSelect
                    name="toiletCleaning"
                    caption="巡回清掃"
                    v-model="requestPost.plusToiletCleaning"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼') && isShowtToilet(requestPost.plusToiletCleaning, '有料トイレ清掃あり') && isShowEstimationCleanCategoryItem('ラウンドプラス')">
                  <NumericTextInput2 
                    name="toiletTimes"
                    caption="有料トイレ清掃回数（回/週）（巡回清掃）" 
                    placeholder="4"
                    v-model="requestPost.plusToiletTimes"
                    :required="true"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼') && isShowtToilet(requestPost.plusToiletCleaning, '有料トイレ清掃あり') && isShowEstimationCleanCategoryItem('ラウンドプラス')">
                  <NumericTextInput2 
                    name="toiletPlace" 
                    caption="有料トイレ清掃箇所数（巡回清掃）"
                    v-model="requestPost.plusToiletPlace"
                    :required="true"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full mt-3" v-show="isShowEstimationCleanCategoryItem('ラウンドプラス') && isShowCategoryItem('見積依頼')">
                  <ToiletCleaningSelect
                    name="plusToiletCleaningNormal"
                    caption="固定清掃"
                    v-model="requestPost.plusToiletCleaningNormal"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼') && isShowtToilet(requestPost.plusToiletCleaningNormal, '有料トイレ清掃あり') && isShowEstimationCleanCategoryItem('ラウンドプラス')">
                  <NumericTextInput2 
                    name="plusToiletTimesNormal"
                    caption="有料トイレ清掃回数（回/週）（固定清掃）" 
                    v-model="requestPost.plusToiletTimesNormal"
                    :required="true"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowCategoryItem('見積依頼') && isShowtToilet(requestPost.plusToiletCleaningNormal, '有料トイレ清掃あり') && isShowEstimationCleanCategoryItem('ラウンドプラス')">
                  <NumericTextInput2 
                    name="plusToiletPlaceNormal" 
                    caption="有料トイレ清掃箇所数（固定清掃）"
                    v-model="requestPost.plusToiletPlaceNormal"
                    :required="true"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowCategoryItem('見積依頼') && (isShowEstimationCleanCategoryItem('ラウンドサービス') || isShowEstimationCleanCategoryItem('ラウンドプラス'))">
                  <ResidenceTypeSelect
                    name="residenceType"
                    caption="ファミリー/シングル"
                    v-model="requestPost.residenceType"
                    :disabled="true"
                  />
                </div>

                <InputGroupSubLabel
                  name="estimationEverydayLabel"
                  caption="日常清掃"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')"
                  :disabled="true"
                />

                <div class="sm:col-span-full">
                  <div v-for="(everydayClean, index) in requestPost.everydayCleans" v-bind:key="everydayClean.id">
                    <div class="mt-3" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')">
                      <label
                        name="otherWorkLabel"
                        class="text-sm font-bold"
                      >
                        日常清掃{{ outputIndex(index) }}
                      </label>
                    </div>

                    <div class="flex mt-ec">
                      <div class="everydayCleanCount" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="everydayCleanCount" 
                          caption="清掃希望回数（週〇回）"
                          :required="true"
                          :decimalPlace="deci"
                          v-model="everydayClean.everydayCleanCount"
                          :disabled="true"
                        />
                      </div>

                      <div class="everydayCleanHours" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="everydayCleanHours" 
                          caption="清掃希望時間（〇時間）"
                          placeholder="2"
                          :required="true"
                          :decimalPlace="deci"
                          v-model="everydayClean.everydayCleanHours"
                          :disabled="true"
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-full mt-ec" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')">
                      <EverydayStartTimeFlagSelect
                        name="everydayStartTimeFlag"
                        caption="作業時間の指定の有無(開始時間は7:30から)"
                        :required="true"
                        v-model="everydayClean.everydayStartTimeFlag"
                        :disabled="true"
                      />
                    </div>

                    <div class="flex mt-ec">
                      <div class="everydayStartTime" v-show="isShowEverydayStartTimeFlagItem('有', index) && isShowCategoryItem('見積依頼') && isShowEstimationCleanCategoryItem('日常清掃')">
                        <WmsTimeInput2
                          name="everydayStartTime" 
                          caption="指定作業開始時間"
                          :clearable="true"
                          :required="true"
                          v-model="everydayClean.everydayStartTime"
                          :disabled="true"
                        />
                      </div>

                      <div class="everydayEndTime" v-show="isShowEverydayStartTimeFlagItem('有', index) && isShowCategoryItem('見積依頼') && isShowEstimationCleanCategoryItem('日常清掃')">
                        <WmsTimeInput2
                          name="everydayEndTime" 
                          caption="指定作業終了時間"
                          :clearable="true"
                          v-model="everydayClean.everydayEndTime"
                          :disabled="true"
                        />
                      </div>
                    </div>
                    
                    <div class="sm:col-span-full mt-ec" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')">
                      <EverydayHolidayFlagSelect
                        name="everydayHolidayFlag"
                        caption="祝日対応の有無"
                        v-model="everydayClean.everydayHolidayFlag"
                        :disabled="true"
                      />
                    </div>

                    <div class="sm:col-span-full mt-ec" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼') && isEverydayHolidayFlag(index)">
                      <EverydayHolidaySelect
                        name="everydayHoliday"
                        caption="祝日対応"
                        v-model="everydayClean.everydayHoliday"
                        :disabled="true"
                      />
                    </div>

                    <div class="sm:col-span-full mt-ec" v-show="isShowEstimationCleanCategoryItem('日常清掃') && isShowCategoryItem('見積依頼')">
                      <ToiletCleaningSelect
                        name="toiletCleaning"
                        caption="有料トイレ清掃あり/有料トイレ清掃無し"
                        v-model="everydayClean.toiletCleaning"
                        :disabled="true"
                      />
                    </div>

                    <div class="flex mt-ec">
                      <div class="toiletTimes" v-show="isShowCategoryItem('見積依頼') && isShowtToiletCleaningItem('有料トイレ清掃あり', index) && isShowEstimationCleanCategoryItem('日常清掃')">
                        <NumericTextInput2 
                          name="toiletTimes"
                          caption="有料トイレ清掃回数（回/週）" 
                          v-model="everydayClean.toiletTimes"
                          :required="true"
                          :disabled="true"
                        />
                      </div>

                      <div class="toiletPlace" v-show="isShowCategoryItem('見積依頼') && isShowtToiletCleaningItem('有料トイレ清掃あり', index) && isShowEstimationCleanCategoryItem('日常清掃')">
                        <NumericTextInput2 
                          name="toiletPlace" 
                          caption="トイレ箇所数"
                          v-model="everydayClean.toiletPlace"
                          :required="true"
                          :disabled="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <InputGroupSubLabel
                  name="managerWorkLabel"
                  caption="管理員業務"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')"
                  :disabled="true"
                />

                <div class="sm:col-span-full">
                  <div v-for="(managerWork, index) in requestPost.managerWorks" v-bind:key="managerWork.id">
                    <div class="mt-3" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')">
                      <label
                        name="managerWorkLabel"
                        class="text-sm font-bold"
                      >
                        管理員業務{{ outputIndex(index) }}
                      </label>
                    </div>

                    <div class="flex mt-mw">
                      <div class="managerWorkCount" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="managerWorkCount" 
                          caption="清掃希望回数（週〇回）"
                          :required="true"
                          :decimalPlace="deci"
                          v-model="managerWork.managerWorkCount"
                          :disabled="true"
                        />
                      </div>

                      <div class="managerWorkHours" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="managerWorkHours" 
                          caption="清掃希望時間（〇時間）"
                          :required="true"
                          :decimalPlace="deci"
                          v-model="managerWork.managerWorkHours"
                          :disabled="true"
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-full mt-mw" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')">
                      <ManagerWorkTimeFlagSelect
                        name="managerWorkTimeFlag"
                        caption="作業時間の指定の有無(開始時間は7:30から)"
                        :required="true"
                        v-model="managerWork.managerWorkTimeFlag"
                        :disabled="true"
                      />
                    </div>

                    <div class="flex mt-mw">
                      <div class="managerWorkTime" v-show="isShowManagerWorkTimeFlagItem('有', index) && isShowCategoryItem('見積依頼')">
                        <WmsTimeInput2
                          name="managerWorkTime" 
                          caption="指定作業開始時間"
                          :clearable="true"
                          :required="true"
                          v-model="managerWork.managerWorkTime"
                          :disabled="true"
                        />
                      </div>

                      <div class="managerWorkEndTime" v-show="isShowManagerWorkTimeFlagItem('有', index) && isShowCategoryItem('見積依頼')">
                        <WmsTimeInput2
                          name="managerWorkEndTime" 
                          caption="指定作業終了時間"
                          :clearable="true"
                          v-model="managerWork.managerWorkEndTime"
                          :disabled="true"
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-full mt-mw" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼')">
                      <ManagerWorkHolidayFlagSelect
                        name="managerWorkHolidayFlag"
                        caption="祝日対応の有無"
                        v-model="managerWork.managerWorkHolidayFlag"
                        :disabled="true"
                      />
                    </div>

                    <div class="sm:col-span-full mt-mw" v-show="isShowEstimationCleanCategoryItem('管理員業務') && isShowCategoryItem('見積依頼') && isManagerWorkHolidayFlag(index)">
                      <ManagerWorkHolidaySelect
                        name="managerWorkHoliday"
                        caption="祝日対応"
                        v-model="managerWork.managerWorkHoliday"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <InputGroupSubLabel
                  name="regularCleanLabel"
                  caption="定期清掃"
                  description="※水栓の使用は見積もり作成の必須条件となります。"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')"
                  :disabled="true"
                />

                <div class="sm:col-span-full">
                  <div v-for="(regularClean, index) in requestPost.regularCleans" v-bind:key="regularClean.id">
                    <div class="mt-3" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                      <label
                        name="regularCleanLabel"
                        class="text-sm font-bold"
                      >
                        定期清掃{{ outputIndex(index) }}
                      </label>
                    </div>

                    <div class="flex">
                      <div class="mw-60 mt-1" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="regularCleanContent" 
                          caption="依頼内容" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems="[]"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          :disabled="true"
                          v-model="regularClean.regularCleanContent"
                        />
                      </div>

                      <div class="mt-1" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="regularCleanCount" 
                          caption="希望回数"
                          :required="true"
                          :disabled="true"
                          v-model="regularClean.regularCleanCount"
                        />
                      </div>
                      
                      <div class="mt-1" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="regularCleanUnitName" 
                          caption="単位" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          :disabled="true"
                          v-model="regularClean.regularCleanUnitName"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    図面(定期平面)
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="regularCleanImage" 
                        caption="図面(定期平面)"
                        :value="requestPost.regularCleanImage" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    図面(定期平面)2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="regularCleanImage2" 
                        caption="図面(定期平面)2"
                        :value="requestPost.regularCleanImage2" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    図面(定期平面)3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="regularCleanImage3" 
                        caption="図面(定期平面)3"
                        :value="requestPost.regularCleanImage3" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>
                
                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('定期清掃') && isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="regularCleanWorkWay" 
                    caption="作業仕様"
                    v-model="requestPost.regularCleanWorkWay"
                    :disabled="true"
                  />
                </div>

                <InputGroupSubLabel
                  name="glassCleanLabel"
                  caption="ガラス清掃"
                  description="※水栓の使用は見積もり作成の必須条件となります。"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('ガラス清掃') && isShowCategoryItem('見積依頼')"
                  :disabled="true"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ガラス清掃') && isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="glassCleanCount" 
                    caption="清掃希望回数"
                    :required="true"
                    v-model="requestPost.glassCleanCount"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ガラス清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    図面(ガラス立面)
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="glassCleanImage" 
                        caption="図面(ガラス立面)"
                        :value="requestPost.glassCleanImage" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ガラス清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    図面(ガラス立面)2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="glassCleanImage2" 
                        caption="図面(ガラス立面)2"
                        :value="requestPost.glassCleanImage2" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ガラス清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    図面(ガラス立面)3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="glassCleanImage3" 
                        caption="図面(ガラス立面)3"
                        :value="requestPost.glassCleanImage3" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('ガラス清掃') && isShowCategoryItem('見積依頼')">
                  <GlassCleanWorkWaySelect
                    name="glassCleanWorkWay"
                    caption="作業仕様"
                    v-model="requestPost.glassCleanWorkWay"
                    :disabled="true"
                  />
                </div>

                <InputGroupSubLabel
                   name="plantingLabel"
                  caption="植栽剪定"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="plantingCount" 
                    caption="希望回数"
                    :required="true"
                    v-model="requestPost.plantingCount"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    対象箇所
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="plantingPlaceFile" 
                        caption="対象箇所"
                        :value="requestPost.plantingPlaceFile" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    対象箇所2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="plantingPlaceFile2" 
                        caption="対象箇所2"
                        :value="requestPost.plantingPlaceFile2" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    対象箇所3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="plantingPlaceFile3" 
                        caption="対象箇所3"
                        :value="requestPost.plantingPlaceFile3" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="plantingReportFile" 
                        caption="過去の報告書"
                        :value="requestPost.plantingReportFile" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="plantingReportFile2" 
                        caption="過去の報告書2"
                        :value="requestPost.plantingReportFile2" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('植栽剪定') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="plantingReportFile3" 
                        caption="過去の報告書3"
                        :value="requestPost.plantingReportFile3" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <InputGroupSubLabel
                  name="specialCleanLabel"
                  caption="特別清掃"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full">
                  <div v-for="(specialClean, index) in requestPost.specialCleans" v-bind:key="specialClean.id">
                    <div class="mt-3" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                      <label
                        name="specialCleanLabel"
                        class="text-sm font-bold"
                      >
                        特別清掃{{ outputIndex(index) }}
                      </label>
                    </div>

                    <div class="flex">
                      <div class="mw-60 mt-1" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="specialCleanContent" 
                          caption="依頼内容 ※駐輪場清掃、照明器具清掃など" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems="[]"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          :disabled="true"
                          v-model="specialClean.specialCleanContent"
                        />
                      </div>

                      <div class="mt-1" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="specialCleanCount" 
                          caption="希望回数"
                          :required="true"
                          :disabled="true"
                          v-model="specialClean.specialCleanCount"
                        />
                      </div>
                      
                      <div class="mt-1" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="specialCleanUnitName" 
                          caption="単位" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems="[]"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          :disabled="true"
                          v-model="specialClean.specialCleanUnitName"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料 図面(平面図)・写真
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="specialCleanImage" 
                        caption="添付資料 図面(平面図)・写真"
                        :value="requestPost.specialCleanImage" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料 図面(平面図)・写真2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="specialCleanImage2" 
                        caption="添付資料 図面(平面図)・写真2"
                        :value="requestPost.specialCleanImage2" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('特別清掃') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    添付資料 図面(平面図)・写真3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="specialCleanImage3" 
                        caption="添付資料 図面(平面図)・写真3"
                        :value="requestPost.specialCleanImage3" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <InputGroupSubLabel
                  name="roundSubLabel"
                  caption="ラウンドサブメニュー"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('ラウンドサブメニュー') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full">
                  <div v-for="(rs, index) in requestPost.roundSubs" v-bind:key="rs.id">
                    <div class="mt-3" v-show="isShowEstimationCleanCategoryItem('ラウンドサブメニュー') && isShowCategoryItem('見積依頼')">
                      <label
                        name="roundSubs"
                        class="text-sm font-bold"
                      >
                        ラウンドサブメニュー{{ outputIndex(index) }}
                      </label>
                    </div>

                    <div class="flex">
                      <div class="mw-60 mt-1" v-show="isShowEstimationCleanCategoryItem('ラウンドサブメニュー') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="roundSubContent" 
                          caption="依頼内容" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems=[]
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          v-model="rs.roundSubContent"
                          :disabled="true"
                        />
                      </div>

                      <div class="mt-1" v-show="isShowEstimationCleanCategoryItem('ラウンドサブメニュー') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="roundSubCount" 
                          caption="希望回数"
                          :required="true"
                          v-model="rs.roundSubCount"
                          :disabled="true"
                        />
                      </div>
                      
                      <div class="mt-1" v-show="isShowEstimationCleanCategoryItem('ラウンドサブメニュー') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="roundSubUnitName" 
                          caption="単位" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems=[]
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          v-model="rs.roundSubUnitName"
                          :disabled="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <InputGroupSubLabel
                  name="estimationMRSabel"
                  caption="MRS"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationCleanCategoryItem('マンションローテーションサービス（MRS）') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationCleanCategoryItem('マンションローテーションサービス（MRS）') && isShowCategoryItem('見積依頼')">
                  <vsa-list>
                    <vsa-item>
                      <vsa-heading>
                        メモ
                      </vsa-heading>

                      <vsa-icon>
                        <span>∨</span>
                      </vsa-icon>

                      <vsa-content>
                        <WmsTextInput 
                          name="estimationMRSMemo" 
                          :multiline="true"
                          :rows="4"
                          v-model="requestPost.estimationMRSMemo"
                          :disabled="true"
                        />
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>

                <InputGroupSubLabel
                  name="fireInspectLabel"
                  caption="消防設備点検"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationInspectCategoryItem('消防設備点検') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('消防設備点検') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="fireInspectReport" 
                        caption="過去の報告書"
                        :value="requestPost.fireInspectReport" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('消防設備点検') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="fireInspectReport2" 
                        caption="過去の報告書2"
                        :value="requestPost.fireInspectReport2" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('消防設備点検') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="fireInspectReport3" 
                        caption="過去の報告書3"
                        :value="requestPost.fireInspectReport3" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <InputGroupSubLabel
                  name="constructionEquipmentInspectLabel"
                  caption="建築設備定期検査"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationInspectCategoryItem('建築設備定期検査') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('建築設備定期検査') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    各階の平面図及び面積表及び確認済証
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="constructionEquipmentInspectReport" 
                        caption="各階の平面図及び面積表及び確認済証"
                        :value="requestPost.constructionEquipmentInspectReport" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('建築設備定期検査') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    各階の平面図及び面積表及び確認済証2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="constructionEquipmentInspectReport2" 
                        caption="各階の平面図及び面積表及び確認済証2"
                        :value="requestPost.constructionEquipmentInspectReport2" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('建築設備定期検査') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    各階の平面図及び面積表及び確認済証3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="constructionEquipmentInspectReport3" 
                        caption="各階の平面図及び面積表及び確認済証3"
                        :value="requestPost.constructionEquipmentInspectReport3" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <InputGroupSubLabel
                  name="specificInspectLabel"
                  caption="特定建築物定期調査"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationInspectCategoryItem('特定建築物定期調査') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('特定建築物定期調査') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    各階の平面図及び面積表及び確認済証
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="specificInspectReport" 
                        caption="各階の平面図及び面積表及び確認済証"
                        :value="requestPost.specificInspectReport" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('特定建築物定期調査') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    各階の平面図及び面積表及び確認済証2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="specificInspectReport2" 
                        caption="各階の平面図及び面積表及び確認済証2"
                        :value="requestPost.specificInspectReport2" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('特定建築物定期調査') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    各階の平面図及び面積表及び確認済証3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="specificInspectReport3" 
                        caption="各階の平面図及び面積表及び確認済証3"
                        :value="requestPost.specificInspectReport3" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <InputGroupSubLabel
                  name="evInspectLabel"
                  caption="エレベーター保守点検"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <EvInspectContentSelect
                    name="evInspectContent"
                    caption="点検内容"
                    v-model="requestPost.evInspectContent"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-3" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <WmsSuggestInput
                    name="evQty" 
                    caption="回数" 
                    v-model="requestPost.evQty"
                    :disabled="true"
                    />
                </div>

                <div class="sm:col-span-3" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <WmsSuggestInput
                    name="evUnitName" 
                    caption="単位" 
                    v-model="requestPost.evUnitName"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="evInspectFile" 
                        caption="過去の報告書"
                        :value="requestPost.evInspectFile" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書2
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="evInspectFile2" 
                        caption="過去の報告書2"
                        :value="requestPost.evInspectFile2" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <label class="block text-xs font-bold text-gray-700">
                    過去の報告書3
                  </label>
                  <div class="mt-1">
                    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                      <WmsAttachment 
                        name="evInspectFile3" 
                        caption="過去の報告書3"
                        :value="requestPost.evInspectFile3" 
                        style="max-width: none!important;"
                        :readonly="true"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="evInspectMaker" 
                    caption="メーカー"
                    v-model="requestPost.evInspectMaker"
                    :disabled="true"
                  />
                </div>

                <div class="sm:col-span-full" v-show="isShowEstimationInspectCategoryItem('エレベーター保守点検') && isShowCategoryItem('見積依頼')">
                  <WmsTextInput 
                    name="evInspectMakeYear" 
                    caption="製造年"
                    v-model="requestPost.evInspectMakeYear"
                    :disabled="true"
                  />
                </div>

                <InputGroupSubLabel
                  name="otherWorkLabel"
                  caption="その他作業"
                  class="mt-4 sm:col-span-full"
                  v-show="isShowEstimationOtherCategoryItem('その他作業') && isShowCategoryItem('見積依頼')"
                />

                <div class="sm:col-span-full">
                  <div v-for="(otherWork, index) in requestPost.otherWorks" v-bind:key="otherWork.id">
                    <div class="mt-3" v-show="isShowEstimationOtherCategoryItem('その他作業') && isShowCategoryItem('見積依頼')">
                      <label
                        name="otherWorkLabel"
                        class="text-sm font-bold"
                      >
                        その他作業{{ outputIndex(index) }}
                      </label>
                    </div>

                    <div class="flex">
                      <div class="mw-60 mt-1" v-show="isShowEstimationOtherCategoryItem('その他作業') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="otherWorkContent" 
                          caption="依頼内容" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems="[]"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          v-model="otherWork.otherWorkContent"
                          :disabled="true"
                        />
                      </div>

                      <div class="mt-1" v-show="isShowEstimationOtherCategoryItem('その他作業') && isShowCategoryItem('見積依頼')">
                        <NumericTextInput2 
                          name="otherWorkCount" 
                          caption="希望回数"
                          :required="true"
                          v-model="otherWork.otherWorkCount"
                          :disabled="true"
                        />
                      </div>
                      
                      <div class="mt-1" v-show="isShowEstimationOtherCategoryItem('その他作業') && isShowCategoryItem('見積依頼')">
                        <WmsSuggestInput
                          name="otherWorkUnitName" 
                          caption="単位" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :required="true"
                          :selectionItems="[]"
                          displayField="text"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          v-model="otherWork.otherWorkUnitName"
                          :disabled="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <!-- 過去の受注データ -->
              <OrderComponents
                v-if="isOrder && !requestPost.orderNew"
                :orderData="orderData"
                :isDisabled="disabled"
                :isTaskRequestMode="taskRequestMode"
                :estimateData="estimateData"
              />
              <!-- 新受注データ -->
              <OrderNewComponents
                v-else-if="isOrder"
                :orderData="orderData"
                :isDisabled="disabled"
                :isTaskRequestMode="taskRequestMode"
                :estimateData="estimateData"
              />
              <!-- 雇い入れ -->
              <EmploymentComponents
                ref="EmploymentComponents"
                v-if="isShowCategoryItem('雇い入れ')"
                :requestPost="requestPost"
                :referenceMode="true"
                :clientLists="clientLists"
                :departmentLists="departmentList"
              />
              <!-- 面接関連 -->
              <InterviewsComponents
                ref="InterviewsComponents" 
                v-if="isShowCategoryItem('面接関連')"
                :requestPost="requestPost"
                :referenceMode="true"
              />

              <div class="flex-1 p-4 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-4 self-start">

                <InputGroupLabel
                  name="request"
                  caption="依頼"
                  class="mt-4"
                />

                <div class="sm:col-span-full">
                  <WmsSuggestInput
                    name="taskRequestSet"
                    caption="タスク依頼セット"
                    displayField="name"
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="taskRequestSetMasterList"
                    :filter="{}"
                    :disabled="!isMainStaff"
                    value="_id"
                    v-model="taskRequestSet"
                    @selected="selectedTaskRequestSet($event, true)"
                  />
                </div>

                <!-- タスク追加ボタン -->
                <div class="sm:col-span-full addButton-contents" v-if="isMainStaff">
                  <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'依頼追加'" @click="addTask">
                    <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                  </PrimaryButton>
                </div>

                <div class="sm:col-span-full">
                  <div v-for="(task, index) in tasks" v-bind:key="task.id">
                    <!-- <div v-if="index != 0" class="border border-gray-300"></div> -->

                    <div class="flex mt-6" v-tooltip="getTaskTooltip(index)">
                      <div class="sm:col-span-full flex mr-2 mt-1" style="flex-direction:column">
                        <PrimaryButton class="normalButtonColor mb-1" size="other" :buttonContents="'要'" @click="taskDisplayOrderUp(index)" v-if="index > 0">
                          <Icon slot="before" iconName="ArrowUp" iconType="outline" :strokeWidth="2" class="h-4 w-4" />
                        </PrimaryButton>

                        <PrimaryButton class="normalButtonColor" size="other" :buttonContents="'要'" @click="taskDisplayOrderDown(index)" v-if="index < tasks.length -1" :class="index > 0 ? '': 'mt-7'">
                          <Icon slot="before" iconName="ArrowDown" iconType="outline" :strokeWidth="2" class="h-4 w-4" />
                        </PrimaryButton>
                      </div>

                      <div>
                        <WmsSuggestInput
                          name="selectTaskRequest"
                          caption="タスク"
                          displayField="name"
                          class="task-contents" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :selectionItems="taskRequestMasterList"
                          :filter="{}"
                          :readonly="false"
                          :required="true"
                          :disabled="!checkCanEdit(index)"
                          :error="!validateRequestTask(index).result"
                          :errorMessage="validateRequestTask(index).message"
                          value="_id"
                          v-model="task.requestTask"
                          @selected="selectedTaskRequest($event, task, index)"
                        />
                      </div>

                      <div>
                        <WmsSuggestInput
                          name="departmentName" 
                          caption="部署" 
                          class="task-contents"
                          :dynamic="false"
                          :incrementalSearch="false"
                          :selectionItems="departmentList"
                          displayField="name"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :required="true"
                          :clearable="isModileDevice"
                          :disabled="!checkCanEdit(index)"
                          :error="!validateDepartmentName(index).result"
                          :errorMessage="validateDepartmentName(index).message"
                          v-model="task.departmentName"
                          @selected="selectedSelectDepartmentName($event, task)"
                          @change="changedSelectDepartmentName($event, task)"
                        />
                      </div>

                      <div>
                        <WmsSuggestInput
                          name="toStaff" 
                          caption="担当者" 
                          class="task-contents" 
                          :dynamic="false"
                          :incrementalSearch="false"
                          :selectionItems="getStaffNameList(task.departmentId)"
                          displayField="userName"
                          :filter="{}"
                          :readonly="isModileDevice"
                          :required="true"
                          :clearable="isModileDevice"
                          :disabled="!checkCanEdit(index)"
                          :error="!validateToStaffName(index).result"
                          :errorMessage="validateToStaffName(index).message"
                          v-model="task.toStaffName"
                          @selected="selectedSelectByUser($event, task)"
                          @change="changedSelectByUser($event, task)"
                          v-if="!requestPost.category.includes('非公開依頼')"
                        />
                        <WmsSelect
                          name="toStaff" 
                          caption="担当者" 
                          class="task-contents"
                          v-model="task.toStaffValue"
                          :selections="getStaffList(task.departmentId)" 
                          :readonly="isModileDevice"
                          :clearable="isModileDevice"
                          :required="true"
                          :disabled="!checkCanEdit(index)"
                          :error="!validateToStaffName(index).result"
                          :errorMessage="validateToStaffName(index).message"
                          @change="changeToStaff($event, task)"
                          v-if="requestPost.category.includes('非公開依頼')"
                        />
                      </div>

                      <!-- 期限：編集権限ある場合 -->
                      <div v-if="checkCanEdit(index)">
                        <WmsDateInput
                          name="limitDate"
                          caption="期限"
                          class="task-contents" 
                          v-model="task.limitDate"
                          :readonly="isModileDevice"
                          :required="true"
                          :clearable="isModileDevice"
                          :min="minLimitDate"
                          :max="requestPost.limitDate"
                          :error="!validateLimitDate(index).result"
                          :errorMessage="validateLimitDate(index).message"
                          :availableDate="getLimitDate(task._id)"
                        />
                      </div>
                      <!-- 期限：編集権限ない場合 -->
                      <div v-else>
                        <WmsTextInput 
                          name="limitDate" 
                          caption="期限"
                          v-model="task.limitDate"
                          class="w-40"
                          :disabled="true"
                        />
                      </div>

                      <!-- 削除ボタン -->
                      <div class="deleteButton-contents mt-5" v-if="checkCanEdit(index)">
                        <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeTask(index)">
                          <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                        </PrimaryButton>
                      </div>
                    </div>

                    <div v-if="task.memo" class="ml-11 text-xs text-red-600 font-bold">{{ task.memo }}</div>

                    <div class="flex border-left text-center mt-2 px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-owner">
                      <input
                        type="radio"
                        :id="'finalCompletionTask' + index"
                        value="1"
                        name="finalCompletionTask"
                        class="my-4 cursor-pointer"
                        v-model="task.finalCompletionTask"
                        :disabled="!checkCanEdit(index)"
                        :error="!validateFinalCompletionTask(index).result"
                        :errorMessage="validateFinalCompletionTask(index).message"
                        @click="clickedFinalCompletionTask(index)"
                      />
                      <label class="cursor-pointer pl-6 pr-24 py-4" :for="'finalCompletionTask' + index">最終完了タスク</label>

                      <!-- 完了ボタン -->
                      <div v-if="task._id">
                        <PrimaryButton
                          :text="task.status=='open'?'完了にする':'未完了に戻す'"
                          :class="checkCanEdit(index, true)?task.status=='open'?'':'bg-blue-100 text-blue-800 border-blue-800':'bg-gray-400'"
                          :disabled="!checkCanEdit(index, true)"
                          @click="changeStatus(task)"
                        >
                          <Icon slot="before" :iconName="task.status=='open'?'Check':'Reply'" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                        </PrimaryButton>
                      </div>
                      <div v-if="task.status!='open'" class="ml-3 text-sm font-bold done-info text-blue-800">{{ getDoneInfo(task) }}</div>
                    </div>

                    <table class="border border-gray-200 rounded ml-7" v-if="task.requestTask.indexOf('見積作成') > -1">
                      <thead>
                        <th class="text-gray-500 px-4 text-center text-xs font-medium tracking-wider bg-gray-50 border-gray">
                          <div class="relative" style="top: 8px; left: -20px;"><InputIcon v-if="!validateOwner(index).result" iconName="ExclamationCircle" :iconColor="'red'" :message="validateOwner(index).message" /></div>
                          オーナー契約（初期値）<span class="-top-0.5 ml-1 text-red-500"> * </span>
                        </th>
                        <td class="border-left bg-white text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray">
                          <div class="m-auto flex justify-around">
                            <form class="ml-4">
                              <input
                                type="radio"
                                :id="'no' + index"
                                value="なし"
                                class="mt-2 mb-2 mr-2 cursor-pointer"
                                v-model="task.owner"
                                :disabled="!checkCanEdit(index)"
                              />
                              <label :for="'no' + index" class="cursor-pointer py-5 pl-6 pr-10">なし</label>
                            </form>
                            <form class="">
                              <input
                                type="radio"
                                :id="'yes' + index"
                                value="あり"
                                class="mt-2 mb-2 mr-2 cursor-pointer"
                                v-model="task.owner"
                                :disabled="!checkCanEdit(index)"
                              />
                              <label :for="'yes' + index" class="cursor-pointer py-5 pl-6 pr-10">あり</label>
                            </form>
                          </div>
                        </td>
                      </thead>
                    </table>

                    <table class="border border-gray-200 rounded ml-7 mt-3" v-if="task.requestTask.indexOf('見積作成') > -1">
                      <thead>
                        <th class="text-gray-500 px-4 text-center text-xs font-medium tracking-wider bg-gray-50 border-gray">
                          <div class="relative" style="top: 8px; left: -20px;"><InputIcon v-if="!validateCommission(index).result" iconName="ExclamationCircle" :iconColor="'red'" :message="validateCommission(index).message" /></div>
                          手数料<span class="-top-0.5 ml-1 text-red-500"> * </span>
                        </th>
                        <td class="border-left bg-white text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray">
                          <div class="m-auto flex justify-around">
                            <form class="ml-4">
                              <input
                                type="radio"
                                :id="'comNo' + index"
                                value="なし"
                                class="mt-2 mb-2 mr-2 cursor-pointer"
                                v-model="task.commission"
                                :disabled="!checkCanEdit(index)"
                              />
                              <label :for="'comNo' + index" class="cursor-pointer py-5 pl-6 pr-10">なし</label>
                            </form>
                            <form class="">
                              <input
                                type="radio"
                                :id="'comYes' + index"
                                value="あり"
                                class="mt-2 mb-2 mr-2 cursor-pointer"
                                v-model="task.commission"
                                :disabled="!checkCanEdit(index)"
                              />
                              <label :for="'comYes' + index" class="cursor-pointer py-5 pl-6 pr-10">あり</label>
                            </form>
                          </div>
                        </td>
                      </thead>
                    </table>

                    <div class="w-fit border border-gray-200 bg-white rounded ml-7 mt-3 py-2 px-4 text-gray-500 text-xs" v-if="task.requestTask.indexOf('見積作成') > -1&&requestPost.newClass.includes('新築')" v-tooltip="'竣工が1年後ごとに金額が5％増になる計算が入ります。見積作成時に変更可能です。'">
                      <!-- 竣工年後 -->
                      <div class="flex">
                        <div class="font-medium my-auto w-40">
                          <div class="relative" style="top: 8px; left: -20px;"><InputIcon v-if="(!task.status || task.status != 'done') && !task.finishPoint" iconName="ExclamationCircle" :iconColor="'red'" message="竣工は必須入力です。竣工を入力してください。" /></div>
                          竣工（初期値）<span class="-top-0.5 ml-1 text-red-500"> * </span>
                        </div>
                        <div class="flex">
                          <input
                            type="number"
                            name="finishPoint" 
                            class="w-16 py-1 px-2 border border-gray-300 rounded-md focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                            v-model="task.finishPoint"
                            :disabled="!checkCanEdit(index)"
                            @change="changeFinishPoint(index)"
                          />
                          <div class="ml-1 mr-2 my-auto">年後 （割増計算不要なら「0」を入力）</div>
                        </div>
                      </div>
                      <!-- 竣工特記事項 -->
                      <div class="flex mt-1">
                        <div class="font-medium my-auto w-40">
                          竣工年月（特記事項に使用）
                        </div>
                        <div class="flex">
                          <input
                            type="number"
                            name="finishYear" 
                            class="w-16 py-1 px-2 border border-gray-300 rounded-md focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                            v-model="task.finishYear"
                            :disabled="!checkCanEdit(index)"
                          />
                          <div class="ml-1 mr-2 my-auto">年</div>
                        </div>
                        <div class="flex">
                          <input
                            type="text"
                            inputmode="numeric"
                            name="finishMonth" 
                            class="w-16 py-1 px-2 border border-gray-300 rounded-md focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                            v-model="task.finishMonth"
                            :disabled="!checkCanEdit(index)"
                          />
                          <div class="ml-1 mr-2 my-auto">月</div>
                        </div>
                      </div>
                    </div>

                    <div class="sm:col-span-full ml-7 mt-3 estimate-category" v-if="task.requestTask.indexOf('見積作成') > -1">
                      <EstimationCleanCategorySelect
                        name="estimationCleanCategory"
                        caption="御見積_清掃カテゴリー(複数選択可)" 
                        v-model="task.estimationCleanCategory"
                        :disabled="!checkCanEdit(index)"
                      />
                    </div>

                    <div class="sm:col-span-full ml-7 mt-3 estimate-category" v-if="task.requestTask.indexOf('見積作成') > -1">
                      <EstimationInspectCategorySelect
                        name="estimationInspectCategory"
                        caption="御見積_点検カテゴリー(複数選択可)" 
                        v-model="task.estimationInspectCategory"
                        :disabled="!checkCanEdit(index)"
                      />
                    </div>

                    <div class="ml-7 mt-3 mb-2 estimate-category" v-if="task.requestTask.indexOf('見積作成') > -1">
                      <EstimationOtherCategorySelect
                        name="estimationOtherCategory"
                        caption="御見積_その他カテゴリー"
                        v-model="task.estimationOtherCategory"
                        :disabled="!checkCanEdit(index)"
                      />
                    </div>

                    <!-- 詳細 -->
                    <div v-if="task.details && task.details.length" class="px-5">

                      <div class="font-bold text-gray-600 text-sm w-full flex ml-2">
                        <!-- 開閉 -->
                        <div class="cursor-pointer w-52 text-xs py-1" @click="openClose(task)">
                          <span v-if="!task.detailsOpen">▶</span><span v-else>▼</span><span class="ml-5">詳細</span>
                        </div>
                        <!-- 追加 -->
                        <div v-if="task.detailsOpen&&checkCanEdit(index)" class="cursor-pointer px-4 ml-52" v-tooltip="'詳細を追加'" @click="addDetail(task)">
                          <Icon iconName="PlusCircle" iconType="solid" :strokeWidth="2" class="h-6 w-6 text-blue-700" />
                        </div>
                      </div>

                      <div v-if="task.detailsOpen">
                        <div v-for="(d, i) in task.details" :key="'detail'+i" class="flex">
                          <!-- 詳細完了チェックボックス -->
                          <label :for="index + 'det' + i" class="my-auto pt-2.5 pl-2 pb-2 pr-3">
                            <input
                              type="checkbox"
                              :id="index + 'det' + i"
                              class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                              v-model="d.isDone"
                              :disabled="!checkCanEdit(index)"
                              @change="changeIsDone(task, d)"
                            />
                          </label>
                          <!-- 詳細項目 -->
                          <div class="w-400px">
                            <WmsTextInput
                              :name="'d' + i"
                              :disabled="!checkCanEdit(index)||d.isDone"
                              v-model="d.task"
                            />
                          </div>
                          <!-- 詳細削除 -->
                          <div v-if="checkCanEdit(index)&&!d.isDone" class="cursor-pointer ml-2 my-auto pt-1 pr-5" @click="removeDetail(task, i)">
                            <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5 text-gray-500" />
                          </div>
                          <div v-if="d.isDone" class="ml-3 text-sm font-bold done-info text-blue-800">{{ getDoneInfo(d) }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="isDetailBtn&&checkCanEdit(index)" class="px-6 text-xs text-blue-700 cursor-pointer" @click="addDetail(task)">
                      詳細を追加
                    </div>
                    <div class="text-transparent">{{reload}}</div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div v-if="!initialized" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">Loading...</p>
          </div>

        </div>
      </div>
    </main>

    <DataSelectorModal
      v-model="dataSelectorShow"
      :title="dataSelectorParams.title"
      :subtitle="dataSelectorParams.subtitle"
      :options="dataSelectorParams.options"
      :selectCallback="dataSelectorParams.selectCallback"
    />

</div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import DataSelectorModal from '@components/DataSelectorModal.vue'

import Category2Select from '../components/Category2Select.vue'
import CategorySelect from '../components/CategorySelect.vue'
import NewNowFlagSelect from '../components/NewNowFlagSelect.vue'
import NewClassSelect from '../components/NewClassSelect.vue'
import EstimationCleanCategorySelect from '../components/EstimationCleanCategorySelect.vue'
import EstimationInspectCategorySelect from '../components/EstimationInspectCategorySelect.vue'
import EstimationOtherCategorySelect from '../components/EstimationOtherCategorySelect.vue'

import InputGroupLabel from '../components/InputGroupLabel.vue'
import AvailablePowerFlagSelect from '../components/AvailablePowerFlagSelect.vue'
import ParkingFlagSelect from '../components/ParkingFlagSelect.vue'
import AvailableToiletFlagSelect from '../components/AvailableToiletFlagSelect.vue'
import ApartmentTypeSelect from '../components/ApartmentTypeSelect.vue'
import DustRemovalSelect from '../components/DustRemovalSelect.vue'
import DustCountSelect from '../components/DustCountSelect.vue'
import CleanCountSelect from '../components/CleanCountSelect.vue'
import PlusDustRemovalSelect from '../components/PlusDustRemovalSelect.vue'
import EverydayStartTimeFlagSelect from '../components/EverydayStartTimeFlagSelect.vue'
import ManagerWorkTimeFlagSelect from '../components/ManagerWorkTimeFlagSelect.vue'
import ManagerWorkHolidayFlagSelect from '../components/ManagerWorkHolidayFlagSelect.vue'

import EverydayHolidayFlagSelect from '../components/EverydayHolidayFlagSelect.vue'
import EverydayHolidaySelect from '../components/EverydayHolidaySelect.vue'
import InputGroupSubLabel from '../components/InputGroupSubLabel.vue'

import ManagerWorkHolidaySelect from '../components/ManagerWorkHolidaySelect.vue'
import GlassCleanWorkWaySelect from '../components/GlassCleanWorkWaySelect.vue'
import EvInspectContentSelect from '../components/EvInspectContentSelect.vue'
import ResidenceTypeSelect from '../components/ResidenceTypeSelect.vue'
import ToiletCleaningSelect from '../components/ToiletCleaningSelect.vue'
import EmploymentComponents from '../components/EmploymentComponents.vue'
import InterviewsComponents from '../components/InterviewsComponents.vue'

import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import NumericTextInput2 from '@components/NumericTextInput2.vue'
import WmsTimeInput2 from '@wmscomponents/WmsTimeInput2.vue'
import DentatsuCategorySelect from '../components/DentatsuCategorySelect.vue'

import WmsAttachment from '@wmscomponents/WmsAttachment.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import WmsSelect from '@wmscomponents/WmsSelect.vue'

// import * as helper from '@libs/helper'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'

import * as requestPostManager from '@managers/requestPostManager'
import * as taskRequestManager from '@managers/taskRequestManager'
import * as estimateManager from '@managers/estimateManager'
import * as orderManager from '@managers/orderManager'

import LoadingIcon from '@assets/loading-circle.svg' 

import * as logManager from '@managers/logManager'
import * as backend from '@libs/backend'
import * as moment from 'moment'
import InputIcon from '@components/InputIcon.vue'
import OrderComponents from '@apps/Order/components/OrderComponents.vue'
import OrderNewComponents from '@apps/Order/components/OrderNewComponents.vue'
import PageModel from '../../main/pages/PageModel.vue'

import { TASK_REQUEST_FEATURES } from '@/config'
import { SUB_CHANGED } from '@/config'
import { SUB_DELETE } from '@/config'
import { PRIVATE_REQUEST_AUTH } from '@/config'
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon
} from 'vue-simple-accordion'
import '@components/VueSimpleAccordion.scss'

export default {
  extends: PageModel,
  components: {
    PageHeader,
    Icon,
    PrimaryButton,
    Category2Select,
    CategorySelect,
    NewNowFlagSelect,
    NewClassSelect,
    EstimationCleanCategorySelect,
    EstimationInspectCategorySelect,
    EstimationOtherCategorySelect,
    ManagerWorkHolidayFlagSelect,

    InputGroupLabel,
    AvailablePowerFlagSelect,
    ParkingFlagSelect,
    AvailableToiletFlagSelect,
    ApartmentTypeSelect,
    DustRemovalSelect,
    DustCountSelect,
    CleanCountSelect,
    PlusDustRemovalSelect,
    EverydayStartTimeFlagSelect,
    ManagerWorkTimeFlagSelect,
    EverydayHolidayFlagSelect,
    EverydayHolidaySelect,
    InputGroupSubLabel,
    ManagerWorkHolidaySelect,
    GlassCleanWorkWaySelect,
    EvInspectContentSelect,
    ResidenceTypeSelect,
    ToiletCleaningSelect,
    WmsSuggestInput,
    WmsTextInput,
    NumericTextInput2,
    WmsTimeInput2,
    DentatsuCategorySelect,
    EmploymentComponents,
    InterviewsComponents,
    
    WmsAttachment,
    WmsDateInput,

    DataSelectorModal,
    LoadingIcon,
    InputIcon,
    OrderComponents,
    OrderNewComponents,
    WmsSelect,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon
  },

  data() {
    return {
      requestPostId: '',
      taskRequestRequestNo: '',
      tasks: [],
      removeTasks: [],
      originalTasks: [],
      requestPost: {},
      editMode: false,
      taskRequestSet: '',
      taskRequestSetMasterList: [],
      taskRequestMasterList: [],
      departmentList: [],

      toDepartmentList: [],
      toStaffNameList: [],

      dataSelectorShow: false,
      dataSelectorParams: {
        title: '',
        subtitle: '',
        options: {
          formId: '',
          displayFields: [],
          sortField: '',
          selectable: true,
          filter: {}
        }
      },
      dispLimitDate: '',
      dispRequestDate: '',

      isSaving: false,
      initialized: false,
      isCategory: false,
      minLimitDate: '',
      isClientRequired: true,
      // 削除する見積りに紐づくタスクID
      deleteEstimate: [],
      orderId: '',
      isOrder: false,
      orderData: {},
      disabled: false,
      taskRequestMode: false,
      isDentasuRequired: true,
      isTransmission: false,
      isBuildingDetailsRequired: true,
      // 編集権限があるか
      canEdit: false,
      estimateData: {},
      // チャット通知を送るサブタスクデータ
      // sendChatTasks: [],
      // 比較用データ
      // comparisonTasks: [],
      deregulationDate: [],
      // 日常、管理員の回数・時間の小数第●位
      deci: 1,
      // 得意先リスト
      clientLists: [],
      // 即時反映用
      reload: 0,
      // 「詳細を追加」要否
      isDetailBtn: false
    }
  },

  computed: {
    isModileDevice() {
      return utils.deviceInfo.isMobile
    },

    isEdit() {
      return this.editMode
    },

    validateRequestTask: function() {
      return function(index) {
        if (!this.tasks.length) {
          return true
        } else if (!this.tasks[index].requestTask) {
          return {
            result: false,
            message: 'タスクは必須入力です。タスクを入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateDepartmentName: function() {
      return function(index) {
        if (!this.tasks.length) {
          return true
        } else if (!this.tasks[index].departmentName) {
          return {
            result: false,
            message: '部署は必須入力です。部署を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateToStaffName: function() {
      return function(index) {
        if (!this.tasks.length) {
          return true
        } else if (!this.tasks[index].toStaffName) {
          return {
            result: false,
            message: '担当者は必須入力です。担当者を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateLimitDate: function() {
      return function(index) {
        if (!this.tasks.length) {
          return true
        } else if (!this.tasks[index].limitDate) {
          return {
            result: false,
            message: '期限は必須入力です。期限を入力してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateOwner: function() {
      return function(index) {
        if (!this.tasks.length) {
          return true
        } else if (!this.tasks[index].owner) {
          return {
            result: false,
            message: 'オーナー契約は必須入力です。オーナー契約を選択してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateCommission: function() {
      return function(index) {
        if (!this.tasks.length) {
          return true
        } else if (!this.tasks[index].commission) {
          return {
            result: false,
            message: '手数料は必須入力です。手数料を選択してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    validateFinalCompletionTask: function() {
      return function() {
        if (!this.tasks.length) {
          return true
        } else if (!this.tasks.some((task) => task.finalCompletionTask == '1')) {
          return {
            result: false,
            message: '最終完了タスクはいずれか1つは入力必須です。最終完了タスクを選択してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      }
    },

    /**
     * ユーザー名
     */
    userName() {
      return this.$store.getters.user.user_name
    },

    /**
     * サブタスク権限
     */
    subAuth() {
      return this.$store.getters.subAuth
    },

    /**
     * ログインユーザーが権限者か、メインの担当者か確認し、編集権限があるか判定
     */
    isMainStaff() {
      // 新規の場合
      if (!this.editMode) {
        return true
      }

      // 削除済み
      if (this.requestPost.status == 'remove') {
        return false
      }

      // 非公開案件の場合は、担当者、もしくは依頼者のみ編集可
      if (this.requestPost.category.includes('非公開依頼')) {
        return (this.requestPost.toStaffId == this.$store.getters.user.id
          || this.requestPost.byUserId == this.$store.getters.user.id)
      }

      // ログインユーザー名
      let name = this.$store.getters.user.user_name
      name = utils.deleteSpace(utils.deleteKana(name))

      if (!this.requestPost.category.includes('非公開依頼')) {
        // 非公開依頼でなければ、管理者権限はOK
        const auth = this.$store.getters.auth
        if (auth.adminAuth) {
          return true
        }
      } else {
        // 非公開依頼の場合、特定ユーザーのみOK
        if (PRIVATE_REQUEST_AUTH.includes(this.$store.getters.user.id)) return true
      }

      // ログインユーザーがメイン担当者の場合、OK
      let tanto = utils.deleteSpace(utils.deleteKana(this.requestPost.toStaffName))
      if (name == tanto) {
        return true
      }

      // ログインユーザーがメイン依頼者の場合、OK
      let irai = utils.deleteSpace(utils.deleteKana(this.requestPost.byUser))
      if (name == irai) {
        return true
      }
      
      // メイン担当者が未定の場合、同じ部署の人はOK
      if (tanto == '未定') {
        let d = this.$store.getters.loginUserData
        if (d.departmentName == this.requestPost.toDepartment || d.departmentIdUser == this.requestPost.toDepartmentId) {
          return true
        }
        // デンタツの場合
        // デンタツの全データ取得
        let resDT = this.$store.getters.transDepartmentList
        // 該当のデンタツ部署のデータを取得
        let dentatsu = resDT.find((r) => {
          return (r.name == this.requestPost.toDepartment || r.departmentId == this.requestPost.toDepartmentId)
        })
        // 該当のデンタツに紐づく部署の人はOK（RLS_デンタツならRLS全員OK）
        if (dentatsu && dentatsu.transDepartment) {
          if (d.departmentIdUser == dentatsu.transDepartment) {
            return true
          }
        }
      }

      return false
    },
  },

  async created() {
    this.initialized = false
    try {
      await this.initialize()
    } finally {
      this.initialized = true
    }
  },

  methods: {
    async initialize() {
      this.minLimitDate = utils.getToday()

      if (!this.$route.query.mode || this.$route.query.mode != 1) {
        // リクエストポストのデータを取得
        this.requestPostId = this.$route.query.requestPostId
        if (this.requestPostId) {
          const requestPost = await requestPostManager.getRequest(this, this.requestPostId)
          if (!requestPost) {
            await dialogs.showErrorDialog('エラー', 'メインタスクを開くことができません。')
          } else {
            // お問い合わせカテゴリーを過去の文言を新しくする
            if (requestPost.category) {
              requestPost.category = this.setNewCategory(requestPost.category)
            }
            // 見積りカテゴリーを過去の文言を新しくする
            if (requestPost.estimationCleanCategory) {
              requestPost.estimationCleanCategory = this.setNewEstimateCategory(requestPost.estimationCleanCategory)
            }
            this.requestPost = requestPost
            this.dispLimitDate = utils.formatDateJa(requestPost.limitDate);
            this.dispRequestDate = utils.formatDateJa(requestPost.requestDate);

            // お問い合わせカテゴリーで見積依頼の選択有無によって幅を変更
            if(this.requestPost.category.includes('見積依頼') || this.requestPost.category.includes('デンタツ')) {
              this.isCategory = true
            } else {
              this.isCategory = false
            }

            // フラグ 設定
            this.category2Changed()
            this.categoryChanged()
            this.setBuildingDetailsRequired()
          }
        }

        // タスク依頼セットのリスト取得
        const taskRequestSetList = await this.$pigeon.getTasksByFormId('task_request_set_master')
        this.taskRequestSetMasterList = taskRequestSetList.filter((t) => {
          return t.classification == '1'
        })

        // タスクのリスト取得
        let taskList = await this.$pigeon.getTasksByFormId('task_master')
        this.taskRequestMasterList = taskList.filter((t) => {
          return t.classification == '1'
        })

        // タスク依頼のデータを取得
        const tr = await taskRequestManager.getByRequestPostId(this, this.requestPostId, this.requestPost.status)
        if (tr.length > 0) {
          this.editMode = true

          for (let i = 0; i < tr.length; i++) {
            const t = tr[i]
            // 見積りカテゴリーを過去の文言を新しくする
            if (t.estimationCleanCategory) {
              t.estimationCleanCategory = this.setNewEstimateCategory(t.estimationCleanCategory)
            }
          }

          this.tasks = tr

          // 複写の場合、メインにないカテゴリーは削除
          for (let i = 0; i < this.tasks.length; i++) {
            const t = this.tasks[i];
            if (t.allCopy) {
              this.deleteCategory('estimationCleanCategory', i)
              this.deleteCategory('estimationInspectCategory', i)
              this.deleteCategory('estimationOtherCategory', i)
            }
          }
        }

        // 「非公開依頼」関係者以外はメニューへリダイレクト
        if (this.requestPost.category.includes('非公開依頼') && !this.isPrivateAuthorized(this.requestPost, tr)
          && !PRIVATE_REQUEST_AUTH.includes(this.$store.getters.user.id) ) {
          dialogs.showInfoDialog('非公開依頼', '非公開依頼のため表示出来ません。メニューへ戻ります。', 'OK')  
          this.$router.push('/')
        // 雇い入れ社員用の場合、人事もしくは大野様、管理者ユーザーのみ表示
        } else if (this.requestPost.category.includes('雇い入れ') && this.requestPost.employmentRegularStaff) {
          let empAuth = await this.isRegularStaffAuthorized()
          if (!empAuth) {
          await dialogs.showInfoDialog('雇い入れ（社員）', '人事総務経理部、もしくは限られた人しか閲覧できません。\r\nメニューへ戻ります。', 'OK')  
          this.$router.push('/')
          }
        // 社員用面接の場合、大野様、管理者ユーザー、人事部、社員面接権限のあるユーザーのみ
        } else if (this.requestPost.category.includes('面接関連') && this.requestPost.interviewsRegularStaff) {
          let intAuth = await this.isRegularStaffInterviewAuthorized()
          if (!intAuth) {
          await dialogs.showInfoDialog('面接関連（社員）', '限られた人しか閲覧できません。\r\nメニューへ戻ります。', 'OK')  
          this.$router.push('/')
          }
        }

        // 雇い入れの場合、サブタスクをセット
        this.setSubtaskSignUp()

        // 雇い入れの場合、得意先リストを取得
        this.getClientLists()
      } else {
        this.orderId = this.$route.query.orderId
        if (this.orderId) {
          this.orderData = await orderManager.getOrderData(this, this.orderId)
          if (!this.orderData) {
            await dialogs.showErrorDialog('エラー', '受注を開くことができません。')
          } else {

            // お問い合わせカテゴリーを過去の文言を新しくする
            if (this.orderData.category) {
              this.orderData.category = this.setNewCategory(this.orderData.category)
            }
            this.requestPost = this.orderData
            this.estimateData = await estimateManager.getEstimate(this, this.orderData.estimateNo)
            this.dispLimitDate = utils.formatDateJa(this.orderData.limitDate);
            this.dispRequestDate = utils.formatDateJa(this.orderData.requestDate);
          }
        } else {
          await dialogs.showErrorDialog('エラー', '受注を開くことができません。')
        }

        this.selectItemWeek = [
          { text: '月', value: '月' },       
          { text: '火', value: '火' },
          { text: '水', value: '水' },
          { text: '木', value: '木' },
          { text: '金', value: '金' },
          { text: '土', value: '土' },
          { text: '日', value: '日' }
        ]

        this.selectItemMonth = [
          { text: '1月', value: '1月' },       
          { text: '2月', value: '2月' },
          { text: '3月', value: '3月' },
          { text: '4月', value: '4月' },
          { text: '5月', value: '5月' },
          { text: '6月', value: '6月' },
          { text: '7月', value: '7月' },
          { text: '8月', value: '8月' },
          { text: '9月', value: '9月' },
          { text: '10月', value: '10月' },
          { text: '11月', value: '11月' },
          { text: '12月', value: '12月' },
        ]

        // タスク依頼セットのリスト取得
        let taskRequestSetList = await this.$pigeon.getTasksByFormId('task_request_set_master')
        this.taskRequestSetMasterList = taskRequestSetList.filter((t) => {
          return t.classification == '2'
        })

        // タスクのリスト取得
        let taskList = await this.$pigeon.getTasksByFormId('task_master')
        this.taskRequestMasterList = taskList.filter((t) => {
          return t.classification == '2'
        })
  
        // タスク依頼のデータを取得
        const tr = await taskRequestManager.getByOrderId(this, this.orderId, this.requestPost.status)
        if (tr.length > 0) {
          this.editMode = true
          this.tasks = tr
        }

        this.isOrder = true
        this.disabled = true
        this.taskRequestMode = true
      }

      this.removeTasks = []

      // 詳細が設定されている場合「詳細を追加」
      for (let i = 0; i < this.tasks.length; i++) {
        if (this.tasks[i].details && this.tasks[i].details.length) {
          this.isDetailBtn = true
          break
        }
      }

      let departmentList = this.$store.getters.departmentList
      this.departmentList = departmentList

      // 制限されている日付の選択を許可するため、サブタスクの_idと期限を保管
      for (let i = 0; i < this.tasks.length; i++) {
        let arr = { _id: this.tasks[i]._id, limitDate: this.tasks[i].limitDate}
        this.deregulationDate.push(arr)
      }

      // 非公開依頼の場合は、ドロップダウン値を設定
      for (let i = 0; i < this.tasks.length; i++) {
        let val = this.$store.getters.userList.find(st => st.id == this.tasks[i].toStaffId)
          if (val) {
            this.tasks[i].toStaffValue = {
              value: val.id,
              text: val.user_name
            }
        }
      }

      // 変更有無をチェックするため、オリジナルデータを保管
      const self = this
      self.$nextTick(() => {
        self.storeOriginal()
      })
    },

    /**
     * メインにないカテゴリーをサブタスクのカテゴリーから削除
     * @param category 対象カテゴリー
     * @param i 番号
     */
    deleteCategory(category, i) {
      let t = this.tasks[i]
      if (this.requestPost[category] && this.requestPost[category].length) {
        if (t[category] && t[category].length) {
          for (let j = 0; j < t[category].length; j++) {
            const c = t[category][j];
            if (!this.requestPost[category].includes(c)) {
              t[category].splice(j, 1)
              j--
            }
          }
        }
      } else {
        t[category] = []
      }
    },

    /**
     * オリジナルデータ保管
     */
    storeOriginal() {
      this.originalTasks = utils.clone(this.tasks)
    },

    /**
     * 編集の有無 取得
     */
    isDirty() {
      return !utils.isEquals(utils.toPlainObject(this.originalTasks), utils.toPlainObject(this.tasks))
    },

    /**
     * 一覧へ遷移
     */
    async toList() {
      if (this.isDirty()) {
        const dialogResult = await dialogs.showWarningConfirmDialog('保存の確認', '変更が保存されていませんがよろしいですか？')
        if (dialogResult !== 'YES') return
      }
      this.$router.push({ name: 'RequestPostProgressList' })
    },
    
    /**
     * メインタスク オープン
     */
     async toMainTask() {
      if (this.isOrder) {
        if (this.orderId) {
          let resolvedRoute = this.$router.resolve({ 
            name: 'RequestPost',
            query: {
              mode: 6,
              orderId: this.orderId
            }
          })
          window.open(resolvedRoute.href, '_blank')
        }
      } else {
        if (this.requestPostId) {
          let resolvedRoute = this.$router.resolve({ 
            name: 'RequestPost',
            query: {
              mode: 1,
              requestPostId: this.requestPostId
            }
          })
          window.open(resolvedRoute.href, '_blank')
        }
      }
    },

    /**
     * 編集できるか確認
     */
    checkCanEdit(i, isDoneButton = false) {
      // 完了の場合 編集不可(完了ボタンは例外)
      if (this.tasks[i].status == 'done' && !isDoneButton) {
        return false
      }

      // 編集の場合に限る
      if (this.editMode) {
        // ログインユーザー名
        let name = this.$store.getters.user.user_name
        name = utils.deleteSpace(utils.deleteKana(name))

        // メインスタッフor権限者はOK
        if (this.isMainStaff) {
          return true
        }

        // 編集できる段階で他の人を割り当てたりする場合に備えて、originalTasksのデータで判定
        if (!this.originalTasks || !this.originalTasks.length) {
          return true
        }

        // ログインユーザーが担当者の場合、OK（速度改善のため、残す）
        let tanto = utils.deleteSpace(utils.deleteKana(this.originalTasks[i].toStaffName))
        if (name == tanto) {
          this.canEdit = true
          return true
        }

        // 担当者に関係なく、同じ部署の人はOK
        let d = this.$store.getters.dualPosition
        for (let j = 0; j < d.length; j++) {
          const tar = d[j]
          if (tar.departmentName == this.originalTasks[i].departmentName || tar.departmentId == this.originalTasks[i].departmentId) {
            this.canEdit = true
            return true
          }
        }
        return false
      } else {
        this.canEdit = true
        return true
      }
    },

    /**
     * タスク Tooltip 取得
     */
    getTaskTooltip(index) {
      if (this.tasks[index].status == 'done') return '完了済みのため編集できません'
      return !this.checkCanEdit(index) ? '部署が違うので編集できません' : ''
    },

    /**
     * 保存
     */
    async save() {
      console.log('Save.')

      const validationResult = await this.validation()
      if (!validationResult) {
        return
      }

      try {
        this.isSaving = true
        let result = true

        // 受注以外
        if (!this.$route.query.mode || this.$route.query.mode != 1) {
          // タスク削除
          for (const task of this.removeTasks) {
            if (task._id) {

              // subTaskNoがあれば物理削除
              if (task.subTaskNo && task.subTaskNo != '') {
                result = await taskRequestManager.deleteTask2(this, task.subTaskNo)

              // subTaskNoがなければ、ステータス以外を空にして更新
              } else {
                task.status = 'remove'
                task.requestPostId = ''
                task.taskRequestRequestNo = ''
                task.requestTask = ''
                task.departmentName = ''
                task.departmentId = ''
                task.toStaffId = ''
                task.toStaffName = ''
                task.limitDate = ''
                task.owner = ''
                task.commission = ''
                task.estimationCleanCategory = ''
                task.estimationInspectCategory = ''
                task.estimationOtherCategory = ''
                task.finalCompletionTask = ''

                await taskRequestManager.update(this, task)
              }
              // 複写で紐づく見積りも削除
              if (task.allCopy != undefined && task.allCopy) {
                let e = await estimateManager.getEstimateByTaskRequestId(this, task._id)
                if (e && e.length) {
                  for (const estimate of e) {
                    await estimateManager.deleteEstimate2(this, estimate.estimateNo)
                  }
                }
              }
              if (!result) break
            }
          }

          // 削除すべき見積り
          for (let i = 0; i < this.deleteEstimate.length; i++) {
            const de = this.deleteEstimate[i];
            let e = await estimateManager.getEstimateByTaskRequestId(this, de)
            if (e && e.length) {
              for (const estimate of e) {
                await estimateManager.deleteEstimate2(this, estimate.estimateNo)
              }
            }
          }

          // タスク登録・更新
          let newFlag = false
          // 複写の場合、複写した見積りを取得
          let copyEstimate = []

          // 未完了の見積作成依頼の数
          let estimateTaskCnt = this.tasks.filter(task => task.requestTask.indexOf('見積作成') > -1 && task.status != 'done').length

          // 未完了の見積作成依頼がある場合、手数料を確認する
          if (!await this.checkCommission(estimateTaskCnt)) {
            return
          }

          for (let i = 0; i < this.tasks.length; i++) {
            const task = this.tasks[i]
            // 並び順
            task.displayOrder = i
            if (task._id) {

              // 複写の場合
              if (task.allCopy != undefined && task.allCopy) {
                // 紐づく見積りを取得
                const estimates = await estimateManager.getEstimateByTaskRequestId(this, task._id)
                if (estimates && estimates.length) {
                  for (let e of estimates) {
                    copyEstimate.push(e)
                    // 部署ID
                    e.departmentIdSubTask = task.departmentId
                    // タスク担当者ID
                    e.taskStaffId = task.toStaffId
                    // タスク担当者
                    e.taskStaff = task.toStaffName
                    // タスク依頼内容
                    e.requestTask = task.requestTask
                    // 見積り更新
                    await estimateManager.updateEstimate(this, e)
                  }
                }

                // サブタスクの複写フラグをfalseにして保存
                task.allCopy = false
              }
              result = await taskRequestManager.update(this, task)
            } else {
              result = await taskRequestManager.register(this, task)
              newFlag = true
            }
            if (!result) break
          }

          if (result) {
            logManager.recordingByRegist(this, newFlag, 'サブタスク', 'サブタスク（お問い合わせ番号：' + this.requestPost.requestNo + '）')
            await dialogs.showSuccessDialog('サブタスクの保存', 'サブタスクを保存しました。')
            // サブタスクが全て完了した場合、メインタスクのステータスも完了にする
            await this.updateMain()
            try {
              await this.sendChatWorkMessage()
            } catch (e) {
              console.log(e)
            }

            this.initialize()
            // 未完了の見積作成依頼があり、複写ではない場合、新規見積り作成へ誘導
            if (estimateTaskCnt > 0 && !copyEstimate.length) {
              const dialogResult = await dialogs.showConfirmDialog('サブタスク', '続けて見積書を作成しますか？', 'はい', 'いいえ')
              if (dialogResult === 'YES') {
                // タスク指定(複数存在する場合は選択)
                if (estimateTaskCnt > 1) {
                  this.dataSelectorParams = {
                    title: '見積作成サブタスクの選択',
                    subtitle: '',
                    options: {
                      formId: 'task_request',
                      displayFields: [ 'requestTask', 'toStaffName', 'limitDate' ],
                      searchFields: [ 'requestTask', 'toStaffName', 'limitDate' ],
                      sortField: '_id',
                      selectable: true,
                      filter: {
                        requestPostId: this.requestPostId,
                        requestTask: {$regex:'見積作成'}
                      }
                    },
                    selectCallback: (selected) => {
                      this.dataSelectorShow = false
                      this.$router.push({
                        name: 'EstimateForm',
                        query: {
                          taskRequestId: selected._id,
                          requestPostId: selected.requestPostId
                        }
                      })
                    }
                  }
                  this.dataSelectorShow = true
                } else {
                  // 見積タスク１件
                  const tasks = await taskRequestManager.getByRequestPostId(this, this.requestPostId, null)
                  const estimateTasks = tasks.filter(task => task.requestTask.indexOf('見積作成') > -1)
                  if (estimateTasks.length > 0) {
                    this.$router.push({ 
                      name: 'EstimateForm',
                      query: {
                        taskRequestId: estimateTasks[0]._id,
                        requestPostId: estimateTasks[0].requestPostId
                      }
                    })
                  }
                }
              } else {
                this.$router.push({ name: 'RequestPostProgressList' })
              }

            // 複写した見積りがある場合
            } else if (estimateTaskCnt > 0 && copyEstimate.length > 1) {
              this.dataSelectorParams = {
                    title: '複写した見積り',
                    subtitle: '見積り画面を開きたいものを選択してください。',
                    options: {
                      formId: 'rp_estimates',
                      displayFields: [ 'requestTask', 'taskStaff', 'totalAmount' ],
                      searchFields: [ 'requestTask', 'taskStaff', 'totalAmount' ],
                      sortField: '_id',
                      selectable: true,
                      filter: {
                        requestPostId: this.requestPostId,
                        allCopy: true
                      }
                    },
                    selectCallback: (selected) => {
                      this.dataSelectorShow = false
                      this.$router.push({ 
                        name: 'EstimateForm',
                        query: {
                          estimateNo: selected.estimateNo
                        }
                      })
                    }
                  }
                  this.dataSelectorShow = true
            } else if (copyEstimate.length == 1) {
              this.$router.push({ 
                name: 'EstimateForm',
                query: {
                  estimateNo: copyEstimate[0].estimateNo
                }
              })
            } else {
                this.$router.push({ name: 'RequestPostProgressList' })
            }
          } else {
            await dialogs.showErrorDialog('サブタスクの保存', 'サブタスクを保存できませんでした。')
          }
        
          // this.storeOriginal()
        } else {
          // タスク削除
          for (const task of this.removeTasks) {
            if (task._id) {
              // 物理削除
              result = await taskRequestManager.orderTaskRequestDeleteTask(this, task.subTaskNo)
              if (!result) break
            }
          }
          // タスク登録・更新
          let newFlag = false
          for (let orderIdx = 0; orderIdx < this.tasks.length; orderIdx++) {
            const task = this.tasks[orderIdx]
            // 並び順
            task.displayOrder = orderIdx
            if (task._id) {
              result = await taskRequestManager.orderTaskRequestUpdate(this, task)
            } else {
              result = await taskRequestManager.orderTaskRequestRegister(this, task)
              newFlag = true
            }
            if (!result) break
            
            // 受注サブタスクに作業開始依頼が含まれている場合は、受注メインタスクのメモに文言を追加
            // 受注メインタスクのメモに既に作業開始連絡の文言がある場合は追加しない
            // if (task.requestTask.indexOf('作業開始連絡') > -1 && this.orderData.orderMemo.indexOf('作業開始連絡:') == -1) {
            //   let workStartContactMemo = '作業開始連絡:\n□日割連絡\n□引継情報確認入館方法担当者\n□登録情報(支店等)確認'
            //   // 受注メインタスクに既に記載がある場合は、改行入れて文言を追加
            //   if (this.orderData.orderMemo != '') workStartContactMemo = '\n\n' + workStartContactMemo
            //   this.orderData.orderMemo += workStartContactMemo

            //   await orderManager.updateOrderData(this, this.orderData)
            // }
          }
  
          if (result) {
            logManager.recordingByRegist(this, newFlag, '受注タスク依頼', '受注タスク依頼（お問い合わせ番号：' + this.requestPost.requestNo + '）')
            await dialogs.showSuccessDialog('受注タスク依頼の保存', '受注タスク依頼を保存しました。')
            // サブタスクが全て完了した場合、メインタスクのステータスも完了にする
            await this.updateMain()
            try {
              await this.sendChatWorkMessage()
            } catch (e) {
              console.log(e)
            }
  
            this.initialize()
            this.$router.push({ name: 'RequestPostProgressList' })
          } else {
            await dialogs.showErrorDialog('受注タスク依頼の保存', '受注タスク依頼を保存できませんでした。')
          }
        }

      } finally {
        this.isSaving = false
      }
    },

    /**
     * 入力値検証
     */
    async validation() {
      // 「スマイル登録」のタスクがあるか
      let isSmile = false

      for (let index = 0; index < this.tasks.length; index++) {
        // 完了していたらチェックしない
        if (this.tasks[index].status && this.tasks[index].status == 'done') {
          continue
        }

        // タスク
        if (!this.validateRequestTask(index).result) {
          await dialogs.showInfoDialog('入力確認', this.validateRequestTask(index).message, 'OK')
          return false
        }

        // 部署
        if (!this.validateDepartmentName(index).result) {
          await dialogs.showInfoDialog('入力確認', this.validateDepartmentName(index).message, 'OK')
          return false
        }

        // 担当者
        if (!this.validateToStaffName(index).result) {
          await dialogs.showInfoDialog('入力確認', this.validateToStaffName(index).message, 'OK')
          return false
        }

        // 期限
        if (!this.validateLimitDate(index).result) {
          await dialogs.showInfoDialog('入力確認', this.validateLimitDate(index).message, 'OK')
          return false
        }

        // オーナー契約
        if(this.tasks[index].requestTask.indexOf('見積作成') > -1) {
          if (!this.validateOwner(index).result) {
            await dialogs.showInfoDialog('入力確認', this.validateOwner(index).message, 'OK')
            return false
          }
        }

        // 手数料
        if(this.tasks[index].requestTask.indexOf('見積作成') > -1) {
          if (!this.validateCommission(index).result) {
            await dialogs.showInfoDialog('入力確認', this.validateCommission(index).message, 'OK')
            return false
          }
        }

        // 竣工数
        if (this.tasks[index].requestTask.indexOf('見積作成') > -1 && this.requestPost.newClass.includes('新築')) {
          if (!this.tasks[index].finishPoint) {
            const res = await dialogs.showWarningConfirmDialog('竣工が未入力です', '竣工（初期値）は０年後でよろしいでしょうか？\r\n見積り画面でも変更できます。')
            if (res != 'YES') {
              return false
            } else {
              this.tasks[index].finishPoint = '0'
            }
          }
        }

        // 「スマイル登録」がある場合
        if (this.tasks[index].requestTask.indexOf('スマイル登録') > -1) {
          isSmile = true
        }

        // 詳細タスクは、内容を記入しているものだけ保存
        if (this.tasks[index].details && this.tasks[index].details.length) {
          const details = []
          for (let i = 0; i < this.tasks[index].details.length; i++) {
            const det = this.tasks[index].details[i]
            if (det.task) {
              details.push(det)
            }
          }
          this.tasks[index].details = details
        }

        // 不要なプロパティを削除
        delete this.tasks[index].detailsOpen
      }

      // 最終完了タスク
      if (!this.validateFinalCompletionTask().result) {
        await dialogs.showInfoDialog('入力確認', this.validateFinalCompletionTask().message, 'OK')
        return false
      }
      // ステータスのチェック
      let finalStatus = false
      let otherStatus = true
      for (let i = 0; i < this.tasks.length; i++) {
        const t = this.tasks[i]
        if (t.finalCompletionTask == '1') {
          if (t.status == 'done') {
            finalStatus = true
          }
        } else {
          if (t.status == 'open') {
            otherStatus = false
          }
        }
      }
      // 最終完了タスクが完了しているのに、他が未完了の場合、保存できない
      if (finalStatus && !otherStatus) {
        await dialogs.showInfoDialog('最終完了タスクは完了できません', '未完了タスクがあるため、最終完了タスクも未完了に戻してください。', 'OK')
        return false
      }
      // 受注のサブタスクで、「日常清掃」が含まれている場合「スマイル登録」のサブタスクを含んでいるか
      if (this.$route.query.mode && this.$route.query.mode == 1) {
        // クリーンプラスもしくはダブルプラスが含まれている場合
        let plus = false
        if (this.requestPost.orderRlsPlus) {
          for (let plusIdx = 0; plusIdx < this.requestPost.orderRlsPlus.length; plusIdx++) {
            const pl = this.requestPost.orderRlsPlus[plusIdx]
            if (pl.estimationRoundPlusCategorySelect && pl.estimationRoundPlusCategorySelect.length) {
              if (pl.estimationRoundPlusCategorySelect.includes('ラウンドクリーンプラス') || pl.estimationRoundPlusCategorySelect.includes('ラウンドダブルプラス')) {
                plus = true
                break
              }
            }
          }
        }

        if ((this.requestPost.estimationDetailsCategory && this.requestPost.estimationDetailsCategory.includes('日常清掃')) || plus) {
          if (!isSmile) {
            const res = await dialogs.showWarningConfirmDialog('「スマイル登録」を追加してください', '「日常清掃」、「クリーンプラス」、「ダブルプラス」が含まれる場合はサブタスクに「スマイル登録」を追加してください。', '追加', '不要')
            if (res == 'YES') {
              // 「スマイル登録」を追加
              this.addTask()
              this.tasks[this.tasks.length - 1].requestTask = 'スマイル登録'
              return false
            }
          }
        }
      }
      return true
    },

    /**
     * 未完了の見積作成依頼がある場合、手数料を確認する
     * @param estimateTaskCnt 未完了の見積作成依頼の数
     */
    async checkCommission(estimateTaskCnt) {
      // 未完了の見積作成依頼がある場合
      if (estimateTaskCnt > 0) {
        // 手数料マスタの設定値
        let rate = 0
        // 手数料マスタのデータ
        let mst = {}
        const result = await backend.searchData('estimateCommissionRate/getCommissionMst', { clientCode: this.requestPost.clientCode, clientAddress: this.requestPost.clientAddress1 + this.requestPost.clientAddress2 })
        // 請求先マスタに該当の得意先があった場合
        if (result.data.data && result.data.data[0]) {
          rate = result.data.data[0].rate
          mst = result.data.data[0]
        // ない場合は、新規の得意先なので、警告なし
        } else {
          return true
        }
        let flg = false
        for (let i = 0; i < this.tasks.length; i++) {
          const t = this.tasks[i]
          if (t.requestTask.indexOf('見積作成') > -1) {
            if (t.commission == 'あり') {
              flg = true
              break
            }
          }
        }

        // マスタに設定値ありだが、手数料なしで登録しようとしている場合
        let name = mst.clientName
        if (mst.clientName2) {
          name += '（' + mst.clientName2 + '）'
        }
        let memo = ''
        if (mst.memo) {
          memo = `（備考：${mst.memo}）`
        }
        if (rate && !flg) {
          const res = await dialogs.showWarningConfirmDialog('手数料は「なし」でよろしいですか？', `${name} は\r\n手数料マスタにて、手数料 ${rate}％ ${memo}となっています。`)
          if (res != 'YES') {
            return false
          }
        } else if (!rate && flg) {
          const res = await dialogs.showWarningConfirmDialog('手数料は「あり」でよろしいですか？', `${name} は\r\n手数料マスタにて、手数料 なし となっています。`)
          if (res != 'YES') {
            return false
          }
        }
      }
      return true
    },

    /**
     * chatworkへ通知 送信
     */
    async sendChatWorkMessage() {

      let noRoomId = []
      let noToken = []

      // チャット送信設定値
      const feature = TASK_REQUEST_FEATURES.TASK_REQUEST.SEND_CHATWORK_MESSAGE_WHEN_REQUESTING_TASKS

      // 設定がOFF、もしくは雇入れの場合
      if (!feature || !feature.enable || (this.requestPost.category && this.requestPost.category[0] == '雇い入れ')) {
        return
      }

      // ログインしているユーザー名を取得
      const loginUser = this.$store.getters.user.user_name
      // メイン担当者
      const mainStaff = utils.deleteKana(this.requestPost.toStaffName)
      // メイン依頼者
      const byUser = utils.deleteKana(this.requestPost.byUser)
      const byUserId = this.requestPost.byUserId

      // 削除されたデータ
      for (let i = 0; i < this.removeTasks.length; i++) {
        const r = this.removeTasks[i]
        // サブ担当者
        let subStaff = utils.deleteKana(r.toStaffName)
        let subStaffId = r.toStaffId

        // ログインユーザとサブ担当者が違ったら、チャット送信
        if (subStaff != utils.deleteKana(loginUser)) {
          // サブ担当者へ
          let res1 = await this.sendChat(SUB_DELETE, r, null, subStaffId, subStaff, noRoomId, noToken)
          noRoomId = res1.noRoomId
          noToken = res1.noToken
          // 依頼者へ
          let res2 = await this.sendChat(SUB_DELETE, r, null, byUserId, byUser, noRoomId, noToken)
          noRoomId = res2.noRoomId
          noToken = res2.noToken
        }
      }

      for (const task of this.tasks) {
        
        // サブタスクのステータスが完了の場合はチャットは送信しない
        if (task.status == 'done') {
          continue
        }

        // サブ担当者
        let subStaff = utils.deleteKana(task.toStaffName)
        let subStaffId = task.toStaffId
        
        // RP担当者とサブタスク担当者が同じ場合はチャットは送信しない（サブタスク担当者が未定の場合は対象外）
        if (mainStaff.replace(/\s+/g, '') == subStaff.replace(/\s+/g, '') && subStaff != '未定') {
          continue
        }

        // 新規の場合
        if (!task._id || task._id == '') {
          
          // サブ担当者へ
          let res1 = await this.sendChat(feature.messageTemplate, task, null, subStaffId, subStaff, noRoomId, noToken)
          noRoomId = res1.noRoomId
          noToken = res1.noToken

        // 更新の場合
        } else {
          // 元データと比較
          for (let i = 0; i < this.originalTasks.length; i++) {
            const o = this.originalTasks[i]

            if (o._id == task._id) {
              // 変更内容
              let msg = []
              
              // 依頼者への通知フラグ
              let irai = false
            
              // タスク名変更
              if (o.requestTask != task.requestTask) {
                msg.push(`タスクが「${o.requestTask}」から「${task.requestTask}」`)
                irai = true
              }

              // 担当者変更
              if (o.toStaffName != task.toStaffName) {
                msg.push(`担当者が「${o.toStaffName}」から「${task.toStaffName}」`)
              }
              
              // 期限変更
              if (o.limitDate != task.limitDate) {
                msg.push(`期限が「${o.limitDate}」から「${task.limitDate}」`)
                irai = true
              }

              // 変更があればチャット送信
              // サブ担当者へ
              if (msg.length) {
                let res1 = await this.sendChat(SUB_CHANGED, task, msg, subStaffId, subStaff, noRoomId, noToken)
                noRoomId = res1.noRoomId
                noToken = res1.noToken
              }
              
              // 依頼者へ
              if (irai) {
                let res2 = await this.sendChat(SUB_CHANGED, task, msg, byUserId, byUser, noRoomId, noToken)
                noRoomId = res2.noRoomId
                noToken = res2.noToken
              }
            }
          }
        }
      }
    },

    /**
     * チャット送信
     * @param temp テンプレートメッセージ
     * @param task 対象タスク
     * @param changeMsg 変更内容
     * @param staffId 送信先スタッフID
     * @param staff 送信先スタッフ名
     * @param noRoomId ルームID無しで送信不可リスト
     * @param noToken トークン無しで送信不可リスト
     */
    async sendChat(temp, task, changeMsg, staffId, staff, noRoomId, noToken) {
      // ログインしているユーザー名を取得
      const loginUser = this.$store.getters.user.user_name

      let isOrder = false
      if (this.$route.query.mode == 1) {
        isOrder = true
      }

      return await taskRequestManager.sendChat(temp, task, changeMsg, staffId, staff, noRoomId, noToken, loginUser, isOrder, this.requestPost)
    },

    back() {
      this.$router.back()
    },

    /** 
     * お問い合わせカテゴリー 表示フラグ
     */
    isShowCategoryItem(category) {
      if (this.requestPost.category && this.requestPost.category.length > 0) {
        return this.requestPost.category.indexOf(category) > -1
      } else {
        return false
      }
    },

    /** 
     * 有料トイレ清掃あり/有料トイレ清掃無し 表示フラグ_RLS,RLSプラス
     */
    isShowtToilet(rp, category) {
      if (rp && rp.length > 0) {
        return rp[0].indexOf(category) > -1
      } else {
        return false
      }
    },

    /** 
     * 添付資料追加 表示フラグ
     */
    isShowAddFileBtnItem(category) {
      if (this.requestPost.addFileBtn) {
        return this.requestPost.addFileBtn.indexOf(category) > -1
      } else {
        return false
      }
    },

    /** 
     * 御見積_清掃カテゴリー 表示フラグ
     */
    isShowEstimationCleanCategoryItem(category) {
      if (this.requestPost.estimationCleanCategory && this.requestPost.estimationCleanCategory.length > 0) {
        return this.requestPost.estimationCleanCategory.indexOf(category) > -1
      } else {
        return false
      }
    },

    /** 
     * 日常清掃 祝日対応の有無
     */
    isEverydayHolidayFlag(index) {
      if (this.requestPost.everydayCleans[index].everydayHolidayFlag && this.requestPost.everydayCleans[index].everydayHolidayFlag.length > 0) {
        return (this.requestPost.everydayCleans[index].everydayHolidayFlag.indexOf('有') > -1 || this.requestPost.everydayCleans[index].everydayHolidayFlag.indexOf('不明') > -1)
      } else {
        return false
      }
    },

    /** 
     * 管理業務 祝日対応の有無
     */
    isManagerWorkHolidayFlag(index) {
      if (this.requestPost.managerWorks[index].managerWorkHolidayFlag && this.requestPost.managerWorks[index].managerWorkHolidayFlag.length > 0) {
        return (this.requestPost.managerWorks[index].managerWorkHolidayFlag.indexOf('有') > -1 || this.requestPost.managerWorks[index].managerWorkHolidayFlag.indexOf('不明') > -1)
      } else {
        return false
      }
    },

    /** 
     * 作業時間の指定の有無 表示フラグ
     */
    isShowEverydayStartTimeFlagItem(category, index) {
      if (this.requestPost.everydayCleans[index].everydayStartTimeFlag && this.requestPost.everydayCleans[index].everydayStartTimeFlag.length > 0) {
        return this.requestPost.everydayCleans[index].everydayStartTimeFlag.indexOf(category) > -1
      } else {
        return false
      }
    },

    isShowManagerWorkTimeFlagItem(category, index) {
      if (this.requestPost.managerWorks[index].managerWorkTimeFlag && this.requestPost.managerWorks[index].managerWorkTimeFlag.length > 0) {
        return this.requestPost.managerWorks[index].managerWorkTimeFlag.indexOf(category) > -1
      } else {
        return false
      }
    },

    /** 
     * 御見積_点検カテゴリー 表示フラグ
     */
    isShowEstimationInspectCategoryItem(category) {
      if (this.requestPost.estimationInspectCategory && this.requestPost.estimationInspectCategory.length > 0) {
        return this.requestPost.estimationInspectCategory.indexOf(category) > -1
      } else {
        return false
      }
    },

    /** 
     * 有料トイレ清掃あり/有料トイレ清掃無し 表示フラグ
     */
     isShowtToiletCleaningItem(category, index) {
      if (this.requestPost.everydayCleans[index].toiletCleaning && this.requestPost.everydayCleans[index].toiletCleaning.length > 0) {
        return this.requestPost.everydayCleans[index].toiletCleaning.indexOf(category) > -1
      } else {
        return false
      }
    },

    /** 
     * 御見積_その他カテゴリー 表示されているかのチェック
     */
    isShowEstimationOtherCategoryItem(category) {
      if (this.requestPost.estimationOtherCategory && this.requestPost.estimationOtherCategory.length > 0) {
        return this.requestPost.estimationOtherCategory.indexOf(category) > -1
      } else {
        return false
      }
    },

    /**
     * タスク依頼セットプルダウン 選択
     */
    selectedTaskRequestSet(set, setSubTask) {
      // 詳細を追加 非表示
      this.isDetailBtn = false

      // タスク 設定
      if (setSubTask) {
        // 既存タスククリア
        for (const task of this.tasks) {
          if (task._id) {
            this.removeTasks.push(task)
          }
        }
        this.tasks = []
        // 部署
        const ud = this.$store.getters.loginUserData

        // ログインユーザー選択
        let iniStaffValue = null
        let val = this.$store.getters.userList.find(st => st.id == this.$store.getters.user.id)
          if (val) {
            iniStaffValue = {
              value: val.id,
              text: val.user_name
            }
        }

        if (!this.$route.query.mode || this.$route.query.mode != 1) {
          // 所属部署名
          let departmentName = ''
          if (ud.departmentName) {
            departmentName = ud.departmentName
          }
          // 所属部署ID
          let departmentId = ''
          if (ud.departmentIdUser) {
            departmentId = ud.departmentIdUser
          }

          for (let i = 0; i < set.group.length; i++) {
            const mtask = set.group[i]

            let task = {
              requestPostId: this.requestPostId,
              taskRequestRequestNo: this.requestPost.requestNo,
              subTaskNo: '',
              requestTask: mtask.task,
              departmentName,
              departmentId,
              toStaffId: this.$store.getters.user.id,
              toStaffName: this.userName,
              limitDate: '',
              memo: '',
              finishPoint: '0',
              finishYear: null,
              finishMonth: null,
              estimationCleanCategory: this.requestPost.estimationCleanCategory,
              estimationInspectCategory: this.requestPost.estimationInspectCategory,
              estimationOtherCategory: this.requestPost.estimationOtherCategory,
              displayOrder: i,
              toStaffValue: iniStaffValue
            }

            // 初期タスク設定
            const selected = utils.clone(mtask)
            this.setInitTask(task, selected, i);

            this.tasks.push(task)
          }
        } else {
          for (let j = 0; j < set.group.length; j++) {
            const mtask2 = set.group[j]
            let task = {
              orderId: this.orderId,
              orderTaskRequestOrderNo: this.requestPost.orderNo,
              subTaskNo: '',
              requestTask: mtask2.task,
              departmentName: ud.departmentName,
              departmentId: ud.departmentIdUser,
              toStaffId: this.$store.getters.user.id,
              toStaffName: this.userName,
              limitDate: '',
              memo: '',
              displayOrder: j,
              toStaffValue: iniStaffValue
            }

            // 初期タスク設定
            const selected = utils.clone(mtask2)
            this.setInitTask(task, selected, j);

            this.tasks.push(task)
          }
        }
      }
    },

    /**
     * タスク選択イベント
     * @param event 選択したデータ
     * @param task 対象タスク
     * @param i index
     */
    async selectedTaskRequest(event, task, i) {
      if (event.name.indexOf('見積') == -1) {
          // 複写されたタスクか確認
        if (task.allCopy != undefined && task.allCopy) {
          let e = await estimateManager.getEstimateByTaskRequestId(this, task._id)
          // 紐づく見積りがある場合
          if (e.length) {
            const dialogResult = await dialogs.showWarningConfirmDialog('削除確認', '紐づく見積りの複写も削除されますがよろしいですか？')
            if (dialogResult != 'YES') {
              task.requestTask = '見積作成'
              return
            } else {
              this.deleteEstimate.push(task._id)
            }
          }
        }
      }

      // 初期タスク設定
      this.setInitTask(task, event, i)
    },

    /**
     * 初期タスク設定
     * @param task サブタスク登録用データ
     * @param selectedSub 選択したサブタスク
     * @param i インデックス
     */
    setInitTask(task, selectedSub, i) {
      // タスク依頼セットから選択した場合
      if (!selectedSub.name) {
        const tar = this.taskRequestMasterList.find((t) => {
          return t.name == selectedSub.task
        })
        if (tar && tar.name) {
          selectedSub = tar
        } else {
          return
        }
      }
      // タスク名をセット
      task.requestTask = selectedSub.name

      // マスタにセットされている部署名、担当者をセット
      taskRequestManager.setSubCharge(selectedSub, task, this.requestPost.toDepartment, this.$store.getters.departmentList, this.$store.getters.userDepartment)

      // 期限をセット
      this.setLimitDate(task, selectedSub)

      // 最終タスク設定がされている場合
      if (selectedSub.finalCompletionTask) {
        this.clickedFinalCompletionTask(i)
        task.finalCompletionTask = '1'
      }

      // メモ
      task.memo = selectedSub.memo

      // 詳細が設定されている場合
      if (selectedSub.details && selectedSub.details.length) {
        task.details = utils.clone(selectedSub.details)
        this.isDetailBtn = true
      }
    },

    /**
     * サブタスクの期限をセット
     * @param task サブタスク登録用データ
     * @param selectedSub 選択したサブタスク
     */
    setLimitDate(task, selectedSub) {
      taskRequestManager.setSubLimitDate(task, selectedSub, this.requestPost)
    },

    /**
     * 部署プルダウン 選択
     */
    selectedSelectDepartmentName(department, task) {
      // 所属部署データ取得
      let uL = this.getStaffNameList(department.departmentId)
      task.departmentId = department.departmentId
      // 担当者が該当の部署にいなければ担当者を空にする
      if (task.toStaffId != '') {
        let result = uL.filter((u) => {
          return u.userId == task.toStaffId
        })
        if (!result || !result.length) {
          task.toStaffId = ''
          task.toStaffName = ''
          task.toStaffValue = null
        }
      }
    },

    changedSelectDepartmentName(department, task) {
      if (!department || department === '') {
        task.departmentName = ''
        task.departmentId = ''
      }
    },

    /**
     * 担当者リスト生成
     * @param id 部署ID
     */
    getStaffNameList(id) {
      if (!id || id == '') {
        return []
      }
      // 所属部署データ取得
      const ud = this.$store.getters.userDepartment

      let list = []
      for (let i = 0; i < ud.length; i++) {
        const u = ud[i]
        if (u.departmentId == id) {
          list.push(u)
        }
      }

      return list
    },

    /**
     * 担当者プルダウン 選択
     */
    async selectedSelectByUser(user, task) {
      task.toStaffId = user.userId
      task.toStaffName = user.userName
    },

    /**
     * 担当者リスト生成
     * @param id 部署ID
     */
     getStaffList(id) {
      if (!id || id == '') {
        return []
      }
      // 所属部署データ取得
      const ud = this.$store.getters.userDepartment

      let list = []
      for (let i = 0; i < ud.length; i++) {
        const u = ud[i]
        if (u.departmentId == id) {
          list.push({
            value: u.userId,
            text: u.userName
          })

        }
      }

      return list
    },

    /**
     * 担当者ドロップダウン 選択
     */
     async changeToStaff(user, task) {
      task.toStaffId = user.value
      task.toStaffName = user.text
    },

    changedSelectByUser(name, task) {
      if (name === '') {
        task.toStaffId = ''
        task.toStaffName = ''
      }
    },

    /**
     * タスク追加ボタン
     */
    addTask() {
      let add = {}
      if (!this.$route.query.mode || this.$route.query.mode != 1) {
        add = taskRequestManager.create()
        add.requestPostId = this.requestPostId
        add.taskRequestRequestNo = this.requestPost.requestNo
        add.estimationCleanCategory = this.requestPost.estimationCleanCategory,
        add.estimationInspectCategory = this.requestPost.estimationInspectCategory,
        add.estimationOtherCategory = this.requestPost.estimationOtherCategory
      } else {
        add = taskRequestManager.newOrderTaskRequestCreate()
        add.orderId = this.orderId
        add.orderTaskRequestOrderNo = this.requestPost.orderNo
      }
      // 部署
      const ud = this.$store.getters.loginUserData
      add.departmentId = ud.departmentIdUser
      add.departmentName = ud.departmentName
      
      // ログインユーザー名
      add.toStaffId = this.$store.getters.user.id
      // ログインユーザー名
      add.toStaffName = this.userName
      // ログインユーザー選択
      let val = this.$store.getters.userList.find(st => st.id == this.$store.getters.user.id)
        if (val) {
          add.toStaffValue = {
            value: val.id,
            text: val.user_name
          }
      }

      // 並び順
      add.displayOrder = this.tasks.length

      this.tasks.push(add)
    },

    /**
     * タスク削除ボタン
     */
    async removeTask(idx) {
      // 未登録タスクは消去、登録済みタスクは論理削除
      if (this.tasks[idx]._id) {

        // 複写されたタスクか確認
        if (this.tasks[idx].allCopy != undefined && this.tasks[idx].allCopy) {
          let e = await estimateManager.getEstimateByTaskRequestId(this, this.tasks[idx]._id)
          // 紐づく見積りがある場合
          if (e.length) {
            const dialogResult = await dialogs.showWarningConfirmDialog('削除確認', '紐づく見積りの複写も削除されますがよろしいですか？')
            if (dialogResult != 'YES') {
              return
            }
          }
        }
        this.removeTasks.push(this.tasks[idx])
      }
      this.tasks.splice(idx, 1)
      // サブタスクを削除した場合は、比較用データのサブタスクも削除
      // this.comparisonTasks.splice(idx, 1)
    },

    /**
     * カテゴリ２ change イベント
     */
    category2Changed() {
      // 社内の場合は、お客様項目必須解除
      this.isClientRequired = (this.requestPost.category2 != '社内')
    },

    /**
     * カテゴリ change イベント
     */
    categoryChanged() {
      // デンタツの場合は、社内/お客様、タイトル、お客様項目の必須解除
      this.isDentasuRequired = (this.requestPost.category != 'デンタツ')

      if (this.requestPost.category.includes('デンタツ')) {
        this.isTransmission = true
      } else {
        this.isTransmission = false
      }
    },

    /**
     * ラベル表示用index変換
     */
    outputIndex(index) {
      let idxNumber = String(index + 1)
      const zenkaku = utils.zenkaku(idxNumber)
      return zenkaku
    },

    /**
     * 最終完了タスク click イベント
     */
    clickedFinalCompletionTask(index) {
      for (let i = 0; i < this.tasks.length; i++) {
        // クリックしたサブタスク以外は値を空にする
        if (index != i) {
          this.tasks[i].finalCompletionTask = ''
        }
      }
    },

    /**
     * 建物詳細 必須設定
     */
    setBuildingDetailsRequired() {
      if ((this.requestPost.estimationOtherCategory == 'その他作業' && this.requestPost.newNowFlag == '契約有' && this.requestPost.newClass == '既存')
        || (this.requestPost.newNowFlag == '契約有' && this.requestPost.newClass == '既存' && !this.forCustomers)) {
        this.isBuildingDetailsRequired = false
      } else {
        this.isBuildingDetailsRequired = true
      }
    },

    /**
     * タスクの表示順を上に上げる
     */
    async taskDisplayOrderUp(taskIdx) {
      this.tasks.splice(taskIdx - 1, 2, this.tasks[taskIdx], this.tasks[taskIdx - 1])
      for (let index = 0; index < this.tasks.length; index++) {
        this.tasks[index].displayOrder = index
      }
    },

    /**
     * タスクの表示順を下に下げる
     */
    async taskDisplayOrderDown(taskIdx) {
      this.tasks.splice(taskIdx, 2, this.tasks[taskIdx + 1], this.tasks[taskIdx])
      for (let index = 0; index < this.tasks.length; index++) {
        this.tasks[index].displayOrder = index
      }
    },

    /**
     * 制限されている期限の日付の選択を許可
     * @param {*} taskId サブタスクの_id
     */
    getLimitDate(taskId) {
      let limitDate = ''
      // 既存のサブタスクの場合は_idが一致するデータの期限を返す
      // 新規のサブタスクの場合は空文字を返す
      if (taskId) {
        for (let i = 0; i < this.deregulationDate.length; i++) {
          if (this.deregulationDate[i]._id == taskId) {
            limitDate = this.deregulationDate[i].limitDate
          }
        }
      }
      return limitDate
    },

    /**
     * 非公開依頼 参照権限 チェック
     * @param {*} requestPost リクエストポスト
     * @param {*} subTasks サブタスク
     */
    isPrivateAuthorized(requestPost, subTasks) {
      // 担当者もしくは依頼主
      if (requestPost.toStaffId == this.$store.getters.user.id || requestPost.byUserId == this.$store.getters.user.id) return true
      // サブ依頼担当者
      if (subTasks.find(task => task.toStaffId == this.$store.getters.user.id)) return true
      return false
    },

    /**
     * 雇い入れ社員用 参照権限 チェック
     */
    async isRegularStaffAuthorized() {
      // 管理者ユーザー、大野様、ログインユーザーの所属課長以上（兼任も含めて）が人事なら、true
      const isEmpRes = await backend.searchData('user/getDepartment', { user: this.$store.getters.user.user_name })
      let isEmployment = utils.isRegularStaffAuthorized(this.$store.getters.user.id, isEmpRes)
      // 管理者ユーザー、大野様、人事でない場合、所属部署の課長以上か確認
      if (!isEmployment) {
        const departmentArr = utils.departmentArr(isEmpRes)
        // 所属部署の課長・部長クラスなら表示する
        isEmployment = utils.isRegularStaffAuthorized2(departmentArr, this.requestPost.employmentDepartmentId)
      }
      return isEmployment
    },

    /**
     * 面接社員用 参照権限 チェック
     */
    async isRegularStaffInterviewAuthorized() {
      const rp = this.requestPost
      const userInfo = await backend.searchData('user/getDepartment', { user: this.$store.getters.user.user_name })
      return utils.isRegularStaffInterviewAuthorized(this.$store.getters.user.id, this.$store.getters.user.user_name, rp.byUser, rp.toStaffName, rp.interviewsResult, userInfo, rp.toDepartment)
    },

    /*
     * お問い合わせカテゴリーを過去の文言を新しくする
     * @param category お問い合わせカテゴリー
     */
    setNewCategory(category) {
      if (category && category.length) {
        for (let i = 0; i < category.length; i++) {
          const ca = category[i]
          if (ca == '契約依頼（発注・解約・変更）') {
            category[i] = '変更処理（解約・変更）'
          } else if (ca == '受注処理一覧') {
            category[i] = '受注処理（新規・金額変更）'
          }
        }
      }
      return category
    },

    /*
     * お見積りカテゴリーを過去の文言を新しくする
     * @param category お見積りカテゴリー
     */
    setNewEstimateCategory(category) {
      if (category && category.length) {
        for (let i = 0; i < category.length; i++) {
          const ca = category[i]
          if (ca == '各種特別清掃') {
            category[i] = '特別清掃'
          }
        }
      }
      return category
    },

    /**
     * サブタスク「完了にする」「未完了に戻す」ボタンイベント
     * @param task 対象サブ
     */
    async changeStatus(task) {
      if (task.status == 'open') {
        // 詳細がある場合
        if (task.details && task.details.length) {
          // 未完了のものがある場合
          const res = task.details.some(d => !d.isDone)
          if (res) {
            await dialogs.showErrorDialog('未完了の詳細があります', '未完了の詳細タスクがあるため、完了できません。')
            task.detailsOpen = true
            this.reload++
            return
          }
        }
        task.status = 'done'
        task.doneDate = moment().format('YYYY-MM-DD HH:mm:ss')
        task.doneStaffName = this.$store.getters.user.user_name
      } else {
        task.status = 'open'
        task.doneDate = null
        task.doneStaffName = null
      }
    },

    /**
     * メインタスク自動完了
     */
    async updateMain() {
      // 編集の場合のみ
      if (this.editMode) {
        // 受注以外の場合
        if (!this.$route.query.mode || this.$route.query.mode != 1) {
          // postgresに登録
          await taskRequestManager.saveEstimateSubmission(this.tasks, this.$store.getters.user.id, null)
        }
        // サブが全て完了したか確認
        let flag = false
        for (let i = 0; i < this.tasks.length; i++) {
          const t = this.tasks[i]
          // 新規のサブもしくは未完了のサブ
          if (!t.status || (t.status && t.status == 'open')) {
            flag = true
            break
          }
        }
        // 全て完了した場合
        if (!flag && this.tasks.length) {
          this.requestPost.status = 'done'
          const result = await requestPostManager.markAsDone(this, this.requestPost._id)

          if (result) {
            await dialogs.showInfoDialog('メインタスク完了', 'サブタスクが全て完了したので、メインタスクも完了しました。')
            logManager.recording(this, logManager.Loglevel.INFO, 'メインタスク', 'ステータス完了', 'メインタスク（お問い合わせ番号：' + this.requestPost.requestNo + '）のステータスを完了しました。')
          }
          
          // 受注の場合、見積りの受注完了ステータスを変更
          if (this.requestPost.type == 'order') {
            // 見積りのデータの受注ステータス更新、完了にした人を保存
            // ログインユーザー名
            let name = this.$store.getters.user.user_name
            name = utils.deleteKana(name)
            await estimateManager.changeOrderStatus(this, this.requestPost.estimateNo, '受注完了', name)
          }
        }
      }
    },

    /**
     * 新規登録時のサブタスクをセット
     */
    setSubtaskSignUp() {
      let cat = this.requestPost.category[0]
      let tar = ''
      // 新規+雇い入れの場合
      if (!this.editMode && this.requestPost.category && this.requestPost.category[0] == '雇い入れ') {
        const ec = this.requestPost.employmentClass
        if (ec && ec[0]) {
          tar = utils.clone(ec[0])
          // 退職の場合、物件退職がヒットしないようにする
          if (tar == '退職') {
            tar = '（退職）'
          }
        }
      }

      if (tar) {
        const tasks = this.taskRequestSetMasterList.find((t) => {
          return t.name.indexOf(cat) > -1 && t.name.indexOf(tar) > -1
        })
        if (tasks && tasks.name) {
          this.selectedTaskRequestSet(tasks, true)
        }
      }
    },

    /**
     * 得意先リスト取得
     */
    async getClientLists() {
      if (this.requestPost.category && this.requestPost.category[0] == '雇い入れ') {
        this.clientLists = await estimateManager.getClient(null)
      } else {
        this.clientLists = []
      }
      // console.log(this.clientLists)
    },

    /**
     * サブタスクの竣工変更イベント
     * @param row 対象のタスク
     */
    changeFinishPoint(i) {
      const task = utils.clone(this.tasks[i])
      if (!this.tasks[i].finishPoint || this.tasks[i].finishPoint == '0') {
        task.finishYear = null
        task.finishMonth = null
      } else {
        task.finishYear = String(Number(moment().format('YYYY')) + Number(this.tasks[i].finishPoint))
      }
      // 画面に即時反映させるためsplice
      this.tasks.splice(i, 1, task)
    },

    /**
     * 詳細の表示日表示切替
     * @param task タスク
     */
    openClose(task) {
      if (!task.detailsOpen) {
        task.detailsOpen = true
      } else {
        task.detailsOpen = false
      }
      // this.$set(this.tasks[i], 'detailsOpen', task.detailsOpen)
      this.reload++
    },

    /**
     * 詳細を追加
     * @param task 対象タスク
     */
    addDetail(task) {
      if (task.status && task.status == 'done') {
        return
      }
      if (!task.details) {
        task.details = []
      }
      task.details.push({ task: '', isDone: false })
      task.detailsOpen = true
      this.reload++
    },

    /**
     * 詳細削除
     * @param task タスク
     * @param i index
     */
    removeDetail(task, i) {
      task.details.splice(i, 1)
      this.reload++
    },

    /**
     * 詳細タスク完了
     * @param task 対象タスク
     * @param detail 詳細タスク
     */
    changeIsDone(task, detail) {
      // 完了日・完了者セット
      if (detail.isDone) {
        detail.doneDate = moment().format('YYYY-MM-DD HH:mm:ss')
        detail.doneStaffName = this.$store.getters.user.user_name
      } else {
        detail.doneDate = null
        detail.doneStaffName = null
      }

      const res = task.details.some(d => !d.isDone)
      // 詳細タスクに未完了がない場合
      if (!res) {
        dialogs.showInfoDialog('タスク完了', 'すべての詳細タスクが完了したので、このタスクは完了します。')
        task.status = 'done'
        task.doneDate = moment().format('YYYY-MM-DD HH:mm:ss')
        task.doneStaffName = this.$store.getters.user.user_name
      }
    },

    /**
     * 完了情報表示
     * @param task 対象タスク
     */
    getDoneInfo(task) {
      // 完了日、完了者を表示
      if ((task.status == 'done' || task.isDone) && task.doneDate) {
        let ret = moment(task.doneDate).format('M/D') + ' '
        if (task.doneStaffName) ret += utils.deleteKana(task.doneStaffName)
        return ret
      }
      return ''
    }
  }
}
</script>

<style>
#inquiry-category div button[type=button] {
  width: 19% !important;
}

.estimate-category div button[type=button] {
  width: 19% !important;
}

div.rp-estimate {
  flex: 1
}

div.rp-no-estimate {
  flex: 0
}

.done-info {
  margin-top: auto;
  margin-bottom: auto;
}
</style>