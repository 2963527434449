<template>
  <form>
    <PageHeader title="apollo-RP - タスク依頼セットマスタ" class="list">
      <template #title-header-content></template>
      <template #page-header-content>
          <div class="mb-auto pl-4 flex items-center h-10">
            <h1 class="text-lg font-bold text-white flex items-center">            
              <Icon iconName="ClipboardList" class="w-7 h-7 mr-2" /> <span class="mt-0">タスク依頼セット 一覧</span>
            </h1>
            <div class="pl-2 mb-100px ml-auto">
              <PrimaryButton text="新規" size="normal hover:bg-pink-400" class="new" @click="edit({})"/>
            </div>
          </div>
      </template>
    </PageHeader>
    <main class="absolute top-32 w-screen bottom-4 -mt-2 leading-8 list">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full">
          <table class="table">
            <thead class="th sticky top-0">
              <tr class="column">
                <th class="th1">区分</th>
                <th class="th2">名前</th>
                <th class="th2 text-blue-700">内容</th>
                <th class=""></th>
                <th class=""></th>
              </tr>
            </thead>
            <tbody v-for="(row, index) in list" :key="index">
              <tr :class="setColor(index)" class="hover-color text-sm">
                <!-- 受注区分 -->
                <td class="td1">{{ row.classification == '1' ? '受注以外' : '受注' }}</td>
                <!-- セット名 -->
                <td class="td2">{{row.name}}</td>
                <!-- 内容 -->
                <td class="td2 text-blue-600 whitespace-pre-line">{{getDetails(row)}}</td>
                <td class="td1 py-2"><PrimaryButton text="編集" @click="edit(row)" /></td>
                <td class="td1"><PrimaryButton text="削除" @click="deleteData(row)" size="normal bg-indigo-200 text-indigo-700 hover:bg-indigo-300"/></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
    <!-- 編集・新規登録モーダル -->
    <SubTaskSetMasterEditModal 
      v-model="modalShow"
      :target="editData"
      :subList="list"
      :open="modalShow"
      @modalClose="modalClose"
    />
  </form>
</template>
<script>

import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import SubTaskSetMasterEditModal from '../components/SubTaskSetMasterEditModal.vue'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import PageModel from '../../main/pages/PageModel.vue'

export default {
  extends: PageModel,
  components: {
    Icon,
    PrimaryButton,
    PageHeader,
    SubTaskSetMasterEditModal
  },

  data() {
    return {
      // サブタスク依頼セット
      list: [],
      // モーダル表示
      modalShow: false,
      // 編集する内容
      editData: {},
    }
  },

  async created() {
    await this.getData()
  },

  computed: {
    /**
     * 内容の表示
     */
    getDetails() {
      return (row) => {
        if (row.group && row.group.length) {
          const res = row.group.map((d) => {
            return d.task
          })
          return res.join('\r\n')
        }
        return null
      }
    }
  },

  methods: {
    /**
     * データ取得
     */
    async getData() {
      this.list = []
      let res = await this.$pigeon.getTasksByFormId('task_request_set_master', { classification: 1 })
      if (res && res.length > 0) {
        this.list = res
      } else if (res.error) {
        alert(res.msg)
      }
    },

    /**
     * 編集イベント
     * @param data 編集内容
     */
    edit(data) {
      this.modalShow = true
      this.editData = data
    },

    /**
     * 背景色設定
     */
    setColor(i) {
      if (i % 2 != 0) {
        return 'bg-blue'
      }
    },

    /**
     * モーダルを閉じる
     */
    async modalClose() {
      this.modalShow = false
      await this.getData()
    },

    /**
     * 削除イベント
     * @param row 削除するデータ
     */
    async deleteData(row) {
      const dialogResult = await dialogs.showWarningConfirmDialog('削除', '削除してよろしいですか？')
      if (dialogResult === 'YES') {
        let registApp = await this.$pigeon.deleteTask(row._id)
        if (!registApp) {
          await dialogs.showErrorDialog('エラー', '削除できませんでした。')
          return
        }
        logManager.recording(this, logManager.Loglevel.INFO, 'タスク依頼セットマスタ', '削除', 'タスク依頼セット（' + row.task + '）を削除しました。')
        await this.getData()
      }
    }
  }
}
</script>

<style scoped>
.table {
  width: 100%;
}

.column th {
  height: 50px;
  background: #edecf7;
}

.bg-blue {
  background: rgb(243 247 249);
}

.th1 {
  width: 100px;
  text-align: center;
}

.th2 {
  text-align: left;
  padding-left: 30px;
}

.td1 {
  text-align: center;
}

.td2 {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.new {
  margin-top: 103px;
  width: 200px;
  background: #eb94c8;
}

.hover-color:hover {
  background-color: rgba(198, 233, 239, 0.853);
}

@media screen and (max-width: 1400px) {
  .list {
    min-width: 1400px !important;
  }
}
</style>