<template>
  <div>
    <PageHeader :title="isTabletDevice||isMobileDevice?'':'感想文一覧'" class="main-form">
      <template #title-header-content>
        <div class="flex-1 flex justify-end items-center">
          <!-- 権限あり -->
          <div v-if="auth&&!isMobileDevice&&!isTabletDevice" class="text-white font-bold text-xs mr-auto">
            ※権限者（人事経理総務部と管理者）のみ全員分表示
          </div>
          
          <!-- 上司 -->
          <div v-else-if="boss&&!isMobileDevice&&!isTabletDevice" class="text-white font-bold text-xs mr-auto">
            ※自分と部下を表示
          </div>
          
          <!-- 権限無し、上司でもない -->
          <div v-else-if="!auth&&!boss" class="text-white font-bold text-sm mr-auto">
            自分の感想文のみ表示
          </div>

          <!-- 検索ボタン -->
          <PrimaryButton
            text="検索"
            class="button-contents mr-2"
            @click="getData()"
          >
          </PrimaryButton>

          <div class="text-white font-bold">
            {{ list.length }}件
          </div>

          <!-- リロードアイコン -->
          <Icon
            iconName="Refresh"
            :clickable="false"
            :class="{ 'animate-spin': loading }"
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer" @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="flex justify-end items-stretch">
          <!-- 期間 -->
          <div class="flex mr-2 items-stretch">
            <input type="date" v-model="filter.start" class="text-sm rounded border-gray-300 p-1" />
            <div class="w-fit text-white text-lg font-bold mx-1 my-auto">～</div>
            <input type="date" v-model="filter.end" class="text-sm rounded border-gray-300 p-1" />
            <div class="text-xs py-2 px-3 bg-green-100 text-green-800 border border-green-800 rounded cursor-pointer ml-1 font-bold" @click="deletePeriod()">
              期間なし
            </div>
          </div>
          <!-- 権限あり、上司 -->
          <div v-if="auth||boss" class="mr-2 mt-auto">
            <input
              type="search"
              name="filterUser" 
              placeholder="名前 検索"
              class="py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-36" 
              v-model="filter.user"
            /> 
          </div>
          <div v-if="auth||boss" class="mr-2 mt-auto">
            <input
              type="search"
              name="filterTitle" 
              placeholder="タイトル 検索"
              class="py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-36" 
              v-model="filter.title"
            /> 
          </div>
          <div v-if="auth||boss" class="mr-2 mt-auto">
            <select
              name="status"
              v-model="filter.status"
              class="border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-36"
            >
              <option
                v-for="(d, i) in statusList" :key="'status' + i"
                :value="d.id"
                class="bg-white">
                {{ d.name }}
              </option>
            </select>
          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute bottom-4 w-full main-form mx-auto top-32">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full w-full">
        <div v-if="list.length" class="rounded-md border border-gray-200 table-h overflow-auto">
          <table class="table text-sm bg-white">
            <thead class="th sticky top-0 z-20 bg-colu">
              <tr class="text-gray-500 text-xs border border-gray-200 bg-colu">
                <th v-for="(c, i) in columns" :key="'col'+i" class="text-left whitespace-pre-line p-2" :class="c.class">{{ c.val }}</th>
              </tr>
            </thead>
            <tbody v-for="(row, i) in list" :key="i">
              <tr class="border-t border-gray-200 hover-color" :class="auth?'cursor-pointer':''">

                <!-- 更新日 -->
                <td class="whitespace-nowrap text-sm text-gray-700 min-w-fit p-2 text-center" @click="changePoints(row)">
                  {{ row.daySt }}
                </td>

                <!-- 名前 -->
                <td class="whitespace-nowrap text-sm text-gray-700 min-w-fit px-2 py-4 font-bold" @click="changePoints(row)">
                  {{ row.userName }}
                </td>

                <!-- タイトル -->
                <td class="whitespace-nowrap text-sm text-gray-700 min-w-90px px-2 py-4" @click="changePoints(row)">
                  {{ row.title }}
                </td>

                <!-- 実施項目 -->
                <td class="text-sm text-gray-700 max-w-150px px-2 py-4" @click="changePoints(row)">
                  {{ row.keyword }}
                </td>

                <!-- 感想文 -->
                <td class="whitespace-pre-wrap text-sm text-gray-700 px-2 py-4 max-w-sm" @click="changePoints(row)">
                  {{ row.report }}
                </td>

                <!-- タイトル -->
                <td v-if="auth" class="text-sm text-green-600 font-bold min-w-90px px-2 py-4 " @click="changePoints(row)">
                  {{ row.mstTitle }}
                </td>

                <!-- 実施項目 -->
                <td v-if="auth" class="text-sm text-green-600 font-bold max-w-150px px-2 py-4 " @click="changePoints(row)">
                  {{ row.mstKeyword.join('、') }}
                </td>

                <!-- 有効の有無 -->
                <td class="whitespace-nowrap text-sm text-gray-700 min-w-fit px-2 py-4 text-center" @click="changePoints(row)">
                  <Badge :color="statusColor[row.res]">{{ row.res==1?'有効':'無効' }}</Badge>
                </td>

                <!-- 備考 -->
                <td v-if="auth" class="whitespace-nowrap text-sm text-gray-700 min-w-fit px-2 py-4 text-center" @click="changePoints(row)">
                  {{ row.memo }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="!loading && !list.length" class="w-full h-full flex justify-center items-center text-gray-400">
          <EmptyMessage
            iconName="Document"
            message="該当するデータはありません。"
          />
        </div>
      </div>
    </main>
  </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as logManager from '@managers/logManager'
import Badge from '@components/Badge.vue'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import * as moment from 'moment'
import * as dialogs from '@libs/dialogs'
import PageModel from '../../main/pages/PageModel.vue'

// カラム
const COL = [
  { val: '作成日', class: 'text-center' },
  { val: '名前' },
  { val: 'タイトル' },
  { val: '実施項目（本人入力）' },
  { val: '感想文' }
]

export default {
  extends: PageModel,
  components: {
    Icon,
    PageHeader,
    EmptyMessage,
    PrimaryButton,
    Badge
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * タブレット
     */
    isTabletDevice() {
      return utils.deviceInfo.isTablet
    },

    /**
     * ログインユーザー名
     */
    user() {
      return utils.deleteKana(this.$store.getters.user.user_name)
    },
  },

  data() {
    return {
      loading: false,
      // リスト
      list: [],
      // カラム
      columns: utils.clone(COL),
      // 検索条件
      filter: {
        // 期間
        start: null,
        end: null,
        // 対象者絞込み
        user: '',
        // タイトル
        title: '',
        // 有効無効
        status: null
      },
      // 権限
      auth: false,
      // 上司
      boss: false,
      // ステータスカラー
      statusColor: {
        1: 'blueGray',
        0: 'red'
      },
      // 条件リスト
      statusList: [
        { id: null, name: '有効・無効' },
        { id: 1, name: '有効のみ' },
        { id: 2, name: '無効のみ' }
      ]
    }
  },

  async created() {
    // 権限（人事部、管理者権限はOK）
    this.auth = false
    const dep = this.$store.getters.dualPosition
    if (dep && dep.length) {
      // 人事部か確認
      const isDep = dep.find((d) => {
        return d.departmentName.indexOf('人事') > -1
      })
      if (isDep && isDep.departmentName) {
        this.auth = true
      }
    }
    // 管理者権限あり場合
    let au = this.$store.getters.auth
    if (au && au.adminAuth) {
      this.auth = true
    }
    // 上司フラグ
    this.boss = this.$store.getters.loginUserData.boss

    // 権限ありの場合
    if (this.auth) {
      this.columns.push({ val: 'タイトル（設定）\r\n権限者のみ表示' })
      this.columns.push({ val: '実施項目（設定）\r\n権限者のみ表示' })
      this.columns.push({ val: '有効の有無', class: 'text-center' })
      this.columns.push({ val: '備考\r\n権限者のみ表示', class: 'text-center' })
    } else {
      this.columns.push({ val: '有効の有無', class: 'text-center' })
    }

    // 期間は今月にする
    this.filter.start = moment().format('YYYY-MM-01')
    this.filter.end = moment().format('YYYY-MM-DD')

    await this.getData()
  },

  methods: {
    /**
     * リフレッシュアイコンイベント
     */
    async refresh() {
      await this.getData()
    },

    /**
     * データ取得
     */
    async getData() {
      this.list = []

      let start = this.filter.start
      let end = this.filter.end

      // データ取得
      this.loading = true

      const res = await backend.postData('evaluation/studyPolicyList', { ...this.filter, start, end, auth: this.auth, boss: this.boss, loginUser: this.user })
      if (res.data.data && res.data.data.length) {
        this.list = res.data.data
      }

      this.loading = false
    },

    /**
     * 有効切り替え
     * @param row 選択行
     */
    async changePoints(row) {
      if (!this.auth) {
        return
      }
      let msg = '有効に'
      let forcePoint = 1

      // 戻す場合
      if (row.forcePoint != null) {
        msg = '有効・無効をもとに戻'
        forcePoint = null
      } else if (row.res == 1) {
        msg = '無効に'
        forcePoint = 0
      }

      const res = await dialogs.showConfirmDialog('有効・無効の強制変更', row.userName + 'さんの「' + row.title + '」の感想文を ' + msg + 'しますか？')
      if (res != 'YES') {
        return
      }
      await backend.postData('evaluation/changePolicyReportStatus', { id: row.id, forcePoint })
      logManager.recording(this, logManager.Loglevel.INFO, '感想文有効無効変更', '変更', `${row.userName}さんの タイトル：${row.title}（ID：${row.id}）を${msg}しました。`)
      await dialogs.showSuccessDialog('変更完了', msg + 'しました。')
      this.getData()
    },

    /**
     * 切替イベント
     * @paran tab 選択したタブ
     */
    async typeChanged(tab) {
      this.type = tab.id
      await this.getData()
    },

    /**
     * 期間削除
     */
    deletePeriod() {
      this.filter.start = null
      this.filter.end = null
    },
  }
}
</script>
  
<style scoped>
.main-form {
  min-width: 970px;
}

.table-h {
  max-height: calc(100% - 40px);
}

.table {
  width: 100%;
}

.bg-colu {
  background: #ebf0fb;
}

.hover-color {
  background: white;
}

.hover-color:hover {
  background: rgb(239, 250, 250);
}
</style>