import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
import * as moment from 'moment'

import { PIGEON_CONFIG } from '@/config'
import { ESTIMATE_PDF_FILE_NAME } from '@libs/constants'
import { ESTIMATE_PDF_FILE_NAME_ORDER } from '@libs/constants'

// 見積
const ESTIMATES_COLLECTION_NAME = 'rp_estimates'
// 特記事項
const ESTIMATES_NOTE_COLLECTION_NAME = 'estimate_note_master'
// 共通の特記事項
const ESTIMATES_COMMON_NOTE_COLLECTION_NAME = 'estimate_common_note_master'
// 承認者
const APPROVER_COLLECTION_NAME = 'estimate_approver_master'
// 発注マスタ
const PURCHASE_COLLECTION_NAME = 'estimate_purchase_master'
// 作業区分マスタ
const WORK_CODE_COLLECTION_NAME = 'estimate_work_code_master'

export const EstimateSchema = {
  // 見積りタイプ
  estimateType: 'normal',
  // 見積番号
  estimateNo: '',
  // 作成日
  estimateDate: '',
  // 期限
  expireDate: '',
  // リクエストポストID
  requestPostId: '',
  // リクエストポスト依頼内容
  requestPostName: '',
  // メインタスク部署ID
  departmentIdMain: '',
  // サブタスク依頼ID
  taskRequestId: '',
  // サブタスクNo
  subTaskNo: '',
  // サブタスク依頼内容
  requestTask: '',
  // サブタスク担当者_作成依頼された人
  taskStaff: '',
  // サブタスク担当者ID
  taskStaffId: '',
  // サブタスク担当者部署ID
  departmentIdSubTask: null,
  // 社内担当者名
  // salesPersonName: '',
  // 実際の作成者
  estimateCreater: '',
  // 作成者印鑑画像
  userStamp: '',
  // 会社印
  campanyStamp: '',
  // 得意先コード
  clientCode: '',
  // 得意先名
  clientName: '',
  // 敬称
  clientHonorific: '御中',
  // 得意先部署名
  clientDepartmentName: '',
  // 得意先郵便番号
  clientPostalCode: '',
  // 得意先アドレス1
  clientAddress1: '',
  // 得意先アドレス2
  clientAddress2: '',
  // 得意先担当者名
  clientPersonName: '',
  // 得意先電話
  clientPhoneNumber: '',
  // 得意先FAX
  clientFax: '',
  // 物件郵便番号 2023/05/10追加
  buildingPostalCode: '',
  // 物件住所
  buildingAddress: '',
  // 物件住所_物件名以外
  buildingAddress2: '',
  // 物件名
  buildingName: '',
  // 要件
  requirement: '',
  // お問い合わせ内容
  subject: '',
  // 区分 1:新規、2:変更、3:スポット
  classification: null,
  // 見積り明細
  details: [],
  // 割引
  discount: '',
  // 税抜き
  withoutTax: '',
  // 消費税
  tax: '',
  // 合計金額
  totalAmount: '',
  // 見積りステータス
  estimateStatus: '作成中',
  // 特記事項リスト
  noteList: [{
    categoryName: '',
    groupName: '',
    noteHTML: ''
  }],
  // 発注明細
  purchase: [{
    categoryCode: '',
    categoryName: '',
    groupName: '',
    // 仕入先名
    supplier: '',
    // 仕入れ率
    rate: '',
    // 仕入金額
    price: '',
    priceBgColor: false,
    rateBgColor: false
  }],
  // 手数料明細
  commission: [{
    categoryCode: '',
    categoryName: '',
    groupName: '',
    estimatePrice: '',
    rate: '',
    commissionPrice: '',
    price: ''
  }],
  // 注文欄を印字
  printOrderForm: true,
  // 添付ファイル
  attachment1: {},
  attachment2: {},
  attachment3: {},
  // メモ（社内用）
  memo: '',
  // 数量1と金額1の並べ替えフラグ
  columnDragFlagOne: false,
  // 数量2と金額2の並べ替えフラグ
  columnDragFlag: true,
  // 送付状追記文
  // coverPageAdditionalText: '',
  // 共通の特記事項
  commonNote: '',
  // タスク依頼で入力された物件の情報（見積明細での初期値として表示）
  buildingInfo: {
    // オーナー契約 初期値
    owner: '',
    // 建物住所
    address: {
      state: '',
      city: '',
      street: ''
    },
    // 階数
    stairs: '0',
    // 地下
    basement: '',
    // 戸数
    households: '0',
    // 居住タイプ
    residenceType: '',
    // 単独発注 単独・他あり
    other: '',
    // 駐車場代あり・なし
    parking: '',
    // 使用可能トイレあり・なし
    availableToilet: '',
    // トイレ清掃回数（週）
    rlsToiletTimes: '0',
    // トイレ清掃回数（週）
    plusToiletTimes: '0',
    // トイレ箇所
    rlsToiletPlace: '0',
    // トイレ箇所
    plusToiletPlace: '0',
    // ラウンド ゴミ搬出
    rlsTrash: '',
    // ラウンド・ラウンドプラス 清掃回数
    rlsCleaningTimes: '',
    plusCleaningTimes: '',
    // ラウンドプラス ゴミ搬出
    rlsPlusTrash: '',
    // 日常清掃
    normal: [{
      times: '',
      hours: '',
      specific: '',
      holiday: '',
      toiletTimes: '0',
      toiletPlace: '0',
      startTime: '',
      endTime: ''
    }],
    // 管理員
    mgt: [{
      times: '',
      hours: '',
      specific: '',
      holiday: '',
      startTime: '',
      endTime: ''
    }],
    // ガラス清掃回数
    glassTimes: '',
    // 植栽剪定回数
    plantingTimes: '',
    // メモ
    memo: {
      rls: '',
      rlsPlus: '',
      normal: '',
      mgt: '',
      fixed: '',
      glass: '',
      dorainPipe: '',
      waterTank: '',
      plant: '',
      sp: '',
      rlsSub: ''
    }
  },
  // 承認差戻理由
  reasonForSendingBack: '',
  // 手動で単価を変更したか false:していない
  priceChange: false,
  // 承認に関する内容
  approvalInfo: {
    // 1番目の承認者へチャット送信 false:しない
    chatwork1: false,
    // 2番目の承認者へチャット送信 false:しない
    chatwork2: false,
    // 1番目の承認者へのメッセージ
    message1: '',
    // 2番目の承認者へのメッセージ
    message2: '',
    // ルート名
    route: '',
  },
  // 1番目の承認ルート
  approvalFlow1: [{
    sequentialOrder: '1',
    userId: '',
    userName: '',
    chatworkAccountId: '',
    chatworkRoomId: '',
    chatworkApiToken: '',
    status: '未承認',
    stamp: '',
    date: null
  }],
  // 2番目の承認ルート
  approvalFlow2: [{
    sequentialOrder: '2',
    userId: '',
    userName: '',
    chatworkAccountId: '',
    chatworkRoomId: '',
    chatworkApiToken: '',
    status: '未承認',
    stamp: '',
    date: null
  }],
  // 承認1ステータス
  approval1: '---',
  // 承認2ステータス
  approval2: '---',
  // 承認申請
  approvalPetition: false,
  // 承認申請した日時
  approvalPetitionDate: null,
  // 複写
  allCopy: false,
  // 複写時の変更 定期
  isChangeRegular: false,
  // 複写時の変更 特別
  isChangeSp: false,
  // 複写時の変更 ラウンドサブの内容
  isChangeSubs: [],
  // 複写時の変更 その他作業の内容
  isChangeOther: [],
  // テストで作成した見積り
  test: false
}

export const EstimateDetailSchema = {
  index: 0,
  // バリデート
  invalid: false,
  // エラーメッセ
  invalidMessage: '',
  // カテゴリー名
  categoryName: '',
  // カテゴリーコード
  categoryCode: null,
  // カテゴリー数量
  categoryQty: '',
  // カテゴリー単位名
  categoryUnitName: '',
  // カテゴリー単価
  categoryUnitPrice: '',
  // カテゴリー合計金額
  categoryAmount: '',
  // 詳細項目名
  itemName: '',
  // 詳細数量
  itemQty: '',
  // 詳細単位名
  itemUnitName: '',
  // 詳細単価
  itemUnitPrice: '',
  // 詳細ツールチップ
  listForTooltip: [],
  // 詳細選択時の特記事項
  // selectedNoteCode: [],
  // 詳細モーダルデータ
  subItemModalData: {},
  // rowspan
  rowspan: 1,
  // セル結合グループ名
  groupName: '0',
  // 手動で価格変更したらtrue
  detailBgColor: false,
  categoryBgColor: false,
  // 詳細モーダルで使用する単価を手動で変更したらtrue
  iconBgColor: false,
  // モーダルを開いて編集していない：false
  modal: false,
  // 行のタイプ
  type: '',
  // 手動で発注金額を変更した場合
  changePurchasePrice: false
}

export const EstimateAttachmentSchema = {
  originalName: '',
  url: '',
  content: ''
}

/**
 * 見積り番号に紐づく見積りデータ取得
 * @param {*} context 
 * @param {*} estimateNo 見積り番号
 * @returns 
 */
export async function getEstimate(context, estimateNo) {
  const searchCondition = {
    searchCondition: {
      type: ESTIMATES_COLLECTION_NAME,
      estimateNo
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const estimate = responseData[0] || null
    return estimate
  } catch (error) {
    console.log('getEstimate error !!!')
    console.log(error)
    return null
  }
}

/**
 * タスク依頼IDに紐づく見積りを取得
 * @param {*} context 
 * @param {*} taskRequestId タスクID
 * @returns 
 */
export async function getEstimateByTaskRequestId(context, taskRequestId) {
  const searchCondition = {
    searchCondition: {
      type: ESTIMATES_COLLECTION_NAME,
      taskRequestId,
      status: { $ne: "remove" }
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const estimate = responseData || null
    return estimate
  } catch (error) {
    console.log('getEstimate error !!!')
    console.log(error)
    return null
  }
}

/**
 * 特記事項マスターデータ取得
 * @param {*} context 
 * @param {*} EstimateNoteCode 特記事項コード
 * @returns 
 */
export async function getEstimateNoteText(context, EstimateNoteCode) {
  const searchCondition = {
    searchCondition: {
      type: ESTIMATES_NOTE_COLLECTION_NAME,
      code: EstimateNoteCode
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const note = responseData[0].note || null
    return note
  } catch (error) {
    console.log('getEstimateNoteText error !!!')
    console.log(error)
    return null
  }
}

/**
 * 共通特記事項番号MAX取得
 * @param {*} context 
 * @returns 
 */
async function getEstimateCommonNoteCode(context) {
  const res = await context.$pigeon.generalSearch(
    'CreateCode',
    { 'type': 'estimateCommonNoteCode' }
  )
  return res[0].max
}

/**
 * 共通特記事項番号MAX保存
 * @param {*} context 
 * @param {*} max code
 * @returns 
 */
async function setEstimateCommonNoteCode(context, max) {
  await context.$pigeon.generalUpdate(
    'CreateCode',
    { 'type': 'estimateCommonNoteCode' },
    { max }
  )
}

/**
 * 共通の特記事項マスター全データ取得
 * @param {*} context 
 * @returns 
 */
export async function getEstimateCommonNoteText(context) {
  try {
    const responseData = await context.$pigeon.getTasksByFormId(ESTIMATES_COMMON_NOTE_COLLECTION_NAME)
    const result = responseData || null
    return result
  } catch (error) {
    console.log('getEstimateCommonNoteText error !!!')
    console.log(error)
    return null
  }
}

/**
 * 共通の特記事項新規登録
 * @param {*} context 
 * @param {*} note 共通の特記事項
 * @returns 
 */
export async function registerEstimateCommonNote(context, note) {
  try {
    const max = await getEstimateCommonNoteCode(context)
    const code = max + 1
    note.estimateCommonNoteCode = String(code)
    await context.$pigeon.registerTask(ESTIMATES_COMMON_NOTE_COLLECTION_NAME, note, {})
    await setEstimateCommonNoteCode(context, code)
    return true
  } catch (error) {
    console.log('registerEstimateCommonNote error !!!')
    console.log(error)
    return false
  }
}

/**
 * 共通の特記事項更新
 * @param {*} context 
 * @param {*} note 共通の特記事項f
 * @returns 
 */
export async function updateEstimateCommonNote(context, note) {
  try {
    note.type = ESTIMATES_COMMON_NOTE_COLLECTION_NAME
    await context.$pigeon.updateTask(note._id, note, {})
    return true
  } catch (error) {
    console.log('updateEstimateCommonNote error !!!')
    console.log(error)
    return false
  }
}

/**
 * 共通特記事項削除 物理削除
 * @param {*} context 
 * @param {*} estimateCommonNoteCode コード
 * @returns 
 */
export async function deleteEstimateCommonNote(context, estimateCommonNoteCode) {
  try {
    const deleteCondition = {
      type: ESTIMATES_COMMON_NOTE_COLLECTION_NAME,
      estimateCommonNoteCode
    }
    await context.$pigeon.generalDelete('Tasks', deleteCondition)
    return true
  } catch (error) {
    console.log('deleteEstimateCommonNote error !!!')
    console.log(error)
    return false
  }
}

/**
 * 得意先コードで得意先データ取得
 * @param {*} clientCode 得意先コード
 * @returns 
 */
export async function getClientByCode(clientCode) {
  if (!clientCode || clientCode == '') {
    return null
  }
  try {
    // パラメータの得意先名検索、得意先支店名検索は空文で固定、完全一致検索（likeFlag: false）
    const result = await backend.searchData('akbs/getClient', { clientCode, client: '', clientDepartment: '', likeFlag: false })
    if (result.data && result.data.data && result.data.data.length) {
      return result.data.data[0]
    } else {
      return null
    }
  } catch (error) {
    console.log('getClientByCode error !!!')
    console.log(error)
    return null
  }
}

/**
 * 得意先データ取得
 * @param {*} filter 検索対象
 * @returns 
 */
export async function getClient(filter) {
  // 得意先コード検索
  let clientCode = ''
  // 得意先名検索
  let client = ''
  // 得意先支店名、部署名
  let clientDepartment = ''
  // 部分一致(固定)
  const likeFlag = true

  if (filter) {
    if (filter.clientCode) {
      clientCode = filter.clientCode
    }
    if (filter.client) {
      client = filter.client
    }
    if (filter.clientDepartment) {
      clientDepartment = filter.clientDepartment
    }
  }
  try {
    const result = await backend.searchData('akbs/getClient', { clientCode, client, clientDepartment, likeFlag })
    if (result.data && result.data.data) {
      return result.data.data
    } else {
      return null
    }
  } catch (error) {
    console.log('getClientByCode error !!!')
    console.log(error)
    return null
  }
}

/**
 * 承認者全データ取得
 * @param {*} context 
 * @returns 
 */
export async function getApprover(context) {
  try {
    const responseData = await context.$pigeon.getTasksByFormId(APPROVER_COLLECTION_NAME)
    const result = responseData || null
    return result
  } catch (error) {
    console.log('getApprover error !!!')
    console.log(error)
    return null
  }
}

/**
 * 承認者データ取得_承認番号を指定
 * @param {*} context 
 * @param {*} sequentialOrder
 * @returns 
 */
export async function getApproverByNumber(context, sequentialOrder) {
  const searchCondition = {
    searchCondition: {
      type: APPROVER_COLLECTION_NAME,
      sequentialOrder
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const result = responseData
    return result
  } catch (error) {
    console.log('getApproverByNumber error !!!')
    console.log(error)
    return []
  }
}

/**
 * 承認マスタ 特定のルート名の承認データを削除
 * @param {*} context 
 * @param {*} routeName ルート名
 * @returns 
 */
export async function deleteApprover(context, routeName) {
  try {
    const deleteCondition = {
      type: APPROVER_COLLECTION_NAME,
      routeName: routeName
    }
    await context.$pigeon.generalDelete('Tasks', deleteCondition)
    return true
  } catch (error) {
    console.log('deleteApprover error !!!')
    console.log(error)
    return false
  }
}

/**
 * Tasksデータ登録
 * @param {*} context 
 * @param {*} data 登録データ
 * @returns 
 */
export async function registerTasks(context, type, data) {
  try {
    await context.$pigeon.registerTask(type, data, {})
    return true
  } catch (error) {
    console.log('registerEstimate error !!!')
    console.log(error)
    return false
  }
}

/**
 * Tasksデータ更新_汎用
 * @param {*} context 
 * @param id 更新するID
 * @param {*} data 更新データ
 * @returns 
 */
export async function updateTasks(context, id, data) {
  try {
    await context.$pigeon.updateTask(id, data, {})
    return true
  } catch (error) {
    console.log('updateTasks error !!!')
    console.log(error)
    return false
  }
}

/**
 * 見積単価更新
 * @param {*} context 
 * @param id 更新するID
 * @param {*} data 更新データ
 * @returns 
 */
export async function updateEstimatePrice(context, id, data) {
  try {
    await context.$pigeon.updateTask(id, data, {})
    return true
  } catch (error) {
    console.log('updateTasks error !!!')
    console.log(error)
    return false
  }
}

/**
 * 見積り作成の他の見積もりから明細を引用リスト取得
 * @param {*} context 
 * @param {*} filter 検索条件
 * @returns 
 */
export async function quoteOtherEstimation(context, filter) {
  const conditions = {}
  // 画面で入力された検索条件を成形
  if (filter && Object.keys(filter)) {
    const keys = Object.keys(filter)
    keys.forEach((k) => {
      // 期間以外
      if (k != 'periodPresetName' && k != 'period') {
        if (filter[k]) {
          conditions[k] = { $regex: filter[k], $options: 'i'}
        }
      }
    })
  }

  const searchCondition = {
    searchCondition: {
      type: ESTIMATES_COLLECTION_NAME,
      status: { $ne: "remove" },
      ...conditions
    },
    sort: {
      estimateNo: -1
    }
  }

  // 期間 条件指定（見積り作成日）
  if (filter && filter.period) {
    searchCondition.searchCondition['estimateDate'] = {}
    if (filter.period.start) {
      searchCondition.searchCondition['estimateDate'].$gte = filter.period.start
    }
    if (filter.period.end) {
      searchCondition.searchCondition['estimateDate'].$lte = filter.period.end
    }
    if (Object.keys(searchCondition.searchCondition['estimateDate']).length === 0) {
      delete searchCondition.searchCondition['estimateDate']
    }
  }
  // console.log(searchCondition)
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const estimates = responseData
    return estimates
  } catch (error) {
    console.log('quoteOtherEstimation error !!!')
    alert(error)
    return []
  }
}

/**
 * リクエストポストで紐づく複写した見積りデータ取得
 * @param {*} context 
 * @param {*} requestPostId リクエストポストID
 * @returns 
 */
export async function getByRequestPostIdAndCopy(context, requestPostId) {
  if (!requestPostId) {
    return []
  }

  const searchCondition = {
    searchCondition: {
      type: ESTIMATES_COLLECTION_NAME,
      status: { $ne: "remove" },
      requestPostId,
      allCopy: true
    },
    sort: {
      estimateNo: 1
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    return responseData
  } catch (error) {
    console.log('getByRequestPostIdAndCopy error !!!')
    console.log(error)
    return []
  }
}

/**
 * 見積り登録
 * @param {*} context 
 * @param {*} estimate 見積りデータ
 * @returns 
 */
export async function registerEstimate(context, estimate) {
  try {
    const estimateNo = await createEstimateNo(context, estimate)
    estimate.estimateNo = estimateNo
    const registrationData = createRegistrationData(estimate)
    await context.$pigeon.registerTask(ESTIMATES_COLLECTION_NAME, registrationData, {})
    return estimateNo
  } catch (error) {
    console.log('registerEstimate error !!!')
    console.log(error)
    return null
  }
}

/**
 * 見積りデータ更新
 * @param {*} context 
 * @param {*} estimate 見積りデータ
 * @returns 
 */
export async function updateEstimate(context, estimate) {
  try {
    // await context.$pigeon.updateTask(estimate._id, estimate, {})
    const registrationData = createRegistrationData(estimate)
    registrationData.type = ESTIMATES_COLLECTION_NAME
    console.log(registrationData)
    await context.$pigeon.updateTask(registrationData._id, registrationData, {})
    return true
  } catch (error) {
    console.log('updateEstimate error !!!')
    console.log(error)
    return false
  }
}

/**
 * 見積り削除
 * @param {*} context 
 * @param {*} estimate 見積りデータ
 * @returns 
 */
export async function deleteEstimate(context, estimate) {
  try {
    await context.$pigeon.deleteTask(estimate._id)
    return true
  } catch (error) {
    console.log('deleteEstimate error !!!')
    console.log(error)
    return false
  }
}

/**
 * 見積り削除 物理削除
 * @param {*} context 
 * @param {*} estimateNo 見積番号
 * @returns 
 */
export async function deleteEstimate2(context, estimateNo) {
  try {
    const deleteCondition = {
      type: ESTIMATES_COLLECTION_NAME,
      estimateNo: estimateNo
    }
    await context.$pigeon.generalDelete('Tasks', deleteCondition)
    return true
  } catch (error) {
    console.log('deleteEstimate2 error !!!')
    console.log(error)
    return false
  }
}

/**
 * 見積りステータス更新
 * @param {*} context 
 * @param {*} estimateId 見積りID
 * @param {*} estimateStatus 見積りステータス
 * @returns 
 */
export async function changeEstimateStatus(context, estimateId, estimateStatus) {
  const updateData = {
    type: ESTIMATES_COLLECTION_NAME,
    estimateStatus
  }
  try {
    await context.$pigeon.updateTask(estimateId, updateData, {})
    return true
  } catch (error) {
    console.log('changeEstimateStatus error !!!')
    console.log(error)
    return false
  }
}

/**
 * 見積り受注ステータス更新（受注タスクが完了になった場合）
 * @param {*} context 
 * @param {*} estimateNo 見積り番号
 * @param {*} orderStatus 受注ステータス
 * @param {*} name ログインユーザー
 * @returns 
 */
export async function changeOrderStatus(context, estimateNo, orderStatus, name) {

  try {
    // 見積番号で見積りIDを取得
    const res = await getEstimate(context, estimateNo)
    if (!res.orderStatus) {
      res.orderStatus = {}
    }
    // 変更
    res.orderStatus.status = orderStatus
    res.orderStatus.done = name
    res.orderStatus.doneDate = moment().format('YYYY-MM-DD HH:mm:ss')
    // 更新
    await updateEstimate(context, res)
    return true
  } catch (error) {
    console.log('changeOrderStatus error !!!')
    console.log(error)
    return false
  }
}

/**
 * 見積りデータ検索_見積一覧
 * @param {*} context 
 * @param {*} searchKeys 検索対象プロパティ
 * @param {*} keyword キーワード
 * @param {*} clientName 得意先名
 * @param {*} buildingName 現場
 * @param {*} subject お問い合わせ内容
 * @param {*} categoryName カテゴリー名
 * @param {*} periodKey 日付絞込み対象プロパティ
 * @param {*} period 期間
 * @param {*} selectFields 表示項目
 * @param {*} jutaku 受託、もしくは管理者（である場合、テストデータも表示）
 * @param {*} classification 区分検索
 * @returns 
 */
export async function searchEstimates(context, searchKeys, keyword, clientName, buildingName, subject, categoryName, periodKey, period, selectFields, jutaku, classification) {
  
  const searchCondition = {
    searchCondition: {
      type: ESTIMATES_COLLECTION_NAME,
      status: { $ne: "remove" }
    },
    sort: {
      estimateDate: -1,
      createDate: -1
    }
  }

  // 受託、管理者でもない場合は、テストデータ以外を表示
  if (!jutaku) {
    searchCondition.searchCondition.test = { $ne: true }
  }

  // 期間 条件指定
  if (periodKey && period) {
    searchCondition.searchCondition[periodKey] = {}
    if (period.start) {
      searchCondition.searchCondition[periodKey].$gte = period.start
    }
    if (period.end) {
      searchCondition.searchCondition[periodKey].$lte = period.end
    }
    if (Object.keys(searchCondition.searchCondition[periodKey]).length === 0) {
      delete searchCondition.searchCondition[periodKey]
    }
    // {
    //   $gte: period.start,
    //   $lte: period.end
    // }
  }

  // キーワード 条件指定
  const keywordCondition = buildMultipleKeywordSearchCondition(keyword || '', searchKeys|| [])
  if (keywordCondition) {
    searchCondition.searchCondition = { ...searchCondition.searchCondition, ...keywordCondition } //.$and = keywordCondition
  }

  // 得意先名 条件指定
  if (clientName && clientName.length > 0) searchCondition.searchCondition['clientName'] = { $regex: clientName, $options: 'i'}

  // 現場 条件指定
  if (buildingName && buildingName.length > 0) searchCondition.searchCondition['buildingName'] = { $regex: buildingName, $options: 'i'}

  // お問い合わせ内容 条件指定
  if (subject && subject.length > 0) searchCondition.searchCondition['subject'] = { $regex: subject, $options: 'i'}

  // 見積もりカテゴリー名 条件指定
  if (categoryName && categoryName.length > 0) searchCondition.searchCondition['details'] = { $elemMatch: { 'categoryName': { $regex: categoryName, $options: 'i' }} }

  // 表示項目 指定
  if (selectFields) {
    searchCondition.selectFields = selectFields
  }

  // 区分 条件指定
  if (classification) {
    searchCondition.searchCondition['classification'] = classification
  }

  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const estimates = responseData || []
    estimates.forEach((e) => {
      if (e.withoutTax == '') {
        e.withoutTax = '0'
      }
      if (e.totalAmount == '') {
        e.totalAmount = '0'
      }
    })
    return estimates
  } catch (error) {
    console.log('searchEstimates error !!!')
    console.log(error)
    return []
  }
}

/**
 * メインタスクが終了していない見積りデータ検索_見積一覧
 * @param {*} context 
 * @param {*} searchKeys 検索対象プロパティ
 * @param {*} keyword キーワード
 * @param {*} clientName 得意先名
 * @param {*} buildingName 現場
 * @param {*} subject お問い合わせ内容
 * @param {*} categoryName 見積りカテゴリー名
 * @param {*} status ステータス
 * @param {*} periodKey 日付絞込み対象プロパティ
 * @param {*} period 期間
 * @param {*} selectFields 表示項目
 * @param {*} requestPostIds メインタスクID
 * @param {*} jutaku 受託所属、もしくは管理者（であればテストデータも表示）
 * @param {*} classification 区分検索
 * @returns 
 */
export async function getWithMainIncomplete(context, searchKeys, keyword, clientName, buildingName, subject, categoryName, periodKey, period, selectFields, requestPostIds, jutaku, classification) {
  
  const searchCondition = {
    searchCondition: {
      type: ESTIMATES_COLLECTION_NAME,
      status: { $ne: "remove" },
      requestPostId: { $in: requestPostIds }
    },
    sort: {
      estimateDate: -1,
      createDate: -1
    }
  }

  // 受託、管理者でもない場合は、テストデータ以外を表示
  if (!jutaku) {
    searchCondition.searchCondition.test = { $ne: true }
  }

  // 期間 条件指定
  if (periodKey && period) {
    searchCondition.searchCondition[periodKey] = {}
    if (period.start) {
      searchCondition.searchCondition[periodKey].$gte = period.start
    }
    if (period.end) {
      searchCondition.searchCondition[periodKey].$lte = period.end
    }
    if (Object.keys(searchCondition.searchCondition[periodKey]).length === 0) {
      delete searchCondition.searchCondition[periodKey]
    }
  }

  // キーワード 条件指定
  const keywordCondition = buildMultipleKeywordSearchCondition(keyword || '', searchKeys|| [])
  if (keywordCondition) {
    searchCondition.searchCondition = { ...searchCondition.searchCondition, ...keywordCondition } //.$and = keywordCondition
  }

  // 得意先名 条件指定
  if (clientName && clientName.length > 0) searchCondition.searchCondition['clientName'] = { $regex: clientName, $options: 'i'}

  // 現場 条件指定
  if (buildingName && buildingName.length > 0) searchCondition.searchCondition['buildingName'] = { $regex: buildingName, $options: 'i'}

  // お問い合わせ内容 条件指定
  if (subject && subject.length > 0) searchCondition.searchCondition['subject'] = { $regex: subject, $options: 'i'}

  // 見積もりカテゴリー名 条件指定
  if (categoryName && categoryName.length > 0) searchCondition.searchCondition['details'] = { $elemMatch: { 'categoryName': { $regex: categoryName, $options: 'i' }} }

  // 表示項目 指定
  if (selectFields) {
    searchCondition.selectFields = selectFields
  }

  // 区分 条件指定
  if (classification) {
    searchCondition.searchCondition['classification'] = classification
  }

  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const estimates = responseData || []
    estimates.forEach((e) => {
      if (e.withoutTax == '') {
        e.withoutTax = '0'
      }
      if (e.totalAmount == '') {
        e.totalAmount = '0'
      }
    })
    return estimates
  } catch (error) {
    console.log('searchEstimates error !!!')
    console.log(error)
    return []
  }
}


/**
 * 作成完了の見積データ取得_メイン受注処理のダイアログ
 * @param {*} context 
 * @param {*} estimateNo 見積番号検索
 * @param {*} clientName 得意先名検索
 * @param {*} buildingName 建物名検索
 * @param {*} periodCreate 作成日期間検索
 * @param {*} taskStaff 担当者検索
 * @returns 
 */
export async function getEstimateForOrder(context, estimateNo, clientName, buildingName, periodCreate, taskStaff) {
  
  const searchCondition = {
    searchCondition: {
      type: ESTIMATES_COLLECTION_NAME,
      estimateStatus: '作成完了',
      status: { $ne: "remove" }
    },
    sort: {
      estimateNo: -1
    }
  }

  // 作成日期間 条件指定
  if (periodCreate) {
    searchCondition.searchCondition['estimateDate'] = {}
    if (periodCreate.start) {
      searchCondition.searchCondition['estimateDate'].$gte = periodCreate.start
    }
    if (periodCreate.end) {
      searchCondition.searchCondition['estimateDate'].$lte = periodCreate.end
    }
    if (Object.keys(searchCondition.searchCondition['estimateDate']).length === 0) {
      delete searchCondition.searchCondition['estimateDate']
    }
  }

  // 見積番号 条件指定
  if (estimateNo && estimateNo.length > 0) searchCondition.searchCondition['estimateNo'] = { $regex: estimateNo, $options: 'i'}

  // 得意先名 条件指定
  if (clientName && clientName.length > 0) searchCondition.searchCondition['clientName'] = { $regex: clientName, $options: 'i'}

  // 建物名 条件指定
  if (buildingName && buildingName.length > 0) searchCondition.searchCondition['buildingName'] = { $regex: buildingName, $options: 'i'}

  // 担当者 条件指定
  if (taskStaff && taskStaff.length > 0) searchCondition.searchCondition['taskStaff'] = { $regex: taskStaff, $options: 'i'}

  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const estimates = responseData || []
    estimates.forEach((e) => {
      if (e.withoutTax == '') {
        e.withoutTax = '0'
      }
      if (e.totalAmount == '') {
        e.totalAmount = '0'
      }
    })
    return estimates
  } catch (error) {
    console.log('getEstimateForOrder error !!!')
    console.log(error)
    return []
  }
}

export async function searchEstimateDetailHistory(context, filter, keyword, period) {

  const searchCondition = {
    searchCondition: {
      type: ESTIMATES_COLLECTION_NAME,
      status: { $ne: "remove" },
      ...filter || {}
    },
    sort: {
      estimateDate: -1,
      createDate: -1
    },
    selectFields: [
      'details.$',
      'categories'
    ]
  }

  if (period) {
    const date = utils.getDate(`${period} days ago`)
    searchCondition.searchCondition['estimateDate'] = { $gte: date }
  }

  if (keyword) {
    searchCondition.searchCondition['details.itemName'] = { $regex: keyword, $options: 'i'}
  }

  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const estimates = responseData || []
    
    return estimates
  } catch (error) {
    console.log('getEstimate error !!!')
    console.log(error)
    return []
  }
}

/**
 * 初期データ作成
 * @returns 
 */
export function createNewEstimateModel() {
  const estimateDate = utils.getToday()
  const expireDate = utils.addMonths(estimateDate, 3)

  const newEstimate = { ...utils.clone(EstimateSchema), estimateDate, expireDate }

  for (let i = 0; i < 1; i++) {
    newEstimate.details.push(createEmptyDetail())
  }

  newEstimate.attachment1 = createEmptyAttachment()
  newEstimate.attachment2 = createEmptyAttachment()
  newEstimate.attachment3 = createEmptyAttachment()

  return newEstimate
}

export function createEmptyDetail() {
  const emptyDetail = utils.clone(EstimateDetailSchema) // { ...EstimateDetailSchema }
  return emptyDetail
}

export function createEmptyAttachment() {
  const emptyAttachmen = utils.clone(EstimateAttachmentSchema) // { ...EstimateAttachmentSchema }
  return emptyAttachmen
}

/**
 * コピー元を取得し、データを生成
 * @param {*} context 
 * @param {*} copyEstimateNo 
 * @returns 
 */
export async function copyEstimate(context, copyEstimateNo) {
  // コピー元の見積りを取得
  let copiedEstimate = await getEstimate(context, copyEstimateNo)
  if (copiedEstimate) {
    // console.log(copiedEstimate)
    copiedEstimate = formatCopy(copiedEstimate)

    return copiedEstimate
  } else {
    return null
  }
}

/**
 * コピーするデータを成形
 * @param {*} copiedEstimate コピー元データ
 */
export function formatCopy (copiedEstimate) {
  // コピー元見積No
  copiedEstimate.copyEstimateNo = copiedEstimate.estimateNo

  // 余計なフィールドを削除＆初期化
  copiedEstimate.estimateType = 'normal'
  copiedEstimate.estimateNo = ''
  copiedEstimate.estimateStatus = '作成中'
  copiedEstimate.estimateDate = utils.getToday()
  copiedEstimate.expireDate = utils.addMonths(copiedEstimate.estimateDate, 3)
  copiedEstimate.reasonForSendingBack = ''

  // 承認に関する内容
  copiedEstimate.approvalInfo = {
    // 1番目の承認者へチャット送信 false:しない
    chatwork1: false,
    // 2番目の承認者へチャット送信 false:しない
    chatwork2: false,
    // 1番目の承認者へのメッセージ
    message1: '',
    // 2番目の承認者へのメッセージ
    message2: '',
    // ルート名
    route: '',
  }
  
  // 1番目の承認ルート
  copiedEstimate.approvalFlow1 = [{
    sequentialOrder: '1',
    userId: '',
    userName: '',
    chatworkAccountId: '',
    chatworkRoomId: '',
    chatworkApiToken: '',
    status: '未承認',
    stamp: ''
  }]
  // 2番目の承認ルート
  copiedEstimate.approvalFlow2 = [{
    sequentialOrder: '2',
    userId: '',
    userName: '',
    chatworkAccountId: '',
    chatworkRoomId: '',
    chatworkApiToken: '',
    status: '未承認',
    stamp: ''
  }]
  // 承認1ステータス
  copiedEstimate.approval1 = '---'
  // 承認2ステータス
  copiedEstimate.approval2 = '---'

  delete copiedEstimate._id
  delete copiedEstimate.status
  delete copiedEstimate.permalink
  delete copiedEstimate.createDate
  delete copiedEstimate.upDate
  delete copiedEstimate.user
  delete copiedEstimate.approvalPetitionDate
  delete copiedEstimate.orderStatus

  // 添付ファイル
  for (let i = 1; i < 4; i++) {
    const name = 'attachment' + i
    copiedEstimate[name] = copyAttachment(copiedEstimate, copiedEstimate[name], name)
  }
  return copiedEstimate
}

/**
 * 添付資料をコピー
 * @param {*} copiedEstimate コピー元データ
 * @param {*} tar 対象添付データ
 * @param {*} name 対象プロパティ名
 * @returns 
 */
function copyAttachment(copiedEstimate, tar, name) {
  let res = createEmptyAttachment()
  // 添付がある場合
  if (tar && tar.url) {
    // コピーを示すプロパティ
    copiedEstimate[name + '_file_content'] = tar.url
    copiedEstimate[name + '_file_name'] = tar.originalName
    copiedEstimate[name + '_display_file_name'] = tar.originalName
    // 添付内容をコピー
    res = utils.clone(tar)
  }
  return res
}

export function buildPrintUrlForDirectPrint(estimate, token, target) {
  let filename = utils.formatString(utils.formatDate(new Date(), ESTIMATE_PDF_FILE_NAME), estimate)
  // 注文書の場合
  if (target == 'Order') {
    filename = utils.formatString(utils.formatDate(new Date(), ESTIMATE_PDF_FILE_NAME_ORDER), estimate)
  }

  const pigeonBaseUrl = PIGEON_CONFIG.baseUrl
  const pigeonPrintUrl = `${pigeonBaseUrl}print?taskId=${estimate._id}&token=${token}&noconvertpdf=true&target=${target}`
  // console.log(pigeonPrintUrl)

  const directPrintBaseUrl = PIGEON_CONFIG.printApiEndpointBase
  const directPrintUrl = `${directPrintBaseUrl}?url=${encodeURIComponent(pigeonPrintUrl)}&format=pdf&printPageNumber=true&pageNumberPos=header&pageNumberAlign=right&&pdfFileName=${encodeURIComponent(filename)}`

  return directPrintUrl
}

export function buildMergePrintUrl(printUrlList, estimate) {
  const encodedUrlList = printUrlList.map((url) => {
    return encodeURIComponent(url)
  })
  const q = encodedUrlList.join('&url=')

  const filename = utils.formatString(utils.formatDate(new Date(), ESTIMATE_PDF_FILE_NAME), estimate)

  const directPrintBaseUrl = PIGEON_CONFIG.printApiEndpointBase
  const mergePrintUrl = `${directPrintBaseUrl}/merge?url=${q}&pdfFileName=${encodeURIComponent(filename)}`

  return  mergePrintUrl
}

export function buildAttachmentUrl(attachmentUrl, token) {
  if (attachmentUrl && token) {
    const pigeonBaseUrl = PIGEON_CONFIG.baseUrl
    const url = `${pigeonBaseUrl}${attachmentUrl}?token=${token}`
    return url
  } else {
    return ''
  }
}

// export async function createCoverPage(context, estimate, numPages) {

//   const senderId = estimate.salesPersonId || estimate.operationPersonId
//   const userList = context.$store.getters.userList
//   const sender = userList.find((u) => {
//     return u.id === senderId 
//   })

//   const newCoverPage = coverPageManager.createNewCoverPageModel()

//   newCoverPage.title = 'ＦＡＸ送付のご案内'
//   newCoverPage.clientName = estimate.clientName
//   newCoverPage.siteName = estimate.siteName
//   newCoverPage.clientPersonName = estimate.clientPersonName
//   newCoverPage.phoneNumber = estimate.phoneNumber
//   newCoverPage.faxNumber = estimate.fax
//   // newCoverPage.mobilePhoneNumber = estimate.mobilePhoneNumber
//   newCoverPage.senderPersonName = sender ? sender.user_name : ''
//   newCoverPage.senderEmail = sender ? sender.e_mail_adress : ''
//   newCoverPage.subject = `${estimate.subject}の御見積書`
//   newCoverPage.numSend = numPages ? String(numPages + 1) : ''
//   newCoverPage.introductionText = FEATURES.ESTIMATION.COVER_PAGE.INTRODUCTION_TEXT
//   newCoverPage.bodyText = estimate.coverPageAdditionalText ? `${FEATURES.ESTIMATION.COVER_PAGE.BODY_TEXT}\n\n${estimate.coverPageAdditionalText}` : FEATURES.ESTIMATION.COVER_PAGE.BODY_TEXT
//   newCoverPage.conclusionText = FEATURES.ESTIMATION.COVER_PAGE.CONCLUSION_TEXT
//   newCoverPage.pdfFileName = utils.formatString(utils.formatDate(new Date(), ESTIMATE_COVER_PAGE_PDF_FILE_NAME), estimate)

//   const registerdCoverPage = await coverPageManager.registerCoverPageData(context, newCoverPage)

//   const printUrl = coverPageManager.buildCoverPagePrintUrlForDirectPrint(registerdCoverPage, context.$store.getters.user.token)

//   return printUrl
// }

function buildMultipleKeywordSearchCondition(keywordText, searchKeys) {
  keywordText = keywordText.trim()
  if (keywordText === '') {
    return null
  } else {
    const keywords = keywordText.split(/\s+/g)
    const condition = { $and: [] }
    keywords.forEach(function(keyword) {
      const query = { $or: [] }
      searchKeys.forEach(function(searchKey) {
        const expression = {}
        expression[searchKey] = { $regex: keyword, $options: 'i'}
        query.$or.push(expression)
      });
      condition.$and.push(query);
    });
    return condition;
  }
}

/**
 * 見積り番号生成
 * @param {*} context 
 * @param {*} estimate 見積りデータ
 * @returns 
 */
async function createEstimateNo(context, estimate) {
  const res = await context.$pigeon.getAutomaticNumber(
    ESTIMATES_COLLECTION_NAME,
    'estimateNo',
    'rp_estimate_no_setting',
    estimate
  )
  return res
}

function createAttachmentRegistrationData(registrationData, targetAttachmentName) {
  // 添付を引き継いでいる時は何もしない
  if (registrationData[`${targetAttachmentName}_display_file_name`]) {
    return
  }
  if (registrationData[targetAttachmentName]) {
    registrationData[`${targetAttachmentName}_file_content`] = registrationData[targetAttachmentName].content || ''
    registrationData[`${targetAttachmentName}_file_name`] = registrationData[targetAttachmentName].originalName || ''
    registrationData[`${targetAttachmentName}_display_file_name`] = registrationData[targetAttachmentName].originalName || ''
  } else {
    registrationData[`${targetAttachmentName}_file_content`] = ''
    registrationData[`${targetAttachmentName}_file_name`] = ''
    registrationData[`${targetAttachmentName}_display_file_name`] = ''
  }
  delete registrationData[targetAttachmentName]
}

/**
 * データを保存用に成形
 * @param {*} estimate 見積りデータ
 * @returns 
 */
function createRegistrationData(estimate) {
  const registrationData = utils.clone(estimate)

  // 見積り詳細のカテゴリー名で改行コードがあれば改行に変換
  registrationData.details = replaceNewLine(registrationData.details)
  // 発注明細のカテゴリー名で改行コードがあれば改行に変換
  registrationData.purchase = replaceNewLine(registrationData.purchase)
  // 手数料明細のカテゴリー名で改行コードがあれば改行に変換
  registrationData.commission = replaceNewLine(registrationData.commission)
  // 特記事項のカテゴリー名で改行コードがあれば改行に変換
  registrationData.noteList = replaceNewLine(registrationData.noteList)

  // Attachments
  createAttachmentRegistrationData(registrationData, 'attachment1')
  createAttachmentRegistrationData(registrationData, 'attachment2')
  createAttachmentRegistrationData(registrationData, 'attachment3')

  return registrationData
}

/**
 * カテゴリー名の改行コードを改行に置換
 * @param {*} data 対象データ
 */
function replaceNewLine (data) {
  // 見積り詳細のカテゴリー名で改行コードがあれば改行に変換
  if (data) {
    data.forEach((d) => {
      d.categoryName = utils.replaceNewLine(d.categoryName)
    })
  }
  return data
}

/**
 * 発注マスタデータ取得
 * @param {*} context 
 * @returns 
 */
export async function getPurchase(context) {
  try {
    const responseData = await context.$pigeon.getTasksByFormId(PURCHASE_COLLECTION_NAME)
    const result = responseData || null
    return result
  } catch (error) {
    console.log('getPurchase error !!!')
    console.log(error)
    return null
  }
}

/**
 * 発注番号MAX取得
 * @param {*} context 
 * @returns 
 */
async function getPurchaseCode(context) {
  const res = await context.$pigeon.generalSearch(
    'CreateCode',
    { 'type': 'purchaseCode' }
  )
  return res[0].max
}

/**
 * 発注番号MAX保存
 * @param {*} context 
 * @param {*} max code
 * @returns 
 */
async function setPurchaseCode(context, max) {
  await context.$pigeon.generalUpdate(
    'CreateCode',
    { 'type': 'purchaseCode' },
    { max }
  )
}

/**
 * 発注先新規登録
 * @param {*} context 
 * @param {*} data データ
 * @returns 
 */
export async function registerPurchase(context, data) {
  try {
    const max = await getPurchaseCode(context)
    const code = max + 1
    data.purchaseCode = String(code)
    await context.$pigeon.registerTask(PURCHASE_COLLECTION_NAME, data, {})
    await setPurchaseCode(context, code)
    return true
  } catch (error) {
    console.log('registerPurchase error !!!')
    console.log(error)
    return false
  }
}

/**
 * 発注データ更新
 * @param {*} context 
 * @param {*} data データ
 * @returns 
 */
export async function updatePurchase(context, data) {
  try {
    data.type = PURCHASE_COLLECTION_NAME
    await context.$pigeon.updateTask(data._id, data, {})
    return true
  } catch (error) {
    console.log('updatePurchase error !!!')
    console.log(error)
    return false
  }
}

/**
 * 発注削除 物理削除
 * @param {*} context 
 * @param {*} purchaseCode 発注先コード
 * @returns 
 */
export async function deletePurchase(context, purchaseCode) {
  try {
    const deleteCondition = {
      type: PURCHASE_COLLECTION_NAME,
      purchaseCode
    }
    await context.$pigeon.generalDelete('Tasks', deleteCondition)
    return true
  } catch (error) {
    console.log('deletePurchase error !!!')
    console.log(error)
    return false
  }
}

/**
 * 作業区分マスタデータ取得
 * @param {*} context 
 * @returns 
 */
export async function getWorkCode(context) {
  try {
    const responseData = await context.$pigeon.getTasksByFormId(WORK_CODE_COLLECTION_NAME)
    const result = responseData || null
    return result
  } catch (error) {
    console.log('getWorkCode error !!!')
    console.log(error)
    return null
  }
}

/**
 * 作業区分マスタ新規登録
 * @param {*} context 
 * @param {*} data データ
 * @returns 
 */
export async function registerWorkCode(context, data) {
  try {
    await context.$pigeon.registerTask(WORK_CODE_COLLECTION_NAME, data, {})
    return true
  } catch (error) {
    console.log('registerWorkCode error !!!')
    console.log(error)
    return false
  }
}

/**
 * 作業区分マスタデータ更新
 * @param {*} context 
 * @param {*} data データ
 * @returns 
 */
export async function updateWorkCode(context, data) {
  try {
    data.type = WORK_CODE_COLLECTION_NAME
    await context.$pigeon.updateTask(data._id, data, {})
    return true
  } catch (error) {
    console.log('updateWorkCode error !!!')
    console.log(error)
    return false
  }
}

/**
 * 作業区分マスタ削除 物理削除
 * @param {*} context 
 * @param {*} code 対象コード
 * @returns 
 */
export async function deleteWorkCode(context, code) {
  try {
    const deleteCondition = {
      type: WORK_CODE_COLLECTION_NAME,
      code
    }
    await context.$pigeon.generalDelete('Tasks', deleteCondition)
    return true
  } catch (error) {
    console.log('deleteWorkCode error !!!')
    console.log(error)
    return false
  }
}

