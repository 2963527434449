<template>
  <div>
    <PageHeader :title="`品質巡回 確認項目設定`" class="">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">

          <span class="text-white mr-auto">お客様に提出するExcelには表示されません</span>

          <PrimaryButton text="登録" size="normal" class="bg-green-600 px-2 py-2 w-36 mr-5" @click="save()">
            <LoadingIcon v-if="loading" slot="after" class="h-4 w-4 my-auto ml-2 text-white" />
          </PrimaryButton>

          <h1 class="text-lg font-bold text-white flex items-center mr-2">
            <Icon iconName="" class="w-7 h-7 mr-2" /> <span class="mt-0 ">{{ list.length }} 件</span>
          </h1>
        </div>
      </template>
      <template #page-header-content>
        <div class="pl-4 flex items-center">
          <!-- 並べ替え -->
          <div class="toggle flex items-center">
            <span class="text-white font-bold text-xs mr-2">並べ替え</span>
            <Toggle v-model="sortable" size="small"/>
          </div>

          <!-- 追加ボタン -->
          <PrimaryButton class="bg-blue-600 ml-auto mr-3" text="項目追加" @click="add()">
            <Icon iconName="PlusCircle" slot="before" class="h-4 w-4" />
          </PrimaryButton>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-24 w-screen bottom-4 mt-4 list">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="rounded-md border border-gray-200 overflow-auto h-full">
          <!-- 一覧 -->

          <div v-if="list">

            <div class="rounded main-height bg-white">
              <table class="main table-auto overflow-auto">
                <thead class="main sticky top-0">
                  
                  <tr v-if="list && list.length" class="border border-gray-200">
                    <th v-for="(c, index) in columns" :key="'c2'+index" scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-colu whitespace-pre-line">
                      {{c.title}}
                    </th>
                  </tr>
                </thead>

                <draggable 
                  key="list"
                  :list="list"
                  direction="vertical"
                  handle=".row-drag-handle"
                  tag="tbody"
                >

                  <tr v-for="(row, i) in list" :key="'l'+i" class="border border-gray-200 tracking-wider hover-color">

                    <td class="px-2 py-3 w-1/3 min-w-500px">
                      <!-- 点検箇所 -->
                      <div class="flex mb-2">
                        <div v-if="!sortable" class="text-blue-700 my-auto mr-1">◆</div>
                        <MenuIcon v-else class="text-blue-400 row-drag-handle cursor-pointer my-auto" />
                        <input
                          autocomplete="off"
                          type="text" 
                          v-model="row.classification"
                          class="w-full border border-gray-200 rounded py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-bold text-blue-700"
                        />
                      </div>
                      <!-- 点検内容 -->
                      <textarea
                        class="border-gray-200 w-full my-0.5 rounded text-gray-700 text-sm"
                        :rows="getRows(row.item)"
                        v-model="row.item"
                      />
                    </td>

                    <!-- 評価ボタン有無 -->
                    <td class="px-2 py-3 text-xs text-gray-700">
                      <div>
                        <input type="radio" :id="'isValueBtn'+i" :value="true" v-model="row.isValueBtn" @change="changeValuBtn(row)" />
                        <label :for="'isValueBtn'+i"> 評価ボタンあり</label>
                      </div>
                      <div class="mt-2">
                        <input type="radio" :id="'onlyNA'+i" :value="false" v-model="row.isValueBtn" />
                        <label :for="'onlyNA'+i"> 『該当なし』のみ</label>
                      </div>
                    </td>

                    <!-- 評価ボタンの種類 -->
                    <td class="px-2 py-1 whitespace-nowrap text-sm text-gray-700">
                      <div v-if="row.isValueBtn" class="w-44">
                        <div v-for="(v, i) in row.valueBtn" :key="'v'+i" class="flex">
                          <input
                            autocomplete="off"
                            type="text" 
                            v-model="row.valueBtn[i]"
                            class="w-full border border-gray-200 rounded py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                          <div class="ml-2 text-xs font-bold text-gray-400 my-auto">
                            <div v-if="i==0">
                              （0点）
                            </div>
                            <div v-else-if="i==1">
                              （1点）
                            </div>
                            <div v-else>
                              （2点）
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="text-xs text-gray-600">
                        ※該当なしボタンのみ
                      </div>
                    </td>

                    <!-- 必須内容 -->
                    <td class="px-2 py-3 text-xs text-gray-700">
                      <div>
                        <input type="checkbox" :id="'isPhotoRequired'+i" v-model="row.isPhotoRequired" />
                        <label :for="'isPhotoRequired'+i"> 『該当なし』以外の場合、写真必須</label>
                      </div>

                      <div class="mt-2">
                        <input type="checkbox" :id="'isNoteRequired'+i" v-model="row.isNoteRequired" />
                        <label :for="'isNoteRequired'+i"> 『該当なし』の場合、コメント必須</label>
                      </div>

                      <div class="mt-2">
                        <input type="checkbox" :id="'isHistory'+i" v-model="row.isHistory" />
                        <label :for="'isHistory'+i"> 『該当なし』の場合、次回に引き継ぐ</label>
                      </div>
                    </td>

                    <!-- 削除 -->
                    <td class="px-2 py-3">
                      <TrashIcon class="text-gray-400 w-7 h-7 cursor-pointer hover:text-gray-500 active:text-gray-600" @click="deleteRow(i)" />
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import draggable from "vuedraggable"
import Toggle from '@components/Toggle.vue'
import { TrashIcon, MenuIcon } from '@vue-hero-icons/solid'
import LoadingIcon from '@assets/loading-circle.svg' 
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import PageModel from '../../main/pages/PageModel.vue'

const row = { classification: '', item: '', isValueBtn: true, valueBtn: ['指摘多数', '指摘あり', '良好'], isPhotoRequired: false, isNoteRequired: false, isHistory: false }
const valueBtn = ['指摘多数', '指摘あり', '良好']

export default {
  extends: PageModel,
  components: {
    Icon,
    PrimaryButton,
    draggable,
    Toggle,
    TrashIcon,
    MenuIcon,
    PageHeader,
    LoadingIcon
  },

  data() {
    return {
      loading: false,
      
      // カラム
      columns: [
          {title: '点検箇所\r\n点検内容'},
          {title: '評価ボタン有無'},
          {title: '評価ボタン種類'},
          {title: 'その他条件'},
          {title: ''},
      ],
      // 表示するデータ
      list: [utils.clone(row)],
      // モーダル表示、非表示
      openCheckListSettingModal: false,
      // モーダルに渡すデータ
      row: {},
      // 並べ替え
      sortable: false,
    }
  },

  computed: {
    getRows() {
      return (val) => {
        if (val) {
          const count = (val.match(/\r\n|\n/g) || []).length
          if (count < 1) {
            return 1
          }
          return count + 1
        }
        return 1
      }
    }
  },

  created() {
    this.getData()
  },

  methods: {
    /**
     * データ取得
     */
    async getData() {
      this.loading = true
      this.list = []
      const res = await backend.searchData('patrolCheckItem/get', { type: 1 })
      if (res.data.data && res.data.data.length) {
        this.list = res.data.data
      }
      this.loading = false
    },

    /**
     * 保存
     */
    async save() {
      for (let i = 0; i < this.list.length; i++) {
        const row = this.list[i]
        if (!row.classification) {
          await dialogs.showErrorDialog('点検箇所未記入あり', (i + 1) + '番目の点検箇所を入力してください。')
          return
        }
        if (row.isValueBtn) {
          for (let j = 0; j < row.valueBtn.length; j++) {
            const v = row.valueBtn[j]
            if (!v) {
              await dialogs.showErrorDialog('評価ボタン未記入あり', (i + 1) + '番目の評価ボタン種類を3つとも入力してください。')
              return
            }
          }
        }
      }
      this.loading = true
      await backend.postData('patrolCheckItem/save', { list: this.list, user: this.$store.getters.user.id, type: 1 })
      this.loading = false
      await dialogs.showSuccessDialog('確認項目 登録', '確認項目を登録しました。')
      logManager.recording(this, logManager.Loglevel.INFO, '品質巡回 確認項目', '変更', '品質巡回の確認項目を変更しました。')
    },

    /**
     * 行追加
     */
    add() {
      this.list.push(utils.clone(row))
    },

    /**
     * 削除ボタンイベント
     * @param i インデックス
     */
    deleteRow(i) {
      this.list.splice(i, 1)
      if (!this.list || !this.list.length) {
        this.add()
      }
    },

    /**
     * 評価ボタンありに変更
     * @param row 対象データ
     */
    changeValuBtn(row) {
      row.valueBtn = utils.clone(valueBtn)
    },

    /**
     * モーダル閉じる
     */
    async modalClose() {
      this.openCheckListSettingModal = false
      await this.getData()
    },

  },
}
</script>
<style scoped>
.list {
  overflow: auto;
}

.main {
  width: 100%;
  min-width: 1200px;
}

.bg-colu {
  background: #ebf0fb;
}

.hover-color:hover {
  background: rgb(236, 236, 251);
}

</style>