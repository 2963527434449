import EvaluationApp from './EvaluationApp.vue'
import EvaluationInput from './pages/EvaluationInput.vue'
import EvaluationList from './pages/EvaluationList.vue'
import EnvReportList from './pages/EnvReportList.vue'
import EnvReportItemSetting from './pages/EnvReportItemSetting.vue'
import EnvReportCounting from './pages/EnvReportCounting.vue'
import Organization from './pages/Organization.vue'
import UnlockSetting from './pages/UnlockSetting.vue'
import EvaluationMovieMaster from './pages/EvaluationMovieMaster.vue'
import StudyPolicy from './pages/StudyPolicy.vue'
import StudyPolicyList from './pages/StudyPolicyList.vue'
import BossAverage from './pages/BossAverage.vue'
import DailyReport from './pages/DailyReport.vue'
import DailyReportList from './pages/DailyReportList.vue'
import EvaluationImport from './pages/EvaluationImport.vue'

export default {
  path: '/Evaluation',
  component: EvaluationApp,
  children: [
    {
      path: '/EvaluationInput',
      name: 'EvaluationInput',
      component: EvaluationInput,
      meta: { requiresAuth: true, title: 'apollo-RP - 評価シート' },
      props: true
    },
    {
      path: '/EvaluationList',
      name: 'EvaluationList',
      component: EvaluationList,
      meta: { requiresAuth: true, title: 'apollo-RP - 評価一覧' },
      props: true
    },
    {
      path: '/EnvReportList',
      name: 'EnvReportList',
      component: EnvReportList,
      meta: { requiresAuth: true, title: 'apollo-RP - 環境整備一覧' },
      props: true
    },
    {
      path: '/EnvReportItemSetting',
      name: 'EnvReportItemSetting',
      component: EnvReportItemSetting,
      meta: { requiresAuth: true, title: 'apollo-RP - 環境整備項目設定' },
      props: true
    },
    {
      path: '/EnvReportCounting',
      name: 'EnvReportCounting',
      component: EnvReportCounting,
      meta: { requiresAuth: true, title: 'apollo-RP - 環境整備集計' },
      props: true
    },
    {
      path: '/Organization',
      name: 'Organization',
      component: Organization,
      meta: { requiresAuth: true, title: 'apollo-RP - 組織図' },
      props: true
    },
    {
      path: '/UnlockSetting',
      name: 'UnlockSetting',
      component: UnlockSetting,
      meta: { requiresAuth: true, title: 'apollo-RP - 先月編集可能設定' },
      props: true
    },
    {
      path: '/EvaluationMovieMaster',
      name: 'EvaluationMovieMaster',
      component: EvaluationMovieMaster,
      meta: { requiresAuth: true, title: 'apollo-RP - 動画設定' },
      props: true
    },
    {
      path: '/StudyPolicy',
      name: 'StudyPolicy',
      component: StudyPolicy,
      meta: { requiresAuth: true, title: 'apollo-RP - 感想文' },
      props: true
    },
    {
      path: '/StudyPolicyList',
      name: 'StudyPolicyList',
      component: StudyPolicyList,
      meta: { requiresAuth: true, title: 'apollo-RP - 感想文一覧' },
      props: true
    },
    {
      path: '/BossAverage',
      name: 'BossAverage',
      component: BossAverage,
      meta: { requiresAuth: true, title: 'apollo-RP - 上司評価平均' },
      props: true
    },
    {
      path: '/DailyReport',
      name: 'DailyReport',
      component: DailyReport,
      meta: { requiresAuth: true, title: 'apollo-RP - 日報' },
      props: true
    },
    {
      path: '/DailyReportList',
      name: 'DailyReportList',
      component: DailyReportList,
      meta: { requiresAuth: true, title: 'apollo-RP - 日報リスト' },
      props: true
    },
    {
      path: '/EvaluationImport',
      name: 'EvaluationImport',
      component: EvaluationImport,
      meta: { requiresAuth: true, title: 'apollo-RP - インポート' },
      props: true
    },
  ]
}
