<template>
  <div>
    <div v-if="sending" class="flex justify-center items-center loading-dialog4">
      <p class="text-gray-600 font-bold animate-pulse" style="font-size: 30px">送信中…</p>
    </div>
    <PageHeader :title="!isMobileDevice?'日報':''" :class="isMobileDevice||isTabletDevice?'main-form-m':'main-form'">
      <template #title-header-content>
        <div class="flex-1 flex justify-end items-center">
          <!-- 完了済み＆編集していない(スマホ) -->
          <div v-if="!notEdit&&isMobileDevice&&report.complete&&!edit" class="text-white m-auto font-bold text-sm">
            完了
          </div>
          <!-- 完了済み＆編集していない -->
          <div v-if="!notEdit&&!isMobileDevice&&report.complete&&!edit" class="text-white m-auto font-bold text-sm">
            ※完了済み＆再編集していないため、完了ボタンは非表示
          </div>
          <!-- 保存ボタン -->
          <div v-else-if="!notEdit&&!isMobileDevice" class="p-1 m-auto">
            <PrimaryButton
              text="完了（チャット送信）"
              class="w-full bg-green-600 px-2"
              @click="save()"
            >
            </PrimaryButton>
          </div>
          <div v-else-if="!isMobileDevice" class="text-white m-auto">
            作成者本人かつ当日のみ編集可能
          </div>

          <!-- 一覧 -->
          <div class="min-w-fit">
            <PrimaryButton class="" size="sm" text="日報一覧" @click="$router.push({ name: 'DailyReportList' })">
              <LoadingIcon v-if="loading" slot="before" class="h-4 w-4" />
              <Icon v-else iconName="ClipboardList" slot="before" class="h-4 w-4" />
            </PrimaryButton>
          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-20 bottom-4 leading-8 w-full mx-auto">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 w-full">
        <div v-if="!loading&&report" class="rounded-md border border-gray-200 table-h overflow-auto bg-white p-2">
          <div class="mx-auto" :class="isMobileDevice||isTabletDevice?'input-form-m':'input-form'">

          <!-- 完了済み＆編集していない -->
          <div v-if="!notEdit&&isMobileDevice&&report.complete&&!edit" class="text-green-800 w-fit mx-auto my-2 font-bold text-sm">
            ※完了済み＆再編集していないため、完了ボタンは非表示
          </div>
          <!-- 保存ボタン -->
          <div v-else-if="!notEdit&&isMobileDevice" class="p-1 m-auto">
            <PrimaryButton
              :text="sending?'送信中…':'完了（チャット送信）'"
              class="w-full bg-green-600 px-2"
              @click="save()"
            >
            </PrimaryButton>
          </div>
          <div v-else-if="isMobileDevice" class="text-green-800 text-xs m-auto">
            作成者本人かつ当日のみ編集可能
          </div>

            <div class="mt-2 p-2 mb-10">

              <!-- 部署名 -->
              <div class="flex mb-1">
                <div class="py-2 w-28 bg-gray-300 items-stretch text-center rounded-l">
                  部署名
                </div>
                <select
                  v-model="report.departmentName"
                  class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 font-bold w-full"
                  :disabled="notEdit"
                  @change="changeDepartment()"
                >
                  <option
                    v-for="(d, i) in departmentList" :key="'department' + i"
                    :value="d.name"
                    class="bg-white">
                    {{ d.name }}
                  </option>
                </select>
              </div>

              <!-- グループ -->
              <div class="flex mb-1">
                <div class="py-2 w-28 bg-gray-300 items-stretch text-center rounded-l">
                  グループ
                </div>
                  <select
                    v-model="report.groupNum"
                    class="border-gray-300 rounded-r leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 font-bold w-full"
                    :disabled="notEdit"
                    @change="saveGroup()"
                  >
                  <option
                    v-for="(d, i) in [1, 2, 3, 4, 5, 6, 7]" :key="'gro' + i"
                    :value="d"
                    class="bg-white">
                    {{ d }}
                  </option>
                </select>
              </div>

              <!-- ルームID -->
              <div class="flex mb-1 w-full h-full">
                <div class="py-2 w-28 bg-gray-300 items-stretch text-center rounded-l">
                  ルームID
                </div>
                <input
                  type="number"
                  class="border-gray-300 font-bold w-full rounded-r items-stretch "
                  :class="!chat.roomId?'bg-red-600':''"
                  v-model="chat.roomId"
                  :disabled="chat.apiToken"
                  @change="saveDailyReportChat()"
                />
              </div>

              <!-- 項目 -->
              <div v-if="report.departmentName&&report.groupNum&&chat.roomId" class="mt-3">
                <div v-for="(row, i) in list" :key="i">
                  <div v-if="isGroup(row)">
                    <div class="text-xs font-bold text-blue-700 mt-2 mb-1">
                      {{ row.title }}
                    </div>

                    <!-- 数字入力の場合 -->
                    <div v-if="row.type=='number'">
                      <input
                        type="number"
                        class="border-gray-300 rounded mb-2"
                        autocomplete="off"
                        v-model="row.report"
                        :disabled="notEdit"
                        @change="saveItem(row)"
                      />
                    </div>

                    <!-- 時間入力の場合 -->
                    <div v-else-if="row.type=='time'||row.title.indexOf('残業時間') > -1" class="w-44">
                      <!-- 本日残業時間入力 -->
                      <div v-if="row.title!='累計残業時間'&&!notEdit" class="flex">
                        <select
                          v-model="row.reportHour"
                          class="w-14 text-center bg-white2 border border-gray-300 rounded focus:bg-yellow-100 focus:ring-white text-gray-900 font-bold px-2 py-1 mb-2 last-center"
                          @change="saveItem(row, 'hour')"
                        >
                          <option
                            v-for="(p, i) in [0, 1, 2, 3]" :key="'pro' + i"
                            :value="p"
                            class="bg-white">
                            {{ p }}
                          </option>
                        </select>
                        <div class="my-auto mx-1 text-xs font-bold">時間</div>
                        <input
                          type="number"
                          class="border-gray-300 rounded mb-2 w-14 text-center font-bold"
                          autocomplete="off"
                          v-model="row.reportMinute"
                          @change="saveItem(row)"
                          :ref="'overtime'"
                        />
                        <div class="my-auto ml-1 text-xs font-bold">分</div>
                      </div>

                      <!-- 編集不可 -->
                      <div v-else-if="notEdit" class="ml-2">
                        {{ formatTime(row.report) }}
                      </div>

                      <!-- 累計残業時間入力 -->
                      <div v-else class="flex ">
                        <input
                          type="number"
                          class="border-gray-300 rounded mb-2 w-14 text-center font-bold"
                          autocomplete="off"
                          v-model="row.reportHour"
                          @change="saveItem(row, 'hour')"
                        />
                        <div class="my-auto mx-1 text-xs font-bold">時間</div>
                        <input
                          type="number"
                          class="border-gray-300 rounded mb-2 w-14 text-center font-bold"
                          autocomplete="off"
                          v-model="row.reportMinute"
                          @change="saveItem(row)"
                        />
                        <div class="my-auto ml-1 text-xs font-bold">分</div>
                      </div>
                      <!-- <input
                        v-if="!notEdit"
                        type="time"
                        class="border-gray-300 rounded mb-2 w-full font-bold"
                        v-model="row.report"
                        @change="saveItem(row)"
                      />
                      <div v-else class="ml-2">
                        {{ formatTime(row.report) }}
                      </div> -->
                    </div>

                    <textarea
                      v-else
                      :rows="countRowNum(row.report)"
                      class="border border-gray-300 w-full rounded text-sm leading-6"
                      v-model="row.report"
                      :disabled="notEdit"
                      @change="saveItem(row)"
                    />
                  </div>
                </div>

                <!-- 完了済み＆編集していない -->
                <div v-if="!notEdit&&report.complete&&!edit" class="text-green-800 w-fit mx-auto my-2 font-bold text-sm">
                  ※完了済み＆再編集していないため、完了ボタンは非表示
                </div>
                <!-- 保存ボタン -->
                <div v-else-if="!notEdit" class="p-1 m-auto">
                  <PrimaryButton
                    :text="sending?'送信中…':'完了（チャット送信）'"
                    class="w-full bg-green-600 px-2"
                    @click="save()"
                  >
                  </PrimaryButton>
                </div>
                <div class="text-transparent">{{reload}}</div>
              </div>
              <div v-else>
                <div class="m-auto w-fit text-red-700 font-bold">
                  <span v-if="!report.departmentName">「部署」 </span><span v-if="!report.groupNum">「グループ」 </span><span v-if="!chat.roomId">「ルームID」 </span>
                  を入力してください
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import LoadingIcon from '@assets/loading-circle.svg'
// import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
// import * as moment from 'moment'
import * as dialogs from '@libs/dialogs'
import * as moment from 'moment'
import * as chatwork from '@libs/chatwork'
import { CHAT_TOKEN } from '@/config'
import PageModel from '../../main/pages/PageModel.vue'

// 人によって項目が決まっている人
const STAFF = [
  { name: '金野　智則',
    item: [
      { sortNo: 1, title: '数字（実績報告）', report: '金野：\r\n長沼：\r\n近藤：\r\n安齋：\r\n吉田：\r\n岩田：\r\n幸田：\r\n今野：\r\n' },
      { sortNo: 2, title: 'お客様情報', report: '' },
      { sortNo: 3, title: 'ライバル情報', report: '' },
      { sortNo: 4, title: '協力企業・行政情報', report: '' },
      { sortNo: 5, title: '部下のVOC', report: '' },
      { sortNo: 6, title: '自分の考え', report: '' },
      { sortNo: 7, title: 'リクエストポストタスク確認', report: '' },
      { sortNo: 8, title: '出勤アルコール', report: '', type: 'number' },
      { sortNo: 9, title: '退勤アルコール', report: '', type: 'number' }
  ] }
]

// 基本項目
const ITEM = [
  { sortNo: 1, title: '数字（実績報告）', report: '' },
  { sortNo: 2, title: 'お客様の声', report: 'VOC登録： 件\r\nVOC外： 件' },
  { sortNo: 3, title: 'ライバル情報', report: '' },
  { sortNo: 4, title: '協力企業・行政情報', report: '' },
  { sortNo: 5, title: '部下のVOC', report: '', targetGroup: 3 },
  { sortNo: 6, title: '自分の考え', report: '' },
  { sortNo: 7, title: 'リクエストポストタスク確認', report: '' }
]

export default {
  extends: PageModel,
  components: {
    Icon,
    PageHeader,
    PrimaryButton,
    LoadingIcon,
    // WmsTextInput
  },

  props: ['target'],

  data() {
    return {
      loading: false,
      reload: 0,
      // チャット送信中
      sending: false,
      // 対象ユーザー
      user: utils.deleteKana(this.$store.getters.user.user_name),
      // 対象ユーザーID
      userId: this.$store.getters.user.id,
      // 日報データ
      report: {departmentName: '', groupNum: '', roomId: ''},
      // 項目リスト
      list: utils.clone(ITEM),
      // 部署リスト
      departmentList: utils.clone(this.$store.getters.departmentList),
      // グループ
      groupNum: null,
      // 課累計残業時間
      past: '0:0',
      hour: 0,
      minute: 0,
      // 編集不可
      notEdit: false,
      // チャット情報（アカウントIDは不要）
      chat: {},
      // 編集したかどうか
      edit: false
    }
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * タブレット
     */
    isTabletDevice() {
      return utils.deviceInfo.isTablet
    },

    /**
     * 該当の部署か判定
     */
    isDepart() {
      return (dep) => {
        if (this.report.departmentName) {
          if (this.report.departmentName.indexOf(dep) > -1) {
            return true
          }
        }
        return false
      }
    },

    /**
     * グループによる表示非表示
     */
    isGroup() {
      return (row) => {
        if (row && row.targetGroup) {
          if (this.report.groupNum) {
            if (this.report.groupNum < row.targetGroup) {
              return false
            }
          }
        }
        return true
      }
    },

    /**
     * 行の数
     */
    countRowNum() {
      return (val) => {  
        if (val) {
          const c = (val.match(/\n/g) || []).length
          if (c) {
            return c + 1
          }
        }
        return 2
      }
    },
  },

  async created() {
    // 所属部署を初期値で入れる
    const userInfo = this.$store.getters.loginUserData
    this.report.departmentId = userInfo.departmentId
    this.report.departmentName = userInfo.departmentName

    await this.getData()
  },

  methods: {
    /**
     * データ取得
     */
    async getData() {
      this.loading = true
      let id = null
      this.setItem()

      // 編集OR参照の場合
      if (this.$route.query.id) {
        id = this.$route.query.id
        if (!this.target) {
          // 一覧へ
          this.$router.push({ name: 'DailyReportList' })
          return
        }
        this.user = this.target.userName
        this.userId = this.target.userId
      }

      const res = await backend.searchData('evaluation/getDailyReport', { user: this.user, id, userId: this.userId, loginUser: utils.deleteKana(this.$store.getters.user.user_name) })
      if (res.data.data) {
        // 基本情報
        if (res.data.data.report) {
          this.report = res.data.data.report
          
          const today = moment().format('YYYY-MM-DD')
          // 本日ではない、もしくは本人でない
          if (this.report.daySt != today || this.report.userName != utils.deleteKana(this.$store.getters.user.user_name)) {
            this.notEdit = true
          }
        }
        // チャット情報
        if (res.data.data.chat) {
          this.chat = res.data.data.chat
        }
        // 累計残業
        this.past = res.data.data.past
        // 項目情報
        if (res.data.data.list) {
          const saved = res.data.data.list
          // 編集不可
          if (this.notEdit) {
            this.list = saved
          // 編集可能
          } else {
            this.setItem()
            for (let i = 0; i < this.list.length; i++) {
              const l = this.list[i]
              for (let j = 0; j < saved.length; j++) {
                const s = saved[j]
                // タイトルで確認
                if (l.title == s.title) {
                  this.list[i].report = s.report
                  this.list[i].id = s.id
                  if ((l.title == '本日残業時間' || l.title == '累計残業時間') && s.report) {
                    const arr = s.report.split(':')
                    let hh = arr[0]
                    let mm = arr[1]
                    if (hh && !isNaN(hh)) {
                      l.reportHour = Number(hh)
                    }
                    if (mm && !isNaN(mm)) {
                      l.reportMinute = Number(mm)
                    }
                  }
                  break
                }
              }
            }
            // 累計残業時間が保存されていない場合は、過去累計を表示
            for (let i = 0; i < this.list.length; i++) {
              const e = this.list[i]
              if (e.title == '累計残業時間' && !e.report) {
                this.getTotalTime(e)
                break
              }
            }
          }
        }
        // グループ
        this.groupNum = res.data.data.groupNum

        // 新規の場合
        if (!this.report.id) {
          // グループをあてはめる
          this.report.groupNum = this.groupNum
          // 新規保存
          await this.saveDailyReport()
        }
      }
      this.loading = false
    },

    /**
     * 項目設定（部署ごと、もしくは人ごと）
     */
    setItem() {
      // 人ごとに項目が決まっている場合
      for (let i = 0; i < STAFF.length; i++) {
        const s = STAFF[i]
        if (s.name.replace(' ', '').replace('　', '') == this.user.replace(' ', '').replace('　', '')) {
          this.list = utils.clone(s.item)
          return
        }
      }

      this.list = utils.clone(ITEM)
      let det = []
      // 新規の場合
      if (this.isDepart('受託営業')) {
        det = ['1. お客様訪問件数：', '2. 現地調査件数：', '3. 見積り件数：']
      } else if (this.isDepart('建物管理')) {
        det = ['1. お客様訪問件数：', '2. 現地調査件数：']
      } else if (this.isDepart('品質管理')) {
        det = ['巡回件数：']
      } else if (this.isDepart('ラウンド')) {
        det = ['1. 訪問件数：', '2. メール件数：', '3. お客様からのメール件数：']
      } else if (this.isDepart('勤怠')) {
        det = ['手配件数：']
      } else if (this.isDepart('BLDX')) {
        det = ['お客様訪問件数：']
      }

      if (det && det.length) {
        this.list[0].report = det.join('\r\n')
      }

      if (this.isDepart('建物管理') || this.isDepart('品質管理') || this.isDepart('ラウンド')) {
        this.list.push({ sortNo: (this.list.length + 1), title: '出勤アルコール', report: '', type: 'number' })
        this.list.push({ sortNo: (this.list.length + 1), title: '退勤アルコール', report: '', type: 'number' })
      }

      this.list.push({ sortNo: (this.list.length + 1), title: '本日残業時間', report: '', type: 'time' })
      this.list.push({ sortNo: (this.list.length + 1), title: '累計残業時間', report: '', type: 'time' })
    },

    /**
     * 時間を成型
     * @param time 時間
     */
    formatTime(time){
      if (time && time.indexOf(':') > -1) {
        let valArr = time.split(':')
        return Number(valArr[0]) + ' 時間 ' + Number(valArr[1]) + ' 分'
      }
      return time
    },

    /**
     * 日報基本データ保存
     */
    async saveDailyReport() {
      if (this.notEdit) {
        return
      }
      const res = await backend.postData('evaluation/saveDailyReport', { ...this.report, userId: this.userId, user: this.user })
      if (res.data.data) {
        this.report.id = res.data.data
      }
    },

    /**
     * 部署変更
     */
    changeDepartment() {
      if (this.notEdit) {
        return
      }
      this.loading = true
      const saved = utils.clone(this.list)
      const id = this.departmentList.find((d) => {
        return this.report.departmentName == d.name
      }).departmentId
      this.report.departmentId = id
      // 項目変更
      this.setItem()
      // すでに保存しているものは内容を置き換える
      for (let i = 0; i < this.list.length; i++) {
        const l = this.list[i]
        for (let j = 0; j < saved.length; j++) {
          const s = saved[j]
          if (l.title == s.title) {
            l.id = s.id
            l.report = s.report
          }
        }
      }
      this.saveDailyReport()
      this.loading = false
    },

    /**
     * グループ更新
     */
    saveGroup() {
      this.saveDailyReport()
      backend.postData('evaluation/saveGroup', { groupNum: this.report.groupNum, name: this.user, userId: this.userId })
    },

    /**
     * チャット更新
     */
    saveDailyReportChat() {
      if (this.notEdit) {
        return
      }
      backend.postData('evaluation/saveDailyReportChat', { userName: this.user, roomId: this.chat.roomId, apiToken: this.chat.apiToken || CHAT_TOKEN })
      this.chat.apiToken = CHAT_TOKEN
    },

    /**
     * 項目一時保存
     */
    async saveItem(row, time) {
      if (this.notEdit) {
        return
      }
      // 編集フラグ
      this.edit = true

      if (row.title == '本日残業時間' || row.title == '累計残業時間') {
        await utils.wait(100)
        if (row.reportMinute && Number(row.reportMinute) >= 60) {
          alert(row.title + 'の「分」は、59以下にしてください。')
          row.reportMinute = null
          this.reload++
          return
        }

        // 本日残業時間の時間の入力であれば、分にカーソルを移動
        if (time == 'hour' && row.title == '本日残業時間') {
          if (row.reportHour == 3) {
            row.reportMinute = 0
          }
          this.$nextTick(() => {
            this.$refs['overtime'][0].focus()
          })
        }

        row.report = row.reportHour + ':' + row.reportMinute
      }
      if (row.report) {
        row.edit = true
        const res = await backend.postData('evaluation/saveDailyReportItem', { dailyReportId: this.report.id, ...row, userId: this.userId })
        if (res.data.data) {
          row.id = res.data.data
          this.reload++
        }
        if (row.title == '本日残業時間') {
          const tar = this.list.find((l) => {
            return l.title == ('累計残業時間')
          })
          if (tar && tar.title) {
            // 累計残業時間を保存
            this.getTotalTime(tar)
            await this.saveItem(tar)
          }
        }
      }
    },

    /**
     * 累計残業時間
     */
    getTotalTime(row) {
      let hour = 0
      let minu = 0
      // 本日残業
      let val = '0:0'
      let time = this.list.find((l) => {
        return l.title == '本日残業時間'
      })
      if (time && time.report) {
        val = time.report
      }

      let valArr = val.split(':')
      let pastArr = this.past.split(':')

      hour = Number(pastArr[0] || 0)
      minu = Number(pastArr[1] || 0)

      // 時間
      if (valArr[0]) {
        hour += Number(valArr[0])
      }
      // 分
      if (valArr[1]) {
        minu += Number(valArr[1])
      }

      if (minu >= 60) {
        let h = Math.floor(minu / 60)
        let m = minu % 60
        hour += h
        minu = m
      }

      row.reportHour = hour
      row.reportMinute = minu
      row.report = hour + ':' + minu
      this.reload++
    },

    /**
     * 保存
     */
    async save() {
      if (this.notEdit) {
        return
      }
      if (!this.edit && this.report.complete) {
        await dialogs.showErrorDialog('すでに完了、チャット送信済み', '同じデータがチャット送信されるのを防ぐため、編集していない場合は再度完了できません。')
        return
      }
      // 項目内容メッセージ
      const item = []

      if (!this.report.departmentName) {
        await dialogs.showErrorDialog('完了できません', '部署を選択してください。')
        return
      }
      if (!this.report.groupNum) {
        await dialogs.showErrorDialog('完了できません', 'グループを選択してください。')
        return
      }
      if (!this.chat.roomId) {
        await dialogs.showErrorDialog('完了できません', '送信先のChatworkルームIDを入力してください。')
        return
      }
      // 入力欄にカーソルをあてたまま、完了ボタンを押された場合に、最新の入力がチャットに入らないので、少し待つ
      await utils.wait(500)
      for (let i = 0; i < this.list.length; i++) {
        const row = this.list[i]
        if (!row.report) {
          if (!row.targetGroup || (row.targetGroup && row.targetGroup <= this.report.groupNum)) {
            await dialogs.showErrorDialog('完了できません', row.title + 'を記入してください。')
            return
          }
        }

        // 記号などを削除して確認
        const val = utils.deleteSymbol(row.report)
        if (!val) {
          if (!row.targetGroup || (row.targetGroup && row.targetGroup <= this.report.groupNum)) {
            await dialogs.showErrorDialog('完了できません', row.title + 'をご確認ください。文章になっていますか？')
            return
          }
        }

        if (row.title.indexOf('残業時間') > -1) {
          if (!row.reportHour && row.reportHour != 0) {
            await dialogs.showErrorDialog('完了できません', row.title + 'の「時間」を記入してください。')
            return
          }
          if (!row.reportMinute && row.reportMinute != 0) {
            await dialogs.showErrorDialog('完了できません', row.title + 'の「分」を記入してください。')
            return
          }
        }

        if (row.title == '本日残業時間') {
          if (row.reportHour == 3 && row.reportMinute != 0) {
            const dia = await dialogs.showWarningConfirmDialog('本日残業が3時間超えています', row.title + 'が3時間を超えていますがよろしいでしょうか？')
            if (dia != 'YES') {
              return
            }
          }
        }

        // 項目内容を取得
        // 非表示設定対象項目は除く
        if (row.targetGroup && this.report.groupNum < row.targetGroup) {
          continue
        }

        // 初めて完了ボタンを押したか、再編集した項目の場合
        if (!this.report.complete || row.edit) {
          item.push(`■ ${row.title}`)
          if (row.title.indexOf('時間') > -1) {
            item.push(this.formatTime(row.report))
          } else {
            item.push(row.report)
          }
          item.push('')
        }
      }
      let again = ''
      if (this.report.complete) {
        again = '（再送信）'
      }
      this.sending = true
      this.report.complete = true
      await this.saveDailyReport()

      // チャット送信
      let message = `[info][title]${this.user} さんの日報です${again}[/title]`
      message += `${this.user}  (${moment().format('M/D')})\r\n\r\n`
      if (again) {
        message += '※編集のため、再送信します。編集した項目のみ表示します。\r\n\r\n'
      }
      message += item.join('\r\n')
      message += '[/info]'
      await chatwork.sendChatWorkMssage(
        this.chat.roomId, 
        message,
        this.chat.apiToken || CHAT_TOKEN
      )
      this.sending = false
      await dialogs.showSuccessDialog('チャット送信完了', 'この日報は完了しました。')
      // 編集フラグリセット
      this.edit = false
      this.list.forEach(e => {
        e.edit = false
      })
      this.reload++
    }
  }
}
</script>

<style scoped>
.main-form {
  min-width: 650px;
}
.input-form {
  height: calc(100vh - 140px);
  width: 650px;
}
.main-form-m {
  /* min-width: 650px; */
  width: 100%;
}
.input-form-m {
  height: calc(100vh - 140px);
  /* width: 650px; */
  width: 100%;
}
.last-center {
  text-align-last: center;
}
/* 入力欄を自動調整 */
textarea {
  field-sizing: content;
}
</style>