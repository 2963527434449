<template>
  <div>
    <div v-if="downloading" class="flex justify-center items-center loading-dialog3">
      <p class="text-gray-600 font-bold animate-pulse" style="font-size: 30px">Downloading...</p>
    </div>
    <PageHeader :title="isMobileDevice || isTabletDevice ? '':'評価シート 一覧'" class="main-form">
      <template #title-header-content>
        <div class="flex-1 flex justify-end items-center">
          <!-- 年度検索 -->
          <div class="mr-2 mt-auto">
            <input
              type="number"
              name="filterYear" 
              min="2024"
              max="2999"
              placeholder="年度 検索"
              class="py-2 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 font-bold w-28 text-center" 
              v-model="filter.year"
            /> 
            <span class="pl-1 pr-2 font-bold text-white text-sm">年度</span>
          </div>

          <!-- 切替 -->
          <div class="bg-white rounded mr-5">
            <div class="flex items-center py-1 rounded mx-5 ">
              <div v-for="tab in typeList" 
                :key="tab.id" 
                class="w-28 text-center mx-1 cursor-pointer"
                :class="[type == tab.id ? 'bg-light-blue-700' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-1 px-3 text-sm font-medium text-white']" 
                @click="typeChanged(tab)"
              >
                {{ tab.title }}
              </div>
            </div>
          </div>

          <!-- 検索ボタン -->
          <PrimaryButton
            text="検索"
            class="button-contents mr-5"
            @click="getData()"
          >
          </PrimaryButton>

          <!-- 入力 -->
          <PrimaryButton class="bg-green-600 mr-2" text="本人入力フォーム" @click="toInputForm()">
            <Icon slot="before" iconName="PencilAlt" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>

          <!-- Excel -->
          <PrimaryButton v-if="auth" text="Excel" @click="download()" :buttonContents="'要'" class="mr-1 bg-blue-600">
            <Icon v-if="!downloading" slot="before" iconName="Download" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
            <LoadingIcon v-else slot="before" class="h-4 w-4 my-auto ml-2 text-white" />
          </PrimaryButton>

          <div class="text-white font-bold">
            {{ list.length }}件
          </div>

          <!-- リロードアイコン -->
          <Icon
            iconName="Refresh"
            :clickable="false"
            :class="{ 'animate-spin': loading || downloading }"
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer" @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="flex justify-end">
          <div v-if="!boss&&!admin" class="text-white font-bold mr-3 mt-auto">
            ※上司以外は自分のデータのみ表示されます。
          </div>
          <div v-else class="flex items-stretch">
            <!-- 上司待ち -->
            <PrimaryButton
              text="上司未完了"
              :buttonContents="'要'"
              size="sm"
              class="mr-2 h-8"
              :class="[filter.stopBoss ? 'clickButtonColor2' : 'normalButtonColor', isMobileDevice||isTabletDevice?'px-1':'']"
              @click="filter.stopBoss=!filter.stopBoss"
            >
              <Icon v-if="!isMobileDevice&&!isTabletDevice" slot="before" iconName="User" iconType="solid" :strokeWidth="2" class="h-5 w-5 " />
            </PrimaryButton>

            <!-- 評価対象者・部下全員 切り替え -->
            <div class="flex items-center mr-2">
              <div class="cursor-pointer rounded-l text-sm font-bold py-1.5 h-8 text-center m-auto w-28" :class="!filter.allMyJunior&&!filter.all?'clickButtonColor4':'normalButtonColor'" @click="changeJunior(1)">
                評価対象部下
              </div>
              <div class="cursor-pointer border-l border-r border-gray-700 text-sm font-bold py-1.5 h-8 text-center m-auto w-28" :class="[filter.allMyJunior?'clickButtonColor4':'normalButtonColor', admin?'':'rounded-r']" @click="changeJunior(2)">
                部下全員
              </div>
              <div v-if="admin" class="cursor-pointer rounded-r text-sm font-bold py-1.5 h-8 text-center m-auto w-28" :class="filter.all?'clickButtonColor4':'normalButtonColor'" @click="changeJunior(3)">
                全員
              </div>
            </div>

            <!-- 名前検索 -->
            <div class="mr-2 mt-auto">
              <input
                type="search"
                name="filterUser" 
                placeholder="名前 検索"
                class="py-1 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 h-8 w-36"
                v-model="filter.user"
              /> 
            </div>

            <div class="text-white font-bold text-sm items-stretch my-auto">
              ※上司の方は評価対象者を選択してください。
            </div>

          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-28 bottom-4 leading-8 w-full main-form mx-auto overflow-hidden">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full w-full">
        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full">
          <div v-if="notComp&&notComp.length" class="p-2 text-sm ">
            <div class="font-bold text-red-700">未完了の部下<span class="ml-3 text-gray-900 font-normal">※全ての部下を完了させなければ、自分の評価を上司にしてもらえません。15日を過ぎると反省文提出となります。</span></div>
            <div  class="mt-1 flex flex-wrap font-bold border border-red-700 rounded p-2 bg-pink-50">
              <div v-for="(row, i) in notComp" :key="'not' + i">
                <div class="mx-3 my-2">
                  ・ {{ row.junior }}
                </div>
              </div>
            </div>
          </div>
          <table v-if="list.length" class="text-sm w-full overflow-auto">
            <thead class="sticky top-0">
              <tr class="text-gray-500 text-xs border border-gray-200">
                <th v-for="(c, i) in columns" :key="'col'+i" class="text-left bg-colu p-2" :class="c.class">{{ c.val }}</th>
              </tr>
            </thead>
            <tbody v-for="(row, i) in list" :key="i">
              <tr class="">

                <!-- 年度 -->
                <td :rowspan="2" class="whitespace-prerap border-b-4 border-gray-200 text-sm text-gray-700 min-w-fit p-2 cursor-pointer text-center" @click="toInputForm(row)">
                  {{ row.year }} <span>{{ row.periodName }}</span>
                </td>

                <!-- 名前 -->
                <td :rowspan="2" class="whitespace-prerap border-b-4 border-gray-200 text-sm text-gray-700 min-w-fit p-2 cursor-pointer font-bold" @click="toInputForm(row)">
                  {{ row.userName }}
                </td>

                <!-- 部署名 -->
                <!-- <td :rowspan="2" class="whitespace-prerap border-b-4 border-gray-200 text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" @click="toInputForm(row)">
                  {{ row.departmentName }}
                </td> -->

                <!-- 種別 -->
                <td class="whitespace-prerap border-b-0 text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r text-center" @click="toInputForm(row)">
                  本人入力
                </td>
                
                <!-- 本人入力 -->
                <td class="whitespace-prerap border-b-0 text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.ps1==0?'bg-red-100':'bg-white2'" @click="toInputForm(row)">
                  <div v-if="row.ps1==1" class="flex justify-center mx-auto w-fit">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5 text-blue-700" />
                    <div class="text-sm ml-2 my-auto text-blue-800 font-bold">{{ row.self1 }} 点</div>
                    <div class="text-xs my-auto text-gray-800">{{ dateFormat(row.processSelfTime1) }}</div>
                  </div>
                  <div v-else-if="row.ps1==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.ps1==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.ps1==-100" class="text-center text-gray-500 text-xs">休職中</div>
                </td>
                
                <!-- 本人入力 -->
                <td class="whitespace-prerap border-b-0 text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.ps2==0?'bg-red-100':'bg-white2'" @click="toInputForm(row)">
                  <div v-if="row.ps2==1" class="flex justify-center mx-auto w-fit">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5 text-blue-700" />
                    <div class="text-sm ml-2 my-auto text-blue-800 font-bold">{{ row.self2 }} 点</div>
                    <div class="text-xs my-auto text-gray-800">{{ dateFormat(row.processSelfTime2) }}</div>
                  </div>
                  <div v-else-if="row.ps2==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.ps2==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.ps2==-100" class="text-center text-gray-500 text-xs">休職中</div>
                </td>
                
                <!-- 本人入力 -->
                <td class="whitespace-prerap border-b-0 text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.ps3==0?'bg-red-100':'bg-white2'" @click="toInputForm(row)">
                  <div v-if="row.ps3==1" class="flex justify-center mx-auto w-fit">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5 text-blue-700" />
                    <div class="text-sm ml-2 my-auto text-blue-800 font-bold">{{ row.self3 }} 点</div>
                    <div class="text-xs my-auto text-gray-800">{{ dateFormat(row.processSelfTime3) }}</div>
                  </div>
                  <div v-else-if="row.ps3==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.ps3==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.ps3==-100" class="text-center text-gray-500 text-xs">休職中</div>
                </td>
                
                <!-- 本人入力 -->
                <td class="whitespace-prerap border-b-0 text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.ps4==0?'bg-red-100':'bg-white2'" @click="toInputForm(row)">
                  <div v-if="row.ps4==1" class="flex justify-center mx-auto w-fit">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5 text-blue-700" />
                    <div class="text-sm ml-2 my-auto text-blue-800 font-bold">{{ row.self4 }} 点</div>
                    <div class="text-xs my-auto text-gray-800">{{ dateFormat(row.processSelfTime4) }}</div>
                  </div>
                  <div v-else-if="row.ps4==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.ps4==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.ps4==-100" class="text-center text-gray-500 text-xs">休職中</div>
                </td>
                
                <!-- 本人入力 -->
                <td class="whitespace-prerap border-b-0 text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.ps5==0?'bg-red-100':'bg-white2'" @click="toInputForm(row)">
                  <div v-if="row.ps5==1" class="flex justify-center mx-auto w-fit">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5 text-blue-700" />
                    <div class="text-sm ml-2 my-auto text-blue-800 font-bold">{{ row.self5 }} 点</div>
                    <div class="text-xs my-auto text-gray-800">{{ dateFormat(row.processSelfTime5) }}</div>
                  </div>
                  <div v-else-if="row.ps5==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.ps5==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.ps5==-100" class="text-center text-gray-500 text-xs">休職中</div>
                </td>
                
                <!-- 本人入力 -->
                <td class="whitespace-prerap border-b-0 text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.ps6==0?'bg-red-100':'bg-white2'" @click="toInputForm(row)">
                  <div v-if="row.ps6==1" class="flex justify-center mx-auto w-fit">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5 text-blue-700" />
                    <div class="text-sm ml-2 my-auto text-blue-800 font-bold">{{ row.self6 }} 点</div>
                    <div class="text-xs my-auto text-gray-800">{{ dateFormat(row.processSelfTime6) }}</div>
                  </div>
                  <div v-else-if="row.ps6==0" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.ps6==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.ps6==-100" class="text-center text-gray-500 text-xs">休職中</div>
                </td>

                <!-- 未完了の部下 -->
                <td :rowspan="2" v-if="columns&&columns.length==10" class="border-b-4 border-gray-200 text-xs text-gray-700 min-w-fit p-2 cursor-pointer">
                  <div v-if="row.notComp" class="">
                    <div class="py-1 px-5 cursor-pointer rounded bg-green-800 text-white font-bold w-fit" @click="openUlockModal(row)">解除</div>
                    <div v-for="(n, ni) in row.notComp" :key="'ni'+ni" class="my-1">
                      ・ {{n.junior}}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="border-b-4 border-gray-200">
                <!-- 種別 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r text-center bg-white2" @click="toInputForm(row)">
                  上司入力
                </td>

                <!-- 上司入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.pb1==-10?'bg-red-100':'bg-white2'" @click="toInputForm(row)">
                  <div v-if="row.pb1==1" class="flex justify-center mx-auto w-fit">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5 text-blue-700" />
                    <div class="text-sm ml-2 my-auto text-blue-800 font-bold">{{ row.boss1 }} 点</div>
                    <div class="text-xs my-auto text-gray-800">{{ dateFormat(row.processBossTime1) }}</div>
                  </div>
                  <div v-else-if="row.pb1==-10" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.pb1==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.pb1==-100" class="text-center text-gray-500 text-xs">休職中</div>
                  <div v-else-if="row.pb1!=2" class="text-center text-gray-500 text-xs">本人入力待ち</div>
                </td>

                <!-- 上司入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.pb2==-10?'bg-red-100':'bg-white2'" @click="toInputForm(row)">
                  <div v-if="row.pb2==1" class="flex justify-center mx-auto w-fit">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5 text-blue-700" />
                    <div class="text-sm ml-2 my-auto text-blue-800 font-bold">{{ row.boss2 }} 点</div>
                    <div class="text-xs my-auto text-gray-800">{{ dateFormat(row.processBossTime2) }}</div>
                  </div>
                  <div v-else-if="row.pb2==-10" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.pb2==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.pb2==-100" class="text-center text-gray-500 text-xs">休職中</div>
                  <div v-else-if="row.pb2!=2" class="text-center text-gray-500 text-xs">本人入力待ち</div>
                </td>
                <!-- 上司入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.pb3==-10?'bg-red-100':'bg-white2'" @click="toInputForm(row)">
                  <div v-if="row.pb3==1" class="flex justify-center mx-auto w-fit">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5 text-blue-700" />
                    <div class="text-sm ml-2 my-auto text-blue-800 font-bold">{{ row.boss3 }} 点</div>
                    <div class="text-xs my-auto text-gray-800">{{ dateFormat(row.processBossTime3) }}</div>
                  </div>
                  <div v-else-if="row.pb3==-10" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.pb3==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.pb3==-100" class="text-center text-gray-500 text-xs">休職中</div>
                  <div v-else-if="row.pb3!=2" class="text-center text-gray-500 text-xs">本人入力待ち</div>
                </td>
                
                <!-- 上司入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.pb4==-10?'bg-red-100':'bg-white2'" @click="toInputForm(row)">
                  <div v-if="row.pb4==1" class="flex justify-center mx-auto w-fit">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5 text-blue-700" />
                    <div class="text-sm ml-2 my-auto text-blue-800 font-bold">{{ row.boss4 }} 点</div>
                    <div class="text-xs my-auto text-gray-800">{{ dateFormat(row.processBossTime4) }}</div>
                  </div>
                  <div v-else-if="row.pb4==-10" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.pb4==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.pb4==-100" class="text-center text-gray-500 text-xs">休職中</div>
                  <div v-else-if="row.pb4!=2" class="text-center text-gray-500 text-xs">本人入力待ち</div>
                </td>
                
                <!-- 上司入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.pb5==-10?'bg-red-100':'bg-white2'" @click="toInputForm(row)">
                  <div v-if="row.pb5==1" class="flex justify-center mx-auto w-fit">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5 text-blue-700" />
                    <div class="text-sm ml-2 my-auto text-blue-800 font-bold">{{ row.boss5 }} 点</div>
                    <div class="text-xs my-auto text-gray-800">{{ dateFormat(row.processBossTime5) }}</div>
                  </div>
                  <div v-else-if="row.pb5==-10" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.pb5==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.pb5==-100" class="text-center text-gray-500 text-xs">休職中</div>
                  <div v-else-if="row.pb5!=2" class="text-center text-gray-500 text-xs">本人入力待ち</div>
                </td>
                
                <!-- 上司入力 -->
                <td class="whitespace-prerap text-sm text-gray-700 min-w-fit p-2 cursor-pointer border-r" :class="row.pb6==-10?'bg-red-100':'bg-white2'" @click="toInputForm(row)">
                  <div v-if="row.pb6==1" class="flex justify-center mx-auto w-fit">
                    <Icon slot="before" iconName="CheckCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5 text-blue-700" />
                    <div class="text-sm ml-2 my-auto text-blue-800 font-bold">{{ row.boss6 }} 点</div>
                    <div class="text-xs my-auto text-gray-800">{{ dateFormat(row.processBossTime6) }}</div>
                  </div>
                  <div v-else-if="row.pb6==-10" class="text-center text-red-800 font-bold">未完了</div>
                  <div v-else-if="row.pb6==-1" class="text-center text-gray-500 text-xs">入社前</div>
                  <div v-else-if="row.pb6==-100" class="text-center text-gray-500 text-xs">休職中</div>
                  <div v-else-if="row.pb6!=2" class="text-center text-gray-500 text-xs">本人入力待ち</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="!loading && !list.length" class="w-full h-full flex justify-center items-center text-gray-400">
          <EmptyMessage
            iconName="Document"
            message="該当するデータはありません。"
          />
        </div>
      </div>
      <div class="text-transparent">{{reload}}</div>
    </main>
    <!-- 未完了部下解除 -->
    <UnlockBossModal
      v-model="unlockModal"
      :open="unlockModal"
      :target="target"
      :columns="columns"
      @getData="getData()"
      @closed="closed()"
    />
  </div>
</template>
  
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import * as moment from 'moment'
import LoadingIcon from '@assets/loading-circle.svg' 
import UnlockBossModal from '../components/UnlockBossModal.vue' 
import * as dialogs from '@libs/dialogs'
import PageModel from '../../main/pages/PageModel.vue'

const settionStrageName = 'evaListStrage'

// 評価シート
const COL = [
  { val: '年度', class: 'text-center' },
  { val: '名前' },
  // { val: '部署' },
  { val: '' },
]

export default {
  extends: PageModel,
  components: {
    Icon,
    PageHeader,
    EmptyMessage,
    PrimaryButton,
    LoadingIcon,
    UnlockBossModal
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * タブレット
     */
    isTabletDevice() {
      return utils.deviceInfo.isTablet
    },

    /**
     * 日付フォーマット
     */
    dateFormat() {
      return (tar) => {
        if (tar) {
          return '（' + moment(tar).format('M/D HH:mm') + '）'
        }
        return ''
      }
    }
  },

  data() {
    return {
      loading: false,
      downloading: false,
      reload: 0,
      // 切替ボタン
      typeList: [
        { id: 1, title: '上期' },
        { id: 2, title: '下期' }
      ],
      type: 1,
      // リスト
      list: [],
      // カラム
      columns: utils.clone(COL),
      // 検索条件
      filter: {
        // 上司入力待ちで絞り込み
        stopBoss: false,
        // 年度
        year: null,
        // 部下全員表示
        allMyJunior: false,
        // 対象者絞込み
        user: '',
        // 全員表示
        all: false
      },
      // 部署リスト
      // departmentList: utils.clone(this.$store.getters.departmentList),
      // 状態リスト
      statusList: [
        { id: '', val: '' },
        { id: '1', val: '未完了あり' },
        { id: '2', val: '全て完了' }
      ],
      // 上司フラグ
      boss: false,
      // Excelボタン権限
      auth: false,
      // 未完了の部下がいる場合
      notComp: [],
      // 未完了の部下解除モーダル
      unlockModal: false,
      // 未完了部下解除に渡すデータ
      target: {},
      // 管理者権限あり
      admin: false
    }
  },

  async created() {
    // 上司フラグ
    this.boss = this.$store.getters.loginUserData.boss
    // 年度
    this.filter.year = Number(moment().format('YYYY'))
    const mm = Number(moment().format('M'))
    if (mm == 1) {
      this.filter.year--
      this.type = 2
    } else if (mm <= 7) {
      this.type = 1
    } else {
      this.type = 2
    }
    // 2月、8月の場合、中旬までは前の期にする
    if ([2, 8].includes(mm)) {
      if (Number(moment().format('D')) <= 16) {
        if (mm == 2) {
          this.type = 2
          this.filter.year--
        } else {
          this.type = 1
        }
      }
    }
    // 部署リスト
    // this.departmentList.unshift({ departmentId: '', name: '全ての部署' })
    // Excelボタン権限
    const userInfo = this.$store.getters.loginUserData
    if (userInfo.departmentName && userInfo.departmentName.indexOf('人事') > -1) {
      this.auth = true
    }
    // 管理者権限
    let auth = this.$store.getters.auth
    if (auth && auth.adminAuth) {
      this.admin = true
      this.boss = true
      this.auth = true
    }
    this.getSettionStrage()
    await this.getData()
  },

  methods: {
    /**
     * リフレッシュアイコンイベント
     */
    async refresh() {
      await this.getData()
    },

    /**
     * セッションストレージ取得
     */
    getSettionStrage() {
      const searchJson = sessionStorage.getItem(settionStrageName)
      const getItem = JSON.parse(searchJson)
      if (getItem) {
        if (getItem.type) {
          this.type = getItem.type
        }
        delete getItem.type
        this.filter = getItem
      }
    },

    /**
     * セッションストレージ保存
     */
    setSessionStorage() {
      const searchStr = JSON.stringify({ ...this.filter, type: this.type })
      sessionStorage.setItem(settionStrageName, searchStr)
    },

    /**
     * データ取得
     */
    async getData() {
      this.list = []
      this.setColumn()
      // データ取得
      this.loading = true

      // プロセスの平均取得しているため、年度、期を必ず選択
      if (!this.filter.year) {
        await dialogs.showErrorDialog('年度未選択', '年度を選択してください。')
        return
      }
      if (!this.type) {
        await dialogs.showErrorDialog('期 未選択', '上期か下期を選択してください。')
        return
      }

      // カナを削除したログインユーザー名、ユーザーID
      const loginUser = utils.deleteKana(this.$store.getters.user.user_name)
      const loginUserId = this.$store.getters.user.id
      const res = await backend.postData('evaluation/getList', { ...this.filter, loginUser, loginUserId, period: this.type, boss: this.boss, admin: this.admin })
      if (res.data.data.list && res.data.data.list.length) {
        this.list = res.data.data.list

        for (let i = 0; i < this.list.length; i++) {
          const l = this.list[i]
          // console.log(l)
          if (l.notComp && l.notComp.length) {
            this.columns.push({ val: '未完了の部下' })
            break
          }
        }
      }

      if (res.data.data.notComp && res.data.data.notComp.length) {
        this.notComp = res.data.data.notComp
      }

      this.setSessionStorage()
      this.loading = false
    },

    /**
     * カラムをセット
     */
    setColumn() {
      this.columns = []
      this.columns = utils.clone(COL)
      // 上期の場合
      let mm = 2
      if (this.type == 2) {
        mm = 8
      }
      for (let i = 0; i < 6; i++) {
        let month = (mm + i) + '月'
        if (month == '13月') {
          month = '1月'
        }
        this.columns.push({ val: month, class: 'text-center' })
      }
    },

    /**
     * 切替イベント
     * @paran tab 選択したタブ
     */
    async typeChanged(tab) {
      this.type = tab.id
      await this.getData()
    },

    /**
     * 入力フォームへ遷移イベント
     */
    async toInputForm(row) {
      if (row) {
        this.$router.push({
          name: 'EvaluationInput',
          params: {
            target: row
          },
          query: {
            id: row.id
          }
        })
      } else {
        this.$router.push({
          name: 'EvaluationInput'
        })
      }
    },

    /**
     * 解除ボタン押下
     */
    openUlockModal(row) {
      this.target = row
      this.unlockModal = true
    },

    /**
     * 表示する部下を変更
     * @param type 1:評価対象部下、2:部下全員、3:全員
     */
    changeJunior(type) {
      if (type == 1) {
        this.filter.allMyJunior = false
        this.filter.all = false
      } else if (type == 2) {
        this.filter.allMyJunior = true
        this.filter.all = false
      } else if (type == 3) {
        this.filter.allMyJunior = false
        this.filter.all = true
      }
      this.reload++
    },

    /**
     * 解除モーダルclose
     */
    closed() {
      this.unlockModal = false
    },

    /**
     * ダウンロードイベント
     */
    async download() {
      this.downloading = true
      const res = await backend.postBlob2('evaluationDownload/excel', { year: this.filter.year, period: this.type })
      try {
        const uint8Array = res.data
        const blob = new Blob([uint8Array], { "Content-Type" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const a = document.createElement("a");
        a.href = (window.URL || window.webkitURL).createObjectURL(blob)
        let pe = '上期'
        if (this.type == 2) {
          pe = '下期'
        }
        let name = '評価シート（' + this.filter.year + '年度' + pe + '）'

        a.download = name + '.xlsx'
        a.click();
        a.remove();
        this.downloading = false
      } catch (e) {
        console.log(e)
        alert(e)
        this.downloading = false
      }
    },

  }
}
</script>
  
<style scoped>
.main-form {
  min-width: 1100px;
}

.table-h {
  max-height: calc(100% - 3px);
}

.table {
  width: 100%;
}

.bg-colu {
  background: #ebf0fb;
}
</style>