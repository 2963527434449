<template>
  <div>
    <div v-if="loading" class="w-full h-full flex justify-center items-center loading">
      <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
    </div>

    <PageHeader title="メインタスク一覧" class="list">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">

          <div class="flex-1 search-contents min-w-300px">
            <label for="search" class="sr-only">Search</label>
            <div class="relative text-light-blue-100 focus-within:text-gray-400">
              <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <Icon iconName="Search" class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              </div>
              <input 
                type="search"
                v-model="searchKeyword" 
                class="block w-full  bg-light-blue-700 bg-opacity-50 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 placeholder-light-blue-100 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="キーワード検索"
              />
            </div>
          </div>

          <div class="ml-auto flex button-contents">
            <PrimaryButton text="検索" @click="search" class="min-w-100px" :buttonContents="'要'" />
          </div>

          <!-- モバイル版 -->
          <div v-if="isMobileDevice||isTabletDevice" class="flex">
            <!-- 担当者絞込み -->
            <div class="ml-auto button-contents ">
              <PrimaryButton text="担当者がユーザー(未定含む)" v-tooltip="'担当者をログインユーザー名・未定で絞込み'" :buttonContents="'要'" class="min-w-200" :class="filterToStaffUser ? 'clickButtonColor' : 'normalButtonColor'" @click="filter('toStaffUser')">
              </PrimaryButton>
            </div>

            <!-- 依頼主絞込み -->
            <div class="ml-auto button-contents ">
              <PrimaryButton text="依頼主がユーザー" v-tooltip="'依頼主をログインユーザー名で絞込み'" :buttonContents="'要'" class="min-w-135" :class="filterByUser ? 'clickButtonColor' : 'normalButtonColor'" @click="filter('byUser')">
              </PrimaryButton>
            </div>

            <!-- 本日までの期限みボタン -->
            <div class="ml-auto flex button-contents ">
              <PrimaryButton text="本日までの期限" :class="untilTodayState ? 'clickButtonColor' : 'normalButtonColor'" class="min-w-130" v-tooltip="'本日までの期限'" @click="untilToday()" :buttonContents="'要'">
              </PrimaryButton>
            </div>

            <!-- 未完了のみボタン -->
            <div class="ml-auto flex button-contents ">
              <PrimaryButton text="未完了" :class="clickIncompleteOnlyState ? 'clickButtonColor' : 'normalButtonColor'" class="min-w-100px" v-tooltip="'未完了のみ'" @click="selectStatus('open')" :buttonContents="'要'">
              </PrimaryButton>
            </div>
          </div>

          <!-- PC版 -->
          <div v-else class="flex">
            <!-- 担当者絞込み -->
            <div class="ml-auto button-contents">
              <PrimaryButton  v-tooltip="'担当者をログインユーザー名・未定で絞込み'" :buttonContents="'要'" :class="filterToStaffUser ? 'clickButtonColor' : 'normalButtonColor'" @click="filter('toStaffUser')">
                <Icon slot="before" iconName="User" iconType="solid" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>

            <!-- 依頼主絞込み -->
            <div class="ml-auto button-contents">
              <PrimaryButton  v-tooltip="'依頼主をログインユーザー名で絞込み'" :buttonContents="'要'" :class="filterByUser ? 'clickButtonColor' : 'normalButtonColor'" @click="filter('byUser')">
                <Icon slot="before" iconName="UserRemove" iconType="solid" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>

            <!-- 本日までの期限みボタン -->
            <div class="ml-auto flex button-contents">
              <PrimaryButton :class="untilTodayState ? 'clickButtonColor' : 'normalButtonColor'" v-tooltip="'本日までの期限'" @click="untilToday()" :buttonContents="'要'">
                <Icon slot="before" iconName="Clock" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>

            <!-- 未完了のみボタン -->
            <div class="ml-auto flex button-contents">
              <PrimaryButton :class="clickIncompleteOnlyState ? 'clickButtonColor' : 'normalButtonColor'" v-tooltip="'未完了のみ'" @click="selectStatus('open')" :buttonContents="'要'">
                <Icon slot="before" iconName="ExclamationCircle" iconType="solid" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>
          </div>

          <div class="ml-auto flex button-contents">
            <PrimaryButton text="クリア" class="normalButtonColor min-w-100px" @click="clear" :buttonContents="'要'" />
          </div>

          <div class="ml-auto flex">
            <vue-json-to-csv :json-data="searchResults" :csv-title="csvName" :labels="labels">
              <PrimaryButton text="CSV出力" class="min-w-100px bg-yellow-600" />
            </vue-json-to-csv>

            <router-link :to="{ name: 'RequestPost' }" class="ml-2">
              <PrimaryButton text="新規登録" class="min-w-100px">
                <Icon slot="before" iconName="PencilAlt" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </router-link>
          </div>

          <Icon 
            iconName="Refresh" 
            :clickable="false" 
            :class="{'animate-spin': loading}" 
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer"
            @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="flex items-center">
          <!-- <h1 class="text-lg font-bold text-white flex items-center">            
            <Icon iconName="UserGroup" class="w-7 h-7 mr-2" /> <span class="mt-0">メインタスク一覧</span>
          </h1> -->

          <div class="flex items-center ml-5" v-tooltip="'検索開始は、検索ボタンを押してください'">
            <span class="text-gray-100 font-bold text-xs">サブタスク表示</span>
            <Toggle v-model="withSub" size="small" class="ml-2"/>
          </div>

          <div class="flex items-center ml-5" v-tooltip="'検索開始は、検索ボタンを押してください'">
            <span class="text-gray-100 font-bold text-xs">メインタスク</span>
            <Toggle v-model="mainTask" size="small" class="ml-2"/>
          </div>

          <div class="flex items-center ml-5" v-tooltip="'検索開始は、検索ボタンを押してください'">
            <span class="text-gray-100 font-bold text-xs">受注タスク</span>
            <Toggle v-model="orderTask" size="small" class="ml-2"/>
          </div>

          <div class="flex items-center ml-5" v-tooltip="'検索開始は、検索ボタンを押してください'">
            <span class="text-gray-100 font-bold text-xs">DC雇い入れタスク</span>
            <Toggle v-model="employmentTask" size="small" class="ml-2"/>
          </div>

          <div v-if="isRegularStaffAuthorized" class="flex items-center ml-5" v-tooltip="'検索開始は、検索ボタンを押してください'">
            <span class="text-gray-100 font-bold text-xs">社員雇い入れタスク</span>
            <Toggle v-model="employmentRegularTask" size="small" class="ml-2"/>
          </div>

          <div class="flex flex-1 ml-8 justify-end">
            <label for="search" class="sr-only">Search</label>
            <div class="relative focus-within:text-gray-400 search-contents">
              <input 
                type="search"
                v-model="category" 
                class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="お問い合わせカテゴリー"
              />
            </div>

            <label for="search" class="sr-only">Search</label>
            <div class="relative focus-within:text-gray-400 search-contents">
              <input 
                type="search"
                v-model="clientName" 
                class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="お客様会社名"
              />
            </div>

            <label for="search" class="sr-only">Search</label>
            <div class="relative focus-within:text-gray-400 search-contents">
              <input 
                type="search"
                v-model="clientPersonName" 
                class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="お客様担当者名"
              />
            </div>

            <div class="relative focus-within:text-gray-400 search-contents">
              <WmsSuggestInput
                name="toDepartment" 
                :dynamic="false"
                :incrementalSearch="false"
                :filter="{}"
                :selectionItems="toDepartmentList"
                displayField="name"
                placeholder="アポロ管財依頼先部署"
                v-model="toDepartment"
                :clearable="true"
                class="p-0.5 block w-full border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                :embed="true"
                id="toDepartment"
              />
            </div>

            <label for="search" class="sr-only">Search</label>
            <div class="relative focus-within:text-gray-400 search-contents">
              <input 
                type="search"
                v-model="toStaffName" 
                class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="担当者"
                v-tooltip="'カッコは無視して検索します'"
              />
            </div>

            <label for="search" class="sr-only">Search</label>
            <div class="relative focus-within:text-gray-400 search-contents">
              <input 
                type="search"
                v-model="byUser" 
                class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="依頼主"
                v-tooltip="'カッコは無視して検索します'"
              />
            </div>

            <label for="search" class="sr-only">Search</label>
            <div class="relative focus-within:text-gray-400 search-contents">
              <input 
                type="search"
                v-model="limitDate" 
                class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="期限"
              />
            </div>
        </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-32 w-screen bottom-4 -mt-2 list">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 ---overflow-auto h-full">

          <RequestPostTable 
            v-show="!loading && searchResults.length > 0" 
            ref="requestPostList" 
            :searchKeyword="searchConditionReset.searchKeyword"
            :searchCategory="searchConditionReset.category"
            :searchClientName="searchConditionReset.clientName"
            :searchClientPersonName="searchConditionReset.clientPersonName"
            :searchToDepartment="searchConditionReset.toDepartment"
            :searchToStaffName="searchConditionReset.toStaffName"
            :searchByUser="searchConditionReset.byUser"
            :searchLimitDate="searchConditionReset.limitDate"
            :sortItem="searchConditionReset.sortItem"
            :sortBy="searchConditionReset.sortBy"
            :searchStatus="searchStatus"
            :period="period" 
            :freeze="freeze"
            :filterToStaffUser="filterToStaffUser"
            :filterByUser="filterByUser"
            :withSub="withSub"
            :mainTask="mainTask"
            :orderTask="orderTask"
            :employmentTask="employmentTask"
            :employmentRegularTask="employmentRegularTask"
            @search-start="searchStart" 
            @search-end="searchEnd" 
            @change="changeStatus"
            @change-sort="changeSort"
          />
          
          <!-- <div v-if="loading" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
          </div> -->
          
          <div v-if="!loading && !initial && overData" class="w-full h-full flex justify-center items-center text-gray-400">
            <EmptyMessage
              iconName="ExclamationCircle"
              message="対象のデータが多いため、絞り込みをして再検索してください。"
            />
          </div>
          <div v-if="!loading && !initial && searchResults.length === 0" class="w-full h-full flex justify-center items-center text-gray-400">
            <EmptyMessage
              iconName="Document"
              message="該当する案件はありません。"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import RequestPostTable from '../components/RequestPostTable.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
// import DateSelect from '@components/DateSelect.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import * as utils from '@libs/utils'
import VueJsonToCsv from 'vue-json-to-csv'
import * as dialogs from '@libs/dialogs'
import * as requestPostManager from '@managers/requestPostManager'
import * as taskRequestManager from '@managers/taskRequestManager'
import Toggle from '@components/Toggle.vue'
import * as orderManager from '@managers/orderManager'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import * as logManager from '@managers/logManager'
import * as estimateManager from '@managers/estimateManager'
import * as backend from '@libs/backend'
import PageModel from '../../main/pages/PageModel.vue'

const sessionStorageItemNameReset = 'requestPostListConditionReset'
const sessionStorageItemName = 'requestPostListCondition'
const sessionSubTask = 'sessionSubTask'
const sessionMainTask = 'sessionMainTask'
const sessionOrderTask = 'sessionOrderTask'
const sessionEmploymentTask = 'sessionEmploymentTask'
const sessionEmploymentRegularTask = 'sessionEmploymentRegularTask'
const sessionFilterToStaffUser = 'sessionFilterToStaffUser'
const sessionFilterByUser = 'sessionFilterByUser'

const startDate = '2022-10-01'

export default {
  extends: PageModel,
  components: {
    Icon,
    RequestPostTable,
    PrimaryButton,
    // DateSelect,
    PageHeader,
    EmptyMessage,
    VueJsonToCsv,
    Toggle,
    WmsSuggestInput
  },

  data() {
    return {
      period: {
        start: startDate
      },
      periodPresetName: 'custom',
      searchResults: [],
      loading: false,
      initial: true,
      freeze: true,
      untilTodayState: false,
      clickIncompleteOnlyState: true,
      csvName: '',
      // メニュー画面に戻ったら検索履歴はリセットされる
      searchConditionReset: {
        searchKeyword : '',
        category : '',
        clientName : '',
        clientPersonName : '',
        toDepartment : '',
        toStaffName : '',
        byUser : '',
        limitDate : '',
        sortItem : 'createDate',
        sortBy: -1
      },
      // メニュー画面に戻ってもリセットされない
      searchCondition: {},
      filterToStaffUser: true,
      filterByUser: true,
      labels: {
        category2: { title : "社内/お客様" },
        category: { title : "お問い合わせカテゴリー" },
        requestNo: { title : "お問い合わせ番号(登録時)" },
        lastRequestNo: { title : "お問い合わせ番号(前回問い合わせ時の返信メールに記載がございます)" },
        clientName: { title : "お客様会社名" },
        clientDepartmentName: { title : "お客様部署名" },
        clientPersonName: { title : "お客様担当者名" },
        clientMail: { title : "メールアドレス(返信メールが届きます)" },
        inquiry_title: { title : "タイトル" },
        note: { title : "お問い合わせ内容" },
        commonFile: { title : "添付資料" },
        addFileBtn: { title : "" },
        commonFile2: { title : "添付資料2" },
        commonFile3: { title : "添付資料3" },
        commonFile4: { title : "添付資料4" },
        commonFile5: { title : "添付資料5" },
        reason_note: { title : "対応内容" },
        depInline: { title : "横並び表示部品" },
        toDepartment: { title : "アポロ管財依頼先部署" },
        helpBtn: { title : "" },
        mailingStatus: { title : "メール送信状況" },
        labelSeminarInfoGroup: { title : "" },
        toStaffName: { title : "担当者" },
        byUser: { title : "依頼主" },
        requestDate: { title : "依頼日" },
        limitDate: { title : "期限" },
        newNowFlag: { title : "新規/既存" },
        estimationCategory: { title : "御見積カテゴリー" },
        estimationCleanCategory: { title : "御見積_清掃カ テゴリー" },
        estimationInspectCategory: { title : "御見積_点検カテゴリー" },
        estimationOtherCategory: { title : "御見積_その他カテゴリー" },
        estimationBasicLabel: { title : "見積基本情報" },
        buildingName: { title : "建物名" },
        buildingAddressLabel: { title : "所在地" },
        postalCode: { title : "郵便番号" },
        state: { title : "都道府県" },
        city: { title : "市区郡" },
        street: { title : "町村・番地" },
        building: { title : "建物名" },
        stairs: { title : "階数" },
        households: { title : "戸数" },
        estimationReason: { title : "見積理由(新規・業者の切り替え・リプレイスなど)" },
        availablePowerFlag: { title : "使用できる電源の有無" },
        parkingFlag: { title : "駐車スペースの有無" },
        availableToiletFlag: { title : "使用可能なトイレの有無" },
        estimationRoundLabel: { title : "ラウンドサービス" },
        roundServiceMemo: { title : "メモ" },
        cleanCount: { title : " 清掃希望回数(月〇回、週〇回など)" },
        dustRemoval: { title : "ゴミ搬出有無" },
        dustCount: { title : "ゴミ搬出希望回数" },
        estimationRoundPlusLabel: { title : "ラウンドプラス" },
        roundPlusMemo: { title : "メモ" },
        plusDustRemoval: { title : "ゴミ搬出有無" },
        plusDustCount: { title : "ゴミ搬出希望回数" },
        residenceType: { title : "ファミリー/シングル" },
        estimationEverydayLabel: { title : "日常清掃" },
        everydayCleanMemo: { title : "メモ" },
        everydayCleans: { title : "日常清掃" },
        managerWorkLabel: { title : "管理員業務" },
        managerWorkMemo: { title : "メモ" },
        managerWorks: { title : "管理員業務" },
        regularCleanLabel: { title : "定期清掃" },
        regularCleanMemo: { title : "メモ" },
        regularCleans: { title : "定期清掃" },
        regularCleanImage: { title : "図面(定期平面)" },
        regularCleanImage2: { title : "図面(定期平面)2" },
        regularCleanImage3: { title : "図面(定期平面)3" },
        regularCleanWorkWay: { title : "作業仕様" },
        glassCleanLabel: { title : "ガラス清掃" },
        glassCleanMemo: { title : "メモ" },
        glassCleanCount: { title : "清掃希望回数" },
        glassCleanImage: { title : "図面(ガラス立面)" },
        glassCleanImage2: { title : "図面(ガラス立面)2" },
        glassCleanImage3: { title : "図面(ガラス立面)3" },
        glassCleanWorkWay: { title : "作業仕様" },
        estimationDorainPipeLabel: { title : "排水管清掃" },
        estimationDorainPipeMemo: { title : "メモ" },
        estimationWaterTankLabel: { title : "貯水槽清掃" },
        estimationWaterTankMemo: { title : "メモ" },
        plantingLabel: { title : "植栽剪定" },
        plantingMemo: { title : "メモ" },
        plantingCount: { title : "希望回数" },
        plantingPlaceFile: { title : "対象箇所" },
        plantingPlaceFile2: { title : "対象箇所2" },
        plantingPlaceFile3: { title : "対象箇所3" },
        plantingReportFile: { title : "過去の報告書" },
        plantingReportFile2: { title : "過去の報告書2" },
        plantingReportFile3: { title : "過去の報告書3" },
        specialCleanLabel: { title : "特別清掃" },
        specialCleans: { title : "特別清掃" },
        specialCleanMemo: { title : "メモ" },
        specialCleanImage: { title : "添付資料　図面(平面図)・写真" },
        specialCleanImage2: { title : "添付資料　図面(平面図)・写真2" },
        specialCleanImage3: { title : "添付資料　図面(平面図)・写真3" },
        roundSubLabel: { title : "ラウンドサブメニュー" },
        roundSubMemo: { title : "メモ" },
        roundSubs: { title : "ラウンドサブメニュー" },
        fireInspectLabel: { title : "消防設備点検" },
        fireInspectReport: { title : "過去の報告書" },
        fireInspectReport2: { title : "過去の報告書2" },
        fireInspectReport3: { title : "過去の報告書3" },
        pressPumpInspectLabel: { title : "増圧ポンプ点 検" },
        pressPumpInspectReport: { title : "過去の報告書" },
        pressPumpInspectReport2: { title : "過去の報告書2" },
        pressPumpInspectReport3: { title : "過去の報告書3" },
        constructionEquipmentInspectLabel: { title : "建築設備定期検査" },
        constructionEquipmentInspectReport: { title : "各階の平面図及び面積表及び確認済証" },
        constructionEquipmentInspectReport2: { title : "各階の平面図及び面積表及び確認済証2" },
        constructionEquipmentInspectReport3: { title : "各階の平面図及び面積表及び確認済証3" },
        specificInspectLabel: { title : "特定建築物定期調査" },
        specificInspectReport: { title : "各階の平面図及び面積表及び確認済証" },
        specificInspectReport2: { title : "各階の平面図及び面積表及び確認済証2" },
        specificInspectReport3: { title : "各階の平面図及び面積表及び確認済証3" },
        waterTankCapacity: { title : "貯水槽の容量" },
        elevatedWaterTankFlag: { title : "高架水槽の有無" },
        waterQualityInspectFlag: { title : "水質検査有無" },
        waterPompInspectFlag: { title : "給水ポンプ点検有無" },
        waterTankReport: { title : "過去の報告書" },
        waterTankReport2: { title : "過去の報告書2" },
        waterTankReport3: { title : "過去の報告書3" },
        dorainPipeCleanCount: { title : "作業回数" },
        dorainPipeAge: { title : "築年数" },
        dorainPipeCleanResults: { title : "直近の作業実績" },
        dorainPipeHolidyFlag: { title : "土日祝日指定" },
        dorainPipeMultiFlag: { title : "複数日指定" },
        estimationCommonAreaLabel: { title : "共用 部設備点検" },
        commonAreaInspectCount: { title : "点検希望回数" },
        commonAreaReport: { title : "過去の報告書　または　点検項目・箇所・内容が分かる資料" },
        commonAreaReport2: { title : "過去の報告書　または　点検項目・箇所・内容が分かる資料2" },
        commonAreaReport3: { title : "過去の報告書　または　点検項目・箇所・内容が分か る資料3" },
        evInspectLabel: { title : "エレベーター保守点検" },
        evInspectContent: { title : "点検内容" },
        evInspectFile: { title : "過去の報告書" },
        evInspectFile2: { title : "過去の報告書2" },
        evInspectFile3: { title : "過去の報告書3" },
        evInspectMaker: { title : "メーカー" },
        evInspectMakeYear: { title : "製造年" },
        evQty: { title : "エレベーター保守点検回数" },
        evUnitName: { title : "エレベーター保守点検単位" },
        otherWorks: { title : "その他作業" }
      },
      // clickSearchPeriodState: false,
      // サブタスク表示
      withSub: false,
      mainTask: true,
      orderTask: true,
      employmentTask: true,
      employmentRegularTask: false,
      toDepartmentList: [],
      searchKeyword: '',
      category: '',
      clientName: '',
      clientPersonName: '',
      toDepartment: '',
      toStaffName: '',
      byUser: '',
      limitDate: '',
      searchStatus: 'open',
      // データが多すぎて表示不可
      overData: false,
      // 社員雇い入れ切り替えタブ表示
      isRegularStaffAuthorized: false
    }
  },

  watch: {
    withSub() {
      sessionStorage.setItem(sessionSubTask, this.withSub)
    },

    mainTask() {
      sessionStorage.setItem(sessionMainTask, this.mainTask)
    },

    orderTask() {
      sessionStorage.setItem(sessionOrderTask, this.orderTask)
    },

    employmentTask() {
      sessionStorage.setItem(sessionEmploymentTask, this.employmentTask)
    },

    employmentRegularTask() {
      sessionStorage.setItem(sessionEmploymentRegularTask, this.employmentRegularTask)
    }
  },

  computed: {
    // state() {
    //   return this.$store.getters.requestPostListState
    // },

    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    isTabletDevice() {
      return utils.deviceInfo.isTablet
    }
  },

  created() {
    this.freeze = true
    this.getRegularStaffAuthorized()
    this.loadCondition()
  },

  mounted() {
    this.$nextTick(() => {
      this.freeze = false
      this.refresh()
    })

    this.loadState()
  },

  methods: {
    /**
     * 検索結果 表示
     */
    // saveState() {
    //   console.log('saveState')

    //   const newState = {}
    //   newState.searchKeyword = this.searchConditionReset.searchKeyword
    //   newState.periodPresetName = this.periodPresetName
    //   newState.period = this.period ? { start: this.period.start, end: this.period.end } : null
    //   this.$store.dispatch('setRequestPostListState', newState)
    //   // CSV出力ファイル名 変更
    //   if(this.periodPresetName === 'custom') {
    //     this.csvName = 'メインタスク一覧_' + this.period.start + '~' + this.period.end
    //   }else if(this.periodPresetName === 'all') {
    //     this.csvName = 'メインタスク一覧_全期間'
    //   }
    // },

    loadState() {
      // const state = this.state
      // if (state) {
      //   this.searchConditionReset.searchKeyword = state.searchKeyword
      //   this.periodPresetName = state.periodPresetName
      //   this.period = state.period ? { start: state.period.start, end: state.period.end } : null
      // }

      // 未完了ボタンは、検索保持しない（遷移したら、常にONに戻すため、コメントアウト）
      // if (!this.searchCondition.searchStatus && this.searchCondition.clickIncompleteOnlyState == false) {
      //   // 未完了のみボタンが押されていない場合
      //   this.clickIncompleteOnlyState = this.searchCondition.clickIncompleteOnlyState
      // } else if (this.searchCondition.searchStatus) {
      //   // 未完了のみボタンが押されている場合
      //   this.selectStatus(this.searchCondition.searchStatus)
      // } else {
      //   // 未完了のみボタン 初期値
      //   this.selectStatus('open')
      //   this.searchCondition.searchStatus = 'open'
      // }

      if (this.searchCondition.untilTodayState) {
        // 本日までの期限ボタンが押されている場合
        this.untilToday()
      }

      this.searchKeyword = this.searchConditionReset.searchKeyword
      this.category = this.searchConditionReset.category
      this.clientName = this.searchConditionReset.clientName
      this.clientPersonName = this.searchConditionReset.clientPersonName
      this.toDepartment = this.searchConditionReset.toDepartment
      this.toStaffName = this.searchConditionReset.toStaffName
      this.byUser = this.searchConditionReset.byUser
      this.limitDate = this.searchConditionReset.limitDate

      let departmentList = this.$store.getters.departmentList
      let transDepartmentList = this.$store.getters.transDepartmentList
      this.toDepartmentList = [ ...departmentList, ...transDepartmentList ]
    },
    
    /**
     * 検索条件を取得
     */
    loadCondition() {
      // 見積りから遷移した場合の検索設定
      this.fromEstimateView()

      const searchJson = sessionStorage.getItem(sessionStorageItemName)
      const searchJsonReset = sessionStorage.getItem(sessionStorageItemNameReset)

      if(searchJson){
        this.searchCondition = JSON.parse(searchJson)
      }
      if(searchJsonReset){
        this.searchConditionReset = JSON.parse(searchJsonReset)
      }

      if (sessionStorage.getItem(sessionSubTask) == 'true') {
        this.withSub = true
      } else {
        this.withSub = false
      }

      if (sessionStorage.getItem(sessionMainTask) == 'false') {
        this.mainTask = false
      } else {
        this.mainTask = true
      }

      if (sessionStorage.getItem(sessionOrderTask) == 'false') {
        this.orderTask = false
      } else {
        this.orderTask = true
      }

      if (sessionStorage.getItem(sessionEmploymentTask) == 'false') {
        this.employmentTask = false
      } else {
        this.employmentTask = true
      }

      if (!sessionStorage.getItem(sessionEmploymentRegularTask) || sessionStorage.getItem(sessionEmploymentRegularTask) == 'false') {
        this.employmentRegularTask = false
      } else {
        this.employmentRegularTask = true
      }

      // 担当者
      if (sessionStorage.getItem(sessionFilterToStaffUser) == 'false') {
        this.filterToStaffUser = false
      } else {
        this.filterToStaffUser = true
      }

      // 依頼主
      if (sessionStorage.getItem(sessionFilterByUser) == 'false') {
        this.filterByUser = false
      } else {
        this.filterByUser = true
      }
    },

    async refresh() {
      this.loading = true
      await utils.wait(500)
      await this.$refs.requestPostList.fetchRequestPosts()
      await utils.wait(500)
      this.loading = false
    },

    /**
     * 検索
     */
    searchStart() {
      this.loading = true
    },

    searchEnd(data) {

      // this.saveState()

      const self = this
      self.initial = false
      self.loading = false
      self.searchResults = data.res
      self.overData = data.overData

      // 検索結果のデータで添付ファイルや配列が空の場合、空文字を追加
      for (let i = 0; i < this.searchResults.length; i++) {
        for( let j in this.labels ) {
          if(Object.keys(this.searchResults[i]).indexOf(j) == -1 || typeof this.searchResults[i][j] === 'object') {
            this.searchResults[i][j] = ''
          }
        }
      }
    },

    /**
     * 完了できるか確認
     */
    async checkCanCompelte(data) {
      // ログインユーザー名
      let name = this.$store.getters.user.user_name
      name = utils.deleteSpace(utils.deleteKana(name))

      // 管理者権限はOK
      const auth = this.$store.getters.auth
      if (auth.adminAuth) {
        return true
      }

      // ログインユーザーが担当者の場合、OK
      let tanto = utils.deleteSpace(utils.deleteKana(data.toStaffName))
      if (name == tanto) {
        return true
      }

      // ログインユーザーが依頼者の場合、OK
      let irai = utils.deleteSpace(utils.deleteKana(data.byUser))
      if (name == irai) {
        return true
      }
        
      // 担当者に関係なく、同じ部署の人はOK
      // 兼任を含めたログインユーザーの前所属部署
      let d = this.$store.getters.dualPosition
      for (let i = 0; i < d.length; i++) {
        const tar = d[i]
        if (tar.departmentName == data.toDepartment || tar.departmentId == data.toDepartmentId) {
          return true
        }
      }
      // デンタツの場合
      // デンタツの全データ取得
      let resDT = this.$store.getters.transDepartmentList
      // 該当のデンタツ部署のデータを取得
      let dentatsu = resDT.find((r) => {
        return (r.name == data.toDepartment || r.departmentId == data.toDepartmentId)
      })
      // 該当のデンタツに紐づく部署の人はOK（RLS_デンタツならRLS全員OK）
      if (dentatsu && dentatsu.transDepartment) {
        for (let i = 0; i < d.length; i++) {
          const tar = d[i]
          if (tar.departmentId == dentatsu.transDepartment) {
            return true
          }
        }
      }
      await dialogs.showErrorDialog('完了できません', '担当者と同じ部署・依頼主でないため、完了できません。')
      return false
    },

    
    // 未完了ボタンを押下するとタスクを完了に変更
    async changeStatus(data) {
      const changeStatusData = data

      const auth = await this.checkCanCompelte(changeStatusData)
      if (!auth) {
        return
      }

      const dialogResult = await dialogs.showConfirmDialog('タスクの完了', 'タスクを完了しますか？', 'はい', 'キャンセル')
      if (dialogResult === 'YES') {
        if (changeStatusData.type == 'apollo_request_post') {
          // IDを元にリクエストポストに紐づく、サブタスクを検索
          let subTasks = await taskRequestManager.getByRequestPostId(this, changeStatusData._id, null)

          // サブタスクが全てdoneになっているか
          let statusResult = subTasks.every(function(val){
            return val.status == 'done'
          })
          
          // trueの場合、メインタスクのステータスをdoneにする
          if (statusResult) {
            const result = await requestPostManager.markAsDone(this, changeStatusData._id)
            if (result) {
              await dialogs.showSuccessDialog('タスクの完了', '完了にしました。')
              changeStatusData.status = 'done'
              logManager.recording(this, logManager.Loglevel.INFO, 'メインタスク', 'ステータス完了', 'メインタスク（お問い合わせ番号：' + changeStatusData.requestNo + '）のステータスを完了しました。')
            } else {
              await dialogs.showErrorDialog('エラー', '完了にできませんでした。')
            }
            this.refresh()
          } else {
            await dialogs.showErrorDialog('サブタスクのステータス', 'サブタスクを全て完了してから、再度行ってください。')
            return
          }
        } else if (changeStatusData.type == 'order') {
          // IDを元に受注に紐づく、サブタスクを検索
          let subTasks = await taskRequestManager.getByOrderId(this, changeStatusData._id, null)

          // サブタスクが全てdoneになっているか
          let statusResult = subTasks.every(function(val){
            return val.status == 'done'
          })
          
          // trueの場合、受注タスクのステータスをdoneにする
          if (statusResult) {
            const result = await orderManager.markAsDone(this, changeStatusData._id)
            if (result) {
              await dialogs.showSuccessDialog('タスクの完了', '完了にしました。')
              changeStatusData.status = 'done'

              // 見積りのデータの受注ステータス更新、完了にした人を保存
              // ログインユーザー名
              let name = this.$store.getters.user.user_name
              name = utils.deleteKana(name)
              await estimateManager.changeOrderStatus(this, changeStatusData.estimateNo, '受注完了', name)

              if (changeStatusData.requestNo) {
                logManager.recording(this, logManager.Loglevel.INFO, '受注タスク', 'ステータス完了', '受注タスク（お問い合わせ番号：' + changeStatusData.requestNo + '）のステータスを完了しました。')
              } else {
                logManager.recording(this, logManager.Loglevel.INFO, '受注タスク', 'ステータス完了', '受注タスクのステータスを完了しました。')
              }
              
            } else {
              await dialogs.showErrorDialog('エラー', '完了にできませんでした。')
            }
            this.refresh()
          } else {
            await dialogs.showErrorDialog('サブタスクのステータス', '他のタスクを完了してから、再度行ってください。')
            return
          }
        }
      }
    },

    // ソート change イベント
    async changeSort(data) {
      this.searchConditionReset.sortItem = data.item
      this.searchConditionReset.sortBy = data.by
      // セッションストレージに保存
      this.setSessionStorage(sessionStorageItemNameReset, this.searchConditionReset)
    },

    // 検索条件をクリア
    clear() {
      window.sessionStorage.clear()
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },

    /**
     * 絞込み
     * @param target 絞込み対象
     */
    filter(target) {
      if (target == 'toStaffUser') {
        this.filterToStaffUser = !this.filterToStaffUser
      } else if (target == 'byUser') {
        this.filterByUser = !this.filterByUser
      }
    },

    // 本日までの期限ボタン 押下
    untilToday() {

      if(!this.untilTodayState) {
        let today = utils.formatDateString(new Date(), '{yyyy}-{MM}-{dd}')
        this.period = { end: today }
      } else {
        this.period = { start: startDate }
      }
      this.periodPresetName = 'custom'
      // this.$refs.dateSelect.setRange(this.period)

      //ボタンの色を変更
      this.untilTodayState = !this.untilTodayState
    },

    // 未完了のみボタン 押下
    selectStatus(status) {
      //ボタンの色を変更
      this.clickIncompleteOnlyState = !this.clickIncompleteOnlyState
      if (this.clickIncompleteOnlyState) {
        this.searchStatus = status
      } else {
        this.searchStatus = ''
      }
    },

    /**
     * 検索条件をセッションストレージへ保存
     */
    setSessionStorage(name, searchCondition) {
      const searchStr = JSON.stringify(searchCondition)
      sessionStorage.setItem(name, searchStr)
    },  

    /**
     * 検索ボタン 押下
     */
    search() {
      // メニュー画面に戻ったら、リセットされる
      this.searchConditionReset.searchKeyword = this.searchKeyword
      this.searchConditionReset.category = this.category
      this.searchConditionReset.clientName = this.clientName
      this.searchConditionReset.clientPersonName = this.clientPersonName
      this.searchConditionReset.toDepartment = this.toDepartment
      this.searchConditionReset.toStaffName = this.toStaffName
      this.searchConditionReset.byUser = this.byUser
      this.searchConditionReset.limitDate = this.limitDate

      // メニュー画面に戻ってもリセットされない
      this.searchCondition.untilTodayState = this.untilTodayState
      // this.searchCondition.clickIncompleteOnlyState = this.clickIncompleteOnlyState
      // this.searchCondition.searchStatus = this.searchStatus

      // セッションストレージに検索条件を保存
      sessionStorage.setItem(sessionFilterByUser, this.filterByUser)
      sessionStorage.setItem(sessionFilterToStaffUser, this.filterToStaffUser)
      // セッションストレージに保存
      this.setSessionStorage(sessionStorageItemNameReset, this.searchConditionReset)
      this.setSessionStorage(sessionStorageItemName, this.searchCondition)
      this.$nextTick(function() {
        this.$refs.requestPostList.fetchRequestPosts()
      })
    },

    /**
     * 見積りから遷移した場合の検索
     */
    fromEstimateView() {
      // 見積参照から遷移した場合
      if (this.$route.query.requestNo) {
        // 検索履歴をクリア
        window.sessionStorage.clear()
        // 問い合わせNoで検索をかける
        this.searchKeyword = utils.clone(this.$route.query.requestNo)
        this.searchConditionReset.searchKeyword = utils.clone(this.$route.query.requestNo)

        // 担当者絞り込み解除
        this.filterToStaffUser = false
        // 依頼者絞り込み解除
        this.filterByUser = false

        // 未完了のみを解除
        this.clickIncompleteOnlyState = false
        this.searchCondition.clickIncompleteOnlyState = false
        this.searchStatus = ''
        // this.searchCondition.searchStatus = ''

        // セッションストレージに検索条件を保存
        sessionStorage.setItem(sessionFilterByUser, this.filterByUser)
        sessionStorage.setItem(sessionFilterToStaffUser, this.filterToStaffUser)
        this.setSessionStorage(sessionStorageItemNameReset, this.searchConditionReset)
        this.setSessionStorage(sessionStorageItemName, this.searchCondition)
      }
    },

    /**
     * 社員雇い入れ切り替えタブを表示
     */
    async getRegularStaffAuthorized() {
      // 管理者ユーザー、大野様、ログインユーザーの所属部署（兼任も含めて）が人事部、所属部署課長クラスなら、true
      const isEmpRes = await backend.searchData('user/getDepartment', { user: this.$store.getters.user.user_name })
      const isEmployment = utils.isRegularStaffAuthorized(this.$store.getters.user.id, isEmpRes)
      const departmentArr = utils.departmentArr(isEmpRes)
      if (isEmployment || (departmentArr && departmentArr.length)) {
        this.isRegularStaffAuthorized = true
      } else {
        this.isRegularStaffAuthorized = false
      }
    }
  },
}
</script>

<style scoped>
  #toDepartment >>> input[type=text] {
    height: 39px;
    margin-top: -2px;
    border-radius: 0.375rem !important;
  }

  .list {
    min-width: 1350px;
  }
</style>